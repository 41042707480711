import _ from 'lodash';
import Checkbox from 'material-ui/Checkbox';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import NoticeIcon from 'material-ui/svg-icons/action/announcement';
import DriveIcon from 'material-ui/svg-icons/notification/drive-eta';
import TextField from 'material-ui/TextField';
import React from 'react';
import { connect } from 'react-redux';
import { updateRiskOnDriver } from '../actions/drivers';
import { listenToAndNormalizeDriveData, updateDriveResults } from '../actions/drives';
import { startListeningToVideos } from '../actions/video';
import IntakeButton from './components/intakebutton';
import IntakeCsv from './components/intakecsv';


const iconStyles = {
    width : "65px",
    height: "65px"
}

const radioButtonStyle = {
    marginBottom: 16
};

class DriveResultsEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    
    componentWillMount() {
        this.props.getDrive(this.props.params.aid);
        this.props.startListeningToVideos();
    }

    render() {
        const aid = this.props.params.aid;
        const drive = this.props.drive;
        const content = this.props.content;
        const videos = [{key: false, title: "No Video"}, ..._.map(this.props.videos, (val, key) => {
            return {
                key,
                title: val.title
            }
        })];
        
        if (drive == null || drive.loading === true) {
            return (<div>Loading...</div>);
        }
        let results = Object.assign({}, drive.results ? drive.results : {}, this.state.results); 

        const pageHead = (
            <div className="row page-header" style={{position: 'relative'}}>
                <div className="display-5">Editing {(this.props.driver ? this.props.driver.fullname : '') + "'"}s Drive Insights</div>
                <div>Date of birth: {this.props.driver && this.props.driver.dob ? this.props.driver.dob : "unknown"}</div>
                
                <IconButton style={{position: 'absolute', top: 0, right: 0}} iconClassName="icon ion-close" href={`/drive/${aid}`}/>
            </div>);



        const danger = this.props.drive.results ? this.props.drive.results.danger : false;
        const alertIcon = danger ? <NoticeIcon color="white" style={iconStyles} /> : <DriveIcon color="white" style={iconStyles}  />
        return (
            <div>
                {pageHead} 
                <div className="panel">
                    <div className="panel-body">
                        <Paper className="genPap">
                            <div className="row">
                                <div className="col-xs-3">
                                    <div className="text-desc">Start Time:</div><b>{new Date(this.props.drive.start).toLocaleString()}</b>
                                </div>
                                <div className="col-xs-3">
                                    <div className="text-desc">Duration:</div><b>{Math.ceil((this.props.drive.lastUpdate - this.props.drive.start) / 60000)} Minutes</b>
                                </div>
                                <div className="col-xs-3">
                                    <IntakeButton intakeId={this.props.drive.intake} />
                                </div>
                                <div className="col-xs-3">
                                    <IntakeCsv intakeId={this.props.drive.intake} filename={(this.props.driver && this.props.driver.fullname ? this.props.driver.fullname.replace(/\s/g, "_") : 'anonymous') + "_intake.csv"} />
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        <Paper className="genPap">
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="drive-summary">
                                        <TextField
                                            hintText="How did this driver do?"
                                            floatingLabelText="Summary"
                                            multiLine={true}
                                            rows={4}
                                            fullWidth={true}
                                            defaultValue={drive.results ? drive.results.summary : ''}
                                            onChange={(e) => {
                                                results.summary = e.target.value;
                                                this.setState({results});
                                            }} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className = "help-section">
                                        What did they do right? What did they do wrong? Where could they improve?
                                    </div>
                                </div>
                            </div>
                        </Paper>
                        <Paper className="genPap">
                            <div className="row" style={{marginTop: '2em'}}>
                                <div className="col-sm-8">
                                    

                                    <RadioButtonGroup
                                        name="danger"
                                        defaultSelected = { drive.results ? drive.results.danger : 0 }
                                        onChange = { (e, value) => {
                                            results.danger = value;
                                            this.setState({results});
                                        } }>
                                        <RadioButton
                                            value={0}
                                            label="Little evidence of dangerous driving"
                                            style={radioButtonStyle}/>
                                        <RadioButton
                                            value={1}
                                            label="Some evidence of dangerous driving"
                                            style={radioButtonStyle}/>
                                        <RadioButton
                                            value={2}
                                            label="Strong evidence of dangerous driving"
                                            style={radioButtonStyle}/>
                                    </RadioButtonGroup>

                                </div>
                                <div className="col-sm-4">
                                    <div className = "help-section">
                                        Did the event report, video recording, and survey lead you to believe
                                        this driver would engage in dangerous driving?
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body">

                        <Paper className="genPap">

                            <div className="row">
                                <div className="col-sm-6">
                                    <h6>Events</h6>
                                    {_.values(drive.events).map((val, key) => {
                                        return (
                                            <div key={key}>
                                                {val.name} - {val.start}
                                            </div>
                                        ) 
                                    })}
                                </div>
                                <div className="col-sm-6">
                                    <h6>Safety Lapses</h6>
                                    {content.safetyLapses.map((lapse, i) => {
                                        let val = 0;
                                        let vid = false;
                                        if (drive.results && drive.results.safetyLapses) {
                                            let dl = drive.results.safetyLapses.find((dl) => dl.type == lapse.slug);
                                            if (dl) {
                                                val = dl.count || 0;
                                                vid = dl.video || false;
                                            }
                                        } else {
                                            _.values(drive.events).map((event, key) => {
                                                if (event.name == lapse.slug) {
                                                    val += 1;
                                                }
                                            });
                                            if (val > 0) {
                                                results.safetyLapses = results.safetyLapses || [];
                                                if (!results.safetyLapses.find((sl) => sl.type == lapse.slug)) {
                                                    results.safetyLapses.push({
                                                        type: lapse.slug,
                                                        count: val
                                                    });
                                                }
                                            }
                                        }

                                        if (vid == false) {
                                            let i = results.safetyLapses ? results.safetyLapses.findIndex((sl) => sl.type == lapse.slug) : -1;
                                            if (i != -1) {
                                                vid = results.safetyLapses[i].video || false;
                                            }
                                        }

                                        return (
                                            <div key={i} className="row">
                                                <div className="col-xs-12">
                                                    {lapse.name}
                                                </div>
                                                <div className="col-xs-6">
                                                    <TextField
                                                        hintText="How many times did this lapse occur?"
                                                        type="number"
                                                        defaultValue={val}
                                                        fullWidth={true}
                                                        onChange={(e) => {
                                                            results.safetyLapses = results.safetyLapses || [];
                                                            let i = results.safetyLapses.findIndex((sl) => sl.type == lapse.slug);
                                                            let val = parseInt(e.target.value, 10);
                                                            if (val <= 0) {
                                                                e.target.value = 0;
                                                            }
                                                            
                                                            if (i == -1) {
                                                                results.safetyLapses.push({
                                                                    type: lapse.slug,
                                                                    count: val
                                                                });
                                                            } else {
                                                                results.safetyLapses[i].count = val;
                                                            }
                                                            this.setState({results});
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <SelectField 
                                                        value={vid || false}
                                                        fullWidth={true}
                                                        onChange={(e, index, value) => {
                                                            results.safetyLapses = results.safetyLapses || [];
                                                            let i = results.safetyLapses.findIndex((sl) => sl.type == lapse.slug);
                                                            
                                                            if (i == -1) {
                                                                results.safetyLapses.push({
                                                                    type: lapse.slug,
                                                                    video: value,
                                                                    count: 0
                                                                });
                                                            } else {
                                                                results.safetyLapses[i].video = value;
                                                            }
                                                            this.setState({results});
                                                        }}
                                                    >
                                                        {videos.map((item) => (
                                                            <MenuItem value={item.key} key={item.key} primaryText={item.title} />
                                                        ))}
                                                    </SelectField>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </Paper>

                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body">

                        <Paper className="genPap">

                            <div className="row">
                                <div className="col-sm-12">
                                    <h6>Indications</h6>
                                    {content.indications.map((indication, i) => (
                                        <Checkbox
                                            key={i}
                                            label={indication.name}
                                            defaultChecked={drive.results && drive.results.indications && drive.results.indications.includes(indication.slug)}
                                            onCheck={(e,checked) => {
                                                results.indications = results.indications || [];
                                                if (checked) {
                                                    results.indications.push(indication.slug);
                                                } else {
                                                    results.indications = results.indications.filter((slug) => slug != indication.slug);
                                                }
                                                this.setState({results});
                                            }} />
                                    ))}
                                </div>
                            </div>

                        </Paper>

                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <RaisedButton onClick={() => {
                                    this.props.updateDriveResults(aid, results);
                                    this.props.updateRisk(results.danger, drive.user, aid);
                                    location.href = "/driveresults/" + aid; //switch to the user's profile? TODO or just change the drive results page to a big under eval banner
                                    }}
                                    label="Save" primary={true} style={{marginRight: 20}}/>
                                <RaisedButton href={`/driveresults/${aid}`} label="Back"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state, props) => ({
    drive: state.drive[props.params.aid],
    driver: (state.drive.hasOwnProperty(props.params.aid) &&
             state.drive[props.params.aid].hasOwnProperty('user')) ? state.driver[state.drive[props.params.aid].user] : {},
    content: state.content.results,
    videos: state.video.data
}), (dispatch) => ({
    updateRisk: (danger, uid, aid) => dispatch(updateRiskOnDriver(danger, uid, aid)),
    getDrive: (aid) => dispatch(listenToAndNormalizeDriveData(aid)),
    updateDriveResults: (aid, results) => dispatch(updateDriveResults(aid, results)),
    startListeningToVideos: () => dispatch(startListeningToVideos())
}))(DriveResultsEdit);
