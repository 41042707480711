import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  DatePicker,
  TimePicker
} from 'redux-form-material-ui';


const validate = function (values) {
    const errors = {};
    // if (!values.locale) {
    //     errors.locale = "Locale value is required"
    // }
    return errors;
}

class SetQueryTimespanForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    

    render () {

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="qry-timespan-form">
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                name="startDate"
                                component={DatePicker}

                                hintText="Start Date" />
                        </div>
                        <div className="col-sm-6">
                            <Field
                                name="endDate"
                                component={DatePicker}

                                hintText="End Date" />
                        </div>
                    </div>
                    <div className="row" style={{display:"none"}}>
                        <div className="col-sm-6">
                            <Field
                                name="startTime"
                                component={TimePicker}

                                defaultValue={null} // TimePicker requires an object,
                                // and redux-form defaults to ''
                                hintText="Start Time" />
                             
                        </div>
                        <div className="col-sm-6">
                            <Field
                                name="endTime"
                                component={TimePicker}

                                defaultValue={null} // TimePicker requires an object,
                                // and redux-form defaults to ''
                                hintText="End Time" />
                        </div>
                    </div>
                    <div className="sbmt-row">
                        <button type="submit">Set Timespan</button>
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
SetQueryTimespanForm = reduxForm({
  form: 'queryTimespan', // a unique name for this form
  validate
})(SetQueryTimespanForm);

export default SetQueryTimespanForm;