
import CircularProgress from 'material-ui/CircularProgress';
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import CoachingWizard from './components/coachingsurveywizard';
import SurveySelectForm from './components/surveyselectform';
import ChooseSurveyForm from './components/choosesurveyform';

const styles = {
    unity: {
        padding: "1rem 19.875rem 4.875rem", 
        backgroundColor: "#3b125d"
    },

    web: {

    }
}

const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToCoachingItems() );
    store.dispatch( actions.startListeningToCoachingPages() );
    store.dispatch( actions.startListeningToCoachingSurveys() );
    store.dispatch( actions.startListeningToVideos());
    store.dispatch( actions.startListeningToOrgs());
    store.dispatch( actions.startListeningIntakeSurveyOptions() );
});

const hasRecentIntake = (auth, offset) => {
    if (auth.lastIntake && auth.lastIntakeTime) {
        const now = Date.now() + offset;
        return Math.abs(now - auth.lastIntakeTime) < 2592000000; // one month (switch to 60000 one minute to test)

    }
    return false;
}

//TODO: check if driver ID has an intake already within the last month
const hasRecentIntakeDriver = (auth, drId, offset) => {
    return false;
}

class Intake extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        console.log("componentWillMount Intake");
        const drId = this.props.params && this.props.params.driverId;
        const survey = this.props.params && this.props.params.survey;
        if (!survey) {
            if (drId) {
                if (hasRecentIntakeDriver(this.props.auth, drId, this.props.util.offset)) {
                    if (this.props.params && this.props.params.unity) {
                        window.sendIntakeSuccess(this.props.auth.lastIntake);
                    }
                }
            } else {
                if (hasRecentIntake(this.props.auth, this.props.util.offset)) {
                    if (this.props.params && this.props.params.unity) {
                        window.sendIntakeSuccess(this.props.auth.lastIntake);
                    }
                }
            }
        }
        
        startListening();
        window.setFullHeight = !!(this.props.params && this.props.params.unity);
    }

    componentDidMount () {
        if (this.props.params && this.props.params.unity) {
            hlp.poll(() => {
                return window.tellUnityToSendIntakeId;
            }).then(() => {
                console.log("asking for id");
                window.tellUnityToSendIntakeId("please give me the id?");
            });
        }
    }

    componentWillReceiveProps (nextProps) {

    }

    componentWillUnmount () {
        window.setFullHeight = false;
    }

    render () {
        const p = this.props;
        const unity = p.params && p.params.unity;
        const intakeKeyForOpen = p.params && p.params.intakeKeyForOpen;
        const mgrId = p.params && p.params.mgrId;
        const driverId = p.params && p.params.driverId;
        const postDriveSurvey = p.params && p.params.survey;
        const aid = p.params && p.params.aid;

        console.log("There should be an aid", aid);

        let content =   <div className="inTheMiddle">
                            <CircularProgress className="middled" />
                        </div>

        if (!p.intakeSurveyOptions.hasReceivedData) {
            return content;
        }

        let org = "";

        if (!intakeKeyForOpen) {
            org = p.orgs.hasReceivedOrgData && p.orgs.data[ p.auth.oid ];
        }

        let surveyIdReady = intakeKeyForOpen || org;

        let surveyId = intakeKeyForOpen || (p.surveys.chosenSurvey || ((org && org.intake) || "default"));

        if (postDriveSurvey) {
            surveyId = (org && org.postDriveSurvey) || "defaultPostDriveSurvey";
        }

        const surveySelector = p.surveys.chosenSurveySelector || org.surveySelector;

        console.log("Does the org have the options? ", org);

        if (org && (org.intakeSurveyOptions && !(p.surveys.chosenSurvey || p.surveys.chosenSurveySelector))) {
            
            return <ChooseSurveyForm
                        org={org}
                        intakeSurveyOptions={p.intakeSurveyOptions.data}
                        onSubmit={(function (e) {e.preventDefault(); this.props.chooseSurvey(); }).bind(this) } />
        }

        if (p.util && p.util.offset) {
            const recent = driverId ? hasRecentIntakeDriver(p.auth, driverId, p.util.offset) : hasRecentIntake(p.auth, p.util.offset);
            if (unity && recent) {
                content = <div>{"You've completed this intake survey recently enough. No need to do it again right now."}</div>
            } else {
                prodConsole.log(
                                "items: " + p.items.hasReceivedData +
                                " -- pages: " + p.pages.hasReceivedData + 
                                " -- surveys: " + p.pages.hasReceivedData +
                                " -- videos: " + p.surveys.hasReceivedData +
                                " -- surveyId: " + surveyId +
                                " -- surveyIdReady: " + surveyIdReady +
                                " -- submitting: " + p.coaching.submitting
                                );
                if (    p.items.hasReceivedData &&
                        p.pages.hasReceivedData && 
                        p.surveys.hasReceivedData &&
                        p.video.hasReceivedVideoData &&
                        surveyId &&
                        surveyIdReady &&
                        !p.coaching.submitting) {
                            if (surveySelector && !p.surveys.prescribedSurvey) {
                                // show survey selector item
                                let surveySelectorQuestion = p.items.data[surveySelector]
                                content = <SurveySelectForm
                                            item={surveySelectorQuestion}
                                            onSubmit={(function (e) {e.preventDefault(); e.stopPropagation(); this.props.setPrescriptedSurvey();  return false; }).bind(this)} />
                            } else {
                                surveyId = p.surveys.prescribedSurvey || surveyId;
                                const survey = p.surveys.data[surveyId];
                                console.log(surveyId);
                                console.log(p.surveys.data[surveyId]);
                                content =   <CoachingWizard
                                                className="coaching-form"
                                                survey={survey}
                                                pages={p.pages.data}
                                                items={p.items.data}
                                                videos={p.video.data}
                                                onSubmit={(function (e) {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (postDriveSurvey) {
                                                        this.props.submitPostDriveSurvey(surveyId, survey, this.context.router, unity, aid);
                                                    } else {
                                                        this.props.submitIntakeSurvey(surveyId, survey, this.context.router, unity, intakeKeyForOpen, mgrId, driverId); 
                                                    }
                                                    
                                                    return false; 
                                                }).bind(this)}>
                                            </CoachingWizard>
                            }
                            
                }


                // if (!open && (p.auth && !p.auth.dob)) {
                //     content = <DobForm onSubmit={(function (e) {e.preventDefault(); e.stopPropagation(); this.props.submitDob(); return false;}).bind(this)} />
                // }
            }
        }



        

        // style={ unity ? styles.unity : styles.web }

        return (

            <div className={unity ? "full-height unityBold" : ""} >
                <div className={(unity ? "unityOuter" : "") + " panel"}>
                    <div className={(unity ? "unityInner" : "") + " panel-body"}>
                        <Paper style={{margin: "20px", padding: "20px", overflow: "hidden"}}>
                            {content}
                        </Paper>
                    </div>
                </div>
            </div> 
                
        )
    }
}

Intake.contextTypes = {
    router: React.PropTypes.object.isRequired
};

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        items: appState.coachingItems,
        pages: appState.coachingPages,
        surveys: appState.coachingSurveys,
        video: appState.video,
        coaching: appState.coaching,
        util: appState.util,
        orgs: appState.orgs,
        intakeSurveyOptions: appState.intakeSurveyOptions
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitIntakeSurvey: function (sid, s, router, unity, intakeKeyForOpen, mgrId, driverId) {
            dispatch( actions.submitIntakeSurvey(sid, s, router, unity, intakeKeyForOpen, mgrId, driverId) )
        },
        submitDob: function () {
            dispatch( actions.submitDob() )
        },
        setPrescriptedSurvey : function () { dispatch( actions.setPrescriptedSurvey() ) },
        submitPostDriveSurvey: function (sid, s, router, unity, aid) { dispatch( actions.submitPostDriveSurvey(sid, s, router, unity, aid) ) },
        chooseSurvey: function () { dispatch( actions.chooseSurvey() ) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Intake);
