import _ from 'lodash';
import { C } from '../constants';

const assessmentRef = C.APP.database().ref('assessments');
const unlockedAssmntRef = C.APP.database().ref('unlockedAssessments');
const lockedAssmntRef = C.APP.database().ref('lockedAssessments');

export const startListeningToAssessment = function (aid) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_ASSESSMENT_DATA, aid });
        console.log("trying to listen to assessment: " + aid);
        assessmentRef.child(aid).on('value', (snap) => {
            console.log("received assessment: " + aid);
            dispatch({ type: C.RECEIVE_ASSESSMENT_DATA, aid, data: snap.val() });
        });
    }
}

//pull down current state once -- clobber the store every time
export const fetchUnlockedAssessments = function () {
    return function (dispatch, getState) {
        console.log("trying to listen to unlocked assessments");
        unlockedAssmntRef.once('value', (snap) => {
            console.log("received unlocked assessments");
            dispatch({ type: C.RECEIVE_UNLOCKED_ASSESSMENTS, data: snap.val() });
        });
    }
}

//pull down current state once -- clobber the store every time
export const fetchLockedAssessments = function () {
    return function (dispatch, getState) {
        console.log("trying to listen to locked assessments");
        lockedAssmntRef.once('value', (snap) => {
            console.log("received locked assessments");
            dispatch({ type: C.RECEIVE_LOCKED_ASSESSMENTS, data: snap.val() || {} });
        });
    }
}

export const triggerFeedback = function (aid) {
    return function (dispatch, getState) {
        const email = getState().form.email.values.email;
        assessmentRef.child(aid + '/email').set(null).then(() => {
            assessmentRef.child(aid).update({email});
        })
    }
}

export const updateFeedbackProgress = function (aid, total, current) {
    return function (dispatch, getState) {
        console.log("updateFeedbackProgress", aid, total, current)
        assessmentRef.child(aid + '/feedbackTotalSteps').set(total).then(() => {
            assessmentRef.child(aid + "/feedbackStepsCompleted").transaction((currentStep) => {
                var newValue = Math.max(current, currentStep); // never make it lower
                return newValue;
            });
            if (current >= total) {
                assessmentRef.child(aid + "/feedbackCompleted").set(true);
                // need to write a date here if not yet written
                assessmentRef.child(aid + "/feedbackCompletionDate").once('value').then((snap) => {
                    if (!snap.val()) {
                        assessmentRef.child(aid + "/feedbackCompletionDate").set(C.TS)
                    }
                })
            }
        })
    }
}

export const updateFeedbackVideoProgress = function (aid, videoList) {
    return function (dispatch, getState) {
        console.log("updateFeedbackVideoProgress", aid, videoList);
        assessmentRef.child(aid + '/feedbackCompleted').once('value').then((snap) => {
            if (!snap.val()) {
                let videoTracker = {};
                _.map(videoList, (val, key) => {
                    videoTracker[key] = val.completed;
                })
                assessmentRef.child(aid + '/feedbackVideos').set(videoTracker);
            }
        })
    }
}




