import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedData: false,
  submittingnew: false,
  data: {},
  prescribedSurvey: "",
  chosenSurvey: "",
  chosenSurveySelector: ""
  // init: {},
  // states: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_COACHING_SURVEYS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedData: true
        });

    case C.AWAIT_NEW_COACHING_SURVEY_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_COACHING_SURVEY_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

    case C.SET_PRESCRIBED_SURVEY:
      return Object.assign({}, state, {
            prescribedSurvey: action.surveyId
        })

    case C.SET_CHOSEN_SURVEY:
      if (action.option.surveySelectQuestion) {
        return Object.assign({}, state, {
            chosenSurveySelector: action.option.surveySelectQuestion
        })
      }
      if (action.option.survey) {
        return Object.assign({}, state, {
            chosenSurvey: action.option.survey
        })
      }

      default:
        return state;
  }
}