import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import AnonImg from "../assets/images/anon.png";
import store from '../store';
import Drives from './components/drives';


class Profile extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {

        store.dispatch( actions.startListeningToDriver( this.props.params.uid ) );

    }

    render () {
        const p = this.props;
        const uid = this.props.params.uid;
        if (!p.driver || !p.driver[uid] || p.driver[uid].loading) {
            return (<CircularProgress />)
        }
        const driver = p.driver[uid];
        return (
            <div>
                <div className="page-header">
                    <div className="media">
                        <div className="media-left media-meddle">
                            <img width="80" alt="" src={AnonImg} />
                        </div>
                        <div className="media-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="display-6">{driver.fullname}</div>
                                    <a className="small" href="">{
                                        driver.nickname ? "employee@company.com" : p.driver[uid].email
                                    }</a>
                                </div>

                                <div className="col-sm-6">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel">
                    <Drives uid={uid} />
                </div>
            </div> 
        // Too many divs
        // Too many cooks
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        driver: appState.driver,
    };
}

const mapDispatchToProps = function (dispatch) {//for dispatching actions
    return {
        deleteUser : function (uid) {
            dispatch( actions.deleteUser(uid) )
        }
    }
}

Profile.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Profile);