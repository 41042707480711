import { RadioButton } from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Markdown from "react-markdown";
import { Field, reduxForm } from 'redux-form';
import { Checkbox, RadioButtonGroup, TextField } from 'redux-form-material-ui';
import { C } from '../../constants';

const styles = {
    multhorz: {
        display: "inline-block",
        width: "20%"
    },
    multvert: {

    },
    multvertlabel: {

    },
    multhorzlabel: {
            position: "relative",
            display: "block",
            width: "0px",
            top: "-1.8em",
            left: "-2.29em"
    }
}

const layoutMap ={
    "full": 12,
    "half": 6,
    "threeq": 9,
    "quarter": 3
}

let validate = function (values) {
    return [];
}

class CoachingWizardPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    render () {
        const p = this.props;

        validate = p.validate;

        

        const page = p.page;
        const items = p.items;
        const cols = [];

        for (var i = 0; i < items.length; i++) {
            let choices = [];
            console.log("What's in an item?", items[i]);
            const item = items[i];
            const layout = page.items[i].layout;
            if (item.numberOfChoices) {
                let choiceWidth = (100 / item.numberOfChoices) + "%";
                for (let i = 0; i < item.choices.length; i++) {
                    let thisChoice =  item.choices[i];
                    let num = i + 1;
                    if (["multvert", "multhorz"].includes(item.type)) {
                        choices.push(<RadioButton
                            key={p.pageID + "-chc-" + num}
                            className={item.type + "-chc"}
                            style={item.type == "multhorz" ? {display: "inline-block", width: choiceWidth, margin: "7px 0"} : {margin: "7px 0"}}
                            labelStyle={item.type == "multhorz" ? {
                                position: "relative",
                                display: "block",
                                width: "0px",
                                top: "-1.8em",
                                left: "-2.29em",
                                marginBottom: "0px",
                                lineHeight: "20px"
                            } : {marginBottom: "0px", lineHeight: "20px"}}
                            value={thisChoice.value}
                            label={thisChoice.display}/>)
                    }
                    if (item.type == "allthatapply") {
                        choices.push(<div>
                            <Field
                                name={page.name + ">>" + item.name + "." + thisChoice.value}
                                key={p.pageID + "-chc-" + num}
                                value={false}
                                component={Checkbox}
                                className="allThatApplyCB"
                                label={thisChoice.display}/>
                        </div>)
                    }
                };
                if (item.type == "allthatapply" && item.includeOther) {
                    choices.push(<div key={p.pageID + "-other-chc-" +  item.name} className="other-choice">
                            <Field
                                name={page.name + ">>" + item.name + "." + "other"}
                                component={TextField}
                                hintText="Other" />
                        </div>)
                }
            }

            const itemContent =  (
                <div className="row intake-row">
                    <div className={"col-sm-12 display-6 intake-q-t intake-q-t-" + item.type}><Markdown source={item.text} /></div>
                    {(["text", "number", "email"].includes(item.type)) && <div className="col-sm-12">
                        <Field
                            name={page.name + ">>" + item.name}
                            fullWidth={item.type === "text"}
                            multiLine={item.type === "text"}
                            rows={4}
                            component={TextField}
                            hintText={`Type your ${item.type == "email" ? 'email' : 'answer'} here.`}/>
                    </div>}
                    {(item.type == "multvert") && <div className="col-sm-12">
                        <Field name={page.name + ">>" + item.name} component={RadioButtonGroup}>
                            {choices}
                        </Field>
                    </div>}
                    {(item.type == "multhorz") && <div>
                        <div className="col-sm-1">{item.leftText}</div>
                        <div className="col-sm-10">
                            <Field name={page.name + ">>" + item.name} component={RadioButtonGroup}>
                                {choices}
                            </Field>
                        </div>
                        <div className="col-sm-1">{item.rightText}</div>
                    </div>}
                    {(item.type == "allthatapply") && <div className="col-sm-12">
                        <Field name={page.name + ">>" + item.name + "." + "root"} component="input" type="hidden" />
                        {choices}
                    </div>}
                    {(item.type == "video") && <div className="col-sm-12">
                        <video width="100%" height="100%" controls>
                            <source src={p.videos[item.video].url} type="video/mp4" />
                        Your browser does not support HTML5 video
                        </video>
                    </div>}
                </div>
            )
            cols.push(<div key={p.pageID + "_item_" + i} className={"coaching-col col-sm-" + (layoutMap[layout])}>{itemContent}</div>)
        };

        

        const previous = !p.index ? null : (
            <div>
                <RaisedButton
                        secondary
                        label="Previous"
                        onClick={p.previousPage}
                        style={{ width: "100%", marginTop: "15px" }} />
            </div>
        )

        console.log("Need next button to be enabled", this.props.pristine, this.props.valid);

        const next = (
                        <div>
                            <RaisedButton
                                primary
                                disabled={ !this.props.valid}
                                label={p.last ? "Submit" : "Next"}
                                type="submit"
                                style={{ width: "100%", marginTop: "15px" }} />
                        </div>
                    )

        

        return (
            <form 
                className="coaching-form"
                onSubmit={p.last ? p.onSubmit : p.nextPage}>
                <div className="row">
                    {cols}
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        {previous}
                    </div>
                    <div className="col-sm-6">
                        {next}
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
CoachingWizardPage = reduxForm({
  form: 'coaching', // a unique name for this form
  destroyOnUnmount: false,
  validate
})(CoachingWizardPage);


export default CoachingWizardPage;