import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import Markdown from "react-markdown";
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';

const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const improvementOpportunity = {
    comments: {
        low: "Great job! Remember that you can always improve.",
        medium: "You’re almost there but you still have some room to improve.",
        high: "You now know exactly what you need to focus on in your practice or daily driving."   
    },
    level: {
        low: "Low",
        medium: "Medium",
        high: "High"
    }
};

const overallResults = {
    headers: {
        grade: "Your Overall Grade",
        improvement_opp: "Your Overall Improvement Opportunity",
        compare: "How Do You Compare to Other Drivers?"
    },
    driverType: {
        new: "new",
        experienced: "experienced"
    }
};

const domain = {
    headers: {
        focus_area: "Your Focus Area",
        definition: "Definition",
        letter_grade: "Your Letter Grade",
        improvement_opp: "Your Improvement Opportunity",
        comments: "Comments"
    },
    domain_1: {
        title: "Speed Management",
        subtitle: "Safely maintaining appropriate vehicle speeds based on environmental conditions"
    },
    domain_2: {
        title: "Road Positioning",
        subtitle: "Safely maintaining vehicle position on the road (e.g., lane management)"
    }, 
    domain_3: {
        title: "Gap Selection",
        subtitle: "Ability to maintain safe distances with other vehicles (e.g., while following)"
    }, 
    domain_4: {
        title: "Blind Spot Management",
        subtitle: "Correctly recognizing and responding to blind spot areas"
    }, 
    domain_5: {
        title: "Hazard Anticipation and Response",
        subtitle: "Correctly scanning for and recognizing potential dangers and risks while driving"
    },
    domain_6: {
        title: "Attention Maintenance",
        subtitle: "Being aware of your surroundings and correctly paying attention to navigational course"
    }, 
    domain_7: {
        title: "Communication and Right of Way",
        subtitle: "Correctly recognizing appropriate right-of-way rules on the roadway"
    },
    domain_8: {
        title: "Vehicle Control",
        subtitle: "Safely controlling the vehicle"
    }
};

function getFeedbackReport(aid) {
    return firebase.functions()
        .httpsCallable('getFeedbackReport')({ aid })
        .catch((err) => {
            console.log("Error retrieving feedback report", err);
        });
}

function printDate(d) {
    return d ? `${MONTH_NAMES[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}` : "";
}

function getImprovementLevel(level) {
    const levelShorthand = improvementOpportunity.level;
    const levelComments = improvementOpportunity.comments;

    switch (level) {
        case 0:
            return {
                message: levelShorthand.low,
                comments: levelComments.low,
                color: 'teal'
            };
        case 1:
            return {
                message: levelShorthand.medium,
                comments: levelComments.medium,
                color: 'orange'
            };
        case 2:
            return {
                message: levelShorthand.high,
                comments: levelComments.high,
                color: 'purple'
            };
        default:
            return null;
    }
}

const TemplateTable = (props) => {
    const header = Object.keys(props.data[0]).map((val) => <th key={val}>{val}</th>);
    const bodyRows = [];

    for (let i = 0; i < props.data.length; i++) {
        const row = props.data[i];
        
        const cells = Object.values(row).map((val) => {
            return <td key={val}>{val}</td>;
        });
          
        bodyRows.push(<tr key={i}>{cells}</tr>);
    }
    
    return (
        <table id="data-template-table" className="center border">
            <thead>
            <tr>
                {header}
            </tr>
            </thead>
            <tbody>
                {bodyRows}
            </tbody>
        </table>
    )
};

const DomainResultsTable = (props) => {
    const table = [];
    const headers = domain.headers;

    for (const domain of props.data) {
        table.push({
            [headers.focus_area]: domain.title,
            [headers.definition]: domain.subtitle,
            [headers.letter_grade]: domain.grade,
            [headers.improvement_opp]: getImprovementLevel(domain.level),
            [headers.comments]: domain.comments
        });
    }
    
    for (const result of table) {
       const level = result[headers.improvement_opp];
       result[headers.improvement_opp] = <strong className={level.color}>{level.message}</strong>;
    }

    console.log('DRT', table);
    return (
        <TemplateTable data={table}/>
    )
};

const OverallResultsTable = (props) => {
    const data = props.data;
    const driverScore = data.score ? 100 - data.score.driverScore : '';

    const overallLevel = getImprovementLevel(data.level);
    const table = [{
        [overallResults.headers.grade]: data.grade,
        [overallResults.headers.improvement_opp]: overallLevel ? <strong className={overallLevel.color}>{overallLevel.message}</strong> : '',
        [overallResults.headers.compare]: `Of all the drivers who completed the virtual driving test, ${driverScore}% drove the virtual driving test safer than you did.`
    }];

    console.log(data);
    console.log(table);
    console.log('ORT');
    return (
        <TemplateTable data={table}/>
    )
};

class Feedback extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            feedbackReport: null
        };

        // document.body.style.backgroundColor = "#FFF";
    }

    componentDidMount() {
        const cmp = this;

        if (this.props.params.aid) {
            store.dispatch( actions.startListeningToAssessment( this.props.params.aid ) );
            store.dispatch( actions.fetchLockedAssessments( ) );
            store.dispatch( actions.startListeningToContent() );

            getFeedbackReport(this.props.params.aid).then((result) => {
                cmp.setState({ feedbackReport: result.data });
            });
        }
    }

    render () {
        const p = this.props;
        const assessmentId = p.params.aid;

        let header = '';
        let content = <div className="magic-load">Loading Feedback Report&#8230;</div>
        let results = '';

        if (!assessmentId) {
            content = <Paper className="feedback-paper">
                { "Something went wrong!" + 
                " Are you trying to view your assessment feedback report?" + 
                " If so, please contact Diagnostic Driving support for assistance." }
            </Paper>
        }

        if (this.state.feedbackReport && !p.content.empty) {
            console.log('feedbackReport Data:', this.state.feedbackReport);
            const report = this.state.feedbackReport;
            const domainResults = report.performance.domains;

            header = <div className="row feedback-header">
                <div className="col-sm-6 "><img src={report.logoUrl} style={ {width: "230px"} } /></div>
            </div>

            if (domainResults && !domainResults.didScoreError && domainResults.results && domainResults.results.length) {
                results = <Paper className="feedback-paper">
                    <div>
                        <h4 className="feedback-hdr display-4">Your Results From The Virtual Driving Test</h4>
                        <h4 className="feedback-obsv-hdr display-6">Background:</h4>
                        <p>{`You completed a virtual driving test incorporating variations of the most common scenarios that have been identified to result in collisions and serious injury for ${domainResults.scoreName} drivers.`}</p>
                        <h4 className="feedback-obsv-hdr display-6">Your focus area results:</h4>
                        <p>Your performance in the virtual driving test was measured across <strong>8 focus areas of driving</strong>. For each focus area, you will see a definition, your letter grade, your improvement opportunity and comments.</p>
                        <DomainResultsTable data={domainResults.results}/>
                        <h4 className="feedback-obsv-hdr display-6">Your Overall Results:</h4>
                        <OverallResultsTable data={report.performance.overall}/>
                    </div>
                </Paper>
            } else {
                results = <Paper className="feedback-paper">
                    <div>
                        <h4 className="feedback-obsv-hdr display-6">There was an error retrieving the results.</h4>
                        <p>Refresh the page to try again. If the issue persists contact Diagnostic Driving for additional support.</p>
                    </div>
                </Paper>
            }

            content = <div>
                <Paper className="feedback-paper">
                    <div className="feedback-date">{printDate(new Date(report.dateOfAssessment))}</div>
                    <div className="feedback-preamble">
                        <Markdown source={report.preamble.markdown} />
                    </div>
                </Paper>
                {results}
            </div>
        }

        if (p.assessments.lockedLoaded && p.assessments.locked[assessmentId]) {
            content = <Paper className="feedback-paper">
                { "Feedback reports are available for 48 hours." +
                " Access to assessment with ID: " + assessmentId + " has expired." +
                " Please contact Diagnostic Driving support (with that ID) to inquire about accessing your results." }
            </Paper>
        }

        return (
                <div>
                    {header}
                    {content}
                </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        assessments: appState.assessments,
        feedback: appState.feedback,
        util: appState.util,
        content: appState.content
    }
}

const mapDispatchToProps = function(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
