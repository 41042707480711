import TextField from 'material-ui/TextField';
import React, { Component, PropTypes } from 'react';
import * as validators from '../../utils/validators';

class TextInput extends Component {
    constructor(props, context) {
        super(props, context);
        this.displayName = 'TextInput';
        this.state = {
            errors: []
        }
    }

    updateValue(value) {
        this.context.update(this.props.name, value);

        if (this.state.errors.length) {
            setTimeout(() => this.isValid(true), 0);
        }
    }

    isValid(showErrors) {
        const errors = this.props.validate
            .reduce((memo, currentName) => 
                memo.concat(validators[currentName](
                    this.props.name,
                    this.context.values
                )), []);

        if (showErrors) {
            this.setState({
                errors
            });
        }

        return !errors.length;
    }

    componentWillMount() {
        this.removeValidationFromContext = this.context.registerValidation(show => 
            this.isValid(show));
        if (this.props.defaultValue) {
            this.updateValue(this.props.defaultValue)
        }
    }

    componentWillUnmount() {
        this.removeValidationFromContext();
    }

    onBlur() {
        this.isValid(true);
    }

    onChange(event) {
        this.updateValue(event.target.value)
    }

    render () {
        return (
          <div>
            <TextField
                hintText={this.props.placeholder}
                fullWidth = { true } // TODO: pass this as a prop to TextInput
                type={this.props.type}
                floatingLabelText={this.props.label} 
                value={this.props.defaultValue ? this.props.defaultValue : this.context.values[this.props.name]}
                disabled={!!this.props.defaultValue}
                onChange={this.onChange.bind(this)}
                onBlur={this.onBlur.bind(this)}
                errorText={this.state.errors.length ? (
                    <div>
                      {this.state.errors.map((error, i) => <div key={i}>{error}</div>)}
                    </div>
                ) : null} />
          </div>
        );
    }
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    validate: PropTypes.arrayOf(PropTypes.string)
}

TextInput.defaultProps = {
    validate: []
}

TextInput.contextTypes = {
    update: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    registerValidation: PropTypes.func.isRequired
}

export default TextInput;