
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleAsset from './SimpleAsset';




class AssetViewer extends Component {

    componentWillMount () {
        
    }

    render () {
        const p = this.props;
        const assetId = p.params.assetId;
        
        return (
            <SimpleAsset assetId={assetId} />
        )
    }
}

const mapStateToProps = function (appState) {
    return {

    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetViewer);
