import PlaceIcon from 'material-ui/svg-icons/maps/place';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Map0 from "../assets/images/map0.png";
import Map1 from "../assets/images/map1.png";
import Map2 from "../assets/images/map2.png";
import Map3 from "../assets/images/map3.png";
import Map4 from "../assets/images/map4.png";

const mapWidth = 2184;
const mapHeight = 898;
const downsizer = 1.5;

const mapMap = {
    "map_0" : Map0,
    "map_1" : Map1,
    "map_2" : Map2,
    "map_3" : Map3,
    "map_4" : Map4
}

function addPoint(points, point, addSome) {
    const coords = point.split(",");
    let x = parseFloat(coords[0]) / downsizer;
    let y = parseFloat(coords[1]) / downsizer;
    x = Math.round((mapHeight / 2) + x);
    y = Math.round((mapWidth / 2) + y);
    //On our current view - x is y and y is x (map is a portrait)
    x = x - 64;
    y = y - 32;
    if (addSome) {
        x = x + 4;
        y = y + 4;
    }
    // subtract 24 from the top
    // subtract 12 from the left
    // make different colors
    points.push(<PlaceIcon key={point + (addSome ? "-x" : "")} className={"map-pointer map-pointer-" + (points.length + 1)} style={{ top: x + "px", left: y + "px"}} />)
}

class Map extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {
        const prms = this.props.params;
        
        let points = [];
        if (prms) {
            if (prms.point1) {
                addPoint(points, prms.point1, false);
            }
            if (prms.point2) {
                addPoint(points, prms.point2, prms.point1 == prms.point2);
            }
        }



        return (
            <div className="map-container">
                {points}
                {prms ? <img src={mapMap[prms.whichmap]} className="map-img" /> : null }
            </div> 
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}

Map.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Map);