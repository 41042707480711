
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as hlp from '../../utils/helpers';
import { C } from '../../constants';


class ReadyAssessment extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    trackDownloadFeedback () {
        C.GA.logEvent('download_feedback_v2', {aid: this.props.aid});
    }

    render () {


        const p = this.props;
        const aData = p.data;
        const aid = p.aid;
        const now = Date.now() + p.util.offset; // server time
        let end = aData.hasOwnProperty('end') ? new Date(aData.end).toLocaleString() : hlp.determineEndTime(now, aData.lastUpdate);
        let statusClass = end ? "ra-completed" : "ra-in-progress";
        if (!aData.hasOwnProperty('lastUpdate')) {
            statusClass = "ra-completed";
        }

        return <div className={"ready-assessment " + statusClass}>

            <div className="row">
                <div className="col-sm-8" style={{paddingTop: "8px"}}>{end ? "Completed: " + end : "Assessment In Progress"}</div>
                <div className="col-sm-4" style={{textAlign: "right"}}>{end ? <span><a onClick={this.trackDownloadFeedback.bind(this)} style={{width: "auto", padding: "6px 30px"}} className="ra-homepage-hero-link" href={"/get/pdf/" + aid} target="_blank">Get Feedback Report</a></span> : null}</div>
            </div>

        </div>

        
        
        
    }
}

const mapStateToProps = function (appState) {
    return {
        util: appState.util,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReadyAssessment);