
import _ from 'lodash';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
// import ContentCopy from 'material-ui/svg-icons/content/content-copy';
// import Create from 'material-ui/svg-icons/content/create';
import MoveUp from 'material-ui/svg-icons/action/open-in-browser';
import React, { Component } from 'react';
import Markdown from "react-markdown";
import { connect } from 'react-redux';
import actions from '../actions';
import C from '../constants';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddQuestionForm from './components/addquestionform';


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToQuestions() );
});

class IntakeAdmin extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount IntakeAdmin");
        startListening();
    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;
        const refs = this.refs;

        console.log("rendering intake admin page");

        const questions = p.questions;

        const questionRows = _.map(p.questions.data, (q, qid) => {
            return <div key={qid} className="row q-row">
                <div className="col-sm-2">{q.name}</div>
                <div className="col-sm-3">{C.QUESTION_TYPES[q.type]}</div>
                <div className="col-sm-5"><Markdown source={q.text} /></div>
                <div className="col-sm-1">{q.required ? "yes" : "no"}</div>
                <div className="col-sm-1">
                    <IconButton onClick={ function () { p.deleteQuestion(qid) }} tooltip="Delete Question">
                        <Delete className="q-trash" />
                    </IconButton>
                    {/*
                    <IconButton disabled={true} tooltip="Edit Question">
                        <Create className="q-edit" />
                    </IconButton>
                    */}   
                    <IconButton onClick={ function () { p.load(qid); }} tooltip="Copy into form">
                        <MoveUp className="q-dupe" />
                    </IconButton>
                </div>
            </div>
        })

        const rows = <div>
            {p.questions.hasReceivedQuestionData ? (
                <div className="q-rows">
                    <div className="row q-row">
                        <div className="col-sm-2">Name</div>
                        <div className="col-sm-3">Type</div>
                        <div className="col-sm-5">Text</div>
                        <div className="col-sm-1">Required?</div>
                        <div className="col-sm-1">Delete</div>
                    </div>
                    {questionRows}
                </div>
            ) : "Loading questions..."}
        </div>

        const noQs = <div> No questions exist </div>
        

        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <AddQuestionForm
                            className="add-question-form"
                            onSubmit={(function (e) {e.preventDefault(); this.props.addQuestion(); }).bind(this) }>
                        </AddQuestionForm>


                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        { questionRows.length ? rows : noQs }
                    </div>
                </div>
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        questions: appState.questions
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addQuestion: function () {
            dispatch( actions.addQuestion() )
        },
        deleteQuestion: function (qid) {
            dispatch( actions.deleteQuestion( qid ) )
        },
        load: function(qid) { dispatch(actions.dupeQuestion(qid)); } 
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(IntakeAdmin);
