/*
This is our top-level component. Sub-components matching specific routes will be
contained in `this.props.children` and rendered out.
*/

import Snackbar from 'material-ui/Snackbar';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { C } from '../constants';
import store from '../store';
import * as perms from "../utils/permission";
import Header from './components/Header';
import InviteLink from './components/invitelink';
import Spinner from './components/spinner';
import ReadyAssessHome from './readyassesshome';
import CourtCompliance from './courtcompliance';


/*
Have this wrapper listen for auth -- show a spinner if awaiting auth info
need to know username and role to proceed

*/

const paperStyle = {
    margin: "30px 50px"
}

class HomeWrapper extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            verificationReminderOpen: false,
            menuOn: true
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({
            verificationReminderOpen : !nextProps.auth.emailVerified && !nextProps.auth.resentVerification
        })
    }

    handleRequestClose () {

        this.setState({
            verificationReminderOpen: false,
        });
    };

    handleReSendClick () {
        this.props.reSendVerificationEmail();
    }

    render () {
        const SecureInviteLink = perms.manager(InviteLink);
        const auth = this.props.auth;
        const isDxdDevWeb = __DXD_DEV_WEB__ || process.env.NODE_ENV === 'development';
        const loggedIn = auth.currently == C.LOGGED_IN;
        const anon = auth.currently == C.ANONYMOUS;
        const isReady = loggedIn && auth.orgName;
        if (!isReady) {
            if (!anon) {
                return  <div className="magic-load">Loading&#8230;</div>
            }
        }

        const isReadyAssessHome = auth.featureFlags && auth.featureFlags.READY_ASSESS_HOME;
        const isCourtCompliance = auth.featureFlags && auth.featureFlags.COURT_COMPLIANCE;
        const isOnlyCpt = auth.featureFlags && auth.featureFlags.ONLY_CPT;
        const isDriver = auth.gid == 'driver';
        const superFakingDriver = auth.fakeDriver;

        const paymentResult = this.props.params.success;
        const paymentToken = this.props.params.token;

        if ((isDriver || superFakingDriver) && isReadyAssessHome) {
            return <ReadyAssessHome>{this.props.children}</ReadyAssessHome>
        }

        if ((isDriver || superFakingDriver) && isCourtCompliance) {
            return <CourtCompliance passedParams={this.props.params}>{this.props.children}</CourtCompliance>
        }

        let topBar = (
             <div className="topbar-wrapper">

                <div className="display-5" style={{position: 'absolute', top: 0, left: 0, paddingLeft: 20}}>{this.props.util.title} {this.props.util.subtitle}</div>
                <div className="topbar-wrapper-mobile-nav">
                    <a href="#" onClick={(function (e) {e.preventDefault(); console.log("minibar!"); store.dispatch( actions.toggleMobileNav() );}).bind(this) } className="topbar-togger js-minibar-toggler">
                        <i className="icon ion-ios-arrow-back hidden-lg-up minibar-show"></i>
                        <i className="icon ion-navicon-round hidden-lg-up minibar-hide"></i>
                    </a>
                    <a href="#" onClick={this.props.logout} className="topbar-togger pull-xs-right hidden-lg-up js-nav-toggler sign-out">
                        <i className="icon ion-log-out"> Sign Out</i>
                    </a>
                </div>
                    

                <div className="topbar-wrapper-search">
                    <form>
                        <input type="search" placeholder="Search all assessments..." className="form-control" /><a href="#" className="topbar-togger topbar-togger-search js-close-search"><i className="icon ion-close"></i></a>
                    </form>
                </div>

                <ul className="nav navbar-nav topbar-wrapper-nav">

                    <SecureInviteLink />

                    
                    <li className="nav-item hidden"><a href="#" className="nav-link js-search-togger"><i className="icon ion-ios-search-strong"></i></a></li>
                    <li className="nav-item dropdown hidden">
                        
                    </li>
                    <li className="nav-item"><a href="#" onClick={this.props.logout} className="nav-link"><i className="icon ion-log-out"></i></a></li>
                </ul>
            </div>
        )

        if (__ODPS__) {
            lightBaseTheme.palette.primary1Color = "#3776BB";
            lightBaseTheme.palette.accent1Color = "#8E031A";
            topBar = (
                <div className="topbar-wrapper">
                    <ul className="nav navbar-nav topbar-wrapper-nav">
                        <li className="nav-item"><span className="nav-link">Hello, {auth.username}</span></li>
                        <li className="nav-item"><a href="#" onClick={this.props.logout} className="nav-link">Log Out</a></li>
                    </ul>
                </div>
            )
        } else {
            lightBaseTheme.palette.primary1Color = "#3B125D";
            lightBaseTheme.palette.accent1Color = "#DE8931";
        }
        const lightMuiTheme = getMuiTheme(lightBaseTheme);

        if (auth.currently == C.UNINITIATED && !auth.orgAbrv && !auth.scoringMethod) {
            return (<MuiThemeProvider muiTheme={lightMuiTheme}><Spinner /></MuiThemeProvider>)
        }
        return (
            <MuiThemeProvider muiTheme={lightMuiTheme}>
                <div className={ `org-${__ODPS__ ? "ODPS" : "other"}${isDxdDevWeb ? "-dev" : ""}`}>
                    <Header />

                    <div className="main-wrapper">

                        <div className="view-wrapper">

                            {topBar}


                            <div className="layout">
                                {this.props.children}
                            </div>

                        </div>

                    </div>
                    <Snackbar
                        open={this.state.verificationReminderOpen}
                        message="Your email address is unverified"
                        action="Re-send Verification e-mail"
                        autoHideDuration={300000}
                        onActionTouchTap={this.handleReSendClick.bind(this)}
                        onRequestClose={this.handleRequestClose.bind(this)} />
                </div>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        util: appState.util
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        logout: function() { dispatch(actions.logoutUser()); },
        reSendVerificationEmail: function() { dispatch( actions.reSendVerificationEmail() ); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeWrapper);
