import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import { withRouter } from 'react-router';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

function getLast(member) {
    if (member.assessments) {
        let when = 'n/a';
        _.map(member.assessments, (timestamp, aid) => {
            when = timestamp;
        })
        return new Date(when).toLocaleString();
    }
    return 'n/a';
}

function nothingOrNa(d) {
    return !d || d == 'n/a';
}

class OrgMembers extends Component {

    handleCellClick(cellData) {
        let userId = cellData.data.id;
        if (userId && cellData.colDef.field == 'id') {
            this.props.router.push('/manageuser/' + userId);
        }

    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        const p = this.props;

        let content = <div>Loading user data...</div>;

        if (p.users.hasReceivedUserData) {
            let colHeaders = [
                {
                    field: 'when',
                    headerName: 'Date Added',
                    headerTooltip: 'Date Added',
                    sortable: true,
                    filter: true,
                    resizable: true,
                    width:  200,
                    comparator: function (date1, date2) {
                
                        if (nothingOrNa(date1) && nothingOrNa(date2)) {
                            return 0;
                        }
                        if (nothingOrNa(date1)) {
                            return -1;
                        }
                        if (nothingOrNa(date2)) {
                            return 1;
                        }

                        let date1Obj = new Date(parseInt(date1.split('/')[2]), parseInt(date1.split('/')[0]) - 1, parseInt(date1.split('/')[1]), 0, 0, 0).getTime();
                        let date2Obj = new Date(parseInt(date2.split('/')[2]), parseInt(date2.split('/')[0]) - 1, parseInt(date2.split('/')[1]), 0, 0, 0).getTime();

                        return date1Obj - date2Obj;
                    }
                },
                {
                    field: 'who',
                    headerName: 'Name',
                    headerTooltip: 'first and last name',
                    sortable: true,
                    filter: true,
                    resizable: true,
                    width:  200
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    headerTooltip: "user's email and username provided for registration",
                    sortable: true,
                    filter: true,
                    resizable: true,
                    width:  200
                },
                {
                    field: 'last',
                    headerName: 'Last Assessment',
                    headerTooltip: "User's last assessment date/time if any",
                    sortable: true,
                    filter: true,
                    resizable: true,
                    width:  200,
                    comparator: function (date1, date2) {
                
                        if (nothingOrNa(date1) && nothingOrNa(date2)) {
                            return 0;
                        }
                        if (nothingOrNa(date1)) {
                            return -1;
                        }
                        if (nothingOrNa(date2)) {
                            return 1;
                        }
                        // console.log('_____');
                        // console.log(date1, date2);
                        let date1Obj = new Date(parseInt(date1.split('/')[2]), parseInt(date1.split('/')[0]) - 1, parseInt(date1.split('/')[1]), 0, 0, 0).getTime();
                        let date2Obj = new Date(parseInt(date2.split('/')[2]), parseInt(date2.split('/')[0]) - 1, parseInt(date2.split('/')[1]), 0, 0, 0).getTime();
                        // console.log(date1Obj, date2Obj);
                        return date1Obj - date2Obj;
                    }
                },
                {
                    field: 'id',
                    headerName: 'User Id',
                    headerTooltip: "Firebase id for this user, click to go to user management page",
                    sortable: true,
                    filter: true,
                    resizable: true,
                    width:  200
                }
            ]
            let rowData = [];
            _.map(p.org.members, (member, key) => {
                if (key) {
                    let user = p.users.data[key];
                    if (user) {
                        rowData.push({
                            when: new Date(user.dateAdded).toLocaleString(),
                            who: member,
                            email: user.email,
                            last: getLast(user),
                            id: key
                        })
                        return;
                    }
                }
                
                return;
                
            })
            content =   <div 
                    className="ag-theme-balham"
                    style={{ 
                        height: '700px', 
                        width: '100%' }} 
                >
                    <AgGridReact
                        modules={AllCommunityModules}
                        reactNext={true}
                        columnDefs={colHeaders}
                        pagination={true}
                        onGridReady={this.onGridReady.bind(this)}
                        rowData={rowData}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onCellClicked = {(function(cellData) {this.handleCellClick(cellData)}).bind(this)}
                        enableBrowserTooltips={true}
                        >

                        
                    </AgGridReact>
                </div>
        }



        return (
            <div>
                {content}
            </div>                
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        users: appState.users
    }
}

const mapDispatchToProps = function(dispatch) {
    return {

    }
}

OrgMembers = withRouter(OrgMembers);

export default connect(mapStateToProps, mapDispatchToProps)(OrgMembers);

