import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { C } from '../constants';

const dRef = C.APP.database().ref('documents');
const storageRef = firebase.storage().ref();

export const startListeningToDocuments = function () {
    return function (dispatch, getState) {
        console.log('*************************** loading docs');
        dRef.on('value', (snap) => {
            console.log('0o0o0o0o0o0o0o0o0o0o0oOoOOooooOOOOooooo>>>> docs loaded -- RECEIVE_DOCUMENTS_DATA');
            dispatch({ type: C.RECEIVE_DOCUMENTS_DATA, data: snap.val()});
        });
    }
}

export const uploadDocument = function () {
    return function (dispatch, getState) {
        // upload the video
        // modify upload state as it loads
        // add an entry to the "videos" db node
        // title - ref (add tags?)
        const formValues = getState().form.document.values;
        const docFile = formValues.docfile[0];
        const ext = docFile.name.substr(docFile.name.lastIndexOf('.')+1);

        const title = formValues.title;
        const description = formValues.description;

        // const docKey = dRef.push().key;
        const docStorageRef = storageRef.child('documents/' + docFile.name);
        dispatch({type: C.AWAIT_NEW_DOCUMENT_RESPONSE});

        var uploadTask = docStorageRef.put(docFile);

        uploadTask.on('state_changed', (snapshot) => {

          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            dispatch({type: C.SET_DOCUMENT_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
            }, (error) => {
                // Handle unsuccessful uploads
                dispatch({type: C.RECEIVE_DOC_UPLOAD_ERROR, error: "An error occurred in the upload process.", 
                    transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
            }, () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                console.log(uploadTask.snapshot);
                uploadTask.snapshot.ref.getDownloadURL().then(dlUrl => {
                    console.log("||||||||||||||| document uploaded successfully |||||||||||||||");
                    console.log(dlUrl);
                    let val = {};
                    dRef.push({
                        downloadUrl : dlUrl,
                        title,
                        description,
                        filename: docFile.name
                    }).then(() => {
                        dispatch({type: C.RECEIVE_NEW_DOCUMENT_RESPONSE});
                        console.log("set doc ref and values in firebase db");
                    }).catch(error => {
                        dispatch({type: C.RECEIVE_DOCUMENT_UPLOAD_ERROR, error: "An error occurred in saving document to firebase."});
                        console.log("error")
                    });
                });
                
        });        
    }
}

export const deleteDocument = function (docId) {
    return function (dispatch, getState) {
        dispatch({type: C.START_DELETE_DOCUMENT, docId});
        dRef.child(docId).once('value').then(snap => {
            let document = snap.val();
            if (document) {
                storage.child('documents').child(document.filename).delete().then(() => {
                    dRef.child(docId).set(null).then(() => {
                        console.log("deleted " + document.filename);
                        dispatch({type: C.END_DELETE_DOCUMENT, docId});
                    })
                })
            }
        })
    }
}
