import { C } from '../constants';

const groupsRef = C.APP.database().ref('groups');

export const startListeningToGroups = function () {
    return function (dispatch, getState) {
        groupsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_GROUPS_DATA, data: snap.val()});
        });
    }
}

export const initializeGroups = function () {
    return function (dispatch, getState) {
        for (var key of Object.keys(C.INIT_GROUPS)) {
            groupsRef.child(key).set(C.INIT_GROUPS[key]).then((snap) => {
                // group should auto-pop
            }).catch((error) => {
                //TODO: handle error
            } );
        }
    }
};

export const submitNewGroup = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.values;
        const key = formValues.key;
        const name = formValues.name;
        const members = {};
        const description = formValues.description;
        dispatch({type: C.AWAIT_NEW_GROUP_RESPONSE});
        groupsRef(key).set({name, description, members}).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_GROUP_RESPONSE});
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_GROUP_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add group!"}) //TODO: better errors
        } )
    }
}