import React, { Component } from 'react';

class Loading extends Component {
    render () {

        return (
            <div className="magic-load">Loading content&#8230;</div>
        )
    }
}

export default Loading;
