import React, { Component } from 'react';
import { connect } from 'react-redux';

import RAImg from "../assets/images/Ready-Assess-Logo.png";
import CHOPNJM from "../assets/images/chopnjm.png";
import ODPS from "../assets/images/odps.png";
import HEALTHAFFAIRS from "../assets/images/healthAffairs.png";
import DXDPHL from "../assets/images/dxdphl.png";
import MANITOBA from "../assets/images/manitoba.png";

import { C } from '../constants';
import Share from "../assets/images/share.png";
import LoadingDots from "../assets/images/745.png";
import Dialog from 'material-ui/Dialog';


class ReadyPress extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        }
    }

    trackClickLogin () {
        C.GA.logEvent('ra_click_login_v2');
    }

    trackClickRegister () {
        C.GA.logEvent('ra_click_register_v2');
    }

    handleOpen () {
        this.setState({open: true});
    }


    handleClose () {
        this.setState({open: false});
    }

    componentWillMount () {
        document.body.style.background = "#FFF";
    }

    componentDidMount () {
        C.GA.logEvent('ra_view_press');
    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    handleCopyShare (e) {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href);
        this.handleClose();
        alert("URL for this page copied to the clipboard");
    }

    handleShare (e) {
        let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
        C.GA.logEvent('ra_share_press');
        if (navigator.share) {
            navigator.share({
                text: window.location.href,
                url: window.location.href,
                title: "Ready Assess™ Self-guided Virtual Driving Test",
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            console.log("Web Share not supported :(");
            this.handleOpen();     
        }
    }

    render () {

    let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
    let loggedIn = this.props.auth && this.props.auth.uid;
    let uninitiated = this.props.auth && this.props.auth.currently == C.UNINITIATED;

        return (
            <div className="ra-all">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CLexend+Deca:regular%7CLexend+Deca:regular,600" media="all"/>

                <div className="ra-top">
                    <div className="ra-top-inner row">
                        <div className="col-sm-6 ra-top-left">
                            <a href={"/get/ready/" + inviteId}><span className="ra-logo-span"><img className="ra-logo" src={RAImg} alt="Ready Assess Logo"  width="311" /></span></a>
                        </div>
                        <div className="col-sm-6 ra-top-rt">
                            <button onClick={this.handleShare.bind(this)} className="share-button"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                        </div>
                        
                        
                        <div className="clrbth"></div>
                    </div>
                </div>
                <div className="ra-section ra-dash-section bg-primary-3">
                        <div className="ra-section-inner row">
                            <div className="col-sm-12 share-hero">
                                <button onClick={this.handleShare.bind(this)} className="share-button shmid"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                            </div>
                            <div className="col-sm-12 clr-lft-pd">
                                <h1 className="press-page-header">Press Room</h1>
                                <div className="press-subheader">Learn more about the different ways that Ready-Assess is helping to fill the roads with safer drivers.</div>
                            </div>
                            
                        </div>
                    </div>
                <div className="ra-section">
                    <div className="ra-section-inner w-layout-grid press-grid-thirds press-grid">
                        <div className="individual-press-item">
                            <a href="https://www.chop.edu/news/children-s-hospital-philadelphia-launches-innovative-virtual-teen-driving-assessment-program-4" target="_blank" className="press-image-container w-inline-block">
                                <img src={CHOPNJM} alt="CHop & NJM Logos" className="press-image" />
                            </a>
                            <a href="https://www.chop.edu/news/children-s-hospital-philadelphia-launches-innovative-virtual-teen-driving-assessment-program-4" target="_blank" className="press-grid-link w-inline-block">
                                <h4 className="press-link-heading">
                                    A Partnership with NJM and the Children’s Hospital of Philadelphia
                                </h4>
                            </a>
                        </div>
                        <div className="individual-press-item">
                            <a href="https://www.google.com/url?q=https://injury.research.chop.edu/sites/default/files/documents/ohio_pdss_research_brief_final.pdf&amp;sa=D&amp;source=editors&amp;ust=1633444962199000&amp;usg=AOvVaw0PbBEA0zA3wtfCsIS3bk59" target="_blank" className="press-image-container w-inline-block">
                                <img src={ODPS} alt="ODPS Logo" className="press-image" />
                            </a>
                            <a href="https://www.google.com/url?q=https://injury.research.chop.edu/sites/default/files/documents/ohio_pdss_research_brief_final.pdf&amp;sa=D&amp;source=editors&amp;ust=1633444962199000&amp;usg=AOvVaw0PbBEA0zA3wtfCsIS3bk59" target="_blank" className="press-grid-link w-inline-block">
                                <h4 className="press-link-heading">
                                    Details of our work -- driver’s licensing and the State of Ohio
                                </h4>
                            </a>
                        </div>
                        <div className="individual-press-item">
                            <a href="https://www.google.com/url?q=https://www.chop.edu/news/virtual-driving-assessment-shows-feasibility-validity-and-efficiency-part-licensing-process&amp;sa=D&amp;source=editors&amp;ust=1633444962198000&amp;usg=AOvVaw0gEBW6k-B9ilYBlcwwzp-d" target="_blank" className="press-image-container w-inline-block">
                                <img src={HEALTHAFFAIRS} alt="Health Affairs Logo" className="press-image" />
                            </a>
                            <a href="https://www.google.com/url?q=https://www.chop.edu/news/virtual-driving-assessment-shows-feasibility-validity-and-efficiency-part-licensing-process&amp;sa=D&amp;source=editors&amp;ust=1633444962198000&amp;usg=AOvVaw0gEBW6k-B9ilYBlcwwzp-d" target="_blank" className="press-grid-link w-inline-block">
                                <h4 className="press-link-heading">
                                    A new paper in Health Affairs describing our partnership with the State of Ohio
                                </h4>
                            </a>
                        </div>
                        <div className="individual-press-item">
                            <a href="https://www.phillymag.com/business/2016/11/17/diagnostic-driving-startup-chop/" target="_blank" className="press-image-container w-inline-block">
                                <img src={DXDPHL} alt="DXD Philly Mag" className="press-image" />
                            </a>
                            <a href="https://www.phillymag.com/business/2016/11/17/diagnostic-driving-startup-chop/" target="_blank" className="press-grid-link w-inline-block">
                                <h4 className="press-link-heading">
                                    Diagnostic Driving named startup of the week
                                </h4>
                            </a>
                        </div>
                        <div className="individual-press-item">
                            <img src={MANITOBA} alt="Manitoba Public Insurance Logo" className="press-image" />
                            <h4 className="press-link-heading no-link">
                                {"Diagnostic Driving launches partnership with Manitoba Public Insurance Corporation to use Ready-Assess™ in their driver's licensing workflow"}
                            </h4>
                        </div>
                    </div>
                </div>

                <div style={{height:"62px"}}></div>
                <div className="ra-announcement-bar">
                    {
                        uninitiated ?

                        <img className="loading-dots" src={LoadingDots} alt="Loading Dots"  />

                        :

                        loggedIn ? 
                        
                        <a href={"/"} className="link-3">Go to Dashboard</a>

                        : 

                        <div>


                            <div  className="ftr-col"><a onClick={this.trackClickLogin.bind(this)} className="ftr-link" href={"/login"}>Login</a></div>
                            <div  className="ftr-col"><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-shrt">Register</a><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-lng">Register for a free account!</a></div>
                            <div  className="ftr-col"><button onClick={this.handleShare.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="18" /></button></div>
                        </div>
                    }
                </div>
                <Dialog
                    actions={[<button onClick={this.handleClose.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">Close</span></button>]}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose.bind(this)}>

                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}  target="_blank" className="share-link"><i className={"icon share-icon ion-social-facebook"}></i></a>
                    <a href={"http://twitter.com/share?text=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills." + "&url=" + window.location.href + "&hashtags=" + ['safedriving', 'drivesafe']} className="share-link" target="_blank"><i className={"icon share-icon ion-social-twitter"}></i></a>
                    <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href} target="_blank" className="share-link"><i className={"icon share-icon ion-social-linkedin"}></i></a>
                    <a href={"http://pinterest.com/pin/create/button/?url=" + window.location.href + "&media=" + "https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c45fc379b131a4baff925_Ready-Assess-Logo.png" + "&description=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-pinterest"}></i></a>
                    <a href={"https://web.whatsapp.com/send?text=" + window.location.href} className="share-link" target="_blank"><i className={"icon share-icon ion-social-whatsapp"}></i></a>
                    <a href={"https://reddit.com/submit?url=" + window.location.href + "&title=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-reddit"}></i></a>
                    <a onClick={this.handleCopyShare.bind(this)} className="share-link"><i className={"icon share-icon ion-ios-copy"}></i></a>
                    <a href={"mailto:?subject=Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills.&body=Go here to register your free account and get started! site: " + window.location.href } target="_blank" className="share-link"><i className={"icon share-icon ion-ios-email"}></i></a>

                </Dialog>
            </div>
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth : appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReadyPress);