import { C } from '../../constants';

const initialState = { 
    data: {}
};

function updateAssignment(state, key, value) {
    let newEntry = {};
    newEntry[key] = value;
    return Object.assign({}, state.data, newEntry);
}


export default (state = initialState, action) => {
    let entry = {};
  switch (action.type) {
    case C.RECEIVE_ASSIGNMENT_DATA:
        return Object.assign({}, state, {
            data: updateAssignment(state, action.key, { hasReceivedData: true, value: action.data})
        });

    case C.AWAIT_ASSIGNMENT_DATA:
        return Object.assign({}, state, {
            data: updateAssignment(state, action.key, { hasReceivedData: false })
        })

      default:
        return state;
  }
}