import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedQuestionData: false,
  submittingnew: false,
  submittingintake: false,
  data: {},
  init: {},
  states: {},
  openIntakeId: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_QUESTIONS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedQuestionData: true
        });

    case C.PREFILL_QUESTION:
        console.log("PREFILL: ", state.data[action.qid]);
        let initData = Object.assign({}, state.data[action.qid])
        initData.shortname = "please_rename";
        if (initData.numberOfChoices) {
          initData.numberofchoices = parseInt(initData.numberOfChoices);
        }
        initData.lefttext = initData.leftText;
        initData.righttext = initData.rightText;
        return Object.assign({}, state, {
            // init is a specific question

            init: initData
        })

    case C.AWAIT_NEW_QUESTION_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_QUESTION_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

    case C.SUBMITTING_INTAKE:
        return Object.assign({}, state, {
            submittingintake: true
        })

    case C.SET_OPEN_INTAKE_ID:
      return Object.assign({}, state, {
          openIntakeId: action.id
      })

    case C.INTAKE_SUBMISSION_RECEIVED:
        return Object.assign({}, state, {
            submittingintake: false
        })

      default:
        return state;
  }
}