
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';

const validate = values => {
    const errors = {}
    const requiredFields = ['moduleFile'];
    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required'
        }
    })
    return errors
}


const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Try dragging/dropping scenario JSON file here, or click to select .json file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class ModuleJsonForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        return (
            <form
                className="cpt-json-form"
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor='assetFile'>Module JSON File</label>
                        <Field
                            name='moduleFile'
                            component={renderDropzoneInput} />
                    </div>
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload Module"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
ModuleJsonForm = reduxForm({
  form: 'moduleJson', // a unique name for this form
  validate
})(ModuleJsonForm);


export default ModuleJsonForm;