import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import Assessment from './components/assessment';

class Results extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    componentWillMount () {
        if (this.props.params && this.props.params.aid) {
            store.dispatch( actions.startListeningToAssessment(this.props.params.aid) );
        }
        
    }

    render () {
        const p = this.props;
        const aid = this.props.params && this.props.params.aid;
        if (!aid) {
            return <div>Error: cannot access this page without an assessment ID</div>
        }

        if (!(p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData)) {
            return <div>Loading...</div>
        }

        console.log(p.assessments.data[aid]);

        return (
            <div>
                <div className="panel">
                    <div className="panel-body">
                        <Paper style={{margin: "20px", padding: "20px", overflow: "hidden"}}>
                            <Assessment data={p.assessments.data[aid].value} aid={aid} key={aid} />
                        </Paper>
                    </div>
                </div>
            </div> );
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        assessments: appState.assessments
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Results);