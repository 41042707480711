import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { SelectField, Slider, TextField } from 'redux-form-material-ui';

const safeName = /^[A-Za-z_][A-Za-z0-9_\-]*$/;

const validate = values => {
    const errors = {}

    if (!values["name"]) {
        errors ["name"] = "Required"
    } else {
        if (!safeName.test(values["name"])) {
            errors[ "name" ] = 'No spaces. Must start with letter or underscore. Can only contain letters, numbers, underscores & dashes';
        }
    }


    if (!values["description"]) {
        errors ["description"] = "Required"
    }

    console.log("what is up with number of items?", values);

    if (!values["numberOfItems"]) {
        console.log("no number of items value", values);
        errors ["numberOfItems"] = "One or more coaching items required"
    } else {
        if (values["numberOfItems"] < 1) {
            console.log("number of items value is less than one", values);
            errors ["numberOfItems"] = "One or more coaching items required"
        } else {
            for (var i = values["numberOfItems"]; i >= 0; i--) {
                if (!values["coaching_item_" + i]) {
                    errors["coaching_item_" + i] = "Required"
                }
                if (!values["layout_" + i]) {
                    errors["layout_" + i] = "Required"
                }
            };
        }
    }

    return errors
}

class AddCoachingPageForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        // store.dispatch( actions.startListeningToOrgs() );
    }

    render () {
        const p = this.props;

        var formToRender;
        let steps = [];


        for (let i = 0; i < p.numberOfItems; i++) {
            console.log("***********adding step", i);
            let stepNumber = "" + (i + 1);
            steps.push(
                //make these steps into step selectors (pull in from DB like we do with videos in VideoForm)
                <div className="q-choice" key={"step_" + i}>
                    <h6 className="choice-header"><b>Item {stepNumber}</b></h6>
                    <div className="intake-choice row">

                        <div className="col-sm-6">
                            <Field
                                name={"coaching_item_" + i}
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText={"Coaching Item " + stepNumber}>
                                {
                                    _.map(p.items.data, (item, key) => {
                                        return <MenuItem value={key} key={key} primaryText={item.name} />
                                    })
                                }
                            </Field>
                        </div>
                        <div className="col-sm-6">
                            <Field
                                name={"layout_" + i}
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText={"Layout " + stepNumber}>
                                    <MenuItem value={"full"} primaryText={"Full Width"} />
                                    <MenuItem value={"threeq"} primaryText={"Three 1/4 Width"} />
                                    <MenuItem value={"half"} primaryText={"Half Width"} />
                                    <MenuItem value={"quarter"} primaryText={"Quarter Width"} />
                            </Field>
                        </div>

                    </div>
                </div>
            )
        }

        formToRender = <Paper style={{ margin: "30px", padding: "25px"}}>
            <form  className={this.props.className} 
                        onSubmit={this.props.onSubmit} >
                    <div>
                        <Field 
                            name="name"
                            component={TextField}
                            fullWidth={true}
                            hintText="Name this coaching page (not user visible)"
                            floatingLabelText="Coaching Page Name"/>
                    </div>
                    <div>
                        <Field 
                            name="description"
                            component={TextField}
                            fullWidth={true}
                            hintText="Briefly describe this page (not user visible)"
                            floatingLabelText="Coaching Page Description"/>
                    </div>

                    <div className="slider-box">
                        <label htmlFor="numberOfItems">{"How many items should this Coaching Page have?"}</label>
                        <div className="ccc-box">{p.numberOfItems}</div>
                        <Field
                            name="numberOfItems"
                            component={Slider}
                            format={function (value, name) {
                                console.log("formatting slider value", value, name);
                                if (value === '') {
                                    return 0
                                }

                                return parseInt(value);
                            }}
                            defaultValue={0}
                            min={0}
                            max={10}
                            step={1}/>
                    </div>

                    {steps}

                    <div className="row">
                        <div className="col-sm-7"></div>
                        <div className="col-sm-5">
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Add Coaching Page"
                            type="submit"
                            fullWidth={true}
                            style={{ width: "20%", marginTop: "15px" }} />
                        </div>
                    </div>
            </form>
            </Paper>;


        return (
            <div>

                    

                {formToRender}


            </div>
            
        )
    }
}

// Decorate the form component
AddCoachingPageForm = reduxForm({
  form: 'coachingpage', // a unique name for this form
  validate
})(AddCoachingPageForm);

//get the input value from createCoachingSurveyForm -- formValueSelector function is from redux-form
const selector = formValueSelector('coachingpage');
AddCoachingPageForm = connect(
  state => {
    // send numSteps to redux state
    const numberOfItems = selector(state, 'numberOfItems')
    return {
      numberOfItems
    }
  }
)(AddCoachingPageForm)

const mapStateToProps = function (appState) {
    return {

    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        //not used atm
        resetForm: function () {
            dispatch(reset('coachingpage'));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCoachingPageForm);