import { C } from '../../constants';

const initialState = {
    empty : true
}

export default (state = initialState, action) => {
    let entry = {};
    switch (action.type) {
        case C.RECEIVE_DRIVER_DATA:
            entry[action.key] = entry[action.key] || {};
            entry[action.key] = action.data;
            entry[action.key].loading = false;
            return Object.assign({}, state, entry);

        case C.AWAIT_NEW_DRIVER_DATA:
            entry[action.key] = {};
            entry[action.key].loading = true;
            entry.empty = false;
            return Object.assign({}, state, entry);

        case C.UPDATE_DRIVERS_DRIVE_DATA:
            let newAssessmentEntry = {};
            newAssessmentEntry[action.aid] = action.data;
            entry[action.data.user] = Object.assign({}, 
                state[action.data.user], 
                {
                    assessments: Object.assign({}, state[action.data.user].assessments, newAssessmentEntry ),
                    driveDataUpdated: true
            });
            return Object.assign({}, state, entry);

        default:
            return state;
  }
}