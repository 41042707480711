import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import ErrorPanel from './errorpanel';

const validate = function (values) {
    const errors = {};
    if (!values.name) {
        errors.name = "Name is required"
    }
    if (!values.abrv) {
        errors.abrv = "Abrv is required"
    } else {
        if (values.abrv.length > 6) {
            errors.abrv = "Abbreviation must be six characters or less";
        }
    }
    return errors;
}

const normalizeDriveLimit = value => value < -1 ? -1 : value;

class AddOrg extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        return (
            <form onSubmit={this.props.onSubmit}>
                <ErrorPanel />
                <div>
                    <Field 
                        name="name"
                        component={TextField}
                        hintText="Type organization name"
                        fullWidth={true}
                        floatingLabelText="Organization Name" />
                </div>
                <div>
                    <Field 
                        name="abrv"
                        component={TextField}
                        hintText={"Abbreviation for this organization.\n" + 
                            "keep it short."}
                        fullWidth={true}
                        floatingLabelText="Organization Abbreviation" />
                </div>
                <div style={{padding:"8px"}}>
                    <Field
                        name="driveLimit"
                        component={TextField}
                        hintText="Enter Drive Limit"
                        step={10}
                        floatingLabelText="Drive Limit"
                        type="number"
                        normalize={normalizeDriveLimit} />
                </div>

                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Add Organization"
                        type="submit" />
                </div>
            </form>
        )
    }
}

// Decorate the form component
AddOrg = reduxForm({
  form: 'addOrg', // a unique name for this form
  validate
})(AddOrg);

export default AddOrg;