import List from 'material-ui/List';
import Face from 'material-ui/svg-icons/action/face';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddUser from './components/addDriverUserForm';

const startListening = hlp.createOneShot(function () {
    //TODO -- I think Firebase handles this
    // but we should make sure this only happens once
    store.dispatch( actions.startListeningToUsers() );
    store.dispatch( actions.startListeningToGroups() );
    store.dispatch( actions.startListeningToOrgs() );
});

class ManagerUtils extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const rows = _.map(p.auth.drivers, function (name, uid) {//select only your drivers
            return (
                <div className="user-list-item" key={uid}>
                    <Link to={ "/profile/" + uid }><Face /> {name}</Link>
                </div>
            )
        })

        return (
                <div>
                    <div className="panel">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-8">
                                    <List className="userList">
                                        {p.users.hasReceivedUserData ? rows : "Loading Users..."}
                                    </List>
                                </div>
                                <div className="col-sm-4" style={{ borderLeft : "1px dotted #571a89", paddingLeft: "35px"}}>
                                <h6>{"Add a new user"}</h6>
                                    <AddUser                                         
                                        className="dxd-add-driver-user-form"
                                        onSubmit={(function (e) {e.preventDefault(); this.props.addDriverUser(p.auth); }).bind(this) }/>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        users: appState.users,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        addDriverUser: function (managerAuth) { dispatch( actions.addDriverUser(managerAuth) )}
    }
}

ManagerUtils.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(ManagerUtils);