import _ from 'lodash';
import Drawer from 'material-ui/Drawer';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import JSONTree from 'react-json-tree';
import { connect } from 'react-redux';
import { Link } from 'react-router';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#000000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

function buildPath(pox, map) {
    let output = "/map/" + map + "/";
    let stPoz = [];
    for (var p of pox) {
        if (p) {
            stPoz.push(Math.round(p.x) + ',' + Math.round(p.z));
        }
    }
    if (stPoz.length) {
        return output + stPoz.join('/');
    }
    return "";
}

function buildMapLink(data, map) {
    let path = "";
    if (data.hasOwnProperty('name')) {
        if (data.name == 'too fast level 0') {
            let d = data.data;
            path = buildPath([d.startPos, d.endPos], map); //"/map/" + d.startPos.x + ',' + d.startPos.z + "/" + d.endPos.x + ',' + d.endPos.z;
        }
        if (data.name == 'minor error 2 - scanning') {
            let d = data.data;
            if (d.pos) {
                path = buildPath([d.pos], map); // "/map/" + d.pos.x + ',' + d.pos.z;
            }
        }
        if (data.name == 'unsafe following time error') {
            let d = data.data;
            path = buildPath([d.driverStartPosition, d.npcStartPosition], map); // "/map/" + d.driverStartPos.x + ',' + d.driverStartPos.z + '/' + d.npcStartPos.x + ',' + d.npcStartPos.z;
        }
        if (data.name == 'teleport') {
            let d = data.data;
            path = buildPath([d.fromPos, d.toPos], map); //"/map/" + d.fromPos.x + ',' + d.fromPos.z + '/' + d.toPos.x + ',' + d.toPos.z;

        }
        if (data.name == 'compound error 3 - vehicle following') {
            let d = data.data;
            path = buildPath([d.driverStartPosition, d.npcStartPosition], map); //"/map/" + d.driverStartPos.x + ',' + d.driverStartPos.z + '/' + d.npcStartPos.x + ',' + d.npcStartPos.z;

        }
        if (data.name == 'collision level 0' || data.name == 'collision level 1' || data.name == 'forbidden zone') {
            let d = data.data;
            path = buildPath([d.driverPosition, d.colliderPosition], map); // "/map/" + d.driverPosition.x + ',' + d.driverPosition.z + '/' + d.colliderPosition.x + ',' + d.colliderPosition.z;
        }
    }
    
    if (path) {
        return <span className="mapLink"><Link target="_blank" to={ path }>map</Link></span>
    }

    return null;
}

class EventList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open : false,
        };
    }

    handleDrawerToggle() {
        this.setState({open: !this.state.open});
    }

    render() {
        const p = this.props;
        let crashed = false;
        let items = _.map(p.data, function(edt, frame) {
            let thisCrash = edt.hasOwnProperty('name') && (edt.name.indexOf("collision") >= 0);
            crashed = crashed || thisCrash;
            let wrap = {};
            wrap[edt.name] = edt;
            return (<div className={thisCrash ? "crash" : ""} key={frame}>
                        <JSONTree
                            className={thisCrash ? "crash" : ""}
                            data={edt.hasOwnProperty('name') ? wrap : edt}
                            theme={theme}
                            invertTheme={true}
                            // labelRenderer={raw => <strong>{raw}</strong>}
                            getItemString={
                                function (type, data, itemType, itemString) {
                                    // console.group("rendering json item");
                                    //     console.log("type", type);
                                    //     console.log("data", data);
                                    //     console.log("itemType", itemType);
                                    //     console.log("itemString", itemString);
                                    // console.groupEnd();
                                    return <span className="mapLinkCont"><span>{itemType} {itemString}</span>{buildMapLink(data, p.map)}</span>
                                }
                            }
                            hideRoot={true} />
                    </div>)
        });
        

        return (

            <div>
                <RaisedButton
                    label={p.label + (crashed ? "*" : "")}
                    onTouchTap={this.handleDrawerToggle.bind(this)} />
                <Drawer
                    docked={false}
                    width={312}
                    open={this.state.open}
                    onRequestChange={(open) => this.setState({open})} >
                        <div style={{ padding : "50px 15px"}}>
                            {items}
                        </div>
                </Drawer>
            </div>
                
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);

