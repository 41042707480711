
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import EmailForm from './components/emailform';

class TriggerFeedback extends Component {

    componentWillMount () {
        // will need to listen to alerts for this user
    }

    

    render () {
        const aid = this.props.params.aid

        return (

                <div>
                    {"To trigger feedback please enter an email address."}
                    <EmailForm  onSubmit={(function (e) {e.preventDefault(); this.props.triggerFeedback(aid); }).bind(this) } />
                </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        triggerFeedback: function (aid) { dispatch( actions.triggerFeedback(aid) )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TriggerFeedback);

