import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import store from './store';
import routes from './routes';

export default class App extends Component {
  render() {
    // try {
    //     return (
    //         <Provider store={store}>
    //             <Router routes={routes} history={browserHistory} />
    //         </Provider>
    //     )
    // } catch (error) {
    //     return <div>An error occured: {error.message}</div>
    // }

    return (
      <Provider store={store}>
        <Router routes={routes} history={browserHistory} />
      </Provider>
    )

  }
}