import _ from 'lodash';
import { reset } from 'redux-form';
import { C } from '../constants';
import Mailer from '../utils/mailer';

const usersRef = C.DB.ref('users');
const groupsRef = C.DB.ref('groups');
const orgsRef = C.DB.ref('organizations');
const assessRef = C.DB.ref('assessments');
const addUserAuth = C.AU_APP.auth();

const inviteQueue = C.DB.ref('inviteQueue');
const invitesRef = C.DB.ref('invites');

const mailer = new Mailer();

export const startListeningToUsers = function () {
    return function (dispatch, getState) {
        usersRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_USERS_DATA, data: snap.val()});
        });
    }
}

export const fetchUserData = function (uid) {
    return function (dispatch, getState) {
        usersRef.child(uid).once( "value" ).then((snap) => {
            dispatch({ type: C.RECEIVE_USER_DATA, key: uid, data: snap.val()});
        });
    }
}

// export const fetchUsersForManager = function (mid) {
//     return function (dispatch, getState) {
//         usersRef.child(mid).once('value', (snap) => {


//             let getDrivers = [];

//             _.map(snap.val().drivers, (data, drId) => {
//                 getDrivers.push( userRef.child(drId).once('value', (dSnap) => {
//                     dispatch({ type: C.RECEIVE_USER_DATA, key: drId, data: dSnap.val()});
//                     return dSnap;
//                 }))
//             });

//             Promise.all(getDrivers).then(results => {
//                 dispatch({ type: })
//             })

//         })
//         usersRef.child(uid).once( "value" ).then(function (snap) {
//             dispatch( {type: C.SET_MANAGER_USERS_LOADED, mid});
//         });
//     }
// }

export const changeUserGroup = function (uid, gid, opt_name) {
    return function (dispatch, getState) {
        // set users group as gid
        // add uid to group.members for gid
        // remove uid from all other groups (user can only belong to one group)
        let payload = {
            "group": gid
        };

        if (gid == 'driver') {
            payload.isOwner = false;
        }

        usersRef.child(uid).update(payload);

        const memberUpdate = {}
        memberUpdate[uid] = opt_name || true;
        groupsRef.child(gid).child("members").update(memberUpdate);
        const groups = getState().groups.data;
        _.forOwn(groups, (value, key) => {
            let userUpdate = {}
            userUpdate[uid] = null;
            if (key != gid) {
                groupsRef.child(key).child("members").update(userUpdate);
            }
        })
    }
}

export const changeUserOrg = function (uid, oid, opt_name) {
    return function (dispatch, getState) {
        // set users group as gid
        // add uid to group.members for gid
        // remove uid from all other groups (user can only belong to one group)
        usersRef.child(uid).update({
            organization: oid,
            allowedOrgs: null,
            isOwner: false
        });
        const memberUpdate = {}
        memberUpdate[uid] = opt_name || true; //use name if we have it
        orgsRef.child(oid).child("members").update(memberUpdate);
        const orgs = getState().orgs.data;
        //remove user from all other orgs (user can only be in one org at the moment)
        _.forOwn(orgs, (value, key) => {
            let userUpdate = {}
            userUpdate[uid] = null;
            if (key != oid) {
                orgsRef.child(key).child("members").update(userUpdate);
            }
        })
    }
}

export const toggleIsOwner = function (uid, isOwner, user) {
    return function (dispatch, getState) {

        let orgs = getState().orgs.data;
        let thisUsersOrg = orgs[user.organization];
        console.log('%c toggle owner user org ', 'background: #205; color: #FF0', thisUsersOrg);
        let isParent = thisUsersOrg.config && thisUsersOrg.config.isParent;
        let childOrgs = thisUsersOrg.childOrgs;

        if (!isOwner) {
            console.log('%c toggling owner on? ', 'background: #205; color: #FF0');
            if (isParent) {
                usersRef.child(uid + '/allowedOrgs').set(childOrgs).then(() => {
                    usersRef.child(uid).update({
                        isOwner : !isOwner
                    });
                })
            } else {
                usersRef.child(uid).update({
                    isOwner : !isOwner
                });
            }
        } else {
            usersRef.child(uid + '/allowedOrgs').set(null).then(() => {
                usersRef.child(uid).update({
                    isOwner : !isOwner
                });
            });
        }

        
    }
}


/*
 * if currently "isManager" remove the user from driver's "managers" and 
 * remove the driver from manager's "drivers"
 * if currently !isManager add the user to driver's "managers" and
 * add the driver to manager's "drivers"
 * If manager is in group "driver" - up their role to manager
 */
export const toggleManager = function (driverId, driver, managerId, manager, isManager) {
    return function (dispatch, getState) {
        //TODO: implement 'waiting' procedure so toggle can deactivate momentarily
        let mData = {};
        mData[managerId] = isManager ? null : manager.fullname;
        let dData = {};
        dData[driverId] = isManager ? null : driver.fullname;
        usersRef.child(driverId + "/managers").update( mData );
        usersRef.child(managerId + "/drivers").update( dData);
        //TODO: manage group for manager - if super leave alone, if manager
        // demote if no drivers after this, promote if new manager
    }
}

/*
 * if "isCurrentlyAlerting" remove the alerted from alerter's "sendAlertsTo" and 
 * remove the alerter from alerted's "alerts.users"
 * if currently !"isCurrentlyAlerting" add the alerted to alerters "sendAlertsTo" and
 * add the alerter to alerted's "alerts.user"
 */
export const toggleUserAlert = function (alerterId, alerterData, alertedId, alertedData, isCurrentlyAlerting) {
    return function (dispatch, getState) {
        //TODO: implement 'waiting' procedure so toggle can deactivate momentarily
        if (alertedData.mobile) {
            let toData = {};
            toData[alertedId] = isCurrentlyAlerting ? null : alertedData.mobile;
            let fromData = {};
            fromData[alerterId] = isCurrentlyAlerting ? null : alerterData.fullname;
            usersRef.child(alerterId + "/sendAlertsTo").update( toData );
            usersRef.child(alertedId + "/alerts/user").update( fromData );
        }
        // TODO: let user know that the person has not configured a mobile number
    }
}

/*
 * if "isCurrentlyAlerted" remove the user from org's "sendAlertsTo" and 
 * remove the org from user's "alerts.orgs"
 * if currently !isCurrentlyAlerted add the user to orgs "sendAlertsTo" and
 * add the org to user's "alerts.orgs"
 */
export const toggleOrgAlert = function (userId, userData, orgId, orgData, isCurrentlyAlerted) {
    return function (dispatch, getState) {
        //TODO: implement 'waiting' procedure so toggle can deactivate momentarily
        console.log("toggleOrgAlert", userId, userData, orgId, orgData, isCurrentlyAlerted);
        if (userData.mobile) {
            let toData = {};
            toData[userId] = isCurrentlyAlerted ? null : userData.mobile;
            let fromData = {};
            fromData[orgId] = isCurrentlyAlerted ? null : orgData.name;
            orgsRef.child(orgId + "/sendAlertsTo").update( toData );
            usersRef.child(userId + "/alerts/orgs").update( fromData );
        }
        // TODO: let user know that the person has not configured a mobile number
    }
}

/*
 * if currently "isDriver" remove the user from manager's "drivers" and 
 * remove the manager from driver's "managers"
 * if currently !isDriver add the user to manager's "drivers" and
 * add the manager to driver's "managers"
 * If manager is in group "driver" - up their role to manager
 */
// export const toggleDriver = function (driverId, driver, managerId, manager, isDriver) {
//     return function (dispatch, getState) {
//         let data = {};
//         data[mid] = isDriver ? null : "name goes here";
//         // usersRef.child(uid + "/managers").update( data );
//     }
// }

export const addUser = () => {
    return (dispatch, getState) => {
        const formValues = getState().form.addUser.values;
        addUserAuth.createUserWithEmailAndPassword(formValues.email, formValues.password).then((userCredential) => {
            // Since we are creating a user via the admin UI, we may not want to send an email verification
            // These users will be unverified -- if we start locking unverified users out -- we will need to verify these users
            // user.sendEmailVerification

            //capitalize formValues.firstname and formValues.lastname so that sorting works properly
            const firstname = formValues.firstname[0].toUpperCase() + formValues.firstname.substr(1);
            const lastname = formValues.lastname[0].toUpperCase() + formValues.lastname.substr(1);

            const fullname = firstname + ' ' + lastname;

            const newUser = {
                dateAdded: Date.now(),
                firstname: firstname,
                lastname: lastname,
                fullname: fullname,
                email: formValues.email,
                group: formValues.group,
                organization: formValues.organization,
                risk: -2
            }

            const memberUpdate = {}
            
            memberUpdate[userCredential.user.uid] = fullname;
            // denormalize: add this user to global group list
            // TODO: easier and more efficent to do in one call to "update"
            groupsRef.child(formValues.group).child("members").update(memberUpdate);
            orgsRef.child(formValues.organization).child("members").update(memberUpdate);
            // this is a new user - so we don't need to remove him/her from other groups

            userCredential.user.updateProfile({
                displayName: fullname
            }).then(() => {
                // set the users display name
            });

            addUserAuth.signOut().then(() => {
                usersRef.child(userCredential.user.uid).set(newUser);
            });

            dispatch(reset('addUser'));
            
        }).catch((error) => {
            // TODO: handle errors with redux state (global error system)
            alert("error adding user: (" + error.code + ")" );
        })
    }
    
}

export const deleteUser = function (uid) {
    return function (dispatch, getState) {
        const userRef = C.DB.ref('users/' + uid);

        usersRef.once('value').then((snap) => {
            let user = snap.val()[uid];
            if (user) {
                if(user.managers) {
                    const managers = user.managers;

                    //delete from any managers' drivers lists
                    for (var manager in managers) {
                        usersRef.child(manager + '/drivers' ).child(uid).remove();
                    }
                }

                if (user.group) {
                    const group = user.group;

                    //delete from group's members list
                    groupsRef.child(group + '/members').child(uid).remove(); 
                }

                if (user.organization) {//sometimes we delete users *because* they dont have orgs
                    const organization = user.organization;
                    orgsRef.child(organization + '/members').child(uid).remove();
                }

                //remove user
                userRef.remove(); 

                //then route to home with a message
                console.log("User ", uid, " deleted from the database. Be sure to delete this user's auth account as well.");
            }
            
        });

    }
}

export const addDriverUser = function(auth) {
    return function (dispatch, getState) {
        const formValues = getState().form.addDriverUser.values;

        addUserAuth.createUserWithEmailAndPassword(formValues.email, formValues.password).then((user) => {
            // Since we are creating a user via the admin UI, we may not want to send an email verification
            // These users will be unverified -- if we start locking unverified users out -- we will need to verify these users
            // user.sendEmailVerification
            //set up managers and drivers data

            //capitalize formValues.firstname and formValues.lastname so that sorting works properly
            const firstname = formValues.firstname[0].toUpperCase() + formValues.firstname.substr(1);
            const lastname = formValues.lastname[0].toUpperCase() + formValues.lastname.substr(1);

            const fullname = firstname + ' ' + lastname;
            let managers = {};
            managers[auth.uid] = auth.username;
            const memberUpdate = {}
            memberUpdate[user.uid] = fullname;
            const newUser = {
                dateAdded: C.TS,
                fullname: fullname,
                firstname: firstname,
                lastname: lastname,
                email: formValues.email,
                brandNew: true,
                addedDirectly: true,
                group: 'driver',
                organization: auth.oid,
                managers: managers,
                risk: -2
            }

            

            addUserAuth.signOut().then( () => {
                usersRef.child(user.uid).set(newUser).then(() => {
                    usersRef.child(user.uid + "/managers").update( managers ); 
                    usersRef.child(auth.uid + "/drivers").update( memberUpdate); 
                    groupsRef.child("driver/members").update(memberUpdate);
                    orgsRef.child(auth.oid).child("members").update(memberUpdate);
                    user.updateProfile({
                        displayName: fullname
                    }).then(() => {
                        // set the users display name
                    });
                });
            } );

            dispatch(reset('addDriverUser'));
            




        }).catch((error) => {
            // TODO: handle errors with redux state (global error system)
            alert("error adding user: (" + error.code + ")" );
        })
    }
    
}

export const generateOpenInvite = function(invitor, oid, type, payPerAssessment, requireParent, validateParentEmail) {
    return function (dispatch, getState) {
        const invite = C.DB.ref('invites').push();
        invite.set({
            invitor: invitor,
            invitee: type,
            oid: oid,
            makeManager: false,
            invited: C.TS,
            registered: false,
            registrationCount: 0,
            payPerAssessment,
            waiveFee: false,
            requireParent : requireParent || false,
            validateParentEmail : validateParentEmail || null,
            generated: C.TS,
            sendCount: 0
        });

        let entry = {};
        entry[invite.key] = "open";
        C.DB.ref('users/' + invitor + '/invites').update(entry);
        C.DB.ref('users/' + invitor).update({"openInvite" : invite.key});
    }
}

/**
* Add a record to "inviteQueue"  with invitor & invitee
* optional org ID -- if none is specified use invitors org
*/
export const inviteNewUsers = (listOfEmails, invitorUid, orgUid, payPerAssessment, waiveFee, requireParent, validateParentEmail) => {
    return (dispatch, getState) => {
        let makeManager = false;
        let inviteOptions = getState().form.inviteOptions;
        if (inviteOptions) {
            makeManager = inviteOptions.values.manager || false;
            orgUid = inviteOptions.values.oid;
        }

        dispatch(reset('inviteOptions'));
        console.log("invite new users action: ", listOfEmails, invitorUid, orgUid, makeManager, payPerAssessment, waiveFee, requireParent, validateParentEmail);
        
        listOfEmails.forEach((email) => mailer.sendNewUserInvite(invitorUid, orgUid, makeManager, email, payPerAssessment, waiveFee, requireParent, validateParentEmail));
    }
}

/**
* Add a record to "inviteQueue"  with invitor & invitee
* invite should have all this info
* update invite with last sent info
*/
export const resendInviteEmail = inviteUid => {
    return (dispatch, getState) => {
        console.log("resendInviteEmail");
        mailer.resendUserInvite(inviteUid);
    }
}

/**
* delete invite from DB (and user's list of invites)
*/
export const deleteInvite = function(inviteId, invitor) {
    return function (dispatch, getState) {
        console.log("deleteInvite");
        C.DB.ref('invites/' + inviteId).set(null).then(() => {
            C.DB.ref('users/' + invitor + '/invites/' + inviteId).set(null);
            C.DB.ref('users/' + invitor + '/openInvite').set(null);
        })
    }
}