import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';



class StringForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        return (
            <form
                onSubmit={function (e) { e.preventDefault(); e.stopPropagation(); }}
                className={this.props.className}>
                <div style={{padding: "44px 11px 0"}}>
                    <div>
                        <Field
                            name={this.props.name}
                            hintText={this.props.label}
                            floatingLabelText={this.props.label}
                            component={TextField} />
                    </div>
                </div>
            </form>
        )
    }
}

/*
function mapStateToProps(state, ownProps) {
stateValues = state;
const result = {
initialValues: {
grade_part: ownProps.profile.gradePart,
subject: ownProps.profile.subject,
}
};
return result;
}
*/

// Decorate the form component
StringForm = reduxForm({
  form: 'stringy' // a unique name for this form
})(StringForm);

StringForm = connect(
  state => ({
    initialValues: state.overview // pull initial values from auth oid
  })
)(StringForm);



export default StringForm;