
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import DocumentForm from './components/documentform';
import * as perms from "../utils/permission";



const style = {
  margin: 20,
  position: "relative"
};

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

const cStyle = {
    padding: 15,
    textAlign: "center"
}

const DeleteButton = (props) => {
    return (
        <FloatingActionButton mini={true} secondary={true}
            onClick={props.onClick}>
                <DeleteIcon />
        </FloatingActionButton>
    )
}

const DocFormArea = (props) => {
    return (
        <Paper
            style={style}
            zDepth={1}
            key={999}
        >
            <div style={{
                    position: "absolute",
                    top: "0px", 
                    right: "0px",
                    padding: "5px",
                    fontSize: "85%",
                    border: "1px solid #CCC",
                    zIndex: "999999999"
                }}>
                Total Bytes: {props.totalBytes} - Bytes Transferred: {props.bytesTransferred}
            </div>
            <div className="progressbar" style={{ width: props.loaded }}></div>
            <div style={innerStyle} >{ props.uploading ? props.wait : props.dForm}</div>
        </Paper>
    )
}


class Documents extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToDocuments() );
        this.props.setTitle('Helpful Files & Documents');
    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    render () {
        const p = this.props;

        const SecureDeleteButton = perms.spr(DeleteButton);
        const rows = _.map(p.documents.data, function (doc, docId) {
            if (p.documents.deleting[docId]) {
                return <Paper
                    key={docId}
                    style={style}
                    zDepth={1}
                > Deleting... </Paper>
            }
            return <Paper
                key={docId}
                style={style}
                zDepth={1}
            >
                
                <div style={innerStyle} >
                    <div className="row">
                        <div className="display-6 col-sm-3">{doc.title}</div>
                        <div className="col-sm-5">{doc.description}</div>
                        <div className="col-sm-2" style={{ paddingTop: "8px"}}><a href={doc.downloadUrl}> view/download </a></div>
                        <div className="col-sm-2">
                            <SecureDeleteButton onClick={(function (e) { p.deleteDocument(docId); }).bind(this)} />

                        </div>
                    </div>
                
                </div>
                
            </Paper>
        })
        const list = p.documents.hasReceivedDocumentData ? rows : (<Paper style={style} zDepth={1}>
                        <div style={innerStyle} >Loading Help Documents...</div>
                    </Paper>)

        const nodata = (
            <Paper
                key={0}
                style={style}
                zDepth={1}
            >
                <div style={innerStyle} >No documents found</div>
            </Paper>
        )
        if (!rows.length) {
            rows.push(nodata);
        }

        const hangOn = <Paper
                style={style}
                zDepth={1}
            >
                <div style={cStyle} >
                    <CircularProgress />
                </div>
            </Paper>

        const dForm = (<DocumentForm className="doc-form" onSubmit={(function (e) {e.preventDefault(); this.props.uploadDocument(); }).bind(this) } />);
        const wait = <div>Progress: {this.props.documents.progress.toFixed(2)}%</div>
        const loaded = this.props.documents.progress + "%";
        console.log("LOADED: ", loaded);
        const SecureDocFormArea = perms.spr(DocFormArea);

        const add = <SecureDocFormArea
                        totalBytes={this.props.documents.totalBytes}
                        bytesTransferred={this.props.documents.bytesTransferred}
                        loaded={loaded}
                        uploading={this.props.documents.uploading}
                        wait={wait}
                        dForm={dForm} />

        //rows.unshift(add);
        
        const displayErrors = function () {
            if (p.documents.error) {
                return <Paper className="doc-upload-error" style={style} zDepth={1}>
                    <div style={innerStyle} >{p.documents.error}</div>
                </Paper>
            }
            return null;
        }


        // console.log("==================", hideThese);
        // _.map(hideThese, function (div, key) {
        //     // div.style.display = "none";
        //     console.log(div);
        // })

        return (
            <div>
                { displayErrors() }
                { add }
            { p.documents.hasReceivedDocumentData ? list : hangOn}
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        documents: appState.documents
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadDocument: function () { dispatch( actions.uploadDocument() )},
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) },
        deleteDocument: function (docId) { dispatch( actions.deleteDocument(docId) )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Documents);
