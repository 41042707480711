import _ from 'lodash';
import { destroy, reset } from 'redux-form';
import { C } from '../constants';




const qRef = C.DB.ref('questions');
const usersRef = C.DB.ref('users');
const intakeRef = C.DB.ref('intakeforms');
const openIntakes = C.DB.ref('openIntakes');
const openIntakeIds = C.DB.ref('intakeMap');

export const startListeningToQuestions = function () {
    return function (dispatch, getState) {
        console.log("START LISTENING TO QUESTIONS")
        qRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_QUESTIONS_DATA, data: snap.val()});
        })
    }
}

export const startListeningToIntakeForm = function (intakeId) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_INTAKE_RESULT_DATA, key: intakeId });
        intakeRef.child(intakeId).on('value', (snap) => {
            let intakeData = snap.val();
            if (!intakeData) {
                openIntakes.child(intakeId).on('value', (oiSnap) => {
                    intakeData = oiSnap.val();
                    dispatch({ type: C.RECEIVE_INTAKE_RESULT_DATA, key: intakeId, data: intakeData });
                })
            } else {
                dispatch({ type: C.RECEIVE_INTAKE_RESULT_DATA, key: intakeId, data: intakeData });
            }
            
        })
    }
}

export const startListeningToAllIntakes = function () {
    return function (dispatch, getState) {
        intakeRef.on('value', (snap) => {
            const allIntakeData = snap.val();
            dispatch({ type: C.RECEIVE_ALL_INTAKE_DATA, data: allIntakeData });
        })
        openIntakes.on('value', (snap) => {
            const openIntakeData = snap.val();
            dispatch({ type: C.RECEIVE_ALL_OPEN_INTAKE_DATA, data: openIntakeData });
        })
    }
}

export const deleteQuestion = function (qid) {
    return function (dispatch, getState) {
        qRef.child(qid).set(null);
    }
}

export const dupeQuestion = function (qid) {

    // we already have the data present -- just grab it?
    return function (dispatch, getState) {
        dispatch({ type: C.PREFILL_QUESTION, qid: qid})
    }
}

export const submitIntake = function (router, unity, open) {
    return function (dispatch, getState) {
        dispatch({ type: C.SUBMITTING_INTAKE });
        const formValues = getState().form.intake.values;
        const qs = getState().questions.data;
        const uid = getState().auth.uid;
        const intake = open ? openIntakes.push() : intakeRef.push();
        let intakeData = Object.assign({}, formValues);
        intakeData.timestamp = C.TS;
        _.map(qs, (q, key) => {
            // instructions won't be in the form and we don't need a no data flag
            if (q.type != "instruction") {
                intakeData[q.name] = intakeData[q.name] || {"noValue": true}
            }
        });
        intake.set(intakeData).then(() => {
            if (open) {
                let map = {};
                map[getState().questions.openIntakeId] = intake.key;
                openIntakeIds.update(map).then(() => {
                    dispatch({ type: C.INTAKE_SUBMISSION_RECEIVED });
                    window.sendIntakeSuccess(intake.key);
                    router.replace('/blank');
                    dispatch(destroy('intake')); 
                });
                

            } else {
                const intakeUpdate = {}
                intakeUpdate[intake.key] = C.TS;
                usersRef.child(uid).update({lastIntake : intake.key, lastIntakeTime : C.TS }).then(() => {
                    usersRef.child(uid).child("intakeforms").update(intakeUpdate).then(() => {
                        console.log("database updated", intake);
                        dispatch({ type: C.INTAKE_SUBMISSION_RECEIVED });
                        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%Are we from unity?", unity)
                        if (unity) {
                            window.sendIntakeSuccess(intake.key);
                            router.replace('/blank');

                        } else {
                            console.log("re-routing to intake success page")
                            router.replace('/intakesuccess');
                        }
                        dispatch(destroy('intake')); 
                    });
                });
            }
            
        });
    }
}

export const addQuestion = function () {
    return function (dispatch, getState) {
        const formValues = getState().form.addQuestion.values;
        const newQuestion = {
            name: formValues.shortname,
            type: formValues.type,
            text: formValues.text,
            required: Boolean(formValues.required)
        }
        if (formValues.type == "multhorz") {
            newQuestion.leftText = formValues.lefttext;
            newQuestion.rightText = formValues.righttext;
        }
        if (["multvert", "multhorz", "allthatapply"].includes(formValues.type)) {
            newQuestion.numberOfChoices = formValues.numberofchoices;
            let choices = [];
            for (let i of Array(formValues.numberofchoices).keys()) {
                let num = i + 1;
                // newQuestion["choicevalue-" + num] = formValues["choicevalue-" + num];
                // newQuestion["choicedisplay-" + num] = formValues["choicedisplay-" + num];
                choices.push({display: formValues["choicedisplay-" + num], value: formValues["choicevalue-" + num]})
            }
            newQuestion.choices = choices;
        }
        if (formValues.type == "allthatapply") {
            newQuestion.includeOther = Boolean(formValues.includeother);
        }
        dispatch({type: C.AWAIT_NEW_QUESTION_RESPONSE});
        qRef.push(newQuestion).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_QUESTION_RESPONSE});
            dispatch(reset('addQuestion'));
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_QUESTION_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add question!"}) //TODO: better errors
        });
    }
}
