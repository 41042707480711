
import _ from 'lodash';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddCoachingPageForm from './components/addcoachingpageform';


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToCoachingPages() );
    store.dispatch( actions.startListeningToCoachingItems() );
});

class CoachingPageAdmin extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount CoachingPageAdmin");
        startListening();
    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;

        console.log("rendering coaching page admin page");

        const pages = p.coachingPages;
        const items = p.coachingItems;

        const coachingPageRows = _.map(pages.data, (cp, cpid) => {
            return <div key={cpid} className="row q-row">
                <div className="col-sm-2">{cp.name}</div>
                <div className="col-sm-1">{cp.numberOfItems}</div>
                <div className="col-sm-8">{
                    // _.map(cp.items, (item, itemId) => { // items in pages will have coachingItem that is a ref to a coachingItem and a layout (full, half, quarter)
                    //     return p.coachingItems.hasReceivedData ? p.coachingItems.data[item.coachingItem].name : "loading"
                    // }).join(", ")
                    cp.description
                }</div>
                <div className="col-sm-1">
                    <IconButton
                        disabled={!!cp.usedBy}
                        onClick={ function () { p.deleteCoachingPage(cpid, cp) }}
                        tooltip={ cp.usedBy ? "Currently used in a survey" : "Delete Coaching Page"}>
                        <Delete
                            className="q-trash" />
                    </IconButton>
                    {/*
                    <IconButton disabled={true} tooltip="Edit Coaching Item">
                        <Create className="q-edit" />
                    </IconButton>
                    */}  
                    {/* This is bound to "Questions" -- need to untangle so that we can use it for Coaching Items as well
                    <IconButton onClick={ function () { p.load(qid); }} tooltip="Copy coaching item values into form">
                        <MoveUp className="q-dupe" />
                    </IconButton>
                    */}
                </div>
            </div>
        })

        const rows = <div>
            {pages.hasReceivedData ? (
                <div className="q-rows">
                    <div className="row q-row">
                        <div className="col-sm-2">Name</div>
                        <div className="col-sm-1">Item Count</div>
                        <div className="col-sm-8">Description</div>
                        <div className="col-sm-1">Delete</div>
                    </div>
                    {coachingPageRows}
                </div>
            ) : "Loading coaching pages..."}
        </div>

        const noQs = <div> No coaching pages exist </div>
        

        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <AddCoachingPageForm
                            className="add-cp-form"
                            items={p.coachingItems}
                            onSubmit={(function (e) {e.preventDefault(); this.props.addCoachingPage(); }).bind(this) }>
                        </AddCoachingPageForm>


                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        { coachingPageRows.length ? rows : noQs }
                    </div>
                </div>
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        coachingItems: appState.coachingItems,
        coachingPages: appState.coachingPages
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addCoachingPage: function () {
            dispatch( actions.addCoachingPage() )
        },
        deleteCoachingPage: function (cpid, cp) {
            dispatch( actions.deleteCoachingPage( cpid , cp ) )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoachingPageAdmin);
