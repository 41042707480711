import { C } from '../../constants';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.SET_COUNT_DATA:
        return Object.assign({}, state, {
          data : action.data
        });

      default:
        return state;
  }
}