import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import { TableRow, TableRowColumn } from 'material-ui/Table';
import React from 'react';
import Video from './videoembed';

class SafetyLapse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            infoDialogOpen: false,
            videoDialogOpen: false,
            benchmarkDialogOpen: false
        }
    }

    render() {
        const props = this.props;

        let video = '';
        if (props.lapse.video) {
            video = (
                <div>
                    <IconButton
                        iconClassName="icon ion-ios-videocam"
                        onClick={() => {this.setState({videoDialogOpen: true})}} />
                    <Dialog
                        title={`${props.lapse.name} Video`} 
                        open={this.state.videoDialogOpen}
                        onRequestClose={() => {this.setState({videoDialogOpen: false})}}>
                        <Video id={props.lapse.video}/>
                    </Dialog>
                </div>
            );
        }

        const aboveMedian = props.lapse.count ? props.lapse.count < props.lapse.median : props.lapse.count <= props.lapse.median;

        return (
            <TableRow>
                <TableRowColumn>
                    {props.lapse.name}
                </TableRowColumn>
                <TableRowColumn style={{width: '60px'}}>
                    {video}
                </TableRowColumn>
                <TableRowColumn style={{width: '60px'}}>
                    <IconButton
                        iconClassName="icon ion-information-circled"
                        onClick={() => {this.setState({infoDialogOpen: true})}} />
                    <Dialog
                        title={`${props.lapse.name} Info`} 
                        open={this.state.infoDialogOpen}
                        onRequestClose={() => {this.setState({infoDialogOpen: false})}}>
                        {props.lapse.description}
                    </Dialog>
                </TableRowColumn>
                <TableRowColumn style={{width:'100px'}} className={aboveMedian? 'text-inverse' : 'text-warning'}>
                    {props.lapse.count}
                    <IconButton
                        iconClassName={`icon ${aboveMedian? 'ion-thumbsup text-inverse' : 'ion-minus-circled text-warning'}`}
                        onClick={() => {this.setState({benchmarkDialogOpen:true})}}
                    />
                    <Dialog
                        title={`${props.lapse.name} Benchmark`} 
                        open={this.state.benchmarkDialogOpen}
                        onRequestClose={() => {this.setState({benchmarkDialogOpen: false})}}>
                        <div className="benchmark">{props.lapse.benchmark}% of drivers had at least 1 occurrence.</div>
                        <div className="range">Sampled drivers had between {props.lapse.range.min} and {props.lapse.range.max} occurrences.</div>
                        <div className="median">The median driver has {props.lapse.median} occurrences.</div>
                    </Dialog>
                </TableRowColumn>
            </TableRow>
        );
    }
}

export default SafetyLapse;