import RaisedButton from 'material-ui/RaisedButton';
import React, { Component, PropTypes } from 'react';

const styles = {
  button: {
    margin: 12,
  }
};

class FileButton extends Component {
    constructor(props, context) {
        super(props, context);
        this.displayName = 'FileButton';
        this.state = {
            errors: [],
            file: {name: ""}
        }
    }

    updateValue(value) {
        this.context.update(this.props.name, value);

        if (this.state.errors.length) {
            setTimeout(() => this.isValid(true), 0);
        }
    }

    isValid(showErrors) {
        const errors = this.props.validate
            .reduce((memo, currentName) => 
                memo.concat(validators[currentName](
                    this.props.name,
                    this.context.values
                )), []);

        if (showErrors) {
            this.setState({
                errors
            });
        }

        return !errors.length;
    }

    componentWillMount() {
        this.removeValidationFromContext = this.context.registerValidation(show => 
            this.isValid(show));
    }

    componentWillUnmount() {
        this.removeValidationFromContext();
    }

    onBlur() {
        this.isValid(true);
    }

    onChange(evt) {
        this.updateValue(evt.target)
        var file = evt.target.files[0]; // FileList object
        this.setState({file}); //escape(file.name) + 
            // " (" + (file.type || "unknown type") + ") - " +
            // file.size + " bytes, last modified: " +
            // file.lastModifiedDate ? file.lastModifiedDate.toLocaleDateString() : "n/a";
    }
    

    componentDidMount () {
        let input = document.getElementById("input-" + this.props.id);
        let span = document.getElementById("span-" + this.props.id);
        var update = this.updateValue
        input.addEventListener('change', function (evt) {
            
        }, false);
    }

    render () {
        return (
            <div>
                <RaisedButton
                    secondary
                    containerElement='label'
                    label={this.props.label}
                    labelPosition="before"
                    // onTouchTap={this.context.submit}
                    style={styles.button}>
                        <input
                            name={this.props.name}
                            onChange={this.onChange.bind(this)}
                            id={"input-" + this.props.id}
                            type="file"
                            className="formFieldInputButton" />
                </RaisedButton>
                <span id={"span-" + this.props.id}>{escape(this.state.file.name)}</span>
            </div>
        )
    }
}

FileButton.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    validate: PropTypes.arrayOf(PropTypes.string)
}

FileButton.contextTypes = {
    update: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    registerValidation: PropTypes.func.isRequired
}

FileButton.defaultProps = {
    label: 'Select File',
    validate: []
}

export default FileButton;
