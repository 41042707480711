import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';

class ErrorPanel extends Component {
    constructor(props, context) {
        super(props, context);
        this.displayName = 'FeedbackPanel';
    }
    render () {

        let p = this.props, errors = p.errors.map( function (msg, index) {
            return (
                <div key={index} className="error">
                    {msg}
                    <button 
                        onClick={p.dismissError.bind(this,index)}>
                            X
                    </button>
                </div>
            )
        });
        return (
            <div className="errorList">
                {errors}
            </div>
        )
    }
}

// now we connect the component to the Redux store:

var mapStateToProps = function(appState){
    // This component will have access to `appState.errors` 
    // through `this.props.errors`

    return {
        errors: appState.dForm.errors
    };
};

var mapDispatchToProps = function(dispatch){
    return {
        dismissError: function(n){ dispatch(actions.dismissError(n)); }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPanel);
