import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {C} from '../../constants';
import actions from '../../actions';
import store from '../../store';
import queryString from 'query-string';

import DashGrid from './dashGrid';
import Subtitle from './subtitle';

const SUGGESTION_MAP = {
    speed: "speeding",
    steeringCornering: "steering/cornering",
    hardBraking: "hard braking",
    cellPhoneUse: "mobile phone use"
}

class AssessmentGrid extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            intakeId : null
        }
    }

    componentWillMount () {
        let aid = this.props.aid;
        store.dispatch({ type: C.SET_QRY_AID, aid});
        store.dispatch({ type: C.SET_QRY_DIMENSIONS, dimensionName: null, dimensionValue: null}); // clear the dimensions in the store
        store.dispatch( actions.startListeningToAssessment(aid) );
        store.dispatch( actions.loadInitialDashQuery(aid) );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let p = this.props;
        let aid = this.props.aid;
        let nowHave = p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData;
        let hadBefore = prevProps.assessments && prevProps.assessments.data && prevProps.assessments.data[aid] && prevProps.assessments.data[aid].hasReceivedData;
        if (nowHave && !hadBefore) {
            let assessmentData = p.assessments.data[aid].value;
            if (assessmentData.intake) {
                store.dispatch( actions.startListeningToIntakeForm(assessmentData.intake))
            }
        }
    }

    // componentWillReceiveProps (nextProps) {
    //     let aid = this.props.aid;
    //     if (nextProps.assessments && nextProps.assessments.data && nextProps.assessments.data[aid] && nextProps.assessments.data[aid].hasReceivedData) {
    //         let aData = nextProps.assessments.data[aid].value;
    //         this.setState({intakeId : aData.intake})
    //     }
    //     if (this.state.intakeId) {
    //         store.dispatch( actions.startListeningToIntakeForm(this.state.intakeId))
    //     }
    //     if (nextProps.intakeForms && nextProps.intakeForms[this.state.intakeId]) {
    //         let responses = nextProps.intakeForms[this.state.intakeId].data && nextProps.intakeForms[this.state.intakeId].data.responses ? nextProps.intakeForms[this.state.intakeId].data.responses : nextProps.intakeForms[this.state.intakeId].data;
    //         let first = responses['first_name>>first_name'];
    //         let last = responses['last_name>>last_name'];
    //         this.props.setTitle('Progress Report for ' + indId + ' ' + first + ' ' + last);
    //     }
    // }

    // componentWillUnmount () {
    //     this.props.clearTitle();
    // }

    render () {
        
        const p = this.props;
        const aid = this.props.aid;

        let assessmentData = null;
        let suggestions = null;

        let isLoading = (p.dash.submittingnew || !p.dash.hasReceivedData);

        let nameInserter = null;

        if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
            assessmentData = p.assessments.data[aid].value;
            if (p.intakeForms && assessmentData.intake && p.intakeForms[assessmentData.intake] && p.intakeForms[assessmentData.intake].data) {
                nameInserter = <Subtitle intake={p.intakeForms[assessmentData.intake].data} />
            }
            
        }

        let content = <div></div>

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        if (p.dash.hasReceivedData && !p.dash.error) {
            content = (
                <DashGrid loading={isLoading} />
            );

            if (p.dash.data.suggestions) {
                let suggsItems = [];
                 _.map(p.dash.data.suggestions, (isSuggested, suggKey) => {
                    if (isSuggested) {
                        suggsItems.push(SUGGESTION_MAP[suggKey]);
                    }
                })
                if (suggsItems.length) {
                    prodConsole.log(suggsItems);
                    suggestions = (
                        <div>Based on the following results, it is recommended that you focus on {/*suggsItems.length == 1 ? suggsItems[0] : */[suggsItems.slice(0, -1).join(', '), suggsItems.slice(-1)[0]].join(suggsItems.length < 2 ? '' : ' and ')} in your telematics.</div>
                    )
                }
            }
        }

        if (p.dash.error) {
            content = <div style={{padding: 40}}>An error occured fetching this assessment data.</div>
        }

        return (

            <div className='dash-box' key={"" + aid}>
                {isLoading ? <div className="dash-curtain"></div> : null}
               
                {suggestions ? (
                    <div className="suggest-header">
                        <div style={{paddingBottom: 12}} className="dash-suggest display-6">
                            {suggestions}
                        </div>
                    </div>
                ) : null}
                

                <div className="domain-table">
                    { isLoading ? (
                           <div className="loading">Loading&#8230;</div>
                        ) : null }
                    {nameInserter}
                    {content}
                </div>
            </div>  
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        dash: appState.dash,
        assessments: appState.assessments,
        intakeForms : appState.intakeForms
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentGrid);
