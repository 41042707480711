import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/analytics';

/*
This file contains all would-be-magic-strings in the app.
*/

const dxdvDevConfig = {
    apiKey: "AIzaSyA1dwdJMUwGohPTnCmhJ148B0ckMntCa34",
    authDomain: "dxdv-dev.firebaseapp.com",
    databaseURL: "https://dxdv-dev.firebaseio.com",
    storageBucket: "dxdv-dev.appspot.com",
    projectId: "dxdv-dev",
    appId: "1:490273315111:web:a797d5c751fe438bdd7102",
    locationId: "us-central",
    messagingSenderId: "490273315111",
    measurementId: "G-YP5108LH1K"
};

const prodConfig = {
    apiKey: "AIzaSyB9KoyhPk6MWTwepC2td1pqYFbFCk75aBE",
    authDomain: "diagnostic-driving.firebaseapp.com",
    databaseURL: "https://diagnostic-driving.firebaseio.com",
    storageBucket: "diagnostic-driving.appspot.com",
    projectId: "diagnostic-driving",
    messagingSenderId: "1024237417068",
    appId: "1:1024237417068:web:3db1674cbb42eadb249e49",
    locationId: "us-central",
    measurementId: "G-8Y0MSFXQYP"
};

const stagingConfig = {
    apiKey: "AIzaSyDfLZxYrlVGkT5XwRG_atpCZnK2zH5fCcM",
    authDomain: "diagnostic-driving-staging.firebaseapp.com",
    databaseURL: "https://diagnostic-driving-staging.firebaseio.com",
    storageBucket: "diagnostic-driving-staging.appspot.com"
};

const testingConfig = {
    apiKey: "AIzaSyBd17Nzqv9f2EftlGXDcktaiDr5bzRJcjQ",
    authDomain: "diagnostic-driving-test.firebaseapp.com",
    databaseURL: "https://diagnostic-driving-test.firebaseio.com",
    storageBucket: "diagnostic-driving-test.appspot.com"
}

const odpsDevConfig = {
    apiKey: "AIzaSyD6CFT4btXbz27NQcYB32p96OpeaMllEDY",
    authDomain: "odps-dev.firebaseapp.com",
    databaseURL: "https://odps-dev.firebaseio.com",
    projectId: "odps-dev",
    storageBucket: "odps-dev.appspot.com",
    messagingSenderId: "845329626595",
    appId: "1:845329626595:web:854c62a6cd29ac2210dcd6",
    locationId: "us-central",
    measurementId: "G-KJPR16280E"
}

const odpsProd = {
    apiKey: "AIzaSyDFlv07NqLWVJf5WPYhIYXDk9PgIrjFaTE",
    authDomain: "odps-pdss.firebaseapp.com",
    databaseURL: "https://odps-pdss.firebaseio.com",
    projectId: "odps-pdss",
    storageBucket: "odps-pdss.appspot.com",
    appId: "1:588070450710:web:568ab503881ccd4c4ba737",
    locationId: "us-central",
    messagingSenderId: "588070450710",
    measurementId: "G-W99TQXW2XH"
}

const production = true;

let deploymentConfig = dxdvDevConfig;

if (__ODPS__) {
    deploymentConfig = production ? odpsProd : odpsDevConfig;
} else {
    deploymentConfig = production ? prodConfig : dxdvDevConfig;
}

let stripePK = 
    production ? 'pk_live_51Hzpn7FNPGVHfbONtciOybx2dqTMWk1D3aIqZaT0Ajwm2IFdwfXWIwIEzKTSkF1tboZyByFgPIepdnU9oMd2Z29a00PhtMSsrJ' :
        'pk_test_51Hzpn7FNPGVHfbONp2hzX1rtUUBj1XBePL79kJawWXp76edD1A0bpWDPTL6qFQKb8qFfNsFQ2j2l4e6y4CCF0vI200PMr3nR9T';
//TODO: fix this to check __ODPS__ -- and prod flag set by bash script

/*
The following is config for a separate firebase app.
The auth in this app is used as an extra protective layer for 
test, dev & staging deployments.
Folks allowed to see those sites will need to log in to the gateway app
*/
const gatewayConfig = {
    apiKey: "AIzaSyDVHmkQ7h-IMy3qTllIVaSAera0yiNQMTg",
    authDomain: "diagnostic-driving-gateway.firebaseapp.com",
    databaseURL: "https://diagnostic-driving-gateway.firebaseio.com",
    storageBucket: "diagnostic-driving-gateway.appspot.com"
}

const groups = {
    driver : {
        name: "Drivers",
        role: "Driver",
        description: "Drivers can take assessments, view their own results," + 
            " and perform tasks within the system " +
            "that apply only to themselves.",
        members: {}
    },
    manager : {
        name: "Managers",
        role: "Manager",
        description: "Managers can view results for themselves and drivers" + 
            " that are assigned to them. " + 
            "They can also perform some tasks within the system that apply " +
            "to the drivers that they manage.",
        members: {}
    },
    super : {
        name: "Super Users",
        role: "Super User",
        description: "Super Users can manage settings, user roles, groups " + 
            "& permissions across the entire system. " + 
            "Super users can view assessment results for all users in the system.",
        members: {}
    }
}

const questionTypes = {
    text: "Basic Text",
    number: "Basic Number",
    multvert: "Vertical Multiple Choice",
    multhorz: "Horizontal Multiple Choice",
    allthatapply: "Select All That Apply",
    instruction: "Instruction",
    video: "Video",
    email: 'Email',
    date: 'Day, Month, Year'
}

//TODO: env vars for deployment environments -- could use hostnames for now?
const mainApp = firebase.initializeApp(deploymentConfig);
const addUserApp = firebase.initializeApp(deploymentConfig, "addUser");
const gatewayApp = firebase.initializeApp(gatewayConfig, 'gateway');
const db = mainApp.database();
const inviteServiceUrl = 'https://dxdnotifier.appspot.com/invite';
const analytics = firebase.analytics();

export const C = {
    // MISC
    FIREBASE: deploymentConfig,
    APP: mainApp,
    AU_APP: addUserApp,
    DB: db,
    GA: analytics,
    TS: firebase.database.ServerValue.TIMESTAMP,
    GATEWAY_APP: gatewayApp,
    INVITE_SERVICE_URL: inviteServiceUrl,
    STRIPE_PK: stripePK,
    EMAIL_JS_ID: "user_B4NZ73f6CkLUF1WEGY5eP",
    EMAIL_INVITE_TEMPLATE: "invite_1",
    EMAIL_ASSIGNMENT_TEMPLATE: "assignment_1",
    EMAIL_GENERIC_TEMPLATE: "generic_1",
    EMAIL_JS_GMAIL: "luke_s_dxd_gmail",
    COACHING_FIELD_SEPARATOR: "%*_COACH_*%",
    SUPER_DRIVER_IDS: [
        "camden_dxd",
        "david_dxd",
        "flaura_dxd",
        "ivan_dxd",
        "luke_dxd",
        "sean_dxd",
        "toni_dxd",
        "venk_dxd"
    ],

    //UI FEEDBACK ACTIONS
    DISPLAY_ERROR: "DISPLAY_ERROR",
    DISMISS_ERROR: "DISMISS_ERROR",
    DISMISS_ALL_ERRORS: "DISMISS_ALL_ERRORS",
    RECEIVE_STATIC_FEEDBACK_DATA: "RECEIVE_STATIC_FEEDBACK_DATA",
    SET_PDF_DOWNLOAD_URL: "SET_PDF_DOWNLOAD_URL",
    AWAIT_PDF_URL: "AWAIT_PDF_URL",
    PDF_GIVE_UP: "PDF_GIVE_UP",
    PDF_GENERATING: "PDF_GENERATING",
    SET_PDF_NOT_READY: "SET_PDF_NOT_READY",


    // AUTH ACTIONS
    ATTEMPTING_LOGIN: "ATTEMPTING_LOGIN",
    ATTEMPTING_REGISTER: "ATTEMPTING_REGISTER",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT: "LOGOUT",
    DEMAND_VERIFICATION: "DEMAND_VERIFICATION",
    AWAITING_VERIFICATION: "AWAITING_VERIFICATION",
    SET_ORGANIZATION: "SET_ORGANIZATION",
    SET_ATTEMPTED_PATH: "SET_ATTEMPTED_PATH",
    CLEAR_ATTEMPTED_PATH: "CLEAR_ATTEMPTED_PATH",
    SET_API_TOKEN_STATE: "SET_API_TOKEN_STATE",


    // AUTH STATES
    LOGGED_IN: "LOGGED_IN",
    ANONYMOUS: "ANONYMOUS",
    AWAITING_AUTH_RESPONSE: "AWAITING_AUTH_RESPONSE",
    UNINITIATED: "UNINITIATED",

    // DASH
    AWAIT_NEW_DASH_DATA: "AWAIT_NEW_DASH_DATA",
    RECEIVE_DASH_DATA: "RECEIVE_DASH_DATA",
    RECEIVE_DASH_ERROR: "RECEIVE_DASH_ERROR",
    CLEAR_WAIT: "CLEAR_WAIT",
    SET_COLUMNS: "SET_COLUMNS",
    SET_HEADER_NAMES: "SET_HEADER_NAMES",
    SET_COL_SIZES: "SET_COL_SIZES",
    SET_TIMESPAN: "SET_TIMESPAN",
    SET_ORGS: "SET_ORGS",
    RECEIVE_SCORING_OPTIONS: "RECEIVE_SCORING_OPTIONS",
    AWAIT_NEW_SCORING_OPTION_RESPONSE: "AWAIT_NEW_SCORING_OPTION_RESPONSE",
    RECEIVE_NEW_SCORING_OPTION_RESPONSE: "RECEIVE_NEW_SCORING_OPTION_RESPONSE",
    RECEIVE_ORG_TYPE_OPTIONS: "RECEIVE_ORG_TYPE_OPTIONS",
    AWAIT_NEW_ORG_TYPE_OPTION_RESPONSE: "AWAIT_NEW_ORG_TYPE_RESPONSE",
    RECEIVE_NEW_ORG_TYPE_OPTION_RESPONSE: "RECEIVE_NEW_ORG_TYPE_RESPONSE",
    SET_QRY_SCORING_METHOD: "SET_QRY_SCORING_METHOD",
    SET_QRY_AID: "SET_QRY_AID",
    SET_QRY_DIMENSIONS: "SET_QRY_DIMENSIONS",
    SET_CUSTOMER_IDS: "SET_CUSTOMER_IDS",
    SET_QRY_LIMIT: "SET_QRY_LIMIT",
    SET_QRY_CUSTOM_COLS: "SET_QRY_CUSTOM_COLS",
    SET_QRY_SHOW_ALL_ORGS: "SET_QRY_SHOW_ALL_ORGS",
    SET_DASH_ORG: "SET_DASH_ORG",
    SET_SHOW_EXCLUDED: "SET_SHOW_EXCLUDED",
    SET_HIDE_EXCLUDED: "SET_HIDE_EXCLUDED",

    // DRIVER IDS
    RECEIVE_DRIVER_IDS: "RECEIVE_DRIVER_IDS",

    //FORM ACTIONS
    FORM_UPDATE_VALUE: "FORM_UPDATE_VALUE",
    FORM_RESET: "FORM_RESET",

    //USERS ACTIONS
    RECEIVE_USERS_DATA: "RECEIVE_USERS_DATA",
    RECEIVE_USER_DATA: "RECEIVE_USER_DATA",

    // GROUPS ACTIONS
    RECEIVE_GROUPS_DATA: "RECEIVE_GROUPS_DATA",
    AWAIT_NEW_GROUP_RESPONSE: "AWAIT_NEW_GROUP_RESPONSE",
    RECEIVE_NEW_GROUP_RESPONSE: "RECEIVE_NEW_GROUP_RESPONSE",

    //PAY
    SET_CHECKOUT_LOADING: "SET_CHECKOUT_LOADING",

    // ORGS ACTIONS
    RECEIVE_ORGS_DATA: "RECEIVE_ORGS_DATA",
    AWAIT_NEW_ORG_RESPONSE: "AWAIT_NEW_ORG_RESPONSE",
    RECEIVE_NEW_ORG_RESPONSE: "RECEIVE_NEW_ORG_RESPONSE",
    INIT_DRIVE_LIMIT: "INIT_DRIVE_LIMIT",

    // WEBHOOK ACTIONS
    RECEIVE_WEBHOOKS: "RECEIVE_WEBHOOKS",
    AWAIT_NEW_WEBHOOK_RESPONSE: "AWAIT_NEW_WEBHOOK_RESPONSE",
    RECEIVE_NEW_WEBHOOK_RESPONSE: "RECEIVE_NEW_WEBHOOK_RESPONSE",
    AWAIT_WEBHOOK_DELETION: "AWAIT_WEBHOOK_DELETION",

    // GROUPS IDS
    DRIVER_GROUP: "driver",
    MANAGER_GROUP: "manager",
    SUPER_GROUP: "super",
    INIT_GROUPS: groups,

    //SIMULATE
    START_SIMULATING: "START_SIMULATING",
    END_SIMULATING: "END_SIMULATING",

    // DRIVE ACTIONS
    RECEIVE_DRIVE_DATA: "RECEIVE_DRIVE_DATA",
    AWAIT_NEW_DRIVE_DATA: "AWAIT_NEW_DRIVE_DATA",
    DRIVE_RESULTS_UPDATING: "DRIVE_RESULTS_UPDATING",
    DRIVE_RESULTS_UPDATED: "DRIVE_RESULTS_UPDATED",

    //DRIVER ACTIONS
    RECEIVE_DRIVER_DATA: "RECEIVE_DRIVER_DATA",
    AWAIT_NEW_DRIVER_DATA: "AWAIT_NEW_DRIVER_DATA",
    UPDATE_DRIVERS_DRIVE_DATA: "UPDATE_DRIVERS_DRIVE_DATA",

    //VIDEOS ACTIONS
    RECEIVE_VIDEOS_DATA: "RECEIVE_VIDEOS_DATA",
    AWAIT_NEW_VIDEO_RESPONSE: "AWAIT_NEW_VIDEO_RESPONSE",
    RECEIVE_NEW_VIDEO_RESPONSE: "RECEIVE_NEW_VIDEO_RESPONSE",

    //ASSIGNMENT
    AWAIT_ASSIGNMENT_DATA: "AWAIT_ASSIGNMENT_DATA",
    RECEIVE_ASSIGNMENT_DATA: "RECEIVE_ASSIGNMENT_DATA",

    //COUNTS
    SET_COUNT_DATA: 'SET_COUNT_DATA',

    //COACHING
    SUBMITTING_COACHING: "SUBMITTING_COACHING",
    COACHING_SUBMISSION_RECEIVED: "COACHING_SUBMISSION_RECEIVED",

    //COACHING ITEMS ACTIONS
    RECEIVE_COACHING_ITEMS_DATA: "RECEIVE_COACHING_ITEMS_DATA",
    AWAIT_NEW_COACHING_ITEM_RESPONSE: "AWAIT_NEW_COACHING_ITEM_RESPONSE",
    RECEIVE_NEW_COACHING_ITEM_RESPONSE: "RECEIVE_NEW_COACHING_ITEM_RESPONSE",

    //COACHING PAGES ACTIONS
    RECEIVE_COACHING_PAGES_DATA: "RECEIVE_COACHING_PAGES_DATA",
    AWAIT_NEW_COACHING_PAGE_RESPONSE: "AWAIT_NEW_COACHING_PAGE_RESPONSE",
    RECEIVE_NEW_COACHING_PAGE_RESPONSE: "RECEIVE_NEW_COACHING_PAGE_RESPONSE",

    //COACHING SURVEYS ACTIONS
    RECEIVE_COACHING_SURVEYS_DATA: "RECEIVE_COACHING_SURVEYS_DATA",
    AWAIT_NEW_COACHING_SURVEY_RESPONSE: "AWAIT_NEW_COACHING_SURVEY_RESPONSE",
    RECEIVE_NEW_COACHING_SURVEY_RESPONSE: "RECEIVE_NEW_COACHING_SURVEY_RESPONSE",
    SET_PRESCRIBED_SURVEY: "SET_PRESCRIBED_SURVEY",
    SET_CHOSEN_SURVEY: "SET_CHOSEN_SURVEY",

    //INTAKE SURVEY OPTIONS
    RECEIVE_NEW_INTAKE_SURVEY_OPTION_RESPONSE: "RECEIVE_NEW_INTAKE_SURVEY_OPTION_RESPONSE",
    AWAIT_NEW_INTAKE_SURVEY_OPTION_RESPONSE: "AWAIT_NEW_INTAKE_SURVEY_OPTION_RESPONSE",
    RECEIVE_INTAKE_SURVEY_OPTIONS_DATA: "RECEIVE_INTAKE_SURVEY_OPTIONS_DATA",

    //COACHING STEPS ACTIONS
    RECEIVE_COACHING_STEP_DATA: "RECEIVE_COACHING_STEP_DATA",
    AWAIT_NEW_COACHING_STEP_RESPONSE: "AWAIT_NEW_COACHING_STEP_RESPONSE",
    RECEIVE_NEW_COACHING_STEP_RESPONSE: "RECEIVE_NEW_COACHING_STEP_RESPONSE",

    //ALT SURVEYS
    AWAIT_NEW_ALT_SURVEY_RESPONSE: "AWAIT_NEW_ALT_SURVEY_RESPONSE",
    RECEIVE_NEW_ALT_SURVEY_RESPONSE: "RECEIVE_NEW_ALT_SURVEY_RESPONSE",

    //COACHING SURVEYS ACTIONS
    // RECEIVE_COACHING_SURVEY_DATA: "RECEIVE_COACHING_SURVEY_DATA",
    // AWAIT_NEW_COACHING_SURVEY_RESPONSE: "AWAIT_NEW_COACHING_SURVEY_RESPONSE",
    // RECEIVE_NEW_COACHING_SURVEY_RESPONSE: "RECEIVE_NEW_COACHING_SURVEY_RESPONSE",

    //COACHING RESPONSE ACTIONS
    RECEIVE_COACHING_RESPONSE_DATA: "RECEIVE_COACHING_RESPONSE_DATA",
    AWAIT_NEW_COACHING_RESPONSE_RESPONSE: "AWAIT_NEW_COACHING_RESPONSE_RESPONSE",
    RECEIVE_NEW_COACHING_RESPONSE_RESPONSE: "RECEIVE_NEW_COACHING_RESPONSE_RESPONSE",
    START_COACHING_RESPONSE: "START_COACHING_RESPONSE",

    //CONTENT ACTIONS
    AWAIT_NEW_CONTENT_DATA: "AWAIT_NEW_CONTENT_DATA",
    RECEIVE_CONTENT_DATA: "RECEIVE_CONTENT_DATA",

    //FEATURE FLAGS
    RECEIVE_FEATURE_FLAGS: "RECEIVE_FEATURE_FLAGS",

    // FEEDBACK ACTIONS
    RECEIVE_FEEDBACK_CONTENT: "RECEIVE_FEEDBACK_CONTENT",

    //INVITE ACTIONS
    RECEIVE_INVITE_DATA: "RECEIVE_INVITE_DATA",
    RECEIVE_INVITE_DATA_FOR_REGISTRATION: "RECEIVE_INVITE_DATA_FOR_REGISTRATION",
    AWAIT_INVITE_DATA: "AWAIT_INVITE_DATA",

    //QUESTION ACTIONS
    RECEIVE_QUESTIONS_DATA: "RECEIVE_QUESTIONS_DATA",
    AWAIT_NEW_QUESTION_RESPONSE: "AWAIT_NEW_QUESTION_RESPONSE",
    RECEIVE_NEW_QUESTION_RESPONSE: "RECEIVE_NEW_QUESTION_RESPONSE",
    PREFILL_QUESTION: "PREFILL_QUESTION",
    QUESTION_TYPES: questionTypes,

    //BUILDS ACTIONS
    RECEIVE_BUILDS_DATA: "RECEIVE_BUILDS_DATA",
    RECEIVE_BUILD_COMMENTS_DATA: "RECEIVE_BUILD_COMMENTS_DATA",
    AWAIT_NEW_BUILD_RESPONSE: "AWAIT_NEW_BUILD_RESPONSE",
    RECEIVE_NEW_BUILD_RESPONSE: "RECEIVE_NEW_BUILD_RESPONSE",
    RECEIVE_BUILD_UPLOAD_ERROR: "RECEIVE_BUILD_UPLOAD_ERROR",
    DELETE_BUILD: "DELETE_BUILD",
    DELETE_TAG: "DELETE_TAG",
    SET_BUILD_UPLOAD_PROGRESS: "SET_BUILD_UPLOAD_PROGRESS",
    START_BUILD_ACTION: "START_BUILD_ACTION",
    END_BUILD_ACTION: "END_BUILD_ACTION",


    //ASSETS ACTIONS
    RECEIVE_ASSET_DATA: "RECEIVE_ASSET_DATA",
    AWAIT_NEW_ASSET_RESPONSE: "AWAIT_NEW_ASSET_RESPONSE",
    RECEIVE_NEW_ASSET_RESPONSE: "RECEIVE_NEW_ASSET_RESPONSE",
    RECEIVE_ASSETT_UPLOAD_ERROR: "RECEIVE_ASSETT_UPLOAD_ERROR",
    DELETE_ASSET: "DELETE_ASSET",
    SET_ASSET_UPLOAD_PROGRESS: "SET_ASSET_UPLOAD_PROGRESS",
    START_ASSET_ACTION: "START_ASSET_ACTION",
    END_ASSET_ACTION: "END_ASSET_ACTION",
    RECEIVE_SINGLE_ASSET: "RECEIVE_SINGLE_ASSET",

    //CPT ACTIONS
    AWAIT_MODULE_DATA: "AWAIT_NEW_MODULE_DATA",
    RECEIVE_MODULE_DATA: "RECEIVE_MODULE_DATA",
    AWAIT_SCENARIO_DATA: "AWAIT_NEW_SCENARIO_DATA",
    RECEIVE_SCENARIO_DATA: "RECEIVE_SCENARIO_DATA",
    SET_CPT_INDEX: "SET_CPT_INDEX",
    AWAIT_ALL_SCENARIOS_DATA: "AWAIT_ALL_SCENARIOS_DATA",
    RECEIVE_ALL_SCENARIOS_DATA: "RECEIVE_ALL_SCENARIOS_DATA",
    RECEIVE_ALL_MODULES_DATA: "RECEIVE_ALL_MODULES_DATA",
    AWAIT_NEW_SCENARIO_JSON_RESPONSE: "AWAIT_NEW_SCENARIO_JSON_RESPONSE",
    RECEIVE_NEW_SCENARIO_JSON_RESPONSE: "RECEIVE_NEW_SCENARIO_JSON_RESPONSE",
    RECEIVE_SCENARIO_JSON_UPLOAD_ERROR: "RECEIVE_SCENARIO_JSON_UPLOAD_ERROR",
    AWAIT_NEW_MODULE_JSON_RESPONSE: "AWAIT_NEW_MODULE_JSON_RESPONSE",
    RECEIVE_NEW_MODULE_JSON_RESPONSE: "RECEIVE_NEW_MODULE_JSON_RESPONSE",
    RECEIVE_MODULE_JSON_UPLOAD_ERROR: "RECEIVE_MODULE_JSON_UPLOAD_ERROR",

    //DOCUMENTS ACTIONS
    RECEIVE_DOCUMENTS_DATA: "RECEIVE_DOCUMENTS_DATA",
    AWAIT_NEW_DOCUMENT_RESPONSE: "AWAIT_NEW_DOCUMENT_RESPONSE",
    RECEIVE_NEW_DOCUMENT_RESPONSE: "RECEIVE_NEW_DOCUMENT_RESPONSE",
    RECEIVE_DOCUMENT_UPLOAD_ERROR: "RECEIVE_DOCUMENT_UPLOAD_ERROR",
    DELETE_DOCUMENT: "DELETE_DOCUMENT",
    START_DELETE_DOCUMENT: "START_DELETE_DOCUMENT",
    END_DELETE_DOCUMENT: "END_DELETE_DOCUMENT",
    SET_DOCUMENT_UPLOAD_PROGRESS: "SET_DOCUMENT_UPLOAD_PROGRESS",

    //MAP ACTIONS
    AWAIT_NEW_MAP_RESPONSE: "AWAIT_NEW_MAP_RESPONSE",
    RECEIVE_NEW_MAP_RESPONSE: "RECEIVE_NEW_MAP_RESPONSE",
    RECEIVE_MAP_UPLOAD_ERROR: "RECEIVE_MAP_UPLOAD_ERROR",
    SET_MAP_UPLOAD_PROGRESS: "SET_MAP_UPLOAD_PROGRESS",
    RECEIVE_MAP_JSON_ERROR: 'RECEIVE_MAP_JSON_ERROR',
    RECEIVE_MAP_JSON: 'RECEIVE_MAP_JSON',

    //LAUNCHER ACTIONS
    AWAIT_NEW_MAC_LAUNCHER_RESPONSE: "AWAIT_NEW_MAC_LAUNCHER_RESPONSE",
    AWAIT_NEW_WIN_LAUNCHER_RESPONSE: "AWAIT_NEW_WIN_LAUNCHER_RESPONSE",
    RECEIVE_NEW_MAC_LAUNCHER_RESPONSE: "RECEIVE_NEW_MAC_LAUNCHER_RESPONSE",
    RECEIVE_NEW_WIN_LAUNCHER_RESPONSE: "RECEIVE_NEW_WIN_LAUNCHER_RESPONSE",
    RECEIVE_MAC_LAUNCHER_UPLOAD_ERROR: "RECEIVE_MAC_LAUNCHER_UPLOAD_ERROR",
    RECEIVE_WIN_LAUNCHER_UPLOAD_ERROR: "RECEIVE_WIN_LAUNCHER_UPLOAD_ERROR",
    SET_MAC_LAUNCHER_UPLOAD_PROGRESS: "SET_MAC_LAUNCHER_UPLOAD_PROGRESS",
    SET_WIN_LAUNCHER_UPLOAD_PROGRESS: "SET_WIN_LAUNCHER_UPLOAD_PROGRESS",
    RECEIVE_LAUNCHER_REFS: "RECEIVE_LAUNCHER_REFS",
    AWAIT_NEW_TEST_FILE_RESPONSE: "AWAIT_NEW_TEST_FILE_RESPONSE",
    SET_TEST_FILE_UPLOAD_PROGRESS: "SET_TEST_FILE_UPLOAD_PROGRESS",
    RECEIVE_TEST_FILE_UPLOAD_ERROR: "RECEIVE_TEST_FILE_UPLOAD_ERROR",
    RECEIVE_NEW_TEST_FILE_RESPONSE: "RECEIVE_NEW_TEST_FILE_RESPONSE",

    //ASSESSMENT ACTIONS
    AWAIT_ASSESSMENT_DATA: "AWAIT_ASSESSMENT_DATA",
    AWAIT_UNLOCKED_ASSESSMENTS: "AWAIT_UNLOCKED_ASSESSMENTS",
    AWAIT_LOCKED_ASSESSMENTS: "AWAIT_LOCKED_ASSESSMENTS",
    RECEIVE_ASSESSMENT_DATA: "RECEIVE_ASSESSMENT_DATA",
    RECEIVE_UNLOCKED_ASSESSMENTS: "RECEIVE_UNLOCKED_ASSESSMENTS",
    RECEIVE_LOCKED_ASSESSMENTS: "RECEIVE_LOCKED_ASSESSMENTS",


    //OVERVIEW ACTIONS
    RECEIVE_ASSESSMENTS_DATA: "RECEIVE_ASSESSMENTS_DATA",
    RECEIVE_ALL_ASSESSMENTS_DATA: "RECEIVE_ALL_ASSESSMENTS_DATA",
    RECEIVE_PROVISIONAL_DATA: "RECEIVE_PROVISIONAL_DATA",
    SET_OVERVIEW_TIME_RANGE: "SET_OVERVIEW_TIME_RANGE",
    SET_OVERVIEW_LASTX: "SET_OVERVIEW_LASTX",
    SET_OVERVIEW_CUSTOMER: "SET_OVERVIEW_CUSTOMER",
    SET_QUERY_TYPE: "SET_QUERY_TYPE",
    ALL_REPLAY_DOWNLOAD_URLS_UPDATED: "ALL_REPLAY_DOWNLOAD_URLS_UPDATED",
    UPDATE_REPLAY_DOWNLOAD_URL: "UPDATE_REPLAY_DOWNLOAD_URL",
    RECEIVE_ASSESSMENT_FOR_MANAGER: "RECEIVE_ASSESSMENT_FOR_MANAGER",
    SET_MANAGER_LOADED: "SET_MANAGER_LOADED",
    END_CALCULATING_SCORE: "END_CALCULATING_SCORE",
    START_CALCULATING_SCORE: "START_CALCULATING_SCORE",
    START_FORCE_LOAD : "START_FORCE_LOAD",
    END_FORCE_LOAD : "END_FORCE_LOAD",
    ERROR_FORCE_LOAD : "ERROR_FORCE_LOAD",
    START_SEND_PDF : "START_SEND_PDF",
    END_SEND_PDF : "END_SEND_PDF",
    ERROR_SEND_PDF : "ERROR_SEND_PDF",
    START_CLEAR_PDF : "START_CLEAR_PDF",
    END_CLEAR_PDF : "END_CLEAR_PDF",
    ERROR_CLEAR_PDF : "ERROR_CLEAR_PDF",
    RECEIVE_CUSTOMER_ASSESSMENTS_DATA: "RECEIVE_CUSTOMER_ASSESSMENTS_DATA",

    // ROLLUP ACTIONS
    RECEIVE_ROLLUP_DATA: "RECEIVE_ROLLUP_DATA",
    AWAIT_NEW_ROLLUP_RESPONSE: "AWAIT_NEW_ROLLUP_RESPONSE",
    SET_TIME_RANGE: "SET_TIME_RANGE",

    //PASSWORD RE$ET
    ATTEMPTING_PASSWORD_RESET: "ATTEMPTING_PASSWORD_RESET",
    PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",
    PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",

    //CUSTOMER ACTIONS
    RECEIVE_CUST_DATA: "RECEIVE_CUST_DATA",
    AWAIT_NEW_CUST_DATA: "AWAIT_NEW_CUST_DATA",
    RECEIVE_CUST_ASS_DATA: "RECEIVE_CUST_ASS_DATA",
    CUSTOMER_NOT_FOUND: "CUSTOMER_NOT_FOUND",

    //INTAKE ACTIONS
    SUBMITTING_INTAKE: "SUBMITTING_INTAKE",
    INTAKE_SUBMISSION_RECEIVED: "INTAKE_SUBMISSION_RECEIVED",
    RECEIVE_INTAKE_RESULT_DATA: "RECEIVE_INTAKE_RESULT_DATA",
    AWAIT_NEW_INTAKE_RESULT_DATA: "AWAIT_NEW_INTAKE_RESULT_DATA",
    RECEIVE_ALL_INTAKE_DATA: "RECEIVE_ALL_INTAKE_DATA",
    SET_OPEN_INTAKE_ID: "SET_OPEN_INTAKE_ID",
    RECEIVE_ALL_OPEN_INTAKE_DATA: "RECEIVE_ALL_OPEN_INTAKE_DATA",

    //DRIVE SORT STATE
    SET_SORT_STATE: "SET_SORT_STATE",

    //MY DRIVERS SORTING STATE
    SORT_DRIVERS_BY: "SORT_DRIVERS_BY",
    INIT_SORT_DRIVERS: "INIT_SORT_DRIVERS",
    SORT_BY_NAME: "SORT_BY_NAME",

    //RECONCILIATION
    FINISH_RECONCILIATION: "FINISH_RECONCILIATION",
    RESET_RECONCILIATION: "RESET_RECONCILIATION",

    // UTIL
    RECEIVE_OFFSET: "RECEIVE_OFFSET",
    SET_TITLE: 'SET_TITLE',
    SET_SUBTITLE: 'SET_SUBTITLE',
    CLEAR_TITLE: 'CLEAR_TITLE',
    RECEIVE_JSON_EDIT_DATA: 'RECEIVE_JSON_EDIT_DATA',
    RESET_JSON_EDIT_DATA: 'RESET_JSON_EDIT_DATA',

    //UI
    TOGGLE_MOBILE_NAV: "TOGGLE_MOBILE_NAV",
    HIDE_MOBILE_NAV: "HIDE_MOBILE_NAV",
    SHOW_MOBILE_NAV: "SHOW_MOBILE_NAV",

    // GATEWAY
    LOCK: "LOCK",
    UNLOCK: "UNLOCK",

    //ODPS
    //CUSTOMER KEYS
    ADDING_CUSTOMERS: "ADDING_CUSTOMERS",
    ADD_BASS_KEY_EXISTS_MSG: "ADD_BASS_KEY_EXISTS_MSG",
    ADD_PROVISIONAL_BASS_KEY_SUCCESS: "ADD_PROVISIONAL_BASS_KEY_SUCCESS",
    ADD_BASS_KEY_SUCCESS: "ADD_BASS_KEY_SUCCESS",
    ADD_PROVISIONAL_BASS_KEY_EXISTS_MSG: "ADD_PROVISIONAL_BASS_KEY_EXISTS_MSG",
    ADD_BASS_KEY_ERROR: "ADD_BASS_KEY_ERROR",
    BASS_KEY_DONE: "BASS_KEY_DONE",

    RECEIVE_LOCS_DATA: "RECEIVE_LOCS_DATA",
    RECEIVE_NEW_LOC_RESPONSE: "RECEIVE_NEW_LOC_RESPONSE",
    AWAIT_NEW_LOC_RESPONSE: "AWAIT_NEW_LOC_RESPONSE",

    RECEIVE_NEW_LOCALE_RESPONSE: "RECEIVE_NEW_LOCALE_RESPONSE",
    AWAIT_NEW_LOCALE_RESPONSE: "AWAIT_NEW_LOCALE_RESPONSE",

    SET_TOTAL_ASSESSMENTS: "SET_TOTAL_ASSESSMENTS",
    SET_RETRO_SCORING_COUNT: "SET_RETRO_SCORING_COUNT",
    SET_BQ_LOAD_COUNT: "SET_BQ_LOAD_COUNT",
    SET_FLAG_REMOVE_COUNT: "SET_FLAG_REMOVE_COUNT",
    SET_TRIM_THE_FAT_COUNT: "SET_TRIM_THE_FAT_COUNT",
    SET_ARCHIVING_COUNT: "SET_ARCHIVING_COUNT",

    RECEIVE_FILES_DATA: "RECEIVE_FILES_DATA",
    AWAIT_NEW_FILE_RESPONSE: "AWAIT_NEW_FILE_RESPONSE",
    RECEIVE_NEW_FILE_RESPONSE: "RECEIVE_NEW_FILE_RESPONSE",
    RECEIVE_FILE_UPLOAD_ERROR: "RECEIVE_FILE_UPLOAD_ERROR",
    SET_FILE_UPLOAD_PROGRESS: "SET_FILE_UPLOAD_PROGRESS"



};

export default C;
