import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  macUploading: false,
  winUploading: false,
  testUploading: false,
  macError: "",
  winError: "",
  testError: "",
  macProgress: 0,
  macTotalBytes: 0,
  macBytesTransferred: 0,
  winProgress: 0,
  winTotalBytes: 0,
  winBytesTransferred: 0,
  testProgress: 0,
  testTotalBytes: 0,
  testBytesTransferred: 0,
  mac: {},
  win: {},
  blobUrl: "",
  filename: "",
  lastFile: ""
};

export default (state = initialState, action) => {
  switch (action.type) {

    case C.AWAIT_NEW_MAC_LAUNCHER_RESPONSE:
        return Object.assign({},state,{
            macUploading: true,
            macError: ""
        });

    case C.AWAIT_NEW_WIN_LAUNCHER_RESPONSE:
        return Object.assign({},state,{
            winUploading: true,
            winError: ""
        });

    case C.AWAIT_NEW_TEST_FILE_RESPONSE:
        return Object.assign({},state,{
            testUploading: true,
            testError: ""
        });

    case C.RECEIVE_NEW_WIN_LAUNCHER_RESPONSE:
        return Object.assign({}, state, {
            winUploading: false,
            winError: "",
            winProgress: 0
        })

    case C.RECEIVE_NEW_MAC_LAUNCHER_RESPONSE:
        return Object.assign({}, state, {
            macUploading: false,
            macError: "",
            macProgress: 0
        })

    case C.RECEIVE_NEW_TEST_FILE_RESPONSE:
        return Object.assign({}, state, {
            testUploading: false,
            testError: "",
            testProgress: 0,
            lastFile: action.lastFile
        })

    case C.RECEIVE_MAC_LAUNCHER_UPLOAD_ERROR:
        return Object.assign({}, state, {
            macUploading: false,
            macError: action.error,
            macProgress: 0
        })

    case C.RECEIVE_WIN_LAUNCHER_UPLOAD_ERROR:
        return Object.assign({}, state, {
            winUploading: false,
            winError: action.error,
            winProgress: 0
        })

    case C.RECEIVE_TEST_FILE_UPLOAD_ERROR:
        return Object.assign({}, state, {
            testUploading: false,
            testError: action.error,
            testProgress: 0
        })

    case C.SET_MAC_LAUNCHER_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            macProgress: action.progress,
            macTotalBytes: action.totalBytes,
            macBytesTransferred: action.bytesTransferred
        })

    case C.SET_WIN_LAUNCHER_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            winProgress: action.progress,
            winTotalBytes: action.totalBytes,
            winBytesTransferred: action.bytesTransferred
        })

    case C.SET_TEST_FILE_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            testProgress: action.progress,
            testTotalBytes: action.totalBytes,
            testBytesTransferred: action.bytesTransferred
        })

    case C.RECEIVE_LAUNCHER_REFS:
        return Object.assign({}, state, {
              mac: action.mac,
              win: action.win,
              blobUrl: action.blobUrl,
              filename: action.filename
          })

      default:
        return state;
  }
}