import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import ModifyQueryForm from './components/modifyqueryform';
import SetQueryTimespanForm from './components/setquerytimespanform';
import DashWrapper from './components/dashWrapper';
import DashGrid from './components/dashGrid';
import Dialog from 'material-ui/Dialog';
import Drawer from 'material-ui/Drawer';
import queryString from 'query-string';


class FreeDash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            queryDrawerOpen : false,
            timespanDialogOpen : false
        }
    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    componentWillMount () {
        this.props.setTitle('Intelligence Dashboard EXTREME');
        store.dispatch( actions.startListeningToOrgs() );
    }

    handleQueryDrawerToggle() {
        this.setState({queryDrawerOpen: !this.state.queryDrawerOpen});
    }


    handleTimespanDialogOpen() {
        this.setState({timespanDialogOpen : true})
    }

    handleTimespanDialogClose() {
        this.setState({timespanDialogOpen : false})
    }

    handleCellClick(cellData) {

        let thisAid = cellData.data.id;
        let thisIndId = cellData.data.customerId
        console.log(cellData);
        if (['location', 'department'].includes(cellData.colDef.field)) {
            // this won't work. . . need a better way
            if (cellData.value) {
                let dimension = cellData.colDef.field == 'department' ? 'departmentId' : cellData.colDef.field;
                let value = cellData.colDef.field == 'department' ? cellData.data.departmentId : cellData.value;
                this.props.router.push('/dash/a/' + dimension + '/' + value);
                return;
            }
            
        }
        if (thisAid && thisIndId) {
            this.props.router.push('/dash/i/' + thisIndId);
        }
    }

    render () {
        
        const p = this.props;

        let isLoading = (p.dash.submittingnew || !(p.dash.hasReceivedData && p.orgs.hasReceivedOrgData) );

        let content = <div></div>

        const dashHeaderItems = [
            <button key="mdbt" onClick={this.handleQueryDrawerToggle.bind(this)}>
                Modify Query
            </button>,
            <Drawer
                docked={false}
                key="mddr"
                width={480}
                openSecondary={true}
                open={this.state.queryDrawerOpen}
                onRequestChange={(queryDrawerOpen) => this.setState({queryDrawerOpen})} >
                    <div style={{ padding : "50px 15px"}}>
                        <ModifyQueryForm
                            dash={p.dash}
                            orgs={p.orgs}
                            onSubmit={(function (e) {e.preventDefault(); p.modifyQuery(); }).bind(this) } />
                    </div>
            </Drawer>,
            <button key="tmbt" style={{marginRight: 10}} onClick={this.handleTimespanDialogOpen.bind(this)}>
                Set Timespan
            </button>,
            <Dialog
                title="Set Query Timespan"
                key="tmdl"
                modal={false}
                open={this.state.timespanDialogOpen}
                onRequestClose={this.handleTimespanDialogClose.bind(this)}>
                    <SetQueryTimespanForm
                        dash={p.dash}
                        onSubmit={(function (e) {e.preventDefault(); p.setQueryTimespan(); this.handleTimespanDialogClose(); }).bind(this) } />
             </Dialog>
        ]

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        if (p.dash.hasReceivedData && p.orgs.hasReceivedOrgData) {
            content = (
                <DashGrid
                    headerItems={dashHeaderItems}
                    loading={isLoading}
                    onCellClicked = {(function(cellData) {this.handleCellClick(cellData)}).bind(this)} />
            );
            
        }
        

        return (
            <DashWrapper {...this.props}>
                <div className='dash-box'>
                    {isLoading ? <div className="dash-curtain"></div> : null}
                    <div>
                        { isLoading ? (
                               <div className="loading">Loading&#8230;</div>
                            ) : null }
                        {content}
                    </div>
                </div>
            </DashWrapper>  
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {

        dash: appState.dash,
        orgs: appState.orgs,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        modifyQuery : function () { dispatch( actions.modifyQuery() ) },
        setQueryTimespan : function () { dispatch( actions.setQueryTimespan() ) },
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) }
    }
}

const RoutableDash = withRouter(FreeDash);


export default connect(mapStateToProps, mapDispatchToProps)(RoutableDash);
