import React, { Component } from 'react';
import { RIETextArea, RIEInput, RIENumber, RIEToggle, RIESelect } from 'riek'
import { C } from '../../constants';



class Editable extends Component {
    constructor(props, context) {
        super(props, context);
    }

    onSave(obj) {
        console.log("Editable Save!");
        console.log(obj);
        let path = this.props.fullPath;
        C.APP.database().ref(path).set(obj.val);
    }

    onNumericSave(obj) {
        console.log("Editable Numeric Save!");
        console.log(obj);
        let path = this.props.fullPath;
        C.APP.database().ref(path).set(parseInt(obj.val));
    }

    render () {

        const p = this.props;

        if (p.type == "text") {
            return <RIEInput
                value={p.value}
                change={this.onSave.bind(this)}
                propName="val" />
        }

        if (p.type == "number") {
            return <RIENumber 
                value={p.value}
                change={this.onNumericSave.bind(this)}
                propName="val" />
        }

        if (p.type == "toggle") {
            return <RIEToggle 
                value={p.value}
                change={this.onSave.bind(this)}
                propName="val" />
        }

        if (p.type == "select") {
            return <RIESelect options={p.options}
                value={p.value}
                change={this.onSave.bind(this)}
                propName="val" />
        }

        return (
            <RIETextArea
                value={p.value}
                change={this.onSave.bind(this)}
                options={p.options}
                propName="val" />
        )
    }
}

export default Editable;