import React, { Component } from 'react';
import ErrorPanel from './errorpanel';
import SmartForm from './Form';
import SubmitButton from './SubmitButton';
import TextInput from './TextInput';


class AddGroup extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        return (
            <SmartForm onSubmit={this.props.onSubmit}>
                <ErrorPanel />
                <TextInput
                    name="key"
                    validate={['required']}
                    placeholder="Type group database key"
                    label="Group Key" />
                <TextInput
                    name="name"
                    validate={['required']}
                    placeholder="Type group name"
                    label="Group Name" />
                <TextInput
                    name="description"
                    validate={['required']}
                    placeholder={"Describe ths group.\n" + 
                        "i.e What are users in this group allowed to do?"}
                    label="Describe this group" />
                <SubmitButton label="Add Group" />
            </SmartForm>
        )
    }
}

export default AddGroup;