
import _ from 'lodash';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
import Wrap from 'material-ui/svg-icons/content/forward';
import React, { Component } from 'react';
import Markdown from "react-markdown";
import { connect } from 'react-redux';
import actions from '../actions';
import C from '../constants';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddQuestionForm from './components/addquestionform';
import Editable from './components/editable';


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToCoachingItems() );
    store.dispatch( actions.startListeningToCoachingPages() );
});

class CoachingItemAdmin extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount CoachingItemAdmin");
        startListening();
    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;

        console.log("rendering coaching item admin page");

        const coachingItems = p.coachingItems;

        const coachingItemRows = _.map(coachingItems.data, (q, qid) => {
            let hasPage = false;
            if (q.usedBy) {
                _.map(q.usedBy, (v, k) => {
                    let page = p.coachingPages.data[k];
                    if (page && page.name == q.name) {
                        hasPage = true;
                    }
                })
            }
            return <div key={qid} className="row q-row">
                <div className="col-sm-1" style={{overflow: 'hidden'}}>{q.name}</div>
                <div className="col-sm-1">{C.QUESTION_TYPES[q.type]}</div>
                <div className="col-sm-4"><Editable type="textarea" value={q.text} fullPath={'coachingItems/' + qid + '/text'}  /></div>
                <div className="col-sm-3">
                    {_.map(q.choices, (chc, k) => {
                        return (
                                <div className="q-r-chc" key={qid + '-chc-' + k}> 
                                    <span className="chc-lbl">v: </span> {chc.value ? <Editable type="text" value={chc.value} fullPath={'coachingItems/' + qid + '/choices/' + k + '/value'}  /> : "NOVALUE"}
                                    <span className="chc-lbl"> d: </span> {chc.display ? <Editable type="text" value={chc.display} fullPath={'coachingItems/' + qid + '/choices/' + k + '/display'}  /> : "NOVALUE"}
                                </div>
                                )
                    })}
                </div>
                <div className="col-sm-1">{q.required ? "yes" : "no"}</div>
                <div className="col-sm-1">
                    <IconButton
                        disabled={hasPage}
                        onClick={ function () { p.wrapCoachingItem(qid, q.name) }}
                        tooltip={ hasPage ? "A coaching page has already been created with just this item." : "Make a coaching page with just this item."}>
                        <Wrap className="q-wrap" />
                    </IconButton>
                </div>
                <div className="col-sm-1">
                    <IconButton
                        disabled={!!q.usedBy}
                        onClick={ function () { p.deleteCoachingItem(qid) }}
                        tooltip={ q.usedBy ? "Currently being used" : "Delete Coaching Item"}>
                        <Delete className="q-trash" />
                    </IconButton>
                    {/*
                    <IconButton disabled={true} tooltip="Edit Coaching Item">
                        <Create className="q-edit" />
                    </IconButton>
                    */}  
                    {/* This is bound to "Questions" -- need to untangle so that we can use it for Coaching Items as well
                    <IconButton onClick={ function () { p.load(qid); }} tooltip="Copy coaching item values into form">
                        <MoveUp className="q-dupe" />
                    </IconButton>
                    */}
                </div>
            </div>
        })

        const rows = <div>
            {coachingItems.hasReceivedData ? (
                <div className="q-rows">
                    <div className="row q-row">
                        <div className="col-sm-1">Name</div>
                        <div className="col-sm-1">Type</div>
                        <div className="col-sm-5">Text</div>
                        <div className="col-sm-2">Choices</div>
                        <div className="col-sm-1">Required?</div>
                        <div className="col-sm-1">Make Page</div>
                        <div className="col-sm-1">Delete</div>
                    </div>
                    {coachingItemRows}
                </div>
            ) : "Loading coaching items..."}
        </div>

        const noQs = <div> No coaching items exist </div>
        
        console.log("coaching item rows", coachingItemRows);

        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <AddQuestionForm
                            className="add-question-form"
                            onSubmit={(function (e) {e.preventDefault(); this.props.addCoachingItem(); }).bind(this) }>
                        </AddQuestionForm>


                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        { coachingItemRows.length ? rows : noQs }
                    </div>
                </div>
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        coachingItems: appState.coachingItems,
        coachingPages: appState.coachingPages
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addCoachingItem: function () {
            dispatch( actions.addCoachingItem() )
        },
        deleteCoachingItem: function (qid) {
            dispatch( actions.deleteCoachingItem( qid ) )
        },
        wrapCoachingItem: function(qid, qName) {
            dispatch( actions.wrapCoachingItem( qid, qName ) )
        },
        load: function(qid) { dispatch(actions.dupeCoachingItem(qid)); } // this needs to be removed from the "question" logic
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoachingItemAdmin);
