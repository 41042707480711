import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  offset : 0,
  title : '',
  subtitle : '',
  attemptedPath : '',
  jsonEditData : {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_OFFSET:
        return Object.assign({}, state, {
          offset : action.offset
        });

    case C.SET_TITLE:
        return Object.assign({}, state, {
          title : action.title
        });

    case C.SET_SUBTITLE:
        return Object.assign({}, state, {
          subtitle : action.subtitle
        });

    case C.CLEAR_TITLE:
        return Object.assign({}, state, {
          title : '',
          subtitle : ''
        });

    case C.SET_ATTEMPTED_PATH:
        return Object.assign({}, state, {
            attemptedPath: action.pathname
        });

    case C.CLEAR_ATTEMPTED_PATH:
        return Object.assign({}, state, {
            attemptedPath: ""
        });

    case C.RECEIVE_JSON_EDIT_DATA:
        return Object.assign({}, state, {
          jsonEditData: action.data
        });
    
    case C.RESET_JSON_EDIT_DATA:
        return Object.assign({}, state, {
          jsonEditData: {}
        });

      default:
        return state;
  }
}