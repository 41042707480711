import emailValidator from 'email-validator';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton } from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { RadioButtonGroup, SelectField, TextField } from 'redux-form-material-ui';

const radioButtonStyle = {
    marginBottom: 16
};

const validate = values => {
    const errors = {}
    if (!values[ "email" ]) {
        errors[ "email" ] = 'Required'
    } else {
        if (!emailValidator.validate(values["email"])) {
            errors[ "email" ] = 'Invalid email'
        }
    }

    if (!values[ "password" ]) {
        errors[ "password" ] = 'Required'
    }

    if (!values[ "firstname" ]) {
        errors[ "firstname" ] = 'Required'
    }

    if (!values[ "lastname" ]) {
        errors[ "lastname" ] = 'Required'
    }

    if (!values[ "group" ]) {
        errors[ "group" ] = 'Required'
    }

    if (!values[ "organization" ]) {
        errors[ "organization" ] = 'Required'
    }


    return errors
}

class AddUserForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        // NB: removed the "create account" button
        // We only allow account creation via invite and/or admin/super
        console.log("rendering add user form");

        return (
            <div>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    <div>
                        <Field
                            name="firstname"
                            component={TextField}
                            floatingLabelText="First Name"
                            hintText="Type user's first name" />
                    </div>
                    <div>
                        <Field
                            name="lastname"
                            component={TextField}
                            floatingLabelText="Last Name"
                            hintText="Type user's last name" />
                    </div>
                    <div>
                        <Field
                            name="email"
                            component={TextField}
                            floatingLabelText="Email"
                            hintText="Type user's email" />
                    </div>
                    <div>
                        <Field
                            name="password"
                            component={TextField}
                            floatingLabelText="Password"
                            hintText="Type user's password" />
                    </div>
                    <div>
                        <Field
                            name="group"
                            component={RadioButtonGroup}

                            style={{ paddingTop: "25px" }}>
                            <RadioButton
                                value="driver"
                                label="Driver"
                                style={radioButtonStyle}/>
                            <RadioButton
                                value="manager"
                                label="Manager"
                                style={radioButtonStyle}/>
                            <RadioButton
                                value="super"
                                label="Super"
                                style={radioButtonStyle}/>
                        </Field>
                    </div>
                    <div>
                        <Field
                            name="organization"
                            fullWidth={true}
                            component={SelectField}
                            floatingLabelText="Organization">
                            {
                                _.map(this.props.orgs.data, (item, key) => {
                                    return <MenuItem value={key} key={key} primaryText={item.name} />
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Add"
                            type="submit"
                            style={{ width: "100%", marginTop: "15px" }} />
                    </div>

                </form>
            </div>
        )

        
    }
}

// Decorate the form component
AddUserForm = reduxForm({
  form: 'addUser', // a unique name for this form
  validate
})(AddUserForm);

export default AddUserForm;