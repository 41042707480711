import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { SelectField, Slider, TextField } from 'redux-form-material-ui';
import actions from '../../actions';
import store from '../../store';

//TODO: require that numSteps is an integer

class CreateCoachingSurveyForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        store.dispatch( actions.startListeningToOrgs() );
    }

    render () {
        const p = this.props;

        // console.log("********createCoachingSurveyForm p.org: ", p.org)

        var formToRender;
        let steps = [];

        if (p.numberOfSteps && p.numberOfSteps > 0) {
            for (let i = 0; i < p.numberOfSteps; i++) {
                console.log("***********adding step", i);
                let stepNumber = "" + (i + 1);
                steps.push(
                    //make these steps into step selectors (pull in from DB like we do with videos in VideoForm)
                    <div className="q-choice" key={"step_" + i}>
                        <h6 className="choice-header"><b>Step {stepNumber}</b></h6>
                        <div className="intake-choice">

                            <Field
                                name={"step_" + stepNumber}
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText={"Step " + stepNumber}>
                                {
                                    _.map(p.steps.data, (item, key) => {
                                        return <MenuItem value={key} key={key} primaryText={item[2].last} />
                                    })
                                }
                            </Field>

                        </div>
                    </div>
                )
            }

            formToRender = <Paper style={{ margin: "30px", padding: "25px"}}>
                <form  className={this.props.className} 
                            onSubmit={this.props.onSubmit} >
                        <div>
                            <Field 
                                name="label"
                                component={TextField}
                                fullWidth={true}
                                rows={2}
                                hintText="Give this coaching survey a label"
                                floatingLabelText="Survey Label"/>
                        </div>

                        {steps}

                        <div style={{textAlign:"right"}}>
                            <RaisedButton
                                primary
                                disabled={this.props.pristine || !this.props.valid}
                                label="Add"
                                type="submit"
                                style={{ width: "20%", marginTop: "15px" }} />
                        </div>
                </form>
                </Paper>;
        } else {
            formToRender = '';
            console.log("form to render is undefined!***********See CreateCoachingSurveyForm")
        }

        return (
            <div>
                <Paper style={{margin: "30px", padding: "25px"}}>
                    <div className="slider-box">
                    <label htmlFor="numberofsteps">{"How many steps should this Coaching Survey have?"}</label>
                        <div className="ccc-box">{p.numberOfSteps}</div>
                        <Field
                            name="numberofsteps"
                            component={Slider}
                            format={function (value, name) {
                                console.log("formatting slider value", value, name);
                                if (value === '') {
                                    return 0
                                }

                                return parseInt(value);
                            }}
                            defaultValue={0}
                            min={0}
                            max={20}
                            step={1}/>
                    </div>

                    {formToRender}

                </Paper>
            </div>
            
        )
    }
}

// Decorate the form component
CreateCoachingSurveyForm = reduxForm({
  form: 'createCoachingSurveyForm', // a unique name for this form
})(CreateCoachingSurveyForm);

//get the input value from createCoachingSurveyForm -- formValueSelector function is from redux-form
const selector = formValueSelector('createCoachingSurveyForm');
CreateCoachingSurveyForm = connect(
  state => {
    // send numSteps to redux state
    const numberOfSteps = selector(state, 'numberofsteps')
    const label = selector(state, 'label')
    return {
      numberOfSteps,
      label,
    }
  }
)(CreateCoachingSurveyForm)

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        video: appState.video,
        org: appState.orgs,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        //not used atm
        resetForm: function () {
            dispatch(reset('createCoachingSurveyForm'));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoachingSurveyForm);