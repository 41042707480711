import React, { Component } from 'react';
import Loadable from 'react-loadable';
import Loading from './Loading';

const Lazy = Loadable({
  loader: () => import('./replayviewer'),
  loading: Loading,
})

export default class LazyReplayViewer extends Component {
  render() {
    console.log(Lazy);
    return <Lazy {...this.props} />;
  }
}