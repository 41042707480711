
import React, { Component } from 'react';
import { connect } from 'react-redux';
import YouTubePlayer from 'youtube-player';
import YT from 'react-youtube';
import _ from 'lodash';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import store from '../../store';
import actions from '../../actions';
import vidArrows from '../../assets/images/vidArrows.png';

// var object = arr.reduce((obj, item) => (obj[item.key] = item.value, obj) ,{});

function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
  
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
  
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
  
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
  
    return ret;
  }

class RequiredVideoBox extends Component {

    constructor(props) {

        let videos = {};
        let open = true;
        let pastState = props.assessmentData && props.assessmentData.feedbackVideos || {}
        let completedAll = true;
        for (var i = 0; i < props.videos.length; i++) {
            let vid = props.videos[i];
            completedAll = completedAll && pastState[vid.id];
            videos[vid.id] = {index: i, completed : pastState[vid.id] || false, progress: 0, open, player: null, data: vid};
            open = false;
        }

        if (completedAll) {
            if(props.onComplete) {
                props.onComplete();
            }
        }
        super(props);
        this.state = {
            videos, // map of video ids to progress
            startedWatching: false
        };
        console.log("CONSTRUCTOR", this.state.videos, props.videos);
    }

    handleVideoClick(id) {
        let videostate = this.state.videos;
        let newV = {};
        console.log("YOUTUBE", YT.PlayerState, videostate);
        _.map(videostate, (v, vid) => {
            console.log("ABRACADABRA", v, vid);
            newV[vid] = v;
            if (id == vid) {
                newV[vid].open = true;
            } else {
                newV[vid].open = false;
                let plyr = newV[vid].player;
                if (plyr) {
                    plyr.pauseVideo();
                }
                
            }
            
        })
        this.setState({videos: newV});
    }

    handleVideoClose(id) {
        
    }

    handleVideoEvent(id, event) {
        console.log("VIDEO EVENT FOR " + id + " AKA " + this.state.videos[id].data.label, event);
        // LOG event to assessment
        if (event.data == 1) {
            
            this.setState({startedWatching: true});
            if(this.props.onComplete) {
                this.props.onComplete();
            }
        }
        if (event.data == 0) {
            // video completed
            let videostate = this.state.videos;
            let newV = {};
            let completedAll = true;
            let count = 0;
            let completed = 0;
            _.map(videostate, (v, vid) => {
                newV[vid] = v;

                if (id == vid) {
                    newV[vid].completed = true;


                } 

                completedAll = completedAll && newV[vid].completed;

                count++;
                if (newV[vid].completed) {
                    completed++;
                }
                
            })
            store.dispatch( actions.updateFeedbackVideoProgress(this.props.aid, newV) );
            this.setState({videos: newV});
            if (completedAll) {
                if(this.props.onComplete) {
                    this.props.onComplete();
                }
            }
        }
    }

    componentDidMount() {
        // let componentScope = this;
        // let videoState = this.state.videos;
        // let mobile = screen.width <= 479;
        // for (var i = 0; i < this.props.videos.length; i++) {
        //     let video = this.props.videos[i];
        //     let vidVars = mobile ? {
        //         width: screen.width + '',
        //         height: (screen.width / 1.7778) + '',
        //         videoId: video.id,
        //         playerVars: {
        //             'playsinline': 1,
        //             //   'controls': 0
        //         }
        //     } : { 
        //         height: '405',
        //         width: '720',
        //         videoId: video.id,
        //         playerVars: {
        //             'playsinline': 1,
        //             //   'controls': 0
        //         }
        //     }
        //     let player = YouTubePlayer('player_' + video.id, vidVars);
        //     console.log("WHERE IS THE PLAYER", player)
        //     videoState[video.id].player = player;
        //     this.setState({videos: videoState})
        //     // player.on('stateChange', (event) => {
        //     //     this.handlePlayerEvent(video.id, event)
        //     // });
        //     player.on('stateChange', (function(vid){
        //         return function (event) {
        //             componentScope.handleVideoEvent(vid, event);
        //         }
        //     })(video.id));
        // };
        
    }

    componentWillReceiveProps(nextProps) {
        for (var i = 0; i < nextProps.videos.length; i++) {
            let vid = nextProps.videos[i];
            if (!this.state.videos[vid.id]) {
                console.log("RECEIVING NEW PROPS, new video that wasn't present", vid.id);
            }
        }
    }

    render() {
        const p = this.props;

        let vidBoxHeight = 62 * p.videos.length;
        let mobile = screen.width <= 479;

        if (mobile) {
            return <div className='mobile-video-box'>
                <div className='mobile-vid-container'>
                            {
                                (function(){
                                    
                                    let vids = [];
                                    for (var i = 0; i < p.videos.length; i++) {
                                        let vid = p.videos[i];
                                        vids.push(  <div key={"mblvidbx_" + i}>
                                                        <h3 className='vid-title'>
                                                            {vid.title || vid.label} &nbsp; {fancyTimeFormat(vid.seconds)}
                                                            {this.state.videos[vid.id].completed ? <CheckCircle style={{width: 20, height: 20}} color="#FFF" className="check-circle-mobile" /> : null}
                                                        </h3>
                                                        <div id={"player_" + vid.id}>
                                                        <YT
                                                            videoId={vid.id}                  
                                                            id={vid.id}                       
                                                            className={'mobile-feedback-video feedback-video'}                
                                                            iframeClassName={'feedback-video-iframe'}          
                                                            style={{}}                    
                                                            title={vid.title}                    
                                                            loading={'eager'}                  
                                                            opts={{
                                                                width: screen.width + '',
                                                                height: (screen.width / 1.7778) + '',
                                                                videoId: vid.id,
                                                                playerVars: {
                                                                    'playsinline': 1,

                                                                }
                                                            }} 
                                                            onReady={(function (e) {
                                                                let player = e.target;
                                                                this.state.videos[vid.id].player = player;
                                                            }).bind(this)}
                                                            onStateChange={(function (e) {
                                                                this.handleVideoEvent(vid.id, e)
                                                            }).bind(this)}              

                                                            />
                                                        </div>
                                                    </div>);
                                    }
                                    return vids;
                                }).bind(this)()
                            }
                        </div>
            </div>
        }

        return (

            <div className="video-link-box">
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <div className="fbr-sct-orng bigger-vid">Your Assigned Videos</div>
                <div className='vidArrowWrap'>
                    <img className={'vidArrowImg' + (this.state.startedWatching ? ' vidHide' : '') } alt="" src={vidArrows} />
                </div>
                <div className="fbr-sct-cntnt vid-div" style={{minHeight: vidBoxHeight + "px"}}>
                    <div className='vidSideNav'>
                        {
                            (function(){
                                let buttons = [];
                                let topOffset = 20;
                                for (var i = 0; i < p.videos.length; i++) {
                                    let vid = p.videos[i];
                                    let video = this.state.videos[vid.id];
                                    buttons.push(<a key={"vidbt_" + i} style={{top: topOffset + "px"}} className={'vid_btn vid_btn_' + i % 4} href="#" onClick={(function (e) {
                                            e.preventDefault();
                                            this.handleVideoClick(vid.id);
                                            return false;
                                        }).bind(this)} id={vid.id}>
                                            {vid.label}{video && video.completed ? <CheckCircle style={{width: 30, height: 30}} color="#FFF" className="check-circle-icon" /> : null}
                                        </a>)
                                    topOffset += 60;
                                }
                                return buttons;
                            }).bind(this)()
                        }
                    </div>
                    
                    <div style={{marginLeft : "65px"}}>
                        <div className='vid-instructions'>Hover over the buttons on the left to switch between videos</div>
                        <div className='vid-container'>
                            {
                                (function(){
                                    
                                    let vids = [];
                                    for (var i = 0; i < p.videos.length; i++) {
                                        let vid = p.videos[i];
                                        console.log("BEFORE_ERROR", this.state.videos, vid, i , p.videos);
                                        let open = this.state.videos && this.state.videos[vid.id] && this.state.videos[vid.id].open;
                                        vids.push(  <div key={"vidbx_" + i} style={{display: (open ? "block" : "none")}}>
                                                        <h3 className='vid-title'>{vid.title || vid.label} &nbsp; {fancyTimeFormat(vid.seconds)}</h3>
                                                        <div id={"player_" + vid.id}>

                                                            <YT
                                                                videoId={vid.id}                  
                                                                id={vid.id}                       
                                                                className={'feedback-video'}                
                                                                iframeClassName={'feedback-video-iframe'}          
                                                                style={{}}                    
                                                                title={vid.title}                    
                                                                loading={'eager'}                  
                                                                opts={{
                                                                    width: '720',
                                                                    height: '405',
                                                                    videoId: vid.id,
                                                                    playerVars: {
                                                                        'playsinline': 1,

                                                                    }
                                                                }} 
                                                                onReady={(function (e) {
                                                                    let player = e.target;
                                                                    this.state.videos[vid.id] = this.state.videos[vid.id] || {};
                                                                    this.state.videos[vid.id].player = player;
                                                                }).bind(this)}
                                                                onStateChange={(function (e) {
                                                                    this.handleVideoEvent(vid.id, e)
                                                                }).bind(this)}              

                                                                />

                                                        </div>
                                                    </div>);
                                    }
                                    return vids;
                                }).bind(this)()
                            }
                        </div>
                    </div>
                </div>
            </div>
                
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequiredVideoBox);

