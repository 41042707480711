import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';


class ForceBQButton extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {
        const p = this.props;
        const aid = p.aid;
        const flSt = p.overview.forceLoadState[aid] || {};

        console.log('Force button', aid);


        let content = <RaisedButton
            primary
            onClick={(function (e) { p.forceBQ(aid); }).bind(this) }
            label="Force BQ" />

        if (flSt.loading) {
            content = <CircularProgress />
        }

        if (flSt.done) {
            content = <span>Loaded!</span>
        }


        return (
            <div>
                { content }
                { flSt.duration ? <div className="force-load-duration" style={{fontSize : "80%", paddingTop: "10px"}}> Last force load attempt took {flSt.duration} milliseconds </div> : null }
            </div>
            
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        overview: appState.overview
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        forceBQ : function (aid) {
            dispatch( actions.forceBQ(aid))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ForceBQButton);