import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';



class EmailForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        return (
            <form onSubmit={this.props.onSubmit}>
                <Field
                    name="email"
                    component={TextField}
                    floatingLabelText="Email Address"
                    hintText="Enter your email" />

                <RaisedButton
                    primary
                    disabled={this.props.pristine || !this.props.valid}
                    label="Submit"
                    type="submit" />

            </form>
        )
    }
}

/*
function mapStateToProps(state, ownProps) {
stateValues = state;
const result = {
initialValues: {
grade_part: ownProps.profile.gradePart,
subject: ownProps.profile.subject,
}
};
return result;
}
*/

// Decorate the form component
EmailForm = reduxForm({
  form: 'email' // a unique name for this form
})(EmailForm);




export default EmailForm;