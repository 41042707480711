
import IconButton from 'material-ui/IconButton';
import CloudDownload from 'material-ui/svg-icons/file/cloud-download';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import Spinner from './components/spinner';


const styles = {

  largeIcon: {
    width: 120,
    height: 120,
  },
  large: {
    width: 240,
    height: 240,
    padding: 30,
  },
};

class DownloadPdf extends Component {

    componentWillMount () {
        document.body.style.background = "#FFF";
        const p = this.props;
        const aid = p.params && p.params.aid;
        const v2 = p.params && p.params.v2;
        store.dispatch( actions.loadPDF(aid, v2) );

    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    render () {
        // use window.navigator.platform -- search for  MacIntel or Win**
        // Otherwise -- show message - no download for the platform you are currently on
        const p = this.props;
        const aid = p.params && p.params.aid;
        const pdfs = p.feedback.pdfs;

        if (!pdfs[aid]) {
            return <div>Fetching Feedback Report...</div>
        }

        const pdf = pdfs[aid];

        if(pdf.notReady) {
            return <div className="dl-outer">
                        <div className="dl-inner">
                            <div>The data required to generate this feedback report is not available.</div>
                            <div>You may have tried to access this report to soon.</div>
                            <div>If so, just wait a few minutes and come back.</div>
                            <div>If it has been more than a 5 minutes since the assessment was completed, something else may have gone wrong.</div>
                            <div>In this case, contact support@diagnosticdriving.com.</div>
                        </div>
                    </div>
        }

        if (pdf.failed) {
            return <div className="dl-outer"><div className="dl-inner">Something went wrong.</div></div>
        }

        if (pdf.loading) {
            return <div className="dl-outer"><div className="dl-inner">Fetching Feedback Report...</div></div>
        }

        if (pdf.generating) {
            return <div className="dl-outer"><div className="dl-inner">No PDF found. Generating Feedback Report. (This could take a second, thanks for your patience.)<Spinner /></div></div>
        }

        return (

                <div className="dl-outer">
                    <div
                        className="dl-inner"
                        style={styles.large}>
                        <a
                            href = {pdf.url}
                            target = "_blank"
                            download = {'feedback_report_' + aid + '.pdf'}
                        >
                            <IconButton
                                iconStyle={styles.largeIcon}>
                                    <CloudDownload />

                            </IconButton>
                            <div style={{textAlign: "center", fontWeight: "bold", position:"relative", "left": 48, "top": -20}}>
                                Click to Download Feedback Report
                            </div>
                        </a>
                    </div>
                </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        feedback: appState.feedback
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DownloadPdf);

