import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {C} from '../constants';
import actions from '../actions';
import store from '../store';
import SetQueryTimespanForm from './components/setquerytimespanform';
import DashGrid from './components/dashGrid';
import DashWrapper from './components/dashWrapper';
import Dialog from 'material-ui/Dialog';
import queryString from 'query-string';


const SUGGESTION_MAP = {
    speed: "speeding",
    steeringCornering: "steering/cornering",
    hardBraking: "hard braking",
    cellPhoneUse: "mobile phone use"
}

const PRETTY_NAMES = {
    id : "Assessment ID",
    customerId : "Driver ID",
    userEmail : "User Email",
    userFirstName: "User First Name",
    userLastName: "User Last Name",
    organizationId: "Organization ID",
    organizationName: "Organization Name",
    feedbackEmail: "Feedback Email",
    department: "Department",
    startDate: "Date",
    startTime: "Time",
    status: "Status",
    trackStart: "Track Start",
    secondsInPractice: "Practice Duration (seconds)",
    practiceInProgress: "Practice In Progress",
    brakingPracticeIncomplete: "Completed Braking Practice",
    setGearToPark: "Passed Set Gear To Park",
    lookLeft: "Passed Look Left",
    lookRight: "Passed Look Right",
    signalRight: "Passed Signal Left",
    turnOffSignal: "Passed Turn Off Signal",
    map: "Map",
    variant: "Variant",
    flags: "Flags",
    events: "Has Events",
    replay: "Replay",
    replayUrl: "Replay URL",
    end: "End Time",
    completed: "Completed",
    assessmentDuration: "Assessment Duration (seconds)",
    totalDuration: "Practice & Assessment Duration (seconds)",
    completeDuration: "Total Duration",
    location: "Location",
    riskLevel: "Risk Level",
    riskScore: "Risk Score",
    riskMessage: 'Risk',
    vehicleCollisions: 'Vehicle Collisions',
    pedestrianCollisions: 'Pedestrian Collisions',
    noviceGrade: "Grade",
    noviceLevel: "Level",
    novicePercentile: "Percentile",
    experiencedGrade: "Grade",
    experiencedLevel: "Level",
    experiencedPercentile: "Percentile"
}

class DimensionDash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            timespanDialogOpen : false
        }
    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    componentWillMount () {
        // let init = this.props.dash.init;
        this.props.setTitle('Dimension-Level Report');
    }


    handleTimespanDialogOpen() {
        this.setState({timespanDialogOpen : true})
    }

    handleTimespanDialogClose() {
        this.setState({timespanDialogOpen : false})
    }

    render () {
        
        const p = this.props;

        const dimensionName = this.props.params.dimensionName;
        const dimensionValue = this.props.params.dimensionValue;

        let suggestions = null;

        let isLoading = (p.dash.submittingnew || !p.dash.hasReceivedData);

        if (p.dash.data.totalCount && dimensionName && dimensionValue) {
            console.log('total count exists, render title content', p.dash.data.totalCount);
            titleContent = <div>Average scores for assessments where {PRETTY_NAMES[dimensionName] || dimensionName} is {dimensionValue}. There were {p.dash.data.totalCount} total assessments.</div>
        }

        let content = <div></div>

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        const dashHeaderItems = [
            <button key="tmbt" style={{marginRight: 10}} onClick={this.handleTimespanDialogOpen.bind(this)}>
                Set Timespan
            </button>,
            <Dialog
                title="Set Query Timespan"
                key="tmdl"
                modal={false}
                open={this.state.timespanDialogOpen}
                onRequestClose={this.handleTimespanDialogClose.bind(this)}>
                    <SetQueryTimespanForm
                        dash={p.dash}
                        onSubmit={(function (e) {e.preventDefault(); p.setQueryTimespan(); this.handleTimespanDialogClose(); }).bind(this) } />
             </Dialog>
        ]

        if (p.dash.hasReceivedData) {
            content = (
                <DashGrid
                    loading={isLoading}
                    headerItems={dashHeaderItems}/>
            );

            if (p.dash.data.aggregateSuggestions && p.dash.data.totalCount) {
                let suggsItems = [];
                _.map(p.dash.data.aggregateSuggestions, (count, suggKey) => {
                    if (count >= p.dash.data.totalCount / 2) {
                        suggsItems.push(SUGGESTION_MAP[suggKey]);
                    }
                })
                if (suggsItems.length) {
                    prodConsole.log(suggsItems);
                    suggestions = (
                        <div>Based on the following results, it is recommended that you focus on {/*suggsItems.length == 1 ? suggsItems[0] : */[suggsItems.slice(0, -1).join(', '), suggsItems.slice(-1)[0]].join(suggsItems.length < 2 ? '' : ' and ')} in your telematics.</div>
                    )
                }
            }   
        }

        return (
            <DashWrapper {...this.props}>

                <div className='dash-box' key={"" + dimensionName + dimensionValue}>
                    {isLoading ? <div className="dash-curtain"></div> : null}
                        <div className="dash-header">
                            <div style={{paddingBottom: 12}} className="dash-suggest display-6">
                                {suggestions}
                            </div>
                        </div>
                    <div>
                        { isLoading ? (
                               <div className="loading">Loading&#8230;</div>
                            ) : null }
                        {content}
                    </div>
                </div>

            </DashWrapper>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        dash: appState.dash
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setQueryTimespan : function () { dispatch( actions.setQueryTimespan() ) },
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DimensionDash);
