
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import * as hlp from '../../utils/helpers';
import AddLoc from './addloc';

const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToLocs() );
});

class Locs extends Component {

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const rows = _.map(p.locs.data, function (districtData, district) {

            return (
                    <div className="district-item" key={district}>
                        <h3>{"District " + district}</h3>
                        {_.map(districtData, function (loc, locId) {
                            return (
                                <div className="loc-item">
                                    <div><b>{loc.city}</b></div>
                                    <div>{ loc.address + " " + loc.city + ", " + loc.state + " " + loc.zip }</div>
                                </div>
                            )
                        })}
                    </div>
            )
        })

        const list = (
            <div className="location-list">
                {p.locs.hasReceivedLocData ? rows : "Loading Locations..."}
            </div>
        )
        const nodata = (
            <div>No locations exist</div>
        )

        const add = <AddLoc onSubmit={(function (e) {e.preventDefault(); this.props.submitNewLoc(); }).bind(this) } />
        const wait = <CircularProgress />

        return (
            <div>
            { this.props.locs.submittingnew ? wait : add}
            { rows.length ? list : nodata }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        locs: appState.locs
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewLoc: function () { dispatch( actions.submitNewLoc() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Locs);
