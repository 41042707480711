import { C } from '../../constants';

const initialState = {
	 sortBy : "lastname",
	 reverse: false,
       hasBeenSorted: false,
	 rows: []
};

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case C.SORT_BY_NAME:
//         return Object.assign({}, state, {
//           reverse : action.reverse
//         });

//       default:
//         return state;
//   }
// }

export default (state = initialState, action) => {
  switch (action.type) {
    case C.SET_SORT_STATE:
        return Object.assign({}, state, {
          sortBy : action.sortBy,
          reverse : action.reverse
        });
    case C.SORT_DRIVERS_BY:
         return Object.assign({}, state, {
          sortBy: action.sortBy,
          reverse : !action.reverse,
          rows: action.rows,
          hasBeenSorted: true
        });
    case C.INIT_SORT_DRIVERS:
         return Object.assign({}, state, {
          sortBy: action.sortBy,
          reverse : action.reverse,
          rows: action.rows,
          hasBeenSorted: false
        });

      default:
        return state;
  }
}