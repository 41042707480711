import { RadioButton } from 'material-ui/RadioButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { RadioButtonGroup } from 'redux-form-material-ui';



class OverviewOptionForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        return (
            <form
                className={this.props.className}>
                <div style={{padding: "44px 11px 0"}}>
                    <Field name="queryType"  component={RadioButtonGroup}>
                        <RadioButton value="last" label={"Show Last " + this.props.viewLast} />
                        <RadioButton value="daterange" label={"Show Date Range"} />
                    </Field>
                </div>
                
            </form>
        )
    }
}

// Decorate the form component
OverviewOptionForm = reduxForm({
  form: 'overviewQueryOption' // a unique name for this form
})(OverviewOptionForm);

//connect other stores here to dynamically show labels
OverviewOptionForm = connect(
  state => ({
    initialValues: state.overview // pull initial values from auth oid
  })
)(OverviewOptionForm);


export default OverviewOptionForm;