import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { C } from '../constants';
// import _ from 'lodash';
// import actions from '../actions';



class SuperDash extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {

        return (
            <div className="panel" style={{marginBottom: "40px"}}>
                <div className="panel-body">
                    <h6 className="panel-title">
                        Admin
                    </h6>
                    <div className="dash-section-body">
                        Administrative functions go here.
                        <ol>
                            <li>Add Users</li>
                            <li>Enter/Link results</li>
                            <li>...</li>
                        </ol>
                    </div>
                </div>  
            </div>

        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}

SuperDash.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(SuperDash);