import { C } from '../constants';

const orgsRef = C.APP.database().ref('organizations');

export const submitNewLocale = function (oid) {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.addLocale.values;
        const locale = formValues.locale;
        
        dispatch({type: C.AWAIT_NEW_LOCALE_RESPONSE});

        orgsRef.child(oid + '/locales').once('value').then(list => {
            let locs = list.val() || [];
            locs.push(locale);
            orgsRef.child(oid + '/locales').set(locs).then(() => {
                dispatch({type:C.RECEIVE_NEW_LOCALE_RESPONSE});
            }).catch(error => {
                dispatch({type:C.RECEIVE_NEW_LOCALE_RESPONSE});
                dispatch({type: C.DISPLAY_ERROR, error: "Failed to add new locale!"}) //TODO: better errors
            })
        });
    }
}

export const deleteLocale = function (oid, index) {
    return function (dispatch, getState) {
        
        dispatch({type: C.AWAIT_NEW_LOCALE_RESPONSE});

        orgsRef.child(oid + '/locales').once('value').then(list => {
            let locs = list.val() || [];
            locs.splice(index, 1); //arr.splice(index, 1);
            orgsRef.child(oid + '/locales').set(locs).then(() => {
                dispatch({type:C.RECEIVE_NEW_LOCALE_RESPONSE});
            }).catch(error => {
                dispatch({type:C.RECEIVE_NEW_LOCALE_RESPONSE});
                dispatch({type: C.DISPLAY_ERROR, error: "Failed to delete locale!"}) //TODO: better errors
            })
        });
    }
}