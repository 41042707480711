

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import { C } from '../constants';

// import CreateAccountForm from './components/createaccountform';
import RegistrationForm from './components/registrationform';
import PaidRegistrationForm from './components/paidregistrationform';

/*
We can do user registration
https://firebase.google.com/docs/reference/js/firebase.User#sendEmailVerification
We can reverse engineer this to build it in REST for Unity if we want to
*/

class Register extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        // if user is already logged in 
        // this route should take them "home"
        prodConsole.log("_________--------", this.props.params)
        this.goHomeIfLoggedIn(this.props.auth);

        if (this.props.params.invite) {
            prodConsole.log("listening to invite")
            store.dispatch( actions.startListeningToInvite( this.props.params.invite, true ) );
        }
        
        // document.body.style.background = "#3b125d";
    }

    componentDidMount () {
        let inviteId = this.props && this.props.params && this.props.params.invite || "";
        C.GA.logEvent('ra_view_registration');
    }

    componentWillReceiveProps (nextProps) {
        
        this.goHomeIfLoggedIn(nextProps.auth);
    }
    goHomeIfLoggedIn (auth) {
        const { router } = this.context
        if (auth.uid) {
            router.replace('/');
        }
    }

    submit () {
        const invite = this.props.invites &&
            this.props.invites.data &&
            this.props.invites.data[this.props.params.invite] &&
            this.props.invites.data[this.props.params.invite].hasReceivedData &&
            this.props.liveInvite.ready &&
            this.props.invites.data[this.props.params.invite].value;

        this.props.attemptRegister(invite && invite.invitee == "open", invite.waiveFee);
    }

    // componentWillUnmount () {
    //     document.body.style.background = "#FFF";
    // }

    render () {
        const inviteId = this.props.params.invite;
        const auth = this.props.auth;

        const invite = this.props.invites &&
            this.props.invites.data &&
            this.props.invites.data[inviteId] &&
            this.props.invites.data[inviteId].hasReceivedData &&
            this.props.liveInvite.ready &&
            this.props.invites.data[inviteId].value;
        
        if (this.props.liveInvite.data.invalid) {
            return (
                <div className={ this.props.auth.currently + " login" }>
                <div className="login-form">
                    <div className="dxd-login-logo" />
                    <div className="dxd-loginSpinner" />
                    <div>This invite is no longer valid.<br />Please contact your manager.</div>
                </div>
            </div>
            )
        }

        if (invite) {

            return (
                //We are using CSS to hide/show spinner when attempting login
                <div className={ this.props.auth.currently + " login" }>
                    <div className="login-form">
                        <div className="dxd-login-logo" />
                        <div className="dxd-loginSpinner" />
                        <RegistrationForm className="dxd-login-form" 
                            onSubmit={(function (e) {e.preventDefault(); this.submit(); }).bind(this) }
                            inviteKey={inviteId}
                            invite={invite} />
                    </div>
                </div>
            )
        }


        return (
            <div className={ this.props.auth.currently + " login" }>
                <div className="login-form">
                    <div className="dxd-login-logo" />
                    <div className="dxd-loginSpinner" />
                    <div>Validating invite</div>
                </div>
            </div>
        )


        
    }
}

Register.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        invites: appState.invites,
        liveInvite: appState.liveInvite
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        attemptRegister: function(open, waiveFee) { dispatch(actions.attemptRegister(open, waiveFee)); },
        submitWithPayment: function(open) { dispatch(actions.registerWithPayment(open)); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
