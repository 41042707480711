import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from 'material-ui/Paper';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import RAImg from "../assets/images/Ready-Assess-Logo.png";
import RALaptop from "../assets/images/ra-laptop.png";
import SimShotTL from "../assets/images/Sim_Shot_TL.png";
import IconCheck from "../assets/images/icon-check-white.svg";
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import {red500} from 'material-ui/styles/colors';
// import Accordion from 'react-bootstrap/Accordion';
// import { Tabbordion, TabPanel, TabLabel, TabContent } from 'react-tabbordion'
import { Accordion, AccordionItem } from 'react-light-accordion';
import { C } from '../constants';
import Share from "../assets/images/share.png";
import LoadingDots from "../assets/images/745.png";
import Dialog from 'material-ui/Dialog';



const style = {
    paper : {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 350,
        padding: 20,
        marginTop: 50
    }
}

const blockElements = {
    animator: 'accordion-animator',
    content: 'accordion-content',
    panel: 'accordion-panel',
    label: 'accordion-title'
}

class ReadyAssess extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        }
    }

    handleOpen () {
        this.setState({open: true});
    }

    trackPdf () {
        let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
        C.GA.logEvent('view_sample_feedback', {invite_id: inviteId});
    }

    trackClickLogin () {
        C.GA.logEvent('ra_click_login_v2');
    }

    trackClickRegister () {
        C.GA.logEvent('ra_click_register_v2');
    }

    handleClose () {
        this.setState({open: false});
    }

    componentWillMount () {
        document.body.style.background = "#FFF";
    }

    componentDidMount () {
        document.querySelectorAll("button.title")[0].click();
        C.GA.logEvent('ra_view_landing');
    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    handleCopyShare (e) {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href);
        this.handleClose();
        alert("URL for this page copied to the clipboard");
    }

    handleShare (e) {
        let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
        C.GA.logEvent('ra_share_landing');
        if (navigator.share) {
            navigator.share({
                text: window.location.href,
                url: window.location.href,
                title: "Ready Assess™ Self-guided Virtual Driving Test",
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            console.log("Web Share not supported :(");
            this.handleOpen();     
        }
    }

    render () {

    let slideSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
    let loggedIn = this.props.auth && this.props.auth.uid;
    let uninitiated = this.props.auth && this.props.auth.currently == C.UNINITIATED;

        return (
            <div className="ra-all">
                <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CLexend+Deca:regular%7CLexend+Deca:regular,600" media="all"/>

                <div className="ra-top">
                    <div className="ra-top-inner row">
                        <div className="col-sm-6 ra-top-left">
                            <span className="ra-logo-span"><img className="ra-logo" src={RAImg} alt="Ready Assess Logo"  width="311" /></span>
                        </div>
                        <div className="col-sm-6 ra-top-rt">
                            <button onClick={this.handleShare.bind(this)} className="share-button"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                        </div>
                        
                        
                        <div className="clrbth"></div>
                    </div>
                </div>
                <div className="ra-hero">
                    <div className="ra-hero-inner row">
                        <div className="ra-hero-left col-sm-12">
                            <h1 className="ra-hero-heading"><strong>Can you avoid a serious crash?</strong></h1>
                        </div>
                        <div className="ra-hero-left col-sm-4">
                            <div className="ra-hero-subheading"><strong>Ready-Assess is a simple way assess your crash-avoidance skills.</strong></div>
                        </div>
                        <div className="ra-hero-right col-sm-8">
                            <img className="ra-laptop" src={RALaptop} alt="Ready Assess Running on a Laptop"  width="700" />
                        </div>
                    </div>
                    <div className="ra-hero-btm row">
                        <div className="ra-hero-left col-sm-6">
                            <div className="ra-hero-ftr">Now, you can complete Ready-Assess at home on your PC or Mac computer!</div>
                        </div>
                        <div className="ra-hero-right hero-action col-sm-6">

                            {
                                uninitiated ?

                                <img className="loading-dots" src={LoadingDots} alt="Loading Dots"  />

                                :

                                loggedIn ? 
                                
                                <a href={"/"} className="ra-homepage-hero-link">Go to Dashboard</a>

                                : 
                                <div className="row">
                                    <div style={{textAlign: "center"}} className="col-sm-6"><a onClick={this.trackClickLogin.bind(this)} href={"/login"} className="ra-homepage-hero-link ra-homepage-hero-link-uno">Login</a></div>
                                    <div style={{textAlign: "center"}} className="col-sm-6"><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ra-homepage-hero-link">Create Account</a></div>
                                    
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="ra-section">
                    <div className="ra-section-inner">
                        <h4 className="ra-large-heading">Ready-Assess:<br />The blood test of safe driving</h4>
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="ra-image-container">
                                    <img src={SimShotTL}  alt="Virtual Driving Test Screen Shot" className="driver-test-image" style={{width: "100%"}}/>
                                </div>
                                <Slider {...slideSettings}>
                                    <div className="ra-review">
                                        <p className="ra-review-text">
                                            <em>
                                                It’s good evidence. Sometimes he has an exaggerated sense of his own abilities. &nbsp;This was an independent arbiter saying ‘
                                            </em>
                                            <strong>
                                                <em>
                                                    look, you have some things to work on
                                                </em>
                                            </strong>
                                            <em>
                                            ’.
                                            </em>
                                        </p>
                                        <h6 className="ra-no-bottom-margin">Susan — Parent</h6>
                                    </div>
                                    <div className="ra-review">
                                        <p className="ra-review-text">
                                            <em aria-hidden="true">
                                                It actually helped our relationship. &nbsp;Taking Ready-Assess confirmed what I was telling her, and helped her to understand what I was saying. &nbsp;It was a plus all the way around. &nbsp;Now she’s more open to what she needs to work on.
                                            </em>
                                        </p>
                                        <h6 className="ra-no-bottom-margin">Toni — Parent</h6>
                                    </div>
                                    <div className="ra-review">
                                        <p className="ra-review-text">
                                            <em>
                                                As a parent there are a number of things I can take away and discuss with my child. &nbsp;I feel like it’s valuable information, and I love the links and tips.
                                            </em>
                                        </p>
                                        <h6 className="ra-no-bottom-margin">Christine —&nbsp;Parent</h6>
                                    </div>
                                    <div className="ra-review">
                                        <p className="ra-review-text">
                                            <em>
                                                This is the information I was looking for!
                                            </em>
                                        </p>
                                        <h6 className="ra-no-bottom-margin">Carmen —&nbsp;Parent</h6>
                                    </div>
                                    <div className="ra-review">
                                        <p className="ra-review-text">
                                            <em>
                                                You want to give your kid every tool to make good decisions. &nbsp;When you’re teaching your teenager you’re not going to put yourself in situations where you face dangerous conditions. &nbsp;This lets her experience things within the safety of a virtual driving test and not on the road.
                                            </em>
                                        </p>
                                        <h6 className="ra-no-bottom-margin">Tina —&nbsp;Parent</h6>
                                    </div>
                                </Slider>
                            </div>
                            <div className="ra-chk-cnt col-sm-7">
                                <h5 className="ra-chk-title">
                                    {"Test your skills beyond what driver's ed can do"}
                                </h5>
                                <div className="checklist upper-checklist">
                                    <div id="w-node-_41f085fc-dfdc-eac1-ff4d-bbe7f482c9e7-2950a28b" className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            The first few months of driving with a license are the riskiest.
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Driving lessons may not expose you to common crash scenarios like driving too fast, following too closely, and driving on sharp curves.
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            A Ready-Assess driving test will safely expose you to these scenarios and test your ability to avoid crashes.
                                        </div>
                                    </div>
                                </div>
                                <h5 className="ra-chk-title">
                                    Ready-Assess is for all types of drivers, including:
                                </h5>
                                <div className="checklist">
                                    <div id="w-node-_7404dda1-6636-86d9-6626-3068c0e6fdc2-2950a28b" className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            {"Students in driver's ed, even without a learner's permit"}
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Learner drivers preparing for their driving test
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Licensed drivers with minimal experience
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Immigrant drivers who are less familiar with the local traffic laws
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Senior drivers seeking an anonymous way to self-assess
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="ra-section ra-bg-primary-3 wf-section">
                    <div className="ra-section-inner">
                        <h4 className="ra-large-heading">
                            <strong>Get credible, personalized feedback</strong>
                        </h4>
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="ra-image-container">
                                    <a onClick={this.trackPdf.bind(this)} href="https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/6154f2eeb085f325a860064c_Mary_Driver_RA_Page_Feedback_Report.pdf" target="_blank" className="link-block w-inline-block">
                                        <img src="https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/6155cb2b77bf32c5ea451f8a_Mary_Driver_RA_Page_Feedback_Report.jpg"  sizes="(max-width: 479px) 83vw, 306px" alt="" className="sample-report-image" width="306"/>
                                    </a>
                                    <div className="mini-text">
                                        Click to view a sample report
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <h5 className="ra-feedback-sub-heading ra-chk-title">
                                    Know your ability to avoid serious crashes
                                </h5>
                                <div className="ra-feedback-sub-sub">
                                    <strong>Ready-Assess™ analyzes your crash-avoidance and safe-driving skills</strong>
                                </div>
                                <div className="checklist checklist-bottom-margin">
                                    <div id="w-node-_24fc1cdc-29b4-67e7-1b4b-5cc4611da7c4-2950a28b" className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            See how you compare to over 100,000 others who have taken our test
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Get detailed feedback on how well you handled common crash scenarios
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Discover specific areas of driving where you need the most improvement
                                        </div>
                                    </div>
                                </div>
                                <div className="ra-feedback-sub-sub">
                                    <strong>Access personalized tips and content to help make you a safer driver:</strong>
                                </div>
                                <div className="checklist checklist-bottom-margin">
                                    <div id="w-node-_2f02cb58-e361-2574-ec60-23f86b0ef8e7-2950a28b" className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Get suggestions about how you can better avoid crashes
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            Access free learning videos selected just for you, developed by our partners at the Children’s Hospital of Philadelphia
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ra-section">
                    <div className="ra-section-inner container-large">

                        <div className="section-title">
                            <div className="title-row">
                                <div className="subscribe-container">
                                    <h4 className="large-heading">
                                        Frequently Asked Questions
                                    </h4>
                                    <div className="large-text">
                                        Learn more about the process, the software, and the science.
                                    </div>
                                </div><img src="https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/61103c36e6d81ca543d05f59_FAQ.jpg" className="title-circle" />
                            </div>
                        </div>

                        <Accordion atomic={true}>
                            <AccordionItem title={<span className="innerTitle">How does it work?</span>}>
                                <div className="accordion-text-inner">
                                    <div>Ready-Assess is a <strong>self-guided virtual driving test</strong> that you take on a computer, using the keyboard and a mouse to “drive.”</div>
                                     <div><strong>Once you register for a free account, you can download and install the software for free.</strong></div>
                                     <div>After you complete the virtual driving test, you will geta free, detailed and personalized Feedback Report on your safe driving and crash-avoidance skills via email.  You can email this report to up to 5 different addresses.</div>
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">What do I need to get started?</span>}>
                                <div className="accordion-text-inner w-richtext">
                                    <p>
                                        <strong>You will need:</strong>
                                    </p>
                                    <ul role="list">
                                        <li>A desktop or laptop computer (Windows or Mac)
                                        </li>
                                        <li>A reliable Internet connection
                                        </li>
                                        <li>About 15 minutes
                                        </li>
                                        <li>A distraction-free space where you can complete the virtual driving test uninterrupted.
                                        </li>
                                    </ul>
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">Why do I need to complete the virtual driving test by myself and without distractions or interruptions?</span>}>
                                <div className="accordion-text-inner">
                                    Like any test or assessment, <strong>we want to make sure we can reliably measure your skills and give you credible feedback.</strong>
                                </div>
                            </AccordionItem>




                            <AccordionItem title={<span className="innerTitle">How long does it take to complete a Ready-Assess™ virtual driving test?</span>}>
                                <div className="accordion-text-inner">
                                    About <strong>15 minutes</strong> once you start a drive.
                                </div>
                            </AccordionItem>

                            <AccordionItem title={<span className="innerTitle">Am I too old or too young for Ready-Assess to help?</span>}>
                                <div className="accordion-text-inner">
                                    Ready-Assess is for people of <strong>all ages and experience levels</strong>, including seniors and people in driver’s ed. &nbsp;However, the test taker should be reasonably familiar with how to drive a car.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">What if I don’t like video games?</span>}>
                                <div className="accordion-text-inner">
                                    You do NOT need to play or like video games to do well in our virtual driving test. <strong>People of all ages, interests, and experience levels</strong> take our test each day.
                                </div>
                            </AccordionItem>
                            
                            <AccordionItem title={<span className="innerTitle">What’s in the Personalized Feedback Report?</span>}>
                                <div className="accordion-text-inner w-richtext">
                                    <p>
                                        <strong>You can view a sample report above.</strong> Your report will provide information and guidance specific to how you performed during our test, including:
                                    </p>
                                    <ul role="list">
                                        <li>How you compared with over 100,000 others drivers who have taken our test.
                                        </li>
                                        <li>Detailed feedback on how well you handled common crash scenarios.
                                        </li>
                                        <li>Feedback on specific areas of driving where you need improvement.
                                        </li>
                                        <li>Tips, videos, and other content that promotes safe driving.
                                        </li>
                                    </ul>
                                </div>
                            </AccordionItem>

                            <AccordionItem title={<span className="innerTitle">Will the Personalized Feedback Report results tell me or my loved one that they should not drive?</span>}>
                                <div className="accordion-text-inner">
                                    <div><strong>Absolutely not.</strong> Ready-Assess™ is a way to test your safe driving skills and give you important insights into your driving ability.</div>
                                    <div>You can only make a decision to not drive or to stop driving with support from your family, a doctor, or a driving evaluator.</div>

                                </div>
                            </AccordionItem>

                            <AccordionItem title={<span className="innerTitle">What if I perform poorly?</span>}>
                                <div className="accordion-text-inner">
                                    There is no “pass” or “fail” for our test, and <strong>every driver has something they can improve on</strong>. &nbsp;We encourage you to share your results with a professional driving instructor, and learn from the tips, videos, and other safe-driving content attached to your Personalized Feedback Report.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">What is the science behind Ready-Assess?</span>}>
                                <div className="accordion-text-inner">
                                    Ready-Assess was developed by traffic safety scientists and in partnership with the Children’s Hospital of Philadelphia. &nbsp;It’s <strong>proven to differentiate drivers by skills and experience.</strong>
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">This program is a simulator and not in a real car. Will it really test my skills?</span>}>
                                <div className="accordion-text-inner">
                                    Ready-Assess was built and designed by road traffic safety researchers. &nbsp;Organizations use it in licensing, healthcare, and commercial settings to reliably test crash-avoidance skills.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">Why not just get driving lessons with a professional driving instructor?</span>}>
                                <div className="accordion-text-inner">
                                    Lessons with a professional instructor may help. &nbsp;However, instructors have limited opportunities to expose you to the types of common crash scenarios that cause significant injury. &nbsp;With Ready-Assess, we can safely simulate these scenarios, and assess your driving in ways instructors rarely can during lessons.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">Will Ready-Assess help me pass my road test to get a driver’s license?</span>}>
                                <div className="accordion-text-inner">
                                    Ready-Assess is proven to predict road test results during driver’s ed. However, we feel that Ready-Assess is <strong>more difficult than your typical road exam</strong> because it simulates driving conditions that are often difficult to encounter during tests.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">Who will have access to my results?</span>}>
                                <div className="accordion-text-inner">
                                    <strong>Only you can decide who will have access to the results.</strong> &nbsp;For example, this could be your parents or your driving instructor.
                                </div>
                            </AccordionItem>
                        </Accordion>


                    </div>
                </div>

                <div style={{height:"62px"}}></div>
                <div className="ra-announcement-bar">
                    {
                        uninitiated ?

                        <img className="loading-dots" src={LoadingDots} alt="Loading Dots"  />

                        :

                        loggedIn ? 
                        
                        <a href={"/"} className="link-3">Go to Dashboard</a>

                        : 

                        <div>


                            <div  className="ftr-col"><a onClick={this.trackClickLogin.bind(this)} className="ftr-link" href={"/login"}>Login</a></div>
                            <div  className="ftr-col"><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-shrt">Register</a><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-lng">Register for a free account!</a></div>
                            <div  className="ftr-col"><button onClick={this.handleShare.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="18" /></button></div>
                        </div>
                    }
                </div>
                <Dialog
                    actions={[<button onClick={this.handleClose.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">Close</span></button>]}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose.bind(this)}>

                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}  target="_blank" className="share-link"><i className={"icon share-icon ion-social-facebook"}></i></a>
                    <a href={"http://twitter.com/share?text=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills." + "&url=" + window.location.href + "&hashtags=" + ['safedriving', 'drivesafe']} className="share-link" target="_blank"><i className={"icon share-icon ion-social-twitter"}></i></a>
                    <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href} target="_blank" className="share-link"><i className={"icon share-icon ion-social-linkedin"}></i></a>
                    <a href={"http://pinterest.com/pin/create/button/?url=" + window.location.href + "&media=" + "https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c45fc379b131a4baff925_Ready-Assess-Logo.png" + "&description=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-pinterest"}></i></a>
                    <a href={"https://web.whatsapp.com/send?text=" + window.location.href} className="share-link" target="_blank"><i className={"icon share-icon ion-social-whatsapp"}></i></a>
                    <a href={"https://reddit.com/submit?url=" + window.location.href + "&title=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-reddit"}></i></a>
                    <a onClick={this.handleCopyShare.bind(this)} className="share-link"><i className={"icon share-icon ion-ios-copy"}></i></a>
                    <a href={"mailto:?subject=Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills.&body=Go here to register your free account and get started! site: " + window.location.href } target="_blank" className="share-link"><i className={"icon share-icon ion-ios-email"}></i></a>

                </Dialog>
            </div>
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth : appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReadyAssess);