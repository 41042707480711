import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import { GridList, GridTile } from 'material-ui/GridList';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import VideoForm from './components/VideoForm';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  gridList: {
    width: "100%",
    // height: 500,
    // overflowY: 'auto',
    marginBottom: 24,
  },
};



class VideoAdmin extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToVideos() );
    }

    render () {
        const p = this.props;
        const rows = _.map(p.video.data, function (video, vid) {
            return <GridTile
                key={vid}
                title={<a href={video.url} target="_blank">{video.title}</a>} 
                titlePosition="top"
            >
                <video width="100%" height="100%" controls>
                    <source src={video.url} type="video/mp4" />
                Your browser does not support HTML5 video
                </video>
            </GridTile>
        })
        const list = (
            <GridList 
                className="vidList"
                cellHeight={300}
                cols={3}
                style={styles.gridList}
            >
                {p.video.hasReceivedVideoData ? rows : (<GridTile
                        key={0}
                        title="Loading Videos..." 
                        subtitle="please wait"
                    >
                    </GridTile>)}
            </GridList>
        )
        const nodata = (
            <GridTile
                key={0}
                title="No Videos Found"
                subtitle="please upload a video"
            >
            </GridTile>
        )
        if (!rows.length) {
            rows.push(nodata);
        }
        const vidForm = (<VideoForm onSubmit={this.props.uploadVideo} submit={true}/>);
        const wait = <CircularProgress />
        const add = (
                     <GridTile
                        key={99}
                        title="Upload a Video"
                        rows={1}
                    >
                        { this.props.video.submittingnew ? wait : vidForm}
                    </GridTile>
                     
        )
        rows.unshift(add);
        

        return (
            <div style={styles.root}>
            { list }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        video: appState.video
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadVideo: function () { dispatch( actions.uploadVideo() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VideoAdmin);
