import React, { Component } from 'react';
import FileButton from './FileButton';
import SmartForm from './Form';
import SubmitButton from './SubmitButton';
import TextInput from './TextInput';

class VideoForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const buttonLabel = this.props.label? this.props.label : "Choose a Video";    
        const submitButton = this.props.submit ? <SubmitButton label="Add Video" /> : null;

        return (
            <SmartForm onSubmit={this.props.onSubmit}>
                <TextInput
                    name="title"
                    validate={['required']}
                    placeholder="Provide a title for video"
                    label="Title" />
                <FileButton id="videoFileButton" name="videofile" label={buttonLabel} />
                {submitButton}
            </SmartForm>
        )
    }
}

export default VideoForm;