import emailValidator from 'email-validator';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const validate = values => {
    const errors = {}
    if (!values[ "email" ]) {
        errors[ "email" ] = 'Required'
    } else {
        if (!emailValidator.validate(values["email"].trim())) {
            errors[ "email" ] = 'Invalid email'
        }
    }

    return errors
}

class ResetForm extends Component {
    constructor(props, context) {
        super(props, context);
    }


    render () {
        // NB: removed the "create account" button
        // We only allow account creation via invite and/or admin/super
        console.log("rendering reset form");

        return (
            <div>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    <Field
                        name="email"
                        component={TextField}
                        floatingLabelText="Email"
                        fullWidth={true}
                        hintText="Enter your email" />

                    <div className="row">

                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Reset Password"
                            type="submit"
                            style={{ width: "100%", marginTop: "15px" }} />

                    </div>
                    

                </form>
            </div>
        )

        
    }
}

// Decorate the form component
ResetForm = reduxForm({
  form: 'reset', // a unique name for this form
  validate
})(ResetForm);

export default ResetForm;