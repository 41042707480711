import { C } from '../../constants';
import { init } from '../init';

/*
A reducer is a function that takes the current state and an action, and then returns a
new state. This reducer is responsible for appState.auth data.
See `initialstate.js` for a clear view of what it looks like!
*/

export default function ( currentstate, action ) {
    switch( action.type ){
        case C.ATTEMPTING_LOGIN:
            return {
                currently: C.AWAITING_AUTH_RESPONSE,
                username: "guest",
                uid: null,
                gid: null,
                oid: null,
                features: {},
                emailVerified: false,
                allowed: currentstate.allowed
            };
        case C.ATTEMPTING_REGISTER:
            return {
                currently: C.AWAITING_AUTH_RESPONSE,
                username: "guest",
                uid: null,
                gid: null,
                oid: null,
                features: {},
                emailVerified: false,
                allowed: currentstate.allowed
            };
        case C.LOGOUT:
            return {
                currently: C.ANONYMOUS,
                username: "guest",
                uid: null,
                gid: null,
                oid: null,
                features: {},
                emailVerified: false,
                allowed: currentstate.allowed
            };
        case C.LOGIN_USER:
            return {
                currently: C.LOGGED_IN,
                username: action.username,
                uid: action.uid,
                gid: action.gid,
                oid: action.oid,
                isOwner: action.isOwner,
                features: action.features,
                brandNew: action.brandNew,
                assessments: action.assessments,
                drivers: action.drivers,
                managers: action.managers,
                invites: action.invites,
                emailVerified: action.emailVerified,
                allowed: currentstate.allowed,
                mobile: action.mobile,
                openInvite: action.openInvite,
                lastIntakeTime: action.lastIntakeTime,
                lastIntake: action.lastIntake,
                dob: action.dob,
                credits: action.credits,
                fakeDriver: action.fakeDriver,
                fakeCount: action.fakeCount,
                showEmailOpen: action.showEmailOpen,
                fakeAssessmentCount: action.fakeAssessmentCount,
                resentVerification: action.resentVerification,
                cpt: action.cpt
            };
        case C.DEMAND_VERIFICATION:
            return Object.assign({}, currentstate, {
                requestVerification: true,
                resentVerification: true,
            });

        case C.AWAITING_VERIFICATION:
            return Object.assign({}, currentstate, {
                requestVerification: false,
                resentVerification: true,
            });

        case C.LOCK:
            return Object.assign({}, currentstate, {
                allowed: false
            });

        case C.UNLOCK:
            return Object.assign({}, currentstate, {
                allowed: true
            });

        case C.SET_ORGANIZATION:
            return Object.assign({}, currentstate, {
                orgAbrv: action.abrv,
                orgName: action.name,
                scoringMethod: action.scoringMethod,
                orgType: action.orgType,
                featureFlags: action.featureFlags || {},
                apiToken: action.apiToken,
                apiTokenV2: action.apiTokenV2,
                tokenState: 'idle',
                isParent: action.isParent,
                childOrgs: action.childOrgs,
                shareUrl: action.shareUrl,
                payPerAssessment: action.payPerAssessment,
                price: action.price,
                cptModules: action.cptModules
            });

        case C.SET_API_TOKEN_STATE:
            return Object.assign({}, currentstate, {
                tokenState: action.state
            });

        default: return currentstate || init.auth;
    }
}