
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as hlp from '../../utils/helpers';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import { C } from '../../constants';


class ComplianceAssessment extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {


        const p = this.props;
        const aData = p.data;
        const aid = p.aid;
        const now = Date.now() + p.util.offset; // server time
        let end = aData.hasOwnProperty('end') ? new Date(aData.end).toLocaleString() : hlp.determineEndTime(now, aData.lastUpdate);
        let incomplete = end && (!aData.completed);
        let statusClass = end ? "ra-completed" : "ra-in-progress";
        if (!aData.hasOwnProperty('lastUpdate')) {
            statusClass = "ra-completed";
        }

        return <div className={"ready-assessment " + statusClass}>

            { incomplete ? 
            
            <div className="row">
                <div className="col-sm-12" style={{paddingTop: "8px"}}>
                    {end ? "Assessment aborted: " + end : "Assessment Aborted"}
                </div>
            </div> 
            
            : 
            
            <div className="row">
                <div className="col-sm-4" style={{paddingTop: "8px"}}>
                    {end ? "Completed: " + end : "Assessment In Progress"}
                </div>
                <div className="col-sm-4" style={{paddingTop: "8px", position: "relative"}}>
                    {
                        aData.feedbackTotalSteps ?
                        (   aData.feedbackStepsCompleted && aData.feedbackStepsCompleted == aData.feedbackTotalSteps ? 
                                <span className='fbprogress'><CheckCircle style={{width: 30, height: 30, position: "absolute", top: 5, left: -20}} color="#009025" /> {aData.feedbackStepsCompleted}/{aData.feedbackTotalSteps} Pages Viewed</span> : 
                                <span className='fbprogress'>{aData.feedbackStepsCompleted}/{aData.feedbackTotalSteps} Pages Viewed</span>
                        ) :
                        null
                    }
                </div>
                <div className="col-sm-4" style={{textAlign: "right"}}>
                    {end ? 
                        <span>
                            <a style={{width: "auto", padding: "6px 30px"}} className="ra-homepage-hero-link" href={"/s/feedback/" + aid}>
                                View Feedback Report
                            </a>
                        </span> 
                        : 
                        null
                    }
                </div>
            </div>

            }

            

        </div>

        
        
        
    }
}

const mapStateToProps = function (appState) {
    return {
        util: appState.util,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ComplianceAssessment);