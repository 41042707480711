import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import FeedbackForm from './components/feedbackform';

class FeedbackAdmin extends Component {
    componentDidMount() {
        store.dispatch(actions.startListeningToFeedbackContent(this.props.auth.oid));

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        this.props.onSubmit(this.props.auth.oid, values);
    }

    render () {
        const { content } = this.props.feedback;

        return (
            <div>
                <div className="panel">
                    <div className="panel-body">
                        <FeedbackForm
                            className="feedback-form"
                            config={content}
                            onSubmit={this.handleSubmit} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        auth: appState.auth,
        feedback: appState.feedback
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (oid, values) => { 
            dispatch(actions.updateFeedbackContent(oid, values)) 
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackAdmin);
