import React from 'react';
import { connect } from 'react-redux';
import { startListeningToVideos } from '../../actions/video';

class VideoEmbed extends React.Component {
    componentDidMount() {
        this.props.getVideo(this.props.id);
    }
    render() {
        if (!this.props.video || this.props.video.loading) {
            return (<div>Loading...</div>)
        }

        let props = Object.assign({
            controls: true,
            width: '100%'
        },this.props, {
            video: null,
            id: null
        });
        const video = this.props.video;

        return (
            <video {...props}>
                <source src={video.url} type="video/mp4" />
                Your browser does not support HTML5 video
            </video>
        )
    }
}

export default connect((state, props) => ({
    video: state.video ? (state.video.data.hasOwnProperty(props.id) ? state.video.data[props.id] : null) : null
}), (dispatch)  => ({
    getVideo: (id) => dispatch(startListeningToVideos(id))
}))(VideoEmbed);