import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  success: false,
  error: "",
  waiting: false,
  email: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.PASSWORD_RESET_SUCCESS:
        return Object.assign({}, state, {
          success: true,
          error: "",
          waiting: false
        });

    case C.ATTEMPTING_PASSWORD_RESET:
        return Object.assign({},state,{
            waiting: true,
            success: false,
            error: "",
            email: action.email
        });

    case C.PASSWORD_RESET_ERROR:
        return Object.assign({}, state, {
            error: action.error,
            success: false,
            waiting: false
        })

      default:
        return state;
  }
}