import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import AddLocale from './addlocale';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';


class Locales extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        const oid = p.oid;
        let rows = _.map(p.org.locales, (locale, index) => {
                return  <div key={index}>
                            {locale} 
                            <span className="locale-delete">
                                <IconButton onClick={ function () { p.deleteLocale(oid, index) }} tooltip="Delete Locale">
                                    <Delete className="q-trash" />
                                </IconButton>
                            </span>
                        </div>
            })
        const list = (
            <div className="location-list">
                { rows }
            </div>
        )
        const nodata = (
            <div>No locations exist</div>
        )
        const add = <AddLocale onSubmit={(function (e) {e.preventDefault(); this.props.submitNewLocale(oid); }).bind(this) } />
        const wait = <CircularProgress />
        return (

            <div className='org-settings-box'>
                <div className='display-6 btm-space'>Configure Locales</div>
                { p.locales.submittingnew ? wait : add}
                { rows.length ? list : nodata }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        locales: appState.locales
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewLocale: function (oid) { dispatch( actions.submitNewLocale(oid) )},
        deleteLocale: function (oid, index) { dispatch( actions.deleteLocale(oid, index) ) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Locales);