import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';


const validate = function (values) {
    const errors = {};
    if (!values.orgTypeOption) {
        errors.orgTypeOption = "Organization type option value is required"
    }
    return errors;
}

class AddOrgTypeOption extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="row add-loc-form">
                    <div className="col-sm-12">
                        <Field 
                            name="orgTypeOption"
                            component={TextField}
                            fullWidth={true}
                            multiLine={true}
                            hintText={"Enter a value for this organization type option"}
                            floatingLabelText="Organization Type Option" />
                    </div>
                    <div className="col-sm-12">
                        <RaisedButton
                            primary
                            disabled={this.props.pristine}
                            label="Add New Organization Type Option"
                            type="submit" />
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
AddOrgTypeOption = reduxForm({
  form: 'addOrgTypeOption', // a unique name for this form
  validate
})(AddOrgTypeOption);

export default AddOrgTypeOption;