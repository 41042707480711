import MenuItem from 'material-ui/MenuItem';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Checkbox, SelectField } from 'redux-form-material-ui';


const radioButtonStyle = {
    marginBottom: 16
};

const validate = values => {
    const errors = {}

    return errors
}

class InviteOptionsForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {



        return (
            <div>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    <div>
                        <Field name="manager" component={Checkbox} label="Make this/these user(s) (a) manager(s)?" />
                    </div>
                    <div>
                        <Field
                            name="oid"
                            fullWidth={true}
                            component={SelectField}
                            floatingLabelText={"Organization"}>
                            {
                                _.map(this.props.orgs.data, (item, key) => {
                                    return <MenuItem value={key} key={key} primaryText={item.name} />
                                })
                            }
                        </Field>
                    </div>

                </form>
            </div>
        )

        
    }
}

// Decorate the form component
InviteOptionsForm = reduxForm({
  form: 'inviteOptions', // a unique name for this form
  validate
})(InviteOptionsForm);

InviteOptionsForm = connect(
  state => ({
    initialValues: state.auth // pull initial values from auth oid
  })
)(InviteOptionsForm);

export default InviteOptionsForm;