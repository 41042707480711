
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';




class Reconcile extends Component {

    componentWillMount () {
        if (this.props.params && this.props.params.rqid) {
            store.dispatch( actions.reconcile(this.props.params.rqid) );
        }
        
    }

    componentWillUnmount() {
        store.dispatch( actions.resetReconciliation() );
    }

    render () {

        const rqid = this.props.params && this.props.params.rqid;

        let content = this.props.reconcile.done ?
            "Success! Your driving results are now available in your account." :
            "Attempting to connect your driving results...";

        if (!rqid) {
            content = "Something went wrong. Reconciliation ID missing. Please contact support@diagnosticdriving.com to connect your virtual driving test results to your account.";
        }



        return (

                <div className={ "login" }>
                    <div className="login-form">
                        <div className="dxd-login-logo" />
                        <div>
                            {content}
                        </div>
                        <div className="row">
                            <div className="col-sm-6 reconcile-action">
                                <RaisedButton
                                    primary
                                    label="Sign In"
                                    href="/login"
                                    style={{ width: "100%", marginTop: "15px" }} />
                            </div>
                            <div className="col-sm-6 reconcile-action">
                                <RaisedButton
                                    secondary
                                    label="Reset Password"
                                    href="/reset"
                                    style={{ width: "100%", marginTop: "15px" }} />
                            </div>
                        </div>
                    </div>
                </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        reconcile : appState.reconcile
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reconcile);

