import { C } from '../constants';

const driverIdsRef = C.APP.database().ref('customers');

export const startListeningToDriverIds = function () {
    return function (dispatch, getState) {

        let oid = getState().auth.oid

        // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

        // sleep(5000).then(() => {
            driverIdsRef.child(oid).on('value', (snap) => {
                dispatch({ type: C.RECEIVE_DRIVER_IDS, data: snap.val()});

            });
        // })

        
    }

}