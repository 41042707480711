import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scenario from './components/accell/Scenario';
import data from '../data/hazard-anticipation.json';

class Accell extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    render () {

        return (

            <div className='accell-wrapper'>
                <Scenario scenario={data.scenarios[0]} onDone={() => {}} />
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Accell);