import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { C } from '../constants';
import actions from '../actions';
import store from '../store';
/*
Groups are Driver, Manager, Super
*/


/*
This function should:
if *is a route component*
check auth - if unauthenticated - redirect to login

if authenticated and groups are specified:

If user does not belong to the groups, redirect to login (with message?)

*else*
simply render <div>{null}</div> if user does not meet the security requirements
 if no groups are specified -- they simply must be logged in
 If groups -- they must meet the tier specified


*/

export function isOdps() {
    return  C.FIREBASE.projectId == "odps-dev" ||
            C.FIREBASE.projectId == "odps-1fec9";
}

export function driver(Component, opt_org) {
    return guard(Component, [C.DRIVER_GROUP, C.MANAGER_GROUP, C.SUPER_GROUP], opt_org);
}

export function isOwnAssessment(Component) {
    return guard(Component, [C.DRIVER_GROUP, C.MANAGER_GROUP, C.SUPER_GROUP], null, false, false, true);
}

export function manager(Component, opt_org) {
    return guard(Component, [C.MANAGER_GROUP, C.SUPER_GROUP], opt_org);
}

export function mgrNonBasic(Component, opt_org) {
    return guard(Component, [C.MANAGER_GROUP, C.SUPER_GROUP], opt_org, null, false, true);
}

export function owner(Component) {
    return guard(Component, [C.MANAGER_GROUP, C.SUPER_GROUP], null, true)
}

export function ownerNonBasic(Component) {
    return guard(Component, [C.MANAGER_GROUP, C.SUPER_GROUP], null, true, true)
}

export function spr(Component, opt_org) {
    return guard(Component, [C.SUPER_GROUP], opt_org);
}

export function guard(Component, groups = [], opt_org, only_for_owner, nonBasic, checkAid) {
    class GuardedComponent extends React.Component {

        componentWillMount() {
            const { location } = this.props

            if (location) {
                
                store.dispatch( actions.hideMobileNav() );

            }
            this.checkPerms(this.props.auth);
        }

        componentWillReceiveProps(nextProps) {
            this.checkPerms(nextProps.auth);
        }

        allowed(auth) {
            if (auth.currently == C.UNINITIATED) {
                return true; //allow but don't render anything.
            }
            if (auth.uid) {
                if (!groups.length) {
                    //logged in and no groups specified? 
                    // go ahead
                    return true;
                }
                // if we have groups -- you are not permitted
                // until we know you are in one of the groups
                let group = auth.gid
                let ok = false;
                for (var g of groups) {
                    ok = ok || (g == group);
                }

                // if org is supplied - feature is only for specific orgs users
                if (opt_org) {
                    ok = opt_org == auth.orgAbrv;
                }

                if (only_for_owner) {
                    ok = ok && auth.isOwner;
                }

                if (nonBasic) {
                    ok = ok && (!['BASIC', 'MPI'].includes(auth.orgType));
                }

                if (checkAid) {
                    let aid = this.props.params && this.props.params.aid;
                    ok = ok && aid && auth.assessments[aid];
                }

                return ok;
            }



            return false;
        }

        checkPerms(auth) {
            //using props or nextProps
            // if this is route component
            // redirect to login if !auth or !allowed
            const { router } = this.context
            const { location } = this.props
            let feedbackToken = this.props.params && this.props.params.feedbackToken;
            // add a next param? to allow for coming to this route after login?
            console.log("checking perms", router, location);

            if (location) { // only if we are a route component
                if (!this.allowed(auth)) {
                    console.log('SET ATTEMPTED PATH ***********', location.pathname);
                    if (feedbackToken && feedbackToken == 'spiderman') {
                        store.dispatch( actions.setAttemptedPath(location.pathname) );
                    }
                    router.replace('/login'); 
                } 
                
            }
        }
        //TODO: logout folks who have unverified e-mail?
        render() {
            const passProps = Object.assign({}, this.props);
            delete passProps.auth; // child components can get auth explicitly if they need it
            if (this.props.auth.currently == C.UNINITIATED) {
                return null;
            }
            if (this.allowed(this.props.auth)) {
                console.log("allowed - returning component");
                return (<Component {...passProps} />)
            }
            console.log("not allowed");
            return null;
        }

    }

    GuardedComponent.contextTypes = {
        router: PropTypes.object.isRequired
    };

    const mapStateToProps = (appState) => ({
        auth: appState.auth
    })

    return connect(mapStateToProps)(GuardedComponent);
}
