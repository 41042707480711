import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './app'
import store from './store';
import actions from './actions';
import injectTapEventPlugin from 'react-tap-event-plugin';
// import Chart from 'chart.js';
// TODO: figure out what we actually need
import './assets/stylesheets/demo.css'
import './assets/stylesheets/font-awesome.css'
import './assets/stylesheets/ionicons.css'
import './assets/stylesheets/skin.css'
import './assets/stylesheets/dxd.css'

// Chart.defaults.global.responsive = true;
// Chart.defaults.global.tooltipTitleFontFamily = "font-family: 'Lato', sans-serif";
// Chart.defaults.global.scaleFontFamily = "font-family: 'Lato', sans-serif";
// Chart.defaults.global.tooltipFontFamily = "font-family: 'Lato', sans-serif";
// Chart.defaults.global.scaleLineColor = "rgba(0,0,0,.1)";
// Chart.defaults.global.animationEasing = "linear";
// Chart.defaults.global.tooltipCornerRadius = 2;
// Chart.defaults.globalscaleFontSize = 11;
// Chart.defaults.tooltipFontSize = 12;
// Chart.defaults.tooltipTitleFontSize = 12;


injectTapEventPlugin();
window.firebaseAuthInit = false;

window.prodConsole = window["con" + "sole"];

// tell redux/firedux to watch things
// firedux.watch('todos')

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component/>
    </AppContainer>,
    document.getElementById('root')
  );
};

render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./dxd.jsx', () => { render(App) })
}

// tell redux to watch firebase auth (not sure you can do auth stuff in firedux)

setTimeout(function() {
  // this uses firebase to hook into auth changes
  //TODO: Move these calls into componentWillMount within their components
  store.dispatch( actions.startListeningToAuth() );
  store.dispatch( actions.listenToOffset() );
  store.dispatch( actions.startListeningToContent() );
  store.dispatch( actions.initializeUnityInterface() );
  // could bind other watchers here if we don't use firedux
});