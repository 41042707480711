import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import store from '../../store';
import actions from '../../actions';
import _ from 'lodash';


class CPTDash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentDidMount() {
        store.dispatch( actions.startListeningToModules() );
    }

    render () {
        const p = this.props;
        let rows =[];
        console.log("CPT MODULES", p.auth.cptModules)
        if (p.cpt.allModulesLoaded) {
            _.map(p.auth.cptModules, (mod, key) => {
                let completions = p.auth.cpt && p.auth.cpt[key] && p.auth.cpt[key].completions;
                let attempts = p.auth.cpt && p.auth.cpt[key] && p.auth.cpt[key].attempts;
                let inProgress = p.auth.cpt && p.auth.cpt[key] && p.auth.cpt[key].inProgress;
                let module = p.cpt.allModules && p.cpt.allModules[key];
                rows.push(<div key={key} className='row' style={{padding: 10}}>
                    <div className='col-sm-4'>{module.title}</div>
                    <div className='col-sm-4'>{completions ? "completed" : "incomplete"}</div>
                    <div className='col-sm-4'>
                        {inProgress ? <span>
                            <a style={{width: "auto", padding: "6px 30px"}} className="ra-homepage-hero-link" href={"/cpt/" + key}>
                                Continue Training
                            </a>
                        </span> : <span>
                            <a style={{width: "auto", padding: "6px 30px"}} className="ra-homepage-hero-link" href={"/cpt/" + key}>
                                {completions ? "Start again": "Start"}
                            </a>
                        </span>}
                    </div>
                </div>)
            })
        }
       

        if (!rows.length) {
            return null;
        }

        return (
            
            <div className="ra-section ra-dash-section bg-primary-3">
                <div className="ra-section-inner">
                    <h2 className="ra-dash-sub-header">
                        Crash Prevention Training
                    </h2>
                    <div>
                        {rows}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        cpt: appState.cpt
    }
}

const mapDispatchToProps = function(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CPTDash);