import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField } from 'redux-form-material-ui';

const validate = values => {
    const errors = {}
    if (!values[ "survey" ]) {
        errors[ "survey" ] = 'Required'
    }

    return errors
}


class AssignmentForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("rendering assignment form", this.props.surveys);

        if (!this.props.surveys) {
            return <div>
                <div className="form-box-top">Assign a Coaching Exercise: </div>
                <div className="form-box">{"No coaching surveys configured. Contact your Diagnostic Driving representative to enable coaching features."}</div>
            </div>
        }

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="form-box-top">Assign a Coaching Exercise: </div>
                <div className="form-box">
                    <div className="row">
                        <div className="col-sm-9">
                            <Field
                                name="survey"
                                fullWidth={true}
                                component={SelectField}
                                style={{ verticalAlign: "top"}}
                                floatingLabelText="Select a Coaching Survey">
                                {
                                    _.map(this.props.surveys, (description, key) => {
                                        return <MenuItem value={key} key={key} primaryText={description} />
                                    })
                                }
                            </Field>
                        </div>
                        <div className="col-sm-3">
                            <RaisedButton
                            primary
                            style={{position: "relative", top: "20px"}}
                            disabled={this.props.pristine || !this.props.valid}
                            label="Assign"
                            type="submit" />
                        </div>
                    </div>
                </div>

            </form>
        )
    }
}


// Decorate the form component
AssignmentForm = reduxForm({
  form: 'assignment', // a unique name for this form
  validate
})(AssignmentForm);



export default AssignmentForm;