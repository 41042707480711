import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import {Tabs, Tab} from 'material-ui/Tabs';
import AssessmentGrid from './components/assessmentGrid';
import { withRouter } from 'react-router';

class IndividualDash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    waitForScoringMethod () {
        const router = this.props.router;

        let indId = this.props.params.indId;
        let assessmentId = this.props.params.assessmentId;
        if (indId) {
            if (assessmentId) {
                router.replace('/dash/i/' + indId + '/' + assessmentId);
            } else {
                router.replace('/dash/i/' + indId);
            }
            
        }

    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    componentWillMount () {
        if (!this.props.auth.scoringMethod) {
            this.waitForScoringMethod();
        } else {
            
            let indId = this.props.params.indId;
            let assessmentId = this.props.params.assessmentId;
            store.dispatch( actions.startListeningToCustomer(indId) );

            this.props.setTitle('Progress Report for ' + indId);
        }
    }

    handleChange (value)  {
        // this.setState({
        //     value: value,
        // });
        let indId = this.props.params.indId;
        this.props.router.replace('/idash/i/' + indId + '/' + value);
    }

    render () {
        
        const p = this.props;
        

        const indId = this.props.params && this.props.params.indId;
        const assessmentId = this.props.params && this.props.params.assessmentId;
        const customer = this.props.customer[indId];


        let ready = customer && customer.hasOwnProperty("assessments");

        let content = <div className="loading">Loading&#8230;</div>

        let selectedTabValue = assessmentId;
        let newestAid = "";
        let index = 0;
        let newestLastUpdate = "";

        console.log("OoOoOoO", selectedTabValue, !selectedTabValue);

        if (ready) {

            let tabs = _.map(customer.assessments, (assessment, aid) => {
                index++;
                if (assessment) {
                    console.log("what is selectedTabValue? ", selectedTabValue);
                    newestAid = aid;
                    
                    newestLastUpdate = assessment.lastUpdate;
                    console.log(aid, assessment, selectedTabValue);
                    if (selectedTabValue && selectedTabValue == aid) {
                        return  <Tab 
                                    label={new Date(assessment.lastUpdate).toLocaleString()}
                                    value={aid}>
                                        <AssessmentGrid aid={aid} />
                                </Tab>
                    }
                    return <Tab 
                                label={new Date(assessment.lastUpdate).toLocaleString()}
                                value={aid}>
                                    
                            </Tab>
                }

                return null;
                
                
            })

            if (!selectedTabValue && newestLastUpdate && newestAid) {
                console.log('NEWEST TAB', newestLastUpdate, newestAid, index)
                console.log('LOLOL', tabs.length);
                tabs[index - 1] =   <Tab 
                                    label={new Date(newestLastUpdate).toLocaleString()}
                                    value={newestAid}>
                                        <AssessmentGrid aid={newestAid} />
                                </Tab>
            }

            content = (
                <div>
                    <Tabs value={selectedTabValue} onChange={this.handleChange.bind(this)}>
                        {tabs.reverse().slice(0, 6)}
                    </Tabs>
                </div>
            );
            
        }


        return content;
        
    }
}

const mapStateToProps = function (appState) {
    return {
        customer: appState.customer,
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) }
    }
}

const RoutableIndividualDash = withRouter(IndividualDash);


export default connect(mapStateToProps, mapDispatchToProps)(RoutableIndividualDash);
