
import IconButton from 'material-ui/IconButton';
import CloudDownload from 'material-ui/svg-icons/file/cloud-download';
import CloudOff from 'material-ui/svg-icons/file/cloud-off';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';


const styles = {

  largeIcon: {
    width: 120,
    height: 120,
  },
  large: {
    width: 240,
    height: 240,
    padding: 30,
  },
};

class Download extends Component {

    componentWillMount () {
        store.dispatch( actions.listenToLauncher() );
    }

    render () {
        // use window.navigator.platform -- search for  MacIntel or Win**
        // Otherwise -- show message - no download for the platform you are currently on
        if (!this.props.launcher.filename) {
            return  <div className="magic-load">Loading Launcher Download Info&#8230;</div>
        }
        const plt = window.navigator.platform;
        const canDownload = plt.startsWith("MacIntel") || plt.startsWith("Win");
        const msg = canDownload ? (plt.startsWith("Win") ? "Download for Windows" : "Download for Mac") : "Application not available for " + plt;
        const path = plt.startsWith("Win") ? this.props.launcher.win.path : this.props.launcher.mac.path;
        return (

                <div className="dl-outer">
                    <div
                        className="dl-inner"
                        style={styles.large}>
                        <a
                            href = {this.props.launcher.blobUrl}
                            download = {this.props.launcher.filename}
                        >
                            <IconButton
                                disabled={!canDownload || !path}
                                iconStyle={styles.largeIcon}>
                                    {canDownload ? <CloudDownload /> : <CloudOff />}

                            </IconButton>
                            <div style={{textAlign: "center", fontWeight: "bold", position:"relative", "left": 48, "top": -20}}>
                                {msg}
                            </div>
                        </a>
                    </div>
                </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        launcher: appState.launcher
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Download);

