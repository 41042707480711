import { C } from '../constants';

const invitesRef = C.APP.database().ref('invites');

export const startListeningToInvite = function (inviteId, opt_Reg) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_INVITE_DATA, key: inviteId });
        prodConsole.log("trying to watch invite: " + inviteId);
        invitesRef.child(inviteId).on('value', (snap) => {
            prodConsole.log("received invite: " + inviteId);
            dispatch({ type: C.RECEIVE_INVITE_DATA, key: inviteId, data: snap.val() });
            if (opt_Reg) {
                dispatch({ type: C.RECEIVE_INVITE_DATA_FOR_REGISTRATION, key: inviteId, data: snap.val() })
            }
            
        });
    }
}





