// Wrap material's checkbox
import LinearProgress from 'material-ui/LinearProgress';
import React, { Component } from 'react';

class ProgressBar extends Component {
    constructor( props, context ) {
        super(props, context);
    }

    render () {
        const record = this.props.record;
        const totalCount = this.props.totalCount;
        const percentage = 100 - ((record / totalCount) * 100);
        return (
            <div>
                <div className="display_6">{this.props.title} {percentage}%</div>
                <LinearProgress mode="determinate" value={percentage} />
            </div>
        );
    }
}


export default ProgressBar;