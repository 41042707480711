import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';

const styles = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16,
  },
};

class CityToggles extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        return (

            <div className='city-box'>
                <div className='display-6 btm-space'>Configure Routes</div>
                {

                    p.org.cities.map((cityIsOn, index) => {
                        return <Checkbox
                            label={"City " + (index + 1)}
                            key={'cityToggle_' + index}
                            checked={cityIsOn}
                            onCheck={ function() { p.toggleCity(p.oid, index, cityIsOn, p.org.cities) } }
                            style={styles.checkbox} />
 
                    })
                }

            </div>
        )
    }
}

export default CityToggles;