import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';


class OrgDisplay extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    componentWillMount() {
        store.dispatch( actions.startListeningToOrgs() );
    }

    render () {
        const p = this.props;
        const orgId = p.org;

        const org = p.orgs && p.orgs.data && p.orgs.data[orgId];



        return (
            <span className="orgdisplay">
                    { org ? org.name : orgId}
            </span>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        orgs : appState.orgs
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrgDisplay);