

import _ from 'lodash';
import List from 'material-ui/List';
import Face from 'material-ui/svg-icons/action/face';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddUserForm from './components/addUserForm';

const radioButtonStyle = {
    marginBottom: 16
};

const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToUsers() );
    store.dispatch( actions.startListeningToGroups() );
    store.dispatch( actions.startListeningToOrgs() );
});

class Users extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const rows = _.map(p.users.data, function (user, uid) {
            return (
                <div className="user-list-item" key={uid}>
                    <Link to={ "manageuser/" + uid }><Face /> {user.fullname}</Link>
                    <span style={{paddingLeft: '30px'}}>{user.email}</span>
                </div>
            )
        })
        let newUser = Object.assign({}, {}, this.state.newUser);
        return (
                <div>
                    <div className="panel">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-8">
                                    <List className="userList">
                                        {p.users.hasReceivedUserData ? rows : "Loading Users..."}
                                    </List>
                                </div>
                                <div className="col-sm-4" style={{ borderLeft : "1px dotted #571a89", paddingLeft: "35px"}}>
                                    <h6>{"Add a new user"}</h6>
                                    <AddUserForm
                                        orgs={p.orgs}
                                        className="dxd-add-user-form"
                                        onSubmit={(function (e) {e.preventDefault(); this.props.addUser(); }).bind(this) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        users: appState.users,
        groups: appState.groups,
        orgs: appState.orgs
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        addUser: function () { dispatch( actions.addUser() )}
    }
}

Users.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Users);