
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import MobileForm from './components/mobileform';


const style = {
    paper : {
        margin: 30,
        padding: 30
    }
}

class Alerts extends Component {

    componentWillMount () {
        // will need to listen to alerts for this user
    }

    

    render () {
        let phoneArea = this.props.auth.mobile ? (
                <div>Alerts will be sent to {this.props.auth.mobile}</div>
            ) : (
                <div>
                    {"To configure alerts please add your mobile phone number."}
                    <MobileForm  onSubmit={(function (e) {e.preventDefault(); this.props.addMobile(); }).bind(this) } />
                </div>
            );

        let alertArea = this.props.auth.mobile ? (
                <div className="row">
                    // two columns - users and orgs
                    //user - list users following - form for following users
                    // org - list orgs following - form for following orgs
                </div>
            ) : null;

        return (

                <div>
                    <Paper style={style.paper}>
                        <div>{phoneArea}</div>
                    </Paper>
                </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        users: appState.users,
        orgs: appState.orgs
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addMobile: function () { dispatch( actions.addMobile() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Alerts);

