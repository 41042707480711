import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  mobileNavToggled: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.TOGGLE_MOBILE_NAV:
        return Object.assign({}, state, {
          mobileNavToggled: !state.mobileNavToggled
        });

    case C.HIDE_MOBILE_NAV:
        return Object.assign({},state,{
            mobileNavToggled: false
        });

    case C.SHOW_MOBILE_NAV:
        return Object.assign({}, state, {
            mobileNavToggled: true
        })

      default:
        return state;
  }
}