import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import ErrorPanel from './errorpanel';

const validate = values => {
    const errors = {}
    const requiredFields = ['email', 'password', 'passwordAgain', 'firstname', 'lastname', 'inviteKey', 'invitor', 'oid']
    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required'
        }
    })

    if (values.password && values.passwordAgain && (values.password !== values.passwordAgain)) {
        errors.password = 'Passwords must match'
        errors.passwordAgain = 'Passwords must match'
    }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}


class PaidRegistrationForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount () {
        this.handleInitialize();
    }

    handleInitialize() {
        // const initData = {
        //     "email": this.props.invite.invitee,
        //     "inviteKey": this.props.inviteKey,
        //     "invitor": this.props.invite.invitor
        // };
        // console.log("trying to initialize: ", initData);

        this.props.initialize(this.props.initialValues);
      }

    render () {

        console.log("Rendering create account form: ", this.props.invite, this.props.inviteKey);
        console.log("initialValues", this.props.initialValues);
        const openInvite = this.props.invite.invitee == "open";
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <ErrorPanel />
                <div>
                    <Field 
                        name="email"
                        component={TextField}
                        hintText="Type your e-mail here"
                        disabled={!openInvite}
                        floatingLabelText="E-mail"
                        ref="email" withRef />
                </div>
                <div>
                    <Field 
                        name="password"
                        component={TextField}
                        type="password"
                        hintText="Type your password here"
                        floatingLabelText="Password"
                        ref="password" withRef />
                </div>
                <div>
                    <Field 
                        name="passwordAgain"
                        component={TextField}
                        type="password"
                        hintText="Type your password again here"
                        floatingLabelText="Password Again"
                        ref="passwordAgain" withRef />
                </div>
                <div>
                    <Field 
                        name="firstname"
                        component={TextField}
                        hintText="Type your first name here"
                        floatingLabelText="First Name"
                        ref="firstname" withRef />
                </div>
                <div>
                    <Field 
                        name="lastname"
                        component={TextField}
                        hintText="Type your last name here"
                        floatingLabelText="Last Name"
                        ref="lastname" withRef />
                </div>

                <Field name="inviteKey" component="input" type="hidden" />
                <Field name="invitor" component="input" type="hidden" />
                <Field name="oid" component="input" type="hidden" />
                <Field name="makeManager" component="input" type="hidden" />
                <Field name="reconcile" component="input" type="hidden" />

                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Create Account"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
                <div className="container-fluid cntr">
                    Already have an account? <Link to="/login">Login here.</Link>
                </div>
            </form>
        )
    }
}

// Decorate the form component
PaidRegistrationForm = reduxForm({
  form: 'paidRegistration', // a unique name for this form
  validate
})(PaidRegistrationForm);

// You have to connect() to any reducers that you wish to connect to yourself
PaidRegistrationForm = connect(
  state => ({
    initialValues: state.liveInvite.data // pull initial values from invite reducer
  })           
)(PaidRegistrationForm)


export default PaidRegistrationForm;