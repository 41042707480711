import { reset } from 'redux-form';
import { C } from '../constants';
import Mailer from '../utils/mailer';

const assignmentsRef = C.APP.database().ref('assignments');
const mailer = new Mailer();

export const startListeningToAssignment = function (assignmentId) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_ASSIGNMENT_DATA, key: assignmentId });
        console.log("trying to watch assignment: " + assignmentId);
        assignmentsRef.child(assignmentId).on('value', (snap) => {
            console.log("received assignment: " + assignmentId);
            dispatch({ type: C.RECEIVE_ASSIGNMENT_DATA, key: assignmentId, data: snap.val() });
        });
    }
}

export const assignCoaching = (driverUid, surveys) => {
    return (dispatch, getState) => {
        const assignorUid = getState().auth.uid;
        const surveyUid = getState().form.assignment.values.survey;
        const assignment = {
            uid: surveyUid,
            description: surveys[surveyUid]
        }

        dispatch(reset('assignment'));
        mailer.sendNewAssignment(driverUid, assignorUid, assignment);
    }
}

export const sendAssignmentReminder = assignmentUid => {
    return (dispatch, getState) => {
        console.log("sendAssignmentReminder");
        mailer.resendAssignment(assignmentUid);
    }
}

/**
* delete assignment from DB (and assignee's list of assignments)
*/
export const cancelAssignment = function(assignmentId) {
    return function (dispatch, getState) {
        console.log("deleteAssignment");
        assignmentsRef.child(assignmentId).once("value", (assignmentSnap) => {
            C.DB.ref('users/' + assignmentSnap.val().assignee + '/assignments/' + assignmentId).set(null);
            C.DB.ref('users/' + assignmentSnap.val().assignee).update({currentlyAssigned: null});
            assignmentsRef.child(assignmentId).set(null);
        })
    }
}


