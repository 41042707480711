import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';



const FIELD_NAME = 'testfile';

const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Try dropping launcher file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class TestFileForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("Rendering test file form");
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor={FIELD_NAME}>Test File</label>
                        <Field
                            name={FIELD_NAME}
                            component={renderDropzoneInput} />
                    </div>
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload Test File"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
TestFileForm = reduxForm({
  form: 'testfile'
})(TestFileForm);


export default TestFileForm;