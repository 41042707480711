import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';

class UtilButton extends Component {
    render () {
        return (
            <RaisedButton
                secondary
                style={{ width: "85%", margin: "25px 40px 0px" }}
                onTouchTap={this.props.onTouchTap}
                label={this.props.label} />
        )
    }
}

class DbUtils extends Component {

    render () {

        const functions = {
            'Remove "addedToDormant" flags from all assessments' : this.props.removeAddedToDormantFlags,
            "Reset Dormant Dataset" : this.props.resetDormantDataset,
            "Load all assessments into assess table in dormant dataset" : this.props.loadAllAssessmentsIntoDormant,
            'Remove "addedToActive" flags from all assessments' : this.props.removeAddedToActiveFlags,
            "Reset Active Dataset" : this.props.resetActiveDataset,
            "Load all assessments into assess table in active dataset" : this.props.loadAllAssessmentsIntoActive,
            "Clear BQ Debug" : this.props.clearBQDebug
        }

        const p = this.props;
        let index = 0;
        return (
            <div>
                <div>
                    {_.map(functions, (action, label) => {
                        return <UtilButton key={index++} label={label} onTouchTap={action} />
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        removeAddedToDormantFlags: function () { dispatch( actions.removeAlreadyAddedFlag('dormant') ) },
        resetDormantDataset: function () { dispatch( actions.initializeAssessmentTable("dormant") )},
        loadAllAssessmentsIntoDormant: function () { dispatch( actions.loadAllAssessmentsIntoBigQuery("dormant") )},
        removeAddedToActiveFlags: function () { dispatch( actions.removeAlreadyAddedFlag('active') ) },
        resetActiveDataset: function () { dispatch( actions.initializeAssessmentTable("active") )},
        loadAllAssessmentsIntoActive: function () { dispatch( actions.loadAllAssessmentsIntoBigQuery("active") )},
        clearBQDebug: function () { dispatch( actions.clearBQDebug() )}

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DbUtils);