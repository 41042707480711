import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';

import ReadyAssessLogo from '../assets/images/Ready-Assess-Logo.png';
import DxdLogo from '../assets/images/DiagnosticDrivingLogo.png';


class Apology extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    componentWillMount () {
        
    }

    componentDidMount() {
        actions.logApology(this.props.aid, this.props.msg);
    }

    render () {


        // if (!(p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData)) {
        //     return <div>Loading...</div>
        // }

        // viewport is 1125px tall 

        const aplg = "We're sorry, something went wrong.";

        return (
            <div className="fbr">
                <div className="fbr-page-1">
                    <div className="fbr-p1-ra-logo"><img width="460"  alt="" src={ReadyAssessLogo} /></div>
                    <div className="fbr-p1-deets display-6">
                        <div className="fbr-sct-hdr">Thank you for caring about being a safer driver.</div>
                        <div className="fbr-sct-cntnt">
                            <p>
                               {aplg} Please contact our <a href="mailto:support@diagnosticdriving.com">support team</a> for help accessing your Personalized Feedback Report.
                            </p>
                        </div>
                    </div>
                    <div className="fbr-p1-dxd-logo"><img className="fbr-dxd-logo" width="386"  alt="" src={DxdLogo} /></div>
                </div>
            </div> 
        );
        
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Apology);