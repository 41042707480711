/*
This is the initial state of the Redux Store.
This is actually just the initial state of the auth store
// store/index.js combines reducers
//TODO: might be nice to have a picture of all init state in one file
*/

import { C } from '../constants';


export const init = {
    auth: {
        currently: C.UNINITIATED,
        allowed: true,
        username: "guest",
        uid: null,
        gid: null, //default to driver? base role?
        requestVerification: false,
        emailVerified: false,
        featureFlags: {},
        orgAbrv: "",
        orgName: "",
        scoringMethod: "",
        orgType: "",
        featureFlags: {},
        apiToken: "",
        apiTokenV2: "",
        tokenState: "idle",
        isParent: false,
        childOrgs: {},
        shareUrl: '',
        payPerAssessment: false,
        credits: 0,
        fakeDriver: false,
        fakeCount: false,
        fakeAssessmentCount: 0,
        showEmailOpen: false,
        cpt: {},
        cptModules: {}
    },
};

export default init;