

import { ListItem } from 'material-ui/List';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../../actions';
import store from '../../store';

class Drive extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        store.dispatch( actions.listenToAndNormalizeDriveData( this.props.aid ) );
    }

    render () {
        const p = this.props;
        const loadingRow = (
                <ListItem primaryText="Loading..." />
            );
        if (p.drive.empty) {
            return loadingRow;
        }

        let driveData = p.drive[p.aid]; 


        if (!driveData) {
            //no drive data yet
            return loadingRow;
        }

        if (driveData.loading) {
            return loadingRow;
        }


        return (
            <ListItem containerElement={Link} to={'/driveresults/' + p.aid} key={p.aid} primaryText={new Date(driveData.start).toLocaleString()} />
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        drive: appState.drive,
        util: appState.util
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}

Drive.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Drive);



