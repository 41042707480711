import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedWebhookData: false,
  submittingnew: false,
  data: {},
  deleting: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_WEBHOOKS:
        return Object.assign({}, state, {
          data: action.webhooks,
          hasReceivedWebhookData: true
        });

    case C.AWAIT_NEW_WEBHOOK_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_WEBHOOK_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

    case C.AWAIT_WEBHOOK_DELETION:
        let record = {};
        record[action.hookID] = true;
        return Object.assign({}, state, {
            deleting: Object.assign({}, state.deleting, record)
        })

      default:
        return state;
  }
}