import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../../actions';



class WelcomeDash extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {
        const p = this.props;
        if (!p.auth.brandNew) {
            return null;
        }

        const isComcast = p.auth.orgAbrv == "CMC";
        const cmcFdbkMsg = <p>{"Your feedback report will be emailed to you within 3 business days after you complete your test."}</p>;

        let firstStep = p.auth.gid == "driver" ? (
            <div>
                <p>{"This is your personal dashboard where you can access the results of your virtual driving test."}</p>
                <div className="display-6">{"What do I do next?"}</div>
                <p>
                    {"Before you complete your scheduled virtual driving test at a designated testing location, please take a few minutes to "}
                    <Link to="/intake">complete your Intake</Link>
                    {" by selecting \"Intake\" on the "}
                    <span className="hidden-md-down">{"left panel."}</span>
                    <span className="hidden-lg-up">{" ".replace(/ /g, "\u00a0")}<i className="icon ion-navicon-round"></i>{"  Menu.".replace(/ /g, "\u00a0")}</span>
                    {" Your individual results will not be shared with " + p.auth.orgName + " or anyone else."}
                </p>

                <div className="display-6">{"When will my virtual driving test feedback report be available for me to review?"}</div>
                {isComcast ? cmcFdbkMsg :
                    <div>
                        <p>{"Your feedback report will be posted within 2 business days after you complete your test."}</p>
                        <div className="display-6">{"How do I access my virtual driving test feedback report?"}</div>
                        <p>{"You can access your feedback report by selecting \"My Drives\" on the "} <span className="hidden-md-down">{"left panel."}</span><span className="hidden-lg-up">{" ".replace(/ /g, "\u00a0")}<i className="icon ion-navicon-round"></i>{"  Menu.".replace(/ /g, "\u00a0")}</span> {"  If you took multiple tests, your individual feedback reports will also be posted in \"My Drives.\""}</p>
                    </div>
                }
                
                <div className="display-6">{"What if I have questions?"}</div>
                <p>{"You can contact support@diagnosticdriving.com and we’ll get back to you within 24 hours."}</p>
            </div>
        ) : (
            <div>
                <p>{"This is your personal dashboard where you can access the results of your virtual driving test."}</p>
            </div>
        );

        let mgrSpr = (p.auth.gid == "super" || p.auth.gid == "manager") ? (
            <div>
                <p>{"As a manager, you can add driver IDs through the \"Add Driver Ids\" tab."}</p>
                <p>{"The Virtual Driving Test software will need to be installed on a PC or a Mac with a Logitech Steering Wheel attached."}</p>
                <p>{"The Virtual Driving Test Launcher can be downloaded"} <Link to="/download">{"here."}</Link></p>
            </div>
        ) : null;

        let onlySpr = (p.auth.gid == "super") ? (
                <p> {"As a Super User, there are other links and controls that show on this page and others that allow you to administrate the system. Be Careful!"}</p>
        ) : null;

        return (
            <div className="panel" style={{marginBottom: "40px"}}>
                <div className="panel-body">
                    <div className="row">
                        <div className={"col-sm-" + (p.auth.lastIntake ? "9" : "12")}>
                            <h3 style={{marginBottom: "10px"}} className="panel-title">
                                Welcome to Diagnostic Driving
                            </h3>
                            <div className="dash-section-body">
                                
                                {firstStep}
                                {mgrSpr}
                                {onlySpr}
                            </div>
                        </div>
                        {
                            <div className="col-sm-3">
                                <RaisedButton
                                    primary
                                    label="Dismiss"
                                    onTouchTap={function () {
                                        p.setUserAsNotNew(p.auth.uid);
                                    }}
                                    style={{ width: "100%", marginTop: "15px" }} />
                            </div>
                            

                        }
                        
                    </div>
                    
                    
                    
                </div>  
            </div>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setUserAsNotNew: function (uid) { dispatch( actions.setUserAsNotNew(uid) )}
    }
}

WelcomeDash.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(WelcomeDash);