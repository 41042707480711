import { C } from '../../constants';

const initialState = {
    empty : true
}

export default (state = initialState, action) => {
    let entry = {};
    switch (action.type) {
        case C.RECEIVE_DRIVE_DATA:
            entry[action.key] = entry[action.key] || {};
            entry[action.key].loading = false;
            entry[action.key] = action.data;
            return Object.assign({}, state, entry);

        case C.AWAIT_NEW_DRIVE_DATA:
            entry[action.key] = {};
            entry[action.key].loading = true;
            entry.empty = false;
            return Object.assign({}, state, entry);

        case C.DRIVE_RESULTS_UPDATED:
            entry[action.key] = Object.assign({}, state[action.key], {
                results: action.data
            });
            return Object.assign({}, state, entry);

        default:
            return state;
  }
}