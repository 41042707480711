import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import Checkbox from 'material-ui/Checkbox';
import Paper from 'material-ui/Paper';

const renderCheckbox = ({ input, label }) => (
  <Checkbox
    label={label}
    checked={input.value ? true : false}
    onCheck={input.onChange} />
)

const validate = values => {
    const errors = {}

    if (!values["batchTag"]) {
        errors ["batchTag"] = "Required"
    }

    return errors
}


class EmailSettingsForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.props.initialize({ batchTag: 'dailyEOD' });
    }

    render () {

        const p = this.props;
        const emailSettings = p.org.config && p.org.config.email

        return (
            <Paper style={{ margin: "30px", padding: "25px"}}>
                <div className='display-6 btm-space'>Configure Email Settings</div>
                <div className='btm-space'>
                {emailSettings ? 'batchTag: ' + emailSettings.batchTag + ', sendImmediately: ' + emailSettings.sendImmediately : 'No email settings configured yet'}
                </div>
                <form onSubmit={this.props.onSubmit}>
                    <Field
                        style={{ display: 'none' }}
                        disabled="true"
                        name="batchTag"
                        component={TextField}
                        floatingLabelText="Batch Tag"
                        hintText="Enter a batch tag" />
                    <Field name="sendImmediately" component={renderCheckbox} label="Send Immediately?" />
                    <RaisedButton
                        primary
                        disabled={!this.props.valid}
                        //disabled={this.props.pristine || !this.props.valid}
                        label="Submit"
                        type="submit" />

                </form>
            </Paper>
        )
    }
}


// Decorate the form component
EmailSettingsForm = reduxForm({
  form: 'emailsettings', // a unique name for this form
  validate
})(EmailSettingsForm);




export default EmailSettingsForm;