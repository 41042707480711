import _ from 'lodash';
import { C } from '../../constants';

const cref = C.APP.database().ref('customers');
const pref = C.APP.database().ref('provisional');
const assessmentsBaseRef = C.APP.database().ref('assessments');
//NEXT: clear form on submit
//clear results on resubmit

export const startListeningToCustomer = function (bassid) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_CUST_DATA, key: bassid });
        const org = getState().auth.oid;
        cref.child([org, bassid].join('/')).on('value', (snap) => {
            const custData = snap.val();
            if (custData) {
                dispatch({ type: C.RECEIVE_CUST_DATA, key: bassid, data: custData });
                if (custData && custData.hasOwnProperty('assessments')) {
                    _.map(custData.assessments, (ad, aid) => {
                        assessmentsBaseRef.child(aid).on("value", (snap) => {
                            dispatch({ type: C.RECEIVE_CUST_ASS_DATA, key: bassid, aid: aid, ad: snap.val() });
                        })
                    });
                }
            } else {
                dispatch({ type: C.CUSTOMER_NOT_FOUND, key: bassid });
            }
            
        })
    }
}

export const addDriverId = function (isProvisional) {
    return function (dispatch, getState) {

        addDrivers([getState().form.addDriver.values.driverId], isProvisional, dispatch, getState);

    }
}

function addDrivers(dvs, prv, dispatch, getState) {
    dispatch({type: C.ADDING_CUSTOMERS});

    const addedBy = getState().auth.uid;

    let transactionPromises = [];
    for (let dv of dvs) {
        let idPath = getState().auth.oid + "/" + dv;

        transactionPromises.push(
            cref.child(idPath).transaction((currentData) => {
                if (currentData === null) {
                    return {
                        added: C.TS,
                        addedBy
                    }
                }
            })
        )

        if (prv) {
            transactionPromises.push(pref.child(idPath).transaction((currentData) => {
                if (currentData === null) {
                    return {
                        added: C.TS,
                        addedBy
                    }
                }
            }));
        }
    }

    Promise.all(transactionPromises).then((results) => {
        /*
        We can remove this an the checks below when we are not 
        double adding IDs for odps 
        */
        let successes = {};
        let failures = {};
        for (let result of results) {
            // There's gotta be a better way to do this. . . but the firebase documentation is sparse.
            // Here we're checking whether this particular transaction is adding to provisional or customers
            let prov = result.snapshot.ref.toString().indexOf('provisional') > 0;
            console.log("~~~~~~~~~~~~##>", prov);

            if (!result.committed) {
                if (!failures[result.snapshot.key]) {
                    if (prov) {
                        dispatch({type: C.ADD_PROVISIONAL_BASS_KEY_EXISTS_MSG, bid: result.snapshot.key});
                    } else {
                        dispatch({type: C.ADD_BASS_KEY_EXISTS_MSG, bid: result.snapshot.key});
                    } 
                }
                failures[result.snapshot.key] = true;
                
            } else {
                if (!successes[result.snapshot.key]) {
                    if (prov) {
                        dispatch({type: C.ADD_PROVISIONAL_BASS_KEY_SUCCESS, bid: result.snapshot.key});
                    } else {
                        dispatch({type: C.ADD_BASS_KEY_SUCCESS, bid: result.snapshot.key});
                    }
                }
                successes[result.snapshot.key] = true;
                
            }
        }
        dispatch({type: C.BASS_KEY_DONE});
    }).catch((error) => {
        dispatch({type: C.ADD_BASS_KEY_ERROR});
        dispatch({type: C.BASS_KEY_DONE});
        console.log(">>>>>>>>>>>>> bass key error", error);
    }) 
}


export const addOdpsCustomers = function (bassIDs, addedBy, opt_provisional) {
    return function (dispatch, getState) {

        addDrivers(bassIDs, opt_provisional, dispatch, getState);

    }
}