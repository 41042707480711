import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedBuildData: false,
  uploading: false,
  error: "",
  acting: false,
  progress: 0,
  totalBytes: 0,
  bytesTransferred: 0,
  data: {},
  commentData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_BUILDS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedBuildData: true
        });

    case C.RECEIVE_BUILD_COMMENTS_DATA:
        return Object.assign({}, state, {
          commentData: action.data
        });

    case C.AWAIT_NEW_BUILD_RESPONSE:
        return Object.assign({},state,{
            uploading: true,
            error: ""
        });

    case C.RECEIVE_NEW_BUILD_RESPONSE:
        return Object.assign({}, state, {
            uploading: false,
            error: "",
            progress: 0
        })

    case C.START_BUILD_ACTION:
      return Object.assign({}, state, {
            acting: true,
            error: "",
            action: ""
        })

    case C.END_BUILD_ACTION:
      return Object.assign({}, state, {
            acting: false,
            action: ""
        })

    case C.DELETE_BUILD:
        return Object.assign({}, state, {
            uploading: false,
            error: action.error,
            progress: 0,
            data: action.data
        })

    case C.DELETE_TAG:
        return Object.assign({}, state, {
            // uploading: false,
            error: action.error,
            // progress: 0,
            // data: action.data
        })

    case C.RECEIVE_BUILD_UPLOAD_ERROR:
        return Object.assign({}, state, {
            uploading: false,
            error: action.error,
            progress: 0
        })

    case C.SET_BUILD_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            progress: action.progress,
            totalBytes: action.totalBytes,
            bytesTransferred: action.bytesTransferred
        })

      default:
        return state;
  }
}