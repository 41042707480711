import { C } from '../../constants';

const initialState = { //define initial state - an empty form
    currentIndex: 0,
    endIndex: 0,
    loaded: false,
    moduleId: "",
    title: "",
    data: [],
    scenarios: {},
    allScenarios: {},
    allScenariosLoading: false,
    jsonUploading: false,
    jsonErrors: [],
    allModules: {},
    allModulesLoaded: false,
    moduleJsonUploading: false,
    moduleJsonErrors: []
};


function updateNestedState(state, nestedProp, key, value) {
    let newEntry = {};
    newEntry[key] = value;
    return Object.assign({}, state[nestedProp], newEntry);
}

export default (state = initialState, action) => {
  switch (action.type) {

    case C.RECEIVE_SCENARIO_DATA:
        return Object.assign({}, state, {
            scenarios: updateNestedState(state, "scenarios", action.id, { hasReceivedData: true, data: action.data})
        });

    case C.AWAIT_SCENARIO_DATA:
        return Object.assign({},state,{
            scenarios: updateNestedState(state, "scenarios", action.id, { hasReceivedData: false })
        });

    case C.AWAIT_ALL_SCENARIOS_DATA: 
        return Object.assign({}, state, {
            allScenariosLoading: true
        });

    case C.RECEIVE_ALL_SCENARIOS_DATA: 
        return Object.assign({}, state, {
            allScenariosLoading: false,
            allScenarios: action.data
        });

    case C.RECEIVE_ALL_MODULES_DATA: 
        return Object.assign({}, state, {
            allModulesLoaded: true,
            allModules: action.data
        });

    case C.AWAIT_NEW_SCENARIO_JSON_RESPONSE: 
        return Object.assign({}, state, {
            jsonUploading: true,
            jsonErrors: []
        });

    case C.AWAIT_NEW_MODULE_JSON_RESPONSE: 
        return Object.assign({}, state, {
            moduleJsonUploading: true,
            moduleJsonErrors: []
        });

    case C.RECEIVE_NEW_SCENARIO_JSON_RESPONSE: 
        return Object.assign({}, state, {
            jsonUploading: false,
            jsonErrors: []
        });

    case C.RECEIVE_NEW_MODULE_JSON_RESPONSE: 
        return Object.assign({}, state, {
            moduleJsonUploading: false,
            moduleJsonErrors: []
        });

    case C.RECEIVE_SCENARIO_JSON_UPLOAD_ERROR: 
        console.log('invalid json', action);
        return Object.assign({}, state, {
            jsonErrors: action.errors,
            jsonUploading: false
        });

    case C.RECEIVE_MODULE_JSON_UPLOAD_ERROR: 
        console.log('invalid json', action);
        return Object.assign({}, state, {
            moduleJsonErrors: action.errors,
            moduleJsonUploading: false
        });

    case C.RECEIVE_MODULE_DATA:
        return Object.assign({}, state, {
            loaded: true,
            data: action.data,
            moduleId: action.id,
            endIndex: action.end,
            title: action.title
        });

    case C.AWAIT_MODULE_DATA:
        return Object.assign({},state,{
            loaded: false
        });

    case C.SET_CPT_INDEX:
        return Object.assign({},state,{
            currentIndex: action.index
        });

      default:
        return state;
  }
}