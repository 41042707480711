import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { C } from '../constants';

const launchersRef = C.APP.database().ref('launchers');
const fbstore = firebase.storage();
const storage = fbstore.ref();
const DIRNAME = "launchers/";

export const listenToLauncher = function () {

    return function (dispatch, getState) {
        console.log("LISTEN TO LAUNCHERS");
        launchersRef.on('value', (snap) => {
            console.log("LAUNCHER LOADED!!!", snap.val());
            const plt = window.navigator.platform;
            const canDownload = plt.startsWith("MacIntel") || plt.startsWith("Win");
            const path = plt.startsWith("Win") ? snap.val().windows.path : snap.val().mac.path;
            let payload = {
                type: C.RECEIVE_LAUNCHER_REFS,
                mac: snap.val().mac,
                win: snap.val().windows,
                blobUrl: "",
                filename: ""
            }
            if (canDownload) {
                storage.child(path).getDownloadURL().then((url) => {
                    // `url` is the download URL for 'images/stars.jpg'
                    window.prodConsole.log("download url is: ", url);
                    // This can be downloaded directly:
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = function(event) {
                        let blob = xhr.response;
                        let blobUrl = URL.createObjectURL(blob);
                        payload.blobUrl = blobUrl;
                        payload.filename = path.split('launchers/')[1];
                        dispatch(payload);
                    };
                    xhr.open('GET', url);
                    xhr.send();

                }).catch((error) => {
                    // Handle any errors
                });
            } else {
                dispatch(payload);
            }
            
        }, (error) => { console.log(error); });
    }
}

export const fetchMaps = function () {
    return (dispatch, getState) => {
        for (var i = 0; i < 4; i++) {
            const mapSuccess = (function (index) {
                return function (response) {
                    dispatch({type: C.RECEIVE_MAP_JSON, index, response})
                }
            })(i);
            const mapError = (function (index) {
                return function (errorMessage) {
                    dispatch({type: C.RECEIVE_MAP_JSON_ERROR, index, errorMessage})
                }
            })(i);
            storage.child('maps/map_' + i + '.ddd').getDownloadURL().then(url => {
                var mapReq = new XMLHttpRequest();
                mapReq.open('GET', url, true);
                mapReq.responseType = 'json';
                mapReq.onload = () => {
                    mapSuccess(mapReq.response);
                }
                mapReq.onerror = () => {
                    mapError(mapReq.statusText);
                }
                mapReq.send();
            });
        };
    }
    
}

export const downloadLauncher = function (path) {
    return function (dispatch, getState) {
        storage.child(path).getDownloadURL().then((url) => {
            // `url` is the download URL for 'images/stars.jpg'

            // This can be downloaded directly:
            let xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            let newWindow = window.open();
            xhr.onload = function(event) {
                let blob = xhr.response;
                let blobUrl = URL.createObjectURL(blob);

                newWindow.location = blobUrl;

                setTimout(() => {
                    newWindow.close();
                }, 500);
            };
            xhr.open('GET', url);
            xhr.send();

        }).catch((error) => {
            // Handle any errors
        });
    }
    
}

export const uploadLaunchers = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.launcher.values;

        const macFile = formValues.maclauncherfile && formValues.maclauncherfile[0];
        const winFile = formValues.windowslauncherfile && formValues.windowslauncherfile[0];

        if (macFile) {
            dispatch({type: C.AWAIT_NEW_MAC_LAUNCHER_RESPONSE});
            let path = 'launchers/' + macFile.name;
            const macStore = storage.child(path);
            let macUploadTask = macStore.put(macFile);
            macUploadTask.on('state_changed', (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                dispatch({type: C.SET_MAC_LAUNCHER_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
                }, (error) => {
                    // Handle unsuccessful uploads
                    dispatch({type: C.RECEIVE_MAC_LAUNCHER_UPLOAD_ERROR, error: "An error occurred in the upload process.", 
                        transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
                }, () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    macUploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        launchersRef.child('mac').set({ path, downloadURL }).then(() => {
                            dispatch({type: C.RECEIVE_NEW_MAC_LAUNCHER_RESPONSE});
                        }).catch((error) => {
                            dispatch({type: C.RECEIVE_MAC_LAUNCHER_UPLOAD_ERROR, error: "An error occurred in saving launcher to firebase."});
                        });
                    })
                   
                    
            });
        }

        if (winFile) {
            dispatch({type: C.AWAIT_NEW_WIN_LAUNCHER_RESPONSE});
            let path = 'launchers/' + winFile.name;
            const winStore = storage.child(path);
            let winUploadTask = winStore.put(winFile);
            winUploadTask.on('state_changed', (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                dispatch({type: C.SET_WIN_LAUNCHER_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
                }, (error) => {
                    // Handle unsuccessful uploads
                    dispatch({type: C.RECEIVE_WIN_LAUNCHER_UPLOAD_ERROR, error: "An error occurred in the upload process.", 
                        transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
                }, () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                    winUploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        launchersRef.child('windows').set({ path, downloadURL }).then(() => {
                            dispatch({type: C.RECEIVE_NEW_WIN_LAUNCHER_RESPONSE});
                        }).catch((error) => {
                            dispatch({type: C.RECEIVE_WIN_LAUNCHER_UPLOAD_ERROR, error: "An error occurred in saving launcher to firebase."});
                        });
                    })
            });
        }

    }
}

export const uploadTestFile = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.testfile.values;

        const file = formValues.testfile && formValues.testfile[0];

        if (file) {
            dispatch({type: C.AWAIT_NEW_TEST_FILE_RESPONSE});
            let path = 'testuploads/' + file.name;
            const fileStore = storage.child(path);
            let fileUploadTask = fileStore.put(file);
            fileUploadTask.on('state_changed', (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                dispatch({type: C.SET_TEST_FILE_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
                }, (error) => {
                    // Handle unsuccessful uploads
                    dispatch({type: C.RECEIVE_TEST_FILE_UPLOAD_ERROR, error: error.message, 
                        transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
                }, () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                    dispatch({type: C.RECEIVE_NEW_TEST_FILE_RESPONSE, lastFile: file.name});
            });
        }

    }
}

export const uploadMaps = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.maps.values;

        const files = [
            formValues.map_0 && formValues.map_0[0],
            formValues.map_1 && formValues.map_1[0],
            formValues.map_2 && formValues.map_2[0],
            formValues.map_3 && formValues.map_3[0]
        ]

        for (var i = 0; i < files.length; i++) {
            if (files[i]) {
                dispatch({type: C.AWAIT_NEW_MAP_RESPONSE, index: i});
                let path = 'maps/map_' + i + '.ddd';
                const store = storage.child(path);
                let uploadTask = store.put(files[i]);
                const onProg = (function (index) {
                    return function (snapshot) {
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        dispatch({type: C.SET_MAP_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred, index: index})
                    }
                })(i);
                const onErr = (function(index) {
                    return function(err) {
                        dispatch({type: C.RECEIVE_MAP_UPLOAD_ERROR, index, error: "An error occurred in the upload process."});
                    }
                })(i);
                const onSucc = (function(index) {
                    return function () {
                        dispatch({type: C.RECEIVE_NEW_MAP_RESPONSE, index});
                    }
                })(i);
                uploadTask.on('state_changed', onProg, onErr, onSucc);
            }
        };

    }
}