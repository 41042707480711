import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Experiment from './experiment';
import ExperimentForm from './experimentform';



class Experiments extends Component {

    render() {
        const p = this.props;
        const tests = p.org.config && p.org.config.abtests || [];

        
        let  testRows = [];


        for (var i = 0; i < tests.length; i++) {
            testRows.push(<Experiment
                                deleteExperiment={(function(tests, index) {
                                    return (function (e) {
                                        e.preventDefault(); 
                                        p.deleteExperiment(tests, index, p.oid);
                                    }).bind(this)
                                })(tests, i)}
                                test={tests[i]}
                                key={'ab_' + i} />);
        };


        return (

            <div>
                <div className='display-6 btm-space'>Configure Experiments</div>
                <div className='row'>
                    <div className='col-sm-7'>
                        <div className="abtest-form">
                            <ExperimentForm
                                onSubmit={(function (e) {e.preventDefault(); this.props.addExperiment(tests, p.oid); }).bind(this) }
                                className="experiment-form"
                                flags={p.flags} />
                        </div>
                    </div>
                    <div className='col-sm-5'>
                        <div className="abtest-box">
                            {testRows}
                        </div>
                    </div>
                </div>
                
                
            </div>
                
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        // flags: appState.featureFlags
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        addExperiment: function (tests, oid) {
            dispatch( actions.addExperiment(tests, oid) )
        },
        deleteExperiment: function (tests, i, oid) {
            dispatch( actions.deleteExperiment(tests, i, oid))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Experiments);

