import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField } from 'redux-form-material-ui';


class CoachingStepForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        return (
            <Paper style={{ margin: "30px", padding: "25px"}}>
                <h3>Add a New Coaching Step</h3>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    <div className="row">
                        <div className="col-sm-4">
                            <Field
                                name="firstvideo"
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText="First Video">
                                {
                                    _.map(this.props.videos.data, (item, key) => {
                                        return <MenuItem value={key} key={key} primaryText={item.title} />
                                    })
                                }
                            </Field>
                        </div>
                        <div className="col-sm-4">
                            <Field
                                name="firstquestion"
                                fullWidth={true}
                                component={TextField}
                                floatingLabelText="First Question"
                                hintText="Type the first question" />
                        </div>
                        <div className="col-sm-4">
                            <Field
                                name="secondquestion"
                                fullWidth={true}
                                component={TextField}
                                floatingLabelText="Second Question"
                                hintText="Type the second question" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <Field
                                name="secondvideo"
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText="Second Video">
                                {
                                    _.map(this.props.videos.data, (item, key) => {
                                        return <MenuItem value={key} key={key} primaryText={item.title} />
                                    })
                                }
                            </Field>
                        </div>
                        <div className="col-sm-8">
                            <Field
                                name="lastquestion"
                                component={TextField}
                                fullWidth={true}
                                floatingLabelText="Last Question"
                                hintText="Type the last question" />
                        </div>
                    </div>

                        <div style={{textAlign:"right"}}>
                            <RaisedButton
                                primary
                                disabled={this.props.pristine || !this.props.valid}
                                label="Add"
                                type="submit"
                                style={{ width: "20%", marginTop: "15px" }} />
                        </div>
                </form>

            </Paper>
        )
    }
}

// Decorate the form component
CoachingStepForm = reduxForm({
  form: 'coachingStepForm', // a unique name for this form
})(CoachingStepForm);

export default CoachingStepForm;