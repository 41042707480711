import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import Assessment from './components/assessment';






class DriverAssessments extends Component {
    constructor(props, context) {
        super(props, context);
        
    }
    // this.props.params.bassid
    componentWillMount () {

        store.dispatch( actions.startListeningToCustomer(this.props.params.driverId) );
    }

    render () {
        const customer = this.props.customer[this.props.params.driverId];
        console.log("CUSTOMER", customer);
        let asms = "loading driver assessment data";
        if (customer) {
            if (customer.hasOwnProperty("assessments")) {
                asms = _.map(customer.assessments, function(ad, aid) {
                    return <Assessment data={ad} aid={aid} key={aid} isOwnerView={true} />
                });
                asms = asms.reverse()
                if(!asms.length) {
                    asms = "No assessments found for " + this.props.params.driverId;
                }
            }
            if (customer.notFound) {
                asms = "Driver ID " + this.props.params.driverId + " was not found in the system.";
            }

        }

        return (
            <div>
                {asms}
            </div> 
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        customer: appState.customer
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DriverAssessments);