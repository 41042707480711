
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import LauncherForm from './components/launcherform';

const style = {
  margin: 20,
  position: "relative"
};

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

class Upload extends Component {


    render () {

        const p = this.props;
        const macLoaded = this.props.launcher.macProgress + "%";
        const winLoaded = this.props.launcher.winProgress + "%";
        let content =  null;

        if (p.launcher.macUploading || p.launcher.winUploading) {
            content = (
            <div>
                <Paper
                    style={style}
                    zDepth={1}
                    key={999}
                >
                    <div style={{
                            position: "absolute",
                            top: "0px", 
                            right: "0px",
                            padding: "5px",
                            fontSize: "85%",
                            border: "1px solid #CCC",
                            zIndex: "999999999"
                        }}>
                        Total Bytes: {this.props.launcher.macTotalBytes} - Bytes Transferred: {this.props.launcher.macBytesTransferred}
                    </div>
                    <div className="progressbar" style={{ width: macLoaded }}></div>
                    <div style={innerStyle} >
                        { this.props.launcher.macUploading ? "Progress: " + this.props.launcher.macProgress.toFixed(2) + "%" : "Not uploading for mac"}
                    </div>
                </Paper>
                <Paper
                    style={style}
                    zDepth={1}
                    key={9999}
                >
                    <div style={{
                            position: "absolute",
                            top: "0px", 
                            right: "0px",
                            padding: "5px",
                            fontSize: "85%",
                            border: "1px solid #CCC",
                            zIndex: "999999999"
                        }}>
                        Total Bytes: {this.props.launcher.winTotalBytes} - Bytes Transferred: {this.props.launcher.winBytesTransferred}
                    </div>
                    <div className="progressbar" style={{ width: winLoaded }}></div>
                    <div style={innerStyle} >
                        { this.props.launcher.winUploading ? "Progress: " + this.props.launcher.winProgress.toFixed(2) + "%" : "Not uploading for windows"}
                    </div>
                </Paper>
            </div>
            )
        } else {
            content = (
                <Paper
                    style={style}
                    zDepth={1}>
                    <LauncherForm className="build-form" onSubmit={(function (e) {e.preventDefault(); this.props.uploadLaunchers(); }).bind(this) } />
                </Paper>
            )
        }

        return (
            <div>
                {content}
            </div>
        )
    }
}


const mapStateToProps = function (appState) {
    return {
        launcher: appState.launcher
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadLaunchers: function () { dispatch( actions.uploadLaunchers() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Upload);

