/*
This is our top-level component. Sub-components matching specific routes will be
contained in `this.props.children` and rendered out.
*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import Editable from './editable';



class SuperBar extends Component {
    
    render () {
        const p = this.props;
        let isBackend = p.params && p.params.feedbackToken;
        if (p.auth.gid == 'super' && !isBackend) {
            return (
                <div className='superbar'>
                    <div className='superbar-stuff'>
                        <div>
                            <span>Show Email Open: </span>
                            <Editable type="toggle" value={p.auth.showEmailOpen || false} fullPath={'users/' + p.auth.uid + '/showEmailOpen'}  />
                        </div>
                        <div>
                            <span>Use Fake Count: </span>
                            <Editable type="toggle" value={p.auth.fakeCount || false} fullPath={'users/' + p.auth.uid + '/fakeCount'}  />
                        </div>
                        <div>
                            <span>Assessments: </span>
                            <Editable type="number" value={p.auth.fakeAssessmentCount || 0} fullPath={'users/' + p.auth.uid + '/fakeAssessmentCount'}  />
                        </div>
                        <div>
                            <span>Credits: </span>
                            <Editable type="number" value={p.auth.credits || 0} fullPath={'users/' + p.auth.uid + '/credits'}  />
                        </div>
                        <div>
                            <span>Fake driver: </span>
                            <Editable type="toggle" value={p.auth.fakeDriver || false} fullPath={'users/' + p.auth.uid + '/fakeDriver'}  />
                        </div>
                        
                    
                    </div>
                </div>
            )
        }

        return null;

    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    }
}

export default connect(mapStateToProps)(SuperBar);
