/*
This is our top-level component. Sub-components matching specific routes will be
contained in `this.props.children` and rendered out.
*/
import _ from 'lodash';
import Snackbar from 'material-ui/Snackbar';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { C } from '../constants';
import store from '../store';
import ComplianceAssessment from './components/complianceassessment';
import CPTDash from './components/cptdash';
import FeedbackReportV2 from './feedbackReportV2';
import RAImg from "../assets/images/Ready-Assess-Logo.png";
import HowTo from "../assets/images/howtodxdra.png";
import Dialog from 'material-ui/Dialog';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import Paper from 'material-ui/Paper';
import RefreshIndicator from 'material-ui/RefreshIndicator';


/*
Have this wrapper listen for auth -- show a spinner if awaiting auth info
need to know username and role to proceed

*/

const styles = {

  largeIcon: {
    width: "160px",
    height: "160px",
  },
  large: {
    paddingTop: "10px",
    textAlign: "center",
  },
};

const payStyle = {
    paper : {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 350,
        padding: 20,
        marginTop: 50,
        position: 'relative'
    },
    refresh: {
        display: 'block',
        position: 'relative',
  },
}

const paperStyle = {
    margin: "30px 50px"
}

class CourtCompliance extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            verificationReminderOpen: false,
            open: false,
            htopen: false
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({
            verificationReminderOpen : !nextProps.auth.emailVerified && !nextProps.auth.resentVerification
        })
    }

    componentWillMount () {
        store.dispatch( actions.listenToLauncher() );
        document.body.style.background = "#FFF";
        const p = this.props;
        console.log("AUTH ON LOAD", p.auth);
        store.dispatch( actions.listenToCustomerAssessments(p.auth.uid, p.auth.oid) );
        // store.dispatch( actions.startListeningToDriver( this.props.uid ) );
    }

    componentDidMount () {
        C.GA.logEvent('ra_view_dash');
    }

    trackViewSec () {
        C.GA.logEvent('view_security_prefs_pdf');
    }

    trackDownloadMac () {
        C.GA.logEvent('download_launcher');
        C.GA.logEvent('download_launcher_mac');
    }

    trackDownloadWin () {
        C.GA.logEvent('download_launcher');
        C.GA.logEvent('download_launcher_win');
    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    handleRequestClose () {

        this.setState({
            verificationReminderOpen: false,
        });
    };

    handleReSendClick () {
        this.props.reSendVerificationEmail();
    }

    handleOpen () {
        this.setState({open: true});
    }

    handleClose () {
        this.setState({open: false});
    }

    handleHowToOpen = () => {
        this.setState({htopen: true});
        setTimeout(function() {window.dispatchEvent(new Event('resize'))}, 60);
        
      };
    
    handleHowToClose = () => {
        this.setState({htopen: false});
    };

    handleCopyShare (e) {
        e.preventDefault();
        let shareUrl = this.props.auth && this.props.auth.shareUrl || 'https://dxdriving.com/get/ready/-MuWDnVBzUALokJH88Hi';
        navigator.clipboard.writeText(shareUrl);
        this.handleClose();
        alert("URL for this page copied to the clipboard");
    }

    handleShare (e) {
        C.GA.logEvent('ra_share_dash');
        let shareUrl = this.props.auth && this.props.auth.shareUrl || 'https://dxdriving.com/get/ready/-MuWDnVBzUALokJH88Hi';
        if (navigator.share) {
            navigator.share({
                text: shareUrl,
                url: shareUrl,
                title: "Ready Assess™ Self-guided Virtual Driving Test",
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            console.log("Web Share not supported :(");
            this.handleOpen();     
        }
    }

    handleBuy (e) {
        e.preventDefault();
        this.props.startCheckout({
            productName: 'Ready Assess Credit'
        }, true);
    }

    render () {
        
        const auth = this.props.auth;
        const isDxdDevWeb = __DXD_DEV_WEB__ || process.env.NODE_ENV === 'development';

        const p = this.props;
        console.log("ANY PARAMS?", p.passedParams);
        const aid = p.passedParams && p.passedParams.aid;
        
        lightBaseTheme.palette.primary1Color = "#3B125D";
        lightBaseTheme.palette.accent1Color = "#DE8931";

        const lightMuiTheme = getMuiTheme(lightBaseTheme);

        const plt = window.navigator.platform;
        const canDownload = plt.startsWith("MacIntel") || plt.startsWith("Win");
        const msg = canDownload ? (plt.startsWith("Win") ? "Download for Windows" : "Download for Mac") : "The Virtual Driving Test software is not available for " + plt + ". Please return to this page on a Windows or Mac computer.";
        const path = plt.startsWith("Win") ? this.props.launcher.win.path : this.props.launcher.mac.path;
        const trackDL = plt.startsWith("Win") ? this.trackDownloadWin.bind(this) : this.trackDownloadMac.bind(this)
        let shareUrl = this.props.auth && this.props.auth.shareUrl || 'https://dxdriving.com/get/ready/-MuWDnVBzUALokJH88Hi';

        let noDrivesYet = "Once you've completed your first virtual driving test, a record of it will be listed here.";
        let loadingDrives = "Loading assessment results";
        let assessmentRows = [];

        if (p.pay.loading) {
            return (
                <Paper style={payStyle.paper}>
                    <RefreshIndicator
                        size={100}
                        left={103}
                        top={0}
                        loadingColor="#FF9800"
                        status="loading"
                        style={payStyle.refresh} />
                </Paper>
            )
        }


        if (p.overview.hasReceivedCustomerAssessmentData) {
            assessmentRows = _.map(p.overview.customerAssessments, (asm, key) => {
                return <ComplianceAssessment data={asm} aid={key} key={key} />;
            })
        }

        let hasTakenTest = assessmentRows.length;
        if (p.auth.fakeCount) {
            hasTakenTest = p.auth.fakeAssessmentCount;
        }
        let noCredits = p.auth.credits == 0;

        let brandNew = !hasTakenTest && noCredits;
        let onlyPaid = !hasTakenTest && !noCredits;
        let couldPayAgain = hasTakenTest && noCredits;
        let readyForRetake = hasTakenTest && !noCredits;
        const cents = p.auth.price;
        const dollars = (cents / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});

        let nextStep = <span>The Ready-Assess™ virtual driving test costs {dollars} You’ll need to <button onClick={this.handleBuy.bind(this)} className="inline-buy">buy a credit</button> before taking the test.</span>
        let mainHdr = `Welcome to your Ready-Assess™ account!`;
        let subHdr = `You can access this account at any time by logging in with your email address and the password you have entered.`;
        if (onlyPaid) {
            nextStep = <span>You’re ready to take the Ready-Assess™ virtual driving test. Come back here when you’re done to access your results.</span>
            mainHdr = `Thanks for your payment!`;
            subHdr = null;
        }

        if (couldPayAgain) {
            nextStep = <span>You have zero credits. If you’d like to take the Ready-Assess™ assessment again, you’ll need to <button onClick={this.handleBuy.bind(this)} className="inline-buy">purchase a credit</button> for {dollars}</span>
            mainHdr = `Welcome back!`;
            subHdr = null;
        }

        if (readyForRetake) {
            nextStep = <span>You’ve paid for {p.auth.credits} credit{p.auth.credits > 1 ? 's' : ''} so you are ready to take the Ready-Assess™ virtual driving test again.</span>
            mainHdr = `Welcome back!`;
            subHdr = null;
        }

        if (!p.auth.payPerAssessment) {
            nextStep = <span>You’re ready to take the Ready-Assess™ virtual driving test. Come back here when you’re done to access your results.</span>
            mainHdr = `Welcome to your Ready-Assess™ account!`;
            subHdr = null;
        }

        return (
            <MuiThemeProvider muiTheme={lightMuiTheme}>
                <div className="ra-all">
                    <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css" />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CLexend+Deca:regular%7CLexend+Deca:regular,600" media="all"/>

                    

                    <div className="ra-top">
                        <div className="ra-top-inner">
                            <span className="ra-logo-span"><img className="ra-logo" src={RAImg} alt="Ready Assess Logo"  width="311" /></span>
                            {
                                p.auth.payPerAssessment ?
                                <div className='credit-box crbxtop'>
                                    <div className='credit-top'>
                                        CREDITS: {p.auth.credits}
                                    </div>
                                    <div className='credit-btm'>
                                        <button onClick={this.handleBuy.bind(this)} className="buy-button buy-top"><span className="buy-text">PURCHASE CREDIT</span></button>
                                    </div>
                                </div> :
                                null
                            }
                            
                            <a href="#" onClick={this.props.logout} className="nav-link ra-nav-link" title="Logout"><i style={{fontSize: "29px"}} className="icon ion-log-out"></i></a>
                            <div className="clrbth"></div>
                        </div>
                    </div>


                    {
                        aid ?
                        <div className='ra-section compliance-section ra-dash-section'>
                            <Paper className='compliance_fb_step'>
                                <FeedbackReportV2 aid={aid} isStep={true} />
                            </Paper>
                        </div>
                         
                        :
                        
                        <div>
                            <div className="ra-section compliance-section ra-dash-section bg-primary-3">
                                <div className="ra-section-inner">
                                    <div className="credit-hero">
                                        {
                                            p.auth.payPerAssessment ?
                                            <div className='credit-box-mid crbx-hero'>
                                                <div className='credit-top'>
                                                    CREDITS: {p.auth.credits}
                                                </div>
                                                <div className='credit-btm'>
                                                    <button onClick={this.handleBuy.bind(this)} className="buy-button buy-top"><span className="buy-text">PURCHASE CREDIT</span></button>
                                                </div>
                                            </div> :
                                            null
                                        }
                                    </div>
                                    <div className="clr-lft-pd">
                                        <h1 className="ra-dash-header">
                                            {mainHdr}
                                        </h1>
                                    </div>
                                    <div className="ra-chk-cnt clr-lft-pd">
                                        <h5 className="ra-chk-title">
                                            {subHdr}
                                        </h5>
                                        <div className='cmp-nxt-stp'>
                                                {nextStep}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            


                            <div className="ra-section ra-dash-section">
                                <div style={{display:"none"}} className="ra-section-inner">
                                    <button onClick={this.handleHowToOpen.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">How to access and review your Personalized Feedback Report</span></button>
                                </div>
                                <div className='ra-section-inner'>
                                    <Card className="howToCard">
                                        <CardHeader 
                                            title={<div className="share-button sb-sm"><span className="share-text st-sm">How to access and review your Personalized Feedback Report</span></div>}
                                            actAsExpander={true}
                                            showExpandableButton={true}/>
                                        <CardText expandable={true}>
                                            <ul>
                                                <li>From the main page of your user portal, click on "View Feedback Report" for the test you completed.</li>
                                                <li>Progress through by reading each page and then clicking "next" to move to the next page.  If you exit the report and come back at a later time, we will save your progress.</li>
                                                <li><b>You must watch all assigned videos, all the way to the end, before you can click "next" and proceed further through your report.</b></li>
                                                <li>At the final page, click "Finish" to complete your review</li>
                                                <li>When you are done, you will see a check mark indicating completion on the main page of your user portal.  You will also receive an email confirmation that you have completed reviewing your assigned content.  Save this email for your records.</li>
                                                <li><b>The Court will be notified automatically when you have finished reviewing your entire report.</b></li>
                                            </ul>
                                        </CardText>
                                    </Card>
                                </div>
                                
                                <div className="ra-section-inner">
                                    <h2 style={{paddingTop: 30}} className="ra-dash-sub-header">
                                        Your results:
                                    </h2>
                                    {

                                        p.overview.hasReceivedCustomerAssessmentData ?

                                            (assessmentRows.length ? assessmentRows.reverse() : noDrivesYet)
                                        :

                                        loadingDrives

                                    }
                                </div>
                            </div>

                            <CPTDash />
                            
                        </div>
                    }

                    



                    <div style={{height:"100px"}}></div>
                    <div className="ra-announcement-bar ra-dash-btm-bar">
                        You can email us at any point at <a href="mailto:support@diagnosticdriving.com">support@diagnosticdriving.com</a> if you have any questions or need assistance.
                    </div>

                    <Dialog
                        actions={[<button onClick={this.handleClose.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">Close</span></button>]}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this.handleClose.bind(this)}>

                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + shareUrl}  target="_blank" className="share-link"><i className={"icon share-icon ion-social-facebook"}></i></a>
                        <a href={"http://twitter.com/share?text=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills." + "&url=" + shareUrl + "&hashtags=" + ['safedriving', 'drivesafe']} className="share-link" target="_blank"><i className={"icon share-icon ion-social-twitter"}></i></a>
                        <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareUrl} target="_blank" className="share-link"><i className={"icon share-icon ion-social-linkedin"}></i></a>
                        <a href={"http://pinterest.com/pin/create/button/?url=" + shareUrl + "&media=" + "https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c45fc379b131a4baff925_Ready-Assess-Logo.png" + "&description=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-pinterest"}></i></a>
                        <a href={"https://web.whatsapp.com/send?text=" + shareUrl} className="share-link" target="_blank"><i className={"icon share-icon ion-social-whatsapp"}></i></a>
                        <a href={"https://reddit.com/submit?url=" + shareUrl + "&title=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-reddit"}></i></a>
                        <a onClick={this.handleCopyShare.bind(this)} className="share-link"><i className={"icon share-icon ion-ios-copy"}></i></a>
                        <a href={"mailto:?subject=Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills.&body=Go here to register your free account and get started! site: " + shareUrl } target="_blank" className="share-link"><i className={"icon share-icon ion-ios-email"}></i></a>

                    </Dialog>

                    

                    <Snackbar
                        open={this.state.verificationReminderOpen}
                        message="Your email address is unverified"
                        action="Re-send Verification e-mail"
                        autoHideDuration={300000}
                        onActionTouchTap={this.handleReSendClick.bind(this)}
                        onRequestClose={this.handleRequestClose.bind(this)} />
                </div>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        launcher: appState.launcher,
        overview: appState.overview,
        pay : appState.pay

    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        logout: function() { dispatch(actions.logoutUser()); },
        reSendVerificationEmail: function() { dispatch( actions.reSendVerificationEmail() ); },
        startCheckout: function (options, isForCredit) {
            dispatch( actions.startCheckout(options, isForCredit) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourtCompliance);
