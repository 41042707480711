
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import TestFileForm from './components/testfileform';

const style = {
  margin: 20,
  position: "relative",
  padding: 50
};

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

class UploadTest extends Component {


    render () {

        const p = this.props;
        const testLoaded = this.props.launcher.testProgress + "%";
        let content =  null;

        if (p.launcher.testUploading) {
            content = (
            <div>
                <Paper
                    style={style}
                    zDepth={1}
                    key={999}
                >
                    <div style={{
                            position: "absolute",
                            top: "0px", 
                            right: "0px",
                            padding: "5px",
                            fontSize: "85%",
                            border: "1px solid #CCC",
                            zIndex: "999999999"
                        }}>
                        Total Bytes: {this.props.launcher.testTotalBytes} - Bytes Transferred: {this.props.launcher.testBytesTransferred}
                    </div>
                    <div className="progressbar" style={{ width: testLoaded }}></div>
                    <div style={innerStyle} >
                        { this.props.launcher.testUploading ? "Progress: " + this.props.launcher.testProgress.toFixed(2) + "%" : "Not uploading"}
                    </div>
                </Paper>
            </div>
            )
        } else {
            content = (
                <Paper
                    style={style}
                    zDepth={1}>
                    <TestFileForm className="build-form" onSubmit={(function (e) {e.preventDefault(); this.props.uploadTestFile(); }).bind(this) } />
                    {
                        this.props.launcher.lastFile ? <div className='display-6' style={{fontSize: '300%', padding: '30px 30px 0px 30px', textAlign: 'center'}}>
                            Successfully uploaded <span style={{color: 'green'}}>{this.props.launcher.lastFile}</span>
                        </div> : null
                    }
                    {
                        this.props.launcher.testError ? <div className='display-6' style={{fontSize: '300%', padding: '30px 30px 0px 30px', textAlign: 'center'}}>
                            An error occured: <span style={{color: 'red'}}>{this.props.launcher.testError}</span>
                        </div> : null
                    }
                    
                </Paper>
            )
        }

        return (
            <div>
                {content}
            </div>
        )
    }
}


const mapStateToProps = function (appState) {
    return {
        launcher: appState.launcher
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadTestFile: function () { dispatch( actions.uploadTestFile() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UploadTest);

