import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';



const FIELD_PREFIX = 'map_';

const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Try dropping launcher file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class MapsForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("Rendering maps form");
        let fields = [];
        for (var i = 0; i < 4; i++) {
            let name = FIELD_PREFIX + i;
            let displayName = "Map " + i;
            fields.push(<div className="col-sm-3" key={i + "_mf"}>
                            <label htmlFor={name}>{displayName}</label>
                            <Field
                                name={name}
                                component={renderDropzoneInput} />
                        </div>)
        };
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    {fields}
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload Map Files"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
MapsForm = reduxForm({
  form: 'maps'
})(MapsForm);


export default MapsForm;