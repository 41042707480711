import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Slider } from 'redux-form-material-ui';



class NumberForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        return (
            <form
                onSubmit={function (e) { e.preventDefault(); e.stopPropagation(); }}
                className={this.props.className}>
                <div style={{padding: "44px 11px 0"}}>
                    <div>
                        {this.props.label}
                    </div>
                    <div>
                        <Field
                            name={this.props.name}
                            component={Slider}
                            defaultValue={this.props.defaultValue}
                            format={null}
                            min={this.props.min}
                            max={this.props.max}
                            step={this.props.step} />
                    </div>
                </div>
            </form>
        )
    }
}

/*
function mapStateToProps(state, ownProps) {
stateValues = state;
const result = {
initialValues: {
grade_part: ownProps.profile.gradePart,
subject: ownProps.profile.subject,
}
};
return result;
}
*/

// Decorate the form component
NumberForm = reduxForm({
  form: 'number' // a unique name for this form
})(NumberForm);

NumberForm = connect(
  state => ({
    initialValues: state.overview // pull initial values from auth oid
  })
)(NumberForm);



export default NumberForm;