import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scenario from './components/accell/Scenario';
import { withRouter } from 'react-router';

class ViewScenario extends Component {

    constructor(props, context) {

        super(props, context);
        this.state = {

        };
    }

    componentWillMount () {

    }

    // const { router } = this.context
    //     if ((auth.isOwner && auth.scoringMethod) && !auth.fakeDriver) {
    //         router.replace('/dash');
    //     }

    done () {

        this.props.router.replace('/scenario/upload');

    }

    render () {
        const p = this.props;
        const scn = p.params.scenario;



        return (

            <div className='accell-wrapper'>
                <Scenario scenario={scn} onDone={this.done.bind(this)} />
            </div> 
                
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
        
    };
}

const mapDispatchToProps = function(dispatch) {
    return {}
}

const RoutableVS = withRouter(ViewScenario);

export default connect(mapStateToProps, mapDispatchToProps)(RoutableVS);