import _ from 'lodash';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow } from 'material-ui/Table';
import React from 'react';
// import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import SafetyLapse from './safetylapse';

class SafetyLapses extends React.Component {
    render() {
        const props = this.props;

                
        if (!props.drive.hasOwnProperty('results')) {
            if (props.autoCalculate) {
                let lapses = {};

                for (let eventFrame in props.drive.events) {
                    if (!props.drive.events.hasOwnProperty(eventFrame)) {
                        continue;
                    }

                    let event = props.drive.events[eventFrame];

                    if (lapses.hasOwnProperty(event.name)) {
                        lapses[event.name] += 1;
                    } else {
                        lapses[event.name] = 1;
                    }
                }

                props.drive.results = {
                    safetyLapses: []
                };

                for (let lapseName in lapses) {
                    if (!lapses.hasOwnProperty(lapseName)) {
                        continue;
                    }

                    props.drive.results.safetyLapses.push({
                        type: lapseName,
                        count: lapses[lapseName]
                    });
                }
            } else {
                return (<div>Still evaluating...</div>);
            }
        }

        let myLapses = props.drive.results.safetyLapses ? props.drive.results.safetyLapses : [];
        let lapses = props.content;

        lapses = lapses.map((lapse) => {
            let myLapse = myLapses.find((myLapse) => {
                return myLapse.type == lapse.slug
            });

            if (myLapse) {
                lapse.count = myLapse.count;
                if (myLapse.video) {
                    lapse.video = myLapse.video;
                }
            } else {
                lapse.count = 0;
            }

            return lapse;
        });

        // const chart = (<Bar data={chartData} height={80} options={chartOptions} />);

        return (
            <div>
                <h3>Safety Lapses</h3>
                <Table>
                    <TableHeader
                        adjustForCheckbox={false}
                        displaySelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn>Type</TableHeaderColumn>
                            <TableHeaderColumn style={{width: '60px'}}>Video</TableHeaderColumn>
                            <TableHeaderColumn style={{width: '60px'}}>Info</TableHeaderColumn>
                            <TableHeaderColumn style={{width: '100px'}}>Count</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {lapses.map((lapse, i) => {
                            return (
                                <SafetyLapse key={i} lapse={lapse}/>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default connect((state) => ({
    content: state.content.results.safetyLapses
}))(SafetyLapses);