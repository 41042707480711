import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import AddAltSurvey from './addAltSurvey';

const LABEL_MAP = {
    intake : 'Intake',
    postDrive : 'Post-Drive',
    surveySelect : 'Survey Select'
}

class AltSurveys extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        const oid = p.oid;
        let rows = _.map(p.org.altSurveys, (lngs, type) => {
                return (<div key={type}>
                        <div className="display-6">{LABEL_MAP[type]}</div>
                        {_.map(lngs, (surveyId, lngCode) => {
                            return <div>{lngCode} : {surveyId}</div>
                        })}
                </div>)
            })
        const list = (
            <div className="alt-survey-list">
                { rows }
            </div>
        )
        const nodata = (
            <div>No alternative language surveys exist</div>
        )
        const add = <AddAltSurvey onSubmit={(function (e) {e.preventDefault(); this.props.submitNewAlternativeSurvey(oid); }).bind(this) } />
        const wait = <CircularProgress />
        return (

            <div className='org-settings-box'>
                <div className='display-6 btm-space'>Configure Alternative Language Surveys</div>
                { p.altSurveys.submittingnew ? wait : add}
                { rows.length ? list : nodata }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        altSurveys: appState.altSurveys
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewAlternativeSurvey: function (oid) { dispatch( actions.submitNewAlternativeSurvey(oid) )}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AltSurveys);