import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm, FormSection } from 'redux-form';
import {
  Checkbox,
  DatePicker,
  TimePicker
} from 'redux-form-material-ui';


const validate = function (values) {
    const errors = {};
    // if (!values.locale) {
    //     errors.locale = "Locale value is required"
    // }
    return errors;
}

const COL_MAP = {
    id : "Assessment ID",
    customerId : "Driver ID",
    userEmail : "User Email",
    userFirstName: "User First Name",
    userLastName: "User Last Name",
    organizationId: "Organization ID",
    organizationName: "Organization Name",
    feedbackEmail: "Feedback Email",
    startDate: "Date",
    startTime: "Time",
    status: "Status",
    trackStart: "Track Start",
    secondsInPractice: "Practice Duration (seconds)",
    practiceInProgress: "Practice In Progress",
    brakingPracticeIncomplete: "Completed Braking Practice",
    setGearToPark: "Passed Set Gear To Park",
    lookLeft: "Passed Look Left",
    lookRight: "Passed Look Right",
    signalRight: "Passed Signal Left",
    turnOffSignal: "Passed Turn Off Signal",
    map: "Map",
    variant: "Variant",
    flags: "Flags",
    events: "Has Events",
    replay: "Replay",
    replayUrl: "Replay URL",
    end: "End Time",
    completed: "Completed",
    assessmentDuration: "Assessment Duration (seconds)",
    totalDuration: "Practice & Assessment Duration (seconds)",
    completeDuration: "Total Duration (seconds)",
    location: "Location",
    riskLevel: "Risk Level",
    riskScore: "Risk Score",
    riskMessage: 'Risk',
    vehicleCollisions: 'Vehicle Collisions',
    pedestrianCollisions: 'Pedestrian Collisions'
}


class ModifyQueryForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    

    render () {

        const columnCheckboxes = _.map(COL_MAP, (v, k) => {
            return <div className="qry-chk" key={k}> <Field name={k} component={Checkbox} label={v} /> </div>
        });

        const orgCheckBoxes = _.map(this.props.orgs.data, (v, k) => {
            return <div className="org-chk" key={k}> <Field name={k} component={Checkbox} label={v.abrv} /> </div>
        });

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="qry-form">
                    <FormSection name="columns">
                        <div className="qry-cols">
                            {columnCheckboxes}
                        </div>
                    </FormSection>
                    
                    <FormSection name="organizations">
                        <div className="qry-orgs">
                            {orgCheckBoxes}
                        </div>
                    </FormSection>
                    <div style={{padding: 25}}>
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || this.props.dash.submittingnew}
                            label="Run Query"
                            type="submit" />
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
ModifyQueryForm = reduxForm({
  form: 'query', // a unique name for this form
  validate
})(ModifyQueryForm);

export default ModifyQueryForm;