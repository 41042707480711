import { C } from '../../constants';

let now = new Date();
let lastweek = new Date();
lastweek.setDate(lastweek.getDate() - 7);

const initialState = { //define initial state - an empty form
  waitingForRollupData: true,
  start: lastweek,
  end: now
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_ROLLUP_DATA:
        return Object.assign({}, state, {
          data: action.data,
          chartData: action.chartData,
          waitingForRollupData: false
        });

    case C.AWAIT_NEW_ROLLUP_RESPONSE:
        return Object.assign({},state,{
            waitingForRollupData: true
        });

    case C.SET_TIME_RANGE:
        return Object.assign({}, state, {
            start: action.start,
            end: action.end
        })

      default:
        return state;
  }
}