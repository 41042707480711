import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const validate = values => {
    const errors = {}
    const requiredFields = ['title', 'description']
    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required'
        }
    })

    return errors
}

const DOC_FIELD_NAME = 'docfile';

const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Drag/drop file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class DocumentForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("Rendering document form");
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-6">

                        <div>
                            <Field 
                                name="title"
                                fullWidth={true}
                                component={TextField}
                                hintText="Enter a title"
                                floatingLabelText="Document Title" />
                        </div>
                        <div>
                            <Field 
                                name="description"
                                fullWidth={true}
                                multiLine={true}
                                rows={3}
                                component={TextField}
                                hintText="Enter a description of this file for customers"
                                floatingLabelText="Document Description" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor={DOC_FIELD_NAME}>File</label>
                        <Field
                            name={DOC_FIELD_NAME}
                            component={renderDropzoneInput} />
                    </div>
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload File"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
DocumentForm = reduxForm({
  form: 'document', // a unique name for this form
  validate
})(DocumentForm);


export default DocumentForm;