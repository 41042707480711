import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { C } from '../constants';

const assetsRef = C.APP.database().ref('assets');

const storage = firebase.storage().ref();

export const startListeningToAssets = function () {
    return function (dispatch, getState) {
        assetsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_ASSET_DATA, data: snap.val()});
        });
    }
}

export const startListeningToAsset = function (assetId) {
    return function (dispatch, getState) {
        assetsRef.child(assetId).on('value', (snap) => {
            let assetData = snap.val();
            let mediaRef = storage.child(assetData.path);
            mediaRef.getDownloadURL().then(function(url) {
                dispatch({ type: C.RECEIVE_SINGLE_ASSET, assetId, assetData, url})
            });
        })

    }
}

export const deleteAsset = function (tag, platform, url, deleteMe, fromDeleteTag) {
    return function (dispatch, getState) {
        dispatch({type: C.START_ASSET_ACTION, action: 'delete asset'});

        //NOt yet implemented
        // storage.child('cpt_assets').child(filename).delete().then(() => {
        //     console.log("Asset in storage was deleted", filename)
        // }).catch((error) => {
        //     console.log("**********an error occured when trying to delete!", filename, " error: ", error)
        // });

        setTimeout(() => {
           dispatch({ type: C.END_ASSET_ACTION, action: 'delete asset'});
        }, 500);
    }
    
} 

export const uploadAsset = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.asset.values;
        console.log("FORM VALUES: ----> ", formValues);
        let name = formValues.name.trim();
        let type = formValues.type;
        let description = formValues.description;

        // const nowdate = new Date();
        // let nowMs = nowdate.valueOf();

        // const filename = [tag, platform, String(version)].join("-") + ".zip";

        
        const assetFile = formValues.assetFile[0];
        console.log(assetFile);
        const ext = assetFile.name.substr(assetFile.name.lastIndexOf('.') + 1).trim();

        const assetPath = 'cpt_assets/' + assetFile.name;
        let assetRecord  = {
            type,
            name,
            description,
            path: assetPath,
            filename: assetFile.name,
            extension: ext,
            uploaded: C.TS
        }
        dispatch({type: C.AWAIT_NEW_ASSET_RESPONSE});



        // const ref = assetsRef.child(tag + "/" + platform);
        const assetStorageRef = storage.child(assetPath);
        console.log("assetStorageRef: ", assetStorageRef);

        var uploadTask = assetStorageRef.put(assetFile);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', (snapshot) => {

          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            dispatch({type: C.SET_ASSET_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
            }, (error) => {
                // Handle unsuccessful uploads
                dispatch({type: C.RECEIVE_ASSET_UPLOAD_ERROR, error: "An error occurred in the upload process.", 
                    transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
            }, () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                console.log(uploadTask.snapshot);
                uploadTask.snapshot.ref.getDownloadURL().then(dlUrl => {
                    console.log("||||||||||||||| asset uploaded successfully |||||||||||||||");
                    console.log(dlUrl);
                    assetRecord.downloadUrl = dlUrl;
                    assetsRef.push(assetRecord).then(() => {
                        dispatch({type: C.RECEIVE_NEW_ASSET_RESPONSE});
                        console.log("set build ref in firebase db");
                    }).catch((error) => {
                        dispatch({type: C.RECEIVE_ASSET_UPLOAD_ERROR, error: "An error occurred in saving asset to firebase."});
                        console.log(error);
                    });
                    
                });
                
        });

    }
}