import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow } from 'material-ui/Table';
import React from 'react';
import { connect } from 'react-redux';
import Indication from './indication';

export default connect((state) => ({
    content: state.content.results.indications
}))((props) => {
    let indications = [];
    if (props.drive.hasOwnProperty('results')) {
        indications = props.drive.results.indications ? props.drive.results.indications : [];
    }

    return (
        <div>
            <h3>Indications</h3>
            <Table>
                <TableHeader
                    adjustForCheckbox={false}
                    displaySelectAll={false}>
                    <TableRow>
                        <TableHeaderColumn>Type</TableHeaderColumn>
                        <TableHeaderColumn style={{width: '120px'}}>Indicated</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {props.content.map((indication, i) => (
                        <Indication key={i} name={indication.name} indicated={indications.includes(indication.slug)} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
});
