import { C } from '../../constants';

const initialState = {
    empty : true
}

export default (state = initialState, action) => {
    let content = {};
    switch (action.type) {
        case C.RECEIVE_CONTENT_DATA:
            content = action.data || {};
            content.empty = action.data ? false : true;
            return Object.assign({}, state, content);
        default:
            return state;
  }
}