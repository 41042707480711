// src/components/Form.jsx
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import * as hlp from '../../utils/helpers';

const noop = () => undefined;

/*
This "redux-reactive" form stuff was built based on this guide:
http://x-team.com/2016/02/tutorial-forms-in-react-and-redux/
AS such, the validation needs some work
We could scrap it completely and have less of our form state in the 
redux store. -- Just use some other handy form/validation libs?
*/

class SmartForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.displayName = 'Form';
        this.validations = [];
    }

    getChildContext() {
        return {
            update: this.props.update,
            reset: this.props.reset,
            submit: this.submit.bind(this),
            values: this.props.formState.values,
            registerValidation: this.registerValidation.bind(this),
            isFormValid: this.isFormValid.bind(this)
        };
    }

    registerValidation(isValidFunc) {
        this.validations = [...this.validations, isValidFunc];
        return this.removeValidation.bind(this, isValidFunc);
    }

    removeValidation(ref) {
        this.validations = hlp.dupeWithout(this.validations, ref);
    }

    isFormValid(showErrors) {
        return this.validations.reduce((memo, isValidFunc) => 
            isValidFunc(showErrors) && memo, true);
    }

    submit(){
        if (this.isFormValid(true)) {
            this.props.onSubmit(Object.assign({}, this.props.values));
            this.props.reset();
        }
    }



    render () {
        return (
                // This onSubmit is hacking the enter key so it submits -- but it has a weird side effect
                // when login fails -- the last field gets validated on reset
                // can probably use some context state to have TextInput clear this in this case
            <form onSubmit={(function (e) {e.preventDefault(); this.submit(); }).bind(this) } className={this.props.className}>
            {this.props.children}
            </form>
        );
    }
}

SmartForm.propTypes = {
    children: PropTypes.node,
    values: PropTypes.object,
    update: PropTypes.func,
    reset: PropTypes.func,
    className: PropTypes.string,
    onSubmit: PropTypes.func
}

SmartForm.childContextTypes = {
    update: PropTypes.func,
    reset: PropTypes.func,
    submit: PropTypes.func,
    values: PropTypes.object,
    registerValidation: PropTypes.func,
    isFormValid: PropTypes.func,
}

SmartForm.defaultProps = {
    onSubmit: noop
}


const mapStateToProps = function (appState) {
  return {
    formState: appState.dForm
  }
}

export default connect(mapStateToProps, actions)(SmartForm);

