import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { SelectField, Slider, TextField, Toggle } from 'redux-form-material-ui';
import actions from '../../actions';

const safeName = /^[A-Za-z_][A-Za-z0-9_\-]*$/;

const safeNameWithPreDash = /^[A-Za-z_\-][A-Za-z0-9_\-]*$/;

const validate = values => {
    const errors = {}
    if (!values[ "type" ]) {
        errors[ "type" ] = 'Required'
    } else {
        if (["multvert", "multhorz", "allthatapply"].includes(values[ "type" ])) {
            if (!values[ "numberofchoices" ]) {
                errors[ "numberofchoices" ] = 'Required'
            } else {
                let num = values[ "numberofchoices" ];
                if (num < 2) {
                    errors[ "numberofchoices" ] = 'Must have at least 2 choices'
                } else {
                    for (let i of Array(num).keys()) {
                        let n = i + 1;
                        if (!values[ "choicevalue-" + n ]) {
                            errors[ "choicevalue-" + n ] = 'Required'
                        } else {
                            if (!safeNameWithPreDash.test(values["choicevalue-" + n])) {
                                errors[ "choicevalue-" + n ] = 'No spaces. Must start with letter or underscore. Can only contain letters, numbers, underscores & dashes';
                            }
                        }
                        if (!values[ "choicedisplay-" + n ]) {
                            errors[ "choicedisplay-" + n ] = 'Required'
                        }
                    }
                }
            }
        }
    }

    if (!values[ "text" ]) {
        errors[ "text" ] = 'Required'
    }


    //TODO: ensure question names are unique?
    if (!values[ "shortname" ]) {
        errors[ "shortname" ] = 'Required'
    } else {
        if (!safeName.test(values["shortname"])) {
            errors[ "shortname" ] = 'No spaces. Must start with letter or underscore. Can only contain letters, numbers, underscores & dashes';
        }
        if (values["shortname"] == "please_rename") {
            errors[ "shortname" ] = 'Please enter a new unique name for this question';
        }
    }



    return errors
}


class AddQuestionForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    componentDidUpdate () {
        // const p = this.props;
        // let initChoices = {};
        // if (p.numberOfChoices && p.numberOfChoices > 1) {
        //     for (let i of Array(p.numberOfChoices).keys()) {
        //         let choiceNumber = "" + (i + 1);
        //         initChoices["choicevalue-" + choiceNumber] = "" + choiceNumber;
        //         initChoices["choicedisplay-" + choiceNumber] = "" + choiceNumber;
        //     }
        // }
        // this.props.initialize(initChoices);
    }

    componentDidMount () {
        // this.props.bridge(function () {
        //     this.initialize();
        // })
    }

    componentWillMount() {
        this.props.startListeningToVideos();
    }

    // componentDidMount () {
    //     this.handleInitialize();
    // }

    // handleInitialize() {


    //     this.props.initialize(this.props.initialValues);
    //   }

// text, multiplechoicevert, multiplechoicehorz, allThatApply, oneThroughSix, allThatApplyOther, oneThroughTen, oneThroughFive, zeroThrough4

    render () {
        const p = this.props;
        console.log("Rendering add question form", p);
        let choices = [];

        if (p.numberOfChoices && p.numberOfChoices > 1) {
            for (let i of Array(p.numberOfChoices).keys()) {
                console.log("adding choice", i);
                let choiceNumber = "" + (i + 1);
                choices.push(
                    <div className="q-choice">
                        <h6 className="choice-header"><b>Choice {choiceNumber}</b></h6>
                        <div className="intake-choice">
                            <Field
                            className="first-choice-field choice-field"  
                            name={"choicevalue-" + choiceNumber }
                            component={TextField}
                            hintText={"Data value for choice " + choiceNumber}
                            floatingLabelText={"Data value for choice " + choiceNumber}
                            defaultValue={"" + choiceNumber}/>

                            <Field 
                            className="choice-field"
                            name={"choicedisplay-" + choiceNumber }
                            component={TextField}
                            hintText={"Displayed value for choice " + choiceNumber}
                            floatingLabelText={"Displayed value for choice " + choiceNumber}
                            defaultValue={"" + choiceNumber}/>
                        </div>
                    </div>
                )
            }
        }
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <Field 
                            name="shortname"
                            component={TextField}
                            hintText="Identify this question"
                            fullWidth={true}
                            floatingLabelText="Name for this question (not shown to user)" />
                    </div>
                    <div className="col-sm-6">
                        <Field 
                            name="type"
                            component={SelectField}
                            hintText="Type of question"
                            floatingLabelText="Type of question">
                            <MenuItem value="text" primaryText="Basic Text"/>
                            <MenuItem value="email" primaryText="Validated Email Address"/>
                            <MenuItem value="number" primaryText="Basic Number Input"/>
                            <MenuItem value="date" primaryText="Date, Month, Year Input"/>
                            <MenuItem value="multvert" primaryText="Vertical Multiple Choice"/>
                            <MenuItem value="multhorz" primaryText="Horizontal Multiple Choice"/>
                            <MenuItem value="allthatapply" primaryText="Select All That Apply"/>
                            <MenuItem value="instruction" primaryText="Instruction"/>
                            <MenuItem value="video" primaryText="Video"/>
                        </Field>
                    </div>
                </div>
                <div>
                    <Field 
                        name="text"
                        component={TextField}
                        fullWidth={true}
                        multiLine={true}
                        rows={3}
                        hintText="Type your question text here."
                        floatingLabelText="Question Text"/>
                </div>
                {(p.questionType == "video") && <div>
                    <Field
                        name="video"
                        fullWidth={true}
                        component={SelectField}
                        floatingLabelText="Select a Video">
                        {
                            _.map(this.props.videos.data, (item, key) => {
                                return <MenuItem value={key} key={key} primaryText={item.title} />
                            })
                        }
                    </Field>
                </div>}
                {(p.questionType == "multhorz") && <div>
                    <div>
                        <Field
                            name="lefttext"
                            component={TextField}
                            hintText="Text on the left of choices"
                            floatingLabelText="Left Text" />
                    </div>
                    <div>
                        <Field
                            name="righttext"
                            component={TextField}
                            hintText="Text on the right of choices"
                            floatingLabelText="Right Text" />
                    </div>
                </div>}
                {(["multvert", "multhorz", "allthatapply"].includes(p.questionType)) && <div>
                    <div className="slider-box">
                    <div className="ccc-box">{p.numberOfChoices}</div>
                        <Field
                            name="numberofchoices"
                            component={Slider}
                            format={function (value, name) {
                                console.log("formatting slider value", value, name);
                                if (value === '') {
                                    return 0
                                }
                                return parseInt(value);
                            }}
                            description="How many choices should this question have?"
                            defaultValue={0}
                            min={0}
                            max={20}
                            step={1}/>
                    </div>
                    <div className="choice-box">
                        {choices}
                    </div>
                </div>}
                {(p.questionType == "allthatapply") && <div>
                    <Field
                        name="includeother"
                        component={Toggle}
                        label="Allow additional custom option"
                        labelPosition="right"/>
                </div>}
                <div>
                    <Field
                        name="required"
                        component={Toggle}
                        label="Required"
                        labelPosition="right"/>
                </div>
                
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine || !this.props.valid}
                        label="Add Question"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
AddQuestionForm = reduxForm({
  form: 'addQuestion', // a unique name for this form
  validate,
  enableReinitialize: true, 
  // keepDirtyOnReinitialize: true
})(AddQuestionForm);

const mapDispatchToProps = function(dispatch) { //***not all of these actions have been implemented***
    return {
        startListeningToVideos: function () {
            dispatch( actions.startListeningToVideos() )
        }
    }
}

// Decorate with connect to read form values
const selector = formValueSelector('addQuestion') // <-- same as form name
AddQuestionForm = connect(
  state => {
    // can select values individually
    const questionType = selector(state, 'type')
    const numberOfChoices = selector(state, 'numberofchoices')
    return {
      questionType,
      numberOfChoices,
      initialValues: state.questions.init,
      videos: state.video
    }
  }
, mapDispatchToProps )(AddQuestionForm)


export default AddQuestionForm;