import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {C} from '../constants';
import actions from '../actions';
import store from '../store';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import ModifyQueryForm from './components/modifyqueryform';
import SetQueryTimespanForm from './components/setquerytimespanform';
import QueryExportForm from './components/queryexportform';
import Dialog from 'material-ui/Dialog';
import Drawer from 'material-ui/Drawer';
import RaisedButton from 'material-ui/RaisedButton';
import queryString from 'query-string';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

function isLow (params) {

    return 
            (params.data.riskLevel && params.data.riskLevel == 0) || 
            (params.data.riskMessage && params.data.riskMessage.indexOf('Little') >= 0) ||
            (params.data.noviceLevel && params.data.noviceLevel == 0) ||
            (params.data.experiencedLevel && params.data.experiencedLevel == 0);
}

function isMid (params) {
    return 
            (params.data.riskLevel && params.data.riskLevel == 1) || 
            (params.data.riskMessage && params.data.riskMessage.indexOf('Some') >= 0) ||
            (params.data.noviceLevel && params.data.noviceLevel == 1) ||
            (params.data.experiencedLevel && params.data.experiencedLevel == 1);
}

function isHigh (params) {
    return 
            (params.data.riskLevel && params.data.riskLevel == 2) || 
            (params.data.riskMessage && params.data.riskMessage.indexOf('Strong') >= 0) ||
            (params.data.noviceLevel && params.data.noviceLevel == 2) ||
            (params.data.experiencedLevel && params.data.experiencedLevel == 2);
}

const PRETTY_NAMES = {
    id : "Assessment ID",
    customerId : "Driver ID",
    userEmail : "User Email",
    userFirstName: "User First Name",
    userLastName: "User Last Name",
    organizationId: "Organization ID",
    organizationName: "Organization Name",
    feedbackEmail: "Feedback Email",
    department: "Department",
    startDate: "Date",
    startTime: "Time",
    status: "Status",
    trackStart: "Track Start",
    secondsInPractice: "Practice Duration (seconds)",
    practiceInProgress: "Practice In Progress",
    brakingPracticeIncomplete: "Completed Braking Practice",
    setGearToPark: "Passed Set Gear To Park",
    lookLeft: "Passed Look Left",
    lookRight: "Passed Look Right",
    signalRight: "Passed Signal Left",
    turnOffSignal: "Passed Turn Off Signal",
    map: "Map",
    variant: "Variant",
    flags: "Flags",
    events: "Has Events",
    replay: "Replay",
    replayUrl: "Replay URL",
    end: "End Time",
    completed: "Completed",
    assessmentDuration: "Assessment Duration (seconds)",
    totalDuration: "Practice & Assessment Duration (seconds)",
    completeDuration: "Total Duration",
    location: "Location",
    riskLevel: "Risk Level",
    riskScore: "Risk Score",
    riskMessage: 'Risk',
    vehicleCollisions: 'Vehicle Collisions',
    pedestrianCollisions: 'Pedestrian Collisions',
    noviceGrade: "Grade",
    noviceLevel: "Level",
    novicePercentile: "Percentile",
    experiencedGrade: "Grade",
    experiencedLevel: "Level",
    experiencedPercentile: "Percentile"
}


const ROW_CLASS_RULES = {
    'opp_little_row' : isLow,
    'opp_some_row' : isMid,
    'opp_strong_row' : isHigh
}

const SUGGESTION_MAP = {
    speed: "speeding",
    steeringCornering: "steering/cornering",
    hardBraking: "hard braking",
    cellPhoneUse: "mobile phone use"
}

class Dash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            queryDrawerOpen : false,
            timespanDialogOpen : false
        }
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        // console.log('DASH COMPONENT DID UPDATE ##########################################', prevProps, this.props)
        // let aid = this.props.params.aid;
        // let dimensionName = this.props.params.dimensionName;
        // let dimensionValue = this.props.params.dimensionValue;
        // const qs = queryString.parse(this.props.location.search);
        // let qryLimit = qs.limit || 1000;
        // if (this.props.auth.scoringMethod) {
        //     console.log('HAS SCORING METHOD');
        //     if (!prevProps.auth.scoringMethod) {
        //         console.log('DID NOT HAVE SCORING METHOD');
        //         store.dispatch( actions.loadInitialDashQuery(aid, dimensionName, dimensionValue, qs.showIds, qryLimit) );
        //     }

        // }
    }

    componentWillReceiveProps (nextProps) {
        if (!nextProps.auth.scoringMethod) {
            this.waitForScoringMethod();
        }
        
    }

     waitForScoringMethod () {
        const router = this.props.router;

        let aid = this.props.params.aid;
        let dn = this.props.params.dimensionName;
        let dv = this.props.params.dimensionValue;
        if (aid || (dn && dv)) {
            if (aid) {
                router.replace('/dash/d/' + aid);
            }
            if (dn && dv) {
                router.replace('/dash/a/' + dn + '/' + dv);
            }
        } else {
            router.replace('/dash');
        }

    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    componentWillMount () {
        if (!this.props.auth.scoringMethod) {
            this.waitForScoringMethod();
        } else {
            let aid = this.props.params.aid;
            let dimensionName = this.props.params.dimensionName;
            let dimensionValue = this.props.params.dimensionValue;
            const qs = queryString.parse(this.props.location.search);
            if (qs.customerIds) {
                let ids = qs.customerIds.split(',');
                store.dispatch({ type: C.SET_CUSTOMER_IDS, ids});
            }
            if (qs.additionalColumns && this.props.auth.gid == 'super') { 
                let addCols = qs.additionalColumns.split(',');
                if (addCols.length) {
                    store.dispatch({ type: C.SET_QRY_CUSTOM_COLS, addCols});
                }
            }

            if (qs.showAllOrgs == 'true' && this.props.auth.gid == 'super') {
                store.dispatch({ type: C.SET_QRY_SHOW_ALL_ORGS, showAllOrgs: true});
            }
            let ttl = 'Intelligence Dashboard';

            if (this.props.params && this.props.params.aid) {
                ttl = 'Assessment-Level Report';
                store.dispatch( actions.startListeningToAssessment(this.props.params.aid) );
            }
            store.dispatch({ type: C.SET_QRY_AID, aid});
            store.dispatch({ type: C.SET_QRY_DIMENSIONS, dimensionName, dimensionValue});
            let qryLimit = qs.limit || 1000;

            if (this.props.params && this.props.params.aid) {
                store.dispatch( actions.startListeningToAssessment(this.props.params.aid) );
            }

            store.dispatch( actions.loadInitialDashQuery(aid, dimensionName, dimensionValue, qs.showIds, qryLimit) );

            store.dispatch( actions.startListeningToOrgs() );
        }
    }

    handleQueryDrawerToggle() {
        this.setState({queryDrawerOpen: !this.state.queryDrawerOpen});
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    clearFilters() {
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
    }


    handleTimespanDialogOpen() {
        this.setState({timespanDialogOpen : true})
    }

    handleTimespanDialogClose() {
        this.setState({timespanDialogOpen : false})
    }

    handleCellClick(cellData) {
        let aid = this.props.params.aid;
        let dimensionName = this.props.params.dimensionName;
        let dimensionValue = this.props.params.dimensionValue;
        if (!aid && !dimensionName && !dimensionValue) {
            let thisAid = cellData.data.id;
            console.log(cellData);
            if (['location', 'department'].includes(cellData.colDef.field)) {
                // this won't work. . . need a better way
                if (cellData.value) {
                    let dimension = cellData.colDef.field == 'department' ? 'departmentId' : cellData.colDef.field;
                    let value = cellData.colDef.field == 'department' ? cellData.data.departmentId : cellData.value;
                    this.props.router.push('/dash/a/' + dimension + '/' + value);
                    return;
                }
                
            }
            // if (cellData.colDef.field == "customerId") {
            //     prodConsole.log('')
            // }
            if (thisAid) {
                this.props.router.push('/dash/d/' + thisAid);
            }
        }
    }

    render () {
        
        const p = this.props;
        

        const aid = this.props.params && this.props.params.aid;

        const dimensionName = this.props.params.dimensionName;
        const dimensionValue = this.props.params.dimensionValue;

        let assessmentData = null;
        let suggestions = null;

        let isLoading = (p.dash.submittingnew || !(p.dash.hasReceivedData && p.orgs.hasReceivedOrgData) );


        if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
            assessmentData = p.assessments.data[aid].value;
        }

        if (p.dash.data.totalCount && dimensionName && dimensionValue) {
            console.log('total count exists, render title content', p.dash.data.totalCount);
            titleContent = <div>Average scores for assessments where {PRETTY_NAMES[dimensionName] || dimensionName} is {dimensionValue}. There were {p.dash.data.totalCount} total assessments.</div>
        }

        let content = <div></div>

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        if (p.dash.hasReceivedData && p.orgs.hasReceivedOrgData) {
            content = (
                <div 
                    className="ag-theme-balham"
                    style={{ 
                        height: '700px', 
                        width: '100%' }} 
                >
                    <AgGridReact
                        modules={AllCommunityModules}
                        reactNext={true}
                        columnDefs={p.dash.data.columnDefs}
                        pagination={true}
                        onGridReady={this.onGridReady.bind(this)}
                        rowData={p.dash.data.rowData}
                        onCellClicked = {(function(cellData) {this.handleCellClick(cellData)}).bind(this)}
                        rowClassRules={ROW_CLASS_RULES}
                        enableBrowserTooltips={true}
                        >

                        
                    </AgGridReact>
                    <div className="dash-footer">
                        
                    </div>
                </div>
            );
            if (p.dash.data.suggestions) {
                let suggsItems = [];
                 _.map(p.dash.data.suggestions, (isSuggested, suggKey) => {
                    if (isSuggested) {
                        suggsItems.push(SUGGESTION_MAP[suggKey]);
                    }
                })
                if (suggsItems.length) {
                    prodConsole.log(suggsItems);
                    suggestions = (
                        <div>Based on the following results, it is recommended that you focus on {/*suggsItems.length == 1 ? suggsItems[0] : */[suggsItems.slice(0, -1).join(', '), suggsItems.slice(-1)[0]].join(suggsItems.length < 2 ? '' : ' and ')} in your telematics.</div>
                    )
                }
            }

            if (p.dash.data.aggregateSuggestions && p.dash.data.totalCount) {
                let suggsItems = [];
                _.map(p.dash.data.aggregateSuggestions, (count, suggKey) => {
                    if (count >= p.dash.data.totalCount / 2) {
                        suggsItems.push(SUGGESTION_MAP[suggKey]);
                    }
                })
                if (suggsItems.length) {
                    prodConsole.log(suggsItems);
                    suggestions = (
                        <div>Based on the following results, it is recommended that you focus on {/*suggsItems.length == 1 ? suggsItems[0] : */[suggsItems.slice(0, -1).join(', '), suggsItems.slice(-1)[0]].join(suggsItems.length < 2 ? '' : ' and ')} in your telematics.</div>
                    )
                }
            }
            
        }

        let topBar = null;

        if (!aid) {
            topBar = (
                <div>
                    <div className="query-controls top-qry">

                        <div className="qry-glbl-cntrls">
                            <button style={{marginRight: 10}} onClick={this.handleTimespanDialogOpen.bind(this)}>
                                Set Timespan
                            </button>
                            <Dialog
                                title="Set Query Timespan"
                                modal={false}
                                open={this.state.timespanDialogOpen}
                                onRequestClose={this.handleTimespanDialogClose.bind(this)}>
                                    <SetQueryTimespanForm
                                        dash={p.dash}
                                        onSubmit={(function (e) {e.preventDefault(); p.setQueryTimespan(); this.handleTimespanDialogClose(); }).bind(this) } />
                             </Dialog>
                             <button onClick={this.clearFilters.bind(this)}>Clear All Filters</button>
                            
                        </div>
                        {

                            false ? (

                                     <div className="qry-btn-box col-sm-6">

                                        <button onClick={this.handleQueryDrawerToggle.bind(this)}>
                                            Modify Query
                                        </button>
                                        
                                        <Drawer
                                            docked={false}
                                            width={480}
                                            openSecondary={true}
                                            open={this.state.queryDrawerOpen}
                                            onRequestChange={(queryDrawerOpen) => this.setState({queryDrawerOpen})} >
                                                <div style={{ padding : "50px 15px"}}>
                                                    <ModifyQueryForm
                                                        dash={p.dash}
                                                        orgs={p.orgs}
                                                        onSubmit={(function (e) {e.preventDefault(); p.modifyQuery(); }).bind(this) } />
                                                </div>
                                        </Drawer>

                                    </div>

                                     ) : null

                        }
                        

                        
                    </div>
                </div>
            )
        }
        

        return (
                <div className='dash-box' key={"" + aid + dimensionName + dimensionValue}>
                    {isLoading ? <div className="dash-curtain"></div> : null}
                   

                            <div className="dash-header">

                                {
                                    aid || (dimensionName && dimensionValue) ? (
                                        <div style={{paddingBottom: 12}} className="dash-suggest display-6">
                                            {suggestions}
                                        </div>
                                    ) : null
                                }
                            </div>


                    
                    
                    { topBar }
                    <div>
                        { isLoading ? (
                               <div className="loading">Loading&#8230;</div>
                            ) : null }
                        {content}
                    </div>
                </div>
                
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        dash: appState.dash,
        orgs: appState.orgs,
        assessments: appState.assessments
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        modifyQuery : function () { dispatch( actions.modifyQuery() ) },
        exportQueryToCSV : function(gridApi) { dispatch( actions.exportQueryToCSV(gridApi) ) },
        setQueryTimespan : function () { dispatch( actions.setQueryTimespan() ) },
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) }
    }
}

const RoutableDash = withRouter(Dash);


export default connect(mapStateToProps, mapDispatchToProps)(RoutableDash);
