import { C } from '../../constants';

const initialState = {
    empty : true
}

export default (state = initialState, action) => {
    let entry = {};
    switch (action.type) {
        case C.RECEIVE_CUST_DATA:
            entry[action.key] = entry[action.key] || {};
            entry[action.key].loading = false;
            entry[action.key].data = action.data;
            entry[action.key].assessments = entry[action.key].assessments || {};
            return Object.assign({}, state, entry);

        case C.AWAIT_NEW_CUST_DATA:
            entry[action.key] = {};
            entry[action.key].loading = true;
            entry.empty = false;
            return Object.assign({}, state, entry);

        case C.RECEIVE_CUST_ASS_DATA:
            entry[action.key] = Object.assign({}, state[action.key])
            entry[action.key].assessments = entry[action.key].assessments || {};
            entry[action.key].assessments[action.aid] = action.ad;
            return Object.assign({}, state, entry);

        case C.CUSTOMER_NOT_FOUND:
            entry[action.key] = Object.assign({}, state[action.key])
            entry[action.key].notFound = true;
            return Object.assign({}, state, entry);

        default:
            return state;
  }
}