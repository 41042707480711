import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';


class ScoreButton extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {
        const p = this.props;
        const aid = p.aid;
        const ref = p.replayRef;
        const scSt = p.overview.scoringState[aid] || {};

        console.log('Score button', aid, ref);

        return (
            <div>
                { scSt.calculating ? <CircularProgress /> : <RaisedButton
                                            primary
                                            onClick={(function (e) { p.generateScore(aid, ref); }).bind(this) }
                                            label="Generate Score" /> }
                { scSt.duration ? <div className="scoring-duration" style={{fontSize : "80%", paddingTop: "10px"}}> Last score generation took {scSt.duration} milliseconds </div> : null }
            </div>
            
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        overview: appState.overview
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        generateScore : function (aid, ref) {
            dispatch( actions.generateScore(aid, ref))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScoreButton);