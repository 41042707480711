import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import JSONTree from 'react-json-tree';
import ProgressBar from './components/progressbar';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#000000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

class UtilButton extends Component {
    render () {
        return (
            <RaisedButton
                secondary
                style={{ width: "95%", margin: "5px 40px" }}
                onTouchTap={this.props.onTouchTap}
                label={this.props.label} />
        )
    }
}

class Utils extends Component {

    render () {

        const functions = {
            "Assign fullname to each user" : this.props.getFullname,
            "First and Last names to All Users in DB": this.props.parseFullname,
            "Add lastAssessment attribute to all users": this.props.addLastAssessment,
            "Add lastAssessmentId attribute to all users": this.props.addLastAssessmentIDAndRisk,
            "Populate lastTime attribute to all users who have values in lastAssessment or lastAssesment": this.props.addLastTime,
            "Add dateAdded attribute to all users": this.props.addDateAdded,
            "Add risk = -2 for users with no assessments": this.props.addRisk,
            "Populate lastAssessment attribute with the latest assessment end time": this.props.addCorrectLastAssessmentInfo,
            "Add name fields if none exist": this.props.addNameFields,
            "Capitalize first and last names of each user": this.props.namesToUpperCase,
            "Erase a field -- specify in users.js action code": this.props.eraseFields,
            "Add a new field from a list of key:values (specify in users actions code)": this.props.addFieldFromList,
            "Add the assessments taken by each user to the user assessments list": this.props.matchAssessmentToUser,
            "Add a group field to each user and populate it with appropriate group (based on FB group/gid/members list)": this.props.putUsersInTheirGroups,
            "Add organization to each user based on FB organizations/oid/members list": this.props.putUsersInTheirOrgs,
            "If a user has no organization, delete that user and print the name": this.props.deleteDisorganizedUsers,
            "If an attribute is a string, parse to int -- modify code for which attribute": this.props.stringToInt,
            "If an assessment has a bad replay url, fix it": this.props.fixReplayUrls,
            "Re-connect replays that didn't get added in the DB if file exists": this.props.repairReplayReferences,
            "Add Orgs to Assessments": this.props.addOrganizationToAssessments,
            "Cleanup Users DB": this.props.cleanUpUsersDb,
            "Update Coaching Item Choices": this.props.updateCoachingItemChoices,
            "Set Default Cities on all Organizations": this.props.setDefaultCitiesOnOrganizations,
            "Count Assessments": this.props.countAssessments,
            "Make Assessments Sortable & Filterable": this.props.createCombinedPropertiesForSortingAndFiltering,
            "Add Super Drivers": this.props.addSuperDrivers,
            "Remove addedToActive Flags": this.props.removeAddedToActiveFlags,
            "Reset & Reload BQ": this.props.resetAndReloadBigQuery,
            "Remove Ghosts": this.props.clearGhostUsers,
            "Expose Firebase": this.props.exposeFirebase,
            "Find Users That Have Data Flag": this.props.findWhoHasDataAccess,
            "Score All": this.props.scoreAllAssessments,
            "Monitor Progress": this.props.monitorBigTaskProgress,
            "Test Submit Intake": this.props.testSubmitIntake,
            "Trim the fat": this.props.trimTheFat,
            // "Archive Assessments": this.props.archiveAssessments,
            "Reset Score Data Table": this.props.resetScoreTable,
            "Load Stragglers": this.props.triggerStragglerLoad,
            "Send Test PDF": (function () { this.props.triggerBackendFunctionWithInput('sendPdf') }).bind(this)
        }

        if (__ODPS__) {
            functions["Fix Locations (ODPS)"] = this.props.fixOdpsLocations;
        }

        const p = this.props;
        let index = 0;
        let countDisplay = null;
        if (p.counts.data) {
            countDisplay = <JSONTree 
                                data={p.counts.data}
                                theme={theme}
                                invertTheme={true}
                                hideRoot={true}/>
        }
        return (
            <div className='row'>
                <div className='col-sm-8'>
                    {_.map(functions, (action, label) => {
                        return <UtilButton key={index++} label={label} onTouchTap={action} />
                    })}
                </div>
                <div className='col-sm-4'>
                    <Paper className="spacy-paper">
                        <ProgressBar title="BQ LOAD PROGRESS" totalCount={p.progress.total} record={p.progress.bqLoad} />
                        <ProgressBar title="SCORE ALL PROGRESS" totalCount={p.progress.total} record={p.progress.scoreAll} />
                        <ProgressBar title="REMOVE FLAGS PROGRESS" totalCount={p.progress.total} record={p.progress.flags} />
                        <ProgressBar title="TRIM THE FAT PROGRESS" totalCount={p.progress.total} record={p.progress.fat} />
                        <ProgressBar title="ARCHIVING PROGRESS" totalCount={p.progress.total} record={p.progress.archiving} />
                        {countDisplay}
                    </Paper>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        users: appState.users, //do we have to start listening to users?
        counts: appState.counts,
        progress: appState.progress
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        parseFullname: function () { dispatch( actions.parseFullname() )},
        addLastAssessment: function () { dispatch( actions.addLastAssessment() )},
        addLastAssessmentIDAndRisk: function () { dispatch (actions.addLastAssessmentIDAndRisk() )},
        addLastTime: function () { dispatch( actions.addLastTime() )},
        addNameFields: function () { dispatch( actions.addNameFields() )},
        eraseFields: function () { dispatch( actions.eraseFields() )},
        addFieldFromList: function () { dispatch( actions.addFieldFromList() )},
        matchAssessmentToUser: function () { dispatch (actions.matchAssessmentToUser() )},
        putUsersInTheirGroups: function () { dispatch (actions.putUsersInTheirGroups() )},
        putUsersInTheirOrgs: function () { dispatch (actions.putUsersInTheirOrgs() )},
        getFullname: function () { dispatch (actions.getFullname() )},
        deleteDisorganizedUsers: function () { dispatch (actions.deleteDisorganizedUsers() )},
        stringToInt: function () { dispatch (actions.stringToInt() )},
        addDateAdded: function () { dispatch (actions.addDateAdded() )},
        addCorrectLastAssessmentInfo: function () {dispatch (actions.addCorrectLastAssessmentInfo() )},
        addRisk: function () { dispatch (actions.addRisk() )},
        namesToUpperCase: function () { dispatch ( actions.namesToUpperCase() )},
        fixReplayUrls: function () { dispatch ( actions.fixReplayUrls() ) },
        fixOdpsLocations: function () { dispatch ( actions.fixOdpsLocations() ) },
        repairReplayReferences: function () { dispatch ( actions.repairReplayReferences() )},
        addOrganizationToAssessments: function () { dispatch ( actions.addOrganizationToAssessments() ) },
        cleanUpUsersDb: function () { dispatch ( actions.cleanUpUsersDb() ) },
        updateCoachingItemChoices: function () { dispatch ( actions.updateCoachingItemChoices() ) },
        setDefaultCitiesOnOrganizations: function () { dispatch ( actions.setDefaultCitiesOnOrganizations() )},
        countAssessments: function () { dispatch ( actions.checkAssessmentCounts() )},
        createCombinedPropertiesForSortingAndFiltering: function () { dispatch( actions.createCombinedPropertiesForSortingAndFiltering() ) },
        addSuperDrivers: function () { dispatch( actions.addSuperDrivers() )},
        removeAddedToActiveFlags: function () { dispatch( actions.removeAddedToActiveFlags() ) },
        resetAndReloadBigQuery: function () { dispatch( actions.resetAndReloadBigQuery() ) },
        clearGhostUsers: function () { dispatch( actions.clearGhostUsers() ) },
        exposeFirebase:  function () { dispatch( actions.exposeFirebase() )},
        findWhoHasDataAccess: function () { dispatch( actions.findWhoHasDataAccess() )},
        scoreAllAssessments: function () { dispatch( actions.scoreAllAssessments() ) },
        monitorBigTaskProgress: function () { dispatch( actions.monitorBigTaskProcess() ) },
        testSubmitIntake: function () { dispatch( actions.testSubmitIntake() )},
        trimTheFat: function () { dispatch( actions.trimTheFat() ) },
        archiveAssessments: function () { dispatch( actions.archiveAssessments() ) },
        resetScoreTable: function () { dispatch( actions.resetScoreTable() ) },
        triggerStragglerLoad: function () {dispatch( actions.triggerStragglerLoad() )},
        triggerBackendFunctionWithInput: function(fn) {dispatch( actions.triggerBackendFunctionWithInput(fn) )}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Utils);