import { C } from '../../constants';

const locsRef = C.APP.database().ref('locations');

export const startListeningToLocs = function () {
    return function (dispatch, getState) {
        locsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_LOCS_DATA, data: snap.val()});
        });
    }
}

export const submitNewLoc = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.addLoc.values;
        const district = formValues.district;
        const address = formValues.address;
        const city = formValues.city;
        const stt = formValues.state;
        const zip = formValues.zip;
        dispatch({type: C.AWAIT_NEW_LOC_RESPONSE});
        //locs are organized by district -- and have a prop of district
        locsRef.child(String(district)).push({district, address, city, state : stt, zip}).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_LOC_RESPONSE});
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_LOC_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add location!"}) //TODO: better errors
        } )
    }
}