import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { C } from '../constants';

const vRef = C.DB.ref('videos');
const storageRef = firebase.storage().ref();

export const startListeningToVideos = function () {
    return function (dispatch, getState) {
        vRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_VIDEOS_DATA, data: snap.val()});
        });
    }
}

export const uploadVideo = function (uid, gid) {
    return function (dispatch, getState) {
        // upload the video
        // modify upload state as it loads
        // add an entry to the "videos" db node
        // title - ref (add tags?)
        const fv = getState().dForm.values;
        const videoFile = fv.videofile.files[0];
        const ext = videoFile.name.substr(videoFile.name.lastIndexOf('.')+1);
        const vidKey = vRef.push().key;
        const videoStorageRef = storageRef.child('videos/' + vidKey + "." + ext);
        dispatch({type: C.AWAIT_NEW_VIDEO_RESPONSE});
        videoStorageRef.put(videoFile).then((snap) => {
            //uploaded a video -- handle success
            let updates = {};
            updates[vidKey] = {title: fv.title,
                putRef: videoStorageRef.fullPath,
                originalFileName: videoFile.name
            }
            videoStorageRef.getDownloadURL().then((url) => {
                updates[vidKey].url = url;
                vRef.update(updates).then((snap) => {
                    dispatch({type: C.RECEIVE_NEW_VIDEO_RESPONSE});
                });
                
            });
        });

        
    }
}
