import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { SelectField, Slider, TextField } from 'redux-form-material-ui';

const safeName = /^[A-Za-z_][A-Za-z0-9_\-]*$/;

const validate = values => {
    const errors = {}

    if (!values["name"]) {
        errors ["name"] = "Required"
    } else {
        if (!safeName.test(values["name"])) {
            errors[ "name" ] = 'No spaces. Must start with letter or underscore. Can only contain letters, numbers, underscores & dashes';
        }
    }


    if (!values["description"]) {
        errors ["description"] = "Required"
    }

    if (!values["numberOfPages"]) {
        console.log("no number of pages value", values);
        errors ["numberOfPages"] = "One or more coaching pages required"
    } else {
        if (values["numberOfPages"] < 1) {
            console.log("number of pages value is less than one", values);
            errors ["numberOfPages"] = "One or more coaching pages required"
        } else {
            for (var i = values["numberOfPages"]; i >= 0; i--) {
                if (!values["page_" + i]) {
                    errors["page_" + i] = "Required"
                }

            };
        }
    }

    return errors
}

class AddCoachingSurveyForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        // store.dispatch( actions.startListeningToOrgs() );
    }

    render () {
        const p = this.props;

        var formToRender;
        let pages = [];


        for (let i = 0; i < p.numberOfPages; i++) {
            console.log("***********adding page", i);
            let pageNumber = "" + (i + 1);
            pages.push(
                <div className="q-choice" key={"page_" + i}>
                    <h6 className="choice-header"><b>Page {pageNumber}</b></h6>
                    <div className="intake-choice row">

                        <div className="col-sm-12">
                            <Field
                                name={"page_" + i}
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText={"Page  " + pageNumber}>
                                {
                                    _.map(p.pages.data, (page, key) => {
                                        return <MenuItem value={key} key={key} primaryText={page.name} />
                                    })
                                }
                            </Field>
                        </div>

                    </div>
                </div>
            )
        }

        formToRender = <Paper style={{ margin: "30px", padding: "25px"}}>
            <form  className={this.props.className} 
                        onSubmit={this.props.onSubmit} >
                    <div>
                        <Field 
                            name="name"
                            component={TextField}
                            fullWidth={true}
                            hintText="Name this coaching survey (not user visible)"
                            floatingLabelText="Coaching Survey Name"/>
                    </div>
                    <div>
                        <Field 
                            name="description"
                            component={TextField}
                            fullWidth={true}
                            hintText="Briefly describe this coaching survey (not user visible)"
                            floatingLabelText="Coaching Survey Description"/>
                    </div>
                    <div className="col-sm-12">
                        <Field
                            name={"type"}
                            fullWidth={true}
                            component={SelectField}
                            floatingLabelText={"Type of Survey"}>
                            {
                                _.map({coaching : "Coaching Assignment", intake : "Intake Survey"}, (display, key) => {
                                    return <MenuItem value={key} key={key} primaryText={display} />
                                })
                            }
                        </Field>
                    </div>

                    <div className="slider-box">
                        <label htmlFor="numberOfPages">{"How many pages will this Coaching Survey have?"}</label>
                        <div className="ccc-box">{p.numberOfPages}</div>
                        <Field
                            name="numberOfPages"
                            component={Slider}
                            format={function (value, name) {
                                console.log("formatting slider value", value, name);
                                if (value === '') {
                                    return 0
                                }

                                return parseInt(value);
                            }}
                            defaultValue={0}
                            min={0}
                            max={100}
                            step={1}/>
                    </div>

                    {pages}

                    <div className="row">
                        <div className="col-sm-7"></div>
                        <div className="col-sm-5">
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Add Coaching Survey"
                            type="submit"
                            fullWidth={true}
                            style={{ width: "20%", marginTop: "15px" }} />
                        </div>
                    </div>
            </form>
            </Paper>;


        return (
            <div>

                    

                {formToRender}


            </div>
            
        )
    }
}

// Decorate the form component
AddCoachingSurveyForm = reduxForm({
  form: 'coachingsurvey', // a unique name for this form
  validate
})(AddCoachingSurveyForm);

//get the input value from createCoachingSurveyForm -- formValueSelector function is from redux-form
const selector = formValueSelector('coachingsurvey');
AddCoachingSurveyForm = connect(
  state => {
    // send numSteps to redux state
    const numberOfPages = selector(state, 'numberOfPages')
    return {
      numberOfPages
    }
  }
)(AddCoachingSurveyForm)

const mapStateToProps = function (appState) {
    return {

    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        //not used atm
        resetForm: function () {
            dispatch(reset('coachingsurvey'));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCoachingSurveyForm);