import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from 'material-ui/Paper';

const style = {
    paper : {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 350,
        padding: 20,
        marginTop: 50
    }
}

class PayCancel extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        const prms = this.props.params;

        return (
            <Paper style={style.paper}>
                Payment cancelled for Driver ID: {prms.driverId}
            </Paper>
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PayCancel);