import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  driveLimit: -1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.INIT_DRIVE_LIMIT:
        return Object.assign({}, state, {
          driveLimit: action.driveLimit
        });

      default:
        return state;
  }
}