import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  progress: [0, 0, 0, 0],
  uploading: [false, false, false, false],
  errors: ["", "", "", ""],
  totalBytes: [0, 0, 0, 0],
  bytesTransferred: [0, 0, 0, 0],
  mapData: [null, null, null, null],
  mapErrors: ["", "", "", ""]
};

export default (state = initialState, action) => {
    let newUploading = [];
    let newErrors = [];
    let newProgress = [];
    let newTotalBytes = [];
    let newBytesTransferred = [];
    let newMapData = [];
  switch (action.type) {

    case C.AWAIT_NEW_MAP_RESPONSE:
        newUploading = [];
        newErrors = [];
        console.log("debug", action.type, action.index, "b4 loop");
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newUploading[i] = true;
                newErrors[i] = "";
            } else {
                newUploading[i] = state.uploading[i];
                newErrors[i] = state.errors[i]
            }
        };
        console.log("debug", action.type, action.index, "b4 loop", state, newUploading, newErrors);
        return Object.assign({},state,{
            uploading: newUploading,
            macError: newErrors
        });

    case C.RECEIVE_NEW_MAP_RESPONSE:
        newUploading = [];
        newErrors = [];
        newProgress = [];
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newUploading[i] = false;
                newErrors[i] = "";
                newProgress[i] = 0;
            } else {
                newUploading[i] = state.uploading[i];
                newErrors[i] = state.errors[i];
                newProgress[i] = state.progress[i];
            }
        };
        return Object.assign({}, state, {
            uploading: newUploading,
            errors: newErrors,
            progress: newProgress
        })

    case C.RECEIVE_MAP_UPLOAD_ERROR:
        newUploading = [];
        newErrors = [];
        newProgress = [];
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newUploading[i] = false;
                newErrors[i] = action.error;
                newProgress[i] = 0;
            } else {
                newUploading[i] = state.uploading[i];
                newErrors[i] = state.errors[i];
                newProgress[i] = state.progress[i];
            }
        };
        return Object.assign({}, state, {
            uploading: newUploading,
            errors: newErrors,
            progress: newProgress
        })

    case C.SET_MAP_UPLOAD_PROGRESS:
        newProgress = [];
        newTotalBytes = [];
        newBytesTransferred = [];
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newProgress[i] = action.progress;
                newTotalBytes[i] = action.totalBytes;
                newBytesTransferred[i] = action.bytesTransferred;
            } else {
                newProgress[i] = state.progress[i];
                newTotalBytes[i] = state.totalBytes[i];
                newBytesTransferred[i] = state.bytesTransferred[i];
            }
        };
        return Object.assign({}, state, {
            progress: newProgress,
            totalBytes: newTotalBytes,
            bytesTransferred: newBytesTransferred
        })
    case C.RECEIVE_NEW_MAP_RESPONSE:
        newUploading = [];
        newErrors = [];
        newProgress = [];
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newUploading[i] = false;
                newErrors[i] = "";
                newProgress[i] = 0;
            } else {
                newUploading[i] = state.uploading[i];
                newErrors[i] = state.errors[i];
                newProgress[i] = state.progress[i];
            }
        };
        return Object.assign({}, state, {
            uploading: newUploading,
            errors: newErrors,
            progress: newProgress
        })

    case C.RECEIVE_MAP_JSON:
        newMapData = [];
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newMapData[i] = action.response;
            } else {
                newMapData[i] = state.mapData[i]
            }
        };
        return Object.assign({}, state, {
            mapData: newMapData
        })

    case C.RECEIVE_MAP_JSON_ERROR:
        newMapErrors = [];
        for (var i = 0; i < 4; i++) {
            if (i == action.index) {
                newMapErrors[i] = action.errorMessage;
            } else {
                newMapErrors[i] = state.mapErrors[i]
            }
        };
        return Object.assign({}, state, {
            mapErrors: newMapErrors
        })


      default:
        return state;
  }
}