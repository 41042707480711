import { C } from '../../constants';

const initialState = {
    empty : true,
    data : {}
}

export default (state = initialState, action) => {
    let entry = {};
    switch (action.type) {
        case C.RECEIVE_DRIVER_IDS:
            return Object.assign({}, state, {data : action.data, empty: false});

        default:
            return state;
  }
}