
import { C } from '../constants';


export function update(name, value) {
  return dispatch => dispatch({
    type: C.FORM_UPDATE_VALUE,
    name, value
  });
}

export function reset() {
  return dispatch => dispatch({
    type: C.FORM_RESET
  });
}

export function dismissError(num) {
    return dispatch => dispatch({
    type: C.DISMISS_ERROR,
    num: num
  });
}

export function dismissAllErrors() {
    return dispatch => dispatch({
    type: C.DISMISS_ALL_ERRORS
  });
}

export function displayError(error) {
    return dispatch => dispatch({
    type: C.DISPLAY_ERROR,
    error: error
  });
}