import React, { Component } from 'react';
import * as perms from "../utils/permission";



class DriverThing extends Component {
    render () {
        return (
            <div>
                Driver Element (all groups can see)
            </div>
        )
    }
}

class ManagerThing extends Component {
    render () {
        return (
            <div>
                Manager Element (Should only be seen by managers and superusers)
            </div>
        )
    }
}

class SuperThing extends Component {
    render () {
        return (
            <div>
                Super Element (Should only be seen by superusers)
            </div>
        )
    }
}

const Driver = perms.driver(DriverThing);
const Manager = perms.manager(ManagerThing);
const Superuser = perms.spr(SuperThing);

class PermTest extends Component {


    constructor(props, context) {
        super(props, context);
        
    }

    render () {
        
        return (
            <div>
                <div>You made it here!</div>
                <Driver />
                <Manager />
                <Superuser />
            </div>
        )
    }
}


export default PermTest;



