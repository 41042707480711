import React, { Component } from 'react';
import Dimensions from 'react-dimensions';
// import { AreaChart, Legend } from 'react-easy-chart';
//import actions from '../../actions'

class DurationChart extends Component {

    constructor() {
        super();
    }


  render() {
    console.log("rendering durations chart", this.props.data);
    const p = this.props;
    const config = [
        {key: 'Time in practice', color: 'orange'},
        {key: 'Total duration', color: 'purple'}
    ];
    return (
            <div>
                <div className="chart-title">Durations</div>
                <div>
                    {
                    // <AreaChart
                    //     axes
                    //     axisLabels={{x: 'Assessments', y: 'Minutes'}}
                    //     margin={{top: 30, right: 50, bottom: 50, left: 60}}
                    //     interpolate={'linear'}
                    //     // dataPoints
                    //     areaColors={['orange', 'purple']}
                    //     grid
                    //     width={p.containerWidth}
                    //     height={280} 
                    //     data={p.data} />
                    }
                </div>
                <div style={{ textAlign: "center"}}>
                    {
                    // <Legend
                    //     horizontal
                    //     data={config}
                    //     dataId='key'
                    //     config={config} />
                    }
                </div>
                <div style={{ textAlign: "center"}}>
                    Average Total Duration: {p.avg} &nbsp;&nbsp;&nbsp;&nbsp; Average Practice Duration: {p.pAvg} 
                </div>
            </div>
        
        )
    } 
}


export default Dimensions()(DurationChart);

