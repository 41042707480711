import { C } from '../constants';
import { startListeningToDriver, updateDriveData } from './drivers';
const assessmentsBaseRef = C.APP.database().ref('assessments');

const addStatusToDriveData = function (driveData, offset) {

    if (!driveData) {
        return driveData;
    }

    function determineEndTime(now, lastUpdate) {
        if (Math.abs(now - lastUpdate) > 5000) {
            return new Date(lastUpdate).toLocaleString();
        }
        return ""
    }

    function getStatus(driveData, offset) {
        if (driveData) {
            const now = Date.now() + offset; // server time
            let end = driveData.hasOwnProperty('end') ? new Date(driveData.end).toLocaleString() : determineEndTime(now, driveData.lastUpdate);
            let completed = driveData.hasOwnProperty('completed') && driveData.completed;
            return !end ? "In Progress" : completed ? "Completed" : "Aborted";
        }
        return "Unknown";   
    }

    let status = getStatus(driveData, offset);
    driveData.status = status;
    return driveData;
}

//necessary? TODO
const addRiskToDriveData = function (driveData) {
    let scoreText = 'Evaluating...';
    let text = 'text-inverse';
    let icon = 'icon ion-clock';

    if (driveData && driveData.results) {
        if (driveData.results.danger >= 2) {
            scoreText = 'Strong Evidence';
            text = 'text-danger';
            icon = 'icon ion-minus-circled';
        } else if (driveData.results.danger >= 1) {
            scoreText = 'Some Evidence';
            text = 'text-warning';
            icon = 'icon ion-record';
        } else {
            scoreText = 'Little Evidence';
            text = 'text-aqua';
            icon = 'icon ion-android-radio-button-off';
        }
        driveData.risk = scoreText;
    }

    return driveData;
}

export const setSortState = function (sortBy) {
    return function (dispatch, getState) {
        let currentSortState = getState().driveSort;
        if (currentSortState.sortBy == sortBy) {
            dispatch({ type: C.SET_SORT_STATE, sortBy: sortBy, reverse: !currentSortState.reverse})
        } else {
            dispatch({ type: C.SET_SORT_STATE, sortBy: sortBy, reverse: false})
        }
    }
}

export const startListeningToDrive = function (aid) {
    return function (dispatch, getState) {
        if(getState().drive.empty){ 
            dispatch({ type: C.AWAIT_NEW_DRIVE_DATA, key: aid });
        }
    }
}

export const listenToAndNormalizeDriveData = function (aid) {
    return function (dispatch, getState) {
            if(getState().drive.empty){ 
                dispatch({ type: C.AWAIT_NEW_DRIVE_DATA, key: aid });
            }
            assessmentsBaseRef.child(aid).on('value', (snap) => {
            let modifiedDriveData = addStatusToDriveData(snap.val(), getState().util.offset);
            //to have auto scoring again, uncomment the code below (see scoreDriveReults function in helpers.js)
            // if (!modifiedDriveData.results) { 
            //     modifiedDriveData = (hlp.scoreDriveResults(modifiedDriveData));
            // }
            modifiedDriveData = addRiskToDriveData(modifiedDriveData);

            dispatch({ type: C.RECEIVE_DRIVE_DATA, key: aid, data: modifiedDriveData});

            if (!getState().driver.hasOwnProperty(modifiedDriveData.user)) {
                dispatch(startListeningToDriver(modifiedDriveData.user));
            }

            dispatch(updateDriveData(aid, modifiedDriveData));
        });
    }
}

export const updateDriveResults = function(aid, results) {
    return (dispatch, getState) => {
        dispatch({type: C.DRIVE_RESULTS_UPDATING, key: aid, data: results});
        assessmentsBaseRef.child(aid).update({results}).then(() => {
            dispatch({ type: C.DRIVE_RESULTS_UPDATED, key: aid, data: results });
        }).catch(() => {
        });
    };
}