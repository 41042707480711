
import React, { Component, PropTypes } from 'react';
import { C } from '../../constants';
import CoachingWizardPage from './coachingwizardpage';



let requiredFields = [];
let emailFields = [];

const validate = values => {
    console.log('validating', values, requiredFields, emailFields);
    const errors = {}
    const keys = Object.keys(values)
    for (var field of requiredFields) {
        if (!keys.includes(field)) {
            errors[field] = "Required";
        }
    }

    for (var field of emailFields) {
        const possibleEmail = values[field];
        if (possibleEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(possibleEmail)) {
            errors[field] = 'Invalid email address';
        }
    }

    return errors
}

class CoachingSurveyWizard extends Component {

    constructor(props, context) {
        super(props, context)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.state = {
            page: 0
        }
    }

    nextPage(e) {
        e.preventDefault();
        this.setState({ page: this.state.page + 1 })
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 })
    }

    render () {
        const p = this.props;
        const s = this.state;
        const survey = p.survey;
        const pages = p.pages;
        const items = p.items

        let index = 0;
        const lastIndex = survey.pages.length - 1;

        let pageDivs = [];

        let error = "";
        console.log("wtf -- ", survey, pages, items, index);
        for (var i = 0; i < survey.pages.length; i++) {
            const pageID = survey.pages[i];
            const page = p.pages[pageID];
            if (page) {
                let coachingItems = [];
                for (var j = 0; j < page.items.length; j++) {
                    let coachingItem = items[page.items[j].coachingItem];
                    if (coachingItem) {
                        coachingItems.push(coachingItem);
                        if (coachingItem.required && !(coachingItem.type == "instruction" || coachingItem.type == "allthatapply" || coachingItem.type == "video")) {
                            requiredFields.push([page.name, coachingItem.name].join(">>")); 
                        }
                        if (coachingItem.type == "email") {
                            emailFields.push([page.name, coachingItem.name].join(">>"));
                        }
                    } else {
                        error = "Cannot display coaching form. An item has been deleted. Please contact DXD support";
                    }
                };
                pageDivs.push(<div key={pageID}>
                    {s.page === index && <CoachingWizardPage 
                                            page={page}
                                            items={coachingItems}
                                            validate={validate}
                                            index={index}
                                            pageID={pageID}
                                            last={index == lastIndex}
                                            previousPage={this.previousPage}
                                            nextPage={this.nextPage}
                                            videos={p.videos}
                                            onSubmit={p.onSubmit} />}
                </div>)
                index++;
            } else {
                error = "Cannot display coaching form. A page has been deleted. Please contact DXD support";
            }
        };

        if (error) {
            return <div>{error}</div>
        }

        return (
            <div>
                <div className="intake-header">{"Page " + s.page + " of " + lastIndex}</div>
                <div>
                    {pageDivs}
                </div> 


            </div>
                
        )
    }
}

CoachingSurveyWizard.propTypes = {
    onSubmit: PropTypes.func.isRequired
}


export default CoachingSurveyWizard;
