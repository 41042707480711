import { ListItem } from 'material-ui/List';
import GroupWork from 'material-ui/svg-icons/action/group-work';
import Info from 'material-ui/svg-icons/action/info';
import React, { Component } from 'react';


class Org extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        return (
            <ListItem 
                className="org" 
                primaryText={p.org.abrv} 
                leftIcon={<GroupWork />} //change this
                nestedItems={[
                    <ListItem key={0} primaryText={p.org.name} leftIcon={<Info />} />,
                ]} />
        )
    }
}

export default Org;