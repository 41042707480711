import { C } from '../../constants';

let now = new Date();
let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

function getRangeMsg(qbd, viewLast, start, end, customer) {
  return (qbd ? ("Viewing assessments from " + start.toLocaleString() + " to " + end.toLocaleString()) : ("Viewing last " + viewLast + " assessments")) + (customer ? " for customer " + customer : "")
}

const initialState = { //define initial state - an empty form
  hasReceivedAssessmentData: false,
  hasReceivedAllAssessmentData: false,
  allReplayUrlsUpdated: false,
  viewLast: 30,
  customer: '',
  start: yesterday,
  end: now,
  msg: getRangeMsg(false, 30, yesterday, now),
  queryType: "last",
  assessmentData: {},
  replayDownloadUrls: {},
  managers: {},
  managersLoaded: {},
  all: {},
  provisional: {},
  provisionalsLoaded: false,
  scoringState: {},
  forceLoadState: {},
  sendPdfState: {},
  clearPdfState: {},
  customerAssessments: {},
  hasReceivedCustomerAssessmentData: false
};

export default (state = initialState, action) => {
  let currentScoringStateCopy = {};
  let currentForceLoadStateCopy = {};
  let currentSendPdfStateCopy = {};
  let currentClearPdfStateCopy = {};
  switch (action.type) {
    case C.RECEIVE_ASSESSMENTS_DATA:
        return Object.assign({}, state, {
          assessmentData: action.data,
          hasReceivedAssessmentData: true,
          msg: getRangeMsg(state.queryType == "daterange", state.viewLast, state.start, state.end, state.customer)
        });

    case C.RECEIVE_CUSTOMER_ASSESSMENTS_DATA:
        return Object.assign({}, state, {
          customerAssessments: action.data,
          hasReceivedCustomerAssessmentData: true
        })

    case C.START_CALCULATING_SCORE:
      currentScoringStateCopy = Object.assign({}, state.scoringState);
      currentScoringStateCopy[action.aid] = currentScoringStateCopy[action.aid] || {};
      currentScoringStateCopy[action.aid].calculating = true;
      currentScoringStateCopy[action.aid].startTime = new Date();
      return Object.assign({}, state, {
        scoringState: currentScoringStateCopy
      })

    case C.END_CALCULATING_SCORE:
      currentScoringStateCopy = Object.assign({}, state.scoringState);
      currentScoringStateCopy[action.aid] = currentScoringStateCopy[action.aid] || {};
      currentScoringStateCopy[action.aid].calculating = false;
      currentScoringStateCopy[action.aid].duration = new Date() - state.scoringState[action.aid].startTime;
      return Object.assign({}, state, {
        scoringState: currentScoringStateCopy
      })

    case C.START_FORCE_LOAD:
      currentForceLoadStateCopy = Object.assign({}, state.forceLoadState);
      currentForceLoadStateCopy[action.aid] = currentForceLoadStateCopy[action.aid] || {};
      currentForceLoadStateCopy[action.aid].loading = true;
      currentForceLoadStateCopy[action.aid].startTime = new Date();
      return Object.assign({}, state, {
        forceLoadState: currentForceLoadStateCopy
      })

    case C.END_FORCE_LOAD:
      currentForceLoadStateCopy = Object.assign({}, state.forceLoadState);
      currentForceLoadStateCopy[action.aid] = currentForceLoadStateCopy[action.aid] || {};
      currentForceLoadStateCopy[action.aid].loading = false;
      currentForceLoadStateCopy[action.aid].done = true;
      currentForceLoadStateCopy[action.aid].duration = new Date() - state.forceLoadState[action.aid].startTime;
      return Object.assign({}, state, {
        forceLoadState: currentForceLoadStateCopy
      })

    case C.ERROR_FORCE_LOAD:
      currentForceLoadStateCopy = Object.assign({}, state.forceLoadState);
      currentForceLoadStateCopy[action.aid] = currentForceLoadStateCopy[action.aid] || {};
      currentForceLoadStateCopy[action.aid].loading = false;
      currentForceLoadStateCopy[action.aid].done = false;
      currentForceLoadStateCopy[action.aid].failed = true;
      currentForceLoadStateCopy[action.aid].duration = new Date() - state.forceLoadState[action.aid].startTime;
      return Object.assign({}, state, {
        forceLoadState: currentForceLoadStateCopy
      })

    case C.START_SEND_PDF:
      currentSendPdfStateCopy = Object.assign({}, state.sendPdfState);
      currentSendPdfStateCopy[action.aid] = currentSendPdfStateCopy[action.aid] || {};
      currentSendPdfStateCopy[action.aid].loading = true;
      currentSendPdfStateCopy[action.aid].startTime = new Date();
      return Object.assign({}, state, {
        sendPdfState: currentSendPdfStateCopy
      })

    case C.END_SEND_PDF:
      currentSendPdfStateCopy = Object.assign({}, state.sendPdfState);
      currentSendPdfStateCopy[action.aid] = currentSendPdfStateCopy[action.aid] || {};
      currentSendPdfStateCopy[action.aid].loading = false;
      currentSendPdfStateCopy[action.aid].done = true;
      currentSendPdfStateCopy[action.aid].duration = new Date() - state.sendPdfState[action.aid].startTime;
      return Object.assign({}, state, {
        sendPdfState: currentSendPdfStateCopy
      })

    case C.ERROR_SEND_PDF:
      currentSendPdfStateCopy = Object.assign({}, state.sendPdfState);
      currentSendPdfStateCopy[action.aid] = currentSendPdfStateCopy[action.aid] || {};
      currentSendPdfStateCopy[action.aid].loading = false;
      currentSendPdfStateCopy[action.aid].done = false;
      currentSendPdfStateCopy[action.aid].failed = true;
      currentSendPdfStateCopy[action.aid].duration = new Date() - state.sendPdfState[action.aid].startTime;
      return Object.assign({}, state, {
        sendPdfState: currentSendPdfStateCopy
      })

    case C.START_CLEAR_PDF:
      currentClearPdfStateCopy = Object.assign({}, state.clearPdfState);
      currentClearPdfStateCopy[action.aid] = currentClearPdfStateCopy[action.aid] || {};
      currentClearPdfStateCopy[action.aid].loading = true;
      return Object.assign({}, state, {
        clearPdfState: currentClearPdfStateCopy
      })

    case C.END_CLEAR_PDF:
      currentClearPdfStateCopy = Object.assign({}, state.clearPdfState);
      currentClearPdfStateCopy[action.aid] = currentClearPdfStateCopy[action.aid] || {};
      currentClearPdfStateCopy[action.aid].loading = false;
      currentClearPdfStateCopy[action.aid].done = true;
      return Object.assign({}, state, {
        clearPdfState: currentClearPdfStateCopy
      })

    case C.ERROR_CLEAR_PDF:
      currentClearPdfStateCopy = Object.assign({}, state.clearPdfState);
      currentClearPdfStateCopy[action.aid] = currentClearPdfStateCopy[action.aid] || {};
      currentClearPdfStateCopy[action.aid].loading = false;
      currentClearPdfStateCopy[action.aid].done = false;
      currentClearPdfStateCopy[action.aid].failed = true;
      return Object.assign({}, state, {
        clearPdfState: currentClearPdfStateCopy
      })

    case C.RECEIVE_ALL_ASSESSMENTS_DATA:
      return Object.assign({}, state, {
          all: action.data,
          hasReceivedAllAssessmentData: true
        });

    case C.RECEIVE_ASSESSMENT_FOR_MANAGER:

      let currentMgrCopy = Object.assign({}, state.managers);
      currentMgrCopy[action.mid] = currentMgrCopy[action.mid] || {};
      currentMgrCopy[action.mid][action.aid] = action.data;
      return Object.assign({}, state, {
        managers: currentMgrCopy
      })

    case C.SET_MANAGER_LOADED:

      let newMgrLoaded = {};
      newMgrLoaded[action.mid] = true;

      return Object.assign({}, state, {
        managersLoaded: Object.assign({}, state.managersLoaded, newMgrLoaded)
      })

    case C.RECEIVE_PROVISIONAL_DATA:
      return Object.assign({}, state, {
          provisional: action.data,
          provisionalsLoaded: true
        });

    case C.SET_OVERVIEW_TIME_RANGE:
        return Object.assign({}, state, {
            start: action.start,
            end: action.end
        })

    case C.SET_OVERVIEW_LASTX:
        return Object.assign({}, state, {
            viewLast: action.viewLast
        })

    case C.SET_OVERVIEW_CUSTOMER:
        return Object.assign({}, state, {
            customer: action.customer
        })

    case C.SET_QUERY_TYPE:
        return Object.assign({}, state, {
            queryType: action.queryType
        })

    case C.UPDATE_REPLAY_DOWNLOAD_URL:
        const newEntry = {};
        newEntry[action.aid] = action.url;
        let dls = Object.assign({}, state.replayDownloadUrls, newEntry);

        return Object.assign({}, state, {
            replayDownloadUrls: dls
        });

    case C.ALL_REPLAY_DOWNLOAD_URLS_UPDATED:
        return Object.assign({}, state, {
          allReplayUrlsUpdated: true
        });

      default:
        return state;
  }
}