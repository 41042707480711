import { C } from '../../constants';

const initialState = { //define initial state - an empty form

  submittingnew: false,
  values: []

};

export default (state = initialState, action) => {
  switch (action.type) {

    case C.AWAIT_NEW_ORG_TYPE_OPTION_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_ORG_TYPE_OPTION_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        });

    case C.RECEIVE_ORG_TYPE_OPTIONS:
        return Object.assign({}, state, {values: action.options});

      default:
        return state;
  }
}