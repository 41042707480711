import { TableRow, TableRowColumn } from 'material-ui/Table';
import React from 'react';

const Indication = (props) => {

    let icon = (<i style={{fontSize: '24px'}} className="text-inverse icon ion-android-radio-button-off"></i>);
    if (props.indicated) {
        icon = (<i style={{fontSize: '24px'}} className="text-warning ion-minus-circled icon"></i>);
    }

    return (
        <TableRow>
            <TableRowColumn>{props.name}</TableRowColumn>
            <TableRowColumn style={{width: '120px'}}>{icon}</TableRowColumn>
        </TableRow>
    );
};

export default Indication;
