
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddWebHook from './components/addwebhook';
import IconButton from 'material-ui/IconButton';
import Visibility from 'material-ui/svg-icons/action/visibility';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import RaisedButton from 'material-ui/RaisedButton';
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import {
    Card,
    CardActions,
    CardHeader,
    CardMedia,
    CardTitle,
    CardText
} from 'material-ui/Card';
import Toggle from 'material-ui/Toggle';
import HelpDialog from './components/HelpDialog';

const styles = {
  smallRedIcon: {
    width: 15,
    height: 15,
    color: 'red'
  },
  smallGreyIcon: {
    width: 15,
    height: 15,
    color: 'rgb(158, 158, 158)'
  },
  small: {
    width: 30,
    height: 30,
    padding: 4,
  }
};

function getAddPersonExample(oid) {
    return [
`curl -X POST https://us-central1-diagnostic-driving.cloudfunctions.net/addPerson \\ `,
`--header "Content-Type: application/json" \\`,
`--data "{\\"token\\": <your-secret-auth-token-here>,\\"accountId\\": \\"${oid}\\",\\"organizationId\\": \\"-PWdNLY1-yWw8Seobi2c\\", \\"personIds\\": [\\"sampleUniqueId1\\", \\"sampleUniqueId2\\"]}"`
];
}

function getListPeopleExample(oid) {
    return [
`curl -X POST https://us-central1-diagnostic-driving.cloudfunctions.net/listPeople \\ `,
`--header "Content-Type: application/json" \\`,
`--data "{\\"token\\": <your-secret-auth-token-here>,\\"accountId\\": \\"${oid}\\",\\"organizationId\\": \\"-PWdNLY1-yWw8Seobi2c\\"}"`
];
}



// html &#9679;
// js "\u25cf"

class HiddenText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showing: false,
        };
    }

    handleTouchTap (ev) {
        ev.preventDefault();
        this.setState({
            showing: !this.state.showing,
        });
    }

    mask (v) {
        let dot = "\u25cf";
        let output = '';
        for (var i = 0; i < v.length; i++) {
            output += dot;
        };
        return output;
    }
    

    render () {
        const p = this.props;
        if (this.state.showing) {
            return <span className="api-elem">
                        <IconButton onTouchTap={this.handleTouchTap.bind(this)}>
                            <VisibilityOff />
                        </IconButton>
                        {p.value}
                    </span>
        }
        return (
            <span>
                <IconButton onTouchTap={this.handleTouchTap.bind(this)}>
                    <Visibility />
                </IconButton>
                {this.mask(p.value)}
            </span>
        )
    }
}


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToWebhooks() );
});

class Webhooks extends Component {

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        
        let rows = _.map(p.webhooks.data, (params, id) => {
            let lastTime = new Date(params.lastTime).toLocaleString().split(',');
            return (<TableRow key={id}>
                        <TableRowColumn> 
                            <Toggle
                                label={params.active ? 'on' : 'off'}
                                onToggle={(function(e) { this.props.toggleWebhook(id, params.active) }).bind(this)}
                                toggled={params.active} />
                        </TableRowColumn>
                        <TableRowColumn colSpan="2">{params.url}</TableRowColumn>
                        <TableRowColumn colSpan="3">{params.token}</TableRowColumn>
                        <TableRowColumn>{<div><div>{lastTime[1]}</div><div>{lastTime[0]}</div></div> || '-'}</TableRowColumn>
                        <TableRowColumn>
                            {params.hasOwnProperty('lastCallSucceeded') ?  
                                (params.lastCallSucceeded ? 
                                    <span className='success-green'>
                                        Success
                                    </span> : 
                                    <div className='failed-red'>
                                        Failed
                                        <HelpDialog
                                            style={styles.small}
                                            iconStyle={styles.smallRedIcon}
                                            title="Error Message">
                                            {params.lastResult}
                                        </HelpDialog>
                                    </div>) : '-'}
                        </TableRowColumn>
                        <TableRowColumn>
                            <RaisedButton
                                primary
                                disabled={!!p.webhooks.deleting[id]}
                                onClick={(function(e) { this.props.deleteWebhook(id) }).bind(this)}
                                label="Delete" />
                        </TableRowColumn>
                    </TableRow>)
        });

        let table = <Table selectable={false} className="webhook-table">
                        <TableHeader className="webhook-tableheader"
                            displaySelectAll={false}
                            adjustForCheckbox={false}
                            style={{zIndex: 99999999}}
                            enableSelectAll={false}>
                            <TableRow>
                                <TableHeaderColumn>
                                    Status
                                    <HelpDialog
                                        style={styles.small}
                                        iconStyle={styles.smallGreyIcon}
                                        title="Webhook Status/Toggle">
                                        Turn the webhook on and off. When the webhook is off, we will not POST to the URL.
                                    </HelpDialog>
                                </TableHeaderColumn>
                                <TableHeaderColumn colSpan="2">
                                    URL
                                    <HelpDialog
                                        style={styles.small}
                                        iconStyle={styles.smallGreyIcon}
                                        title="Webhook URL">
                                        This is the URL for the service we will POST test results to. The data will be sent as multipart/form-data (see below for more info).
                                    </HelpDialog>
                                </TableHeaderColumn>
                                <TableHeaderColumn colSpan="3">
                                    Token
                                    <HelpDialog
                                        style={styles.small}
                                        iconStyle={styles.smallGreyIcon}
                                        title="Webhook Token/Secret">
                                        Within the response we send to you the "token" field will contain the value in this column.
                                        In your code, check this parameter and make sure it contains the correct value to ensure the call is coming from us.
                                    </HelpDialog>
                                </TableHeaderColumn>
                                <TableHeaderColumn>
                                    Last Time
                                    <HelpDialog
                                        style={styles.small}
                                        iconStyle={styles.smallGreyIcon}
                                        title="Webhook Last Time Called">
                                        This column shows you the last time each webhook was called.
                                    </HelpDialog>
                                </TableHeaderColumn>
                                <TableHeaderColumn>
                                    Last Result
                                    <HelpDialog
                                        style={styles.small}
                                        iconStyle={styles.smallGreyIcon}
                                        title="Webhook Last POST Response">
                                        When we POST to the URL you have provided, we expect a response with statusCode of 200 (Success).
                                        If we get any other statusCode, we display "Failed" in this column. 
                                        Click the question mark icon to see the message associated with the error.
                                    </HelpDialog>
                                </TableHeaderColumn>
                                <TableHeaderColumn>
                                    Delete
                                    <HelpDialog
                                        style={styles.small}
                                        iconStyle={styles.smallGreyIcon}
                                        title="Webhook Delete">
                                        Delete this webhook. This action is permanent. Once deleted the service will no longer be called.
                                    </HelpDialog>
                                </TableHeaderColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody className="webhook-tablebody"
                            displayRowCheckbox={false}
                            showRowHover={false}
                            stripedRows={true}>

                            {
                                rows.length ? rows : 
                                    <TableRow>
                                        <TableHeaderColumn colSpan="9" style={{textAlign: 'center'}}>
                                            No webhooks to display at this time
                                        </TableHeaderColumn>
                                    </TableRow>
                            }

                        </TableBody>
                    </Table>;

        const add = <AddWebHook onSubmit={(function (e) {e.preventDefault(); this.props.submitNewWebhook(); }).bind(this) } />
        const wait = <CircularProgress />

        let whatAreWebhooks = "Webhooks allow external services to be " +
            "notified when a virtual driving test is completed. When a virtual drive is completed and scored" +
            ", we’ll send a POST request to each of the URLs you provide with the test's score/results as multipart/form-data. " +
            "Make sure to check for the token in the response to assure that " +
            "the POST request is from us!";

        let addWebhookSub = "We’ll send a POST request to the provided URL upon " +
            "completion of each virtual driving test. The test result data" +
            " will be sent as multipart/form-data.";

        let apiSub = "An Account ID and Auth Token are required to make calls to Diagnostic Driving API services."

        let docSub = "The following are the API functions that are available in the Diagnostic Driving system"

        let addPersonExample = getAddPersonExample(p.auth.oid);

        let addPersonLines = [];

        for (var i = 0; i < addPersonExample.length; i++) {
            addPersonLines.push(<div style={{paddingLeft: (i ? 20 : 0)}}>{addPersonExample[i]}</div>);
        };

        let listPeopleLines = [];

        let listPeopleExample = getListPeopleExample(p.auth.oid);

        for (var i = 0; i < listPeopleExample.length; i++) {
            listPeopleLines.push(<div style={{paddingLeft: (i ? 20 : 0)}}>{listPeopleExample[i]}</div>);
        };


        return (
            <div className='webhook-page'>
                <Card className='webhook-card'>
                    <CardHeader title="Webhooks" subtitle={whatAreWebhooks} />
                    <CardText className="webhook-card-text">
                        { table }
                    </CardText>
                </Card>
                <Card className='webhook-card'>
                    <CardHeader
                        title="Add Webhook"
                        subtitle={addWebhookSub}/>
                    <CardText>
                        { this.props.webhooks.submittingnew ? wait : add }
                        <div className="api-alert">
                            <div>{   "URI's supplied as webhooks should point to a function or service that is set up to process multipart/form-data. " +
                                "This the same sort of function/service that would process a form submitted from a website that contained a file upload field. " +
                                "In this case the form would have the following string inputs: "  }</div>
                            <div className="api-indent" style={{padding:20}}>
                            <div className="row"><div className="col-sm-4">token</div><div className="col-sm-8">We generate a unique token for every webhook. Always check that the token is there and correct.</div></div>
                            <div className="row"><div className="col-sm-4">assessmentId</div><div className="col-sm-8">Unique ID for this assessment</div></div>
                            <div className="row"><div className="col-sm-4">organizationId</div><div className="col-sm-8">Unique ID for the organization associated with this assessment</div></div>
                            <div className="row"><div className="col-sm-4">personId</div><div className="col-sm-8">Unique ID for individual who took this assessment</div></div>
                            <div className="row"><div className="col-sm-4">startTimeStamp</div><div className="col-sm-8">UNIX timestamp when assessment started.</div></div>
                            <div className="row"><div className="col-sm-4">endTimestamp</div><div className="col-sm-8">UNIX timestamp when assessment finished.</div></div>
                            <div className="row"><div className="col-sm-4">location</div><div className="col-sm-8">Location where assessment was taken.</div></div>
                            </div>
                            <div>{"The multipart/form-data will also have a file in it's Files array. This is a the PDF feedback report containing the assessment results and feedback for the individual who completed the assessment."}</div>
                            <div>{"Process this data the same way you would process a form submitted from a website that had a file upload field. All values, including timestamps, are strings."}</div>
                        </div>
                    </CardText>
                </Card>
                <Card className='webhook-card'>
                    <CardHeader
                        title="API Access"
                        subtitle={apiSub}/>
                    <CardText>
                        <div className="row">
                            <div className="col-sm-5 api-left">
                                <div className="api-comp">
                                    <div className="api-lbl">ACCOUNT ID</div>
                                    <div className="api-sect"><div className="api-elem">{this.props.auth.oid}</div></div>
                                    
                                </div>
                                <div className="api-comp">
                                    <div className="api-lbl">AUTH TOKEN</div>
                                    <div className="api-sect">
                                        <div>
                                            <div className="api-sub-lbl">Primary Token</div>
                                            <div>
                                            {this.props.auth.apiToken ? 
                                                <div className="hide-a-key"><HiddenText value={this.props.auth.apiToken} /></div> :
                                                <div>
                                                    No token created for this account.
                                                    <button
                                                        disabled={p.auth.tokenState != 'idle'}
                                                        onClick={(function(e) { this.props.updateApiToken() }).bind(this)}>
                                                        Create Token
                                                    </button>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                        {

                                            this.props.auth.apiTokenV2 ?

                                            <div>
                                                <div className="api-sub-lbl">Secondary Token</div>
                                                <div className="hide-a-key"><HiddenText value={this.props.auth.apiTokenV2} /></div>
                                                <div>
                                                    <button
                                                        disabled={p.auth.tokenState != 'idle'}
                                                        onClick={(function(e) { this.props.deleteSecondaryApiToken() }).bind(this)}>
                                                        Delete Secondary Token
                                                    </button>
                                                    <button
                                                        disabled={p.auth.tokenState != 'idle'}
                                                        onClick={(function(e) { this.props.promoteSecondaryApiToken() }).bind(this)}>
                                                        Promote to Primary
                                                    </button>
                                                </div>
                                            </div>

                                            :

                                            <div>
                                                <button
                                                    disabled={p.auth.creatingSecondaryApiToken}
                                                    onClick={(function(e) { this.props.createSecondaryApiToken() }).bind(this)}>
                                                    Request a Secondary Token
                                                </button>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-7 api-right">
                                <div className="api-help">
                                    Diagnostic Driving uses two credentials to determine which project an API request is coming from: The Account ID, which acts as a username, and the Auth Token which acts as a password.
                                </div>
                                <div className="api-alert">
                                    Notice: If another user gets access to your Auth Token and Account ID, they will have full access to your project, and use of the Diagnostic Driving API as if they were you. All users should take steps to keep their Auth Token private. 
                                </div>
                                <div className="api-help">
                                    {'To change your Auth Token: 1) click "Request Secondary Token." 2) Click the Eye icon to view your new secondary token, and use it to update your existing applications.  3) Once your projects are updated, Click "Promote to Primary" to make your newly created Auth Token the main token for your project. Note: If you decide you don’t need the second token and want to keep using the primary one, click "Delete Secondary Token" to remove it.'}
                                </div>
                                <div className="api-alert">
                                    Notice: Promoting a secondary Auth Token to the primary role instantly and automatically removes your old existing Auth Token from your project. Immediately after promoting the new token, all requests to Diagnostic Driving using your old Auth Token will result in an error. Any existing code using your old token will need to be updated with the new Auth Token before they can work successfully again. To make this change without incurring downtime, we recommend updating all projects with the secondary token prior to promoting it to primary.
                                </div>
                            </div>
                        </div>
                        
                    </CardText>
                </Card>
                <Card className='webhook-card'>
                    <CardHeader
                        title="API Documentation"
                        subtitle={docSub}/>
                    <CardText>
                        <div>All calls to these endpoints must be POST requests and the data supplied in the request as well as returned in the response is in the JSON format. You must include your accountID and (secret) token in the data provided to every request.</div>
                        <div className="api-endpoint">
                            <div className="api-name">/addPerson</div>
                            <div className="api-details">
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Description
                                    </div>
                                    <div className="col-sm-10">
                                        <div>
                                            Adds a unique driver/person ID within organization (specified by the unique organizationId provided).
                                        </div>
                                        <div>
                                            If no organizationId is provided, driver/person is added to this (parent) organization.
                                        </div>
                                    </div>
                                </div>
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        URL Structure
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            {"https://us-central1-diagnostic-driving.cloudfunctions.net/addPerson"}
                                        </div>
                                    </div>
                                </div>
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Example
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            {addPersonLines}
                                        </div>
                                    </div>
                                </div>
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Parameters
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            <div>{"{"}</div>
                                            <div style={{paddingLeft: 20}}>{'"token": <your-secret-auth-token-here>'}</div>
                                            <div style={{paddingLeft: 20}}>{'"accountId": "' + p.auth.oid + '"'}</div>
                                            <div style={{paddingLeft: 20}}>{'"organizationId": "-PWdNLY1-yWw8Seobi2c"'}</div>
                                            <div style={{paddingLeft: 20}}>{'"personIds": ["sampleUniqueId1", "sampleUniqueId2"]'}</div>
                                            <div>{"}"}</div>
                                        </div>
                                        <div>
                                            <div className="api-args-title">Add Person Arguments</div>
                                            <div className="api-args-body">

                                                <div className="api-arg-item">
                                                    <span className="arg">token</span>
                                                    <span className="arg-type">String</span>
                                                    <span className="arg-desc">Your secret Auth Token.</span>
                                                </div>

                                                <div className="api-arg-item">
                                                    <span className="arg">accountId</span>
                                                    <span className="arg-type">String</span>
                                                    <span className="arg-desc">Your account ID.</span>
                                                </div>

                                                <div className="api-arg-item">
                                                    <span className="arg">organizationId</span>
                                                    <span className="arg-type">String</span>
                                                    <span className="arg-desc">The organization ID that you are adding the persons/drivers to. This argument is optional. If none is provided, the persons/drivers will be added to your (parent) organization.</span>
                                                </div>

                                                <div className="api-arg-item">
                                                    <span className="arg">personIds</span>
                                                    <span className="arg-type">List of Strings</span>
                                                    <span className="arg-desc">The unique person/driver ID(s) you are adding to the system. Values can only contain, letters, numbers, dashes & underscores</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Response Example
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            <div>{"{"}</div>
                                            <div style={{paddingLeft: 20}}>{'"msg": "[sampleUniqueId1] was added to organization -PWdNLY1-yWw8Seobi2c , but [sampleUniqueId2] was not added because it was already there."'}</div>
                                            <div>{"}"}</div>
                                            <div className="api-or">OR</div>
                                            <div>{"{"}</div>
                                            <div style={{paddingLeft: 20}}>{'"error": "The \'personIds\' list was empty"'}</div>
                                            <div>{"}"}</div>
                                        </div>
                                        <div>
                                            <div className="api-responses-title">Response Codes, Errors & Messages</div>
                                            <div className="api-responses-body">

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 200</span>
                                                        <span className="responses-type">Success</span>
                                                        <span className="responses-desc">
                                                            Operation was successful. 
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This is returned even if all or some of the IDs were not added due to them already being in the system for the specified organization.
                                                        The JSON response will contain a 'msg' property detailing which IDs were added and which IDs were already in the system (if any).
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 400</span>
                                                        <span className="responses-type">Failure: Bad Request</span>
                                                        <span className="responses-desc">
                                                            The server could not understand the request due to invalid syntax.
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This is returned if data necessary for this operation is not provided or is malformed.
                                                        Values for personId can only contain, letters, numbers, dashes & underscores. If any are malformed, none will be added.
                                                        This response might also be returned if the personIds list is missing or empty.
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 405</span>
                                                        <span className="responses-type">Failure: Method Not Allowed</span>
                                                        <span className="responses-desc">
                                                            The endpoint was called with the wrong request method (only accepts POST)
                                                        </span>
                                                    </div>
                                                    <div>
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 401</span>
                                                        <span className="responses-type">Failure: Unauthenticated</span>
                                                        <span className="responses-desc">
                                                            Request was missing the required data for authentication.
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This type of response is returned when the request is missing 'accountId' and/or 'token' properties.
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 403</span>
                                                        <span className="responses-type">Failure: Forbidden</span>
                                                        <span className="responses-desc">
                                                            Data required for authentication was incorrect/invalid.
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This type of response is returned when the accountId or token values do not match the actual values for an account in the system or the token for that account.
                                                        This could alos occur if a token has not yet been generated for the specified account.
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 500</span>
                                                        <span className="responses-type">Failure: Internal Server Error</span>
                                                        <span className="responses-desc">
                                                            {"The server has encountered a situation it doesn't know how to handle."}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This type of response is returned when something is broken on our end.
                                                        Please reach out to support if you receive this kind of response.
                                                        A JSON response is returned with an 'error' property (however, in this case, it probably will not be super helpful.)
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="api-endpoint">
                            <div className="api-name">/listPeople</div>
                            <div className="api-details">
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Description
                                    </div>
                                    <div className="col-sm-10">
                                        <div>
                                            Retrieves a list of all the personIds for a specified organization
                                        </div>
                                        <div>
                                            If no organizationId is provided, retrieves personIds from this (parent) organization.
                                        </div>
                                    </div>
                                </div>
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        URL Structure
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            {"https://us-central1-diagnostic-driving.cloudfunctions.net/listPeople"}
                                        </div>
                                    </div>
                                </div>
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Example
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            {listPeopleLines}
                                        </div>
                                    </div>
                                </div>
                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Parameters
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            <div>{"{"}</div>
                                            <div style={{paddingLeft: 20}}>{'"token": <your-secret-auth-token-here>'}</div>
                                            <div style={{paddingLeft: 20}}>{'"accountId": "' + p.auth.oid + '"'}</div>
                                            <div style={{paddingLeft: 20}}>{'"organizationId": "-PWdNLY1-yWw8Seobi2c"'}</div>
                                            <div>{"}"}</div>
                                        </div>
                                        <div>
                                            <div className="api-args-title">List People Arguments</div>
                                            <div className="api-args-body">

                                                <div className="api-arg-item">
                                                    <span className="arg">token</span>
                                                    <span className="arg-type">String</span>
                                                    <span className="arg-desc">Your secret Auth Token.</span>
                                                </div>

                                                <div className="api-arg-item">
                                                    <span className="arg">accountId</span>
                                                    <span className="arg-type">String</span>
                                                    <span className="arg-desc">Your account ID.</span>
                                                </div>

                                                <div className="api-arg-item">
                                                    <span className="arg">organizationId</span>
                                                    <span className="arg-type">String</span>
                                                    <span className="arg-desc">The ID of the organization you are seeking the personIds from. This argument is optional. If none is provided, the persons/drivers will be listed from your (parent) organization.</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="api-row row">
                                    <div className="col-sm-2">
                                        Response Example
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="api-code">
                                            <div>{"["}</div>
                                            <div style={{paddingLeft: 20}}>{'"sampleUniqueId1", "sampleUniqueId2"'}</div>
                                            <div>{"]"}</div>
                                            <div className="api-or">OR</div>
                                            <div>{"{"}</div>
                                            <div style={{paddingLeft: 20}}>{'"error": "Account is not set as a parent and is trying to manage a different organization"'}</div>
                                            <div>{"}"}</div>
                                        </div>
                                        <div>
                                            <div className="api-responses-title">Response Codes, Errors & Messages</div>
                                            <div className="api-responses-body">

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 200</span>
                                                        <span className="responses-type">Success</span>
                                                        <span className="responses-desc">
                                                            Operation was successful. 
                                                        </span>
                                                    </div>
                                                    <div>
                                                        If this status is returned the JSON response will be a list of personIds for the organization specified.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 405</span>
                                                        <span className="responses-type">Failure: Method Not Allowed</span>
                                                        <span className="responses-desc">
                                                            The endpoint was called with the wrong request method (only accepts POST)
                                                        </span>
                                                    </div>
                                                    <div>
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 401</span>
                                                        <span className="responses-type">Failure: Unauthenticated</span>
                                                        <span className="responses-desc">
                                                            Request was missing the required data for authentication.
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This type of response is returned when the request is missing 'accountId' and/or 'token' properties.
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 403</span>
                                                        <span className="responses-type">Failure: Forbidden</span>
                                                        <span className="responses-desc">
                                                            Data required for authentication was incorrect/invalid.
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This type of response is returned when the accountId or token values do not match the actual values for an account in the system or the token for that account.
                                                        This could alos occur if a token has not yet been generated for the specified account.
                                                        A JSON response is returned with an 'error' property that described exactly what went wrong.
                                                    </div>
                                                </div>

                                                <div className="api-responses-item">
                                                    <div>
                                                        <span className="responses-status">Status: 500</span>
                                                        <span className="responses-type">Failure: Internal Server Error</span>
                                                        <span className="responses-desc">
                                                            {"The server has encountered a situation it doesn't know how to handle."}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        This type of response is returned when something is broken on our end.
                                                        Please reach out to support if you receive this kind of response.
                                                        A JSON response is returned with an 'error' property (however, in this case, it probably will not be super helpful.)
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        webhooks: appState.webhooks,
        auth: appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewWebhook: function () { dispatch( actions.submitNewWebhook() )},
        deleteWebhook: function (hookID) { dispatch( actions.deleteWebhook(hookID) )},
        toggleWebhook: function (hookID, active) { dispatch( actions.toggleWebhook(hookID, active) )},
        updateApiToken: function () { dispatch( actions.updateApiToken() ) },
        createSecondaryApiToken: function () { dispatch( actions.createSecondaryApiToken() ) },
        promoteSecondaryApiToken: function () { dispatch( actions.promoteSecondaryApiToken() ) },
        deleteSecondaryApiToken: function () { dispatch( actions.deleteSecondaryApiToken() ) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);
