import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { SelectField, Slider, TextField, DatePicker, TimePicker } from 'redux-form-material-ui';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

const safeName = /^[A-Za-z_][A-Za-z0-9_\-]*$/;

const validate = values => {
    const errors = {}

    if (!values["name"]) {
        errors ["name"] = "Required"
    }

    if (!values["max"]) {
        errors ["max"] = "Required"
    }

    if (!values["expirationDate"]) {
        errors ["expirationDate"] = "Required"
    }

    if (!values["expirationTime"]) {
        errors ["expirationTime"] = "Required"
    }



    if (!values["numberOfItems"]) {
        
        errors ["numberOfItems"] = "One or more flags required"
    } else {
        if (values["numberOfItems"] < 1) {
            
            errors ["numberOfItems"] = "One or more flags required"
        } else {
            for (var i = values["numberOfItems"]; i >= 0; i--) {
                if (!values["flag_" + i]) {
                    errors["flag_" + i] = "Required"
                }

            };
        }
    }

    return errors
}

const renderRadioGroup = ({ input, ...rest }) => (
    <RadioButtonGroup
        {...input}
        {...rest}
        defaultSelected='on'
        onChange={(event, value) => input.onChange(value)} />
)

class ExperimentForm extends Component {
    constructor(props, context) {
        super(props, context);
    }


    render () {
        const p = this.props;
        const featureFlags = p.flags;

        var formToRender;
        let flagFields = [];


        for (let i = 0; i < p.numberOfItems; i++) {
            
            let flagNumber = "" + (i);
            flagFields.push(
                //make these steps into step selectors (pull in from DB like we do with videos in VideoForm)
                <div className="q-choice" key={"flag_" + i}>
                    <h6 className="choice-header"><b>Flag {flagNumber}</b></h6>
                    <div className="row">
                        <div className="col-sm-8">
                            <Field
                                name={"flag_" + i}
                                fullWidth={true}
                                component={SelectField}
                                floatingLabelText={"Flag " + flagNumber}>
                                {
                                    _.map(featureFlags, (item, index) => {
                                        return <MenuItem value={item} key={item} primaryText={item} />
                                    })
                                }
                            </Field>
                        </div>
                        <div className="col-sm-4">
                            <Field name={"flag_negate_" + i} defaultSelected="on" component={renderRadioGroup}>
                                <RadioButton value="on" label="On" />
                                <RadioButton value="off" label="Off" />
                            </Field>
                        </div>
                    </div>
                </div>
            )
        }

        formToRender = <Paper style={{ margin: "30px", padding: "25px"}}>
            <form  className={this.props.className} 
                        onSubmit={this.props.onSubmit} >
                    <div>
                        <Field 
                            name="name"
                            component={TextField}
                            fullWidth={true}
                            hintText="Name this experiment"
                            floatingLabelText="Experiment Name"/>
                    </div>
                    <div className="slider-box">
                        <label htmlFor="chance">{"What chance (out of 100) should this have of happening?"}</label>
                        <div className="ccc-box">{p.chance}</div>
                        <Field
                            name="chance"
                            component={Slider}
                            format={function (value, name) {
                                
                                if (value === '') {
                                    return 0
                                }

                                return parseInt(value);
                            }}
                            defaultValue={0}
                            min={0}
                            max={100}
                            step={1}/>
                    </div>
                    <div>
                        <Field
                            name="expirationDate"
                            component={DatePicker}
                            hintText="Date of expiration?" />
                    </div>

                
                    <div>
                        <Field
                            name="expirationTime"
                            component={TimePicker}
                            defaultValue={null} // TimePicker requires an object,
                            // and redux-form defaults to ''
                            hintText="Time of expiration?" />
                    </div>
                    <div>
                        <Field
                            name="max"
                            component={TextField}
                            hintText="1000"
                            floatingLabelText="Maximum times test should run"
                            type="number"/>
                    </div>
                    <div className="slider-box">
                        <label htmlFor="numberOfItems">{"How many flags does this experiment need?"}</label>
                        <div className="ccc-box">{p.numberOfItems}</div>
                        <Field
                            name="numberOfItems"
                            component={Slider}
                            format={function (value, name) {
                                
                                if (value === '') {
                                    return 0
                                }

                                return parseInt(value);
                            }}
                            defaultValue={0}
                            min={0}
                            max={10}
                            step={1}/>
                    </div>

                    {flagFields}

                    <div className="row">
                        <div className="col-sm-7"></div>
                        <div className="col-sm-5">
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Add Experiment"
                            type="submit"
                            fullWidth={true}
                            style={{ width: "20%", marginTop: "15px" }} />
                        </div>
                    </div>
            </form>
            </Paper>;


        return (
            <div>

                    

                {formToRender}


            </div>
            
        )
    }
}

// Decorate the form component
ExperimentForm = reduxForm({
  form: 'experiment', // a unique name for this form
  validate
})(ExperimentForm);

//get the input value from createCoachingSurveyForm -- formValueSelector function is from redux-form
const selector = formValueSelector('experiment');
ExperimentForm = connect(
  state => {
    // send numSteps to redux state
    const numberOfItems = selector(state, 'numberOfItems')
    const chance = selector(state, 'chance')
    const max = selector(state, 'max')
    return {
      numberOfItems,
      chance,
      max
    }
  }
)(ExperimentForm)

const mapStateToProps = function (appState) {
    return {

    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        //not used atm
        resetForm: function () {
            dispatch(reset('coachingpage'));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperimentForm);