import { ListItem } from 'material-ui/List';
import GroupWork from 'material-ui/svg-icons/action/group-work';
import Info from 'material-ui/svg-icons/action/info';
import React, { Component } from 'react';


class Group extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        return (
            <ListItem 
                className="group" 
                primaryText={p.group.name} 
                leftIcon={<GroupWork />}
                nestedItems={[
                    <ListItem key={0} primaryText={p.group.description} leftIcon={<Info />} />,
                ]} />
        )
    }
}

export default Group;