import { C } from '../constants';

const timeRef = C.APP.database().ref("/.info/serverTimeOffset");
const staticFeedbackRef = C.APP.database().ref('staticFeedback');

export const listenToOffset = function () {
    return function (dispatch, getState) {
        timeRef.on('value', (offset) => {
            dispatch({ type: C.RECEIVE_OFFSET, offset: offset.val() });
        });
    }
}

export const setTitle = function (title) {
    return function (dispatch, getState) {

        dispatch({ type: C.SET_TITLE, title });

    }
}

export const setSubtitle = function (subtitle) {
    return function (dispatch, getState) {

        dispatch({ type: C.SET_SUBTITLE, subtitle });

    }
}

export const clearTitle = function () {
    return function (dispatch, getState) {

        dispatch({ type: C.CLEAR_TITLE });

    }
}

export const setAttemptedPath = function (pathname) {
    console.log('OoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOo   ' + pathname + '   OoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOoOo');
    return function(dispatch,getState){
        dispatch({type: C.SET_ATTEMPTED_PATH, pathname})
    }
}

export const clearAttemptedPath = function () {
    return function(dispatch,getState){
        dispatch({type: C.CLEAR_ATTEMPTED_PATH})
    }
}

export const startListeningToStaticFeedback = function () {
    return function (dispatch, getState) {
        staticFeedbackRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_STATIC_FEEDBACK_DATA, data: snap.val()});
        });
    }
}