import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField } from 'redux-form-material-ui';

const validate = values => {
    const errors = {}
    console.log(values);
    console.log(values["survey"] === null);
    if (values[ "survey" ] === null && values["surveySelectQuestion"] === null) {
        console.log("huh");
        errors[ "survey" ] = 'Either a survey or survey select question must be chosen'
        errors[ "surveySelectQuestion" ] = 'Either a survey or survey select question must be chosen'
    }

    return errors
}


class AddIntakeOptionForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("rendering add intake options form");

        if (!(this.props.surveys && this.props.items)) {
            return <div>
                <div className="form-box-top">Create an Intake Survey Option: </div>
                <div className="form-box">{"No intake surveys or intake questions configured. Please create some intake questions, pages and surveys first."}</div>
            </div>
        }

        let itemChoices = _.map(this.props.items.data, (item, key) => {
            if (item.type == 'multvert') {
                if (item.choices && item.choices.length) {
                    let allowed = true;
                    // check if each choice value is a valid survey
                    for (var i = 0; i < item.choices.length; i++) {
                        let choice = item.choices[i];
                        allowed = allowed && this.props.surveys.data[choice.value];
                    };
                    if (allowed) {
                        return <MenuItem key={key} value={key} primaryText={item.name} />
                    }
                    
                }
            }
            return null;
        });

        let surveyChoices = _.map(this.props.surveys.data, (survey, key) => {
            return <MenuItem value={key} key={key} primaryText={survey.name} />
        });

        itemChoices.unshift(<MenuItem value={null} key={'null_value'} primaryText={""} />);
        surveyChoices.unshift(<MenuItem value={null} key={'null_value'} primaryText={""} />);

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="form-b-box-top display-6">Create an Intake Survey Option: </div>
                <div className="form-b-box">
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                name="survey"
                                fullWidth={true}
                                component={SelectField}
                                style={{ verticalAlign: "top"}}
                                floatingLabelText="Select an Intake Survey">

                                {
                                    surveyChoices
                                }
                            </Field>
                        </div>
                        <div className="col-sm-6">
                            <Field
                                name="surveySelectQuestion"
                                fullWidth={true}
                                component={SelectField}
                                style={{ verticalAlign: "top"}}
                                floatingLabelText="Select a Survey Select Question">

                                {
                                   itemChoices
                                }
                            </Field>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <Field 
                                name="name"
                                component={TextField}
                                fullWidth={true}
                                hintText="Name this intake survey option"
                                floatingLabelText="Option Name"/>
                        </div>
                        <div className="col-sm-4">
                            <Field 
                                name="optionDisplayText"
                                component={TextField}
                                fullWidth={true}
                                hintText="Text displayed to users for this option"
                                floatingLabelText="Option Display Text"/>
                        </div>
                        <div className="col-sm-4">
                            <Field 
                                name="instructionText"
                                component={TextField}
                                fullWidth={true}
                                hintText="Instruction for selecting a survey option"
                                floatingLabelText="Option Specific Instruction"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <RaisedButton
                                primary
                                disabled={this.props.pristine || !this.props.valid}
                                label="Add Intake Survey Option"
                                type="submit"
                                fullWidth={true}
                                style={{ width: "20%", marginTop: "15px" }} />
                        </div>
                    </div>
                </div>

            </form>
        )
    }
}


// Decorate the form component
AddIntakeOptionForm = reduxForm({
  form: 'iso', // a unique name for this form
  validate
})(AddIntakeOptionForm);



export default AddIntakeOptionForm;