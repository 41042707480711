import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Markdown from "react-markdown";
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const styles = {
    multhorz: {
        display: "inline-block",
        width: "20%"
    },
    multvert: {

    },
    multvertlabel: {

    },
    multhorzlabel: {
            position: "relative",
            display: "block",
            width: "0px",
            top: "-1.8em",
            left: "-2.29em"
    }
}

let validate = function (values) {
    return [];
}

class CoachingWizardStep extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentWillMount () {
        // store.dispatch( actions.startListeningToVideos() );
    }

    render () {
        const p = this.props;
        validate = p.validate;
        let choices = [];
        const step = p.step;
        const videos = p.video;
        let intakeRow; // to hold the divs we want to display
        var partNum = parseInt(p.stepId.slice(-1), 10)+1;
        var stepNum = p.stepId.slice(4,-6);
        var size = Object.keys(step).length; //how many parts to this step?

        if (p.video.hasReceivedVideoData) {
            if (size === 1) {
                // this part has only one component, so it must be the last question in this step
                intakeRow =  (
                <div className="row intake-row">
                    <div className={"col-sm-12 display-6 intake-q-t intake-q-t-video"}><Markdown source={step['last']} /></div>
                    {<div className="col-sm-12">
                        <Field
                            name={'step'+stepNum+'lastQuestion'}
                            fullWidth={true}
                            component={TextField}
                            hintText="Type your answer here."/>
                    </div>}
                </div>
                )
            } else if (size === 2) {
                // this part has two components -- a video and a question
                intakeRow =  (
                <div className="row intake-row">
                    <div className={"col-sm-12 display-6 intake-q-t intake-q-t-video"}>{"Please watch the following video carefully and then answer the following question below. You can re-watch the video as many times as you want."}</div>
                    <video width="100%" height="100%" controls>
                            <source src={p.video.data[step['v'+partNum]].url} type="video/mp4" />
                            Your browser does not support HTML5 video
                    </video>

                    <div className={"col-sm-12 display-6 intake-q-t intake-q-t-text"}><Markdown source={step['q'+partNum]} /></div>
                    {<div className="col-sm-12">
                        <Field
                            name={'step'+stepNum+'question'+partNum}
                            fullWidth={true}
                            multiLine={true}
                            rows={5}
                            component={TextField}
                            hintText="Type your answer here."/>
                    </div>}
                </div>
                )
            } else {
                console.error("This step has an abnormal size. Please see CoachingWizardStep.jsx in the code base.")
            }
        }

        const previous = !p.index ? null : (
            <div>
                <RaisedButton
                        secondary
                        label="Previous"
                        onClick={p.previousPage}
                        style={{ width: "100%", marginTop: "15px" }} />
            </div>
        )

        const next = p.last ? (
            <div>
                <RaisedButton
                        primary
                        disabled={this.props.pristine }
                        label="Submit"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
            </div>
        ) : (
            <div>
                <RaisedButton
                        primary
                        label="Next"
                        disabled={this.props.pristine }
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
            </div>
        )

        return (
            <form 
                className="coaching-form"
                onSubmit={p.last ? p.onSubmit : p.nextPage}>
                {intakeRow}
                <div className="row">
                    <div className="col-sm-6">
                        {previous}
                    </div>
                    <div className="col-sm-6">
                        {next}
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        video: appState.video
    };
}

export default connect(mapStateToProps)(CoachingWizardStep);