import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {C} from '../constants';
import actions from '../actions';
import store from '../store';
import queryString from 'query-string';
import AssessmentGrid from './components/assessmentGrid';
import { withRouter } from 'react-router';


class AssessmentDash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    waitForScoringMethod () {
        const router = this.props.router;

        let aid = this.props.params.aid;

        if (aid) {
            router.replace('/dash/d/' + aid);
        }

    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    componentWillMount () {
        
        if (!this.props.auth.scoringMethod) {
            this.waitForScoringMethod();
        } else {
            this.props.setTitle('Assessment-Level Report');
        }
    }

    render () {
        
        const p = this.props;
        const aid = this.props.params && this.props.params.aid;

        if (this.props.auth.scoringMethod) {
            return <AssessmentGrid aid={aid} />
        }

        return <div></div>
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) }
    }
}

const RoutableAssessmentDash = withRouter(AssessmentDash);

export default connect(mapStateToProps, mapDispatchToProps)(RoutableAssessmentDash);
