import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';
import _ from 'lodash';

const styles = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16,
  },
};

class OrgToggles extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        const p = this.props;
    }

    render () {
        const p = this.props;
        return (

            <div className='org-box'>
                <div className='display-6 btm-space'>Configure Child Organizations</div>
                {

                    _.map(p.orgs.data, (org, oid) => {
                        if (oid != p.oid) {
                            return <Checkbox
                                label={org.name + ' - ' + org.abrv + ' - ' + oid}
                                key={'orgToggle_' + oid}
                                checked={p.thisOrg.childOrgs && p.thisOrg.childOrgs[oid]}
                                onCheck={ function() { p.toggleOrg(p.oid, oid, p.thisOrg, org) } }
                                style={styles.checkbox} />
                        }
                        
 
                    })
                }

            </div>
        )
    }
}

export default OrgToggles;