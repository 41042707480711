import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import FileForm from './components/fileform';
import * as perms from "../utils/permission";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import Paper from 'material-ui/Paper';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import IconButton from 'material-ui/IconButton';



class DownloadLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlready: false,
            url: "",
            error: false,
            errorMsg: ""
        };
    }

    componentWillMount () {
        const storage = firebase.storage();
        console.log("this.props.data", this.props.data);
        const pathReference = storage.ref(this.props.data.ref);
        console.log(pathReference);
        const compStash = this;

        pathReference.getDownloadURL().then(function(url) {
          compStash.setState({
            urlready: true,
            url: url
          });
        }).catch(function(error) {
            console.log("replay dl url error", error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object_not_found':
              compStash.setState({
                error: true,
                errorMsg: "File not found"
              });
              break;

            case 'storage/unauthorized':
              compStash.setState({
                error: true,
                errorMsg: "Unauthorized"
              });
              break;

            case 'storage/canceled':
              compStash.setState({
                error: true,
                errorMsg: "Cancelled"
              });
              break;

            case 'storage/unknown':
              compStash.setState({
                error: true,
                errorMsg: "Unknown Error"
              });
              break;
          }
        });

    }

    render () {
        const p = this.props;
        if (this.state.error) {
            return <span>{this.state.errorMsg}</span>
        }
        if (!this.state.urlready) {
            return <span>getting download url</span>
        }
        return (
            <a href={this.state.url} target="_blank">{p.label || 'download'}</a>
        )
    }
}


class Files extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToFiles() );
    }

    render () {
        const p = this.props;
        const SuperIconButton = perms.spr(IconButton);
        const rows = _.map(p.files.data, function (file, fileId) {
            return (
                    <Paper className="file-paper" key={fileId}>
                        <div className="file-hdr display-6">{file.name}</div>
                        <div className="file-bdy row">
                            <div className="col-sm-8">{file.description}</div>
                            <div className="col-sm-2"><DownloadLink data={file}/></div>
                            <div className="col-sm-2">
                                <SuperIconButton
                                    tooltip="Delete this file"
                                    onClick={(function (e) { p.deleteFile(fileId, file); }).bind(this)}>
                                        <DeleteIcon />
                                </SuperIconButton>
                            </div>
                        </div>
                    </Paper>)
        })
        const list = (
            <div className="fileList">
                <div className="filesPageHeader">
                    Help Files
                </div>
                {p.files.hasReceivedData ? rows : (<div key={0}>Loading help files</div>)}
            </div>
        )
        const nodata = (
            <div>No files available</div>
        )
        if (!rows.length) {
            rows.push(nodata);
        }

        const SuperFileForm = perms.spr(FileForm);


        rows.unshift(<SuperFileForm files={p.files}  onSubmit={(function (e) {e.preventDefault(); this.props.uploadFile(); }).bind(this) } />);
        

        return (
            <div>
            { list }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        files: appState.files
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadFile: function () { dispatch( actions.uploadFile() )},
        deleteFile: function (fileId, file) { dispatch( actions.deleteFile(fileId, file) )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Files);
