

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import UserDrawer from './components/userdrawer';

const startListening = hlp.createOneShot(function () {
    //TODO -- I think Firebase handles this
    // but we should make sure this only happens once
    store.dispatch( actions.startListeningToUsers() );
    store.dispatch( actions.startListeningToGroups() );
    store.dispatch( actions.startListeningToOrgs() );
});

class ManageUser extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const uid = this.props.params.uid;
        const loaded = p.users.hasReceivedUserData;
        const user = p.users.data[uid];

        return (
                <div>
                    <div className="panel">
                        <div className="panel-body">
                            { loaded ? (
                                user ? (
                                    <div>
                                        <h3>{ user.fullname }</h3>
                                        <h5>{ user.email }</h5>
                                        <UserDrawer
                                            uid={uid}
                                            user={user} /> 
                                    </div>
                                ) : <h3>User not found</h3>
                            ) : (

                                <h3>Loading user...</h3>

                            )}
                            
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        users: appState.users,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {

    }
}

ManageUser.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);



