
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import IconButton from 'material-ui/IconButton';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import BuildForm from './components/buildform';

const style = {
  margin: 20,
  position: "relative"
};

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

const cStyle = {
    padding: 15,
    textAlign: "center"
}

const bplts = {
    "win32" : "Windows",
    "darwin" : "Mac"
}

const versionRegex = /-(\d+)\.zip/;
const tagRegex = /2F([a-zA-Z!@^&%\*\+\=<>`~,\.\?$#\(\)\d\-_]+)\-((\bdarwin\b)|(\bwin32\b))/;

function getComments(comments, vrs, ref) {
    // console.log('BUILDEBUG', comments, ref)
    // let originalVersionNumber = versionRegex.exec(ref)[1];
    // let refMatches = tagRegex.exec(ref);
    // let ogTag = refMatches[1];
    // let platform = refMatches[2];
    // return comments[ogTag] && comments[ogTag][platform] && comments[ogTag][platform][originalVersionNumber] || "";
    return "comments disabled temporarily";
}

function getUploadInfo(vrs, ref) {
    let originalVersionNumber = versionRegex.exec(ref);
    let ogDate = "unknown";
    let ogVersion = "unknown";
    if (originalVersionNumber) {
        ogDate = new Date(parseInt(originalVersionNumber[1]) * 1000).toLocaleString(); 
        ogVersion = originalVersionNumber[1];
    }
    let ogTag = "unknown";
    let ogTagMatch = tagRegex.exec(ref);
    if (ogTagMatch) {
        ogTag = ogTagMatch[1];
    }
    let tagDate = new Date(vrs * 1000).toLocaleString(); 
    return <div><div>{"Tagged: " + tagDate}</div><div>{"Uploaded: " + ogDate}</div><div>{"Original Tag: " + ogTag}</div><div>{"Original Version: " + ogVersion}</div></div>;
}


class Builds extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToBuilds() );
        store.dispatch( actions.startListeningToBuildComments() );
    }

    render () {
        const p = this.props;
        let hideThese = {};
        const comments = p.builds.commentData || {};

        const renderVersions = function (versions, platform, tag) {
            let key = platform + "-" + tag;
            return _.map(versions, function (ref, version) {

                let pinput = null;

                let del = <div className="build-delete col-sm-1">
                        <div
                        ref = {function (div) { if (div != null && !hideThese[key]) {hideThese[key] = div; div.className="hiddenDelete";} }}>
                            <IconButton
                                tooltip="Delete this build"
                                onClick={(function (e) { p.deleteBuild(tag, platform, ref, version, false); }).bind(this)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                return <div className="buildRow row" style={{ padding: 14, borderBottom: "1px dashed #bbb" }} key={version}>
                    <div className="col-sm-2" style={{ paddingTop: "8px"}}><a href={ref}> {version} </a></div>
                    <div className="col-sm-3">{ getUploadInfo(version, ref) }</div>
                    <div className="col-sm-3">
                        <div className="build-comments">
                            {getComments(comments, version, ref)}
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <RaisedButton
                        primary
                        onClick={(function (e) { p.promoteBuild(tag, platform, ref); }).bind(this) }
                        label="Move Up" />
                    </div>
                    <div className="col-sm-2">
                        <div style={{ textAlign: "right"}}>
                            <label>Tag: </label>
                            <input
                                type="text"
                                style={{ margin: "0px 15px 0px 6px"}}
                                ref={function(input) {
                                    if (input != null) {
                                        pinput = input;
                                    }
                                }} />

                            <RaisedButton
                                primary
                                onClick={(function (e) { if (pinput && pinput.value) {p.promoteBuild(pinput.value, platform, ref); pinput.value = ""; } }).bind(this) }
                                label="Change Tag" />
                        </div>
                    </div>
                    {del}
                </div>
            })
        }
        let keymaker = -1;
        const renderPlatforms = function (platforms, tag) {
           return _.map(platforms, function (versions, platform) {
                keymaker++;
                return <div className="buildPlatform" key={platform + "-" + keymaker}>
                    <div className="buildPlatformTitle"> { bplts[platform] } </div>
                    { renderVersions(versions, platform, tag).reverse().slice(0, 10) }
                </div>;
            })
        }
        const rows = _.map(p.builds.data, function (platforms, tag) {
            return <Paper
                key={tag}
                style={style}
                zDepth={1}
            >
                
                <div style={innerStyle} >
                <div className="row">
                    <div className="display-6 col-sm-11">{tag}</div>
                    <div className="col-sm-1">
                        { tag === "default" ? null : <FloatingActionButton mini={true} secondary={true}
                                                        onClick={(function (e) { p.deleteTag(tag); }).bind(this)}>
                                                        <DeleteIcon />
                                                    </FloatingActionButton>}

                    </div>
                </div>
                
                { renderPlatforms(platforms, tag) }
                </div>
                
            </Paper>
        })
        const list = p.builds.hasReceivedBuildData ? rows : (<Paper style={style} zDepth={1}>
                        <div style={innerStyle} >Loading Build Data...</div>
                    </Paper>)

        const nodata = (
            <Paper
                key={0}
                style={style}
                zDepth={1}
            >
                <div style={innerStyle} >No builds found</div>
            </Paper>
        )
        if (!rows.length) {
            rows.push(nodata);
        }

        const hangOn = <Paper
                style={style}
                zDepth={1}
            >
                <div style={cStyle} >
                    <CircularProgress />
                </div>
            </Paper>

        const bForm = (<BuildForm className="build-form" onSubmit={(function (e) {e.preventDefault(); this.props.uploadBuild(); }).bind(this) } />);
        const wait = <div>Progress: {this.props.builds.progress.toFixed(2)}%</div>
        const loaded = this.props.builds.progress + "%";
        console.log("LOADED: ", loaded);
        const add = (
                     <Paper
                        style={style}
                        zDepth={1}
                        key={999}
                    >
                        <div style={{
                                position: "absolute",
                                top: "0px", 
                                right: "0px",
                                padding: "5px",
                                fontSize: "85%",
                                border: "1px solid #CCC",
                                zIndex: "999999999"
                            }}>
                            Total Bytes: {this.props.builds.totalBytes} - Bytes Transferred: {this.props.builds.bytesTransferred}
                        </div>
                        <div className="progressbar" style={{ width: loaded }}></div>
                        <div style={innerStyle} >{ this.props.builds.uploading ? wait : bForm}</div>
                    </Paper>
                     
        )
        //rows.unshift(add);
        
        const displayErrors = function () {
            if (p.builds.error) {
                return <Paper className="build-upload-error" style={style} zDepth={1}>
                    <div style={innerStyle} >{p.builds.error}</div>
                </Paper>
            }
            return null;
        }


        // console.log("==================", hideThese);
        // _.map(hideThese, function (div, key) {
        //     // div.style.display = "none";
        //     console.log(div);
        // })

        return (
            <div>
                { displayErrors() }
                { add }
                <div style={{paddingRight: "40px", textAlign: "right"}}>
                    <RaisedButton
                        primary
                        onClick={(function (e) { p.cleanUpBuilds(); }).bind(this) }
                        label="Expire Old Builds" />
                </div>
            { p.builds.acting ? hangOn : list }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        builds: appState.builds,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadBuild: function () { dispatch( actions.uploadBuild() )},
        promoteBuild: function (tag, platform, ref) { dispatch( actions.promoteBuild(tag, platform, ref) ) },
        deleteTag: function (tag) { dispatch( actions.deleteTag(tag) )},
        deleteBuild: function (tag, platform, version, ref) { dispatch( actions.deleteBuild(tag, platform, version, ref) )},
        cleanUpBuilds : function () { dispatch( actions.cleanUpBuilds() ) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Builds);
