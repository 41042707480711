import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';
import Today from 'material-ui/svg-icons/action/today';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import * as hlp from '../../utils/helpers';

// Pull down coaching assignments
// IF none -- show assignment form
// If assignment in progress -- show when assigned - time lapsed and buttons to revoke, remind
// If 1 or more completed assignments but none in progress, show assignment form and list of completed coaching assignments
// (when assigned and time lapsed until completion)

function getTimePassedPhrase(elapsed) {
    let timePassed = (elapsed.days ? elapsed.days + " Day" + (elapsed.days > 1 ? "s " : " ") : "") + 
                        (elapsed.hours ? elapsed.hours + " Hour" + (elapsed.hours > 1 ? "s " : " ") : "") +
                        (elapsed.minutes ? elapsed.minutes + " Minute" + (elapsed.minutes > 1 ? "s " : " ") : "");
    // Only show seconds if all we have is seconds
    timePassed = timePassed || (elapsed.seconds ? elapsed.seconds + " Second" + (elapsed.seconds > 1 ? "s" : "") : "");
    return timePassed;
}

let liveAssignments = {};

class AssignmentRow extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listenToAssignment(this.props.assignmentId);
    }

    render() {
        const p = this.props;
        const now = Date.now() + p.util.offset;
        const assignmentStore = this.props.assignments.data[this.props.assignmentId];

        if (assignmentStore && assignmentStore.hasReceivedData && assignmentStore.value) {
            const assignment = assignmentStore.value;
            console.log("ASSIGNMENT", assignment, assignment.assigned)
            const assignedAt = new Date(assignment.assigned).toLocaleString()
            let elapsed = hlp.getDaysHoursMinutesAndSeconds(assignment.assigned, now);
            if (!assignment.completed) {
                return <div className="form-outer-box" key={p.assignmentId}><div className="form-box-top">
                    { assignment.surveyDescription ?  assignment.surveyDescription : null }</div>
                    <div className="form-box">
                        <div className="row">
                            <div className="col-sm-5" style={{fontSize: "80%", position: "relative"}}>
                                <div disabled={true} style={{cursor: "pointer", position: "absolute", top: "-1px", left: "-11px"}}><IconButton tooltip={assignedAt} touch={true} tooltipPosition="top-center">
                                    <Today />
                                </IconButton></div>
                                <div style={{padding:"10px 0px 5px 20px"}}>Assigned {getTimePassedPhrase(elapsed)} ago.</div>
                            </div>
                            <div className="col-sm-4">
                                <RaisedButton
                                    onClick={() => {
                                        p.sendReminder(p.assignmentId);
                                    }}
                                    style={{margin: "10px 0 5px"}}
                                    label="Remind" />
                            </div>
                            <div className="col-sm-3">
                                <RaisedButton
                                    onClick={() => { p.cancelAssignment(p.assignmentId) } }
                                    label="Cancel"
                                    style={{margin: "10px 0 5px"}}
                                    secondary={true} />
                            </div>
                        </div>
                    </div>
                </div>
            } else {
                if (p.showPrevious) {
                    const completedAt = new Date(assignment.completed).toLocaleString()
                    elapsed = hlp.getDaysHoursMinutesAndSeconds(assignment.assigned, assignment.completed);
                    return <div style={{padding:"3px", borderBottom: "1px solid #999"}}><div className="row" style={{fontSize: "75%", color: "#667"}}>
                        <div className="col-sm-12" style={{fontWeight: "bolder"}}> { assignment.surveyDescription ?  assignment.surveyDescription : null } </div>
                        <div className="col-sm-4"> Assigned: {assignedAt} </div>
                        <div className="col-sm-4"> Completed: {completedAt} </div>
                        <div className="col-sm-4"> Elapsed: {getTimePassedPhrase(elapsed)} </div>
                    </div></div>
                }
                return null;
            }
        }

        return <div>Loading...</div>

    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        assignments : appState.assignments,
        util: appState.util,
    }
}

const mapDispatchToProps = function(dispatch) {
    return {

        sendReminder: function (assId) {
            dispatch( actions.sendAssignmentReminder(assId) )
        },
        cancelAssignment: function (assId) {
            console.log("dispatching cancel assignment action");
            dispatch( actions.cancelAssignment(assId) )
        },
        listenToAssignment: function (assignmentId) {
            dispatch( actions.startListeningToAssignment(assignmentId) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentRow);

