/*
This is our top-level component. Sub-components matching specific routes will be
contained in `this.props.children` and rendered out.
*/

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SuperBar from './components/superbar';



class Wrapper extends Component {
    
    render () {
        
        const lightMuiTheme = getMuiTheme(lightBaseTheme);

        return (
            <MuiThemeProvider muiTheme={lightMuiTheme}>
                <div className={window.setFullHeight ? "full-height" : ""}>
                    <SuperBar />
                    {this.props.children}
                </div>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    }
}

export default connect(mapStateToProps)(Wrapper);
