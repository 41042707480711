import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SubmitButton extends Component {
    constructor(props, context) {
        super(props, context);
        this.displayName = 'SubmitButton';
    }

    render () {
        return (
            <div>
                <RaisedButton
                    primary
                    disabled={!this.context.isFormValid()}
                    label={this.props.label}
                    type="submit"
                    style={{ width: "100%", marginTop: "15px" }}
                    onTouchTap={this.context.submit} />
            </div>
        )
    }
}

SubmitButton.propTypes = {
    label: PropTypes.string
}

SubmitButton.contextTypes = {
    isFormValid: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
}

SubmitButton.defaultProps = {
    label: 'Submit'
}

export default SubmitButton;
