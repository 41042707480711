import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import * as perms from "../utils/permission";
// import DriverDash from "./components/driverdash";
import ManagerDash from "./components/managerdash";
import CPTDash from './components/cptdash';
import Dash from "./dash";
import RollUps from './components/rollups';
import SuperDash from "./components/superdash";
import WelcomeDash from "./components/welcomedash";
import AddOdpsCustomer from "./odps/addcustomer";


class Dashboard extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        }
    }

    handleClose () {
        this.props.setUserAsNotNew(this.props.auth.uid);
        this.setState({open: false});
    };

    componentWillMount () {

        if (__ODPS__) {
            if (this.props.auth.brandNew) {
                this.setState({open: true});
            }
        } else {
             this.goToDashIfOwner(this.props.auth);
        }
        
    }

    componentWillReceiveProps (nextProps) {
        if (!__ODPS__) {
            this.goToDashIfOwner(nextProps.auth);
        }
    }

    goToDashIfOwner (auth) {
        const { router } = this.context
        if ((auth.isOwner && auth.scoringMethod) && !auth.fakeDriver) {
            router.replace('/dash');
        }
    }

    render () {
        // const p = this.props;
        // const auth = p.auth;
        // const isCourtCompliance = auth.featureFlags && auth.featureFlags.COURT_COMPLIANCE;
        // const isReadyAssessHome = auth.featureFlags && auth.featureFlags.READY_ASSESS_HOME;
        // const isDriver = auth.gid == 'driver';
        // const superFakingDriver = auth.fakeDriver;

        // if ((isDriver || superFakingDriver) && isCourtCompliance) {
        //     return <CourtComplianceDash />
        // }

        if (!this.props.auth.scoringMethod) {
            return <div>Loading...</div>
        }
        // const ManagerSection = perms.manager(Dash);
        const ManagerSection = perms.manager(ManagerDash);
        const SuperSection = perms.spr(SuperDash);
        /*
        The dashboard will look different for users with different perms
        Base case: Driver (all users can view their assessments results)
        Next up: Manager: will see a list of his/her drivers
        Finally: Super: will have links to app management pages
        */
        const SuperRollups = perms.spr(RollUps);

        let dash = (
            <div>
                <CPTDash />
                <WelcomeDash />
                <ManagerSection />
                
            </div>
        )

        if (__ODPS__) {

            let welcome = null;

            if (this.props.auth.brandNew) {

                const actions = [
                    <FlatButton
                        label="Got it!"
                        primary={true}
                        onClick={this.handleClose.bind(this)} />,
                ];
                

                welcome =   <Dialog
                                title="Welcome!"
                                actions={actions}
                                modal={true}
                                open={this.state.open} >
                                    <div>You are now registered.</div>
                                    <div>Please, remember the e-mail and password that you registered with.</div>
                                    <div>{"You'll use those credentials to login to the simulators."}</div>
                                    <div>The form on this page allows you to add new customer IDs to the system.</div>
                                    <div>When a customer sits down at a simulator to take a test,</div>
                                    <div>{"they'll need to enter their valid customer ID to start."}</div>
                            </Dialog>
            }

            dash = (
                <div>
                    {welcome}
                    <AddOdpsCustomer />
                    <SuperRollups />
                </div>
            )
        }

        return (
            <div>
                {dash} 
            </div> 
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setUserAsNotNew: function (uid) { dispatch( actions.setUserAsNotNew(uid) )}
    }
}

Dashboard.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);