
import React, { Component } from 'react';
import { connect } from 'react-redux';




class Blank extends Component {

    render () {
        

        return (

            <div>

            </div> 
                
        )
    }
}


const mapStateToProps = function (appState) {
    return {}
}

const mapDispatchToProps = function(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Blank);
