import { C } from '../../constants';

const initialState = []

export default (state = initialState, action) => {
    switch (action.type) {
        case C.RECEIVE_FEATURE_FLAGS:

            return action.flags;
        default:
            return state;
  }
}