import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedUserData: false,
  data: {},
  states: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
      case C.RECEIVE_USERS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedUserData: true
        });

      case C.RECEIVE_USER_DATA:
          let entry = {};
          entry[action.key] = action.data;
          return Object.assign({}, state, {
            data: Object.assign({}, state.data, entry)
          });


      default:
        return state;
  }
}