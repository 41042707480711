import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  submitting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {


    case C.SUBMITTING_COACHING:
        return Object.assign({}, state, {
            submitting: true
        })

    case C.COACHING_SUBMISSION_RECEIVED:
        return Object.assign({}, state, {
            submitting: false
        })

      default:
        return state;
  }
}