import { C } from '../../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedLocData: false,
  submittingnew: false,
  data: {},
  states: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_LOCS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedLocData: true
        });

    case C.AWAIT_NEW_LOC_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_LOC_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

      default:
        return state;
  }
}