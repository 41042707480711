import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import Driver from './driver';


/*
The list of assessment ids is pulled down when the user logs in
We need to display the list -- each drive will listen to its own state
*/

class MyDrivers extends Component {
    //initial display
    componentWillMount () {
        //myDriversSort contains the sorting state (hasBeenSorted, reverse, rows, and sortBy)
        //you know, all the things that are useful for sorting this auth's drivers
        const sortState = this.props.myDriversSort;
        console.log("sortState: ", sortState)

        if (sortState.hasBeenSorted) {
            store.dispatch( actions.initSortDrivers( sortState.sortBy, !sortState.reverse ) );
        } else {
            store.dispatch( actions.initSortDrivers( sortState.sortBy, sortState.reverse ) );
        }
    }

    render () {
        const p = this.props;
        const sortState = this.props.myDriversSort;

        //map the rows prop (which is an array of uids) to an array of <Driver/> components
        let rows=sortState.rows.map(uid => <Driver key={uid} uid={uid}/>);

        return (

            <div>
                <div style={{textAlign: "right", fontSize: "80%"}}>
                    <div style={{textAlign: "left", width: "220px", display: "inline-block"}}>
                    <SelectField
                        floatingLabelText="Sort By"
                        value={sortState.sortBy}
                        fullWidth={true}
                        onChange={(function (event, index, value) { p.sortDriversBy(value, sortState.reverse) }).bind(this)}>
                        <MenuItem value={"lastname"} primaryText={"Last Name"} />
                        <MenuItem value={"firstname"} primaryText={"First Name"} />
                        <MenuItem value={"dateAdded"} primaryText={"Date Added"} />
                        <MenuItem value={"lastTime"} primaryText={"Last Assessment"} />
                    </SelectField>
                    </div>
                </div>
                <div>
                    {rows}
                </div>
            </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        myDriversSort: appState.myDriversSort,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        // sortByName : function () {
        //     dispatch( actions.sortByName() )
        // },
        sortDriversBy : function (sortBy, reverse) {
            dispatch( actions.sortDriversBy(sortBy, reverse) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDrivers);