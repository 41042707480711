import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Dropzone from 'react-dropzone';
import Paper from 'material-ui/Paper';


const validate = values => {
    const errors = {}
    if (!values[ "name" ]) {
        errors[ "name" ] = 'Required'
    } 

    if (!values[ "description" ]) {
        errors[ "description" ] = 'Required'
    }

    return errors
}

const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzoneFile"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Drag/drop file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class FileForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        if (this.props.files.uploading) {
            return (

                <div>
                    <div>Progress: {this.props.files.progress.toFixed(2)}%</div>
                    <div>Total Bytes: {this.props.files.totalBytes} - Bytes Transferred: {this.props.files.bytesTransferred}</div>
                    <CircularProgress />
                </div>

                    );

            
        }

        return (

            <Paper style={{margin: 40, padding: 30}}>
                <form
                    onSubmit={this.props.onSubmit}>
                    <div className="row">
                        <div className="col-sm-3">
                            <Field
                                name="name"
                                hintText="Enter a name for this file for customers"
                                floatingLabelText="Name/Title"
                                autoComplete="off"
                                fullWidth={true}
                                component={TextField} />
                        </div>
                        <div className="col-sm-9">
                             <Field
                                name="description"
                                hintText="Enter a description of this file for customers"
                                floatingLabelText="Description"
                                multiLine={true}
                                fullWidth={true}
                                rows={3}
                                component={TextField} />
                        </div>
                        <div className="col-sm-12">
                            <label htmlFor="file">File</label>
                            <Field
                                name="file"
                                component={renderDropzoneInput} />
                        </div>
                        <div style={{textAlign: 'right'}} className="col-sm-12">
                            <RaisedButton
                                primary
                                disabled={this.props.pristine || !this.props.valid}
                                label="Upload File"
                                style={{marginTop: 25}}
                                type="submit" />
                        </div>
                    </div>
                </form>
            </Paper>
            
        )
    }
}


// Decorate the form component
FileForm = reduxForm({
  form: 'file' ,// a unique name for this form
  validate
})(FileForm);



export default FileForm;