
import _ from 'lodash';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddIntakeOptionForm from './components/addintakeoptionform';


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToCoachingItems() );
    store.dispatch( actions.startListeningToCoachingSurveys() );
    store.dispatch( actions.startListeningIntakeSurveyOptions() );
    store.dispatch( actions.startListeningToOrgs() );
});

class IntakeSurveyOptions extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount CoachingPageAdmin");
        startListening();
    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;

        console.log("rendering intake survey options admin page");

        const items = p.coachingItems;
        const surveys = p.coachingSurveys;
        const intakeSurveyOptions = p.intakeSurveyOptions;

        let liveIntakeOptions = [];

        if (p.orgs && p.orgs.hasReceivedOrgData) {
            _.map(p.orgs.data, (org, orgId) => {
                if (org.intakeOptions) {
                    _.map(org.intakeOptions, (option, index) => {
                        // assuming (for the moment) that intakeOptions is just a list of intake option IDs
                        liveIntakeOptions.push(option);
                    })
                }
            })
        }

        const intakeSurveyOptionRows = _.map(intakeSurveyOptions.data, (iso, isoId) => {

            return <div key={isoId} className="row q-row">
                <div className="col-sm-2">{iso.name}</div>
                <div className="col-sm-3">{iso.displayText}</div>
                <div className="col-sm-3">{iso.instructionText}</div>
                <div className="col-sm-2">{iso.survey}</div>
                <div className="col-sm-1">{iso.surveySelectQuestion}</div>
                <div className="col-sm-1">
                    <IconButton
                        disabled={!!iso.orgs || liveIntakeOptions.indexOf(isoId) >= 0}
                        onClick={ function () { p.deleteCoachingSurvey(isoId, iso) }}
                        tooltip={!!iso.orgs || liveIntakeOptions.indexOf(isoId) >= 0 ? "Intake Survey Option is being used, Cannot Delete." : "Delete Intake Survey Option"}>
                        <Delete className="q-trash" />
                    </IconButton>
                </div>
            </div>
        })

        const rows = <div>
            <div className="q-rows">
                <div className="row q-row">
                    <div className="col-sm-2">Name</div>
                    <div className="col-sm-3">Display</div>
                    <div className="col-sm-3">Instruction</div>
                    <div className="col-sm-2">Survey</div>
                    <div className="col-sm-1">Survey Select</div>
                    <div className="col-sm-1">Delete</div>
                </div>
                {intakeSurveyOptionRows}
            </div>
        </div>

        const noQs = <div> No intake survey options exist </div>
        

        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <AddIntakeOptionForm
                            className="add-io-form"
                            surveys={p.coachingSurveys}
                            items={p.coachingItems}
                            onSubmit={(function (e) {e.preventDefault(); this.props.addIntakeSurveyOption(); }).bind(this) }>
                        </AddIntakeOptionForm>


                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        { intakeSurveyOptionRows.length ? rows : noQs }
                    </div>
                </div>
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        coachingItems: appState.coachingItems,
        coachingSurveys: appState.coachingSurveys,
        intakeSurveyOptions: appState.intakeSurveyOptions,
        orgs: appState.orgs,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addIntakeSurveyOption: function () {
            dispatch( actions.addIntakeSurveyOption() )
        },
        deleteIntakeSurveyOption: function (isoId, iso) {
            dispatch( actions.deleteIntakeSurveyOption( isoId, iso ) )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(IntakeSurveyOptions);
