import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import moment from 'moment';

import CheckBox from 'material-ui/svg-icons/toggle/check-box';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import ThumbUp from 'material-ui/svg-icons/action/thumb-up';
import Warning from 'material-ui/svg-icons/alert/warning';

import ReadyAssessLogo from '../assets/images/Ready-Assess-Logo.png';
import DxdLogo from '../assets/images/DiagnosticDrivingLogo.png';
import Title from '../assets/images/CoverPage-Graphic.png';

import AggressiveSpeeding from '../assets/images/AggressiveSpeeding.png';
import DrivingOffCourse from '../assets/images/DrivingOffCourse.png';
import DrivingStraightTooFast from '../assets/images/DrivingStraightTooFast.png';
import DrivingThroughRedlight from '../assets/images/DrivingThroughRedlight.png';
import DrivingThruStopSign from '../assets/images/DrivingThruStopSign.png';
import DrivingTooSlow from '../assets/images/DrivingTooSlow.png';
import ExcessiveLaneDeviation from '../assets/images/ExcessiveLaneDeviation.png';
import ExcessiveWeavingLane from '../assets/images/ExcessiveWeavingLane.png';
import FollowingVehicles from '../assets/images/FollowingVehicles.png';
import HardBraking from '../assets/images/HardBraking.png';
import JerkyVehicleControl from '../assets/images/JerkyVehicleControl.png';
import ManagingIntersections from '../assets/images/ManagingIntersections.png';
import NegotiatingCurves from '../assets/images/NegotiatingCurves.png';
import NotFollowingInstructions from '../assets/images/NotFollowingInstructions.png';
import NotPullingOverAmbulance from '../assets/images/NotPullingOverAmbulance.png';
import NotStoppingForBus from '../assets/images/NotStoppingForBus.png';
import NotUsingTurnSignals from '../assets/images/NotUsingTurnSignals.png';
import NotYielding from '../assets/images/NotYielding.png';
import NotYieldingPedestrians from '../assets/images/NotYieldingPedestrians.png';
import OtherAreasofDriving from '../assets/images/OtherAreasofDriving.png';
import OverAdjustingSteering from '../assets/images/OverAdjustingSteering.png';
import OvertakingVehicle from '../assets/images/OvertakingVehicle.png';
import ScanForHazards from '../assets/images/ScanForHazards.png';
import SchoolZoneSpeedLimit from '../assets/images/SchoolZoneSpeedLimit.png';
import SpeedingConstructionZone from '../assets/images/SpeedingConstructionZone.png';
import UnsafeFollowing from '../assets/images/UnsafeFollowing.png';
import CrashBang from '../assets/images/1f4a5.png';
import Worried from '../assets/images/1f6a9.png';

import NJMLogo from '../assets/images/CHoP/NJM-Insurance-Group_RGB.png';
import CHoPLogo from '../assets/images/CHoP/CHOP_HORIZ_RGB.png';


import Apology from './apology';

import {red500, greenA200} from 'material-ui/styles/colors';

class WarningBadge extends Component {
    render () {
        if (this.props.show) {
            return <div className="badge_box"><div className="badge"><Warning style={{width: 36, height: 36}} color="#B02C19" className="danger-icon" /></div></div>
        }
        return null;
    }
}

const TITLE_PAGE_LOGOS = {
    '-MWNAja4yGMW2rkzBXuW' : CHoPLogo,
    '-MUyDFYzNdrR2iUw5Auz': CHoPLogo,
    '-Mvu8VYmhIlLdXyT9_fs': CHoPLogo,
    '-LRmsgEgBT3xKmdWFuwq': CHoPLogo,
    '-LeYGPgCRXXPlNy7hjTr': CHoPLogo
}

const PAGE_3_LOGOS = {
    '-MWNAja4yGMW2rkzBXuW' : NJMLogo,
    '-MUyDFYzNdrR2iUw5Auz': NJMLogo,
    '-Mvu8VYmhIlLdXyT9_fs' : NJMLogo,
    '-LRmsgEgBT3xKmdWFuwq': NJMLogo,
    '-LeYGPgCRXXPlNy7hjTr': NJMLogo
}

const NJM_COPY = "This personalized feedback report was made possible by a generous grant from NJM Insurance Group, a leading auto insurer and teen driver safety advocate in the Mid-Atlantic Region.";

const PAGE_3_CUSTOM_COPY = {
    '-MWNAja4yGMW2rkzBXuW' : NJM_COPY,
    '-MUyDFYzNdrR2iUw5Auz': NJM_COPY,
    '-Mvu8VYmhIlLdXyT9_fs': NJM_COPY,
    '-LRmsgEgBT3xKmdWFuwq': NJM_COPY,
    '-LeYGPgCRXXPlNy7hjTr': NJM_COPY
}

const SCENARIOS = [
    {
        id: 'following', 
        desc: 'Following other vehicles (rear-end)', 
        title: 'Following Vehicles', 
        example: 'Rear-end crash',
        suggestions : [
            'Maintaining a safe following distance',
            'Anticipating vehicle in front of you stopping suddenly',
            'Practicing safe speed management'
        ]
    },
    {id: 'curves', desc: 'Negotiating curves (driving off the road)'},
    {id: 'straightfast', desc: 'Driving straight & too fast for conditions (driving off the road)'},
    {id: 'intersections', desc: 'Managing intersections with traffic (turn & timing-related collisions)'},
    {id: 'other', desc: 'Other areas of the drive (beyond the four scenarios)'}
]


function getFirstName(iData) {
    let ir = iData.responses;
    return ir && (ir['first_name>>first_name'] || ir['Name>>first_name'] || ir['first_name_last_name>>first_name'] || ir['firstName_lastName>>first_name']);
}

function getLastName(iData) {
    let ir = iData.responses;
    //    lastName : "getAnswerMulti(intake, ['last_name_lowercase>>last_name_lowercase', 'last_name>>last_name', 'Name>>last_name_initial', 'first_name_last_name>>last_name', 'firstName_lastName>>last_name']) as lastName",
    return ir && (ir['last_name_lowercase>>last_name_lowercase'] || ir['last_name>>last_name'] || ir['Name>>last_name_initial'] || ir['first_name_last_name>>last_name'] || ir['firstName_lastName>>last_name']);
}

const MONTHS = 'January,February,March,April,May,June,July,August,September,October,November,December'.split(',');

function formatStart(st) {
    if (st) {
        let start = new Date(st);
        return MONTHS[start.getMonth()] + ' ' + start.getDate() + ', ' + start.getFullYear(); 
    }
    return '';
}

function getAgeCategoricalString(age) {
    if (age == -1000) {
        return "Under 18";
    }
    if (age == -2000) {
        return "18 or over";
    }
    return "";
}

function isAgeCategorical(age) {
    return age == -1000 || age == -2000;
}

function getAge(aData, iData) {

    // Ohio2020-26>>Ohio2020-26

    let born = iData.responses && iData.responses['Ohio2020-26>>Ohio2020-26'];
    console.log('getAge', born, aData.start);
    if (born && aData.start) {
        let birthdate = moment(parseInt(born));
        let testDate = moment(aData.start);
        return testDate.diff(birthdate, 'years');
    }

    let ageCat = iData.responses && iData.responses['age_categorical_AGECAT>>age_categorical_AGECAT'];
    if (ageCat) {
        if (ageCat == 'va1') {
            return -1000;
        }
        if (ageCat == 'va2') {
            return -2000;
        }
    }

    return -1;

}

function getStatus(iData) {

    let licenseStatus = iData.responses && iData.responses['license_status>>license_status'];

    if (licenseStatus) {
        return {'_1' : 'Valid License', '_2' : 'Suspended/Revoked License', '_3' : 'Learner Permit', '_4' : 'None'}[licenseStatus];
    } else {

        if (iData.survey) {
            switch(iData.survey) {
                case '-MXcGNqZVAfyT7DIuM2R':
                case '-MuMR-4O4USzdSma6oOc':
                case '-Mo5f4YeUi3NsiCCmaGc':
                    return 'Valid License';

                case '-MXcI4-ls5zC7LMHuWA-':
                case '-MuMY0_7E_WF06tos1uh':
                case '-Mo5gdfLT-ja6OU-fki2':
                    return 'Suspended/Revoked License';

                case '-MXcJQBceriKZfy401yB':
                case '-MuMZAKe0CUUJU0iE1MI':
                case '-Mo5hxWBMgo9VzfQBeHX':
                    return 'Learner Permit';

                case '-MXcJoqVXbLrQB3zNEwX':
                case '-MuM_0GA1ARyR3JRZroI':
                case '-Mo5k2JZzjRq-dVx8eQm':
                    return 'None';

                default:
                    return '';
            }
        }

    }

    
    return ''
}

function getOrgName(aData, orgs) {
    return aData.organization && orgs.data && orgs.data[aData.organization] && orgs.data[aData.organization].name;
}

function getLoc(aData, orgs) {
    let org = getOrgName(aData, orgs);
    return (org ? org : '') + (aData.location ? (org ? ': ' : '') + aData.location : '');
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function collectFeedbackData(aData, iData, orgs) {

    aData.feedbackData.infractions_va0 = aData.feedbackData.infractions_va0 || [];
    aData.feedbackData.infractions_va1 = aData.feedbackData.infractions_va1 || [];
    aData.feedbackData.infractions_va2 = aData.feedbackData.infractions_va2 || [];

    let first = getFirstName(iData);
    let last = getLastName(iData);

    return {
        name : capitalize(first) + (last ? " " + capitalize(last) : ""),
        date: formatStart(aData.start),
        age: getAge(aData, iData),
        status: hlp.determineLicenseStatus(aData, null, '', iData),
        loc: getLoc(aData, orgs),
        results: aData.feedbackData
    }
}

class Infraction extends Component {
    render () {
        return  <div className="row">
                    <div className="col-sm-5 centr">
                        <div className="dtl-tbl-cell">
                            <div className="sdi-cell">
                                <div className="col-sm-5 sdicon"><img className="dtl-img spact" alt="" src={this.props.icon} /></div>
                                <div className="col-sm-7 sdinfo">{this.props.desc}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="dtl-tbl-cell thngs sdthngs">
                            <div className="thngs-hd">TIP</div>
                            <div className="thngs-cntnt">
                                {this.props.tip}
                            </div>
                        </div>
                    </div>
                </div>
    }
}

const VIDEO_MAP = {
    rightOfWay : <a target="_blank" href="https://www.youtube.com/watch?v=gCla_EVlEDE">Right of Way</a>,
    controlledIntersections : <a target="_blank" href="https://www.youtube.com/watch?v=8JWc4agC-7w&t=13s">Controlled Intersections</a>,
    managingIntersections : <a target="_blank" href="https://www.youtube.com/watch?v=dyI33CV_hf4&t=34s">Managing Intersections</a>,
    scanning: <a target="_blank" href="https://www.youtube.com/playlist?list=PLamT53otBDn53210FmuY_aG0FgEvYeT2b">All About Scanning, Identifying, and Reacting to Dangerous Situations</a>,
    spaceCushion1: <a target="_blank" href="https://www.youtube.com/watch?v=ye-zGn9agys&t=32s">Space Cushion</a>,
    spaceCushion2: <a target="_blank" href="https://www.youtube.com/watch?v=T2wOTgz_cJk">Space Cushion on Four Sides</a>,
    distanceEstimation: <a target="_blank" href="https://www.youtube.com/watch?v=DPYoO46GGOk&t=2s">Distance Estimation</a>,
    speedMgmt: <a target="_blank" href="https://www.youtube.com/playlist?list=PLamT53otBDn7od1y1MHXLyc3mm2YDvdhS">All About Speed Management</a>,
    crosswalks: <a target="_blank" href="https://www.youtube.com/watch?v=ldxIRUBxCjk">Intersections with Crosswalks</a>,
    laneChanging: <a target="_blank" href="https://www.youtube.com/watch?v=1j4b55v4KJ8&t=30s">Lane Changing</a>,
    enteringExiting: <a target="_blank" href="https://www.youtube.com/watch?v=mdstrgH2ETU&t=35s">Entering and Exiting</a>,
    steeringWheel: <a target="_blank" href="https://www.youtube.com/watch?v=eAIbwVE9mG0&list=PLamT53otBDn4O56FljV_jF6hl-ADZTF_k&index=3">Adjusting the Steering Wheel</a>,
    turning: <a target="_blank" href="https://www.youtube.com/watch?v=1zz6ml4YSew">Turning</a>,
    drivingStraight: <a target="_blank" href="https://www.youtube.com/watch?v=LqZ6GFpRGbE&t=10s">Driving Straight</a>,
    mirrors: <a target="_blank" href="https://www.youtube.com/watch?v=fz0HBjt7b_o">Adjusting the Mirrors</a>
}

const VIDS_PER_INFRCT = {
    stop_sign_infraction :          [
                                        'rightOfWay',
                                        'controlledIntersections',
                                        'managingIntersections'
                                    ],
    red_light_infraction :          [
                                        'rightOfWay',
                                        'controlledIntersections',
                                        'managingIntersections'
                                    ],
    navigation_infraction :         [
                                        'scanning'
                                    ],
    unsafe_follow_infraction :      [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'speedMgmt'
                                    ],
    school_zone_infraction :        [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    crosswalk_infraction_va0 :      [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    crosswalk_infraction_va1 :      [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    crosswalk_infraction_va2 :      [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    school_bus_infraction :         [
                                        'scanning'
                                    ],
    ambulance_infraction :          [
                                        'scanning'
                                    ],
    yielding_infraction :           [
                                        'laneChanging',
                                        'enteringExiting',
                                        'speedMgmt'
                                    ],
    construction_infraction :       [
                                        'scanning',
                                        'speedMgmt'
                                    ],
    aggro_speed_infraction_va0 :    [
                                        'speedMgmt'
                                    ],
    aggro_speed_infraction_va1 :    [
                                        'speedMgmt'
                                    ],
    aggro_speed_infraction_va2 :    [
                                        'speedMgmt'
                                    ],
    too_slow_infraction_va0 :       [
                                        'speedMgmt'
                                    ],
    too_slow_infraction_va1 :       [
                                        'speedMgmt'
                                    ],
    too_slow_infraction_va2 :       [
                                        'speedMgmt'
                                    ],
    over_steer_infraction_va0 :     [
                                        'steeringWheel',
                                        'turning',
                                        'drivingStraight'
                                    ],
    over_steer_infraction_va1 :     [
                                        'steeringWheel',
                                        'turning',
                                        'drivingStraight'
                                    ],
    over_steer_infraction_va2 :     [
                                        'steeringWheel',
                                        'turning',
                                        'drivingStraight'
                                    ],
    weaving_infraction_va0 :        [
                                        'drivingStraight',
                                        'laneChanging'
                                    ],
    weaving_infraction_va1 :        [
                                        'drivingStraight',
                                        'laneChanging'
                                    ],
    weaving_infraction_va2 :        [
                                        'drivingStraight',
                                        'laneChanging'
                                    ],
    jerky_infraction_va0 :          [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'drivingStraight'
                                    ],
    jerky_infraction_va1 :          [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'drivingStraight'
                                    ],
    jerky_infraction_va2 :          [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'drivingStraight'
                                    ],
    off_course_infraction :         [
                                        'speedMgmt',
                                        'scanning',
                                        'turning',
                                        'drivingStraight'
                                    ],
    off_lane_center_infraction_va0 :    [
                                            'drivingStraight',
                                            'laneChanging'
                                        ],
    off_lane_center_infraction_va1 :    [
                                            'drivingStraight',
                                            'laneChanging'
                                        ],
    off_lane_center_infraction_va2 :    [
                                            'drivingStraight',
                                            'laneChanging'
                                        ],
    scanning_infraction :           [
                                        'mirrors',
                                        'scanning'
                                    ]
    }

const INFRACTION_MAP = {
    stop_sign_infraction :          <Infraction
                                        icon={DrivingThruStopSign}
                                        desc={"Driving through a stop sign"}
                                        tip={"Always come to a complete stop at stop signs and scan in all directions for vehicles, pedestrians, or hazards to determine when it is safe to proceed."}/>,
    red_light_infraction :          <Infraction
                                        icon={DrivingThroughRedlight}
                                        desc={"Driving through a red traffic light"}
                                        tip={"Use caution when approaching a traffic light. Never run a red light. Stop on yellow when it is reasonable to do so. Brake slowly and stop completely behind the white stop line or crosswalk if there is one."}/>,
    navigation_infraction :         <Infraction
                                        icon={NotFollowingInstructions}
                                        desc={"Not following the navigational directions"}
                                        tip={"Following turn-by-turn directions is something you often have to do as a driver. If you’re missing turns or often heading the wrong way -- take that as a reminder to refocus on your main job of driving safely."}/>,
    unsafe_follow_infraction :      <Infraction 
                                        icon={UnsafeFollowing}
                                        desc={"Unsafe following distance"}
                                        tip={"Follow the 3-second rule whenever possible. When you leave less space than that between you and another vehicle, you are at greater risk of crashing."}/>,
    school_zone_infraction :        <Infraction
                                        icon={SchoolZoneSpeedLimit}
                                        desc={"School-zone speed limit violation"}
                                        tip={"Remember that speed limits drop on roads near schools during restricted hours (before and after school), for the safety of students walking to and from school. This lower speed limit generally extends for some distance beyond the school."}/>,
    crosswalk_infraction_va0 :      <Infraction
                                        icon={NotYieldingPedestrians}
                                        desc={"Dangerous driving behavior at a pedestrian crosswalk"}
                                        tip={"Pedestrians always have the right-of-way at posted crosswalks. Come to a complete stop when there are pedestrians crossing or about to cross the road."}/>,
    crosswalk_infraction_va1 :      <Infraction
                                        icon={NotYieldingPedestrians}
                                        desc={"Dangerous driving behavior at a pedestrian crosswalk"}
                                        tip={"Pedestrians always have the right-of-way at posted crosswalks. Come to a complete stop when there are pedestrians crossing or about to cross the road."}/>,
    crosswalk_infraction_va2 :      <Infraction
                                        icon={NotYieldingPedestrians}
                                        desc={"Dangerous driving behavior at a pedestrian crosswalk"}
                                        tip={"Pedestrians always have the right-of-way at posted crosswalks. Come to a complete stop when there are pedestrians crossing or about to cross the road."}/>,
    school_bus_infraction :         <Infraction
                                        icon={NotStoppingForBus}
                                        desc={"Not stopping for a school bus"}
                                        tip={"Keep a safe distance from school buses at all times.  Never pass a school bus when it is stopped to drop off or pick up students, when its lights are blinking or when its stop sign is extended. This is true whether or not children are visible near the bus."}/>,
    ambulance_infraction :          <Infraction
                                        icon={NotPullingOverAmbulance}
                                        desc={"Not stopping for an ambulance"}
                                        tip={"Always move to the right for ambulances and other emergency vehicles. If you hear or see one coming up toward you, behind you, or on a nearby crossroad -- move to the right side of the road and come to a complete stop as quickly as it is safe to do so."}/>,
    yielding_infraction :           <Infraction
                                        icon={NotYielding}
                                        desc={"Not yielding for traffic"}
                                        tip={"When entering a main road from a ramp: slow down, watch for traffic, use your mirrors to decide when it is safe to merge, and check your blind spot as a final safety check before merging. When entering a road from a stop sign: stop, watch for traffic, and go when it is safe to do so."}/>,
    construction_infraction :       <Infraction
                                        icon={SpeedingConstructionZone}
                                        desc={"Speeding in a construction zone"}
                                        tip={"Construction zones can be dangerous for you and those working in them. Speed limits are lower in these areas to keep everyone safe. Slow down when you drive through them no matter where they occur and be mindful of special posted speed limits."}/>,
    aggro_speed_infraction_va0 :    <Infraction
                                        icon={AggressiveSpeeding}
                                        desc={"Instance of aggressive speeding"}
                                        tip={"Speed limits exist to keep you and others safe and that’s why they are regularly displayed. Scan for speed limit signs and regularly glance at your speedometer. Stay at or just below the posted speed."}/>,
    aggro_speed_infraction_va1 :    <Infraction
                                        icon={AggressiveSpeeding}
                                        desc={"Instance of aggressive speeding"}
                                        tip={"Speed limits exist to keep you and others safe and that’s why they are regularly displayed. Scan for speed limit signs and regularly glance at your speedometer. Stay at or just below the posted speed."}/>,
    aggro_speed_infraction_va2 :    <Infraction
                                        icon={AggressiveSpeeding}
                                        desc={"Instance of aggressive speeding"}
                                        tip={"Speed limits exist to keep you and others safe and that’s why they are regularly displayed. Scan for speed limit signs and regularly glance at your speedometer. Stay at or just below the posted speed."}/>,
    too_slow_infraction_va0 :       <Infraction
                                        icon={DrivingTooSlow}
                                        desc={"Driving too slowly"}
                                        tip={"Going too slowly can be as dangerous as going too fast. Scan for speed limit signs and regularly glance at your speedometer. Try and stay at or just below the posted speed."}/>,
    too_slow_infraction_va1 :       <Infraction
                                        icon={DrivingTooSlow}
                                        desc={"Driving too slowly"}
                                        tip={"Going too slowly can be as dangerous as going too fast. Scan for speed limit signs and regularly glance at your speedometer. Try and stay at or just below the posted speed."}/>,
    too_slow_infraction_va2 :       <Infraction
                                        icon={DrivingTooSlow}
                                        desc={"Driving too slowly"}
                                        tip={"Going too slowly can be as dangerous as going too fast. Scan for speed limit signs and regularly glance at your speedometer. Try and stay at or just below the posted speed."}/>,
    over_steer_infraction_va0 :     <Infraction
                                        icon={OverAdjustingSteering}
                                        desc={"Over-adjusting your steering"}
                                        tip={"Small changes or corrections to your steering wheel can make a big difference in the direction of the car. Under regular driving conditions, it is better to make small adjustments to the direction of your vehicle."}/>,
    over_steer_infraction_va1 :     <Infraction
                                        icon={OverAdjustingSteering}
                                        desc={"Over-adjusting your steering"}
                                        tip={"Small changes or corrections to your steering wheel can make a big difference in the direction of the car. Under regular driving conditions, it is better to make small adjustments to the direction of your vehicle."}/>,
    over_steer_infraction_va2 :     <Infraction
                                        icon={OverAdjustingSteering}
                                        desc={"Over-adjusting your steering"}
                                        tip={"Small changes or corrections to your steering wheel can make a big difference in the direction of the car. Under regular driving conditions, it is better to make small adjustments to the direction of your vehicle."}/>,
    weaving_infraction_va0 :        <Infraction
                                        icon={ExcessiveWeavingLane}
                                        desc={"Excessive weaving in your lane"}
                                        tip={"It is best to keep your vehicle steady in one lane position, ideally in or near the center."}/>,
    weaving_infraction_va1 :        <Infraction
                                        icon={ExcessiveWeavingLane}
                                        desc={"Excessive weaving in your lane"}
                                        tip={"It is best to keep your vehicle steady in one lane position, ideally in or near the center."}/>,
    weaving_infraction_va2 :        <Infraction
                                        icon={ExcessiveWeavingLane}
                                        desc={"Excessive weaving in your lane"}
                                        tip={"It is best to keep your vehicle steady in one lane position, ideally in or near the center."}/>,
    jerky_infraction_va0 :          <Infraction
                                        icon={JerkyVehicleControl}
                                        desc={"Jerky vehicle control"}
                                        tip={"When it’s not an emergency, first slow your vehicle by gradually taking your foot off the accelerator. When braking, begin with soft pressure and gradually increase it as needed."}/>,
    jerky_infraction_va1 :          <Infraction
                                        icon={JerkyVehicleControl}
                                        desc={"Jerky vehicle control"}
                                        tip={"When it’s not an emergency, first slow your vehicle by gradually taking your foot off the accelerator. When braking, begin with soft pressure and gradually increase it as needed."}/>,
    jerky_infraction_va2 :          <Infraction
                                        icon={JerkyVehicleControl}
                                        desc={"Jerky vehicle control"}
                                        tip={"When it’s not an emergency, first slow your vehicle by gradually taking your foot off the accelerator. When braking, begin with soft pressure and gradually increase it as needed."}/>,
    off_course_infraction :         <Infraction
                                        icon={DrivingOffCourse}
                                        desc={"Driving off the road"}
                                        tip={"When you drive off the edge of the road it is generally because (1) you are going too fast for road conditions or (2) you are distracted and not focusing on the road ahead. Watch your speed and keep your eyes on the road."}/>,
    off_lane_center_infraction_va0 :    <Infraction
                                            icon={ExcessiveLaneDeviation}
                                            desc={"Regularly off center of lane"}
                                            tip={"In most driving conditions, it is safest to stay as close to the center of your lane as possible. Keeping your eyes looking ahead of the vehicle will help you maintain this position."}/>,
    off_lane_center_infraction_va1 :    <Infraction
                                            icon={ExcessiveLaneDeviation}
                                            desc={"Regularly off center of lane"}
                                            tip={"In most driving conditions, it is safest to stay as close to the center of your lane as possible. Keeping your eyes looking ahead of the vehicle will help you maintain this position."}/>,
    off_lane_center_infraction_va2 :    <Infraction
                                            icon={ExcessiveLaneDeviation}
                                            desc={"Regularly off center of lane"}
                                            tip={"In most driving conditions, it is safest to stay as close to the center of your lane as possible. Keeping your eyes looking ahead of the vehicle will help you maintain this position."}/>,
    scanning_infraction :           <Infraction
                                            icon={ScanForHazards}
                                            desc={"Not scanning for potential or hidden hazards"}
                                            tip={"Always scan your surroundings and anticipate when a hazard may enter your path in order to take steps to avoid a crash."}/>,
}

function setupInfractionAndVideoLists(feedbackData) {
    if (feedbackData.age >= 0 || isAgeCategorical(feedbackData.age)) {
        let globalInfractions = [];
        console.group('GLOBAL INFRACTIONS');
        feedbackData.results.infractions_va0 = feedbackData.results.infractions_va0 || [];
        feedbackData.results.infractions_va1 = feedbackData.results.infractions_va1 || [];
        feedbackData.results.infractions_va2 = feedbackData.results.infractions_va2 || [];
        for (var i = feedbackData.results.infractions_va0.length - 1; i >= 0; i--) {
            let infraction = feedbackData.results.infractions_va0[i];
            console.log(i, infraction, infraction.name.indexOf('_va0'));
            if (!(infraction.name.indexOf('_va0') >= 0)) {
                globalInfractions.push(infraction);
            }
        };
        console.groupEnd();
        if (feedbackData.age >= 18 || feedbackData.age == -2000) {
            feedbackData.groupId = 'va2';
            feedbackData.infractions = feedbackData.results.infractions_va2.concat(globalInfractions);
            feedbackData.results.overallPerformScore = feedbackData.results.percentile_va2;
            feedbackData.results.num_infractions = feedbackData.results.num_infractions_va2;
        } else {
            feedbackData.groupId = 'va1';
            feedbackData.infractions = feedbackData.results.infractions_va1.concat(globalInfractions);
            feedbackData.results.overallPerformScore = feedbackData.results.percentile_va1;
            feedbackData.results.num_infractions = feedbackData.results.num_infractions_va1;
        }
    } else {
        feedbackData.groupId = 'va0';
        feedbackData.infractions = feedbackData.results.infractions_va0;
        feedbackData.results.overallPerformScore = feedbackData.results.percentile_va0;
        feedbackData.results.num_infractions = feedbackData.results.num_infractions_va0;
    }

    feedbackData.results.num_infractions = feedbackData.infractions.length;

    let videoFilter = {};
    for (var i = 0; i < feedbackData.infractions.length; i++) {
        let infracky = feedbackData.infractions[i];
        if (infracky) {
            let vids = VIDS_PER_INFRCT[infracky.name];
            if (vids) {
                for (var j = 0; j < vids.length; j++) {
                    videoFilter[vids[j]] = true;
                };
            }
            
        }
        
    };

    videoFilter['scanning'] = true;
    videoFilter['speedMgmt'] = true;

    feedbackData.videos = _.map(videoFilter, (val, key) => {
        return VIDEO_MAP[key];
    })

    console.log("VIDEO DEBUG", videoFilter, feedbackData.videos);

    let infractionPages = [];
    let page = -1;
    for (var i = 0; i < feedbackData.infractions.length; i++) {
        let remainder = i % 6;

        if (!remainder) {
            page++;
        }

        let oddPage = page % 1;
        let oddRow = i % 2;
        let rowClass = "fbr-wht";
        // if (oddPage) {
        //     if (oddRow) {
        //         rowClass = "";
        //     }
        // } else {
        //     if (!oddRow) {
        //         rowClass = "";
        //     }
        // }

        if (!oddRow) {
            rowClass = ""; 
        }
        infractionPages[page] = infractionPages[page] || [];
        let inf = feedbackData.infractions[i];
        infractionPages[page].push(<div key={"infraction-" + i} className={"fbr-wht-" + oddRow}>{INFRACTION_MAP[inf.name]}</div>)
    };
    feedbackData.infractionPages = infractionPages;
}

const COMMON_CRASH_COPY = {
    rear_end_common_crash: {
        short: 'Following other vehicles (rear-end)',
        icon: FollowingVehicles,
        title : 'Following Vehicles',
        example : 'Rear-end crash',
        handledWell : 'Nice work. Always remember to keep a cushion of at least 3 seconds between you and vehicles in front of you.',
        crashed : 'Improper following distance resulted in a bad outcome. Practice maintaining a space cushion of at least 3 seconds between you and vehicles in front of you.',
        danger : 'You didn’t crash or experience a bad outcome, but there were times when your following distance put you in danger of crashing. Practice maintaining a space cushion of at least 3 seconds between you and vehicles in front of you.',
    },
    curve_common_crash: {
        short: 'Negotiating curves (driving off the road)',
        icon: NegotiatingCurves,
        title : 'Negotiating Curves',
        example : 'Driving off the road',
        handledWell : 'Good job. Continue to work on slowing your vehicle gradually as you enter a curve, and on keeping your eyes on the road ahead so you know how much of the curve remains.',
        crashed : 'Curves can be tricky. To avoid going off the road or similar bad outcomes while driving through a curve: practice slowing your vehicle gradually as you enter a curve, and keep your eyes on the road ahead to better understand how much of the curve remains.',
        danger : 'You didn’t run off the road or experience a bad outcome in a curve, but you were at risk of doing so. Curves can be tricky. To avoid going off the road while driving through a curve: practice slowing your vehicle gradually as you enter a curve, and keep your eyes on the road ahead to better understand how much of the curve remains.',
    },
    too_fast_common_crash: {
        short: 'Driving straight & too fast for conditions (driving off the road)',
        icon: DrivingStraightTooFast,
        title : 'Driving Straight and Too Fast',
        example : 'Speeding on straightaways',
        handledWell : 'You stayed on the road on all the areas that were not curves. Continue to follow posted speed limits, and always keep your eyes on the road in front of you and regularly scanning.',
        crashed : 'You went off the road in one or more places that were not a curve. Practice keeping your eyes on the road in front of you and regularly scanning. And watch your speed because going too fast can make running off the road more likely.',
        danger : 'You were at risk of driving off the road in one or more places that were not a curve. Practice keeping your eyes on the road in front of you and regularly scanning. And watch your speed because going too fast can make running off the road more likely.',
    },
    manage_intersection_common_crash: {
        short: 'Managing intersections with traffic (turn & timing-related collisions)',
        icon: ManagingIntersections,
        title : 'Managing Intersections',
        example : 'Turn & timing-related collisions',
        handledWell : 'You did well at avoiding crashes or other bad outcomes when turning left at intersections. Remain mindful of traffic lights and stop signs. And don’t forget -- when you make the turn be sure to carefully assess the speed and distance of traffic coming across your path or headed towards you.',
        crashed : 'You crashed or experienced a bad outcome at least once when making a left-turn at an intersection. Pay attention to traffic lights and stop signs. When you turn be sure to carefully assess the speed and distance of traffic coming across your path or headed towards you.',
        danger : 'You didn’t crash or experience a bad outcome when making a left at an intersection, but some of your actions left you vulnerable to a collision. Pay closer attention to traffic lights and stop signs. When you make the turn be sure to carefully assess the speed and distance of traffic coming across your path or headed towards you.',
    },
    other_areas_common_crash: {
        short: 'Other areas of the drive (beyond the four scenarios)',
        icon: OtherAreasofDriving,
        title : 'Other Areas of Drive',
        example : 'Beyond the four scenarios above',
        handledWell : 'Crashes or other bad outcomes can occur at any time. Stay alert to vehicles stopping suddenly in front of you, and to pedestrians and objects that might require you to stop or slow down. Stick to posted speed limits, regularly scan ahead, and maintain proper following distances.',
        crashed : 'Crashes or other bad outcomes can occur at any time. Stay alert to vehicles stopping suddenly in front of you, and to pedestrians and objects that might require you to stop or slow down. Stick to posted speed limits, regularly scan ahead, and maintain proper following distances.',
        danger : 'Crashes or other bad outcomes can occur at any time. Stay alert to vehicles stopping suddenly in front of you, and to pedestrians and objects that might require you to stop or slow down. Stick to posted speed limits, regularly scan ahead, and maintain proper following distances.',
    }
}


function createSampleData() {
    return {
        name: 'Mary Jo Driver',
        date: 'December 20, 2020',
        age: 16,
        status: 'Learner permit',
        loc: 'Anytown Driving School, LLC, 1 A St.',
        results: {
            description: "version 25",
            common_crashes: [
                {
                    name: 'rear_end_common_crash',
                    show_handled_well: true,
                    show_in_crash_column:false,
                    show_in_danger_column:false,
                    show_needs_work:false,
                    show_red_exclamation:false,
                },
                {
                    name: 'curve_common_crash',
                    show_handled_well: false,
                    show_in_crash_column:true,
                    show_in_danger_column:true,
                    show_needs_work:true,
                    show_red_exclamation:true,
                },
                {
                    name: 'too_fast_common_crash',
                    show_handled_well: false,
                    show_in_crash_column:false,
                    show_in_danger_column:true,
                    show_needs_work:true,
                    show_red_exclamation:true,
                },
                {
                    name: 'manage_intersection_common_crash',
                    show_handled_well: false,
                    show_in_crash_column:false,
                    show_in_danger_column:true,
                    show_needs_work:true,
                    show_red_exclamation:true,
                },
                {
                    name: 'other_areas_common_crash',
                    show_handled_well: true,
                    show_in_crash_column:false,
                    show_in_danger_column:false,
                    show_needs_work:false,
                    show_red_exclamation:false,
                },
            ],
            infractions_va0: [
                {name: "stop_sign_infraction"},
                {name: "red_light_infraction"},
                {name: "navigation_infraction"},
                {name: "unsafe_follow_infraction"},
                {name: "school_zone_infraction"},
                {name: "crosswalk_infraction_va0"},
                {name: "school_bus_infraction"},
                {name: "ambulance_infraction"},
                {name: "yielding_infraction"},
                {name: "construction_infraction"},
                {name: "off_course_infraction"},
                {name: "off_lane_center_infraction_va0"},
                {name: "scanning_infraction"}
            ],
            infractions_va1: [
                {name: "crosswalk_infraction_va1"},
                {name: "aggro_speed_infraction_va1"},
                {name: "too_slow_infraction_va1"},
                {name: "over_steer_infraction_va1"},
                {name: "weaving_infraction_va1"},
                {name: "jerky_infraction_va1"},
                {name: "off_lane_center_infraction_va1"},
            ],
            infractions_va2: [
                {name: "off_lane_center_infraction_va2"}
            ],
            num_infractions_va0:11,
            num_infractions_va1:11,
            num_infractions_va2:11,
            num_pedestrian_crashes: 1,
            num_vehicle_crashes: 1,
            percentile_va0: 0.8399450922208705,
            percentile_va1: 0.8399450922208705,
            percentile_va2: 0.827144274380787,
            vdt_error_score: 2
        }
    }
}

function renderScorebar(ctx, score, w, h, bw, y) {
    //oom=23&pm=6&lbld=55
    ctx.clearRect(0, 0, w, h);
    ctx.save();

    const lineStartX = (w - bw) / 2;
    const lineY = 50;
    const pm = 6;
    const om = 8;
    const oom = 23;
    const lineEndX = (lineStartX + bw);
    const lineMidX = (lineStartX + (bw / 2));
    const lbld = 55;


    const you = score ? parseInt(score) / 100 : 0;
    console.log('YOU', you);
    const uX = lineStartX + (you * bw);
    let ulb = 'YOUR SCORE';

    ctx.beginPath();
    ctx.moveTo(lineStartX, lineY);
    ctx.lineTo(lineEndX, lineY);
    ctx.lineWidth = 3;

      // set line color
    ctx.strokeStyle = '#6b2867';
    ctx.stroke();


    ctx.beginPath();
    ctx.arc(lineStartX, lineY, pm, 0, 2 * Math.PI);
    ctx.fillStyle = '#6b2867';
    ctx.fill();
    
    
    ctx.beginPath();
    ctx.arc(lineMidX, lineY, pm, 0, 2 * Math.PI);
    ctx.fillStyle = '#6b2867';
    ctx.fill();

    ctx.beginPath();
    ctx.arc(lineEndX, lineY, pm, 0, 2 * Math.PI);
    ctx.fillStyle = '#6b2867';
    ctx.fill();

    ctx.font = "11px Arial"; 
    ctx.textAlign = "center"; 
    ctx.fillStyle = '#d28c3f';
    ctx.fillText('0%', lineStartX , 20);
    ctx.fillText('50%', lineMidX , 20);
    ctx.fillText('100%', lineEndX , 20);


    if (uX !== null) {
        console.log("DRAW uX!");
        ctx.beginPath();
        ctx.arc(uX, lineY, om, 0, 2 * Math.PI);
        ctx.fillStyle = '#d28c3f';
        ctx.fill();
        ctx.beginPath();
        ctx.arc(uX, lineY, oom, 0, 2 * Math.PI);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#d28c3f';
        ctx.stroke();
        ctx.font = "15px Arial"; 
        ctx.textAlign = "center"; 
        ctx.fillStyle = '#d28c3f';
        ctx.fillText(ulb, uX , lineY + lbld);
    }

    ctx.restore();
}

class FeedbackReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackData : {}
        }
    }

    componentWillMount () {
        if (this.props.params && this.props.params.aid) {
            store.dispatch( actions.startListeningToAssessment(this.props.params.aid) );
        } else {
            store.dispatch(actions.startListeningToStaticFeedback());
        }
        store.dispatch( actions.startListeningToOrgs() );
        // store.dispatch( actions.logBrowserInfo('pfr') );
    }

    componentDidMount() {
        
        
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let p = this.props;
        let aid = p.params && p.params.aid;
        let nowHave = p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData;
        let hadBefore = prevProps.assessments && prevProps.assessments.data && prevProps.assessments.data[aid] && prevProps.assessments.data[aid].hasReceivedData;
        if (nowHave && !hadBefore) {
            let assessmentData = p.assessments.data[aid].value;
            if (assessmentData && assessmentData.intake) {
                store.dispatch( actions.startListeningToIntakeForm(assessmentData.intake))
            }
        }
        const canvas = this.refs.scorebar;

        if (canvas) {
            console.log('yayayayay', canvas);
            const ctx = canvas.getContext('2d');
            let feedbackData = {};
            let assessmentData = {};
            let intakeData = {};
            let go = false;
            if (!aid) {
                if (!p.staticFeedback.empty) {
                    feedbackData = p.staticFeedback;
                    setupInfractionAndVideoLists(feedbackData);
                    go = true;
                }
                
            } else {
                if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
                    assessmentData = p.assessments.data[aid].value;
                    if (assessmentData && assessmentData.feedbackData) {
                        if (assessmentData.intake && p.intakeForms[assessmentData.intake] && p.intakeForms[assessmentData.intake].data) {
                            intakeData = p.intakeForms[assessmentData.intake].data;
                        }

                        feedbackData = collectFeedbackData(assessmentData, intakeData, p.orgs);
                        setupInfractionAndVideoLists(feedbackData);
                        go = true;
                    }

                } 

            }

            
            if (go && feedbackData.results.overallPerformScore) {
                renderScorebar(ctx, feedbackData.results.overallPerformScore * 100, canvas.width, canvas.height, 580);
            }
        }
        
    }

    render () {

        // return <div style={{backgroundColor: '#FFF', width: "200px", height: "200px"}}>test</div>;
        const p = this.props;
        const aid = this.props.params && this.props.params.aid;

        let feedbackData = {};
        let assessmentData = {};
        let intakeData = {};

        if (!p.orgs.hasReceivedOrgData) {
            return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
        }



        if (!aid) {
            if (p.staticFeedback.empty) {
                return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
            }
            feedbackData = p.staticFeedback;
        } else {
            if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
                assessmentData = p.assessments.data[aid].value;
                if (assessmentData) {
                    if (!assessmentData.feedbackData) {
                        console.log('no feedbackData');
                        return <Apology aid={aid} />
                    }
                }
                

                if (p.intakeForms && assessmentData && assessmentData.intake && p.intakeForms[assessmentData.intake] && p.intakeForms[assessmentData.intake].data) {
                    intakeData = p.intakeForms[assessmentData.intake].data;
                }

            } else {
                if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
                    // not loading -- no assessment found
                    // render apology PDF
                    return <Apology aid={aid} />
                }
                return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
            }


            if (assessmentData) {
                feedbackData = collectFeedbackData(assessmentData, intakeData, p.orgs);
            } else {
                return <Apology aid={aid} />
            }
            
            
        }

        setupInfractionAndVideoLists(feedbackData);

        const titlePageLogo = TITLE_PAGE_LOGOS[assessmentData.organization];
        const page3Logo = PAGE_3_LOGOS[assessmentData.organization];
        const page3CustomCopy = PAGE_3_CUSTOM_COPY[assessmentData.organization];

        console.log("OOOOOOOOOOOOOOOOOO", feedbackData);



        // if (!(p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData)) {
        //     return <div>Loading...</div>
        // }

        // viewport is 1125px tall 

        return (
            <div className="fbr">
                <div className="fbr-page-1">
                    <div className="fbr-p1-ra-logo"><img width="460"  alt="" src={ReadyAssessLogo} /></div>
                    <div><img width="800"  alt="" src={Title} /></div>
                    <div className="fbr-p1-deets display-6">
                        <div className="fbr-prepFor">Prepared for <span className="fbr-p1-name">{feedbackData.name || 'you'}</span></div>
                        <div className="fbr-date">{feedbackData.date}</div>
                    </div>
                    {
                        titlePageLogo ?
                        <div style={{width:"80%", margin: "auto"}}>
                            <div className="row">
                                <div className="col-sm-6"><img className="fbr-dxd-logo" style={{width:"100%"}}  alt="" src={DxdLogo} /></div>
                                <div className="col-sm-6"><img className="fbr-dxd-logo" style={{width:"100%"}}  alt="" src={titlePageLogo} /></div>
                            </div>
                        </div> :
                        <div className="fbr-p1-dxd-logo"><img className="fbr-dxd-logo" width="386"  alt="" src={DxdLogo} /></div>
                    }
                    
                </div>
                <div className="fbr-page-2">
                <span className="page-number">1</span>
                    <div className="fbr-flush-top fbr-purp display-7">AN IMPORTANT INTRODUCTION</div>
                    <div className="intro-content">
                        <div className="ic-deets">
                            <div><span className="ic-label">Name: </span><span className="lrgr">{feedbackData.name || ''}</span></div>
                            <div><span className="ic-label">Age: </span><span className="lrgr">{feedbackData.age >= 0 ? (feedbackData.age + ' year' + (feedbackData.age == 1 ? '' : 's')) : (getAgeCategoricalString(feedbackData.age))}</span></div>
                            <div><span className="ic-label">Driver status: </span><span className="lrgr">{feedbackData.status}</span></div>
                            <div><span className="ic-label">Date completed: </span><span className="lrgr">{feedbackData.date || ''}</span></div>
                            <div><span className="ic-label">Location taken: </span><span className="lrgr">{feedbackData.loc}</span></div>
                        </div>
                        <div className="fbr-cntnt">
                            <div className="fbr-sct-hdr">Welcome to your Safe Driving Skills Report.</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    <b>Please read through these first two pages</b> – they will provide you with important background information on the test you’ve just taken and give you useful context for how to think about your results.
                                </p>
                                <p>
                                    <span className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is an educational tool and should not replace the advice of a certified driving instructor. We encourage you to share this report with a driving instructor and/or your guardians. Your results and personalized action plan are detailed in the pages below.
                                </p>
                            </div>
                            <div className="fbr-sct-hdr">Thank you for caring about being a safer driver.</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    Nearly 1-in-5 new drivers get into a crash soon after obtaining their driver’s license and we don’t want that to happen to you.
                                </p>
                                <p>
                                    Developed by scientists from the Children’s Hospital of Philadelphia, <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is a way to measure your driving skills, specifically focusing on the skills you need to avoid crashes. Whether you are preparing to get your license, or already have one, we hope this report provides you with useful information as you continue on your journey of becoming a safer and independent driver.
                                </p>
                            </div>
                            <div className="fbr-sct-hdr">What Does <i>Ready-Assess</i>&#8482; do?</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> specifically tests your skills at avoiding crashes that are highly common and cause injuries to drivers and passengers.
                                </p>
                                <ul>
                                    <li>You may not have realized it, but in the drive you completed we exposed you to common crash scenarios shown to result in serious injuries to drivers like you – scenarios like following other vehicles, handling curves, and managing intersections when there is traffic.</li>
                                    <li>In your feedback you’ll see how you performed compared to over 50,000 other people like you who have completed this exact same test.</li>
                                    <li>You will learn more about where your skills are strong, and where you still have room for improvement.</li>
                                </ul>
 
                            </div>

                        </div>
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>
                <div className="fbr-page-3">
                <span className="page-number">2</span>
                    <div className="fbr-flush-top fbr-purp display-7">AN IMPORTANT INTRODUCTION</div>
                    <div className="intro-content">
                        <div className="fbr-cntnt">
                            <div className="fbr-sct-hdr">Before we continue, let’s first manage some expectations...</div>
                            <div className="fbr-sct-cntnt">
                                <p><span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is not meant to test your skills on the basics of how to operate a vehicle or replicate exactly what it feels like to drive in a real car. We understand that this “drive” actually took place on a computer.</p>
                                <p>You may have felt that <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> was “harder” than you expected. Or perhaps you thought, “How is this testing my skills?” or “Based on this test, I must be a terrible driver.”</p>
                                <p>If you did, don’t worry.</p>
                                <ul>
                                    <li>We know that the steering wheels and pedals were a little more sensitive than you are used to.</li>
                                    <li>Because of that sensitivity you may have felt as if you had a little trouble controlling the car.</li>
                                </ul>
                                <p><b>And that’s OK.</b> Because for this test we are not concerned with how “smoothly” you turned or
how “perfectly” you stayed in the lane. What we really care about is:</p>
                                <ul>
                                    <li>How are your decision-making skills? Are your driving decisions safe, even if you did not experience a crash or other poor outcome?</li>
                                    <li>Do you recognize potential hazards on the road and adjust your driving behaviors in the moment?</li>
                                </ul>
 
                            </div>
                            <div className="hilite">
                                <div className="hilite-box"></div>
                                <p>
                                    Remember, we are comparing your performance to lots of other drivers who completed this very same test. Many of them had the same experiences with this test as you did.
                                </p>
                                <p>
                                    At the end of the day, it’s not about “doing better than everyone else.” It’s about knowing that you always have room for improvement to become an even safer driver and (no pun intended) we are just trying to steer you in the right direction.
                                </p>
                            </div>
                        </div>
                        {
                            PAGE_3_LOGOS[assessmentData.organization] ?
                            <div className="page-3-extra-logo"><img className="page-3-extra-img"  alt="" src={PAGE_3_LOGOS[assessmentData.organization]} /></div> : null
                        }
                        {
                            PAGE_3_CUSTOM_COPY[assessmentData.organization] ?
                            <div className="page-3-custom-content">{PAGE_3_CUSTOM_COPY[assessmentData.organization]}</div> : null
                        }
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>
                <div className="fbr-page-4">
                <span className="page-number">3</span>
                    <div className="fbr-flush-top fbr-orng display-7">SUMMARY OF RESULTS</div>
                    <div className="smry-content">
                        <div className="cntr">
                            <div className="smry-hdr">Your Overall Performance</div>
                            <div className="smry-sbhdr deprecated">Borderline unsafe. Needs work.</div>
                            <div className="smry-expln deprecated">Your performance is slightly below average compared to other drivers in your peer group.</div>
                        </div>
                        <div className="smry-counts display-6">
                            <div className="smry-count"><span>{feedbackData.results.num_vehicle_crashes}</span>&nbsp; Crash{feedbackData.results.num_vehicle_crashes == 1 ? '' : 'es'} with a vehicle</div>
                            <div className="smry-count"><span>{feedbackData.results.num_pedestrian_crashes}</span>&nbsp; Crash{feedbackData.results.num_pedestrian_crashes == 1 ? '' : 'es'} with a pedestrian</div>
                            <div className="smry-count"><span>{feedbackData.results.num_infractions}</span>&nbsp; Driving infraction{feedbackData.results.numInfractions == 1 ? '' : 's'}</div>
                        </div>
                        <div className="smry-score-expln">
                            "Your Score" below shows you how you performed compared to other drivers in your age group who completed the same exact drive. For example, a score of 100% means that you drove safer than all the other drivers in your age group who completed the same exact drive.

                        </div>
                        <div className="scorebar">
                            {

                                true ?

                                <canvas ref="scorebar" width="680" height="130" />

                                :

                                <img src={"https://us-central1-dxdv-dev.cloudfunctions.net/image/scorebar?w=680&bw=600&h=170&u=" + (feedbackData.results.overallPerformScore * 100) + "&line=true&oom=23&pm=6&lbld=55"} />

                            }
                            
                        </div>

                        <div className="picscs">
                            <div className="picscs-hdr">Performance In Common & Serious Crash Scenarios:</div>
                            <div className="picscs-hdrs row">
                                <div className="col-sm-2">SATISFACTORY</div>
                                <div className="col-sm-2">NEEDS WORK</div>
                                <div className="col-sm-8"></div>
                            </div>

                            {

                                (function() {
                                    let rows = [];
                                    for (var i = 0; i < feedbackData.results.common_crashes.length; i++) {
                                        let commonCrash = feedbackData.results.common_crashes[i];
                                        rows.push(<div key={'picscs-' + commonCrash.name + i} className={i % 2 ? "" : "pcs-odd"}>
                                                        <div className={"picscs-row row" + (i % 2 ? " pcs-even" : "")}>
                                                            <div className="col-sm-2">{commonCrash.show_handled_well ? <ThumbUp color="#5F813F" className="pass-icon" /> : ''}</div>
                                                            <div className="col-sm-2">{commonCrash.show_needs_work ? <Warning color="#B02C19" className="danger-icon" /> : ''}</div>
                                                            <div className="col-sm-8">{(i + 1) + '. '}{COMMON_CRASH_COPY[commonCrash.name].short}</div>
                                                        </div>
                                                  </div>)
                                    };
                                    return rows;
                                })()

                            }
                        </div>
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>
                <div className="fbr-page-5">
                <span className="page-number">4</span>
                    <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS</div>
                    <div className="dtl-content">
                        <div className="wshd inr-pd">
                            <div className="cntr hdr-btm-pd">
                                <div className="smry-hdr">Your Detailed Results</div>
                            </div>
                            <div className="dtl-p">
                                <p><b>Avoiding collisions and having the skills to make good decisions in dangerous situations is an important part of being a safe driver.</b> But even when you’ve done many things right, there are always things to keep in mind or work on to be as safe as you can when you’re out on the road.</p>
                                <p>On the next page you’ll see a detailed breakdown of your performance across common crash scenarios – those most in need of work, but also those where you drove with relative safety.</p>
                                <p>You will also see feedback on aspects of driving that will help make you a safer driver, even if they were not related to a specific crash scenario.</p>
                                <p>SECTIONS TO FOLLOW:</p>
                                <ul className="toc-list">
                                    <li>
                                        <dl>
                                            <dt>Detailed feedback on all common crash scenarios</dt>
                                            <dd>Page 5</dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt>Specific driving infractions</dt>
                                            <dd>Page 6</dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt>Safe driving videos selected just for you</dt>
                                            <dd>Page {6 + feedbackData.infractionPages.length}</dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt>Helpful Resources</dt>
                                            <dd>Page {7 + feedbackData.infractionPages.length}</dd>
                                        </dl>
                                    </li>
                                </ul>
                                <div className="clearyclearclear" />
                            </div>
                        </div>
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>
                
                <div className="fbr-page-6">
                <span className="page-number">5</span>
                    <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS</div>
                    <div className="dtl-table wshd">
                        <div className="cntr">
                            <div className="smry-hdr">Performance in Common Crash Scenarios</div>
                        </div>
                        <div className="fbr-wht">
                            <div className="row">
                                <div className="col-sm-3 centr">
                                    <div className="dtl-tbl-hdr"><div className="dtl-inner dtl-cs">Crash Scenario</div></div>
                                </div>
                                <div className="col-sm-2 centr">
                                    <div className="dtl-tbl-hdr"><div className="dtl-inner dtl-cod">Crashing or driving off the road</div></div>
                                </div>
                                <div className="col-sm-2 centr">
                                    <div className="dtl-tbl-hdr"><div className="dtl-inner dtl-ddd">Dangerous driving decision(s)</div></div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="dtl-tbl-hdr"><div className="dtl-inner dtl-stc">Things to consider</div></div>
                                </div>
                            </div>
                        </div>

                        {
                            (function () {
                                let rows = [];
                                for (var i = 0; i < feedbackData.results.common_crashes.length; i++) {
                                    let commonCrash = feedbackData.results.common_crashes[i];
                                    let copy = COMMON_CRASH_COPY[commonCrash.name];
                                    rows.push(<div key={commonCrash.name + i} className={i % 2 ? 'fbr-wht' : ''}>
                                                <div className="row">
                                                    <div className="col-sm-3 centr">

                                                        <WarningBadge show={commonCrash.show_needs_work} />
                                                        <div className="dtl-tbl-cell">
                                                            <div className="infracticon"><img className="dtl-img" alt="" src={copy.icon} /></div>
                                                            <div className="infraction purptext">{copy.title}</div>
                                                            <div className="infradesc">{copy.example}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 centr">
                                                        <div className="dtl-tbl-cell big-num">{commonCrash.show_in_crash_column ? <img className="dtl-img" alt="" src={CrashBang} /> : null}</div>
                                                    </div>
                                                    <div className="col-sm-2 centr">
                                                        <div className="dtl-tbl-cell big-num">{commonCrash.show_in_danger_column ? <img className="dtl-img" alt="" src={Worried} /> : null}</div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        {
                                                            commonCrash.show_handled_well ?

                                                            <div className="dtl-tbl-cell thngs">
                                                                <div className="thngs-hd">SATISFACTORY / KEEP IN MIND</div>
                                                                <div  className="thngs-cntnt">
                                                                    {copy.handledWell}
                                                                </div>
                                                            </div> :

                                                            ( commonCrash.show_in_crash_column ? 
                                                                <div className="dtl-tbl-cell thngs">
                                                                    <div className="thngs-hd">NEEDS WORK</div>
                                                                    <div  className="thngs-cntnt">
                                                                        {copy.crashed}
                                                                    </div>
                                                                </div> :
                                                                <div className="dtl-tbl-cell thngs">
                                                                    <div className="thngs-hd">NEEDS WORK</div>
                                                                    <div  className="thngs-cntnt">
                                                                        {copy.danger}
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                </div>
                                            </div>)
                                };
                                return rows;
                            })()
                        }
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>

                {(function () {

                    let infractionPages = [];

                    let pageIndex = 7;

                    for (var i = 0; i < feedbackData.infractionPages.length; i++) {
                        let theseInfractions = feedbackData.infractionPages[i];
                        infractionPages.push(

                            <div key={"infraction-page-" + i} className={'fbr-page-' + pageIndex}>
                                <span className="page-number">{pageIndex - 1}</span>
                                <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS</div>
                                <div className="dtl-table wshd">
                                    <div className="cntr">
                                        <div className="smry-hdr">Specific Driving Infractions</div>
                                    </div>
                                    <div className="fbr-wht">
                                        <div className="row">
                                            <div className="col-sm-5 centr">
                                                <div className="dtl-tbl-hdr"><div className="dtl-inner dtl-sdi">Specific Driving Infractions</div></div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="dtl-tbl-hdr"><div className="dtl-inner dtl-aif">Tips for Avoiding Them in the Future</div></div>
                                            </div>
                                        </div>
                                    </div>
                                    {theseInfractions}
                                    
                                </div>
                                <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                            </div>

                        )
                        pageIndex++;
                    };

                    return infractionPages;

                })()}

                <div className={"fbr-page-" + (7 + feedbackData.infractionPages.length)}>
                    <span className="page-number">{(7 + feedbackData.infractionPages.length) - 1}</span>
                    <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS</div>
                    <div className="dtl-content">
                        <div className="wshd">
                            <div className="cntr">
                                <div className="gca-hdr">Safe Driving Videos Selected Just for You</div>
                            </div>
                        </div>

                        <div className="fbr-cntnt gca-box">
                            <div className="spacer"><div className="fbr-sct-hdr">Guidance and instruction based on your <i>Ready-Assess</i>&#8482; results:</div></div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    Everyone can benefit from practice.  Even drivers who do many things correctly have some aspects of their driving that could be made better.  
                                </p>
                                <p>
                                    We encourage you to visit the links below, which will connect you to simple, short, and helpful safe driving videos. <b>These videos have been chosen for you based on the results of your test.</b> We think they will help you build upon your driving skills and brush up on those areas where you most need improvement. 
                                </p>

                                {
                                    (feedbackData.age >= 18 || feedbackData.age == -2000) ?
                                    <p>
                                        Note - the content below was originally created to help parents and their teenage drivers get ready for the road.  We understand that you are not a teen.  Please know that these links below provide information that is helpful to drivers of ALL ages.
                                    </p> : null
                                }

                                
                            </div>
                            <div className="video-link-box">
                                <div className="fbr-sct-orng">Links to safe driving videos chosen for you based on your test results</div>
                                <div className="fbr-sct-cntnt vid-div">
                                    {(function() {


                                        let videoPairs = feedbackData.videos.reduce(function(result, value, index, array) {
                                            if (index % 2 === 0)
                                                result.push(array.slice(index, index + 2));
                                            return result;
                                        }, []);
                                        let videoRows = [];
                                        for (var i = 0; i < videoPairs.length; i++) {
                                            let pair = videoPairs[i];
                                            let odd = i % 2;
                                            videoRows.push(<div key={"vr-" + i} className={"video-row row" + (odd ? "" : " vrOdd")}>
                                                                    <div className="vid col-sm-6">
                                                                        {pair[0]}
                                                                    </div>
                                                                    <div className="vid col-sm-6">
                                                                        {pair[1]}
                                                                    </div>
                                                                </div>) 
                                        };

                                        return videoRows;
                                    })()}
                                </div>
                            </div>

                        </div>

                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>
                <div className={"fbr-page-" + (8 + feedbackData.infractionPages.length)}>
                    <span className="page-number">{(8 + feedbackData.infractionPages.length) - 1}</span>
                    <div className="meta-info"><div className="row"><div className="col-sm-10 aidlbl">{aid}</div><div className="col-sm-2">{feedbackData.groupId}</div></div></div>
                    <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS</div>
                    <div className="dtl-content">
                        <div className="wshd">
                            <div className="cntr">
                                <div className="gca-hdr">Helpful Resources</div>
                            </div>
                        </div>

                        <div className="fbr-cntnt gca-box">
                            <div className="fbr-sct-hdr">A Practice Plan Based on Years of Crash-Avoidance Research:</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    Certified driving instructors are an important part of learning how to drive safely.  You also should be practicing at home. To do that well, it is helpful to have a plan to follow that includes time spent driving on a variety of routes and roads, in different types of weather, and with varying road conditions.  If you need help formulating a plan like this, consider the following as a great place to start.

                                </p>
                                <p><a href="https://www.teendriversource.org/learning-to-drive/practice-driving-lessons" target="_blank">Practice Driving Lessons</a></p>
                            </div>
                            <div className="fbr-sct-hdr">And Always Remember:  Keep the Focus on Driving!</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    It is important for novice drivers to maintain focus as driving is a new skill - minimize anything that takes your attention away from the road: passengers, electronics, and other distractions. Here is a helpful video on the topic of distracted driving (<a href="https://www.youtube.com/watch?v=yCJVsd8aKvQ&list=PLamT53otBDn6Oj1guO_MPaZ4guuFr-rE5&index=2" target="_blank">Managing Distractions Inside and Outside the Car</a>).  And for more information on safe driving and preventing crashes, see <a href="https://www.teendriversource.org" target="_blank">TeenDriverSource.org</a>.
                                </p>
                                <p><a href="http://www.teendriversource.org/teen-crash-risks-prevention/car-accident-prevention" target="_blank">Car Accident Prevention</a></p>
                            </div>
                        </div>

                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>

            </div> 
        );
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        assessments: appState.assessments,
        intakeForms: appState.intakeForms,
        orgs: appState.orgs,
        staticFeedback: appState.staticFeedback
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FeedbackReport);