import { C } from '../constants';

const rqRef = C.APP.database().ref("/reconciliationRequests");

export const reconcile = function (rqid) {
    return function (dispatch, getState) {
        rqRef.child(rqid).update({confirmed: true}).then(() => {
            dispatch({type: C.FINISH_RECONCILIATION});
        })
    }
}

export const resetReconciliation = function () {
    return function (dispatch, getState) {

        dispatch({type: C.RESET_RECONCILIATION});

    }
}