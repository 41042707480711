import React, { Component } from 'react';
import Place from 'material-ui/svg-icons/maps/navigation';


class Gauge extends Component {

    render() {
        const score = this.props.score;
        const showRisk = this.props.showRisk;
        const offsetStyle = {};
        offsetStyle[(showRisk ? 'left' : 'right')] = (score * 100) + "%"
        offsetStyle.top = (this.props.topOffset || 8) + 'px';
        offsetStyle.filter = 'drop-shadow(1px 1px 2px black)';
        const cursorSize = this.props.cursorSize || 40;
        const topSpace = this.props.topSpace || 8;
        return <div className="score-gauge-container" style={{paddingTop : topSpace + 'px'}}>
                    <div className={"score-gauge" + (showRisk ? '-risk' : '')} style={{height : (this.props.height || 60) + 'px'}}>
                        <div className="gauge-positioner" style={offsetStyle}>
                            <Place style={{position: 'absolute', left: "-" + (cursorSize / 2) + "px", width: cursorSize + "px", height: cursorSize + "px"}} color='#EEE' />
                        </div>
                    </div>
                </div>
    }
}


export default Gauge

