import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card';
import Divider from 'material-ui/Divider';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../../actions';
import * as hlp from '../../utils/helpers';
import * as perms from "../../utils/permission";
import CoachingSection from './coachingSection';

let initDriveData = {};

class ResultsLink extends Component {
    render () {
        return (
            <div className="row" style={{fontSize: "130%"}}>
                <div className="col-sm-6" style={{textAlign: "right"}}></div>
                <div className="col-sm-6">
                    <Link to={ "/results/" + this.props.aid }>
                        View Results
                    </Link>
                </div>
            </div>
        )
    }
}

class Driver extends Component {

    componentWillMount () {
        console.log("start listening to driver " + this.props.uid);
        this.props.dispatch( actions.startListeningToDriver( this.props.uid ) ); //do we want to call this every time or just once?
        initDriveData = {};
        // load orgs -- or just load this org?
    }

    componentWillReceiveProps () {
        //check if drive data updates -- if not load it
        const p = this.props;
        const driverData = !p.driver.empty && p.driver[p.uid]; 
        if (driverData && !driverData.loading && !initDriveData[p.uid]) {
            const aid = hlp.getLatestAssessment(driverData);
            if (aid) {
                this.props.dispatch( actions.listenToAndNormalizeDriveData(aid) );
                initDriveData[p.uid] = true;
            }
            
        }

    }

    render () {
        const p = this.props;
        const now = Date.now() + p.util.offset; // server time
        const loadingRow = (
                <Card>
                    <CardHeader
                        title="Loading..."/>
                </Card>
            );
        if (p.driver.empty) {
            console.log("Rendering Driver ----> p.driver.empty")
            return loadingRow;
        }

        let driverData = p.driver[p.uid];

        if (!driverData) {
            console.log("Rendering Driver ----> !driverData")
            return loadingRow;
        }

        if (driverData.loading) {
            console.log("Rendering Driver ----> driverData.loading")
            return loadingRow;
        }


        // if (!driverData.results) {
        //     return loadingRow;
        // }

        let aid = hlp.getLatestAssessment(driverData);
        let created = new Date(p.driver[p.uid].dateAdded).toLocaleDateString();

        console.log("---> Created is", created);


        if (!aid) {
            return (
                <Card className="driver-card" key={p.uid}>
                    <CardHeader
                        title={driverData.lastname + ", " + driverData.firstname}
                        actAsExpander={true}
                        showExpandableButton={true} />

                    <CardText expandable={true} style={{alignItems: "center"}}>

                        <div className="row" style={{position: "relative"}}>
                            <div style={{position: "absolute", width: "200px", right: 18, top: -55}}>
                                Added: {created}
                            </div>
                            <div className="col-sm-5" style={{padding: '2em'}}> No assessments yet. </div>
                            <div className="col-sm-7" style={{borderLeft: '2px solid #DDD', padding: '1.5em'}}> Coaching will be available once driver completes an assessment and results are available. </div>
                        </div>
                    </CardText>
                    <Divider />
                    <CardActions expandable={true} style={{textAlign: "right"}}>
                        <RaisedButton label="Schedule Assessment" disabled />
                        <RaisedButton label="Assign Coaching" disabled color="primary" />
                    </CardActions>
                </Card>
                    )
        }

        const SecureResultsLink = perms.spr(ResultsLink);

        let date = 'Loading...';
        let score = 'evaluating';

        let risk = p.driver[p.uid].risk;

        //is this part necessary now? TODO
        let recentDrive = p.drive.hasOwnProperty(aid) && p.drive[aid] && !p.drive[aid].loading ? p.drive[aid] : {}; //causing issues since we took out the getLatestAssessment function TODO

        console.log("RECENT DRIVE FOR " + driverData.lastname + ", " + driverData.firstname, recentDrive);

        let textClass = 'text-inverse';
        let icon = 'icon ion-clock';


        if (aid && recentDrive) {
            date = new Date(p.driver[p.uid].lastTime).toLocaleString(); 
            const danger = recentDrive.results && recentDrive.results.danger;
            if (recentDrive.results) {
                if (danger >= 2) {
                    textClass = 'text-danger';
                    icon = 'icon ion-minus-circled';
                } else if (danger >= 1) {
                    textClass = 'text-warning';
                    icon = 'icon ion-record';
                } else {
                    textClass = 'text-aqua';
                    icon = 'icon ion-android-radio-button-off';
                }
            }
        } else {
            date = "No drives yet...";
        }

        return (
                //TODO: add class based on last overall score

                <Card className="driver-card" key={p.uid}>
                    <CardHeader
                        title={driverData.lastname + ", " + driverData.firstname}
                        actAsExpander={true}
                        showExpandableButton={true} />

                    <CardText expandable={true} style={{alignItems: "center"}}>

                        <div className="row" style={{position: "relative"}}>
                            <div style={{position: "absolute", width: "200px", right: 18, top: -55}}>
                                Added: {created}
                            </div>
                            <div className="col-sm-5" style={{padding: '1.3em'}}>
                                <div className="row">
                                    <div className="col-sm-6" style={{textAlign: "right"}}>Last assessment:</div>
                                    <div className="col-sm-6"><Link to={ "driveresults/" + aid}>{ date }</Link></div>
                                </div>
                                <div className="row" style={{fontSize: "130%"}}>
                                    <div className="col-sm-6" style={{textAlign: "right"}}>Risk:</div>
                                    <div className="col-sm-6">
                                        <Link to={ "/driveresults/" + aid }>
                                            <span className={textClass}><i className={icon} style={{marginRight: "7px"}}></i> 
                                                {recentDrive ? recentDrive.risk : "..."}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row" style={{fontSize: "130%"}}>
                                    <div className="col-sm-6" style={{textAlign: "right"}}></div>
                                    <div className="col-sm-6">
                                        <Link to={ "/feedback/" + aid }>
                                            View Report
                                        </Link>
                                    </div>
                                </div>
                                <SecureResultsLink aid={aid} />

                            </div>
                            <div className="col-sm-7" style={{borderLeft: '2px solid #DDD', padding: '3em'}}>
                                <CoachingSection driverId={p.uid} drive={recentDrive} />
                            </div>
                        </div>
                    </CardText>
                    <Divider />
                    <CardActions expandable={true} style={{textAlign: "right"}}>
                        <RaisedButton label="Schedule Assessment" disabled />
                        <RaisedButton label="Assign Coaching" disabled color="primary" />
                    </CardActions>
                </Card>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        driver: appState.driver,
        util: appState.util,
        drive: appState.drive,
    };
}

Driver.contextTypes = {
    router: PropTypes.object.isRequired
};


export default connect(mapStateToProps)(Driver);



