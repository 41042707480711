// import Chart from 'chart.js';
import IconButton from 'material-ui/IconButton';
import NoticeIcon from 'material-ui/svg-icons/action/announcement';
import InfoIcon from 'material-ui/svg-icons/action/info';
import DriveIcon from 'material-ui/svg-icons/notification/drive-eta';
import React from 'react';
import Markdown from "react-markdown";
import { connect } from 'react-redux';
import { listenToAndNormalizeDriveData } from '../actions/drives';
import * as perms from "../utils/permission";
import Indications from './components/indications';
import SafetyLapses from './components/safetylapses';

const SuperIconButton = perms.spr(IconButton);

const iconStyles = {
    width : "65px",
    height: "65px"
}

// const chartId = "bar-chart-risk"
// const legendId = "bar-chart-risk-legend"

// const errorTypeDefMap = {
//     "Crashes" : "Driver collided with another object, pedestrian or vehicle",
//     "Near Crashes" : "Driver nearly collided with another object, pedestrian or vehicle",
//     "Major Errors" : "Such as running a red light, or driving through a stop sign",
//     "Compound Errors" : "Such as absence of an evasive maneuver while following too closely",
//     "Minor Errors" : "Such as non-frequent sppeding, or failing to scan/check for traffic at intersections"
// }

// function displayChart (eventData) {
//     const data = {
//         labels: ['Crashes', 'Near Crashes', 'Major Errors', 'Compound Errors', 'Minor Errors'],
//         datasets: [
//             {
//                 label: "Number of Errors",
//                 backgroundColor: "#66BAB7",
//                 borderColor: "#55A9A6",
//                 borderSkipped: "bottom",
//                 hoverBackgroundColor: "rgba(102, 186, 183,.5)",
//                 hoverBorderColor: "rgba(102, 186, 183,.5)",
//                 data: eventData
//             }
//         ]
//       };
//     const options = {
//             legend: {
//                 display: false
//             },
//             tooltips: {
//                 callbacks: {
//                     label: function (tooltipItem, data) {
//                         let type = tooltipItem.xLabel;
//                         return errorTypeDefMap[type];
//                     }
//                 }
//             }
//         }
//     var ctx  = document.getElementById(chartId).getContext("2d");
//     var myNewChart = new Chart (ctx, {
//         type: 'bar',
//         data: data,
//         options: options
//     });

// }

class DriveResults extends React.Component {
    
    componentWillMount() {
        this.props.getDrive(this.props.params.aid);
    }

    componentDidUpdate () {
        // if (document.getElementById(chartId)) {
        //     let data = [0,0,0,0,0];
        //     const errorMap = {
        //         crash : 0,
        //         nearCrash: 1,
        //         majorError: 2,
        //         compoundError: 3,
        //         minorError: 4
        //     }
        //     const events = this.props.drive.events;
        //     for (var key in events) {
        //         if (events.hasOwnProperty(key)) { 
        //             let evt = events[key];
        //             data[errorMap[evt.type]]++;
        //         }
        //     }
        //     displayChart(data);
        // }
    }

    render() {
        const aid = this.props.params.aid;
        const drive = this.props.drive;
        
        if (drive == null || drive.loading === true) {
            return (<div>Loading...</div>);
        }

        // const chart = (<canvas id={chartId}></canvas>);
        const pageHead = (
            <div className="row page-header" style={{position: 'relative'}}>
                <div className="col-sm-6">
                    <div className="display-6">{(this.props.driver ? this.props.driver.fullname : '') + "'"}s Drive Insights</div>
                </div>
                <div className="col-sm-6 insight-deets">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="text-desc">Start Time:</div><b>{new Date(this.props.drive.start).toLocaleString()}</b>
                        </div>
                        <div className="col-xs-6">
                            <div className="text-desc">Duration:</div><b>{Math.ceil((this.props.drive.lastUpdate - this.props.drive.start) / 60000)} Minutes</b>
                        </div>
                    </div>
                </div>
                <SuperIconButton style={{position: 'absolute', top: 0, right: 0}} iconClassName="icon ion-edit" href={`/drive/${aid}/edit`}/>
            </div>);

        if (!this.props.drive.results) {
            return (
                <div>
                    {pageHead}
                    <div className="panel">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-6 display-6">
                                    <b>Under Review... </b>
                                </div>
                                <div className="col-sm-6 display-6" style={{fontStyle: "italic"}}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        

        const danger = this.props.drive.results.danger;
        const dangerColor = ["info", "warning", "danger"][danger];
        const dangerMsg = ["Little", "Some", "Strong"][danger];
        const alertIcon = [<DriveIcon color="white" style={iconStyles} />, 
            <InfoIcon color="white" style={iconStyles} />,
            <NoticeIcon color="white" style={iconStyles} />][danger];

        return (
            <div>
                {pageHead} 
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-xs-4 col-sm-7">
                                <div className="drive-summary">
                                    <h6>Summary</h6>
                                    <div className="drive-summary-body dxd-section">
                                        <Markdown source={ this.props.drive.results.summary } />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-5">
                                <div className = "dxd-section">
                                    <ul style={ { margin: 0, padding: 0 } } id="distractions">
                                        <li className={"widget bg-" + dangerColor }>
                                            <div className="w-main w-center">{alertIcon}</div>
                                            <div className={"w-center bg-" + dangerColor + "-darken text-uppercase small"}>{ dangerMsg } Evidence of Dangerous Driving</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <SafetyLapses aid={aid} drive={drive} autoCalculate={true}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <Indications aid={aid} drive={drive}/>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        );
    }
}

export default connect((state, props) => ({
    drive: state.drive[props.params.aid],
    driver: (state.drive.hasOwnProperty(props.params.aid) &&
             state.drive[props.params.aid].hasOwnProperty('user')) ? state.driver[state.drive[props.params.aid].user] : {}
}), (dispatch) => ({
    getDrive: (aid) => dispatch(listenToAndNormalizeDriveData(aid))
}))(DriveResults);