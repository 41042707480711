import _ from 'lodash';
import { C } from '../constants';
import * as hlp from '../utils/helpers';

const aRef = C.APP.database().ref('assessments');

export const updateRollups = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_ROLLUP_RESPONSE });
        const state = getState();
        const now = state.util.offset;
        //get all the assessments within the time range -- default/starting point is 'last week'
        aRef.orderByChild('lastUpdate').startAt(state.rollup.start.getTime()).endAt(state.rollup.end.getTime()).once('value').then((snap) => {

            // PROCESS THE DATA HERE WHILE SPINNER IS SPINNING
            /*
            * ideas:
            * durations
            * average total time, average time in practice, average time in assessment
            * Which maps? (counts) bar chart

            const barData = [
              {x: 'No collisions', y: 10},
              {x: 'One Collision', y: 5},
              {x: 'Two Collisions', y: 15}
            ]

            * 
            */
            let practiceDurationLine = [];

            let totalDurationLine = [];


            let collisionCounts = {};

            let index = 0;
            let allPracticeSecs = 0;
            let allTotalSecs = 0;

            _.map(snap.val(), (asm, asmId) => {
                let numOfCollisions = 0;
                _.map(asm.events, (eData, frame) => {
                    if (eData.name.indexOf('collision') >= 0) {
                        numOfCollisions++;
                    }
                });
                if (numOfCollisions > 50) {
                    console.log(numOfCollisions + "collisions: ", asmId);
                }
                collisionCounts[numOfCollisions] = collisionCounts[numOfCollisions] || 0;
                collisionCounts[numOfCollisions] = collisionCounts[numOfCollisions] + 1;
                let practiceDuration = hlp.calculatePracticeSeconds(asm);
                let totalDuration = hlp.calculateSeconds(asm, now)
                if ((practiceDuration != 'n/a') && (totalDuration != 'n/a')) {
                    if (totalDuration < 3600) {
                        let practiceSecs = hlp.calculatePracticeSeconds(asm);
                        let totalSecs = hlp.calculateSeconds(asm, now);
                        allPracticeSecs += practiceSecs;
                        allTotalSecs += totalSecs;
                        practiceDurationLine.push({x: index, y: practiceSecs / 60});
                        totalDurationLine.push({x: index, y: totalSecs / 60});
                        index++;
                    }
                }
                
                
            })

            let collisionData = _.map(collisionCounts, (value, key) => {
                return {x: String(key), y: value};
            });

            let collisionPieData = _.map(collisionCounts, (value, key) => {
                return {key: String(key), value: value};
            });

            console.log("rollup data received");
            dispatch({ type: C.RECEIVE_ROLLUP_DATA, data: snap.val(), chartData: {
                collisionData,
                collisionPieData,
                durations : [practiceDurationLine, totalDurationLine],
                averagePracticeDuration: hlp.millisToMinutesAndSeconds((allPracticeSecs / index) * 1000),
                averageTotalDuration: hlp.millisToMinutesAndSeconds((allTotalSecs / index) * 1000)
            }});

        });
    }
}

export const setRollupDates = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.datetimerange.values;
        console.log(formValues);
        console.log(typeof formValues.startDate);
        console.log(typeof formValues.startTime);
        let startDate = formValues.startDate || state.rollup.start;
        let startTime = formValues.startTime || state.rollup.start;
        let endDate = formValues.endDate || state.rollup.end;
        let endTime = formValues.endTime || state.rollup.end;
        let start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 
               startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
        let end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 
               endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());



        dispatch({ type: C.SET_TIME_RANGE, start: start, end: end});

    }
}

export const submitRollupDates = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.datetimerange.values;
        console.log(formValues);

        // dispatch({ type: C.SET_TIME_RANGE, start: start, end: end});

    }
}

