import React, { Component } from 'react';

export default class DomainRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  addHelpIcon(value) {
    return `${value} &#x1f6c8;`;
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value,
      });
    }
    return true;
  }

  render() {
    return <span>{this.addHelpIcon(this.state.value)}</span>;
  }
}