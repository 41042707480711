import firebase from 'firebase/compat/app';

import { C } from '../constants';

const modules = C.APP.database().ref('modules');
const scenarios = C.APP.database().ref('scenarios');
const users = C.APP.database().ref('users');
const assetsRef = C.APP.database().ref('assets');

export const startListeningToModule = function (id) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_MODULE_DATA, id });
        modules.child(id).on('value', (snap) => {
            if (snap.val()) {
                let moduleScenarios = snap.val().scenarios;
                let moduleTitle = snap.val().title;
                dispatch({ type: C.RECEIVE_MODULE_DATA, id, data: moduleScenarios, end: moduleScenarios.length - 1, title: moduleTitle});
            } 
            
        });
    }
}

export const startListeningToScenario = function (id) {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_SCENARIO_DATA, id });
        scenarios.child(id).on('value', (snap) => {
            dispatch({ type: C.RECEIVE_SCENARIO_DATA, id, data: snap.val()});
        });
    }
}

export const startListeningToScenarios = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_ALL_SCENARIOS_DATA });
        scenarios.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_ALL_SCENARIOS_DATA, data: snap.val()});
        });
    }
}

export const deleteScenario = function (key) {
    return function (dispatch, getState) {
        scenarios.child(key).set(null).then(() => {
            //done
        })
    }
}

export const deleteModule = function (key) {
    return function (dispatch, getState) {
        modules.child(key).set(null).then(() => {
            //done
        })
    }
}

export const startListeningToModules = function () {
    return function (dispatch, getState) {
        modules.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_ALL_MODULES_DATA, data: snap.val()});
        });
    }
}

export const setCurrentIndex = function (index) {
    return function (dispatch, getState) {
        dispatch({ type: C.SET_CPT_INDEX, index});
    }
}

export const setUserProgress = function (uid, module, index) {
    return function (dispatch, getState) {
        users.child(uid + '/cpt/' + module + '/inProgress').update({index})
    }
}

export const startOrContinue = function (uid, module) {
    return function (dispatch, getState) {
        users.child(uid + '/cpt/' + module + '/inProgress').once('value').then(snap => {
            if (!snap.val()) {
                users.child(uid + '/cpt/' + module + '/inProgress').set({start: C.TS, index: 0}).then(() => {
                    users.child(uid + '/cpt/' + module + '/attempts').set(firebase.database.ServerValue.increment(1))
                })
            }
        })
    }
}

export const finishCPT = function (uid, module) {
    return function (dispatch, getState) {
        users.child(uid + '/cpt/' + module + '/inProgress').once('value').then(snap => {
            let record = snap.val();
            record.end = C.TS;
            users.child(uid + '/cpt/' + module + '/finished').push(record).then(() => {
                users.child(uid + '/cpt/' + module).update({inProgress : null, completions : firebase.database.ServerValue.increment(1)})
            })
        })
    }
}



export const uploadScenario = function () {
    return function (dispatch, getState) {
        dispatch({type: C.AWAIT_NEW_SCENARIO_JSON_RESPONSE});
        const state = getState();
        const formValues = state.form.scenarioJson.values;
        console.log("FORM VALUES: ----> ", formValues);


        
        const jsonFile = formValues.scenarioFile[0]; 
        console.log(jsonFile);

        let onReaderLoad = (event) => {
            console.log(event.target.result);
            let scenarioObj = JSON.parse(event.target.result);
            let valid = true;
            let msgs = [];
            let assetPromises = [];
            if (!scenarioObj.title) {
                valid = false;
                msgs.push("Scenario JSON must include 'title' property");
            }
            if (!scenarioObj.pages) {
                valid = false;
                msgs.push("Scenario JSON must include 'pages' property");
            }
            if (scenarioObj.pages && scenarioObj.pages.length < 1) {
                valid = false;
                msgs.push("Scenario must have at least one page");
            }
            scenarioObj.pages.forEach((page) => {
                // depending on page type validate page
                if (!page.pageType) {
                    valid = false;
                    msgs.push("All pages must have a 'pageType' property");
                }
                if (!page.pageTitle) {
                    valid = false;
                    msgs.push("All pages must have a 'pageTitle' property");
                }
                switch (page.pageType) {
                    case "title":
                        if (!page.pageTitle) {
                            valid = false;
                            msgs.push("Page of type 'title' with no 'pageTitle' prop");
                        }
                        break;
                    case "instruction":
                    case "review":
                        if (!page.text) {
                            valid = false;
                            msgs.push(`Page of type ${page.pageType} with no 'text' prop`);
                        }
                        if (!page.image) {
                            valid = false;
                            msgs.push(`Page of type ${page.pageType} with no 'image' prop`);
                        } else {
                            assetPromises.push(assetsRef.child(page.image).once('value'))
                        }
                        if (page.imageType) { // imageType is actually optional -- but you need it if you want to make the gifs unlooped
                            if (!['gif', 'image', 'video'].includes(page.imageType)) {
                                valid = false;
                                msgs.push("imageType prop must be 'gif', 'image' or 'video'");
                            }
                        }
                        if (page.audio) {
                            assetPromises.push(assetsRef.child(page.audio).once('value'))
                        }
                        if (page.stillImage) {
                            assetPromises.push(assetsRef.child(page.stillImage).once('value'))
                        }
                        break;
                    case "clickZone":
                        if (!page.texts) {
                            valid = false;
                            msgs.push("Page of type 'clickZone' with no 'texts' prop");
                        } else {
                            if (!page.texts.prompt) {
                                valid = false;
                                msgs.push("The texts object must have an 'prompt' prop");
                            }
                            if (!page.texts.wrong) {
                                valid = false;
                                msgs.push("The texts object must have an 'wrong' prop");
                            }
                            if (!page.texts.review) {
                                valid = false;
                                msgs.push("The texts object must have an 'review' prop");
                            }
                            if (!page.texts.confirm) {
                                valid = false;
                                msgs.push("The texts object must have an 'confirm' prop");
                            }
                            if (!page.texts.explain) {
                                valid = false;
                                msgs.push("The texts object must have an 'explain' prop");
                            }
                            if (!page.texts.final) {
                                valid = false;
                                msgs.push("The texts object must have an 'final' prop");
                            }
                        }
                        if (page.audios) {
                            if (page.audios.prompt) {
                                assetPromises.push(assetsRef.child(page.audios.prompt).once('value'))
                            }
                            if (page.audios.wrong) {
                                assetPromises.push(assetsRef.child(page.audios.wrong).once('value'))
                            }
                            if (page.audios.review) {
                                assetPromises.push(assetsRef.child(page.audios.review).once('value'))
                            }
                            if (page.audios.confirm) {
                                assetPromises.push(assetsRef.child(page.audios.confirm).once('value'))
                            }
                            if (page.audios.explain) {
                                assetPromises.push(assetsRef.child(page.audios.explain).once('value'))
                            }
                            if (page.audios.final) {
                                assetPromises.push(assetsRef.child(page.audios.final).once('value'))
                            }
                        }
                        if (!page.image) {
                            valid = false;
                            msgs.push("Page of type 'clickZone' with no 'image' prop");
                        } else {
                            assetPromises.push(assetsRef.child(page.image).once('value'))
                        }
                        if (!page.wrongImage) {
                            valid = false;
                            msgs.push("Page of type 'clickZone' with no 'wrongImage' prop");
                        } else {
                            assetPromises.push(assetsRef.child(page.wrongImage).once('value'))
                        }
                        if (page.imageType) {
                            valid = false;
                            msgs.push("Page of type 'clickZone' does not need an 'imageType' prop -- clickZone images cannot be animations or videos");
                        } 
                        if (!page.clickZone) {
                            valid = false;
                            msgs.push("Page of type 'clickZone' with no 'clickZone' prop");
                        } else {
                            if (!page.clickZone.x) {
                                valid = false;
                                msgs.push("The clickZone object must have an 'x' prop");
                            }
                            if (!page.clickZone.y) {
                                valid = false;
                                msgs.push("The clickZone object must have an 'y' prop");
                            }
                            if (!page.clickZone.width) {
                                valid = false;
                                msgs.push("The clickZone object must have an 'width' prop");
                            }
                            if (!page.clickZone.height) {
                                valid = false;
                                msgs.push("The clickZone object must have an 'height' prop");
                            }
                        }
                        break;
                }
            })
            //make sure the asset IDs point to valid assets
            Promise.all(assetPromises).then(assetSnaps => {
                for (var i = 0; i < assetSnaps.length; i++) {
                    let snap = assetSnaps[i];
                    if (!snap.val()) {
                        valid = false;
                        msgs.push("Asset with ID: " + snap.key + " not found in the database.")
                    }
                };
                if (valid) {
                    scenarios.push(scenarioObj).then(() => {
                        dispatch({type: C.RECEIVE_NEW_SCENARIO_JSON_RESPONSE});
                    })
                } else {
                    dispatch({type: C.RECEIVE_SCENARIO_JSON_UPLOAD_ERROR, errors: msgs});
                }
            })
            
            // validate that it is a healthy scenario
            // load it into firebase
        };

        let reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.readAsText(jsonFile);
    }
}

export const uploadModule = function () {
    return function (dispatch, getState) {
        dispatch({type: C.AWAIT_NEW_MODULE_JSON_RESPONSE});
        const state = getState();
        console.log(state.form);
        const formValues = state.form.moduleJson.values;
        console.log("FORM VALUES: ----> ", formValues);


        
        const jsonFile = formValues.moduleFile[0]; 
        console.log(jsonFile);

        let onReaderLoad = (event) => {
            console.log(event.target.result);
            let moduleObj = JSON.parse(event.target.result);
            let valid = true;
            let msgs = [];
            let scenarioPromises = [];
            if (!moduleObj.title) {
                valid = false;
                msgs.push("Module JSON must include 'title' property");
            }
            if (!moduleObj.scenarios) {
                valid = false;
                msgs.push("Module JSON must include 'scenarios' property (list of IDs)");
            }
            if (moduleObj.scenarios && moduleObj.scenarios.length < 1) {
                valid = false;
                msgs.push("Module must have at least one scenario");
            }
            moduleObj.scenarios.forEach((scn) => {

                scenarioPromises.push(scenarios.child(scn).once('value'))

            })
            //make sure the asset IDs point to valid assets
            Promise.all(scenarioPromises).then(scnSnaps => {
                for (var i = 0; i < scnSnaps.length; i++) {
                    let snap = scnSnaps[i];
                    if (!snap.val()) {
                        valid = false;
                        msgs.push("Scenario with ID: " + snap.key + " not found in the database.")
                    }
                };
                if (valid) {
                    modules.push(moduleObj).then(() => {
                        dispatch({type: C.RECEIVE_NEW_MODULE_JSON_RESPONSE});
                    })
                } else {
                    dispatch({type: C.RECEIVE_MODULE_JSON_UPLOAD_ERROR, errors: msgs});
                }
            })
            
            // validate that it is a healthy module
            // load it into firebase
        };

        let reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.readAsText(jsonFile);
    }
}