import { C } from '../../constants';

const initialState = { //define initial state - an empty form

  submittingnew: false,

};

export default (state = initialState, action) => {
  switch (action.type) {

    case C.AWAIT_NEW_LOCALE_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_LOCALE_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

      default:
        return state;
  }
}