import emailValidator from 'email-validator';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import ErrorPanel from './errorpanel';

const validate = values => {
    const errors = {}
    if (!values[ "email" ]) {
        errors[ "email" ] = 'Required'
    } else {
        if (!emailValidator.validate(values["email"].trim())) {
            errors[ "email" ] = 'Invalid email'
        }
    }

    if (!values[ "password" ]) {
        errors[ "password" ] = 'Required'
    }


    return errors
}

class LoginForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    goToCreateAccount() {
        const {router} = this.context;
        router.replace("/register");
    }

    render () {
        // NB: removed the "create account" button
        // We only allow account creation via invite and/or admin/super
        console.log("rendering login form");

        return (
            <div>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    <ErrorPanel />
                    <Field
                        name="email"
                        component={TextField}
                        className="login-email-input"
                        floatingLabelText="Email"
                        fullWidth={true}
                        hintText="Enter your email" />
                    <Field
                        name="password"
                        type="password"
                        component={TextField}
                        className="login-password-input"
                        floatingLabelText="Password"
                        fullWidth={true}
                        hintText="Enter your password" />
                    <div className="row">

                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Sign In"
                            type="submit"
                            className="login-submit"
                            style={{ width: "100%", marginTop: "15px" }} />

                    </div>
                    

                </form>
            </div>
        )

        
    }
}

LoginForm.contextTypes = {
    router: React.PropTypes.object.isRequired
};

// Decorate the form component
LoginForm = reduxForm({
  form: 'login', // a unique name for this form
  validate
})(LoginForm);

export default LoginForm;