import React, { Component } from 'react';
import Media from './Media';

import HA_1_1_Near_Top from '../../../assets/images/accell/HA_1_1_Near_Top.png';
import HA_1_1_Near_Persp from '../../../assets/images/accell/HA_1_1_Near_Persp.png';
import HA_1_1_Far_Top from '../../../assets/images/accell/HA_1_1_Far_Top.png';
import HA_1_1_Far_Persp from '../../../assets/images/accell/HA_1_1_Far_Persp.png';

const IMAGE_MAP = {
    "HA_1_1_Near_Top" : HA_1_1_Near_Top,
    "HA_1_1_Near_Persp" : HA_1_1_Near_Persp,
    "HA_1_1_Far_Top" : HA_1_1_Far_Top,
    "HA_1_1_Far_Persp" : HA_1_1_Far_Persp
}


export class Page extends Component {

    constructor(props, context) {
      super(props);
    }
  
    render() {
      const p = this.props;
      return (
        <PageWrap pageTitle={p.pageTitle} className={"Page-" + p.pageType}>
            {p.text ? (<PageText text={p.text} audio={p.audio} />) : null}
            {p.image ? (<div className="Page-image Page-main"><Media assetId={p.image} type={p.imageType} loop={p.imageLoop} stillAsset={p.stillImage} alt="" width={500} /></div>) : null}
            <PageButton onClick={p.onDone} text={p.buttonText} />
        </PageWrap>
      );
    }
}

// Wrapper for pages with the right/wrong flow
class QuestionPage extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            wrong: false,
            phase: 0,
            wrongCount: 0
        };
    }

    rightClick = () => {
        const p = this.props;
        const s = this.state;
        const texts = this.props.texts || {};
        if (s.wrong && s.phase === 0) {
            this.setState({
                ...s,
                wrong: false
            });
            return;
        }

        this.setState({
            ...s,
            wrong: false,
            phase: s.phase + 1
        });

        if (s.phase >= 3 || !texts.confirm && s.phase >= 2)
            p.onDone();
    }
    
    wrongClick = () => {
        const s = this.state;
        const p = this.props;
        let attempts = p.attempts || 3;
        if (s.wrong)
            return;


        if (s.wrongCount + 1 >= attempts) {
            this.setState({
                ...s,
                wrong: true,
                wrongCount: s.wrongCount + 1,
                phase: s.phase + 1
            });
        } else {
            this.setState({
                ...s,
                wrong: true,
                wrongCount: s.wrongCount + 1
            });
        }
    }

    render() {
        const p = this.props;
        const s = this.state;
        let pageClass = "Page-question";
        let texts = p.texts || {};
        let text = texts.prompt || "";
        let audios = p.audios || {};
        let audio = audios.prompt || "";

        let showButton = false;
        let buttonText = "Next";
        let resultingContentFunction = p.children;

        if (s.wrong && s.phase < 1) {
            // The user clicked on the wrong place.
            text = texts.wrong || "";
            audio = audios.wrong || "";
            showButton = true;
            buttonText = "Try Again";

            resultingContentFunction = p.wrongContent;
        }

        if (s.phase === 1) {
            // Review or explain page.
            // Both have the same visual content, but different text.

            showButton = true;
            resultingContentFunction = p.reviewContent;
            text = texts.review || "";
            audio = audios.review || "";

            if (s.wrong) {
                // The user clicked on the wrong place too many times.
                text = texts.explain || "";
                audio = audios.explain || "";


            }
        } else if (s.phase === 2) {
            // Do confirmation question.
            text = texts.confirm || "";
            audio = audios.confirm || "";
        } else if (s.phase === 3) {
            // Do final review.
            showButton = true;
            resultingContentFunction = p.reviewContent;
            text = texts.final || "";
            audio = audios.final || "";
        }
        return (
            <PageWrap pageTitle={p.pageTitle} className={pageClass}>
                {text ? (<PageText text={text} audio={audio} />) : null}
                {resultingContentFunction(this.rightClick.bind(this), this.wrongClick.bind(this))}
                {showButton ? (<PageButton onClick={this.rightClick.bind(this)} text="Next" />) : null}
            </PageWrap>
        );
      }
}

export class ClickZonePage extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            wrong: false,
            phase: 0,
            wrongCount: 0
        };
    }

    render() {
        const p = this.props;
        const s = this.state;
        let attempts = p.attempts || 3;
        
        return (
            <QuestionPage
                pageTitle={p.pageTitle}
                attempts={attempts}
                onDone={p.onDone}
                texts={p.texts}
                audios={p.audios}
                reviewContent={
                    () => (<PageImage image={p.image} className="Page-clickZone">
                        <button className="clickzone-button clickzone-button-show" style={{
                            top: p.clickZone.y + "%",
                            left: p.clickZone.x + "%",
                            height: p.clickZone.height + "%",
                            width: p.clickZone.width + "%"
                        }} disabled>Correct Answer</button>
                    </PageImage>)
                }
                wrongContent={() => <PageImage image={p.wrongImage} />}
            >
                {(onRight, onWrong) => (<PageImage image={p.image} onClick={onWrong} className="Page-clickZone">
                    <button className="clickzone-button" style={{
                        top: p.clickZone.y + "%",
                        left: p.clickZone.x + "%",
                        height: p.clickZone.height + "%",
                        width: p.clickZone.width + "%"
                    }} onClick={onRight} >X</button>
                </PageImage>)}
            </QuestionPage>
        );
      }
}

export class SteeringPage extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            wrong: false,
            wrongGuesses: [false, false, false, false, false]
        };
    }

    clickButton = (direction) => {
        const p = this.props;
        if (direction !== p.correct) {
            let newWrongGuesses = [...this.state.wrongGuesses];
            newWrongGuesses[direction + 2] = true;
            this.setState({wrongGuesses: newWrongGuesses});
            this.setState({wrong: true});
            return;
        }
        p.onDone();
    }

    render() {
        const p = this.props;
        const s = this.state;
        
        let text = p.texts.prompt;
        let audio = p.audios.prompt;
        if (s.wrong) {
            text = p.texts.wrong;
            audio = p.audios.wrong;
        }

        const labels = ["Hard Left", "Left", "Straight", "Right", "Hard Right"];
        var buttons = labels.map((label, index) =>
            <DecisionButton className="steering-button" direction={index - 2}
                onClick={this.clickButton.bind(this)} wrong={this.state.wrongGuesses[index]} >{label}</DecisionButton>
        );

        return (
            <PageWrap pageTitle={p.pageTitle}>
                {text ? (<PageText text={text} audio={audio} />) : null}
                <div className="Page-acceleration Page-main">
                    {buttons}
                </div>
            </PageWrap>
        );
      }
}

export class AccelerationPage extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            wrong: false,
            wrongGuesses: [false, false, false, false, false]
        };
    }

    clickButton = (speed) => {
        const s = this.state;
        const p = this.props;
        if (speed !== p.correct) {
            let newWrongGuesses = [...this.state.wrongGuesses];
            newWrongGuesses[speed + 2] = true;
            this.setState({wrongGuesses: newWrongGuesses});
            this.setState({wrong: true});
            return;
        }
        p.onDone();

    }

    render() {
        const p = this.props;
        const s = this.state;
        var text = p.texts.prompt;
        let audio = p.audios.prompt;
        if (s.wrong) {
            text = p.texts.wrong;
            audio = p.audios.wrong;
        }

        const labels = ["Brake Hard", "Brake Lightly", "No Change", "Accelerate Lightly", "Accelerate Hard"];
        var buttons = labels.map((label, index) =>
            <DecisionButton className="acceleration-button" direction={index - 2}
                onClick={this.clickButton.bind(this)} wrong={s.wrongGuesses[index]} >{label}</DecisionButton>
        );

        return (
            <PageWrap pageTitle={p.pageTitle}>
                {text ? (<PageText text={text} audio={audio} />) : null}
                <div className="Page-acceleration Page-main">
                    {buttons}
                </div>
            </PageWrap>
        );
      }
}

class DecisionButton extends Component {
    constructor(props, context) {
        super(props);
    }

    render() {
        const p = this.props;
        let className = p.className + " " + p.className + "-" + p.direction + (p.wrong ? " " + p.className + "-wrong" : "");
        return (
            <button disabled={p.wrong} className={className} onClick={() => p.onClick(direction)}>{p.children}</button>
        );
      }
}

export class AttentionPage extends Component {
    constructor(props, context) {
        super(props);
    }

    render() {
        const p = this.props;
        const s = this.state;
        
        return (
            <PageWrap pageTitle={p.pageTitle}>
                {p.text ? (<PageText text={p.text} audio={p.audio} />) : null}
                <div className="Page-attention Page-main">
                    Not yet implemented.
                </div>
                <PageButton onClick={p.onDone} />
            </PageWrap>
        );
      }
}

class PageImage extends Component {

    render() {
        const p = this.props;
        
        return (
            <div className={"Page-image Page-main " + p.className}>
                <Media assetId={p.image} alt={p.alt} onClick={p.onClick} />
                {p.children}
            </div>
        );
      }
}

class PageWrap extends Component {

    render() {
        const p = this.props;
        let clsnm = p.className || "";
        return (
            <div className={"Page " + clsnm}>
                <header className="Page-header">{p.pageTitle}</header>
                <div className="Page-body">
                    {p.children}
                </div>
            </div>
        );
      }
}

function makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

class PageText extends Component {
    
    render() {
        const p = this.props;
        let textElements = p.text.split("\n").map((line) => <p key={makeId(6)}>{line}</p>);
        return (
            <div className="Page-text">
                <div>{textElements}</div>
                {p.audio ? <div><Media assetId={p.audio} alt="" /></div> : null}
            </div>
        );
    }
}

class PageButton extends Component {

    render() {
        const p = this.props;
        let clsnm = p.className || "";
        let text = p.text || "Next";
        return (
            <div className={"Page-button " + clsnm}>
                <button onClick={p.onClick}>{text}</button>
            </div>
        );
      }
}
