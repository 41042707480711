import emailValidator from 'email-validator';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const radioButtonStyle = {
    marginBottom: 16
};

const validate = values => {
    const errors = {}
    if (!values[ "email" ]) {
        errors[ "email" ] = 'Required'
    } else {
        if (!emailValidator.validate(values["email"])) {
            errors[ "email" ] = 'Invalid email'
        }
    }

    if (!values[ "password" ]) {
        errors[ "password" ] = 'Required'
    }

    if (!values[ "firstname" ]) {
        errors[ "firstname" ] = 'Required'
    }

        if (!values[ "lastname" ]) {
        errors[ "lastname" ] = 'Required'
    }

    return errors
}

class AddDriverUserForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        // NB: removed the "create account" button
        // We only allow account creation via invite and/or admin/super
        console.log("rendering add user form");

        return (
            <div>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    <div>
                        <Field
                            name="firstname"
                            component={TextField}
                            floatingLabelText="First Name"
                            hintText="Type user's first name" />
                    </div>
                    <div>
                        <Field
                            name="lastname"
                            component={TextField}
                            floatingLabelText="Last Name"
                            hintText="Type user's last name" />
                    </div>
                    <div>
                        <Field
                            name="email"
                            component={TextField}
                            floatingLabelText="Email"
                            hintText="Type user's email" />
                    </div>
                    <div>
                        <Field
                            name="password"
                            component={TextField}
                            floatingLabelText="Password"
                            hintText="Type user's password" />
                    </div>
                    <div>
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Add"
                            type="submit"
                            style={{ width: "100%", marginTop: "15px" }} />
                    </div>

                </form>
            </div>
        )

        
    }
}

// Decorate the form component
AddDriverUserForm = reduxForm({
  form: 'addDriverUser', // a unique name for this form
  validate
})(AddDriverUserForm);

export default AddDriverUserForm;