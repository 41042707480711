import _ from 'lodash';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';


class IntakeCsv extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    componentWillMount() {
        if (this.props.intakeId) {
            store.dispatch( actions.startListeningToIntakeForm(this.props.intakeId) );
        }
        
    }

    render () {
        const p = this.props;
        const iid = p.intakeId;

        if (!iid) {
            return <div> No Intake Data</div>
        }

        const intake = p.intakeForms[iid];

        if (!intake) {
            return <div>No Intake Data</div>
        }

        if (intake.loading) {
            return <div> Loading Intake </div>
        }

        let csvkeys = [];
        let csvValues = [];

        let responses = p.intakeForms[p.intakeId].data && p.intakeForms[p.intakeId].data.responses ? p.intakeForms[p.intakeId].data.responses : p.intakeForms[p.intakeId].data;

        let otherData = _.map(responses, function(value, key) {
            csvkeys.push(key);
            if (typeof value == "string") {
                csvValues.push(value);
            } else {
                let choices = [];
                _.map(value, function (cv, ck) {
                    choices.push(ck);
                });
                csvValues.push(choices.join(', '));
            }
            return true;
        })

        return (
            <CSVLink data={[csvkeys, csvValues]}
                filename={p.filename}
                className="btn btn-primary"
                target="_blank">
                    Intake CSV
            </CSVLink>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        intakeForms : appState.intakeForms
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(IntakeCsv);