
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import store from '../../../store';
import { Page, ClickZonePage, SteeringPage, AccelerationPage, AttentionPage } from "./Page";

const pages = {
    'clickZone': ClickZonePage,
    'steering': SteeringPage,
    'acceleration': AccelerationPage,
    'attention': AttentionPage
};

class Scenario extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            stage: 0
        };
    }

    componentWillMount () {
        const p = this.props;
        const s = p.scenario;
        store.dispatch( actions.startListeningToScenario(s) );

    }

    componentWillReceiveProps(nextProps) {
        const p = this.props;
        const s = p.scenario;
        if (this.props.scenario != nextProps.scenario) {
            this.setState({stage: 0})
            store.dispatch( actions.startListeningToScenario(nextProps.scenario) );
        }
    }

    nextPage = (result) => {
        const p = this.props;
        const s = this.state;
        const sceneLoad = p.cpt.scenarios[p.scenario];
        let scenario = sceneLoad.data;
        let next = this.state.stage + 1;
        if (next >= scenario.pages.length) {
            console.log('DONE WITH scenario', scenario.title);
            p.onDone()
        } else {
            this.setState({stage: this.state.stage + 1})
        }
        

    }

    render() {
        const p = this.props;
        const s = this.state;
        const sceneLoad = p.cpt.scenarios[p.scenario];
        console.log("WHAT'S THE SCENARIO", sceneLoad, p.scenario);
        if (sceneLoad && sceneLoad.hasReceivedData) {
            let scenario = sceneLoad.data;
            if (s.stage >= scenario.pages.length) {
                return <div>Done with this scenario (should not see this)</div>
            }
        
            let PageComponent = Page;
            let params = scenario.pages[s.stage];
        
            params.onDone = this.nextPage;
        
            if (pages[params.pageType]) {
                PageComponent = pages[params.pageType];
            }
        
            return (
                <div className="Scenario">
                    <PageComponent {...params} />
                </div>
            );
        }

        return <div>loading...</div>
        
      }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        cpt: appState.cpt
    };
}

const mapDispatchToProps = function(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Scenario);
