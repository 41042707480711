import emailValidator from 'email-validator';

export function required(key, values) {
    const value = values[key];
    //TODO: strip?
  return !value ? ['This field cannot be empty'] : [];
}

export function email(key, values) {
    const value = values[key];
  return !emailValidator.validate(value) ? ['This email address is invalid']: [];
}

export function password(key, values) {
    const value = values[key];
    //TODO: stricter password requirements
    // https://github.com/nowsecure/owasp-password-strength-test
    return value && value.length < 10 ? ['Password must be 10 characters or longer'] : [] 
}

export function again(key, values) {
    const value = values[key];
    let again = values[key + "-again"]
    if (key.endsWith("-again")) {
        again = values[key.split('-again')[0]];
    }
    // if (!value && !again) {
    //     return ['Both password fields are required'];
    // }

    return (value && again) && (value !== again) ? ['Fields do not match'] : []
}