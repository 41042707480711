import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedData: false,
  uploading: false,
  error: "",
  progress: 0,
  totalBytes: 0,
  bytesTransferred: 0,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_FILES_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedData: true
        });

    case C.AWAIT_NEW_FILE_RESPONSE:
        return Object.assign({},state,{
            uploading: true,
            error: ""
        });

    case C.RECEIVE_NEW_FILE_RESPONSE:
        return Object.assign({}, state, {
            uploading: false,
            error: "",
            progress: 0
        })

    case C.RECEIVE_FILE_UPLOAD_ERROR:
        return Object.assign({}, state, {
            uploading: false,
            error: action.error,
            progress: 0
        })

    case C.SET_FILE_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            progress: action.progress,
            totalBytes: action.totalBytes,
            bytesTransferred: action.bytesTransferred
        })

      default:
        return state;
  }
}