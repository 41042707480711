
import _ from 'lodash';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddCoachingSurveyForm from './components/addcoachingsurveyform';
import ArrayMove from './components/arraymove';


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToCoachingPages() );
    store.dispatch( actions.startListeningToCoachingItems() );
    store.dispatch( actions.startListeningToCoachingSurveys() );
    store.dispatch( actions.startListeningToOrgs() );
});

class CoachingSurveyAdmin extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount CoachingPageAdmin");
        startListening();
    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;

        console.log("rendering coaching page admin page");

        const pages = p.coachingPages;
        const items = p.coachingItems;
        const surveys = p.coachingSurveys;

        let liveIntakes = [];

        if (p.orgs && p.orgs.hasReceivedOrgData) {
            _.map(p.orgs.data, (org, orgId) => {
                if (org.intake) {
                    liveIntakes.push(org.intake);
                }
            })
        }

        const coachingSurveyRows = _.map(surveys.data, (cs, csid) => {

            return <div key={csid} className="row q-row">
                <div className="col-sm-2">{cs.name}</div>
                <div className="col-sm-1">{cs.numberOfPages}</div>
                <div className="col-sm-2"> {cs.pages && cs.pages.length ? <ArrayMove array={cs.pages} fullPath={"coachingSurveys/" + csid + '/pages'} /> : null} </div>
                <div className="col-sm-3">{cs.description}</div>
                <div className="col-sm-1">{cs.type}</div>
                <div className="col-sm-2">{csid}</div>
                <div className="col-sm-1">
                    <IconButton
                        disabled={!!cs.orgs || liveIntakes.indexOf(csid) >= 0}
                        onClick={ function () { p.deleteCoachingSurvey(csid, cs) }}
                        tooltip={cs.orgs ? "Survey Assigned. Cannot Delete." : "Delete Coaching Survey"}>
                        <Delete className="q-trash" />
                    </IconButton>
                    {/*
                    <IconButton disabled={true} tooltip="Edit Coaching Item">
                        <Create className="q-edit" />
                    </IconButton>
                    */}  
                    {/* This is bound to "Questions" -- need to untangle so that we can use it for Coaching Items as well
                    <IconButton onClick={ function () { p.load(qid); }} tooltip="Copy coaching item values into form">
                        <MoveUp className="q-dupe" />
                    </IconButton>
                    */}
                </div>
            </div>
        })

        const rows = <div>
            {pages.hasReceivedData ? (
                <div className="q-rows">
                    <div className="row q-row">
                        <div className="col-sm-2">Name</div>
                        <div className="col-sm-1">Page Count</div>
                        <div className="col-sm-4">Description</div>
                        <div className="col-sm-1">Type</div>
                        <div className="col-sm-3">ID</div>
                        <div className="col-sm-1">Delete</div>
                    </div>
                    {coachingSurveyRows}
                </div>
            ) : "Loading coaching surveys..."}
        </div>

        const noQs = <div> No coaching surveys exist </div>
        

        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <AddCoachingSurveyForm
                            className="add-cs-form"
                            pages={p.coachingPages}
                            onSubmit={(function (e) {e.preventDefault(); this.props.addCoachingSurvey(); }).bind(this) }>
                        </AddCoachingSurveyForm>


                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        { coachingSurveyRows.length ? rows : noQs }
                    </div>
                </div>
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        coachingItems: appState.coachingItems,
        coachingPages: appState.coachingPages,
        coachingSurveys: appState.coachingSurveys,
        orgs: appState.orgs,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addCoachingSurvey: function () {
            dispatch( actions.addCoachingSurvey() )
        },
        deleteCoachingSurvey: function (csid, cs) {
            dispatch( actions.deleteCoachingSurvey( csid, cs ) )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoachingSurveyAdmin);
