import { C } from '../constants';

const assessmentsBaseRef = C.APP.database().ref('users');
const usersRef = C.DB.ref('users');

export const startListeningToDriver = function (uid) {
    return function (dispatch, getState) {

        if (getState().driver.empty) {//will only await if there is no driver data
            dispatch({ type: C.AWAIT_NEW_DRIVER_DATA, key: uid });
        } 

        assessmentsBaseRef.child(uid).on('value', (snap) => {
            dispatch({ type: C.RECEIVE_DRIVER_DATA, key: uid, data: snap.val()});

            //what the heck is this doing
            // const assessments = snap.val().assessments;
            // const state = getState();
            // for (let aid in assessments) {
            //     if (state.drives && state.drives.hasOwnProperty(aid)) {
            //         continue;
            //     }

            //     // dispatch(startListeningToDrive(aid)); //commenting this out doesnt seem to do anything at all
            // }
        });
    }

}

export const updateRiskOnDriver = function (danger, uid, aid) {
    return function (dispatch, getState) {
        usersRef.child(uid).once('value', (snap) => { 
            const risk = {risk: danger}

            //if this aid matches the last assessment aid, then change the risk
            if (aid == snap.val().lastAssessmentId) {
                //will run even if you dont change but it is the last assessment TODO
                usersRef.child(uid).update(risk);
                console.log("------The latest assessment's risk level was changed.")
            } else {
                console.log("------The risk level of this assessment was updated. It was not the latest assessment from this user.")
            }
        });
    }
};

export const updateDriveData = function (driveDataId, driveData) {
    return function (dispatch, getState) {
        let driver = getState().driver[driveData.user];
        console.log("-------------------+++++++++++ update drive data", driver);
        if (driver) {
            let assessments = driver.assessments;
            for (let aid in assessments) {
                if (driveDataId == aid) {
                    console.log("==============dispatching UPDATE_DRIVERS_DRIVE_DATA", aid);
                    dispatch({ type: C.UPDATE_DRIVERS_DRIVE_DATA, aid: driveDataId, data: driveData, offset: getState().util.offset})
                }
            }
        }
    }
}

export const sortDriversBy = function (sortBy, reverse) {
    return function (dispatch, getState) {
            let rows=[];

            usersRef.orderByChild(sortBy).once("value", (snap) => { //Case sensitive boooooo
                snap.forEach((child) => {
                    //must use forEach to get the order we want
                    if (child.key in getState().auth.drivers) {

                        //if a new column was clicked, our sort should be in normal order (ie not reversed)
                        if (getState().myDriversSort.sortBy != sortBy) {
                            reverse = false;
                        } else if (!getState().myDriversSort.hasBeenSorted) { 
                            //our sortBys are the same, but this page has not yet been sorted, so switch the reverse
                            reverse = !reverse;
                        }

                        //sort the drivers
                        if (!reverse) { 
                            //push this element to the bottom
                            rows.push(child.key);
                        } else {
                            //reverse the order: insert the element at the top instead of pushing to bottom
                            rows.splice(0,0,child.key);
                        }
                    }
            });
                dispatch ({type: C.SORT_DRIVERS_BY, sortBy: sortBy, reverse: reverse, rows: rows});
        });
        
    }
}

export const initSortDrivers = function (sortBy, reverse) {
    return function (dispatch, getState) {
        let rows=[];

        usersRef.orderByChild(sortBy).once("value", (snap) => { //Case sensitive boooooo
                snap.forEach((child) => {
                    //must use forEach to get the order we want
                    if (child.key in getState().auth.drivers) {

                        if (!reverse) { 
                            // push this element to the bottom
                            rows.push(child.key);
                        } else {
                            //reverse the order: insert the element at the top instead of pushing to bottom
                            rows.splice(0,0,child.key);
                        }

                    }
            });
            dispatch ({type: C.INIT_SORT_DRIVERS, sortBy: sortBy, reverse: reverse, rows: rows})
        });

    }
}

// export const sortByName = function () {
//     return function (dispatch, getState) {
//         let currentSortState = getState().myDriversSort;//whats this guy -- it's from the reducers
//         dispatch({ type: C.SORT_BY_NAME, reverse: !currentSortState.reverse})
//     }
// }

export const setDriverSortState = function (sortBy) {
    return function (dispatch, getState) {
        let currentSortState = getState().myDriversSort;
        console.log("- - - - - - -", currentSortState, sortBy)
        if (currentSortState.sortBy == sortBy) {
            dispatch({ type: C.SET_SORT_STATE, sortBy: sortBy, reverse: !currentSortState.reverse})
        } else {
            dispatch({ type: C.SET_SORT_STATE, sortBy: sortBy, reverse: false})
        }
    }
}