import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const validate = function (values) {
    const errors = {};
    if (!values.url) {
        errors.name = "URL is required"
    }
    return errors;
}


class AddWebhook extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        return (
            <form onSubmit={this.props.onSubmit}>
                <div>
                    <Field 
                        name="url"
                        component={TextField}
                        hintText="http://example.com/receivepost"
                        style={{width: '60%'}}
                        floatingLabelFixed={true}
                        floatingLabelText="Payload URL" />
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Add Webhook"
                        type="submit" />
                </div>
            </form>
        )
    }
}

// Decorate the form component
AddWebhook = reduxForm({
  form: 'webhook', // a unique name for this form
  validate
})(AddWebhook);

export default AddWebhook;