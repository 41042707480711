

import React, { Component } from 'react';
import { connect } from 'react-redux';
import JSONEdit from '../JSONEdit';


class ScenarioJsonEdit extends Component {

    


    render () {
        const p = this.props;
        let params = p.params;

        let sid = params.sid;

        return (
            <JSONEdit path={"scenarios/" + sid} />
        )
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioJsonEdit);
