import React, { Component } from 'react';
import { connect } from 'react-redux';
import {C} from '../../constants';
import actions from '../../actions';
import store from '../../store';
import DomainRenderer from './domainRenderer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

const FRAMEWORK_COMPONENTS = {
    // domainRenderer: DomainRenderer
}

function isLow (params) {

    return 
            (params.data.riskLevel && params.data.riskLevel == 0) || 
            (params.data.riskMessage && params.data.riskMessage.indexOf('Little') >= 0) ||
            (params.data.noviceLevel && params.data.noviceLevel == 0) ||
            (params.data.experiencedLevel && params.data.experiencedLevel == 0);
}

function isMid (params) {
    return 
            (params.data.riskLevel && params.data.riskLevel == 1) || 
            (params.data.riskMessage && params.data.riskMessage.indexOf('Some') >= 0) ||
            (params.data.noviceLevel && params.data.noviceLevel == 1) ||
            (params.data.experiencedLevel && params.data.experiencedLevel == 1);
}

function isHigh (params) {
    return 
            (params.data.riskLevel && params.data.riskLevel == 2) || 
            (params.data.riskMessage && params.data.riskMessage.indexOf('Strong') >= 0) ||
            (params.data.noviceLevel && params.data.noviceLevel == 2) ||
            (params.data.experiencedLevel && params.data.experiencedLevel == 2);
}


const ROW_CLASS_RULES = {
    'opp_little_row' : isLow,
    'opp_some_row' : isMid,
    'opp_strong_row' : isHigh,
    'rtd-in-progress': function(params) {
        let inProg = params.data.inProgress === 'YES';
        let time = params.data.startDate;
        let date = params.data.startTime;
        let fourHours = 4 * 60 * 60 * 1000;
        if (inProg && time && date) {
            let now = new Date();
            let then = new Date(date + " " + time);
            if ((now - then) < fourHours) {
                return true;
            }
        }
        return false;
    },
}

const SUGGESTION_MAP = {
    speed: "speeding",
    steeringCornering: "steering/cornering",
    hardBraking: "hard braking",
    cellPhoneUse: "mobile phone use"
}

class DashGrid extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentWillMount () {

    }


    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.sortByDateDesc();
    }

    sortByDateDesc () {
        var sort = [
            {
                colId: 'startDate',
                sort: 'desc',
            },
        ];
        this.gridApi.setSortModel(sort);
    }

    clearFilters() {
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
    }


    render () {
        
        const p = this.props;
        

        let content = <div></div>

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        if (!p.loading) {
            content = (
                <div>
                    <div className="dash-header">
                        {p.headerItems}
                        <button onClick={this.clearFilters.bind(this)}>Clear All Filters</button>
                        <button onClick={(function (e) {e.preventDefault(); p.exportQueryToCSV(this.gridApi); }).bind(this)}>Export to CSV</button>
                    </div>
                    <div className="ag-theme-balham" style={{ height: '700px', width: '100%', padding: '10px 10px 0px 10px' }}>
                        <AgGridReact
                            modules={AllCommunityModules}
                            reactNext={true}
                            columnDefs={p.dash.data.columnDefs}
                            pagination={true}
                            onGridReady={this.onGridReady.bind(this)}
                            rowData={p.dash.data.rowData}
                            onCellClicked = {p.onCellClicked}
                            rowClassRules={ROW_CLASS_RULES}
                            enableBrowserTooltips={true}
                            rowStyle={{height: 60}}
                            cellStyle={{padding: 20}}
                            frameworkComponents={FRAMEWORK_COMPONENTS}
                            >

                            
                        </AgGridReact>
                        <div className="dash-footer">
                            
                        </div>
                    </div>
                </div>
            );
            
        }

        

        return (
                <div>
                    { p.loading ? (
                           <div className="loading">Loading&#8230;</div>
                        ) : null }
                    {content}
                </div>
                
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        dash: appState.dash,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        exportQueryToCSV : function(gridApi) { dispatch( actions.exportQueryToCSV(gridApi) ) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DashGrid);
