/*
This file defines the main Redux Store. It is used by the app index.js file where it is given to
the Provider element from ReactRedux, which allows smart components to `connect` to the store
*/

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk'; // allows us to use asynchronous actions
import { init } from './init';
import { rootReducer } from './reducers';

// A super-simple logger
var logger = store => next => action => {
    //console.log('dispatching', action.type, action)
    var result = next(action)
    //console.log('next state', store.getState())
    return result
}

export default compose(
    applyMiddleware(thunk, logger),
    window.devToolsExtension ? window.devToolsExtension() : f => f
    )(createStore)(rootReducer, init)