import { C } from '../../constants';

const initialState = { 
    data: {},
    ready: false
};


export default (state = initialState, action) => {

  switch (action.type) {
    case C.RECEIVE_INVITE_DATA_FOR_REGISTRATION:
      if (action.data) {
          return { data : { 
              email: action.data.invitee == "open" ? "" : action.data.invitee,
              inviteKey: action.key,
              invitor: action.data.invitor,
              oid: action.data.oid,
              makeManager: action.data.makeManager,
              reconcile: action.data.reconcile,
              payPerAssessment: action.data.payPerAssessment,
              requireParent: action.data.requireParent,
              waiveFee: action.data.waiveFee
          }, ready: true };
      }
      return {
        data: {
          invalid: true
        },
        ready: true
      }
        

      default:
        return state;
  }
}