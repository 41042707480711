

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import store from '../../../store';
import GifPlayer from 'react-gif-player'



class Media extends Component {

    componentWillMount () {
        console.log("MOUNTING");
        const assetId = this.props.assetId
        let stillAsset = this.props.stillAsset;
        if (stillAsset) {
            store.dispatch( actions.startListeningToAsset(stillAsset) );
        }
        store.dispatch( actions.startListeningToAsset(assetId) );
    }

    componentDidUpdate(prevProps) {
        if(this.props.assetId != prevProps.assetId) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            store.dispatch( actions.startListeningToAsset(this.props.assetId) );
        }
        if (this.props.stillAsset) {
            if (this.props.stillAsset != prevProps.stillAsset) {
                store.dispatch( actions.startListeningToAsset(this.props.stillAsset) );
            }
        }
      } 

    render () {
        const p = this.props;
        const assetId = p.assetId;
        let thisAsset = p.assets.assets[assetId]

        let stillAsset = p.stillAsset;
        let still = null;
        if (stillAsset) {
            still = p.assets.assets[stillAsset];
        }

        let ready = thisAsset && thisAsset.hasReceivedData;
        if (stillAsset) {
            ready = ready && still;
        }
        console.log("Media", assetId, thisAsset, stillAsset, still);
        if (ready) {
            // figure out which media type and render it
            let type = thisAsset.assetData.type;
            if (type == 'gif') {
                if (p.loop) {
                    console.log("LOOOOP")
                    return <img src={thisAsset.url} alt={p.alt} width={p.width ? p.width : '100%'} />
                } else {
                    console.log("DON'T LOOOOOP")
                    if (!still) {
                        return <GifPlayer gif={thisAsset.url}/>
                    } else {
                        return <GifPlayer gif={thisAsset.url} still={still.url}/>
                    }
                }
            }

            if (type == 'image') {
                return <img src={thisAsset.url} onClick={p.onClick} width={p.width} />
            }
            if (type == 'video') {
                return <video style={{width: '100%'}} controls>
                            <source src={thisAsset.url} type="video/mp4" />
                            Your browser does not support HTML5 video
                        </video>
            }

            if (type == 'audio') {
                return <audio controls>
                            <source src={thisAsset.url} alt={p.alt} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>;
            }
            return <div>Something went wrong.</div>
        }

        

        return (
            <div>
                One moment please...
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        assets: appState.assets,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Media);
