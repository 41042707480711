
import React, { Component } from 'react';


class IntakeSuccess extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount IntakeSuccess");

    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;

        console.log("rendering intake page");
        

        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <div>Thank you!</div>
                        <div>You are now ready to take the simulation portion of the assessment.</div>
                    </div>
                </div>
            </div> 
                
        )
    }
}


export default IntakeSuccess
