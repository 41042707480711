import { RadioButton } from 'material-ui/RadioButton';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Markdown from "react-markdown";
import { Field, reduxForm } from 'redux-form';
import {RadioButtonGroup} from 'redux-form-material-ui';
import {C} from '../../constants';




const validate = values => {
    const errors = {}

    if (!values["surveyId"]) {
        errors ["surveyId"] = "Required"
    }

    return errors
}

class SurveySelectForm extends Component {

    render () {
        const p = this.props;

        let choices = [];

        const item = p.item;

        for (let i = 0; i < item.choices.length; i++) {
            let thisChoice =  item.choices[i];
            let num = i + 1;

            choices.push(<RadioButton
                key={"surveySelectFormChoice-" + num}
                className={item.type + "-chc"}
                style={{margin: "7px 0"}}
                labelStyle={{marginBottom: "0px", lineHeight: "20px"}}
                value={thisChoice.value}
                label={thisChoice.display}/>)

        };



        console.log("Need next button to be enabled", this.props.pristine, this.props.valid);

        const next = (
                        <div>
                            <RaisedButton
                                primary
                                disabled={ !this.props.valid}
                                label={"Next"}
                                type="submit"
                                style={{ width: "100%", marginTop: "15px" }} />
                        </div>
                    )

        

        return (
            <form 
                className="survey-select-form"
                onSubmit={p.onSubmit}>
                <div className="row">
                    <div className={'row intake-row'}>
                        <div className={"col-sm-12 display-6 intake-q-t intake-q-t-" + item.type}><Markdown source={item.text} /></div>
                        <div className="col-sm-12">
                            <Field name='surveyId' component={RadioButtonGroup}>
                                {choices}
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                    </div>
                    <div className="col-sm-6">
                        {next}
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
SurveySelectForm = reduxForm({
  form: 'surveyPrescription', // a unique name for this form
  validate
})(SurveySelectForm);


export default SurveySelectForm;