import React, { Component } from 'react';
import { connect } from 'react-redux';


class Unknown extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const { location } = this.props;
        return (
            //We are using CSS to hide/show spinner when attempting login
            <div className="uhoh-box">

                <div className="dxd-login-logo" />
                <div className="uhoh-text">
                    <div style={{fontSize: 20}}>The path <span className="big-code"> {location.pathname} </span> does not exist.</div>
                    <div>(did you type the URL correctly?)</div>
                    <div>If you think this is a mistake, please contact the <a href="mailto:support@diagnosticdriving.com">support team.</a></div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unknown);
