
import LinearProgress from 'material-ui/LinearProgress';
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import CollisionCountPieChart from './collisionCountPieChart';
import DateRangeForm from './daterangeform';
import DurationChart from './durationChart';

const style = {
  margin: 20,
  position: "relative"
};

const chStyle = {
  margin: 20,
  padding: 15,
  position: "relative"
};

const cStyle = {
    padding: 15,
    textAlign: "center"
}

class RollUps extends Component {

    componentWillMount () {
        store.dispatch( actions.updateRollups() );
    }

    render () {
        const p = this.props;
        

        const hangOn = (<div className="row"><div className="col-sm-12"><Paper style={style} zDepth={1}>
                            <div style={cStyle} >
                                <LinearProgress />
                            </div>
                        </Paper></div></div>);



        const drf = (<DateRangeForm
                        className="date-range-form"
                        includeSubmitButton={true}
                        onSubmit={(function (e) {e.preventDefault(); this.props.updateRollups(); }).bind(this) }
                        onChange={(function (e) { this.props.setRollupDates(); }).bind(this) }
                        start={p.rollup.start}
                        end={p.rollup.end} />
                    );

        console.log("waiting for rollup data? ", p.rollup.waitingForRollupData);

        return (
            <div className="container rollups">
                <div className="row rollup-row">
                    <div className="col-sm-12">
                        <Paper style={style} zDepth={1}>
                        { drf }
                        </Paper>
                    </div>
                </div>
                {
                    !p.rollup.waitingForRollupData ? (
                        <div className="row">
                            <div className="col-sm-7">
                                <Paper className="chartPaper" style={chStyle}>
                                    <DurationChart
                                        data={p.rollup.chartData.durations}
                                        avg={p.rollup.chartData.averageTotalDuration}
                                        pAvg={p.rollup.chartData.averagePracticeDuration} />
                                </Paper>
                            </div>
                            <div className="col-sm-5">
                                <Paper className="chartPaper" style={chStyle}>
                                    <CollisionCountPieChart data={p.rollup.chartData.collisionPieData} />
                                </Paper>
                            </div>
                        </div>
                    ) : (
                        hangOn
                    )
                }
                
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        rollup: appState.rollup
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        setRollupDates: function () { dispatch( actions.setRollupDates() )},
        updateRollups: function () { dispatch( actions.updateRollups() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RollUps);
