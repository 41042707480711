import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyDrivers from './mydrivers';
// import { C } from '../constants';
// import _ from 'lodash';
// import actions from '../actions';



class ManagerDash extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    render () {

        return (
            <Paper className="spacy-paper">
                <div className="panel" style={{marginBottom: "40px"}}>
                    <div className="panel-body">
                        <div className="row">

                                <h6 className="panel-title">
                                    My Drivers
                                </h6>
                                <div className="dash-section-body">
                                    <MyDrivers />
                                </div>


                        </div>
                        
                        
                        
                    </div>  
                </div>
            </Paper>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}

ManagerDash.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(ManagerDash);