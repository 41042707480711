import { C } from '../constants';

const aRef = C.APP.database().ref('assessments');
 /*
{
            {"user", uid},
            {"loginTime", getTimestampPlaceholder ()},
            {"inProgress", inProgress},
            {"location", loc},
            {"organization", organization},
            {"intake", intakeId},
            {"practiceInProgress", practiceInProgress},
            {"completed", false},
            {"buildTime", ProgressionSettings.Instance.buildTime},
            {"requestErrors", numRequestErrors }
        };

        if (isCustomer) {
            data.Add("isCustomer", true);
        }


        const EXP = {
    id: true,
    organizationName: true,
    location: true,
    firstName: true,
    lastName: true,
    customerId: true,
    startTime: true,
    startDate: true,
    completeDuration: true,
    completed: true,
    hasReplay: true,
    feedbackEmail: true,
    age: true,
    sex: true,
    race: true,
    ethnicity: true,
    licenseStatus: true,
    percentile: true,
    errorScore: true,
    vCrashCount: true,
    pCrashCount: true,
    infractionCount: true,
    stopSignInf: true,
    redLightInf: true,
    navInf: true,
    followInf: true,
    schoolZoneInf: true,
    crosswalkInf: true,
    schoolBusInf: true,
    ambulanceInf: true,
    yieldInf: true,
    constructionInf: true,
    aggSpeedInf: true,
    tooSlowInf: true,
    overSteerInf: true,
    weavingInf: true,
    jerkyInf: true,
    offCourseInf: true,
    offLaneInf: true,
    scanInf: true,
    rearEndNeedsWork: true,
    rearEndCrash: true,
    rearEndDanger: true,
    curveNeedsWork: true,
    curveCrash: true,
    curveDanger: true,
    tooFastNeedsWork: true,
    tooFastCrash: true,
    tooFastDanger: true,
    intersectionNeedsWork: true,
    intersectionCrash: true,
    intersectionDanger: true,
    otherAreasNeedsWork: true,
    otherAreasCrash: true,
    otherAreasDanger: true
}
 */

export const simulateDrive = function (driverId) {
    return function (dispatch, getState) {
        dispatch({type: C.START_SIMULATING});
        let data = {
            user: driverId,
            "loginTime": C.TS,
            "inProgress": true,
            location: 'simulated drive',
            organization: getState().auth.oid,
            intake: "", // will be -MoA8D6NAPu-r8cuSuSE
            practiceInProgress: false,
            completed: false,
            buildTime: 1632167915,
            requestErrors: 0,
            isCustomer: true
        }
        const simAssess = aRef.push();
        simAssess.set(data).then(() => {
            setTimeout(() => { 
                aRef.child(simAssess.key).update({
                    intake: '-MoA8D6NAPu-r8cuSuSE'
                }).then(() => {
                    setTimeout(() => {
                        aRef.child(simAssess.key).update({
                            start: C.TS
                        }).then(() => {
                            setTimeout(()=>{
                                aRef.child(simAssess.key).update({
                                    end: C.TS,
                                    completed : true,
                                    inProgress: false,
                                }).then(() => {
                                    setTimeout(() => {
                                        aRef.child(simAssess.key).update({
                                            replay : {
                                                ref: "replays/2208493/-MoA7Nq5M0QKRuEIoijY/-MoA7Nq5M0QKRuEIoijY.ddd",
                                                url: "https://firebasestorage.googleapis.com/v0/b/diagnostic-driving.appspot.com/o/replays%2F2208493%2F-MoA7Nq5M0QKRuEIoijY%2F-MoA7Nq5M0QKRuEIoijY.ddd?alt=media&token=3169500c-1c2a-4641-b060-f5e516e13a63"
                                            },
                                            feedbackEmail : "abc@onetwothree.com,xyz@fourfivesix.com",
                                            feedbackData : {
                                              "common_crashes" : [ {
                                                "conditions" : [ {
                                                  "name" : "condition_1",
                                                  "truth_value" : true
                                                }, {
                                                  "name" : "condition_2",
                                                  "truth_value" : true
                                                }, {
                                                  "name" : "condition_3",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_4",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_5",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_6",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_7",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_8",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_9",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_10",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_11",
                                                  "truth_value" : true
                                                }, {
                                                  "name" : "condition_12",
                                                  "truth_value" : false
                                                } ],
                                                "name" : "rear_end_common_crash",
                                                "show_handled_well" : false,
                                                "show_in_crash_column" : true,
                                                "show_in_danger_column" : true,
                                                "show_needs_work" : true,
                                                "show_red_exclamation" : true
                                              }, {
                                                "conditions" : [ {
                                                  "name" : "condition_1",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_2",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_3",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_4",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_5",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_6",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_7",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_8",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_9",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_10",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_11",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_12",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_13",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_14",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_15",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_16",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_17",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_18",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_19",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_20",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_21",
                                                  "truth_value" : false
                                                } ],
                                                "name" : "curve_common_crash",
                                                "show_handled_well" : true,
                                                "show_in_crash_column" : false,
                                                "show_in_danger_column" : false,
                                                "show_needs_work" : false,
                                                "show_red_exclamation" : false
                                              }, {
                                                "conditions" : [ {
                                                  "name" : "condition_1",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_2",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_3",
                                                  "truth_value" : false
                                                } ],
                                                "name" : "too_fast_common_crash",
                                                "show_handled_well" : true,
                                                "show_in_crash_column" : false,
                                                "show_in_danger_column" : false,
                                                "show_needs_work" : false,
                                                "show_red_exclamation" : false
                                              }, {
                                                "conditions" : [ {
                                                  "name" : "condition_1",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_2",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_3",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_4",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_5",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_6",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_7",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_8",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_9",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_10",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_11",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_12",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_13",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_14",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_15",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_16",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_17",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_18",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_19",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_20",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_21",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_22",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_23",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_24",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_25",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_26",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_27",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_28",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_29",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_30",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_31",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_32",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_33",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_34",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_35",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_36",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_37",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_38",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_39",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_40",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_41",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_42",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_43",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_44",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_45",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_46",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_47",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_48",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_49",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_50",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_51",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_52",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_53",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_54",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_55",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_56",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_57",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_58",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_59",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_60",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_61",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_62",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_63",
                                                  "truth_value" : false
                                                } ],
                                                "name" : "manage_intersection_common_crash",
                                                "show_handled_well" : true,
                                                "show_in_crash_column" : false,
                                                "show_in_danger_column" : false,
                                                "show_needs_work" : false,
                                                "show_red_exclamation" : false
                                              }, {
                                                "conditions" : [ {
                                                  "name" : "condition_1",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_2",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_3",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_4",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_5",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_6",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_7",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_8",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_9",
                                                  "truth_value" : false
                                                }, {
                                                  "name" : "condition_10",
                                                  "truth_value" : true
                                                }, {
                                                  "name" : "condition_11",
                                                  "truth_value" : false
                                                } ],
                                                "name" : "other_areas_common_crash",
                                                "show_handled_well" : false,
                                                "show_in_crash_column" : false,
                                                "show_in_danger_column" : true,
                                                "show_needs_work" : true,
                                                "show_red_exclamation" : true
                                              } ],
                                              "description" : "version 29",
                                              "infractions_va0" : [ {
                                                "name" : "ambulance_infraction"
                                              }, {
                                                "name" : "construction_infraction"
                                              }, {
                                                "name" : "aggro_speed_infraction_va0"
                                              }, {
                                                "name" : "too_slow_infraction_va0"
                                              }, {
                                                "name" : "over_steer_infraction_va0"
                                              }, {
                                                "name" : "weaving_infraction_va0"
                                              }, {
                                                "name" : "off_lane_center_infraction_va0"
                                              } ],
                                              "infractions_va1" : [ {
                                                "name" : "aggro_speed_infraction_va1"
                                              }, {
                                                "name" : "too_slow_infraction_va1"
                                              }, {
                                                "name" : "weaving_infraction_va1"
                                              } ],
                                              "infractions_va2" : [ {
                                                "name" : "aggro_speed_infraction_va2"
                                              }, {
                                                "name" : "too_slow_infraction_va2"
                                              }, {
                                                "name" : "over_steer_infraction_va2"
                                              }, {
                                                "name" : "weaving_infraction_va2"
                                              } ],
                                              "num_infractions_va0" : 7,
                                              "num_infractions_va1" : 5,
                                              "num_infractions_va2" : 6,
                                              "num_pedestrian_crashes" : 0,
                                              "num_vehicle_crashes" : 0,
                                              "percentile_va0" : 0.6869478057727699,
                                              "percentile_va1" : 0.6907886972708167,
                                              "percentile_va2" : 0.6847441888353683,
                                              "vdt_error_score" : 8
                                            }

                                        }).then(() => {
                                            //done
                                            dispatch({type: C.END_SIMULATING});
                                        })
                                    }, 20000)
                                })
                            }, 20000)
                        })
                    }, 5000)
                })
            }, 20000)
        })

    }
}