import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import Assessment from './components/assessment';
import DateRangeForm from './components/daterangeform';
import NumberForm from './components/numberform';
import StringForm from './components/stringform';
import OverviewOptionForm from './components/overviewoptionform';
import Checkbox from 'material-ui/Checkbox';
import * as perms from "../utils/permission";


class Overview extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showProvisionals: false,
            showSuperDrives: false
        }
    }

    componentWillMount () {
        const isOwnerView = this.props.params && this.props.params.owner;
        if (isOwnerView) {
            store.dispatch( actions.startListeningToOwnerOverview() );
            store.dispatch( actions.startListeningToProvisionals() );
        } else {
            store.dispatch( actions.startListeningToProvisionals() );
            store.dispatch( actions.startListeningToOverview() );
        }
    }

    handleKeyPress (event) {
        if(event.key == 'Enter'){
            const isOwnerView = this.props.params && this.props.params.owner;
            if (isOwnerView) {
                this.props.updateOwnerOverview();
            } else {
                this.props.updateOverview();
            }

        }
    }

    render () {
        
        const p = this.props;

        if (!p.overview.provisionalsLoaded) {
            return <div>One moment please...</div>
        }

        const SuperCheckbox = perms.spr(Checkbox);

        const isOwnerView = this.props.params && this.props.params.owner;
        let aRows = _.map(p.overview.assessmentData, function (aData, aid) {
            console.log(aData.user, p.overview.provisional[aData.user]);
            return <Assessment provisional={p.overview.provisional[aData.organization] && p.overview.provisional[aData.organization][aData.user]} data={aData} aid={aid} key={aid} isOwnerView={isOwnerView} />
        });

        let dash = (
            <div className={(this.state.showProvisionals ? 'showProvisionals' : 'hideProvisionals') + ' ' + (this.state.showSuperDrives ? 'showSuperDrives' : 'hideSuperDrives')}>
                <div className="row ov-row" style={{textAlign: 'center', border: '1px dotted #999'}}>
                    <div className="col-sm-2">
                        <OverviewOptionForm
                            className="overviewOptionsForm"
                            viewLast={p.overview.viewLast}
                            start={p.overview.start}
                            end={p.overview.end}
                            onChange={ (function (e) { this.props.setOverviewQueryType(); }).bind(this) } />
                    </div>
                    <div className={"col-sm-7 " + (p.overview.queryType == "daterange" ? "hide" : "")}>
                        <NumberForm 
                            className="viewLastNumberForm"
                            name="viewLast"
                            label="Number of recent assessments"
                            defaultValue= {p.overview.viewLast}
                            min={1}
                            max={300}
                            step={1}
                            onChange={(function (e) { this.props.setOverviewNumber(); }).bind(this) } />
                        


                    </div>
                    <div className={"col-sm-7 " + (p.overview.queryType == "daterange" ? "" : "hide")}>
                        <DateRangeForm
                            className="date-range-form"
                            onSubmit={(function (e) {e.preventDefault(); p.isOwnerView ? p.updateOwnerOverview() : this.props.updateOverview(); }).bind(this) }
                            onChange={(function (e) { this.props.setOverviewDates(); }).bind(this) }
                            start={p.overview.start}
                            end={p.overview.end} />
                    </div>
                    <div className={"col-sm-3" + (isOwnerView ? "" : " hide")}>
                        <StringForm
                            className="customerIDStringForm"
                            name="customer"
                            label="Filter by driver ID"
                            onChange={(function (e) { this.props.setOverviewCustomer(); }).bind(this) } />
                    </div>
                    <div>

                        <RaisedButton
                            primary
                            disabled={this.props.pristine}
                            label="Set query timespan"
                            onClick={isOwnerView ? this.props.updateOwnerOverview : this.props.updateOverview}
                            fullWidth={true}
                            style={{ width: "100%", marginTop: "15px" }} />

                    </div>
                    <div className='row'>
                        <div className='col-sm-2'></div>
                        <div className='col-sm-8'>{ p.overview.msg }</div>
                        <div className='col-sm-2'>
                            <Checkbox
                                label='Show Provisionals'
                                checked={this.state.showProvisionals}
                                onCheck={(function(e){this.setState({showProvisionals: !this.state.showProvisionals})}).bind(this)}
                                style={{margin: '3px 0px'}} />

                            <SuperCheckbox
                                label='Show Super Drives'
                                checked={this.state.showSuperDrives}
                                onCheck={(function(e){this.setState({showSuperDrives: !this.state.showSuperDrives})}).bind(this)}
                                style={{}} />
                        </div>
                    </div>
                </div>
                { aRows.reverse() }
            </div>
        );

        return (
            <div onKeyPress={this.handleKeyPress.bind(this)}>
                {dash} 
            </div> 
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        overview: appState.overview
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setOverviewDates: function () { dispatch( actions.setOverviewDates() )},
        setOverviewQueryType: function () { dispatch( actions.setOverviewQueryType() )},
        setOverviewNumber: function () { dispatch( actions.setOverviewNumber() ) },
        setOverviewCustomer: function () { dispatch( actions.setOverviewCustomer() ) },
        updateOverview: function () { dispatch( actions.updateOverview() )},
        updateOwnerOverview: function () { dispatch( actions.updateOwnerOverview() )},
    }
}

Overview.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Overview);