import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField } from 'redux-form-material-ui';

const validate = values => {
    const errors = {}
    const requiredFields = ['type', 'name', 'description', 'assetFile'];
    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required'
        }
    })
    return errors
}


const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Try dragging/dropping media file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class AssetForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        return (
            <form
                className="cpt-assett-form"
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            <Field
                                name="type"
                                fullWidth={true}
                                component={SelectField}
                                hintText="What type of media is this?"
                                floatingLabelText="Media Type"
                            >
                                <MenuItem value="image" primaryText="Image (static)" />
                                <MenuItem value="gif" primaryText="Animated GIF" />
                                <MenuItem value="video" primaryText="Video" />
                                <MenuItem value="audio" primaryText="Audio" />
                            </Field>
                        </div>
                        <div>
                            <Field 
                                name="name"
                                fullWidth={true}
                                component={TextField}
                                hintText='Short name to identify the media'
                                floatingLabelText="Name" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor='assetFile'>Media File</label>
                        <Field
                            name='assetFile'
                            component={renderDropzoneInput} />
                    </div>
                </div>
                <div>
                    <Field 
                        name="description"
                        component={TextField}
                        fullWidth={true}
                        multiLine={true}
                        rows={4}
                        hintText="Add a description of this media file here"
                        floatingLabelText="Description"/>
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload Asset"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
                <div style={{paddingTop: 14, fontWeight: 'bold', color: '#FF4443', fontStyle: 'italic', opacity: 0.8, letterSpacing: '1px'}}>Note: Filenames matter! A file uploaded with the same filename as one that is already in the system will overwrite that file!</div>
            </form>
        )
    }
}

// Decorate the form component
AssetForm = reduxForm({
  form: 'asset', // a unique name for this form
  validate
})(AssetForm);


export default AssetForm;