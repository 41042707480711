import { C } from '../../constants';

const initialState = { //define initial state - an empty form
	 sortBy : "start",
	 reverse: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.SET_SORT_STATE:
        return Object.assign({}, state, {
          sortBy : action.sortBy,
          reverse : action.reverse
        });

      default:
        return state;
  }
}