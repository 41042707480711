import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { C } from '../constants';

const buildsRef = C.APP.database().ref('builds');
const buildCommentsRef = C.APP.database().ref('buildComments');
const storage = firebase.storage().ref();

export const startListeningToBuilds = function () {
    return function (dispatch, getState) {
        buildsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_BUILDS_DATA, data: snap.val()});
        });
    }
}

export const startListeningToBuildComments = function () {
    return function (dispatch, getState) {
        buildCommentsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_BUILD_COMMENTS_DATA, data: snap.val()});
        });
    }
}

export const cleanUpBuilds = function () {
    return function (dispatch, getState) {
        buildsRef.once('value').then(tags => {
            _.map(tags.val(), (platforms, tag) => {
                _.map(platforms, (builds, plat) => {
                    // dispatch deleteBuild for all
                    let buildsToDelete = _.map(builds, (url, version) => {
                        return {version, url}
                    }).reverse().slice(2);
                    for (var i = 0; i < buildsToDelete.length; i++) {
                        
                        let thisBuild = buildsToDelete[i];
                        const now = Date.now()
                        const then = new Date(thisBuild.version * 1000)
                        const days = (now - then.getTime()) / 8.64e+7
                        if (days > 14) {
                            let numberOfPointersRemaining = deletePointer(tags, tag, plat, thisBuild.url, thisBuild.version);
                            // delete FB pointer
                            
                            // if ok delete file
                            var filename = thisBuild.url.match(/(\%2F)(.*)(\.zip)/);
                            filename = filename[2] + '.zip';
                            if (!numberOfPointersRemaining) {
                                storage.child('builds').child(filename).delete().then(() => {
                                    console.log("Build in storage was deleted", filename)
                                }).catch((error) => {
                                    console.log("**********an error occured when trying to delete!", filename, " error: ", error)
                                });
                            } else {
                                console.log("Not deleting file from GCS, pointers remain", numberOfPointersRemaining);
                            }
                        }
                    };
                })
            })
        })
    }
}

export const deleteTag = function (tag) {
    return function (dispatch, getState) {
        const tagRef = buildsRef.child(tag);
        const winRef = tagRef.child('win32');
        const macRef = tagRef.child('darwin');

        dispatch({type: C.START_BUILD_ACTION, action: 'delete tag'});

        winRef.on('value', (snap) => {

            //iterate through the urls in this tag's win32 child
            snap.forEach( buildSnap => {
                var buildKey = buildSnap.key;
                var url = buildSnap.val();
                // console.log("###############--WIN--url: ", buildSnap.val());

                dispatch(deleteBuild(tag, 'win32', url, buildKey, true));
            });
        })

        macRef.on('value', (snap) => {

            //iterate through the urls in this tag's darwin child
            snap.forEach( buildSnap => {
                var buildKey = buildSnap.key;
                var url = buildSnap.val();
                // console.log("###############-MAC---url: ", buildSnap.val());

                dispatch(deleteBuild(tag, 'darwin', url, buildKey, true));
            });
        })

        //then delete the tag
        // tagRef.remove();
        //actually, deleting all the builds in the tag view will delete the tag itself by some magic by Luke (or redux?)

        setTimeout(() => {
           dispatch({ type: C.END_BUILD_ACTION, action: 'delete tag'});
        }, 500);
    }
    
}

function deletePointer (builds, tag, platform, url, versionNum) {
    var total = 0;
    builds.forEach(tagSnap => {
        var buildPointers = tagSnap.val()[platform];
        for (var buildPointer in buildPointers) {
            if (buildPointers[buildPointer] === url) {
                total++;
            }
        }
    });
    console.log("removing " + [tag, platform, versionNum].join("/"));
    buildsRef.child(tag).child(platform).child(versionNum).remove();
    return total - 1;
}

export const deleteBuild = function (tag, platform, url, deleteMe, fromDeleteTag) {
    return function (dispatch, getState) {
        dispatch({type: C.START_BUILD_ACTION, action: 'delete build'});

        //check all OTHER tags to see if this url is pointed to anywhere else.
        //if found, just delete this pointer from this tag
        //else delete the build from storage
        buildsRef.once('value').then((snap) => {
            var canWeDelete = true; //assume we can until we can't
            var i = 0; //counter for multiple identical builds in one tag -- important bc we dont want to delete a build in storage if there are other pointers to it
            var j = 0; //counter for all matching pointers
            
            snap.forEach( tagSnap => {
                var pointers = tagSnap.val()[platform];

                for (var x in pointers) {
                    // is this a matching url?
                    if(pointers[x] === url) {
                        j++;//count total matches

                        //this is the one we pressed delete on -- delete it from view
                        if (x == deleteMe) { 
                            buildsRef.child(tag).child(platform).child(x).remove();
                        }
                        //is this a different tag? Don't delete.
                        if (tagSnap.key != tag) {
                            canWeDelete = false;
                        } else if (!fromDeleteTag) {
                            //same tag, Delete Build was pressed
                            i++; //count number of other pointers in same tag who are pointing to this url
                        }
                    }
                }
            })

            //parse the filename from the url
            // var filename = url.match(/(\%2F)(.*)(\%2F)(.*)(\.zip)/);
            var filename = url.match(/(\%2F)(.*)(\.zip)/);
            filename = filename[2] + '.zip';

            if (!fromDeleteTag) {
                //check if there are other pointers to that url in this tag
                canWeDelete = ((j-i)>0) ? false : true;
            }

            //if canwedelete is true, delete the build from storage
            if (canWeDelete) {
                console.log("REMOVING BUILD", filename)
                storage.child('builds').child(filename).delete().then(() => {
                    console.log("Build in storage was deleted", filename)
                }).catch((error) => {
                    console.log("**********an error occured when trying to delete!", filename, " error: ", error)
                });
            } else {
                console.log("this build cannot be deleted from storage yet: ", filename);
            }
        })

        setTimeout(() => {
           dispatch({ type: C.END_BUILD_ACTION, action: 'delete build'});
        }, 500);
    }
    
} 

export const promoteBuild = function (tag, platform, url) {
    return function (dispatch, getState) {
        // make an older file (url) the latest version
        dispatch({type: C.START_BUILD_ACTION, action: 'promote build'});
        const now = Date.now() + getState().util.offset; // server time
        const version = parseInt(new Date(now).valueOf() / 1000);
        const ref = buildsRef.child(tag + "/" + platform);
        ref.child(version).set(url).then(() => {
            dispatch({type: C.END_BUILD_ACTION, action: 'promote build'});
            console.log("promoted build ref in firebase db");
        }).catch((error) => {
            dispatch({type: C.END_BUILD_ACTION, action: 'promote build'});
            console.log("error");
        });
    }
    
}

export const uploadBuild = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.build.values;
        console.log("FORM VALUES: ----> ", formValues);
        let tag = (formValues.tag && formValues.tag.replace(/\s/g,'')) || "";
        const platform = formValues.platform.trim();
        let version = (formValues.version && formValuesVersion.trim()) || "";
        if (!version) {
            const now = Date.now() + getState().util.offset; // server time
            version = parseInt(new Date(now).valueOf() / 1000);
        } 

        const filename = [tag, platform, String(version)].join("-") + ".zip";
        const buildFile = formValues.buildfile[0];
        console.log(buildFile);
        const ext = buildFile.name.substr(buildFile.name.lastIndexOf('.') + 1).trim();

        dispatch({type: C.AWAIT_NEW_BUILD_RESPONSE});

        if (ext != "zip") {
            dispatch({type: C.RECEIVE_BUILD_UPLOAD_ERROR, error: "Must be a zip file."});
        }

        const ref = buildsRef.child(tag + "/" + platform);
        const commentsRef = buildCommentsRef.child(tag + "/" + platform);
        const buildStorageRef = storage.child('builds/' + filename);
        console.log("buildStorageRef: ", buildStorageRef);

        var uploadTask = buildStorageRef.put(buildFile);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', (snapshot) => {

          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            dispatch({type: C.SET_BUILD_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
            }, (error) => {
                // Handle unsuccessful uploads
                dispatch({type: C.RECEIVE_BUILD_UPLOAD_ERROR, error: "An error occurred in the upload process.", 
                    transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
            }, () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                console.log(uploadTask.snapshot);
                uploadTask.snapshot.ref.getDownloadURL().then(dlUrl => {
                    console.log("||||||||||||||| build uploaded successfully |||||||||||||||");
                    console.log(dlUrl);
                    let val = {};
                    ref.child(version).set(dlUrl).then(() => {
                        commentsRef.child(version).set(formValues.comments).then(() => {
                            dispatch({type: C.RECEIVE_NEW_BUILD_RESPONSE});
                            console.log("set build ref in firebase db");
                        });
                        
                    }).catch((error) => {
                        dispatch({type: C.RECEIVE_BUILD_UPLOAD_ERROR, error: "An error occurred in saving build to firebase."});
                        console.log(error);
                    });
                });
                
        });

    }
}