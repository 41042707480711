import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '../store';
import actions from '../actions';
import { withRouter } from 'react-router';

class cptFinish extends Component {

    constructor(props, context) {
        console.log('CPT constructor');
        super(props, context);
        this.state = {

        };
    }

    tryAgain () {

        const p = this.props;
        const m = p.params.module;
        store.dispatch( actions.finishCPT(p.auth.uid , m));
        p.router.replace('/cpt/' + m);
    }

    allDone () {
        const p = this.props;
        const m = p.params.module;
        store.dispatch( actions.finishCPT(p.auth.uid , m));
        p.router.replace('/');
    }


    render () {
        const p = this.props;
        return  <div className={"Page Page-title"}>
                    <header className="Page-header">You've completed {p.cpt.title}</header>
                    <div className="Page-body">
                        <div style={{position: 'relative'}} className={"Page-button"}>
                            <button onClick={this.tryAgain.bind(this)}>Try Again</button>
                        </div>
                        <div style={{position: 'relative'}} className={"Page-button"}>
                            <button onClick={this.allDone.bind(this)}>Go to dashboard</button>
                        </div>
                    </div>
                </div>

        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        cpt: appState.cpt
    };
}

const mapDispatchToProps = function(dispatch) {
    return {}
}

const RoutableCPTFin = withRouter(cptFinish);


export default connect(mapStateToProps, mapDispatchToProps)(RoutableCPTFin);