import _ from 'lodash';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import {Tabs, Tab} from 'material-ui/Tabs';
import Toggle from 'material-ui/Toggle';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import AltSurveys from './altsurveys';
import DriveLimitForm from './drivelimitform';
import EmailSettingsForm from './emailsettingsform';
import CityToggles from './citytoggles';
import OrgToggles from './orgtoggles';
import FeatureFlags from './featureflags';
import OrgCpt from './orgcpt';
import OrgMembers from './orgmembers';
import Locales from './locales';
import Experiments from './experiments';
import Editable from './editable';



const styles = {
  toggle: {
    marginBottom: 16,
  },
  thumbOff: {
    backgroundColor: '#ffcccc',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: 'red',
  },
  trackSwitched: {
    backgroundColor: '#ff9d9d',
  },
  labelStyle: {
    color: 'red',
  },
};

class OrgDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            surveyDrawerOpen : false,
            intakeSurveyOptionsDrawerOpen : false
        };
    }

    componentWillMount () {
        store.dispatch( actions.startListeningToBuilds() );
        store.dispatch( actions.startListeningToCoachingSurveys() );
        store.dispatch( actions.startListeningToCoachingItems() );
        store.dispatch( actions.initOid(this.props.oid) );
        store.dispatch( actions.startListeningToFeatureFlags() );
        store.dispatch( actions.startListeningIntakeSurveyOptions() );
        store.dispatch( actions.startListeningToScoringOptions() );
        store.dispatch( actions.startListeningToOrgTypeOptions() );
        store.dispatch( actions.startListeningToModules() );
        // store.dispatch( actions.startListeningToOrgs() ); // can't assume we already did this
    }


    handleBuildChange(event, index, value) {
        this.props.setOrgBuild(this.props.oid, value);
    }

    handleIntakeSurveyChange(event, index, value) {
        this.props.setOrgIntake(this.props.oid, value);
    }

    handlePostDriveSurveyChange(event, index, value) {
        this.props.setOrgPostDriveSurvey(this.props.oid, value);
    }

    handleSurveySelectQuestionChange(event, index, value) {
        this.props.setSurveySelectQuestion(this.props.oid, value);
    }

    handleScoringMethodChange(event, index, value) {
        this.props.setScoringOption(this.props.oid, value, this.props.orgs.data[this.props.oid].name);
    }

    handleOrgTypeChange(event, index, value) {
        this.props.setOrgTypeOption(this.props.oid, value, this.props.orgs.data[this.props.oid].name);
    }

    handleSurveyDrawerToggle() {
        this.setState({surveyDrawerOpen: !this.state.surveyDrawerOpen});
    }

    handleIntakeSurveyOptionDrawerToggle() {
        this.setState({intakeSurveyOptionsDrawerOpen: !this.state.surveyDrawerOpen});
    }

    render() {
        const p = this.props;
        if (!p.orgs.hasReceivedOrgData) {
            return <div>Loading organization data</div>
        }

        const org = p.orgs.data[p.oid];
        const name = org.name;
        const build = org.build;
        const survey = org.survey; //this is the survey's FB ID. Not sure what the "value" does in SelectField :/
        const numUsed = org.hasOwnProperty('numDrives') ? org.numDrives : 0;
        const limit = org.hasOwnProperty('driveLimit') ? (org.driveLimit < 0 ? "unlimited" : org.driveLimit) : "unlimited";
        let surveyToggles = [];
        let intakeSurveyOptions = [];
        let surveySelectQuestionOptions = [];

        if (p.items.hasReceivedData && p.surveys.hasReceivedData) {
            surveySelectQuestionOptions = _.map(p.items.data, (item, itemId) => {
                if (item.type == 'multvert') {
                    if (item.choices && item.choices.length) {
                        let allowed = true;
                        // check if each choice value is a valid survey
                        for (var i = 0; i < item.choices.length; i++) {
                            let choice = item.choices[i];
                            allowed = allowed && p.surveys.data[choice.value];
                        };
                        if (allowed) {
                            return <MenuItem key={itemId} value={itemId} primaryText={item.name} />
                        }
                    }
                }
                return null;
            })
            surveySelectQuestionOptions.unshift(<MenuItem key={'noSSQ'} value={null} primaryText={'None'} />)
        }

        if (p.surveys.hasReceivedData) {
            surveyToggles = _.map(p.surveys.data, (survey, id) => {
                if (survey.type && survey.type == "intake") {
                    return null;
                }
                const isOn = org.surveys && !!org.surveys[id]
                return (
                    // TODO: add filter to narrow down
                    <Toggle
                        label= {survey.name + " (" + survey.type || "unknown" + ")"}
                        key= {id}
                        toggled = {isOn}
                        onToggle = { (toggleState) => { 
                            p.toggleSurvey(p.oid, org, id, survey, isOn);
                        } }
                        style={{marginBottom: 16}} />
                )
            })
        }

        if (p.intakeSurveyOptions.hasReceivedData) {
            intakeSurveyOptions = _.map(p.intakeSurveyOptions.data, (option, optionId) => {
                const isTgld = org.intakeSurveyOptions && org.intakeSurveyOptions[optionId];
                return (
                    <Toggle
                        label = {option.name}
                        key = {optionId}
                        toggled = {isTgld}
                        onToggle = { (toggleState) => { 
                            p.toggleIntakeSurveyOption(p.oid, org, optionId, option, isTgld);
                        } }
                        style={{marginBottom: 16}} />
                )
            })
        }

        return (
            <div className="userdrawer">
            	<div className="row">
                    <div className="col-sm-8">
                        <h3>{name}</h3>

                        <div>
                            { "Used " + numUsed + " of " + limit + " drive" + (limit == 1 ? "" : "s")}
                        </div>
                    </div>
                    <div style={{position: 'relative'}} className="col-sm-4">
                        <div className="orgParentToggle">
                            <Toggle
                                label="Parent Organization"
                                toggled = {org.config && org.config.isParent}
                                onToggle = {
                                    (toggleState) => {
                                        p.toggleOrgIsParent(p.oid, org);
                                    }
                                } />
                        </div>
                    </div>
                </div>
                <Tabs>
                    <Tab label='Settings'>
                        <div className='row' style={{padding: '17px'}}>
                            <div className='col-sm-6'>
                                <div className="org-config-row">
                                    <SelectField 
                                        value={build || "default"}
                                        disabled={!p.builds.hasReceivedBuildData}
                                        floatingLabelText="Build Tag"
                                        onChange={this.handleBuildChange.bind(this)}>
                                            { p.builds.hasReceivedBuildData ? _.map(p.builds.data, function (build, tag) { 
                                                return <MenuItem key={tag} value={tag} primaryText={tag} />
                                            }) : <MenuItem key={0} value={"default"} primaryText={"Loading Build Tags"} /> }
                                    </SelectField>
                                </div>
                                <div className="org-config-row">
                                    <DriveLimitForm onSubmit={(function (e) {e.preventDefault(); p.updateDriveLimit(p.oid); }).bind(this) } />
                                </div>
                                <div className='org-config-row'>
                                    <EmailSettingsForm org={org} onSubmit={(function (e) {e.preventDefault(); p.setEmailSettings(p.oid); }).bind(this) } />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="org-config-row">
                                    <RaisedButton
                                        label="Edit Surveys"
                                        onTouchTap={this.handleSurveyDrawerToggle.bind(this)} />
                                    <Drawer
                                        docked={false}
                                        width={350}
                                        open={this.state.surveyDrawerOpen}
                                        onRequestChange={(surveyDrawerOpen) => this.setState({surveyDrawerOpen})} >
                                            <div style={{ padding : "50px 15px"}}>
                                                {surveyToggles}
                                            </div>
                                    </Drawer>
                                </div>
                                <div className="org-config-row">
                                    <RaisedButton
                                        label="Edit Intake Survey Options"
                                        onTouchTap={this.handleIntakeSurveyOptionDrawerToggle.bind(this)} />
                                    <Drawer
                                        docked={false}
                                        width={350}
                                        open={this.state.intakeSurveyOptionsDrawerOpen}
                                        onRequestChange={(intakeSurveyOptionsDrawerOpen) => this.setState({intakeSurveyOptionsDrawerOpen})} >
                                            <div style={{ padding : "50px 15px"}}>
                                                {intakeSurveyOptions}
                                            </div>
                                    </Drawer>
                                </div>
                                <div className="org-config-row">
                                    <SelectField 
                                        value={org.intake}
                                        disabled={!p.surveys.hasReceivedData}
                                        floatingLabelText="Intake Survey"
                                        onChange={this.handleIntakeSurveyChange.bind(this)}>
                                            { p.surveys.hasReceivedData ? _.map(p.surveys.data, function (survey, surveyId) { 
                                                return survey.type && survey.type == "intake" ? <MenuItem key={surveyId} value={surveyId} primaryText={survey.name} /> : null;
                                            }) : <MenuItem key={0} value={"default"} primaryText={"Loading Intake Surveys"} /> }
                                    </SelectField>
                                </div>
                                <div className="org-config-row">
                                    <SelectField 
                                        value={org.surveySelector}
                                        disabled={!p.items.hasReceivedData}
                                        floatingLabelText="Survey Select Question"
                                        onChange={this.handleSurveySelectQuestionChange.bind(this)}>
                                            { p.items.hasReceivedData ? surveySelectQuestionOptions : <MenuItem key={0} value={"default"} primaryText={"Loading Intake Surveys"} /> }
                                    </SelectField>
                                </div>
                                <div className="org-config-row">
                                    <SelectField 
                                        value={org.postDriveSurvey}
                                        disabled={!p.surveys.hasReceivedData}
                                        floatingLabelText="Post Drive Survey"
                                        onChange={this.handlePostDriveSurveyChange.bind(this)}>
                                            { p.surveys.hasReceivedData ? _.map(p.surveys.data, function (survey, surveyId) { 
                                                return survey.type && survey.type == "intake" ? <MenuItem key={surveyId} value={surveyId} primaryText={survey.name} /> : null;
                                            }) : <MenuItem key={0} value={"default"} primaryText={"Loading Intake Surveys"} /> }
                                    </SelectField>
                                </div>
                                <div className="org-config-row">
                                    <SelectField 
                                        value={(org && org.config && org.config.scoringMethod) || null}
                                        floatingLabelText="Scoring Method"
                                        onChange={this.handleScoringMethodChange.bind(this)}>
                                            { 
                                                _.map(p.scoringOptions.values, function (scoringOption, index) { 
                                                    return <MenuItem key={index} value={scoringOption} primaryText={scoringOption} />;
                                                })  
                                            }
                                    </SelectField>
                                </div>
                                <div className="org-config-row">
                                    <SelectField 
                                        value={(org && org.config && org.config.orgType) || null}
                                        floatingLabelText="Organization Type"
                                        onChange={this.handleOrgTypeChange.bind(this)}>
                                            { 
                                                _.map(p.orgTypeOptions.values, function (orgTypeOption, index) { 
                                                    return <MenuItem key={index} value={orgTypeOption} primaryText={orgTypeOption} />;
                                                })  
                                            }
                                    </SelectField>
                                </div>
                                <div className='="org-config-row'>
                                    <div>
                                        <span>Pay per assessment: </span>
                                        <Editable type="toggle" value={org.config && org.config.payPerAssessment || false} fullPath={'organizations/' + p.oid + '/config/payPerAssessment'}  />
                                    </div>
                                    <div>
                                        <span>Price: </span>
                                        <Editable type="number" value={org.config && org.config.price || 0} fullPath={'organizations/' + p.oid + '/config/price'}  />
                                    </div>
                                    <div>
                                        <span>Parent Email Field(s): </span>
                                        <Editable type="toggle" value={org.config && org.config.requireParentEmail || false} fullPath={'organizations/' + p.oid + '/config/requireParentEmail'}  />
                                    </div>
                                    <div>
                                        <span>Parent Email Required: </span>
                                        <Editable type="toggle" value={org.config && org.config.validateParentEmail || false} fullPath={'organizations/' + p.oid + '/config/validateParentEmail'}  />
                                    </div>
                                    <div>
                                        <span>Welcome Email:</span>
                                        <Editable type="select"
                                            value={org.config && org.config.welcomeEmail || {id: '0', text: 'none'}}
                                            fullPath={'organizations/' + p.oid + '/config/welcomeEmail'}
                                            options={[
                                                {id: '0', text: 'none'},
                                                {id: '1', text: 'generic'},
                                                {id: '2', text: 'compliance'}
                                              ]}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Cities">
                        <CityToggles org={org} toggleCity={p.toggleCity} oid={p.oid} />
                    </Tab>
                    <Tab label="Features">
                        <FeatureFlags org={org} oid={p.oid} flags={p.flags} toggleFeatureFlag={p.toggleFeatureFlag} />
                    </Tab>
                    <Tab label="Experiments">
                        <Experiments oid={p.oid} org={org} flags={p.flags} />
                    </Tab>
                    <Tab label="Locales">
                        <Locales oid={p.oid} org={org} />
                    </Tab>
                    <Tab label="Other Surveys">
                        <AltSurveys oid={p.oid} org={org} />
                    </Tab>
                    {
                        org.config && org.config.isParent ?

                        <Tab label="Child Orgs">
                            <OrgToggles thisOrg={org} orgs={p.orgs} toggleOrg={p.toggleOrg} oid={p.oid} />
                        </Tab>

                        :

                        null

                    }
                    <Tab label="Members">
                        <OrgMembers org={org} />
                    </Tab>
                    <Tab label="CPT">
                        <OrgCpt org={org} oid={p.oid} modules={p.cpt.allModules} toggleModule={p.toggleModule} />
                    </Tab>
                    
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        orgs: appState.orgs,
        builds: appState.builds,
        surveys: appState.coachingSurveys,
        items: appState.coachingItems,
        flags: appState.featureFlags,
        intakeSurveyOptions: appState.intakeSurveyOptions,
        scoringOptions: appState.scoringOptions,
        orgTypeOptions: appState.orgTypeOptions,
        cpt: appState.cpt
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        setOrgBuild: function (uid, tag) { dispatch( actions.setOrgBuild(uid, tag)) },
        setOrgIntake: function (oid, survey) { dispatch( actions.setOrgIntake(oid, survey) ) },
        setOrgPostDriveSurvey: function (oid, survey) { dispatch( actions.setOrgPostDriveSurvey(oid, survey) ) },
        updateDriveLimit: function (oid) { dispatch( actions.updateDriveLimit(oid)) },
        // START HERE! -- ony one survey of type "intake"
        toggleSurvey: function (oid, org, surveyId, survey, isOn) { dispatch( actions.toggleSurvey(oid, org, surveyId, survey, isOn) )},
        toggleIntakeSurveyOption: function (oid, org, surveyId, survey, isOn) { dispatch( actions.toggleIntakeSurveyOption(oid, org, surveyId, survey, isOn) )},
        toggleCity: function (oid, index, isOn, cities) { dispatch( actions.toggleCity(oid, index, isOn, cities) )},
        toggleFeatureFlag: function (oid, flag, isOn) { dispatch( actions.toggleFeatureFlag(oid, flag, isOn) ) },
        toggleModule: function (oid, module, isOn) { dispatch( actions.toggleModule(oid, module, isOn) ) },
        setSurveySelectQuestion: function (oid, itemId) { dispatch( actions.setSurveySelectQuestion(oid, itemId) ) },
        setEmailSettings: function (oid) { dispatch( actions.setEmailSettings(oid) ) },
        setScoringOption: function (oid, val, orgName) { dispatch( actions.setScoringOption(oid, val, orgName) ) },
        setOrgTypeOption: function (oid, val, orgName) { dispatch( actions.setOrgTypeOption(oid, val, orgName) ) },
        toggleOrgIsParent: function (oid, org) { dispatch( actions.toggleOrgIsParent(oid, org)) },
        toggleOrg: function (parentId, childId, parent, child) { dispatch( actions.toggleOrg(parentId, childId, parent, child) ) }
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgDrawer);