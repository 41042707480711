import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import _ from 'lodash';
import { C } from '../../constants';
import actions from '../../actions';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Popover from 'material-ui/Popover';
import ActionPageView from 'material-ui/svg-icons/action/pageview';
import ContentFileCopy from 'material-ui/svg-icons/content/content-copy';
import CommunicationUnsubscribe from 'material-ui/svg-icons/communication/no-sim';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as hlp from '../../utils/helpers';
import * as perms from "../../utils/permission";
import EventList from './eventlist';
import IntakeButton from './intakebutton';
import IntakeCsv from './intakecsv';
import JsonButton from './jsonbutton';
import OrgDisplay from './orgdisplay';
import Gauge from './gauge';
import UserInfo from './userinfo';
import ScoreButton from './scorebutton';
import ForceBQButton from './forceBQButton';
import SendPDFButton from './sendPdfButton';
import ClearPDFButton from './clearPdfButton';

class FeedbackLink extends Component {
    render () {
        const path = this.props.v2 ? "/download/pdf/" + this.props.aid + "/v2" : "/download/pdf/" + this.props.aid;
        return (
                <Cell label={this.props.v2 ? "v4 report" : "report"}>
                    <Link to={ path }>
                        {this.props.v2 ? "download v4 report" : "download report"}
                    </Link>
                </Cell>
                )
    }
}


class ReplayLink extends Component {
    render () {
        return <Cell label="replay"><Link to={ "/replay/" + this.props.aid }>view replay</Link></Cell>
    }
}

class ComprehensionPopOver extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    handleTouchTap (ev) {
        ev.preventDefault();
        this.setState({
            open: true,
            anchorEl: ev.currentTarget,
        });
    }

    handleRequestClose () {
        this.setState({
            open: false,
        });
    }

    render () {
        console.log("rendering comp", this.props)
        const p = this.props;
        
        if (this.props.hasOwnProperty("results")) {
            return (
            <div>
                <IconButton onTouchTap={this.handleTouchTap.bind(this)}>
                    <ActionPageView />
                </IconButton>
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                    targetOrigin={{horizontal: 'left', vertical: 'top'}}
                    onRequestClose={this.handleRequestClose.bind(this)}
                >
                    <Menu>
                        {
                            _.map(p.results, function ( passed, task ) {
                                return <MenuItem key={task} disabled={true} primaryText={task + " : " + (passed ? "y" : "n")} />
                            })
                        }
                    </Menu>
                </Popover>
            </div>
            );
        }
        return <div>"n/a"</div>;
    }
}

class Cell extends Component {
    constructor(props) {
        super(props);
    }


    render () {
        const p = this.props;
        return (
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 ov-cell">
                <div className="ov-label">
                    { p.label}
                </div>
                <div className="ov-inner">
                    { p.children }
                </div>
            </div>
        )
    }
}

class Replay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlready: false,
            url: "",
            error: false,
            errorMsg: ""
        };
    }

    componentWillMount () {
        const storage = firebase.storage();
        console.log("this.props.data", this.props.data);
        const pathReference = storage.ref(this.props.data.ref);
        console.log(pathReference);
        const compStash = this;

        pathReference.getDownloadURL().then(function(url) {
          compStash.setState({
            urlready: true,
            url: url
          });
        }).catch(function(error) {
            console.log("replay dl url error", error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object_not_found':
              compStash.setState({
                error: true,
                errorMsg: "File not found"
              });
              break;

            case 'storage/unauthorized':
              compStash.setState({
                error: true,
                errorMsg: "Unauthorized"
              });
              break;

            case 'storage/canceled':
              compStash.setState({
                error: true,
                errorMsg: "Cancelled"
              });
              break;

            case 'storage/unknown':
              compStash.setState({
                error: true,
                errorMsg: "Unknown Error"
              });
              break;
          }
        });

    }

    render () {
        const p = this.props;
        if (this.state.error) {
            return <span>{this.state.errorMsg}</span>
        }
        if (!this.state.urlready) {
            return <span>getting download url</span>
        }
        return (
            <a href={this.state.url} target="_blank">{p.label || 'download'}</a>
        )
    }
}

class Assessment extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    handleDuplicate (ev) {
        ev.preventDefault();
        console.log('handleDuplicate');
        this.props.duplicateAssessment(this.props.data, this.props.aid, {includeEmail: true});
    }

    handleDuplicateNoEmail (ev) {
        ev.preventDefault();
        console.log('handleDuplicateNoEmail');
        this.props.duplicateAssessment(this.props.data, this.props.aid);
    }

    render () {


        const p = this.props;
        const aData = p.data;
        const now = Date.now() + p.util.offset; // server time
        const SecureFeedbackLink = perms.spr(FeedbackLink);
        const SecureReplayLink = perms.spr(ReplayLink);
        let end = aData.hasOwnProperty('end') ? new Date(aData.end).toLocaleString() : hlp.determineEndTime(now, aData.lastUpdate);
        let startTrack = aData.hasOwnProperty('trackStart') ? new Date(aData.trackStart).toLocaleString() : "n/a";
        let comp = aData.hasOwnProperty('comp') ? <ComprehensionPopOver results={aData.comp} /> : "n/a";
        let statusClass = end ? "completed" : "in-progress";
        const SuperGauge = perms.spr(Gauge);
        if (!aData.hasOwnProperty('lastUpdate')) {
            statusClass = "completed";
        }
        if (p.isOwnerView) {
            return (
                <Card className={ statusClass + " ov-row " + (p.provisional ? "provisional " : " ") + (C.SUPER_DRIVER_IDS.includes(aData.user) ? "superDrive " : " ")} key={p.aid}>
                    <CardHeader 
                        title={end}
                        subtitle={aData.user}
                        actAsExpander={true}
                        showExpandableButton={true}/>
                    <CardText expandable={true}>
                        <div className="ov-cont">
                            <div className="row">
                                <div className="col-sm-2">
                                    {   aData.feedbackData ?
                                        <Link to={ "/download/pdf/" + p.aid }>
                                            View Feedback Report
                                        </Link>
                                        :
                                        <span>Not yet scored...</span>
                                    }

                                    
                                </div>
                                <div className="col-sm-2">
                                    <IntakeCsv intakeId={aData.intake} filename={p.aid + "_" + aData.intake + "_intake.csv"} />
                                </div>
                                <div className="col-sm-2">
                                    <IntakeButton intakeId={aData.intake} />
                                </div>
                                <div className="col-sm-2">
                                    { aData.hasOwnProperty('replay') && aData.replay.ref ? <Replay data={aData.replay} label={'download replay file'} /> : null }
                                </div>
                                <div className="col-sm-2">
                                    { aData.hasOwnProperty('replay') && aData.replay.ref ? <Link to={ "/replay/" + this.props.aid }>view replay<sup style={{padding : '4px', top: '-10px', fontWeight: 'bolder', fontSize: '58%'}}>BETA</sup></Link> : "no replay" }
                                </div>
                                <div className="col-sm-2">
                                    Completed: { aData.completed ? "Yes" : "No" }
                                </div>
                            </div>
                                { 

                                    aData.hasOwnProperty('scoreData') && aData.scoreData.score >= 0 ? 

                                    <SuperGauge
                                        score={aData.scoreData.score}
                                        topSpace={10}
                                        height={18}
                                        cursorSize={24}
                                        topOffset={5}
                                        showRisk={false} />

                                    :

                                    null

                                }
                            
                            
                        </div>
                    </CardText>
                </Card>
            )
        }
        return (
            <Card className={ statusClass + " ov-row " + (p.provisional ? "provisional" : "") } key={p.aid}>
                <CardHeader
                    title={<div><Link to={"/profile/" + aData.user}>{aData.user}</Link>{aData.user ? <UserInfo uid={aData.user} /> : "No User"}</div>}
                    subtitle={p.aid}
                    actAsExpander={true}
                    showExpandableButton={true} />

                <CardText expandable={true}>
                    <div className="ov-cont">
                        <div className="row">
                            <Cell label="practice in progress?">{ aData.practiceInProgress ? "yes" : "no" }</Cell>
                            <Cell label="map">{ aData.map ? aData.map : "n/a" }</Cell>
                            <Cell label="variant">{ hlp.getVariant(aData) }</Cell>
                            <Cell label="comprehension results">{ comp }</Cell>
                            <Cell label="events">{ aData.hasOwnProperty('events') ? <EventList label={"Events"} map={aData.map} data={aData.events}/> : 'n/a' }</Cell>
                            <Cell label="stats">{ aData.hasOwnProperty('stats') ? <EventList label={"Stats"} map={aData.map} data={aData.stats}/> : 'n/a' }</Cell>
                            <Cell label="replay">{ aData.hasOwnProperty('replay') && aData.replay.ref ? <Replay data={aData.replay} /> : 'no' }</Cell>
                            <Cell label="start">{ new Date(aData.start).toLocaleString() }</Cell>
                            <Cell label="track start">{ startTrack }</Cell>
                            <Cell label="end">{ end }</Cell>
                            <Cell label="completed">{ aData.completed ? "y" : "n" }</Cell>
                            <Cell label="duration">{ hlp.calculateDuration(aData, now) }</Cell>
                            <Cell label="Location">{ aData.location || "n/a" }</Cell>
                            <SecureFeedbackLink aid={p.aid} />
                            <SecureFeedbackLink aid={p.aid} v2={true} />
                            <Cell label="View Intake"><IntakeButton intakeId={aData.intake} /></Cell>
                            <Cell label="Intake CSV"><IntakeCsv intakeId={aData.intake} filename={p.aid + "_" + aData.intake + "_intake.csv"} /></Cell>
                            <Cell label="Organization"><OrgDisplay org={aData.organization} /></Cell>
                            { aData.hasOwnProperty('replay') && aData.replay.ref ? <SecureReplayLink aid={p.aid} /> : null }
                            <Cell label="Score">{ (aData.scoreData && <JsonButton label="Score Data" data={aData.scoreData} />) || 'Not yet scored' }</Cell>
                            <Cell label="Generate Score"> { aData.hasOwnProperty('replay') && aData.replay.ref ? <ScoreButton aid={p.aid} replayRef={aData.replay.ref} /> : "Can't score. No replay." }   </Cell>
                            <Cell label="view JSON"><JsonButton label="View as JSON" data={aData} /></Cell>
                            <Cell label="edit JSON"><JsonButton label="Edit JSON" data={aData} path={"assessments/" + p.aid} /></Cell>
                            <Cell label="Force BQ"><ForceBQButton aid={p.aid} /></Cell>
                            <Cell label="Send PDF"><SendPDFButton aid={p.aid} /></Cell>
                            <Cell label="Clear Cached PDF"><ClearPDFButton aid={p.aid} /></Cell>
                            <Cell label="Duplicate">
                                <IconButton tooltip="with email" style={{marginRight: '20px'}} onClick={this.handleDuplicate.bind(this)}>
                                    <ContentFileCopy />
                                </IconButton>
                                <IconButton tooltip="without email" onClick={this.handleDuplicateNoEmail.bind(this)}>
                                    <CommunicationUnsubscribe />
                                </IconButton>
                            </Cell>

                        </div>
                        { 

                            aData.hasOwnProperty('scoreData') && aData.scoreData.score >= 0 ? 

                            <Gauge
                                score={aData.scoreData.score}
                                topSpace={10}
                                height={18}
                                cursorSize={24}
                                topOffset={5}
                                showRisk={true} />

                            :

                            null

                        }
                    </div>
                </CardText>
            </Card>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        util: appState.util,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        duplicateAssessment : function (asm, aid) { dispatch( actions.duplicateAssessment(asm, aid) ) }
    }
}

Assessment.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Assessment);