import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField } from 'redux-form-material-ui';

const validate = values => {
    const errors = {}
    const requiredFields = ['platform', 'tag', 'comments'];
    requiredFields.forEach(field => {
        if (!values[ field ]) {
            errors[ field ] = 'Required'
        }
    })

    if (values['tag'] == 'default') {
        errors.tag = "No longer allowed to upload direct to default. Please use staging or similar, then promote."
    }

    if (values.version && !/\d+/i.test(values.version)) {
        errors.version = 'Digits only'
    }
    return errors
}

const BUILD_FIELD_NAME = 'buildfile';

const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Try dropping zipped build file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class BuildForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("Rendering build form");
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            <Field
                                name="platform"
                                fullWidth={true}
                                component={SelectField}
                                hintText="Platform"
                                floatingLabelText="Platform"
                            >
                                <MenuItem value="win32" primaryText="Windows" />
                                <MenuItem value="darwin" primaryText="Mac" />
                            </Field>
                        </div>
                        <div>
                            <Field 
                                name="tag"
                                fullWidth={true}
                                component={TextField}
                                hintText='Leave blank for "default"'
                                floatingLabelText="Build Tag (optional)" />
                        </div>
                        <div>
                            <Field 
                                name="version"
                                fullWidth={true}
                                component={TextField}
                                hintText="Leave blank to auto-generate"
                                floatingLabelText="Version Number (optional)" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor={BUILD_FIELD_NAME}>Build</label>
                        <Field
                            name={BUILD_FIELD_NAME}
                            component={renderDropzoneInput} />
                    </div>
                </div>
                <div>
                    <Field 
                        name="comments"
                        component={TextField}
                        fullWidth={true}
                        multiLine={true}
                        rows={4}
                        hintText="Please provide as detailed a list of changes/new features/fixes as possible in thie field."
                        floatingLabelText="Version Comments/Details"/>
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload Build"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
BuildForm = reduxForm({
  form: 'build', // a unique name for this form
  validate
})(BuildForm);


export default BuildForm;