import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import RaisedButton from 'material-ui/RaisedButton';

const driverRe = /^[\da-zA-Z_-]+$/;

const digitRe = /^\d+$/;

const validate = values => {
    const errors = {}
    if (!values[ "driverId" ]) {
        errors[ "driverId" ] = 'Required'
    } else {
        console.log('testing 1,2');
        let driverIdTest = __ODPS__ ? digitRe : driverRe;
        let msg = __ODPS__ ? 'Must only contain numbers' : 'Only letters, numbers, dashes & underscores allowed (no spaces)';
        console.log(msg, driverIdTest.test(values[ "driverId" ]));
        if (!driverIdTest.test(values[ "driverId" ])) {
            errors[ "driverId" ] = msg;
        }
    }

    return errors
}


class SimpleAddDriverIdForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        let hint = __ODPS__ ? "Enter a BASS ID" : "Enter a driver identification value (Like a license number, or employee ID)";

        return (
            <form
                onSubmit={this.props.onSubmit}
                className={this.props.className}>
                <div style={{padding: "44px 11px 0"}}>
                    <h6 className="headerWithTip">{"Add " + (this.props.provisional ? "provisional " : " ") + this.props.idLabel + ":"}</h6>
                    <div>
                        <Field
                            name="driverId"
                            hintText={hint}
                            floatingLabelText={this.props.idLabel}
                            autoComplete="off"
                            component={TextField}
                            style={{ width: "90%", marginTop: 15, marginRight: 20 }} />
                    </div>
                    <div>
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Add"
                            type="submit"
                            style={{ marginTop: 15, marginRight: 20 }} />
                    </div>
                </div>
            </form>
        )
    }
}


// Decorate the form component
SimpleAddDriverIdForm = reduxForm({
  form: 'addDriver' ,// a unique name for this form
  validate
})(SimpleAddDriverIdForm);



export default SimpleAddDriverIdForm;