import { C } from '../../constants';

const initialState = {
    loading : false
}

export default (state = initialState, action) => {
    let entry = {};
    switch (action.type) {
        case C.SET_CHECKOUT_LOADING:
            return Object.assign({}, state, {loading: action.loading});

        default:
            return state;
  }
}