import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  TextField,
  Checkbox
} from 'redux-form-material-ui';


const validate = function (values) {
    const errors = {};
    // if (!values.locale) {
    //     errors.locale = "Locale value is required"
    // }
    return errors;
}



class QueryExportForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {

        return (
            <form onSubmit={this.props.onSubmit}>
                    

                    <label className="qry-ftr-elem">
                        File Name:
                        <Field 
                            name="fileName"
                            component="input"
                            type="text" />
                    </label>
                    <label className="qry-ftr-elem">
                        Separator:
                        <Field 
                            name="columnSeparator"
                            component="input"
                            type="text" />
                    </label>
                    <label className="qry-ftr-elem">
                        <Field
                            name="suppressQuotes"
                            component="input"
                            type="checkbox" />
                        Suppress Quotes
                     </label>
                    <label className="qry-ftr-elem">
                        <button type="submit">Export to CSV</button>
                    </label>
            
            
            </form>
        )
    }
}

// Decorate the form component
QueryExportForm = reduxForm({
  form: 'qcsv', // a unique name for this form
  validate
})(QueryExportForm);

export default QueryExportForm;