import { C } from '../constants';
import { reset } from 'redux-form';
import * as hlp from '../utils/helpers';

const orgsRef = C.APP.database().ref('organizations');
const whRef = C.DB.ref('webhooks');

export const startListeningToWebhooks = function () {
    return function (dispatch, getState) {
        // get webhook data for current logged in org
        let currentOrg = getState().auth.oid;

        whRef.child(currentOrg).on('value', whSnap => {
            dispatch({ type: C.RECEIVE_WEBHOOKS, webhooks: whSnap.val()})
        });
        
    }
}

export const submitNewWebhook = function () {
    return function (dispatch, getState) {
        const state = getState();
        let currentOrg = state.auth.oid;
        const formValues = state.form.webhook.values;
        const url = formValues.url;
        const token = hlp.generateUUID();
        const added = C.TS;
        const active = false;
        dispatch({type: C.AWAIT_NEW_WEBHOOK_RESPONSE});
        const webhook = {url, token, added, active};
        whRef.child(currentOrg).push(webhook).then((snap) => {
            let orgRec = {};
            orgRec[snap.key] = webhook;
            orgsRef.child(currentOrg + '/webhooks').update(orgRec).then(snap => {
                dispatch({type: C.RECEIVE_NEW_WEBHOOK_RESPONSE});
                dispatch(reset('webhook'));
            });
        }).catch((error) => {
            dispatch({type: C.RECEIVE_NEW_WEBHOOK_RESPONSE});
            alert('an error occured adding webhook');
        } )
    }
}

export const deleteWebhook = function (hookID) {
    return function (dispatch, getState) {
        dispatch({type: C.AWAIT_WEBHOOK_DELETION, hookID});
        const state = getState();
        let currentOrg = state.auth.oid;
        whRef.child(currentOrg + '/' + hookID).set(null).then(() => {
            orgsRef.child(currentOrg + '/webhooks/' + hookID).set(null).then(() => {
                // do nothing.
            })
        })
    }
}

export const toggleWebhook = function (hookID, active) {
    return function (dispatch, getState) {
        const state = getState();
        let currentOrg = state.auth.oid;
        whRef.child(currentOrg + '/' + hookID + '/active').set(!active);
    }
}

