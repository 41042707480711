

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import store from '../../../store';


class SimpleAsset extends Component {

    componentWillMount () {
        const assetId = this.props.assetId
        store.dispatch( actions.startListeningToAsset(assetId) );
    }

    render () {
        const p = this.props;
        const assetId = p.assetId;
        let thisAsset = p.assets.assets[assetId]

        if (thisAsset && thisAsset.hasReceivedData) {
            // figure out which media type and render it
            let type = thisAsset.assetData.type;
            if (type == 'image' || type == 'gif') {
                return <img src={thisAsset.url} />
            }
            if (type == 'video') {
                return <video controls>
                            <source src={thisAsset.url} type="video/mp4" />
                            Your browser does not support HTML5 video
                        </video>
            }

            if (type == 'audio') {
                return <audio controls>
                            <source src={thisAsset.url} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>;
            }
            return <div>Something went wrong.</div>
        }

        

        return (
            <div>
                One moment please...
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        assets: appState.assets,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SimpleAsset);
