
import emailValidator from 'email-validator';
import _ from 'lodash';
import ChipInput from 'material-ui-chip-input';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as perms from "../utils/permission";
import InviteOptionsForm from './components/inviteoptionsform';
import Toggle from 'material-ui/Toggle';



const re = /\s*,\s*|\s+/;

const SuperOptions = perms.spr(InviteOptionsForm);

function getDeleteButton (invite, deleteInvite) {
    let action = "Revoke"
    if (invite.value.registered) {
        action = "Delete"
    }

    return (
        <RaisedButton
            onClick={() => {
                deleteInvite();
            }} 
            label={ action + " Invite"} 
            fullWidth={true}
            secondary={true} 
            style={{marginRight: 20}}/>
    );
}

function getRegistered (invite) {

    if (invite.value.invitee == "open") {
        return (
                <div className="row">
                    <div className="col-sm-6 text-desc"> Registration Count:</div>
                    <div className="col-sm-6"><b>{invite.value.registrationCount}</b></div>
                </div>
                )
    }

    invite.value.registered ? "Registered: " + new Date(invite.value.registered).toLocaleString() : "Not yet registered."
    if (invite.value.registered) {
        return (
            <div className="row">
                <div className="col-sm-6 text-desc"> Registered:</div>
                <div className="col-sm-6"><b>{new Date(invite.value.registered).toLocaleString()}</b></div>
            </div>
        )
    }
    return (<div className="row"><div className="col-sm-12"><b>Not yet registered.</b></div></div>);
}

function getInviteButtons (invite, resendInvite, deleteInvite) {
    const reSendButton = (
        <RaisedButton
            onClick={() => {
                resendInvite();
            }} 
            label="Re-send Invite" 
            fullWidth={true}
            secondary={true} 
            style={{marginRight: 20}}/>
    )
    return (
        <div className="col-sm-3">
            {
                invite.value.invitee == "open" ?  null :
                <div className="invite-button">
                    {!invite.value.registered ? reSendButton : ""}
                </div>
            }
            
            <div className="invite-button">
                {getDeleteButton(invite, deleteInvite)}
            </div>
        </div>
    )
}

let listeningTo = {}


class Invites extends Component {

    constructor(props, context) {
        super(props, context);
        this.mgrLinkInput = null;
        this.mgrLinkInputCallback = this.mgrLinkInputCallback.bind(this);
        this.state = {
            emails : [],
            errorEmails : [],
            linkMsg : "",
            waiveFee : false
        };
    }

    mgrLinkInputCallback (e) {
        this.mgrLinkInput = e;
    }

    copyUrlToClipboard () {

        /* Select the text field */
        this.mgrLinkInput.select();

        /* Copy the text inside the text field */
        document.execCommand("copy");

        this.setState({linkMsg: "Copied to Clipboard"});
    }

    componentWillMount () {
        let iv = this.props.auth.invites;
        for (var key of Object.keys(iv)) {
            listeningTo[key] = true;
            store.dispatch( actions.startListeningToInvite( key ) );
            
        }

        store.dispatch( actions.startListeningToOrgs() );

    }

    componentWillReceiveProps (nextProps) {
        let iv = nextProps.auth.invites;
        for (var key of Object.keys(iv)) {
            if (!listeningTo[key]) {
                listeningTo[key] = true;
                store.dispatch( actions.startListeningToInvite( key ) );
            }
        }
    }

    handleAddChip(chip) {
        let values = chip.split(re);
        values = values.map( (s) => { return s.trim() })
        values = values.filter( (str, idx) => { return str.length } )
        let errorEmails = [];
        let newEmails = [];
        for (var em of values) {
            if (!emailValidator.validate(em)) {
                errorEmails.push(em)
            } else {
                newEmails.push(em)
            }
        }
        this.setState({
            emails : [...this.state.emails, ...newEmails],
            errorEmails : errorEmails
        });

    }

    handleDeleteChip(chip) {
        this.setState({
            emails : this.state.emails.filter((str, idx) => {return str !== chip}),
            errorEmails : []
        });
    }

    handleToggleWaiveFee() {
        this.setState({
            waiveFee : !this.state.waiveFee
        })
    }

    

    render () {
        const p = this.props;
        let errorMessage = "";
        if (this.state.errorEmails.length) {
            errorMessage = this.state.errorEmails.join(", ") +
            (this.state.errorEmails.length > 1 ? " are not valid emails" : " is not a valid email");
        } 
        console.log("rendering invites page", this.state, errorMessage);
        console.log("current user's auth: ", p.auth);

        const thisComp = this;
        

        const invites = p.invites;

        let orgData = p.orgs && p.orgs.data && p.orgs.data[p.auth.oid];

        console.log("ORG DATA", orgData);

        if (!orgData) {
            return <div>Loading...</div>
        }

        let payPerAssessment = orgData.config && orgData.config.payPerAssessment;

        //TODO: KNOWN ISSUE
        // Auth.invites and general "invites" reducers are separate async events
        // usually auth will update and not see invites change in time
        // We need to put the actual invite data in the auth store

        const inviteRows = _.map(p.auth.invites, function (email, inviteId) {
            console.log("inviteId", inviteId);
            const invite = invites.data[inviteId];
            console.log("INVITE:", invite);

            const openInvite = invite && invite.value && invite.value.invitee && invite.value.invitee == "open";

            // TODO: For some reason the emails are the same
            // This doesn't matter at the moment -- but would be good to know why
            return (
                <div key={inviteId} className="invite-item">
                    { invite && invite.value && invite.value.invitee ? (
                        <div>
                            <div className="row invite-row">
                                <div className="col-sm-4 display-6">
                                    {openInvite ? "Open Registration Link" : invite.value.invitee}
                                </div>
                                <div className="col-sm-5">
                                    <div className="row">
                                        <div className="col-sm-6 text-desc">
                                            {openInvite ? "Generated:" : "Invited:"}
                                        </div>
                                        <div className="col-sm-6">
                                            <b>{new Date(openInvite ? invite.value.generated : invite.value.invited).toLocaleString()}</b>
                                        </div>
                                    </div>
                                    {openInvite ? null : 
                                        <div className="row">
                                            <div className="col-sm-6 text-desc">
                                                Last e-mail sent:
                                            </div>
                                            <div className="col-sm-6">
                                                <b>{new Date(invite.value.lastEmailSent).toLocaleString()}</b>
                                            </div>
                                        </div>
                                    }
                                    
                                    <div>{ getRegistered(invite) }</div>
                                </div>
                                { getInviteButtons(invite,
                                    () => { p.resendInviteEmail(inviteId) },
                                    () => { p.deleteInvite(inviteId, p.auth.uid) })
                                }
                                {openInvite ? 
                                    <div className="col-sm-12">
                                        <div style={{marginTop: "16px"}}>
                                            <label>Your Manager Invite Link:</label>


                                            <input style={{width: "500px", margin: "0px 10px"}} type="text" value={window.location.origin + "/register/" + inviteId} ref={thisComp.mgrLinkInputCallback} id="mgrInviteUrl" />


                                            <RaisedButton 
                                                onClick={() => {
                                                    thisComp.copyUrlToClipboard();
                                                }} 
                                                style={{marginRight: "10px"}}
                                                label="Copy to Clipboard" />
                                            <span>{thisComp.state.linkMsg}</span>
                                        </div>
                                        <div style={{paddingLeft:"10px"}}>
                                            <small className="protip">When people register with this link they will be added to {p.orgs && p.orgs.data && p.orgs.data[p.auth.oid] ? "the " + p.orgs.data[p.auth.oid].name : "your"} organization with you as their manager</small>
                                        </div>
                                    </div> : null
                                }
                                
                            </div>

                        </div>
                    ) : (
                        "Loading..."
                    )}
                </div>
            )
        })

        return (

            <div>
                
                <div className="panel">
                    <div className="panel-body">
                        <h6 className="headerWithTip">Add email addresses to invite:</h6>
                        <small className="protip">Pro tip: You can paste a list that is comma and/or whitespace separated</small>
                        <div>
                            <ChipInput
                                value={this.state.emails}
                                onRequestAdd={(chip) => this.handleAddChip(chip)}
                                onRequestDelete={(chip) => this.handleDeleteChip(chip)}
                                fullWidth={true} />
                        </div>

                        {
                            payPerAssessment ?
                            <div>
                                <Toggle
                                    label="Waive Fee?"
                                    toggled = {this.state.waiveFee}
                                    onToggle = {
                                        (toggleState) => {
                                            this.handleToggleWaiveFee();
                                        }
                                    } />
                            </div> :
                            null
                        }
                        <div className="errorText">
                            {errorMessage}
                        </div>
                        <SuperOptions orgs={p.orgs} defaultOrganization={p.auth.oid}/>
                        <div>
                            <RaisedButton
                                onClick={() => {
                                    this.props.inviteNewUsers(this.state.emails, this.props.auth.uid, this.props.auth.oid, orgData.config && orgData.config.payPerAssessment, this.state.waiveFee, orgData.config && orgData.config.requireParentEmail, orgData.config && orgData.config.validateParentEmail);
                                    this.setState({emails : []})
                                }} 
                                label="Invite" 
                                primary={true} 
                                disabled={!this.state.emails.length}
                                style={{marginRight: 20}}/>
                        </div>
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        {inviteRows}
                    </div>
                </div>
                {p.auth.openInvite ? null : 
                    <div className="panel">
                        <div className="panel-body">


                            <RaisedButton 
                                onClick={() => {
                                    this.props.generateOpenInvite(this.props.auth.uid, this.props.auth.oid, "open", (orgData.config && orgData.config.payPerAssessment || false), orgData.config && orgData.config.requireParentEmail, orgData.config && orgData.config.validateParentEmail);
                                }} 
                                
                                label="Generate Open Invite" />
                        </div>
                    </div>
                }
                
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        invites: appState.invites,
        orgs: appState.orgs
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        inviteNewUsers: function (listOfEmails, invitor, oid, payPerAssessment, waiveFee, requireParentEmail, validateParentEmail) {
            dispatch( actions.inviteNewUsers(listOfEmails, invitor, oid, payPerAssessment, waiveFee, requireParentEmail, validateParentEmail) )
        },
        resendInviteEmail: function (inviteId) {
            dispatch( actions.resendInviteEmail(inviteId) )
        },
        deleteInvite: function (inviteId, invitor) {
            console.log("dispatching delete invite action");
            dispatch( actions.deleteInvite(inviteId, invitor) )
        },
        generateOpenInvite: function (invitor, oid, type, pay, requireParentEmail, validateParentEmail) {
            dispatch( actions.generateOpenInvite(invitor, oid, type, pay, requireParentEmail, validateParentEmail) )
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Invites);
