import React, { Component, PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import ActionHelp from 'material-ui/svg-icons/action/help';

const styles = {
  mediumIcon: {
    width: 48,
    height: 48,
  },
  medium: {
    width: 96,
    height: 96,
    padding: 24,
  }
};

class HelpDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    handleOpen () {
        this.setState({open: true});
    }

    handleClose () {
        this.setState({open: false});
    }

    render() {
        const actions = [
            <FlatButton
                label="OK"
                primary={true}
                onClick={this.handleClose.bind(this)}/>
        ];

        return (
            <span>
                <IconButton
                    onClick={this.handleOpen.bind(this)}
                    iconStyle={this.props.iconStyle || styles.mediumIcon}
                    style={this.props.style || styles.medium}>
                    <ActionHelp />
                </IconButton>
                <Dialog
                    title={this.props.title}
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose.bind(this)}>
                    {this.props.children}
                </Dialog>
            </span>
    );
  }
}

HelpDialog.propTypes = {
    title: PropTypes.string.isRequired
}

export default HelpDialog;