import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const normalizeDriveLimit = value => value < -1 ? -1 : value;

class DriveLimitForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    blurIfZero (e, to, from) {
        if (to == 0) {
            e.target.blur();
        }
    }

    render () {

        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div>
                    <span style={{padding:"8px"}}>
                       <Field
                            name="driveLimit"
                            component={TextField}
                            hintText="Enter Drive Limit"
                            step={this.props.driveLimitValue < 0 ? 1 : 10}
                            floatingLabelText="Drive Limit"
                            onChange={this.blurIfZero.bind(this)}
                            type="number"
                            normalize={normalizeDriveLimit} />
                    </span>
                    <span style={{padding:"8px"}}>
                        <RaisedButton
                            primary
                            disabled={this.props.pristine}
                            label="Update Drive Limit"
                        type="submit" />
                    </span>
                </div>
            </form>
        )
    }
}


// Decorate the form component
DriveLimitForm = reduxForm({
  form: 'driveLimit' // a unique name for this form
})(DriveLimitForm);

const selector = formValueSelector('driveLimit')

DriveLimitForm = connect(
  state => ({
    driveLimitValue: selector(state, 'driveLimit'),
    initialValues: state.initial
  })
)(DriveLimitForm);



export default DriveLimitForm;