import MenuItem from 'material-ui/MenuItem';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField, AutoComplete } from 'redux-form-material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import {AutoComplete as MUIAutoComplete} from 'material-ui';


const radioButtonStyle = {
    marginBottom: 16
};

const validate = (values, props) => {
    const errors = {}
    if (!values[ "oid" ]) {
        errors[ "oid" ] = 'Required'
    }

    if (!values[ "driverId" ]) {
        errors[ "driverId" ] = 'Required'
    }

    if (!props.driverIds.data[values["driverId"]]) {
        errors["driverId"] = 'Driver ID not found in system';
    }
    return errors
}

class PaymentForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {



        return (
            <div>
                <form className={this.props.className} onSubmit={this.props.onSubmit}>
                    {false ? 

                    <div>
                        <Field
                            name="oid"
                            component={SelectField}
                            fullWidth={true}
                            floatingLabelText={"Organization"}>
                            {
                                _.map(this.props.orgs.data, (item, key) => {
                                    return <MenuItem value={key} key={key} primaryText={item.name} />
                                })
                            }
                        </Field>
                    </div>

                    : null}
                    <div>
                        

                        <Field
                            name="driverId"
                            component={AutoComplete}
                            floatingLabelText="Driver ID"
                            hintText="Enter driver ID."
                            openOnFocus
                            filter={MUIAutoComplete.fuzzyFilter}
                            dataSource={Object.keys(this.props.driverIds.data)} />
                    </div>
                    <div>
                        <RaisedButton
                            primary
                            disabled={this.props.pristine || !this.props.valid}
                            label="Checkout"
                            type="submit"
                            style={{ width: "100%", marginTop: "15px" }} />
                    </div>

                </form>
            </div>
        )

        
    }
}

// Decorate the form component
PaymentForm = reduxForm({
  form: 'payment', // a unique name for this form
  validate
})(PaymentForm);

PaymentForm = connect(
  state => ({
    initialValues: state.auth // pull initial values from auth oid
  })
)(PaymentForm);

export default PaymentForm;