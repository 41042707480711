

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../actions';
import LoginForm from './components/loginform';

class Login extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        // if logged in user somehow gets here
        // immediately re-route them to main page
        // This should take a "next" param -- then redirect to that after login?
        this.redirectIfLoggedIn(this.props.auth, this.props.util);

        // document.body.style.background = "#3b125d";
    }

    componentWillReceiveProps (nextProps) {
        this.redirectIfLoggedIn(nextProps.auth, nextProps.util);
    }

    redirectIfLoggedIn (auth, util) {
        const { router } = this.context
        if (auth.uid) {
            if (util.attemptedPath) {
                
                let path = util.attemptedPath;
                actions.dbDbug({msg: "attemptedPath redirecting", path});
                console.log('CLEARING ATTEMPTED PATH *************************');
                this.props.clearAttemptedPath();
                console.log('REPLACING WITH ' + path);
                router.replace(path);

            } else {
                router.replace('/');
            }
            
        }
    }

    // componentWillUnmount () {
    //     document.body.style.background = "#FFF";
    // }

    render () {
        const auth = this.props.auth;
        return (
            //We are using CSS to hide/show spinner when attempting login
            <div className={ this.props.auth.currently + " login" }>
                <div className="login-form">
                    <div className="dxd-login-logo" />
                    <div className="dxd-loginSpinner" />
                    <LoginForm className="dxd-login-form" 
                        onSubmit={(function (e) {e.preventDefault(); this.props.dismissAllErrors(); this.props.attemptLogin(); }).bind(this) } />
                    <div className="small forgot" style={{ textAlign: "center", padding: "8px"}}>
                        <Link to={"/reset"}>I forgot my password.</Link>
                    </div>
                </div>
            </div>
        )
    }
}

Login.contextTypes = {
    router: React.PropTypes.object.isRequired
};

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        util: appState.util
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        attemptLogin: function() { dispatch(actions.attemptLogin()); },
        dismissAllErrors: function() { dispatch(actions.dismissAllErrors()); },
        clearAttemptedPath: function() { dispatch(actions.clearAttemptedPath()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
