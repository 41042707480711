import firebase from 'firebase/compat/app';
import { C } from '../constants';
const assessmentsBaseRef = C.APP.database().ref('assessments');
const userRef = C.APP.database().ref('users');
const provRef = C.APP.database().ref('provisional');

let ovwQueryListener = null;

const storage = firebase.storage();

export const startListeningToAssessments = function () {
    return function (dispatch, getState) {
        assessmentsBaseRef.once('value', (snapshot) => {
            dispatch({ type: C.RECEIVE_ALL_ASSESSMENTS_DATA, data: snapshot.val()})
            // iterate through data - creat a list of promises and use promise.all
            // each promise will get DownloadUrl for the replay url
            // dispatch = add replay download url
            // then when all are complete -- dispatch all download URLs added
            let getDLUrlPromises = [];
            _.map(snapshot.val(), (aData, aid) => {
                if (aData.replay && aData.replay.ref) {
                    const pathRef = storage.ref(aData.replay.ref);
                    getDLUrlPromises.push( pathRef.getDownloadURL().then(url =>  {
                        return {aid, url};
                    }).catch(err => {
                console.log('error getting dl url', err);
            }) )
                }
            })

            Promise.all(getDLUrlPromises).then(results => {
                for ( let result of results ) {
                    dispatch({ type: C.UPDATE_REPLAY_DOWNLOAD_URL, aid: result.aid, url: result.url});
                }
                dispatch({ type: C.ALL_REPLAY_DOWNLOAD_URLS_UPDATED });
            });

        })
    }
}


export const startListeningToOwnerOverview = function (mid) {
    return (dispatch, getState) => {
        /*
            // get all customers
            // get the addedBy user
            // get that users organization
            // if that organization is the current users organization
            // get the assessment(s) for that user within the timespan
        */
        dispatch(updateOwnerOverview());
        
    }
}

const whitespace = /^\s+|\s+$/g;

export const updateOwnerOverview = function () {
    return function (dispatch, getState) {
        const state = getState();
        const orgId = state.auth.oid;
        let viewLast = state.overview.viewLast;
        let customer = state.overview.customer.replace(whitespace, '');;
        let qByDR = state.overview.queryType == "daterange";
        if (ovwQueryListener) {
            assessmentsBaseRef.off('value', ovwQueryListener);
        }
        if (qByDR) {
            let param = customer ? 'orgCustEnd' : 'orgEnd';
            let start = orgId + customer + state.overview.start.getTime();
            let end = orgId + customer + state.overview.end.getTime()
            console.log(param, start, end);
            ovwQueryListener = assessmentsBaseRef.orderByChild(param).startAt(start).endAt(end).on('value', (snap) => {
                dispatch({ type: C.RECEIVE_ASSESSMENTS_DATA, data: snap.val()})
            });
        } else {
            let whichParam = customer ? 'orgCust' : 'organization';
            ovwQueryListener = assessmentsBaseRef.orderByChild(whichParam).equalTo(orgId + customer).limitToLast(viewLast).on('value', (snap) => {
                dispatch({ type: C.RECEIVE_ASSESSMENTS_DATA, data: snap.val()})
            });
        }
        
    }
}

export const listenToCustomerAssessments = function (uid, oid) {
    return function (dispatch, getState) {

        console.log('listenToCustomerAssessments', oid, uid);
        assessmentsBaseRef.orderByChild('orgCust').equalTo(oid + uid).limitToLast(1000).on('value', (snap) => {
            dispatch({ type: C.RECEIVE_CUSTOMER_ASSESSMENTS_DATA, data: snap.val()})
        });
    }
}

export const startListeningToManagerOverview = function (mid) {
    return (dispatch, getState) => {
        userRef.child(mid).once('value', (snap) => {
            
            // for each driver
            // load that driver
            // for each assessment
            // load that assessment and put the data in the store

            let drivers = [];

            let getDrivers = [];

            _.map(snap.val().drivers, (data, drId) => {
                getDrivers.push( userRef.child(drId).once('value', (dSnap) => {
                    return dSnap;
                }))
            });

            Promise.all(getDrivers).then(results => {
                for (let result of results) {
                    _.map(result.val().assessments, (ts, aid) => {
                        assessmentsBaseRef.child(aid).on("value", (aSnap) => {
                            dispatch({ type: C.RECEIVE_ASSESSMENT_FOR_MANAGER, aid, data: aSnap.val(), mid})
                        })
                    })
                }
            })

        })
    }
}

export const startListeningToOverview = function () {
    return function (dispatch, getState) {
        dispatch(updateOverview());
    }
}


export const setOverviewDates = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.datetimerange.values;
        console.log(formValues);
        console.log(typeof formValues.startDate);
        console.log(typeof formValues.startTime);
        let startDate = formValues.startDate || state.rollup.start;
        let startTime = formValues.startTime || state.rollup.start;
        let endDate = formValues.endDate || state.rollup.end;
        let endTime = formValues.endTime || state.rollup.end;
        let start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 
               startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
        let end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 
               endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());



        dispatch({ type: C.SET_OVERVIEW_TIME_RANGE, start: start, end: end});

    }
}

export const setOverviewNumber = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.number.values; 
        dispatch({ type: C.SET_OVERVIEW_LASTX, viewLast: formValues.viewLast});
    }
}

export const setOverviewCustomer = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.stringy.values; 
        dispatch({ type: C.SET_OVERVIEW_CUSTOMER, customer: formValues.customer});
    }
}

export const updateOverview = function () {
    return function (dispatch, getState) {
        const state = getState();
        let viewLast = state.overview.viewLast;
        let qByDR = state.overview.queryType == "daterange";
        if (ovwQueryListener) {
            assessmentsBaseRef.off('value', ovwQueryListener);
        }
        if (qByDR) {
            ovwQueryListener = assessmentsBaseRef.orderByChild('lastUpdate').startAt(state.overview.start.getTime()).endAt(state.overview.end.getTime()).on('value', (snap) => {
                dispatch({ type: C.RECEIVE_ASSESSMENTS_DATA, data: snap.val()})
            })
        } else {
            ovwQueryListener = assessmentsBaseRef.limitToLast(viewLast).on('value', (snap) => {
                dispatch({ type: C.RECEIVE_ASSESSMENTS_DATA, data: snap.val()})
            });
        }
        
    }
}

export const setOverviewQueryType = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.overviewQueryOption.values;
        console.log("changing overview query type", formValues);
        dispatch({ type: C.SET_QUERY_TYPE, queryType: formValues.queryType})
    }
}

// This starts listening to provisionals for the overview hiding function
export const startListeningToProvisionals = function () {
    return function (dispatch, getState) {
        provRef.on("value", (snp) => {
            dispatch({ type: C.RECEIVE_PROVISIONAL_DATA, data: snp.val()})
        })
    }
}


