import _ from 'lodash';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import Toggle from 'material-ui/Toggle';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import Editable from './editable';
import JsonButton from './jsonbutton';


const styles = {
  toggle: {
    marginBottom: 16,
  },
  thumbOff: {
    backgroundColor: '#ffcccc',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: 'red',
  },
  trackSwitched: {
    backgroundColor: '#ff9d9d',
  },
  labelStyle: {
    color: 'red',
  },
};

class UserDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            managerDrawerOpen : false,
            driverDrawerOpen: false,
            userAlertDrawerOpen: false,
            orgAlertDrawerOpen: false
        };
    }

    componentWillMount () {
        store.dispatch( actions.startListeningToBuilds() );
    }

    handleGroupChange(event, index, value) {
        this.props.changeUserGroup(this.props.uid, value, this.props.user.fullname);
    }

    handleOrgChange(event, index, value) {
        this.props.changeUserOrg(this.props.uid, value, this.props.user.fullname);
    }

    handleManagerDrawerToggle() {
        this.setState({managerDrawerOpen: !this.state.managerDrawerOpen});
    }

    handleDriverDrawerToggle() {
        this.setState({driverDrawerOpen: !this.state.driverDrawerOpen});
    }

    handleUserAlertDrawerToggle() {
        this.setState({ userAlertDrawerOpen: !this.state.userAlertDrawerOpen });
    }

    handleOrgAlertDrawerToggle() {
        this.setState({ orgAlertDrawerOpen: !this.state.orgAlertDrawerOpen });
    }

    render() {
        const p = this.props;
        //TODO: This is super slow - we need a snappier interface for this
        // easiest is just to pull this into a new page per user (rather than a drawer)
        let managerToggles = [];
        let driverToggles = [];
        let userAlertToggles = [];
        let orgAlertToggles = [];

        if (p.orgs.hasReceivedOrgData) {
            orgAlertToggles = _.map(p.orgs.data, function (orgData, orgId) {
                const isCurrentlyAlerted = !!(p.user.alerts && p.user.alerts.orgs && p.user.alerts.orgs[orgId]);
                return (
                    // TODO: add filter to narrow down
                    <Toggle
                        label= {orgData.name}
                        key= {orgId}
                        toggled = {isCurrentlyAlerted}
                        onToggle = { (toggleState) => { 
                            p.toggleOrgAlert(p.uid, p.user, orgId, orgData, isCurrentlyAlerted);
                        } }
                        style={styles.toggle} />
                )
            })
        }

        if (p.users.hasReceivedUserData) {
            managerToggles = _.map(p.users.data, function(targetUserData, targetUserId) {
                const isManager = p.user.managers && !!p.user.managers[targetUserId]
                const isDriver = p.user.drivers && !!p.user.drivers[targetUserId]
                const isAlerter = p.user.alerts && p.user.alerts.user && !!p.user.alerts.user[targetUserId]
                if (p.user.group !== "driver") {
                    driverToggles.push(<Toggle
                        label= {targetUserData.fullname}
                        key= {targetUserId}
                        toggled = {isDriver}
                        onToggle = { (toggleState) => { 
                            // a bit confusing - by making this drawer's user the manager, 
                            // we are making the toggled user the driver (and vice versa)
                            p.toggleManager(targetUserId, targetUserData, p.uid, p.user, isDriver);
                        } }
                        style={styles.toggle} />);

                    userAlertToggles.push(<Toggle
                        label= {targetUserData.fullname}
                        key= {targetUserId}
                        toggled = {isAlerter}
                        onToggle = { (toggleState) => { 
                            p.toggleUserAlert(targetUserId, targetUserData, p.uid, p.user, isAlerter);
                        } }
                        style={styles.toggle} />)
                }
                
                return (
                    // TODO: add filter to narrow down
                    <Toggle
                        label= {targetUserData.fullname}
                        key= {targetUserId}
                        toggled = {isManager}
                        onToggle = { (toggleState) => { 
                            p.toggleManager(p.uid, p.user, targetUserId, targetUserData, isManager);
                        } }
                        style={styles.toggle} />
                )
            });
        }

        const driverDrawerToggle = (
            <div>
                <RaisedButton
                    label="Edit Drivers"
                    onTouchTap={this.handleDriverDrawerToggle.bind(this)} />
                <Drawer
                    docked={false}
                    width={350}
                    openSecondary={true}
                    open={this.state.driverDrawerOpen}
                    onRequestChange={(driverDrawerOpen) => this.setState({driverDrawerOpen})} >
                        <div style={{ padding : "50px 15px"}}>
                            {driverToggles}
                        </div>
                </Drawer>
            </div>
        )

        const userAlertDrawerToggle = (
            <div>
                <RaisedButton
                    label="Edit User Alerts"
                    onTouchTap={this.handleUserAlertDrawerToggle.bind(this)} />
                <Drawer
                    docked={false}
                    width={350}
                    openSecondary={true}
                    open={this.state.userAlertDrawerOpen}
                    onRequestChange={(userAlertDrawerOpen) => this.setState({userAlertDrawerOpen})} >
                        <div style={{ padding : "50px 15px"}}>
                            {userAlertToggles}
                        </div>
                </Drawer>
            </div>
        )

        const orgAlertDrawerToggle = (
            <div>
                <RaisedButton
                    label="Edit Organization Alerts"
                    onTouchTap={this.handleOrgAlertDrawerToggle.bind(this)} />
                <Drawer
                    docked={false}
                    width={350}
                    openSecondary={true}
                    open={this.state.orgAlertDrawerOpen}
                    onRequestChange={(orgAlertDrawerOpen) => this.setState({orgAlertDrawerOpen})} >
                        <div style={{ padding : "50px 15px"}}>
                            {orgAlertToggles}
                        </div>
                </Drawer>
            </div>
        )
        

        return (
            <div className="userdrawer row">
                <div className="col-sm-4">
                    <RaisedButton
                        label="Edit Managers"
                        onTouchTap={this.handleManagerDrawerToggle.bind(this)} />
                    <Drawer
                        docked={false}
                        width={350}
                        open={this.state.managerDrawerOpen}
                        onRequestChange={(managerDrawerOpen) => this.setState({managerDrawerOpen})} >
                            <div style={{ padding : "50px 15px"}}>
                                {managerToggles}
                            </div>
                    </Drawer>
                </div>
                <div className="col-sm-4">
                    { p.user.group !== "driver" ? driverDrawerToggle : "" }
                    
                </div>

                { p.user.group !== "driver" ? <div className="col-sm-4">{userAlertDrawerToggle}</div> : "" }

                { p.user.group !== "driver" ? <div className="col-sm-4">{orgAlertDrawerToggle}</div> : "" }
                    
                <div className="col-sm-4">
                    <SelectField 
                        value={p.user.group}
                        disabled={!p.groups.hasReceivedGroupData}
                        floatingLabelText="Role"
                        style={{width: "100%"}}
                        onChange={this.handleGroupChange.bind(this)}>
                            { p.groups.hasReceivedGroupData ? _.map(p.groups.data, function (group, gid) {
                                const groupState = p.groups.states[gid]; //TODO: use this to show user async state
                                return <MenuItem key={gid} value={gid} primaryText={group.role || group.name} />
                            }) : <MenuItem key={0} value={p.user.group} primaryText={"Loading Groups"} /> }
                    </SelectField>
                </div>
                <div className="col-sm-4">
                    <SelectField 
                        value={p.user.organization}
                        disabled={!p.orgs.hasReceivedOrgData}
                        floatingLabelText="Organization"
                        style={{width: "100%"}}
                        onChange={this.handleOrgChange.bind(this)}>
                            { p.orgs.hasReceivedOrgData ? _.map(p.orgs.data, function (org, oid) {
                                const orgState = p.orgs.states[oid]; //TODO: use this to show user async state
                                return <MenuItem key={oid} value={oid} primaryText={org.name} />
                            }) : <MenuItem key={0} value={p.user.group} primaryText={"Loading Organizations"} /> }
                    </SelectField>
                </div>

                <div className="col-sm-1">
                    { p.user.group !== "driver" ? <Toggle
                        label= {'Is Owner'}
                        toggled = {!!p.user.isOwner}
                        onToggle = { function() { p.toggleIsOwner(p.uid, p.user.isOwner, p.user) } } /> : "" }
                </div>


                <div className="col-sm-2">
                    <RaisedButton
                    label="Force Verify Email"
                    onTouchTap={(function (e) {e.preventDefault(); this.props.forceVerifyEmail(p.uid); }).bind(this) } />
                </div>
                <div className="col-sm-12" style={{paddingTop: 20}}>
                    User added: {new Date(p.user.dateAdded).toLocaleString()}
                </div>
                <div className="col-sm-12" style={{paddingTop: 20}}>
                    <span>Credits: </span>
                    <Editable type="number" value={p.user.credits || 0} fullPath={'users/' + p.uid + '/credits'}  />
                </div>
                <div className='col-sm-12'>
                    <div style={{paddingTop: 15}}><JsonButton label="View JSON user data" data={p.user} /></div>
                </div>

                <div className="clear" />
            </div>
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        groups: appState.groups,
        users: appState.users,
        orgs: appState.orgs,
        builds: appState.builds
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        changeUserGroup: function(uid, gid, opt_name) { dispatch(actions.changeUserGroup(uid, gid, opt_name)); },
        changeUserOrg: function (uid, oid, opt_name) { dispatch( actions.changeUserOrg(uid, oid, opt_name) ) },
        toggleManager: function(uid, userData, mid, managerData, isManager) { dispatch( actions.toggleManager(uid, userData, mid, managerData, isManager) ); },
        toggleUserAlert: function(alerterId, alerterData, alertedId, alertedData, isCurrentlyAlerting) { dispatch( actions.toggleUserAlert(alerterId, alerterData, alertedId, alertedData, isCurrentlyAlerting) ); },
        toggleOrgAlert: function(userId, userData, orgId, orgData, isCurrentlyAlerted) { dispatch( actions.toggleOrgAlert(userId, userData, orgId, orgData, isCurrentlyAlerted) ); },
        toggleIsOwner: function(uid, isOwner, user) { dispatch( actions.toggleIsOwner(uid, isOwner, user) ) },
        forceVerifyEmail: function(uid) { dispatch( actions.forceVerifyEmail(uid)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawer);

