import React, { Component } from 'react';
import { connect } from 'react-redux';


class DashLoader extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    componentWillMount () {


        this.goToDashIfOwner(this.props.auth);

        
    }

    componentWillReceiveProps (nextProps) {
        this.goToDashIfOwner(nextProps.auth);
    }

    goToDashIfOwner (auth) {
        const { router } = this.context
        if ((auth.isOwner && auth.scoringMethod) && !auth.fakeDriver) {
            let aid = this.props.params.aid;
            let dn = this.props.params.dimensionName;
            let dv = this.props.params.dimensionValue;
            let free = this.props.params.free;
            let indId = this.props.params.indId;
            let assessmentId = this.props.params.assessmentId;
            console.log('IN THE BACK YO', this.props.location.search);
            if (aid || (dn && dv) || free || indId) {
                if (aid) {
                    router.replace('/idash/d/' + aid + this.props.location.search);
                }
                if (dn && dv) {
                    router.replace('/idash/a/' + dn + '/' + dv + this.props.location.search);
                }
                if (free) {
                    router.replace('/idash/f/' + free + this.props.location.search);
                }
                if (indId) {
                    if (assessmentId) {
                        router.replace('/idash/i/' + indId + '/' + assessmentId + this.props.location.search);
                    } else {
                        router.replace('/idash/i/' + indId + this.props.location.search);
                    }
                }
            } else {
                router.replace('/idash' + this.props.location.search);
            }
            
        }
    }

    render () {
        

        return (
            <div>
                Loading data...
            </div> 
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}

DashLoader.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(DashLoader);