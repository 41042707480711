import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import QueryExportForm from './queryexportform';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-material.css';

function compareDates(date1, date2) {
    
    if (!date1 && !date2) {
        return 0;
    }
    if (!date1) {
        return -1;
    }
    if (!date2) {
        return 1;
    }

    let date1Obj = new Date(date1).getTime();
    let date2Obj = new Date(date2).getTime();
    return date1Obj - date2Obj;
}

class DriverIdList extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    componentWillMount () {


        store.dispatch( actions.startListeningToDriverIds() );

    }

    

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    render () {
        
        const p = this.props;

        let content = <div></div>

        if (p.driverIds.empty) {
            return <div style={{padding: 8}}>Loading driver ID data...</div>
        }

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        // TURN customer list into array of objects key/value -- may need user info too?

        let dRows = _.map(p.driverIds.data, (driverData, driverId) => {
            return {
                id: driverId,
                added: new Date(driverData.added).toLocaleString(),
                addedByYou: driverData.addedBy == p.auth.uid ? 'yes' : 'no',
                lastAssessment: driverData.lastTime ? new Date(driverData.lastTime).toLocaleString() : '-'
            }
        })




        content = (
            <div 
                className="ag-theme-material"
                style={{ 
                    height: '700px', 
                    width: '100%' }} 
            >
                <AgGridReact
                    modules={AllCommunityModules}
                    columnDefs={
                        [
                            {
                                headerName: 'Driver ID',
                                field: 'id',
                                sortable: true,
                                filter: true
                            },
                            {
                                headerName: 'Date Added',
                                field: 'added',
                                sortable: true,
                                filter: true,
                                comparator: compareDates
                            },
                            {
                                headerName: 'Added By You?',
                                field: 'addedByYou',
                                sortable: true,
                                filter: true
                            },
                            {
                                headerName: 'Last Assessment',
                                field: 'lastAssessment',
                                sortable: true,
                                filter: true,
                                comparator: compareDates
                            }
                        ]
                    }
                    pagination={true}
                    onGridReady={this.onGridReady.bind(this)}
                    rowData={dRows}>

                    
                </AgGridReact>
                <div className="dash-footer">
                    <QueryExportForm  onSubmit={(function (e) {e.preventDefault(); p.exportQueryToCSV(this.gridApi); }).bind(this)} />
                </div>
            </div>
        )
        

        return (

                <div>
                    {content}
                </div>

                
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        driverIds: appState.driverIds
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        exportQueryToCSV : function(gridApi) { dispatch( actions.exportQueryToCSV(gridApi) ) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DriverIdList);
