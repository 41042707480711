import React from 'react';
import {Route, IndexRoute} from 'react-router';

import Accell from './pages/accell';
import Uploader from './pages/components/accell/Uploader';
import ScenarioUploader from './pages/components/accell/ScenarioUploader';
import ScenarioJsonEdit from './pages/components/accell/ScenarioJsonEdit';
import ModuleCreator from './pages/components/accell/ModuleCreator';
import ViewScenario from './pages/ViewScenario';
import AssetViewer from './pages/components/accell/AssetViewer';
import Alerts from './pages/alerts';
import TriggerFeedback from './pages/triggerfeedback';
import AddDriver from './pages/odps/addcustomer';
import Builds from './pages/builds';
import { C } from './constants';
import CPT from './pages/cpt';
import CPTFin from './pages/cptFinish';
import Dashboard from './pages/dashboard';
import DashLoader from './pages/dashLoader';
import DefaultDash from './pages/defaultDash';
import Documents from './pages/documents';
import DownloadPdf from './pages/downloadPdf';
import IndividualDash from './pages/individualDash';
import DimensionDash from './pages/dimensionDash';
import AssessmentDash from './pages/assessmentDash';
import FreeDash from './pages/freeDash';
import DriveResults from './pages/driveresults';
import DriveResultsEdit from './pages/driveresultsedit';
import Download from './pages/download';
import FeedbackReport from './pages/feedbackReport';
import FeedbackReportV2 from './pages/feedbackReportV2';
import Files from './pages/files';
import Upload from './pages/upload';
import UploadTest from './pages/uploadTest';
import MapExportUploader from './pages/mapExportUploader';
import Feedback from './pages/feedback'; // open page -- how to we allow access to assessment in rules?
import Groups from './pages/groups';
import HomeWrapper from './pages/homewrapper';
import Intake from './pages/intake';
import Blank from './pages/blank';
import IntakeAdmin from './pages/intakeadmin';
import IntakeSuccess from './pages/intakesuccess';
import IntakeSurveyOptions from './pages/intakeSurveyOptions';
import Invites from './pages/invites';
import Login from './pages/login';
import ManageOrg from './pages/manageorg';
import ManagerDash from './pages/components/managerdash';
import ManagerUtils from './pages/managerutils';
import ManageUser from './pages/manageuser';
import Map from './pages/map';
import Orgs from './pages/organizations';
import OrgTypeOptions from './pages/orgTypeOptions';
import Overview from './pages/overview';
import Pay from './pages/pay';
import PaySuccess from './pages/paysuccess';
import PayCancel from './pages/paycancel';
import ManagerOverview from './pages/manageroverview';
import PermTest from './pages/permtest'; // a page to test various users -- do they see the right things?
import Profile from './pages/profile';
import Coaching from './pages/coaching';
import CoachingSurvey from './pages/coachingSurvey';
import Register from './pages/register';
import ResetPassword from './pages/resetpassword';
import ScoringOptions from './pages/scoringOptions';
import Users from './pages/users';
import Results from './pages/results';
import Utils from './pages/utils';
import DbUtils from './pages/dbutils';
import CoachingAdmin from './pages/coachingadmin';
import VideoAdmin from './pages/videoadmin';
import FeedbackAdmin from './pages/feedbackAdmin';
import Wrapper from './pages/wrapper';
import * as perms from "./utils/permission";
import Customer from './pages/customer';
import DriverAssessments from './pages/driverAssessments';
import AddOdpsCustomer from "./pages/odps/addcustomer";
import Locs from "./pages/odps/locations";
import CoachingItemAdmin from "./pages/coachingItemAdmin";
import CoachingPageAdmin from "./pages/coachingPageAdmin";
import CoachingSurveyAdmin from "./pages/coachingSurveyAdmin";
import CoachingSuccess from "./pages/CoachingSuccess";
import LazyReplayViewer from "./pages/lazyReplayViewer";
import ReadyAssess from "./pages/readyassess";
import ReadyStory from "./pages/readystory";
import ReadyScience from "./pages/readyscience";
import ReadyPress from "./pages/readypress";
import Reconcile from "./pages/reconcile";
import Simulate from "./pages/simulate";
import Snapshot from "./pages/snapshot";
import Webhooks from './pages/webhooks';
import Unknown from './pages/unknown';


/*
NB: If components are not wrapped in at least perms.driver - they are open pages (available without login)
*/

export default (
    <Route path="/" component={Wrapper} >
        <Route component={HomeWrapper} >
            <IndexRoute component={perms.driver(Dashboard)} />
            <Route path="/ra/feedback/:aid" component={perms.spr(Dashboard)} />
            <Route path="/users" component={perms.spr(Users)} />
            <Route path="/groups" component={perms.spr(Groups)} />
            <Route path="/utils" component={perms.spr(Utils)} />
            <Route path="/dbutils" component={perms.spr(DbUtils)} />
            <Route path="/coachingadmin" component={perms.spr(CoachingAdmin)} />
            <Route path="/coachingitem/admin" component={perms.spr(CoachingItemAdmin)} />
            <Route path="/coachingpage/admin" component={perms.spr(CoachingPageAdmin)} />
            <Route path="/coachingsurvey/admin" component={perms.spr(CoachingSurveyAdmin)} />
            <Route path="/coachingsuccess" component={perms.driver(CoachingSuccess)} />
            <Route path="/organizations" component={perms.spr(Orgs)} />
            <Route path="/test" component={perms.guard(PermTest)} />
            <Route path="/alerts" component={perms.spr(Alerts)} />
            <Route path='/trigger/feedback/:aid' component={perms.driver(TriggerFeedback)} />
            <Route path="/improve/:assignment" component={perms.driver(CoachingSurvey)} />
            <Route path="/profile/:uid" component={perms.driver(Profile)} />
            <Route path="/coaching/:uid" component={perms.driver(Coaching)} />
            <Route path="/driveresults/:aid" component={perms.driver(DriveResults)} />
            <Route path="/drive/:aid/edit" component={perms.spr(DriveResultsEdit)} />
            <Route path="/payment" component={perms.driver(Pay)} />
            <Route path="/payment/success/:org/:driverId" component={perms.driver(PaySuccess)} />
            <Route path="/payment/cancel/:org/:driverId" component={perms.driver(PayCancel)} />
            <Route path="/files" component={perms.manager(Files)} />
            <Route path="/results/:aid" component={perms.spr(Results)} />
            <Route path="/video/admin" component={perms.spr(VideoAdmin)} />
            <Route path="/feedback/admin" component={perms.spr(FeedbackAdmin)} />
            <Route path="/intake/admin" component={perms.spr(IntakeAdmin)} />
            <Route path="/intake" component={perms.spr(Intake)} />
            <Route path="/intakesuccess" component={perms.driver(IntakeSuccess)} />
            <Route path="/intakesurveyoptions" component={perms.spr(IntakeSurveyOptions)} />
            <Route path="/invites" component={perms.manager(Invites)} />
            <Route path="/manageorg/:oid" component={perms.spr(ManageOrg)} />
            <Route path="/manageuser/:uid" component={perms.spr(ManageUser)} />
            <Route path="/managerutils" component={perms.manager(ManagerUtils)} />
            <Route path="/manager/overview/:mid" component={perms.manager(ManagerOverview)} />
            <Route path="/team" component={perms.manager(ManagerDash)} />
            <Route path="/builds" component={perms.spr(Builds)} />
            <Route path="/overview" component={perms.spr(Overview)} />
            <Route path="/:owner/overview" component={perms.ownerNonBasic(Overview)} />
            <Route path="/locations" component={perms.spr(Locs)} />
            <Route path="/customer/:bassid" component={perms.spr(Customer)} />
            <Route path="/driver/:driverId" component={perms.manager(DriverAssessments)} />
            <Route path="/provisional/:provisional" component={perms.driver(AddOdpsCustomer)} />
            <Route path="/map/:whichmap/:point1/:point2" component={perms.spr(Map)} />
            <Route path="/map/:whichmap/:point1" component={perms.spr(Map)} />
            <Route path="/download" component={perms.manager(Download)} />
            <Route path="/download/pdf/:aid" component={perms.ownerNonBasic(DownloadPdf)} />
            <Route path="/download/pdf/:aid/:v2" component={perms.ownerNonBasic(DownloadPdf)} />
            <Route path="/upload" component={perms.spr(Upload)} />
            <Route path="/upload/test" component={perms.driver(UploadTest)} />
            <Route path="/map/upload" component={perms.spr(MapExportUploader)} />
            <Route path="/feedback/report/:aid" component={Feedback} />
            <Route path="/add/driver" component={perms.manager(AddDriver)} />
            <Route path="/api" component={perms.ownerNonBasic(Webhooks)} />
            <Route path="/replay/:aid" component={perms.ownerNonBasic(LazyReplayViewer)} />
            <Route path="/docs" component={perms.manager(Documents)} />
            <Route path="/idash" component={perms.owner(DefaultDash)} />
            <Route path="/idash/d/:aid" component={perms.ownerNonBasic(AssessmentDash)} />
            <Route path="/idash/i/:indId" component={perms.ownerNonBasic(IndividualDash)} />
            <Route path="/idash/i/:indId/:assessmentId" component={perms.ownerNonBasic(IndividualDash)} />
            <Route path="/idash/a/:dimensionName/:dimensionValue" component={perms.ownerNonBasic(DimensionDash)} />
            <Route path="/idash/f/:free" component={perms.spr(FreeDash)} />
            <Route path="/dash" component={perms.owner(DashLoader)} />
            <Route path="/dash/d/:aid" component={perms.ownerNonBasic(DashLoader)} />
            <Route path="/dash/a/:dimensionName/:dimensionValue" component={perms.ownerNonBasic(DashLoader)} />
            <Route path="/dash/i/:indId" component={perms.ownerNonBasic(DashLoader)} />
            <Route path="/dash/i/:indId/:assessmentId" component={perms.ownerNonBasic(DashLoader)} />
            <Route path="/dash/f/:free" component={perms.spr(DashLoader)} />
            <Route path="/scoring/options" component={perms.spr(ScoringOptions)} />
            <Route path="/simulate" component={perms.spr(Simulate)} />
            <Route path="/organizationtype/options" component={perms.spr(OrgTypeOptions)} />
            <Route path="/snapshot" component={perms.spr(Snapshot)} />
            <Route path="/:isStep/feedback" component={perms.driver(FeedbackReportV2)} />
            <Route path="/:isStep/feedback/:aid" component={perms.driver(FeedbackReportV2)} />
            <Route path="/accell" component={perms.spr(Accell)} />
            <Route path="/asset/upload" component={perms.spr(Uploader)} />
            <Route path="/scenario/upload" component={perms.spr(ScenarioUploader)} />
            <Route path="/module/upload" component={perms.spr(ModuleCreator)} />
            <Route path="/view/scenario/:scenario" component={perms.spr(ViewScenario)} />
            <Route path="/view/asset/:assetId" component={perms.spr(AssetViewer)} />
            <Route path="/edit/scenario/:sid" component={perms.spr(ScenarioJsonEdit)} />
            
        </Route>
        <Route path="/pfr" component={perms.driver(FeedbackReport)} />
        <Route path="/pfr/:aid" component={perms.driver(FeedbackReport)} />
        <Route path="/pfrv2" component={perms.driver(FeedbackReportV2)} />
        <Route path="/pfrv2/:aid" component={perms.driver(FeedbackReportV2)} />
        <Route path="/backend/feedback/:aid/:feedbackToken" component={perms.driver(FeedbackReport)} />
        <Route path="/backend/feedbackV2/:aid/:feedbackToken" component={perms.driver(FeedbackReportV2)} />
        <Route path="/intake/:unity" component={perms.driver(Intake)} />
        <Route path="/intake/:unity/:mgrId/:driverId" component={perms.manager(Intake)} />
        <Route path="/post/drive/:survey" component={perms.driver(Intake)} />
        <Route path="/post/drive/:survey/:unity/:aid" component={perms.driver(Intake)} />
        <Route path="/reconcile/:rqid" component={Reconcile} />
        <Route path="/:intakeKeyForOpen/intake/:unity" component={Intake} />
        <Route path="blank" component={perms.driver(Blank)} />
        <Route path="/login" component={Login} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/feedback/:aid" component={Feedback} />
        <Route path="/register/:invite" component={Register} />
        <Route path="/get/ready/:inviteId" component={ReadyAssess} />
        <Route path="/get/ready/story/:inviteId" component={ReadyStory} />
        <Route path="/get/ready/science/:inviteId" component={ReadyScience} />
        <Route path="/get/ready/press/:inviteId" component={ReadyPress} />
        <Route path="/get/pdf/:aid" component={perms.isOwnAssessment(DownloadPdf)} />
        <Route path= "/cpt/:module" component={perms.driver(CPT)} />
        <Route path= "/cptfin/:module" component={perms.driver(CPTFin)} />
        <Route path="*" component={Unknown} />
    </Route>
);