import { C } from '../constants';
import { reset } from 'redux-form';
import actions from '../actions';

const orgsRef = C.APP.database().ref('organizations');
const surveysRef = C.DB.ref('coachingSurveys');
const intakeSurveyOptionsRef = C.DB.ref('intakeSurveyOptions');
const usersRef = C.DB.ref('users');

export const startListeningToOrgs = function () {
    return function (dispatch, getState) {
        orgsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_ORGS_DATA, data: snap.val()});
        });
    }
}

export const submitNewOrg = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.addOrg.values;
        const name = formValues.name;
        const abrv = formValues.abrv;
        const driveLimit = formValues.hasOwnProperty('driveLimit') ? formValues.driveLimit : -1;
        const users = {};
        const cities = [true, true, true, true, true, true, true, true, true, true]
        dispatch({type: C.AWAIT_NEW_ORG_RESPONSE});
        orgsRef.push({name, abrv, driveLimit, users, cities}).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_ORG_RESPONSE});
            dispatch( actions.addSuperDrivers() );
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_ORG_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add organization!"}) //TODO: better errors
        } )
    }
}

export const updateDriveLimit = function (oid) {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.driveLimit.values;
        orgsRef.child(oid).update({
            "driveLimit": formValues.driveLimit
        })
    }
}

export const initOid = function (oid) {
    return function (dispatch, getState) {
        const state = getState();
        const org = state.orgs.data[oid];
        dispatch({type: C.INIT_DRIVE_LIMIT, driveLimit: org.hasOwnProperty('driveLimit') ? org.driveLimit : -1});
    }
}

export const setOrgBuild = function (oid, tag) {
    return function (dispatch, getState) {
        // set users group as gid
        // add uid to group.members for gid
        // remove uid from all other groups (user can only belong to one group)
        orgsRef.child(oid).update({
            "build": tag
        });
    }
}

export const setOrgIntake = function (oid, survey) {
    return function (dispatch, getState) {
        orgsRef.child(oid).update({
            "intake": survey
        });
    }
}

export const setOrgPostDriveSurvey = function (oid, survey) {
    return function (dispatch, getState) {
        orgsRef.child(oid).update({
            "postDriveSurvey": survey
        });
    }
}

export const setSurveySelectQuestion = function (oid, itemId) {
    return function (dispatch, getState) {
        orgsRef.child(oid).update({
            'surveySelector': itemId
        });
    }
}

/*
 * if currently "isOn" remove the survey from org's "surveys" and 
 * remove the org from survey's "orgs"
 * if currently !isOn add the survey to orgs's "surveys" and
 * add the org to survey's "orgs"
 */
export const toggleSurvey = function (oid, org, surveyId, survey, isOn) {
    return function (dispatch, getState) {
        //TODO: implement 'waiting' procedure so toggle can deactivate momentarily
        let sData = {};
        sData[surveyId] = isOn ? null : survey.description;
        let oData = {};
        oData[oid] = isOn ? null : org.abrv;
        orgsRef.child(oid + "/surveys").update( sData );
        surveysRef.child(surveyId + "/orgs").update( oData);

    }
}

/*
 * if currently "isOn" remove the survey from org's "intakeSurveyOptions" and 
 * remove the org from intakeSurveyOption's "orgs"
 * if currently !isOn add the survey to orgs's "intakeSurveyOptions" and
 * add the org to intakeSurveyOption's "orgs"
 */
export const toggleIntakeSurveyOption = function (oid, org, optionId, option, isOn) {
    return function (dispatch, getState) {
        //TODO: implement 'waiting' procedure so toggle can deactivate momentarily
        let sData = {};
        sData[optionId] = isOn ? null : option.name;
        let oData = {};
        oData[oid] = isOn ? null : org.abrv;
        orgsRef.child(oid + "/intakeSurveyOptions").update( sData );
        intakeSurveyOptionsRef.child(optionId + "/orgs").update( oData);

    }
}

export const toggleCity = function (oid, index, isOn, cities) {
    return function (dispatch, getState) {
        //TODO: implement 'waiting' procedure so toggle can deactivate momentarily
        const update = {};
        update[index] = !isOn;
        let okToToggle = false;
        if (!update[index]) { // if we are turning off
            for (var i = 0; i < cities.length; i++) {
                // If there is one or more other cities turned on -- go ahead
                if (i != index) {
                    okToToggle = okToToggle || cities[i];
                }   
            };
        } else {
            okToToggle = true;
        }
        if (okToToggle) {
            orgsRef.child(oid + "/cities").update( update );
        } else {
            alert("Must have at least one city turned on!");
        }
        
    }
}

export const toggleFeatureFlag = function (oid, flag, isOn) {
    return function (dispatch, getState) {
        orgsRef.child(oid + '/config/featureFlags/' + flag).set(isOn ? null : true);
    }
}

export const toggleModule = function (oid, module, isOn) {
    return function (dispatch, getState) {
        orgsRef.child(oid + '/config/cptModules/' + module).set(isOn ? null : true);
    }
}



export const deleteExperiment = function (tests, index, oid) {
    return function (dispatch, getState) {
        console.log(tests, index, oid)
        const newTests = [...tests]
        newTests.splice(index, 1);
        console.log(newTests)
        orgsRef.child(oid + '/config/abtests').set(newTests);
    }
}

export const addExperiment = function (tests, oid) {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.experiment.values;
        let newTest = {name: formValues.name, chance: formValues.chance, 'max-count': formValues.max};
        let expiration = new Date(formValues.expirationDate.getFullYear(), formValues.expirationDate.getMonth(), formValues.expirationDate.getDate(), 
               formValues.expirationTime.getHours(), formValues.expirationTime.getMinutes(), formValues.expirationTime.getSeconds());
        newTest.expiration = expiration;
        let flags = [];
        for (let i of Array(formValues.numberOfItems).keys()) {
            console.log('adding flag', i);
            let flagValue = formValues['flag_' + i];
            let prefix = formValues['flag_negate_' + i] == 'off' ? '-' : '';
            flags.push(prefix + flagValue);
        }
        newTest.flags = flags;
        let newTests = tests.length ? [...tests] : [];
        newTests.push(newTest);
        console.log(newTests);
        orgsRef.child(oid + '/config/abtests').set(newTests).then(() => {
            dispatch(reset('experiment'));
        });
    }
}

export const setEmailSettings = function (oid) {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.emailsettings.values;
        let newSettings = {batchTag: formValues.batchTag, sendImmediately: !!formValues.sendImmediately};
        
        orgsRef.child(oid + '/config/email').set(newSettings).then(() => {
            dispatch(reset('emailsettings'));
        });
    }
}

export const setScoringOption = function (oid, val, orgName) {
    return function (dispatch, getState) {

        
        orgsRef.child(oid + '/config/scoringMethod').set(val).then(() => {
            alert('Scoring method set to ' + val + ' for ' + orgName);
        });
    }
}

export const setOrgTypeOption = function (oid, val, orgName) {
    return function (dispatch, getState) {

        
        orgsRef.child(oid + '/config/orgType').set(val).then(() => {
            alert('Org type set to ' + val + ' for ' + orgName);
        });
    }
}

export const toggleOrgIsParent = function (oid, org) {
    return function (dispatch, getState) {

        let togglingOn = !(org.config && org.config.isParent);
        
        orgsRef.child(oid + '/config/isParent').set(togglingOn).then(() => {
            _.map(org.members, (name, id) => {

                usersRef.child(id).once('value').then(uSnap => {
                    let user = uSnap.val();
                    if (togglingOn) {
                        if (user.isOwner) {
                            usersRef.child(id + '/allowedOrgs').set(org.childOrgs);
                        }
                    } else {
                        usersRef.child(id + '/allowedOrgs').set(null);
                    }
                    
                })
            })
        });
    }
}

export const toggleOrg = function (parentId, childId, parent, child) {
    return function (dispatch, getState) {
        let children = parent.childOrgs || {};
        let parents = child.parentOrgs || {};
        let togglingOn = false;
        if (children[childId]) {
            //toggling off
            children[childId] = null;
            parents[parentId] = null;
        } else {
            //toggling on
            togglingOn = true;
            children[childId] = true;
            parents[parentId] = true;
        }

        orgsRef.child(parentId + '/childOrgs').set(children).then(() => {
            orgsRef.child(childId + '/parentOrgs').set(parents).then(() => {
                _.map(parent.members, (name, id) => {
                    usersRef.child(id).once('value').then(uSnap => {
                        let user = uSnap.val();
                        if (togglingOn) {
                            if (user.isOwner) {
                                usersRef.child(id + '/allowedOrgs/' + childId).set(true);
                            }
                        } else {
                            usersRef.child(id + '/allowedOrgs/' + childId).set(null);
                        }
                        
                    })
                })
            });
        });
    }
}
