import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedAssetData: false,
  uploading: false,
  error: "",
  acting: false,
  progress: 0,
  totalBytes: 0,
  bytesTransferred: 0,
  data: {},
  assets: {}
};

function updateAsset(state, key, value) {
    let newEntry = {};
    newEntry[key] = value;
    return Object.assign({}, state.assets, newEntry);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_ASSET_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedAssetData: true
        });

    case C.RECEIVE_SINGLE_ASSET:
        return Object.assign({}, state, {
            assets: updateAsset(state, action.assetId, { hasReceivedData: true, assetData: action.assetData, url: action.url})
        });

    case C.AWAIT_NEW_ASSET_RESPONSE:
        return Object.assign({},state,{
            uploading: true,
            error: ""
        });

    case C.RECEIVE_NEW_ASSET_RESPONSE:
        return Object.assign({}, state, {
            uploading: false,
            error: "",
            progress: 0
        })

    case C.START_ASSET_ACTION:
      return Object.assign({}, state, {
            acting: true,
            error: "",
            action: ""
        })

    case C.END_ASSET_ACTION:
      return Object.assign({}, state, {
            acting: false,
            action: ""
        })

    case C.DELETE_ASSET:
        return Object.assign({}, state, {
            uploading: false,
            error: action.error,
            progress: 0,
            data: action.data
        })

    case C.RECEIVE_ASSET_UPLOAD_ERROR:
        return Object.assign({}, state, {
            uploading: false,
            error: action.error,
            progress: 0
        })

    case C.SET_ASSET_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            progress: action.progress,
            totalBytes: action.totalBytes,
            bytesTransferred: action.bytesTransferred
        })

      default:
        return state;
  }
}