import { C } from '../../constants';

const initialState = {
    empty : true,
    content: null,
    pdfs : {}
}

function updatePdf(state, key, value) {
    let newEntry = {};
    newEntry[key] = value;
    return Object.assign({}, state.pdfs, newEntry);
}

export default (state = initialState, action) => {
    let content = {};
    switch (action.type) {
        case C.RECEIVE_FEEDBACK_CONTENT:
            return Object.assign({}, state, {
                empty : false,
                content: action.content
            });
        case C.SET_PDF_DOWNLOAD_URL:
            return Object.assign({}, state, {
                pdfs: updatePdf(state, action.aid, { url: action.url, loading: false, generating: false, failed: false })
            });
        case C.AWAIT_PDF_URL:
            return Object.assign({}, state, {
                pdfs: updatePdf(state, action.aid, { loading: true, generating: false, failed: false })
            });
        case C.PDF_GIVE_UP:
            return Object.assign({}, state, {
                pdfs: updatePdf(state, action.aid, {generating: false, loading: false, failed: true })
            });
        case C.PDF_GENERATING:
            return Object.assign({}, state, {
                pdfs: updatePdf(state, action.aid, { generating: true, loading: false, failed: false })
            });
        case C.SET_PDF_NOT_READY:
            return Object.assign({}, state, {
                pdfs: updatePdf(state, action.aid, { notReady: true, loading: false, failed: false })
            });
        default:
            return state;
  }
}