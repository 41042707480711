
import CircularProgress from 'material-ui/CircularProgress';
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import CoachingWizard from './components/coachingsurveywizard';


const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToCoachingItems() );
    store.dispatch( actions.startListeningToCoachingPages() );
    store.dispatch( actions.startListeningToCoachingSurveys() );
    store.dispatch( actions.startListeningToVideos());
});

class CoachingSurvey extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentWillMount () {
        console.log("componentWillMount Intake");
        startListening();
        if (this.props.params.assignment) {
            store.dispatch( actions.startListeningToAssignment( this.props.params.assignment) );
        }
    }

    componentWillReceiveProps (nextProps) {

    }

    render () {
        const p = this.props;
        const assignmentId = p.params.assignment;
        console.log(assignmentId);
        const assignment = p.assignments &&
            p.assignments.data &&
            p.assignments.data[assignmentId] &&
            this.props.assignments.data[assignmentId].hasReceivedData &&
            this.props.assignments.data[assignmentId].value;


        if (!assignment) {
            return <div>
                <div className="panel">
                    <div className="panel-body">
                        <Paper style={{margin: "20px", padding: "20px", overflow: "hidden"}}>
                            {"Oops! We couldn't find a coaching task assigned to you at the moment."}
                        </Paper>
                    </div>
                </div>
            </div> 
        }

        if (assignment.completed) {
            return <div>
                <div className="panel">
                    <div className="panel-body">
                        <Paper style={{margin: "20px", padding: "20px", overflow: "hidden"}}>
                            {"This assignment has already been completed. Please contact your manager if you feel you need more coaching."}
                        </Paper>
                    </div>
                </div>
            </div> 
        }

        if (assignment.assignee != p.auth.uid) {
            return <div>
                <div className="panel">
                    <div className="panel-body">
                        <Paper style={{margin: "20px", padding: "20px", overflow: "hidden"}}>
                            {"This assignment is assigned to a different user. Please log out and log back in with the account that the assignment was assigned to."}
                        </Paper>
                    </div>
                </div>
            </div> 
        }

        const surveyId = assignment.survey;

        let content =   <div className="inTheMiddle">
                            <CircularProgress className="middled" />
                        </div>

        if (p.items.hasReceivedData && p.pages.hasReceivedData && p.surveys.hasReceivedData && p.video.hasReceivedVideoData && surveyId && !p.coaching.submitting) {
            const survey = p.surveys.data[surveyId];
            console.log(surveyId);
            console.log(p.surveys.data[surveyId]);
            if (!survey) {
                content = <div>No survey found, please contact Diagnostic Driving support.</div>
            } else {
                content =   <CoachingWizard
                            className="coaching-form"
                            survey={survey}
                            pages={p.pages.data}
                            items={p.items.data}
                            videos={p.video.data}
                            onSubmit={(function (e) {e.preventDefault(); e.stopPropagation(); this.props.submitCoachingSurvey(surveyId, survey, this.context.router, assignmentId, assignment); return false; }).bind(this)}>
                        </CoachingWizard>
            }
            
        }
 



        return (

            <div>
                <div className="panel">
                    <div className="panel-body">
                        <Paper style={{margin: "20px", padding: "20px", overflow: "hidden"}}>
                            {content}
                        </Paper>
                    </div>
                </div>
            </div> 
                
        )
    }
}

CoachingSurvey.contextTypes = {
    router: React.PropTypes.object.isRequired
};

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        items: appState.coachingItems,
        pages: appState.coachingPages,
        surveys: appState.coachingSurveys,
        video: appState.video,
        coaching: appState.coaching,
        assignments: appState.assignments
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitCoachingSurvey: function (sid, s, router, assignmentId, assignment) {
            dispatch( actions.submitCoachingSurvey(sid, s, router, assignmentId, assignment) )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoachingSurvey);
