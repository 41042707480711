

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../actions';
import ResetForm from './components/resetform';

class ResetPassword extends Component {
    constructor(props, context) {
        super(props, context);
    }



    // componentWillUnmount () {
    //     document.body.style.background = "#FFF";
    // }

    

    render () {


        let spinner = null;
        if (this.props.reset.waiting) {
            spinner = <div className="dxd-loginSpinner" />
        }
        let error = null;
        if (this.props.reset.error) {
            error = <div className="small errorText middling">{this.props.reset.error}</div>
        }
        let success = null;
        if (this.props.reset.success) {
            success = <div className="small middling">{ "Change password email sent to: " + this.props.reset.email }</div>
        }


        return (
            //We are using CSS to hide/show spinner when attempting login
            <div className={ this.props.reset.waiting + " login" }>
                <div className="login-form">
                    <div className="dxd-login-logo" />
                    { spinner }
                    { error }
                    { success }
                    <ResetForm className="dxd-reset-form" 
                        onSubmit={(function (e) {e.preventDefault(); this.props.resetPassword(); }).bind(this) } />
                    <div className="small forgot" style={{ textAlign: "center", padding: "8px"}}>
                        <Link to={"/login"}>Back to Login.</Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        reset: appState.reset
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        resetPassword: function() { dispatch(actions.resetPassword()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
