

import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import { List } from 'material-ui/List';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';
import * as hlp from '../../utils/helpers';
import Drive from './drive';

/*
The list of assessment ids is pulled down when the user logs in
We need to display the list -- each drive will listen to its own state
*/


class Drives extends Component {

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        store.dispatch( actions.startListeningToDriver( this.props.uid ) );
    }

    render () {
        const s = this.state;
        const p = this.props;
        const driver = p.driver[p.uid];
        const driveSort = p.driveSort;

        let drivesObj, unsortedRows, sortedRows;
        let rows = [];
        if (driver) {
            drivesObj = driver.assessments;
        } 

        if (!driver || driver.loading) { // || driver.assessmentsLoaded -- check that the data for all assessments for this driver have been loaded
            //so we dont have to do all these checks throughout this component. where should assessmentsLoaded be? here? something to
            // do with the reducer
            return (<CircularProgress />)
        }

        if (drivesObj) {
            unsortedRows = _.map(drivesObj, function (value, aid) {
                //create an array of objects that we can sort
                let newObj = {
                    aid: '',
                    data: {}
                }

                //do we need this check here? TODO
                //probs not. it would make more sense to check what the value is. if it's just a number, we gotta wait for the data to populate
                //aka needs to be an object
                if (aid && value) {
                    newObj.aid = aid;
                    newObj.data = value;
                    return newObj;
                }
            });
        }

        //determine which column to sort by
        if (unsortedRows) {
            sortedRows = unsortedRows.sort(hlp.compare(driveSort.sortBy, driveSort.reverse));
        };

        // then create an array of drives to be rendered 
        //could just use sortedRows.map(row => <Drive .../>) and just render with this
        if (sortedRows) {
            sortedRows.forEach(function (el) {
                rows.push(<Drive key={el.aid} aid={el.aid}/>);
            })
        }

        return (

            <List>
                {rows}
            </List>

        )
    }
}

//to access the state (Redux doesnt want components to change state, so make it a prop)
const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        driver: appState.driver,
        drive: appState.drive, 
        driveSort: appState.driveSort //reducers index
    };
}

const mapDispatchToProps = function (dispatch) {//for dispatching actions
    return {
        setSortState : function (sortBy) {
            dispatch( actions.setSortState(sortBy) )
        }
    }
}

Drives.contextTypes = {
    router: React.PropTypes.object.isRequired
};

//this allows injection of state into this component
export default connect(mapStateToProps, mapDispatchToProps)(Drives); 