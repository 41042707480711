import Drawer from 'material-ui/Drawer';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import JSONTree from 'react-json-tree';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#000000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};



class IntakeButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open : false,
        };
    }

    handleDrawerToggle() {
        this.setState({open: !this.state.open});
    }

    componentWillMount() {
        if (this.props.intakeId) {
            store.dispatch( actions.startListeningToIntakeForm(this.props.intakeId) );
        }
    }

    render() {
        const p = this.props;
        const iid = p.intakeId;

        if (!iid) {
          return <div>No Intake Survey</div>
        }


        const intake = p.intakeForms[iid];

        if (!intake) {
            return <div>No Intake Survey</div>
        }

        if (intake.loading) {
            return <div> Loading Intake </div>
        }
        
        let responses = intake.data && intake.data.responses ? intake.data.responses : intake.data;

        return (

            <div>
                <RaisedButton
                    label="Intake"
                    onTouchTap={this.handleDrawerToggle.bind(this)} />
                <Drawer
                    docked={false}
                    width={312}
                    open={this.state.open}
                    onRequestChange={(open) => this.setState({open})} >
                        <div style={{ padding : "50px 15px"}}>
                            <JSONTree 
                                data={responses}
                                theme={theme}
                                invertTheme={true}
                                hideRoot={true}/>
                        </div>
                </Drawer>
            </div>
                
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        intakeForms : appState.intakeForms
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntakeButton);

