
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import MapsForm from './components/mapsform';

const style = {
  margin: 20,
  position: "relative"
};

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

class MapExportUploader extends Component {


    render () {

        const p = this.props;
        const LoadIndicators = [
            this.props.maps.progress[0] + "%",
            this.props.maps.progress[1] + "%",
            this.props.maps.progress[2] + "%",
            this.props.maps.progress[3] + "%"
        ]

        let content =  null;

        if (p.maps.uploading[0] || p.maps.uploading[1] || p.maps.uploading[2] || p.maps.uploading[3]) {
            let papers = [];
            for (var i = 0; i < 4; i++) {
                papers.push(
                    <Paper
                        style={style}
                        zDepth={1}
                        key={i}
                    >
                        <div style={{
                                position: "absolute",
                                top: "0px", 
                                right: "0px",
                                padding: "5px",
                                fontSize: "85%",
                                border: "1px solid #CCC",
                                zIndex: "999999999"
                            }}>
                            Total Bytes: {this.props.maps.totalBytes[i]} - Bytes Transferred: {this.props.maps.bytesTransferred[i]}
                        </div>
                        <div className="progressbar" style={{ width: LoadIndicators[i] }}></div>
                        <div style={innerStyle} >
                            { this.props.maps.uploading[i] ? "Progress: " + this.props.maps.progress[i].toFixed(2) + "%" : "Not uploading map " + i}
                        </div>
                    </Paper>
                )
            };
            content = (
            <div>
                {papers}
            </div>
            )
        } else {
            content = (
                <Paper
                    style={style}
                    zDepth={1}>
                    <MapsForm className="build-form" onSubmit={(function (e) {e.preventDefault(); this.props.uploadMaps(); }).bind(this) } />
                </Paper>
            )
        }

        return (
            <div>
                {content}
            </div>
        )
    }
}


const mapStateToProps = function (appState) {
    return {
        maps: appState.maps
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadMaps: function () { dispatch( actions.uploadMaps() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MapExportUploader);

