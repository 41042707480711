import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedCoachingResponseData: false,
  submittingnew: false,
  done: false,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case C.RECEIVE_COACHING_RESPONSE_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedCoachingResponseData: true
        });

    case C.AWAIT_NEW_COACHING_RESPONSE_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_COACHING_RESPONSE_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false,
            done: true // a refresh of the page should re-mount and start over?
        });

    case C.START_COACHING_RESPONSE:
        return Object.assign({}, state, {
            done: false
        });

      default:
        return state;
  }
}