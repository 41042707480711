import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedData: false,
  submittingnew: false,
  data: {},
  // init: {},
  // states: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_COACHING_ITEMS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedData: true
        });

    case C.AWAIT_NEW_COACHING_ITEM_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_COACHING_ITEM_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

      default:
        return state;
  }
}