import { C } from '../constants';

const orgsRef = C.APP.database().ref('organizations');

export const submitNewAlternativeSurvey = function (oid) {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.addAltSurvey.values;
        const type = formValues.type;
        const lang = formValues.lang;
        const survey = formValues.survey;
        const surveySelect = formValues.surveySelectQuestion;

        const payloadRef = (type == 'surveySelect') ? surveySelect : survey;
        
        dispatch({type: C.AWAIT_NEW_ALT_SURVEY_RESPONSE});

        orgsRef.child(oid + '/altSurveys/' + type + '/' + lang).set(payloadRef).then(() => {
            dispatch({type: C.RECEIVE_NEW_ALT_SURVEY_RESPONSE});
        }).catch(err => {
            dispatch({type: C.RECEIVE_NEW_ALT_SURVEY_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add new alt survey!"}) //TODO: better errors
        })
    }
}