import { combineReducers } from 'redux';
import altSurveyReducer from "./altSurveys";
import assessmentReducer from "./assessments";
import assetsReducer from './assets';
import assignmentsReducer from "./assignments";
import authReducer from "./auth";
import buildsReducer from './builds';
import coachingItemsReducer from './coachingItem';
import coachingPagesReducer from './coachingPages';
import cptReducer from './cpt';
import dashReducer from './dash';
import deprecatedFormReducer from "./form";
import documentsReducer from "./documents";
import driverIdsReducer from "./driverIds";
import feedbackReducer from "./feedback";
import filesReducer from "./files";
import usersReducer from "./users";
import groupsReducer from "./groups";
import orgsReducer from "./orgs";
import driveReducer from "./drive";
import driverReducer from "./driver";
import utilReducer from "./util";
import videoReducer from "./video";
import coachingStepReducer from "./coachingstep";
import coachingSurveysReducer from "./coachingSurveys";
import coachingResponseReducer from "./coachingresponse";
import coachingReducer from './coaching.js';
import contentReducer from './content';
import inviteReducer from './invites';
import questionsReducer from './questions';
import driveSortReducer from './driveSort';
import myDriversSortReducer from './myDriversSort';
import liveInviteReducer from './liveInvite';
import orgTypeOptionReducer from './orgTypeOptions';
import overviewReducer from './overview';
import { reducer as formReducer } from 'redux-form';
import customerReducer from './odps/customer';
import locReducer from './odps/locations';
import localeReducer from './locales';
import progressReducer from './progress';
import custReducer from './customer';
import rollupReducer from './rollup';
import resetReducer from './reset';
import initialReducer from './initial';
import launcherUploadReducer from './launcher';
import mapsReducer from './maps';
import intakeFormReducer from './intakeforms';
import reconcileReducer from './reconcile';
import uiReducer from './ui';
import countsReducer from './counts';
import featureFlagsReducer from './featureflags';
import webhookReducer from './webhooks';
import intakeSurveyOptionsReducer from './intakeSurveyOptions';
import scoringOptionsReducer from './scoringOptions';
import staticFeedbackReducer from './staticFeedback';
import payReducer from './pay';
import simulateReducer from './simulate';



export const rootReducer = combineReducers({
    altSurveys: altSurveyReducer,
    assessments: assessmentReducer,
    assets: assetsReducer,
    assignments: assignmentsReducer,
    auth: authReducer,
    builds: buildsReducer,
    counts: countsReducer,
    cpt: cptReducer,
    dash: dashReducer,
    dForm: deprecatedFormReducer,
    documents: documentsReducer,
    files: filesReducer,
    form: formReducer,
    users: usersReducer,
    groups: groupsReducer,
    orgs: orgsReducer,
    drive: driveReducer,
    driver: driverReducer,
    driverIds: driverIdsReducer,
    feedback: feedbackReducer,
    util: utilReducer,
    video: videoReducer,
    step: coachingStepReducer,
    coaching: coachingReducer,
    coachingItems: coachingItemsReducer,
    coachingPages: coachingPagesReducer,
    coachingSurveys: coachingSurveysReducer,
    content: contentReducer,
    invites: inviteReducer,
    overview: overviewReducer,
    liveInvite: liveInviteReducer,
    questions: questionsReducer,
    driveSort: driveSortReducer,
    myDriversSort: myDriversSortReducer,
    odps: customerReducer,
    locs: locReducer,
    locales: localeReducer,
    progress: progressReducer,
    customer: custReducer,
    rollup: rollupReducer,
    reset: resetReducer,
    initial: initialReducer,
    launcher: launcherUploadReducer,
    intakeForms: intakeFormReducer,
    reconcile: reconcileReducer,
    staticFeedback: staticFeedbackReducer,
    ui: uiReducer,
    maps: mapsReducer,
    featureFlags: featureFlagsReducer,
    webhooks: webhookReducer,
    intakeSurveyOptions: intakeSurveyOptionsReducer,
    scoringOptions: scoringOptionsReducer,
    orgTypeOptions: orgTypeOptionReducer,
    pay: payReducer,
    simulate: simulateReducer

});

export default rootReducer;

// this is our root redux state -- these are all the properties that are exposed
// cant access the whole state anywhere -- have to expose it here