import React, { Component } from 'react';
import Slider from 'material-ui/Slider';
import { C } from '../../constants';


function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};


class ArrayMove extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            fromSlider : 0,
            toSlider : 0,
            msg : ""
        };
    }

    handleFromSlider(e, val) {
        this.setState({fromSlider : val})
    }

    handleToSlider(e, val) {
        this.setState({toSlider : val})
    }

    moveElement() {
        
        let path = this.props.fullPath;
        let array = this.props.array;
        C.APP.database().ref(path).set(array_move(array, this.state.fromSlider, this.state.toSlider)).then(() => {
            this.setState({msg : "moved " + this.state.fromSlider + " to " + this.state.toSlider})
        });
    }

    render () {

        const p = this.props;

        return <div>
            <div>
                <Slider
                    value={this.state.fromSlider}
                    min={0}
                    max={p.array.length - 1}
                    step={1}
                    onChange={this.handleFromSlider.bind(this)} /> {this.state.fromSlider}
            </div>
            <div>
                <Slider
                    value={this.state.toSlider}
                    min={0}
                    max={p.array.length - 1}
                    step={1}
                    onChange={this.handleToSlider.bind(this)} /> {this.state.toSlider}
            </div>
            <div>
                <button onClick={this.moveElement.bind(this)}>
                    Move Page
                </button>
            </div>
            <div>{this.state.msg}</div>
        </div>
    }
}

export default ArrayMove;