/*
This module contains action creators dealing with `appState.auth`
*/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import { C } from '../constants';
import * as hlp from '../utils/helpers';


const fireAuth = firebase.auth();

const usersRef = C.DB.ref('users');
const groupsRef = C.DB.ref('groups');
const orgsRef = C.DB.ref('organizations');
const invitesRef = C.DB.ref('invites');
const functions = firebase.functions();

export const startListeningToAuth = function () {
    return function(dispatch,getState){
        // always unlocked in production
        if (!hlp.isProduction()) {
            const gatewayAuth = firebase.auth(C.GATEWAY_APP);
            gatewayAuth.onAuthStateChanged((user) => {
                if (user) {
                    // current visitor is allowed
                    dispatch({type: C.UNLOCK});
                } else {
                    dispatch({type: C.LOCK});
                }
            });
        }
        fireAuth.onAuthStateChanged((user) => {
            if (user) {
                if (getState().auth.requestVerification) {
                    user.sendEmailVerification();
                    dispatch({ type: C.AWAITING_VERIFICATION}); // no longer send email validation
                }
                usersRef.child(user.uid).on("value", (data) => {
                    // TODO: Add an update username action -- 
                    // login user in without username -- then update store with
                    // username on data fetch
                    if (!data.val()) {
                        // We are in a weird state -- the user record for this auth UID is gone or never existed
                        // We should let this person know somehow -- but for now -- just make sure they are not (partially) logged in
                        if (getState().auth.currently !== C.ANONYMOUS){ // log out if not already logged out
                            dispatch({type:C.LOGOUT});
                            window.firebaseAuthInit = true;
                        }
                    }

                    dispatch({
                        type: C.LOGIN_USER,
                        username: data.val().fullname,
                        uid: user.uid,
                        gid: data.val().group,
                        oid: data.val().organization,
                        isOwner: data.val().isOwner,
                        brandNew: data.val().brandNew,
                        assessments: data.val().assessments || {},
                        drivers: data.val().drivers || {},
                        managers: data.val().managers || {},
                        invites: data.val().invites || {},
                        emailVerified: user.emailVerified,
                        mobile: data.val().mobile,
                        features: data.val().features || {},
                        openInvite: data.val().openInvite,
                        lastIntakeTime: data.val().lastIntakeTime,
                        lastIntake: data.val().lastIntake,
                        dob: data.val().dob,
                        credits: data.val().credits || 0,
                        fakeDriver: data.val().fakeDriver || false,
                        fakeCount: data.val().fakeCount || false,
                        showEmailOpen: data.val().showEmailOpen || false,
                        fakeAssessmentCount: data.val().fakeAssessmentCount || 0,
                        resentVerification: false,
                        cpt: data.val().cpt || {}
                    });
                    window.firebaseAuthInit = true;

                    orgsRef.child(data.val().organization).on("value", (data) => {
                        dispatch({
                            type: C.SET_ORGANIZATION,
                            abrv: data.val().abrv,
                            name: data.val().name,
                            scoringMethod : data.val() && data.val().config && data.val().config.scoringMethod || 'experienced_v1',
                            orgType : data.val() && data.val().config && data.val().config.orgType || 'default',
                            featureFlags : data.val() && data.val().config && data.val().config.featureFlags || {},
                            apiToken : data.val() && data.val().api && data.val().api.token || '',
                            apiTokenV2 : data.val() && data.val().api && data.val().api.tokenV2 || '',
                            isParent : data.val() && data.val().config && data.val().config.isParent || false,
                            childOrgs : data.val() && data.val().childOrgs || {},
                            shareUrl : data.val() && data.val().config && data.val().config.shareUrl || '',
                            payPerAssessment : data.val() && data.val().config && data.val().config.payPerAssessment || false,
                            price : data.val() && data.val().config && data.val().config.price || 2499,
                            cptModules: data.val() && data.val().config && data.val().config.cptModules || {}
                        });
                    });

                    // Just update the use rdiaply name (an auth parameter not the DB)
                    // whenever the auth state changes to keep it up to date
                    user.updateProfile({
                        displayName: data.val().fullname
                    }).then(() => {
                        // set the users display name
                    });

                });
                // dispatch({
                //     type: C.LOGIN_USER,
                //     uid: user.uid
                // });
            } else {
                if (getState().auth.currently !== C.ANONYMOUS){ // log out if not already logged out
                    dispatch({type:C.LOGOUT});
                    window.firebaseAuthInit = true;
                }
            }
            // set a global flag so we know FB auth has initiated
            
        });
    }
};

export const reSendVerificationEmail = function () {


    return function (dispatch, getState) {
        let user = fireAuth.currentUser;
        if (user) {

            user.sendEmailVerification().then(() => {
                dispatch({ type: C.AWAITING_VERIFICATION});
            }).catch((error) => {
                // An error happened.
            });
    }
    }

    
}

export const submitDob = function () {
    //TODO: add "submitting" state?
    // TODO: We need a pretty M/D/Y for dob and a timestamp for dobStamp
    return function (dispatch, getState) {
        const formValues = getState().form.dob.values;
        var d = new Date(formValues.year, formValues.month, formValues.day, 0, 0, 0, 0);
        usersRef.child( getState().auth.uid ).update({
            dob: d.toLocaleDateString(),
            dobStamp: d.getTime()
        });
    }
    
}



export const initializeUnityInterface = function() {
    return function(dispatch,getState){
        window.logoutFromUnity = function() {
            console.log("--<JS><> LOGGING OUT FROM UNITY <><>--");
            fireAuth.signOut();
        }
        window.loginFromUnity = function(email, password) {
            console.log("--<JS><> LOGGING IN FROM UNITY <><>--");
            fireAuth.signInWithEmailAndPassword(email, password)
            .then(() => {
                console.log("--<JS><> LOGIN SUCCESSFUL <><>--");
                window.SendLoginResponseToUnity(true);
            }).catch((error) => {
                console.log("--<JS><> LOGIN ERROR <><>--");
                window.SendLoginResponseToUnity(false);
            });
        }
        window.setIntakeIdFromUnity = function(id) {
            console.log("received intake ID", id);
            dispatch({type: C.SET_OPEN_INTAKE_ID, id})
        }
    }
}

export const attemptLogin = function () {
    return function(dispatch,getState){
        dispatch({type:C.ATTEMPTING_LOGIN});
        const formValues = getState().form.login.values;
        fireAuth.signInWithEmailAndPassword(formValues.email, formValues.password)
        .then(() => {
            /*
            Since the router vs redux battle is hurting my brain
            I'm just going to try to hack back to "home" here
            */
            // window.location.replace("/");
        }).catch((error) => {
            dispatch({type:C.DISPLAY_ERROR, error: "Login Failed! " /* + error.message */ }) //TODO: map our own error messages to codes
            dispatch({type:C.LOGOUT});
        });
    }
};

export const resetPassword = function () {
    return function(dispatch,getState){
        
        const formValues = getState().form.reset.values;
        dispatch({type:C.ATTEMPTING_PASSWORD_RESET, email: formValues.email});
        fireAuth.sendPasswordResetEmail(formValues.email)
        .then(() => {
            dispatch({type:C.PASSWORD_RESET_SUCCESS});
        }).catch((error) => {
            dispatch({type:C.PASSWORD_RESET_ERROR, error: error.message /* + error.message */ }) //TODO: map our own error messages to codes
        });
    }
}

export const logoutUser = function () {
    return function(dispatch,getState){
        dispatch({type:C.LOGOUT}); // don't really need to do this, but nice to get immediate feedback
        fireAuth.signOut().then(() => {
          // Sign-out successful.
          // Will push to home -- that should push to login
        }, (error) => {
          // same as logout . . push to login page -- hopefully we don't have this problem
        });
    }
}

export const setUserAsNotNew = function (uid) {
    return function(dispatch,getState){
        usersRef.child(uid).update({brandNew: false});
    }
}

export const addMobile = function () {
    return function(dispatch,getState){
        // get uid from auth state
        //get mobile form data
        const uid = getState().auth.uid;
        const mobile = getState().form.mobile.values.mobile;
        usersRef.child(uid).update({ mobile });
    }
}

// TODO: reconcile data if property exists on invite
export const attemptRegister = (opt_Open, waiveFee) => {
    return function (dispatch, getState) {
        dispatch({type:C.ATTEMPTING_REGISTER});
        const formValues = getState().form.registration.values;
        console.log(formValues);

        fireAuth.createUserWithEmailAndPassword(formValues.email, formValues.password).then((userCredential) => {
                dispatch({type: C.DEMAND_VERIFICATION});

                const fullname = formValues.firstname + ' ' + formValues.lastname;
                let mData = {};
                mData[formValues.invitor] = true; //need fullname here
                const reconcileId = formValues.reconcile;
                const group = formValues.makeManager ? C.MANAGER_GROUP : C.DRIVER_GROUP;
                const newUser = {
                    dateAdded: C.TS,
                    firstname: formValues.firstname,
                    lastname: formValues.lastname,
                    fullname: fullname,
                    email: formValues.email,
                    mainParentEmail: formValues.parentEmail1 || null,
                    altParentEmail: formValues.parentEmail2|| null,
                    group,
                    credits: waiveFee ? 1 : 0,
                    brandNew: true,
                    invitedBy: formValues.invitor,
                    inviteKey: formValues.inviteKey,
                    managers: mData,
                    organization: formValues.oid,
                    dob: formValues.dob ? formValues.dob.toLocaleDateString() : null, //don't require dob for odps
                    dobStamp: formValues.dob ? formValues.dob.valueOf() : null, // don't require dob for odps
                    risk: -2
                };
                userCredential.user.updateProfile({
                    displayName: fullname
                }).then(() => {
                    // set the users display name
                });

                usersRef.child(userCredential.user.uid).set(newUser).then(() => {
                    if (reconcileId) {
                        // let the function handle reconciliation
                        C.DB.ref("reconciliationRequests/" + reconcileId).update({confirmed: true});
                    }
                    if (formValues.inviteKey == '-MuWDnVBzUALokJH88Hi') {
                        C.GA.logEvent('ra_sign_up', newUser);
                    }

                    const memberUpdate = {}
                    memberUpdate[userCredential.user.uid] = fullname;
                    usersRef.child(formValues.invitor + "/drivers").update(memberUpdate).then(() => {
                        groupsRef.child(group).child("members").update(memberUpdate).then((snap1) => {
                            orgsRef.child(formValues.oid).child("members").update(memberUpdate).then((snap2) => {
                                console.log("Is this an open invite?", opt_Open ? "yes" : "no");
                                if (opt_Open) {

                                    invitesRef.child(formValues.inviteKey + "/registrationCount").transaction((currentCount) => {
                                      var newValue = (currentCount || 0) + 1;
                                      return newValue;
                                    })

                                } else {
                                    invitesRef.child(formValues.inviteKey).update({ registered: C.TS }).then((snap3) => {
                                        usersRef.child(userCredential.user.uid).child('inviteKey').set(null);
                                    });
                                }
                                
                            });
                        });
                    });
                    // denormalize: add this user to global group and org list
                    // TODO: easier and more efficent to do in one call to "update"
                    
                });
            }).catch((error) => {
            switch (error.code) {
                case "auth/email-already-in-use":
                    dispatch({type: C.DISPLAY_ERROR, error: "Account already exists with this e-mail"}); //TODO: Implement forgot password
                    break;
                case "auth/invalid-email":
                    dispatch({type: C.DISPLAY_ERROR, error: "Invalid email"});
                    break;
                case "auth-weak-password":
                    dispatch({type: C.DISPLAY_ERROR, error: "Password is not strong enough"});
                    break;
                default:
                    //TODO: add link to email support
                    dispatch({type: C.DISPLAY_ERROR, error: "Error creating account, please try again. (" + error.code + ")"});
            }
            dispatch({type:C.LOGOUT});
        });
    }
}

export const forceVerifyEmail = function (uid) {

    return function(dispatch, getState) {
        let verify = functions.httpsCallable('forceVerifyEmail');
        verify({uid}).then(res => {
            prodConsole.log(`forced email verification for ${uid}`, res);
        }).catch(err => {
            prodConsole.log(`Error forcing email verification for ${uid}`, err);
        })
    }

    
}

export const updateApiToken = function () {

    return function(dispatch, getState) {
        dispatch({type: C.SET_API_TOKEN_STATE, state: 'settingV1'});
        let token = hlp.generateUUID();
        let oid = getState().auth.oid;
        orgsRef.child(oid + '/api').update({token}).then(() => {
            dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
        });
    }

    
}

export const createSecondaryApiToken = function () {

    return function(dispatch, getState) {
        dispatch({type: C.SET_API_TOKEN_STATE, state: 'settingV2'});
        if (confirm('This will generate a secondary auth token for use as a backup or during key rotation. Proceed?')) {
            let tokenV2 = hlp.generateUUID();
            let oid = getState().auth.oid;
            orgsRef.child(oid + '/api').update({tokenV2}).then(() => {
                dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
            });
        } else {
            dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
        }

        
    }

    
}

export const deleteSecondaryApiToken = function () {

    return function(dispatch, getState) {
        dispatch({type: C.SET_API_TOKEN_STATE, state: 'deletingV2'});
         if (confirm('This will delete the Secondary Auth Token. Any calls to Diagnostic Driving using this token will result in an error. Proceed?')) {
            let tokenV2 = null;
            let oid = getState().auth.oid;
            orgsRef.child(oid + '/api').update({tokenV2}).then(() => {
                dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
            });
         } else {
            dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
         }

        
    }

    
}

export const promoteSecondaryApiToken = function () {

    return function(dispatch, getState) {
        dispatch({type: C.SET_API_TOKEN_STATE, state: 'promotingV2'});
        if (confirm("The Primary Auth Token will be overwritten with the Secondary Auth Token. Any calls to Diagnostic Driving using the (former) Primary Auth Token will result in an error. Proceed?")) {
            let oid = getState().auth.oid;
            orgsRef.child(oid + '/api/tokenV2').once('value').then((tv2Snap) => {
                let token = tv2Snap.val();
                orgsRef.child(oid + '/api').update({token}).then(() => {
                    let tokenV2 = null;
                    orgsRef.child(oid + '/api').update({tokenV2}).then(() => {
                        dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
                    });
                });
            })
        } else {
            dispatch({type: C.SET_API_TOKEN_STATE, state: 'idle'});
        }
        
    }

    
}


