import { C } from '../constants';
import * as hlp from '../utils/helpers';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import _ from 'lodash';
import moment from 'moment';
import assessment from '../pages/components/assessment';


const functions = firebase.functions();
const makeQuery = functions.httpsCallable('makeQuery');
const scoringOptionsRef = C.APP.database().ref('scoringOptions');
const orgTypeOptionsRef = C.APP.database().ref('orgTypeOptions');
const assessmentsBaseRef = C.APP.database().ref('assessments');
const intakesRef = C.APP.database().ref('intakeforms');
let realTimeQueryListeners = [];

const EXCLUDED_CUSTOMER_IDS = [
    "toni_dxd",
    "venk_dxd",
    "luke_dxd",
    "ivan_dxd",
    "camden_dxd",
    "david_dxd",
    "123",
    "1234",
    "12345",
    "123456",
    "0",
    "00", 
    "000", 
    "0000", 
    "00000", 
    "000000", 
    "0000000", 
    "00000000", 
    "000000000", 
    "0000000000", 
    "00000000000", 
    "999",  
    "999999", 
    "sean_dxd",  
    "666", 
    "1111", 
    "101393", 
    "029", 
    "007", 
    "005", 
    "002", 
    "00002", 
    "00003", 
    "000002222", 
    "000007777", 
    "000000007", 
    "000000004",
    "TestJoe"
]

const DOMAINS = [
    'Speed Management',
    'Road Positioning',
    'Gap Selection',
    'Managing Blind Spot',
    'Hazard Anticipation & Response',
    'Attention Maintenance',
    'Communication & Right of Way',
    'Vehicle Control'];

const DOMAIN_DESCRIPTIONS = {
    domain_1 : 'Driving too slow or too fast for conditions relative to posted speed limits relative to other drivers',
    domain_2 : 'Erratic steering, with inability to stay in lanes relative to other drivers',
    domain_3 : 'Following vehicles too closely (e.g., < 3 seconds headway time)',
    domain_4 : 'Not using the scan feature buttons at yields or at intersections',
    domain_5 : 'Not coming to a full stop or colliding with pedestrians at the active crosswalk',
    domain_6 : 'Not following the navigational commands correctly',
    domain_7 : 'Driving through red lights or stop signs without coming to complete stops',
    domain_8 : 'Crashing or nearly crashing into cars, people, or other objects',
    domain_9 : 'Overall rating for the drive'
};

const GROUP_TOOLTIPS = {
    'Speed Management' : DOMAIN_DESCRIPTIONS.domain_1,
    'Road Positioning' : DOMAIN_DESCRIPTIONS.domain_2,
    'Gap Selection' : DOMAIN_DESCRIPTIONS.domain_3,
    'Managing Blind Spot' : DOMAIN_DESCRIPTIONS.domain_4,
    'Hazard Anticipation & Response' : DOMAIN_DESCRIPTIONS.domain_5,
    'Attention Maintenance' : DOMAIN_DESCRIPTIONS.domain_6,
    'Communication & Right of Way' : DOMAIN_DESCRIPTIONS.domain_7,
    'Vehicle Control' : DOMAIN_DESCRIPTIONS.domain_8,
    'Overall Rating': DOMAIN_DESCRIPTIONS.domain_9
};

const COL_SIZES = {

    riskMessage: 300,
    trackStart: 400,
    end: 400
}

const PRETTY_NAMES = {
    id : "Assessment ID",
    inProgress : "In Progress",
    customerId : "Driver ID",
    firstName : "First Name",
    lastName : "Last Name",
    age : "Age (yrs)",
    ageCat : "Age Category",
    licenseStatus: "License Status",
    licenseStatusDirect: "License Status",
    userEmail : "Email",
    userFirstName: "First Name",
    userLastName: "Last Name",
    organizationId: "Organization ID",
    organizationName: "Organization Name",
    feedbackEmail: "Feedback Email",
    feedbackOpen: "Feedback Email Opened",
    feedbackCompleted: "Feedback Completed",
    feedbackCompletionDate: "Date Feedback Completed",
    emailedFeedback: "Feedback Emailed",
    department: "Department",
    startDate: "Date",
    startTime: "Time",
    status: "Status",
    trackStart: "Track Start",
    secondsInPractice: "Practice Duration (seconds)",
    practiceInProgress: "Practice In Progress",
    brakingPracticeIncomplete: "Completed Braking Practice",
    setGearToPark: "Passed Set Gear To Park",
    lookLeft: "Passed Look Left",
    lookRight: "Passed Look Right",
    signalRight: "Passed Signal Left",
    turnOffSignal: "Passed Turn Off Signal",
    map: "Map",
    variant: "Variant",
    flags: "Flags",
    events: "Has Events",
    replay: "Replay",
    replayUrl: "Replay URL",
    hasReplay: "Replay Saved",
    end: "End Time",
    completed: "Completed",
    assessmentDuration: "Assessment Duration (seconds)",
    totalDuration: "Practice & Assessment Duration (seconds)",
    completeDuration: "Total Duration (min)",
    location: "Location",
    language: "Language",
    riskLevel: "Risk Level",
    riskScore: "Risk Score",
    riskMessage: 'Risk',
    vehicleCollisions: 'Vehicle Collisions',
    pedestrianCollisions: 'Pedestrian Collisions',
    noviceGrade: "Grade",
    noviceLevel: "Level",
    novicePercentile: "Percentile",
    experiencedGrade: "Grade",
    experiencedLevel: "Level",
    experiencedPercentile: "Percentile",
    noviceDomain1Grade: "Speed Management Grade",
    noviceDomain1Level: "Speed Management Improvement Opportunity",
    noviceDomain2Grade: "Road Positioning Grade",
    noviceDomain2Level: "Road Positioning Improvement Opportunity",
    noviceDomain3Grade: "Gap Selection Grade",
    noviceDomain3Level: "Gap Selection Improvement Opportunity",
    noviceDomain4Grade: "Managing Blind Spot Grade",
    noviceDomain4Level: "Managing Blind Spot Improvement Opportunity",
    noviceDomain5Grade: "Hazard Anticipation & Response Grade",
    noviceDomain5Level: "Hazard Anticipation & Response Improvement Opportunity",
    noviceDomain6Grade: "Attention Maintenance Grade",
    noviceDomain6Level: "Attention Maintenance Improvement Opportunity",
    noviceDomain7Grade: "Communication & Right of Way Grade",
    noviceDomain7Level: "Communication & Right of Way Improvement Opportunity",
    noviceDomain8Grade: "Vehicle Control Grade",
    noviceDomain8Level: "Vehicle Control Improvement Opportunity",
    experiencedDomain1Grade: "Speed Management Grade",
    experiencedDomain1Level: "Speed Management Improvement Opportunity",
    experiencedDomain2Grade: "Road Positioning Grade",
    experiencedDomain2Level: "Road Positioning Improvement Opportunity",
    experiencedDomain3Grade: "Gap Selection Grade",
    experiencedDomain3Level: "Gap Selection Improvement Opportunity",
    experiencedDomain4Grade: "Managing Blind Spot Grade",
    experiencedDomain4Level: "Managing Blind Spot Improvement Opportunity",
    experiencedDomain5Grade: "Hazard Anticipation & Response Grade",
    experiencedDomain5Level: "Hazard Anticipation & Response Improvement Opportunity",
    experiencedDomain6Grade: "Attention Maintenance Grade",
    experiencedDomain6Level: "Attention Maintenance Improvement Opportunity",
    experiencedDomain7Grade: "Communication & Right of Way Grade",
    experiencedDomain7Level: "Communication & Right of Way Improvement Opportunity",
    experiencedDomain8Grade: "Vehicle Control Grade",
    experiencedDomain8Level: "Vehicle Control Improvement Opportunity",
    novice_speedSuggestion: "Monitor Speeding",
    novice_steeringCorneringSuggestion: "Monitor Steering/Cornering",
    novice_hardBrakingSuggestion: "Monitor Hard Braking",
    novice_cellPhoneUseSuggestion: "Monitor Mobile Phone Use",
    experienced_speedSuggestion: "Monitor Speeding",
    experienced_steeringCorneringSuggestion: "Monitor Steering/Cornering",
    experienced_hardBrakingSuggestion: "Monitor Hard Braking",
    experienced_cellPhoneUseSuggestion: "Monitor Mobile Phone Use",
}

const SUGGESTION_MAP = {
    speed: "Speeding",
    steeringCornering: "Steering/Cornering",
    hardBraking: "Hard Braking",
    cellPhoneUse: "Mobile Phone Use"
}

const DEFAULT_COLS = {
    customerId: true,
    firstName: true,
    lastName: true,
    startDate: true,
    location: true,
}

const DSO_COLS = {
    customerId: true,
    firstName: true,
    lastName: true,
    startDate: true,
    location: true,
}

const BASIC_COLS = {
    customerId: true,
    firstName: true,
    lastName: true,
    startDate: true,
    completed: true,
    hasReplay: true
}

const LIMITED_COLS = {
    customerId: true,
    firstName: true,
    lastName: true,
    startDate: true,
    startTime: true,
    location: true,
    completed: true,
    hasReplay: true,
    completeDuration: true,
    emailedFeedback: true,
    age: true,
    licenseStatus: true
}

const LIMITED_V_COLS = {
    customerId: true,
    firstName: true,
    lastName: true,
    startDate: true,
    startTime: true,
    location: true,
    completed: true,
    hasReplay: true,
    completeDuration: true,
    emailedFeedback: true,
    ageCat: true,
    licenseStatusDirect: true
}

const EXP_COLS = {
    id: true,
    organizationName: true,
    location: true,
    firstName: true,
    lastName: true,
    customerId: true,
    startTime: true,
    startDate: true,
    completeDuration: true,
    completed: true,
    hasReplay: true,
    feedbackEmail: true,
    age: true,
    sex: true,
    race: true,
    ethnicity: true,
    licenseStatus: true,
    percentile: true,
    errorScore: true,
    vCrashCount: true,
    pCrashCount: true,
    infractionCount: true,
    stopSignInf: true,
    redLightInf: true,
    navInf: true,
    followInf: true,
    schoolZoneInf: true,
    crosswalkInf: true,
    schoolBusInf: true,
    ambulanceInf: true,
    yieldInf: true,
    constructionInf: true,
    aggSpeedInf: true,
    tooSlowInf: true,
    overSteerInf: true,
    weavingInf: true,
    jerkyInf: true,
    offCourseInf: true,
    offLaneInf: true,
    scanInf: true,
    rearEndNeedsWork: true,
    rearEndCrash: true,
    rearEndDanger: true,
    curveNeedsWork: true,
    curveCrash: true,
    curveDanger: true,
    tooFastNeedsWork: true,
    tooFastCrash: true,
    tooFastDanger: true,
    intersectionNeedsWork: true,
    intersectionCrash: true,
    intersectionDanger: true,
    otherAreasNeedsWork: true,
    otherAreasCrash: true,
    otherAreasDanger: true
}

const COMPLIANCE_COLS = {
    id: true,
    organizationName: true,
    location: true,
    userFirstName: true,
    userLastName: true,
    customerId: true,
    startTime: true,
    startDate: true,
    completeDuration: true,
    completed: true,
    hasReplay: true,
    userEmail: true,
    age: true,
    sex: true,
    race: true,
    ethnicity: true,
    licenseStatus: true,
    percentile: true,
    errorScore: true,
    feedbackCompleted: true,
    feedbackCompletionDate: true
}

const MPI_COLS = {
    id: true,
    customerId: true,
    location: true,
    startDate: true,
    startTime: true,
    completeDuration: true,
    completed: true,
    hasReplay: true,
    language: true
}



export const loadInitialDashQuery = function (opt_aid, opt_dimensionName, opt_dimensionValue, opt_showIds, opt_limit) {
    return function (dispatch, getState) {

        let orgType = getState().auth.orgType;
        
        // console.log("**dash.js action loadInitialDashQuery orgType is " + orgType);
        let basic = orgType == 'BASIC';
        let lim = orgType == 'LIMITED' || orgType == 'LIMITED_V' || orgType == 'EXP' || orgType == 'COMPLIANCE';
        let mpi = orgType == 'MPI';

        let columns = DEFAULT_COLS;

        if (orgType == 'DSO') {
            columns = DSO_COLS;
        }

        if (orgType == 'LIMITED') {
            columns = LIMITED_COLS;
        }

        if (orgType == 'EXP') {
            columns = EXP_COLS;
        }

        if (orgType == 'LIMITED_V') {
            columns = LIMITED_V_COLS;
        }

        if (orgType == 'MPI') {
            columns = MPI_COLS;
        }

        if (basic) {
            columns = BASIC_COLS;
        }

        if (orgType == 'COMPLIANCE') {
            columns = COMPLIANCE_COLS;
        }

        if (getState().dash.showAllOrgs) {
            columns.organizationName = true;
        }



        if (opt_showIds) {
            columns.id = true;
        }

        let headerNames = {
            completeDuration : 'Total Duration (min)',
            noviceLevel : "Improvement Opportunity",
            experiencedLevel : "Improvement Opportunity",
            collisionLevel0: "Vehicles and pedestrians",
            collisionLevel1: "Objects",
        }

        let colSizes = {
            startDate: 125,
            id: 160,
            customerId: 160,
            firstName: 160,
            lastName: 160,
            completeDuration: 125,
            completed: 100,
            noviceGrade: 90,
            experiencedGrade: 90,
            location: 100,
            department: 100,
            noviceLevel: 90,
            experiencedLevel: 90,
            novicePercentile: 90,
            experiencedPercentile: 90,
            collisionLevel0: 90,
            collisionLevel1: 90,
            domainName: 450
        }

        let scoringMethod = getState().auth.scoringMethod;
        


        if (orgType == 'default') {
            columns.department = true;
        }

        if (!basic) {
            columns.completeDuration = true;
            columns.completed = true;
        }
        

        if (orgType == 'DSO') {
            columns.collisionLevel0 = true;
            columns.collisionLevel1 = true;
        }

        

        let prefix = scoringMethod.split('_')[0];

        let keyList = [];

        for (var i = 1; i < 9; i++) {
            let domPrefix = prefix + 'Domain' + i;
            keyList.push(domPrefix + 'Grade');
            keyList.push(domPrefix + 'Level');
            headerNames[domPrefix + 'Grade'] = 'Grade';
            headerNames[domPrefix + 'Level'] = 'Improvement Opportunity';
            colSizes[domPrefix + 'Grade'] = 90;
            colSizes[domPrefix + 'Level'] = 90;
        };

        if (!basic && !lim && !mpi) {
            for (var i = 0; i < keyList.length; i++) {
                columns[keyList[i]] = true;
            };
        }
        

        if (orgType == 'default') {
            _.map(SUGGESTION_MAP, (v, k) => {
                let key = prefix + '_' + k + 'Suggestion';
                columns[key] = true;
                headerNames[key] = v;
                colSizes[key] = 90;
            })
        }

        if (!basic && !lim && !mpi) {
            columns[prefix + 'Grade'] = true;
            columns[prefix + 'Level'] = true;
            columns[prefix + 'Percentile'] = true;            
        }

        dispatch({ type: C.SET_QRY_SCORING_METHOD, method: scoringMethod});

        if (opt_aid && (!basic && !lim && !mpi)) {
            dispatch({ type: C.SET_QRY_AID, aid: opt_aid});
        }

        if (opt_limit && (!basic && !lim && !mpi)) {
            dispatch({ type: C.SET_QRY_LIMIT, limit: opt_limit});
        }

        if (opt_dimensionName && opt_dimensionValue && (!basic && !lim && !mpi)) {
            dispatch({ type: C.SET_QRY_DIMENSIONS, dimensionName: opt_dimensionName, dimensionValue: opt_dimensionValue});
        }


        



        const aDeets = "Assessment Details";
        const ovrt = "Overall Rating";
        const drt = "Domain Ratings"
        const crashes = "Crashes"

        let columnGroups = {
            id : [aDeets],
            firstName : [aDeets],
            lastName : [aDeets],
            customerId : [aDeets],
            startDate : [aDeets],
            location : [aDeets],
            // department : [aDeets],
            completeDuration : [aDeets],
            completed : [aDeets]

        }

        if (orgType == 'default') {
            columnGroups.department = [aDeets];
        }

        


        for (var i = 1; i < 9; i++) {
            let domPrefix = prefix + 'Domain' + i;
            columnGroups[domPrefix + 'Grade'] = [drt, DOMAINS[i - 1]];
            columnGroups[domPrefix + 'Level'] = [drt, DOMAINS[i - 1]];
        };

        columnGroups.noviceGrade = [ovrt];
        columnGroups.noviceLevel = [ovrt];
        columnGroups.novicePercentile = [ovrt];
        columnGroups.experiencedGrade = [ovrt];
        columnGroups.experiencedLevel = [ovrt];
        columnGroups.experiencedPercentile = [ovrt];


        if (orgType == 'DSO') {
            columnGroups.collisionLevel0 = [crashes];
            columnGroups.collisionLevel1 = [crashes];
        }

        let stm = 'Suggested Telematics Monitoring';


        _.map(SUGGESTION_MAP, (v, k) => {
            let pre = prefix + '_';
            columnGroups[pre + k + 'Suggestion'] = [stm];
        })

        if (basic || lim || mpi) {
            columnGroups = {};
        }

        dispatch({ type: C.SET_COL_SIZES, colSizes});
        dispatch({ type: C.SET_HEADER_NAMES, headerNames, columnGroups});
        dispatch({ type: C.SET_COLUMNS, columns});
        // if some flag -- start listening to set of assessments instead of query
        if (getState().auth.isParent) {
            dispatch({type: C.SET_DASH_ORG, org: 'ALL'});
        }

        query(dispatch, getState);

        
    }
}

export const startListeningToScoringOptions = function () {
    return function (dispatch, getState) {
        scoringOptionsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_SCORING_OPTIONS, options: snap.val()});
        });
    }
}

export const startListeningToOrgTypeOptions = function () {
    return function (dispatch, getState) {
        orgTypeOptionsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_ORG_TYPE_OPTIONS, options: snap.val()});
        });
    }
}

export const submitNewScoringOption = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.addScoringOption.values;
        const scoringOption = formValues.scoringOption;
        
        dispatch({type: C.AWAIT_NEW_SCORING_OPTION_RESPONSE});

        scoringOptionsRef.once('value').then(list => {
            let locs = list.val() || [];
            locs.push(scoringOption);
            scoringOptionsRef.set(locs).then(() => {
                dispatch({type:C.RECEIVE_NEW_SCORING_OPTION_RESPONSE});
            }).catch(error => {
                dispatch({type:C.RECEIVE_NEW_SCORING_OPTION_RESPONSE});
                dispatch({type: C.DISPLAY_ERROR, error: "Failed to add new scoring option!"}) //TODO: better errors
            })
        });
    }
}

export const submitNewOrgTypeOption = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.addOrgTypeOption.values;
        const orgTypeOption = formValues.orgTypeOption;
        
        dispatch({type: C.AWAIT_NEW_ORG_TYPE_OPTION_RESPONSE});

        orgTypeOptionsRef.once('value').then(list => {
            let locs = list.val() || [];
            locs.push(orgTypeOption);
            orgTypeOptionsRef.set(locs).then(() => {
                dispatch({type:C.RECEIVE_NEW_ORG_TYPE_OPTION_RESPONSE});
            }).catch(error => {
                dispatch({type:C.RECEIVE_NEW_ORG_TYPE_OPTION_RESPONSE});
                dispatch({type: C.DISPLAY_ERROR, error: "Failed to add new org type option!"}) //TODO: better errors
            })
        });
    }
}

function getOrGenerateGroup(nestStrings, list) {
    let placeToPut = list;
    for (var i = 0; i < nestStrings.length; i++) {
        let name = nestStrings[i];
        let group = null;
        for (var j = 0; j < list.length; j++) {
            if (list[j].headerName == name) {
                group = list[j];
            }
        };
        if (!group) {
            group = {headerName : name, headerTooltip: GROUP_TOOLTIPS[name] || name};
            group.children = [];
            list.push(group);
        }
        placeToPut = group.children;
    };
    return placeToPut;
}

function addColumnDefinition(col, colGroups, list, data) {
    let placeToPut = getOrGenerateGroup(colGroups[col] || [], list);
    placeToPut.push({
        headerName: (data.headerNames && data.headerNames[col]) || PRETTY_NAMES[col],
        headerTooltip: (data.headerTooltips && data.headerTooltips[col]) || ((data.headerNames && data.headerNames[col]) || PRETTY_NAMES[col]),
        field: col,
        sortable: true,
        filter: true,
        resizable: true,
        width: ((data.colSizes && data.colSizes[col]) || COL_SIZES[col]) || 200
    });

}

function getValueFromIntake(assessment, intakeMap, questionNames) {
    if (assessment.intake) {
        let intake = intakeMap[assessment.intake];
        if (intake && intake.responses) {
            
            for (var i = 0; i < questionNames.length; i++) {
                let thisName = questionNames[i];
                if (intake.responses[thisName]) {
                    return intake.responses[thisName];
                }
            };
        }
    }
    return "n/a";
}

function getAge(assessment, intakeMap) {
    if (assessment.intake) {
        let intake = intakeMap[assessment.intake];
        if (intake && intake.responses) {
            let ageQName  = 'Ohio2020-26>>Ohio2020-26';

            if ((assessment.loginTime || assessment.start) && intake.responses[ageQName]) {
                let dateVal = moment(parseInt(intake.responses[ageQName])); //new Date(parseInt(intake.responses[ageQName]));
                let start = moment(assessment.loginTime || assessment.start); //new Date(assessment.loginTime || assessment.start);
                // var now = new Date();
                return start.diff(dateVal, 'years');
            }

        }
    }
    return -1;
}

function getAgeGroup(age) {
    if (age < 0) {
        return '_va0'
    }
    if (age >= 18) {
        return '_va2';
    }
    return '_va1';
}

function hasInfraction(asm, g, inf) {
    let infractions = asm.feedbackData && asm.feedbackData['infractions' + g] || [];
    let has = 'n/a'; // will never be this now, but ok.
    if (infractions) {
        has = 'no';
        for (var i = 0; i < infractions.length; i++) {
            if (infractions[i].name == inf) {
                has = 'yes';
            }
        };
    }
    return has;
}

function getCommonCrashProp(asm, name, property) {
    let cc = asm.feedbackData && asm.feedbackData.common_crashes;
    if (cc) {
        for (var i = 0; i < cc.length; i++) {
            let crash = cc[i];
            if (crash.name == name) {
                let prop = crash[property];
                return prop ? 'yes' : 'no';
            }
        };
    }
    return 'n/a';
}

const getAsmntValFunctions = {
    customerId: (assessment, intakeMap) => {return assessment.user},
    // need to provide all organizations so we can find name or abbreviation
    organizationName : (assessment, intakeMap, key, orgs) => {return assessment.organization && orgs && orgs[assessment.organization] && orgs[assessment.organization].name || 'n/a'},
    firstName: (assessment, intakeMap) => {
        return getValueFromIntake(assessment, intakeMap, ['first_name>>first_name', 'Name>>first_name', 'first_name_last_name>>first_name', 'firstName_lastName>>first_name']);
    },
    inProgress: (assessment) => {
        return assessment.inProgress ? 'YES' : 'NO'; 
    },
    lastName: (assessment, intakeMap) => {
        return getValueFromIntake(assessment, intakeMap, ['last_name_lowercase>>last_name_lowercase', 'last_name>>last_name', 'Name>>last_name_initial', 'first_name_last_name>>last_name', 'firstName_lastName>>last_name']);
    },
    startDate: (assessment, intakeMap) => {
        
        if (assessment.loginTime || assessment.start) {
            let start = new Date(assessment.loginTime || assessment.start);
            var mnth = start.getMonth() + 1;
            var dt = start.getDate();
            return [(mnth < 10 ? '0' + mnth : mnth), (dt < 10 ? '0' + dt : dt), start.getFullYear()].join('/');
        }
        return 'n/a';
    },
    startTime: (assessment, intakeMap) => {
        if (assessment.loginTime || assessment.start) {
            let start = new Date(assessment.loginTime || assessment.start);
            var hrs = start.getHours();
            var ampm = hrs >= 12 ? ' PM' : ' AM';
            var mns = start.getMinutes();
            var scs = start.getSeconds();
            hrs = hrs % 12;
            hrs = hrs ? hrs : 12;
            return [hrs, (mns < 10 ? '0' + mns : mns), (scs < 10 ? '0' + scs : scs)].join(':') + ampm;
        }
        return 'n/a';
    },
    location: (assessment, intakeMap) => {return assessment.location},
    completed: (assessment, intakeMap) => {return assessment.completed ? 'YES' : 'NO'},
    hasReplay: (assessment, intakeMap) => {return assessment.replay && assessment.replay.ref ? 'YES' : 'NO'},
    completeDuration: (assessment, intakeMap) => {
        let loginTime = new Date(assessment.loginTime) ;
        let end = new Date(assessment.end);
        let lastUpdate = new Date(assessment.lastUpdate);
        let secs = 0;
        if  (loginTime) {
            if (end) {
                secs = Math.round(( end.getTime() - loginTime.getTime() ) / 1000)
            }
            if (lastUpdate) {
                var now = Date.now();
                if (Math.abs( now - lastUpdate.getTime() ) > 5000) {
                    secs = Math.round( (lastUpdate.getTime() - loginTime.getTime()) / 1000 );
                }
            }
        }
        if (secs > 0) {
            var hours = Math.floor(secs / 60 / 60);
            var minutes = Math.floor(secs / 60) - (hours * 60);
            var seconds = secs % 60;
            return (hours ? hours + ':' : '') + (hours ? minutes.toString().padStart(2, 0) : minutes) + ':' + seconds.toString().padStart(2, 0);
        }
        return 'n/a';
    },
    emailedFeedback: (assessment, intakeMap) => {
        return assessment.feedbackEmail || assessment.email ? 'YES' : 'NO';
    },
    age: (assessment, intakeMap, key, orgs, age, ageGroup) => {
        // console.log('%c WTF ', 'background: #F90; color: #FFF', age);
        if (age > 0) {
            return age;
        }
        return "n/a";
    },
    licenseStatus: (assessment, intakeMap, key, orgs, age, ageGroup) => {
        return hlp.determineLicenseStatus(assessment, intakeMap)
    },
    id: (assessment, intakeMap, key) => {return key},
    feedbackEmail: (assessment) => {return assessment.feedbackEmail || assessment.email},
    feedbackOpen: (assessment) => {
        if (assessment.feedbackOpen) {
            let stamps = [];
            _.map(assessment.feedbackOpen, (v, k) => {
                stamps.push(v);
            })
            return stamps.join(', ');
        }
        return 'n/a';
    },
    feedbackCompletionDate: (assessment, intakeMap) => {
        
        if (assessment.feedbackCompletionDate) {
            let ts = new Date(assessment.feedbackCompletionDate);
            var mnth = ts.getMonth() + 1;
            var dt = ts.getDate();
            return [(mnth < 10 ? '0' + mnth : mnth), (dt < 10 ? '0' + dt : dt), ts.getFullYear()].join('/');
        }
        return 'n/a';
    },
    feedbackCompleted: (assessment) => {
        if (assessment.feedbackCompleted) {
            return 'yes';
        }
        if (!assessment.completed) {
            return 'n/a';
        }
        return 'no';
    },
    userEmail: (assessment) => {
        return assessment.userEmail || '';
    },
    userFirstName: (assessment) => {
        return assessment.userFirstName || '';
    },
    userLastName: (assessment) => {
        return assessment.userLastName || '';
    },
    sex: (assessment, intakeMap) => {
        return getValueFromIntake(assessment, intakeMap, ['sex_otso>>sex_otso', 'sex>>sex']);
    },
    race: (assessment, intakeMap) => {
        return getValueFromIntake(assessment, intakeMap, ['Ohio2020-27>>Ohio2020-27']);
    },
    ethnicity: (assessment, intakeMap) => {
        return getValueFromIntake(assessment, intakeMap, ['Ohio2020-28>>Ohio2020-28']);
    },
    percentile: (assessment, intakeMap, key, orgs, age, ageGroup) => {return (assessment && assessment.feedbackData) ? assessment.feedbackData['percentile' + ageGroup] : 'n/a'},
    errorScore: (assessment) => {return (assessment && assessment.feedbackData) ? assessment.feedbackData.vdt_error_score : 'n/a'},
    vCrashCount: (assessment) => {return (assessment && assessment.feedbackData) ? assessment.feedbackData.num_vehicle_crashes : 'n/a'},
    pCrashCount: (assessment) => {return (assessment && assessment.feedbackData) ? assessment.feedbackData.num_pedestrian_crashes : 'n/a'},
    infractionCount: (assessment, intakeMap, key, orgs, age, ageGroup) => {return (assessment && assessment.feedbackData) ? assessment.feedbackData['num_infractions' + ageGroup] : 'n/a'},
    stopSignInf: (assessment) => {return hasInfraction(assessment, '_va0', 'stop_sign_infraction')},
    redLightInf: (assessment) => {return hasInfraction(assessment, '_va0', 'red_light_infraction')},
    navInf: (assessment) => {return hasInfraction(assessment, '_va0', 'navigation_infraction')},
    followInf: (assessment) => {return hasInfraction(assessment, '_va0', 'unsafe_follow_infraction')},
    schoolZoneInf: (assessment) => {return hasInfraction(assessment, '_va0', 'school_zone_infraction')},
    crosswalkInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'crosswalk_infraction' + ageGroup)},
    schoolBusInf: (assessment) => {return hasInfraction(assessment, '_va0', 'school_bus_infraction')},
    ambulanceInf: (assessment) => {return hasInfraction(assessment, '_va0', 'ambulance_infraction')},
    yieldInf: (assessment) => {return hasInfraction(assessment, '_va0', 'yielding_infraction')},
    constructionInf: (assessment) => {return hasInfraction(assessment, '_va0', 'construction_infraction')},
    aggSpeedInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'aggro_speed_infraction' + ageGroup)},
    tooSlowInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'too_slow_infraction' + ageGroup)},
    overSteerInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'over_steer_infraction' + ageGroup)},
    weavingInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'weaving_infraction' + ageGroup)},
    jerkyInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'jerky_infraction' + ageGroup)},
    offCourseInf: (assessment) => {return hasInfraction(assessment, '_va0', 'off_course_infraction')},
    offLaneInf: (assessment, intakeMap, key, orgs, age, ageGroup) => {return hasInfraction(assessment, ageGroup, 'off_lane_center_infraction' + ageGroup)},
    scanInf: (assessment) => {return hasInfraction(assessment, '_va0', 'scanning_infraction')},
    rearEndNeedsWork: (assessment) => {return getCommonCrashProp(assessment, 'rear_end_common_crash', 'show_needs_work')},
    rearEndCrash: (assessment) => {return getCommonCrashProp(assessment, 'rear_end_common_crash', 'show_in_crash_column')},
    rearEndDanger: (assessment) => {return getCommonCrashProp(assessment, 'rear_end_common_crash', 'show_in_danger_column')},
    curveNeedsWork: (assessment) => {return getCommonCrashProp(assessment, 'curve_common_crash', 'show_needs_work')},
    curveCrash: (assessment) => {return getCommonCrashProp(assessment, 'curve_common_crash', 'show_in_crash_column')},
    curveDanger: (assessment) => {return getCommonCrashProp(assessment, 'curve_common_crash', 'show_in_danger_column')},
    tooFastNeedsWork: (assessment) => {return getCommonCrashProp(assessment, 'too_fast_common_crash', 'show_needs_work')},
    tooFastCrash: (assessment) => {return getCommonCrashProp(assessment, 'too_fast_common_crash', 'show_in_crash_column')},
    tooFastDanger: (assessment) => {return getCommonCrashProp(assessment, 'too_fast_common_crash', 'show_needs_work')},
    intersectionNeedsWork: (assessment) => {return getCommonCrashProp(assessment, 'manage_intersection_common_crash', 'show_needs_work')},
    intersectionCrash: (assessment) => {return getCommonCrashProp(assessment, 'manage_intersection_common_crash', 'show_in_crash_column')},
    intersectionDanger: (assessment) => {return getCommonCrashProp(assessment, 'manage_intersection_common_crash', 'show_in_danger_column')},
    otherAreasNeedsWork: (assessment) => {return getCommonCrashProp(assessment, 'other_areas_common_crash', 'show_needs_work')},
    otherAreasCrash: (assessment) => {return getCommonCrashProp(assessment, 'other_areas_common_crash', 'show_in_crash_column')},
    otherAreasDanger: (assessment) => {return getCommonCrashProp(assessment, 'other_areas_common_crash', 'show_in_danger_column')}

}

function getAssessmentValue(col, assessment, intakeMap, aid, orgs, age, ageGroup) {
    if (getAsmntValFunctions.hasOwnProperty(col)) {
        return getAsmntValFunctions[col](assessment, intakeMap, aid, orgs, age, ageGroup);
    }
    return '!';
}

function query(dispatch, getState) {
    let featureFlags = getState().auth.featureFlags;
    let showEmailOpen = getState().auth.showEmailOpen;
    dispatch({ type: C.AWAIT_NEW_DASH_DATA });
    let options = {};
    options.user = {
        oid: getState().auth.oid
    }
    let scMthd = getState().dash.scoringMethod;
    let shExc = getState().dash.showExcluded;
    let orgs = getState().orgs.data;

    if (!scMthd) {
        console.log('NO SCORING METHOD');
        dispatch({ type: C.CLEAR_WAIT });
        return;
    }

    options.columns = getState().dash.columns;
    let customColumns = getState().dash.customColumns;
    if (customColumns && customColumns.length) {
        for (var i = 0; i < customColumns.length; i++) {
            options.columns[customColumns[i]] = true;
        };
    }
    if (showEmailOpen) {
        options.columns.feedbackOpen = true;
    }
    options.headerNames = getState().dash.headerNames;
    options.columnGroups = getState().dash.columnGroups;
    options.colSizes = getState().dash.colSizes;
    options.start = getState().dash.start;
    options.end = getState().dash.end;
    options.orgs = getState().dash.orgs;
    if (!options.orgs || !options.orgs.length) {
        console.log("Is this org a parent?", getState().auth);
        if (getState().auth.isParent && getState().auth.childOrgs) {
            if (!featureFlags.REAL_TIME_DASHBOARD) {
                options.orgs = [getState().auth.oid].concat(Object.keys(getState().auth.childOrgs));
                options.columns = Object.assign({organizationName : true}, options.columns); // HACK for JS object to get organizationName as the 'first' property
            }
            
        }
    }
    options.aid = getState().dash.aid;
    options.scoringMethod = getState().dash.scoringMethod;
    options.dimensionName = getState().dash.dimensionName;
    options.dimensionValue = getState().dash.dimensionValue;
    options.customerIds = getState().dash.customerIds;
    options.limit = getState().dash.limit;
    options.showAllOrgs = getState().dash.showAllOrgs;
    // if ODPS set limit

    if (featureFlags.REAL_TIME_DASHBOARD) {
        // console.log('%c In RTD block ', 'background: #C00; color: #FFF');
        if (realTimeQueryListeners.length) {
            for (var i = 0; i < realTimeQueryListeners.length; i++) {
                assessmentsBaseRef.off('value', realTimeQueryListeners[i]);
            };
        }
        options.columns.inProgress = true;
        let orgIds = [getState().dash.org || getState().auth.oid];
        if(getState().dash.org && getState().dash.org == 'ALL') {
            orgIds = [getState().auth.oid];
            _.map(getState().auth.childOrgs, (org, id) => {
                orgIds.push(id);
            })
            if (!options.columns.organizationName) {
                options.columns = Object.assign({organizationName : true}, options.columns);
            }
        }
        let columnHeaders = [];
        let COL_GRPS = options.columnGroups || {};
        _.map(options.columns, (v, k) => {
            if (v) {
                addColumnDefinition(k, COL_GRPS, columnHeaders, options)
            }
            
        })
        let formattedData = {
            defaultColDef: {
                resizable: true
            },
            pagination: true,
            columnDefs: columnHeaders,
            rowData: []
            
        };
        const allAssessmentRowsMap = {};
        // need to listen to multiple queries for this org and any children
        try {
            let snapFunction = (snap) => {
                // console.log('%c In assessment listener ', 'background: #C00; color: #FFF');
                let assessments = snap.val();
                if (!shExc) {
                    console.log('%c FILTERING EXCLUDED ', 'background: #f90; color: #008');
                    assessments = filterExcluded(assessments);
                }
                

                let intakeMap = {};
                let intakePromises = [];
                _.map(assessments, (assessment, aid) => {
                    // let row = {};
                    // row.id = aid;
                    // _.map(options.columns, (val, col) => {
                    //     row[col] = getAsmntValFunctions[col](assessment);
                    // })
                    if (assessment.intake) {
                        intakePromises.push(intakesRef.child(assessment.intake).once('value'))
                    }

                })
                // console.log('%c intakePromises ', 'background: #C00; color: #FFF', intakePromises);
                Promise.all(intakePromises).then(intakeSnaps => {
                    for (var i = 0; i < intakeSnaps.length; i++) {
                        let snap = intakeSnaps[i];
                        intakeMap[snap.key] = snap.val();
                    };
                    _.map(assessments, (assessment, aid) => {
                        let row = {};
                        row.id = aid;
                        // get age and then  pass age to all functions
                        let age = getAge(assessment, intakeMap);
                        let ageGroup = getAgeGroup(age);
                        _.map(options.columns, (val, col) => {
                            row[col] = getAssessmentValue(col, assessment, intakeMap, aid, orgs, age, ageGroup) // getAsmntValFunctions[col](assessment, intakeMap);
                        })
                        allAssessmentRowsMap[aid] = row;
                        //formattedData.rowData.push(row);
                    });
                    addDateSorting(formattedData.columnDefs);
                    modifyDisplay(formattedData.columnDefs);
                    formattedData.rowData = _.map(allAssessmentRowsMap, (row, aid) => {
                        return row;
                    })
                    // makeQuery(options).then(result => {
                    //     console.log("ABOUT TO DISPATCH DASH", result.data, formattedData);

                        // need a new reducer that receives partial data and updates the list
                        // console.log('%c Dispatching receive dash data ', 'background: #007; color: #dddd00', formattedData);
                        dispatch({ type: C.RECEIVE_DASH_DATA, data:  formattedData});
                    // })
                    
                })



            };
            for (var i = 0; i < orgIds.length; i++) {
                /*

                if (qByDR) {
                    let param = customer ? 'orgCustEnd' : 'orgEnd';
                    let start = orgId + customer + state.overview.start.getTime();
                    let end = orgId + customer + state.overview.end.getTime()
                    console.log(param, start, end);
                    ovwQueryListener = assessmentsBaseRef.orderByChild(param).startAt(start).endAt(end).on('value', (snap) => {
                        dispatch({ type: C.RECEIVE_ASSESSMENTS_DATA, data: snap.val()})
                    });
                } else {
                    let whichParam = customer ? 'orgCust' : 'organization';
                    ovwQueryListener = assessmentsBaseRef.orderByChild(whichParam).equalTo(orgId + customer).limitToLast(viewLast).on('value', (snap) => {
                        dispatch({ type: C.RECEIVE_ASSESSMENTS_DATA, data: snap.val()})
                    });
                }

                */
                if (options.start && options.end) {
                    let sToken = orgIds[i] + new Date(options.start).getTime();
                    console.log('%c sToken', 'background: #C00; color: #FFF',  sToken, new Date(options.start), options.start);
                    let eToken = orgIds[i] + new Date(options.end).getTime();
                    console.log('%c eToken', 'background: #C00; color: #FFF',  eToken, new Date(options.end), options.end);
                    realTimeQueryListeners.push(assessmentsBaseRef.orderByChild('orgEnd').startAt(sToken).endAt(eToken).on("value", snapFunction));
                } else {
                    realTimeQueryListeners.push(assessmentsBaseRef.orderByChild('organization').equalTo(orgIds[i] + "").limitToLast(5000).on("value", snapFunction));
                }
                
            };
        }
        catch (exc) {
            console.log('%c exception ', 'background: #6a0; color: #FFF', exc);
        }
        
    } else {
        makeQuery(options).then( result => {
            console.log("result of makeQuery: ", result);
            addDateSorting(result.data.columnDefs);
            modifyDisplay(result.data.columnDefs);
            dispatch({ type: C.RECEIVE_DASH_DATA, data:  result.data})
        } ).catch( err => {
            // deal with the error
            console.log('error making query', err);
            dispatch({ type: C.CLEAR_WAIT });
            dispatch({ type: C.RECEIVE_DASH_ERROR, error:  err})
        } )
    }

    
}

function filterExcluded(asmnts) {
    let filteredAssessments = {};
    _.map(asmnts, (data, key) => {
        // console.log('%c hmmm ', 'background: #f90; color: #008', data.user);
        if (!EXCLUDED_CUSTOMER_IDS.includes(data.user)) {
            filteredAssessments[key] = data;
        }
    })
    return filteredAssessments;
}

function getSqlFriendlyDate(date, time) {

    if (date && time) {
        let part1 = date.toISOString().slice(0, 19).split('T')[0];
        let part2 = time.toISOString().slice(0, 19).split('T')[1];
        console.log('getSqlFriendlyDate', part1, part2);
        return [part1, part2].join(' ');
    }

    if (date) {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    }

    return null;
}

const levelSort = {
    'LOW' : 0,
    'MEDIUM' : 1,
    'HIGH' : 2,
    'n/a' : 3
}

function domainFormatter(params) {
  return params.value + '    \u24D8';
}

function alterColumnDefs(columnDefs, levelKeys) {
    for (var i = 0; i < columnDefs.length; i++) {



        let colDef = columnDefs[i];

        if (colDef.children) {
            alterColumnDefs(colDef.children, levelKeys);
        }
        
        // console.log(colDef.field);

        if (levelKeys.includes(colDef.field)) {
            colDef.cellClassRules = {
                "low-opp" : function(params) {
                    return params.value === 'LOW';
                },
                "mid-opp" : function(params) {
                    return params.value === 'MEDIUM';
                },
                "high-opp" : function(params) {
                    return params.value === 'HIGH';
                }
            }
            
            colDef.comparator = function (v1, v2) {
                
                if (!v1 && !v2) {
                    return 0;
                }
                if (!v1) {
                    return -1;
                }
                if (!v2) {
                    return 1;
                }

                let v1Num = levelSort[v1];
                let v2Num = levelSort[v2];
                return v1Num - v2Num;
            }

        }


        // if (colDef.field == 'domainName') {
        //     colDef.cellRenderer = 'domainRenderer'
        // }

        // if (colDef.field == 'domainName') {
        //     colDef.valueFormatter = domainFormatter;
        // }

    };

}

function modifyDisplay(columnDefs) {
    let levelKeys = ['noviceLevel', 'experiencedLevel', 'level'];
    for (var i = 1; i < 9; i++) {
        levelKeys.push('noviceDomain' + i + 'Level');
        levelKeys.push('experiencedDomain' + i + 'Level');
    };
    
    alterColumnDefs(columnDefs, levelKeys)
}

function getHours(t) {
    let parts1 = t.split(/\s+/g);
    // console.log('getHours', t, parts1);
    let parts2 = parts1[0].split(':');
    if (parts1[1] == 'PM') {
        return parseInt(parts2[0]) + 12;
    }
    return parseInt(parts2[0]);
}

function getMinutes(t) {
    let parts1 = t.split(/\s+/g);
    let parts2 = parts1[0].split(':');

    return parseInt(parts2[1]);
}

function getSeconds(t) {
    let parts1 = t.split(/\s+/g);
    let parts2 = parts1[0].split(':');

    return parseInt(parts2[2]);
}

function nothingOrNa(d) {
    return !d || d == 'n/a';
}


function addDateSorting(columnDefs) {
    for (var i = 0; i < columnDefs.length; i++) {
        let colDef = columnDefs[i];

        if (colDef.children) {
            addDateSorting(colDef.children);
        }

        if (['startDate', 'end', 'trackStart', 'added', 'lastAssessment'].includes(colDef.field)) {
            colDef.comparator = function (date1, date2) {
                
                if (nothingOrNa(date1) && nothingOrNa(date2)) {
                    return 0;
                }
                if (nothingOrNa(date1)) {
                    return -1;
                }
                if (nothingOrNa(date2)) {
                    return 1;
                }
                // console.log('_____');
                // console.log(date1, date2);
                let date1Obj = new Date(parseInt(date1.split('/')[2]), parseInt(date1.split('/')[0]) - 1, parseInt(date1.split('/')[1]), 0, 0, 0).getTime();
                let date2Obj = new Date(parseInt(date2.split('/')[2]), parseInt(date2.split('/')[0]) - 1, parseInt(date2.split('/')[1]), 0, 0, 0).getTime();
                // console.log(date1Obj, date2Obj);
                return date1Obj - date2Obj;
            }
        }
        if (colDef.field == 'startTime') {
            colDef.comparator = function (time1, time2) {
                
                if (nothingOrNa(time1) && nothingOrNa(time2)) {
                    return 0;
                }
                if (nothingOrNa(time1)) {
                    return -1;
                }
                if (nothingOrNa(time2)) {
                    return 1;
                }
                // console.log(time1, time2);
                // console.log('time1 args', 2001, 0, 1, getHours(time1), getMinutes(time1), getSeconds(time1));
                // console.log('time2 args', 2001, 0, 1, getHours(time2), getMinutes(time2), getSeconds(time2));
                let date1Obj = new Date(2001, 0, 1, getHours(time1), getMinutes(time1), getSeconds(time1)).getTime();
                let date2Obj = new Date(2001, 0, 1, getHours(time2), getMinutes(time2), getSeconds(time2)).getTime();
                // console.log(date1Obj, date2Obj);
                return date1Obj - date2Obj;
            }
        }
    };

}

export const exportQueryToCSV = function (gridApi) {
    return function (dispatch, getState) {

        const date = new Date();

        let fileName = 'dxd_' +
            date.getFullYear() + (date.getMonth() + 1) + date.getDate() +
            date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds();

        let params = {
            fileName,
            columnGroups : true
        };

        gridApi.exportDataAsCsv(params);
    }
    
}

export const setQueryTimespan = function() {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_DASH_DATA });
        const state = getState();
        const formValues = state.form.queryTimespan.values;

        if (formValues) {
            dispatch(
                 { 
                    type: C.SET_TIMESPAN, 
                    start: getSqlFriendlyDate(formValues.startDate, formValues.startTime),
                    end: getSqlFriendlyDate(formValues.endDate, formValues.endTime)
                })
        }
        query(dispatch, getState);
        

    }
}

export const clearTimespan = function() {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_DASH_DATA });
        const state = getState();
            dispatch(
                 { 
                    type: C.SET_TIMESPAN, 
                    start: "",
                    end: ""
                })
        query(dispatch, getState);
        

    }
}

export const modifyQuery = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_DASH_DATA });
        const state = getState();
        const formValues = state.form.query.values;
        // console.log('BBOBBOXX', formValues);
        dispatch({ type: C.SET_COLUMNS, columns: formValues.columns});
        if (formValues.organizations) {
            dispatch({ type: C.SET_ORGS, columns: formValues.organizations});
        }
        query(dispatch, getState);
    }
}

export const changeDashOrg = function (oid) {
    return function (dispatch, getState) {
        // dispatch({ type: C.AWAIT_NEW_DASH_DATA });
        dispatch({ type: C.SET_DASH_ORG, org: oid });
        query(dispatch, getState);
        

    }
}
