
import _ from 'lodash';

import Paper from 'material-ui/Paper';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import store from '../../../store';
import AssetForm from './AssetForm';
import { Link } from 'react-router';

const style = {
  margin: 20,
  position: "relative"
};

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}



class Uploader extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToAssets() );
    }

    render () {
        const p = this.props;

        const bForm = (<AssetForm  onSubmit={(function (e) {e.preventDefault(); this.props.uploadAsset(); }).bind(this) } />);
        const wait = <div>Progress: {this.props.assets.progress.toFixed(2)}%</div>
        const loaded = this.props.assets.progress + "%";
        console.log("LOADED: ", loaded);
        const add = (
                     <Paper
                        style={style}
                        zDepth={1}
                        key={999}
                    >
                        <div style={{
                                position: "absolute",
                                top: "0px", 
                                right: "0px",
                                padding: "5px",
                                fontSize: "85%",
                                border: "1px solid #CCC",
                                zIndex: "999999999"
                            }}>
                            Total Bytes: {this.props.assets.totalBytes} - Bytes Transferred: {this.props.assets.bytesTransferred}
                        </div>
                        <div className="progressbar" style={{ width: loaded }}></div>
                        <div style={innerStyle} >{ this.props.assets.uploading ? wait : bForm}</div>
                    </Paper>
                     
        )
        //rows.unshift(add);
        
        const displayErrors = function () {
            if (p.assets.error) {
                return <Paper className="asset-upload-error" style={style} zDepth={1}>
                    <div style={innerStyle} >{p.assets.error}</div>
                </Paper>
            }
            return null;
        }


        // console.log("==================", hideThese);
        // _.map(hideThese, function (div, key) {
        //     // div.style.display = "none";
        //     console.log(div);
        // })

        return (
            <div>
                { displayErrors() }
                { add }
                
                    {
                        this.props.assets.hasReceivedAssetData ? <div>
                            {_.map(this.props.assets.data, (asset, key) => {
                                return  <Paper style={{margin: 20, padding: 20}}>
                                    <div className='row' key={key}>
                                    
                                        <div style={{paddingBottom:5}} className='col-sm-3'>{asset.name}</div>
                                        <div style={{paddingBottom:5}} className='col-sm-7'>{asset.path}</div>
                                        <div style={{paddingBottom:5}} className='col-sm-2'>
                                        <Link to={ "/view/asset/" + key }>
                                            view
                                        </Link>
                                        </div>
                                        <div className='col-sm-9'>{asset.description}</div>
                                        <div className='col-sm-3'>{key}</div>
                                    </div> </Paper>
                            }).reverse()}
                        </div> : null
                    }
                
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        assets: appState.assets,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadAsset: function () { dispatch( actions.uploadAsset() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Uploader);
