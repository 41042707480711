/*
This is our top-level component. Sub-components matching specific routes will be
contained in `this.props.children` and rendered out.
*/

import React, { Component } from 'react';


/*
Have this wrapper listen for auth -- show a spinner if awaiting auth info
need to know username and role to proceed

*/

class Spinner extends Component {

    // componentWillMount () {
    //     document.body.style.background = "#3b125d";
    // }

    // componentWillUnmount () {
    //     document.body.style.background = "#FFF";
    // }

    render () {
        return (
            <div className="login">
                <div className="login-form">
                    <div className="dxd-login-logo" />
                    <div className="dxd-loginSpinner" />
                </div>
            </div>
        )
    }
}

export default Spinner;
