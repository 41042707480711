
export function dupeWithout(obj, key) {
  let dupe = Object.assign({}, obj);
  if (key in dupe) {
    delete dupe[key];
  }
  return dupe;
}

export function onFbInit(doThis) {
    poll(() => {
        return window.firebaseAuthInit;
    }, 2000, 25).then(doThis);
}

export function createOneShot(fn) {
    let called = false;
    let result;
    return function() {
        if (!called) {
            called = true;
            result = fn.apply(this, arguments);
        }
        return result;
    };
}

export function qnorm(p) {
    p = parseFloat(p);
    const split = 0.42;
    
    const a0 = 2.50662823884;
    const a1 = -18.61500062529;
    const a2 = 41.39119773534;
    const a3 = -25.44106049637;
    const b1 = -8.47351093090;
    const b2 = 23.08336743743;
    const b3 = -21.06224101826;
    const b4 = 3.13082909833;
    const c0 = -2.78718931138;
    const c1 = -2.29796479134;
    const c2 = 4.85014127135;
    const c3 = 2.32121276858;
    const d1 = 3.54388924762;
    const d2 = 1.63706781897;
    
    const q = p - 0.5;
    
    let r, ppnd;
    if (Math.abs(q) <= split) {
        r = q * q;
        ppnd = q * (((a3 * r + a2) * r + a1) * r + a0) / ((((b4 * r + b3) * r + b2) * r + b1) * r + 1);
    } else {
        r = p;
        if (q > 0) r = 1 - p;
        if (r > 0) {
          r = Math.sqrt(-Math.log(r));
          ppnd = (((c3 * r + c2) * r + c1) * r + c0) / ((d2 * r + d1) * r + 1);
          if (q < 0) ppnd = -ppnd;
        } else {
          ppnd = 0;
        }
    }
    
    return ppnd;
    
}

export function toListWithAnd(a) {
    return [a.slice(0, -1).join(', '), a.slice(-1)[0]].join(a.length < 2 ? '' : ' and ');
}

export function isProduction() {
    // TODO: if domain is our production domain return true
    return window.location.host.toLowerCase() == "dxdriving.com" ||
    window.location.host.toLowerCase() == "odps-pdss.firebaseapp.com";
}

//function to compare values in an Object
export function compare (item, reverse) {
    return function (a,b) {
        //handle undefined
        if (!a.data[item]) {
            return Number(reverse ? -1 : 1)
        }
        //return the greater only if reverse is false
        if ((a.data[item] > b.data[item]))
            return Number(reverse ? -1 : 1);
        //return the lesser when reverse is true
        if ((a.data[item] < b.data[item]))
            return (reverse ? -1 : 1) * -1;
        return 0;
    }
}

//to compare alphabetically 
//to accept sortBy
export function compareName (sortBy, reverse) {
    return function (a,b) {
        // console.log("#######a and b", a, b)
        //switch statement
        switch (sortBy) {
            case "lastname":
                var textA = a[0].toUpperCase();
                var textB = b[0].toUpperCase();
                break;
            case "firstname":
                var textA = a[1].toUpperCase();
                var textB = b[1].toUpperCase();
                break;
            default:
                console.log("sortBy is: ", sortBy);
                break;
        }

        if (textA == textB) 
            return 0;
        if (reverse)
            return (textA < textB) ? 1 : -1;

        return (textA > textB) ? 1 : -1;
    }
}

export function poll(fn, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    return new Promise((resolve, reject) => {

        (function waitForIt () {
            if (fn()) {
                resolve();
            }

            else if (Number(new Date()) < endTime) {
                setTimeout(waitForIt, interval);
            }

            else {
                reject(new Error('timed out for ' + fn + ': ' + arguments));
            }
        })();

        
    });
}

//returns the aid of the latest assessment for the driver; if no assessments, return false
export function getLatestAssessment(driverData) {
    const obj = driverData.assessments;

    return obj ? Object.keys(obj).reduce((a, b) => { return obj[a] > obj[b] ? a : b }) : false;
}

export const scoreDriveResults = function (data) {
    let count = 0;
    let lapses = data.events;

    for (var obj in lapses) {
        let lapse = lapses[obj];

        if (lapse.type === "crash") {
            count ++;
        }
    }

    // initial automatic scoring: just check if there are any collision level 0 or collision level 1 safety lapses
    // call the driver dangerous if there are any of those two lapses
    let danger = (count>0 ? 2 : 0);
    let results = {danger: danger};
    data.results = results;

    return data;
}

export function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export function millisToSeconds(millis) {
    return Math.round(millis / 1000);
}

export function calculateDuration(data, now) {
    if (data.hasOwnProperty('start')) {
        if (data.hasOwnProperty('end')) {
            return millisToMinutesAndSeconds(data.end - data.start);
        }
        if (Math.abs(now - data.lastUpdate) > 5000) {
            return millisToMinutesAndSeconds(data.lastUpdate - data.start);
        }
        return "n/a";
    }
    return "n/a";
}

export function calculateSeconds(data, now) {
    if (data.hasOwnProperty('start')) {
        if (data.hasOwnProperty('end')) {
            return millisToSeconds(data.end - data.start);
        }
        if (Math.abs(now - data.lastUpdate) > 5000) {
            return millisToSeconds(data.lastUpdate - data.start);
        }
        return "n/a";
    }
    return "n/a";
}

export function calculatePracticeTime(data) {
    if (data.hasOwnProperty('start') && data.hasOwnProperty('trackStart')) {
        return millisToMinutesAndSeconds(data.trackStart - data.start);
    }
    return "n/a";
}

export function calculatePracticeSeconds(data) {
    if (data.hasOwnProperty('start') && data.hasOwnProperty('trackStart')) {
        return millisToSeconds(data.trackStart - data.start);
    }
    return "n/a";
}




export function determineEndTime(now, lastUpdate) {
    if (Math.abs(now - lastUpdate) > 5000) {
        return new Date(lastUpdate).toLocaleString();
    }
    return "";
}

export function calculateAssessmentDuration(data, now) {
    if (data.hasOwnProperty('trackStart')) {
        if (data.hasOwnProperty('end')) {
            return millisToMinutesAndSeconds(data.end - data.trackStart);
        }
        if (data.lastUpdate) {
            if (Math.abs(now - data.lastUpdate) > 5000) {
                return millisToMinutesAndSeconds(data.lastUpdate - data.trackStart);
            }
        }
    }
    return "n/a"
}

export function calculateAssessmentSeconds(data, now) {
    if (data.hasOwnProperty('trackStart')) {
        if (data.hasOwnProperty('end')) {
            return millisToSeconds(data.end - data.trackStart);
        }
        if (data.lastUpdate) {
            if (Math.abs(now - data.lastUpdate) > 5000) {
                return millisToSeconds(data.lastUpdate - data.trackStart);
            }
        }
    }
    return "n/a"
}

export function getDaysHoursMinutesAndSeconds(start, end) {
    let milliseconds = end - start;
    let days, hours, minutes, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    days = Math.floor(hours / 24);
    hours = hours % 24;
    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}

export function getVariant(asmData) {
    if (asmData.variant && asmData.map) {
        if (asmData.map == "map_3") {
            return "city 10";
        }
        return asmData.variant;
    }
    return "n/a";
}

export function generateUUID() {
    let d = new Date().getTime();
    
    if ( window.performance && typeof window.performance.now === "function" ) {
        d += window.performance.now();
    }
    
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });

    return uuid;
}

export function determineLicenseStatus(assessment, intakeMap, noVal='n/a', opt_intake) {
    // console.log("DETERMINE LICENSE STATUS", assessment, intakeMap, noVal);
    if ((assessment.intake && intakeMap && intakeMap[assessment.intake]) || opt_intake) {

        let intake = opt_intake || intakeMap[assessment.intake];

        let licenseStatus = intake.responses && intake.responses['license_status>>license_status'];
        if (licenseStatus) {
            return {'_1' : 'Valid License', '_2' : 'Suspended/Revoked License', '_3' : 'Learner Permit', '_4' : 'None'}[licenseStatus];
        } else {
            if (intake.survey) {
                let surveyId = intake.survey;
                switch(surveyId) {
                    case '-MXcGNqZVAfyT7DIuM2R':
                        return 'Valid License';

                    case '-Mo5f4YeUi3NsiCCmaGc':
                        return 'Valid License';


                    case '-MXcI4-ls5zC7LMHuWA-':
                        return 'Suspended/Revoked License';

                    case '-Mo5gdfLT-ja6OU-fki2':
                        return 'Suspended/Revoked License';


                    case '-MXcJQBceriKZfy401yB':
                        return 'Learner Permit';

                    case '-Mo5hxWBMgo9VzfQBeHX':
                        return 'Learner Permit';


                    case '-MXcJoqVXbLrQB3zNEwX':
                        return 'None';

                    case '-Mo5k2JZzjRq-dVx8eQm':
                        return 'None';

                    default:
                        return noVal;
                }
            }
        }
        
    }
    return noVal;
}
