
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';

import store from '../store';



class Snapshot extends Component {
	constructor(props, context) {
		super(props, context);
	}

	componentWillMount () {

		store.dispatch( actions.downloadSnapshot() );

	}

	render () {
		
		return (
			<div>
				Fetching assessments snapshot
			</div> 

		)
		
	}
}

const mapStateToProps = function (appState) {
	return {

	};
}

const mapDispatchToProps = function (dispatch) {//for dispatching actions
	return {

	}
}



export default connect(mapStateToProps, mapDispatchToProps)(Snapshot);