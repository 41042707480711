import { C } from '../constants';

const ffRef = C.APP.database().ref('featureFlags');

export const startListeningToFeatureFlags = function () {
    return function (dispatch, getState) {
        ffRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_FEATURE_FLAGS, flags: snap.val()});
        });
    }
}
