import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import MenuItem from 'material-ui/MenuItem';
import { SelectField } from 'redux-form-material-ui';
import { connect } from 'react-redux';


const validate = function (values) {
    const errors = {};
    if (!values.type) {
        errors.type = "type is required";
    }
    if (!values.lang) {
        errors.lang = "language is required";
    }
    if (values.type == 'intake' || values.type == 'postDrive') {
        if (!values.survey) {
            errors.survey = "survey value is required";
        }
    }

    if (values.type == 'surveySelect') {
        if (!values.surveySelectQuestion) {
            errors.surveySelectQuestion = "survey select question value is required";
        }
    }

    return errors;
}

const LABEL_MAP = {
    intake : 'Intake',
    postDrive : 'Post-Drive'
}

class AddAltSurvey extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        let surveySelectQuestionOptions = [];

        if (p.items.hasReceivedData && p.surveys.hasReceivedData) {
            surveySelectQuestionOptions = _.map(p.items.data, (item, itemId) => {
                if (item.type == 'multvert') {
                    if (item.choices && item.choices.length) {
                        let allowed = true;
                        // check if each choice value is a valid survey
                        for (var i = 0; i < item.choices.length; i++) {
                            let choice = item.choices[i];
                            allowed = allowed && p.surveys.data[choice.value];
                        };
                        if (allowed) {
                            return <MenuItem key={itemId} value={itemId} primaryText={item.name} />
                        }
                    }
                }
                return null;
            })
            // surveySelectQuestionOptions.unshift(<MenuItem key={'noSSQ'} value={null} primaryText={'None'} />)
        }

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="row add-alt-form">
                    <div className="col-sm-12">
                        <Field
                            name={"type"}
                            fullWidth={true}
                            component={SelectField}
                            floatingLabelText={"Type"}>
                                <MenuItem value={"intake"} primaryText={"Intake (Pre-Drive)"} />
                                <MenuItem value={"postDrive"} primaryText={"Post-Drive"} />
                                <MenuItem value={"surveySelect"} primaryText={"Survey Select Question"} />
                        </Field>
                    </div>
                    <div style={{display: (p.type ? 'block' : 'none')}} className="col-sm-12">
                        <Field
                            name={"lang"}
                            fullWidth={true}
                            component={SelectField}
                            floatingLabelText={"Language"}>
                                <MenuItem value={"fr"} primaryText={"French"} />
                        </Field>
                    </div>
                    {
                        p.type == 'surveySelect' ?
                        (

                            <div style={{display: (p.lang ? 'block' : 'none')}} className="col-sm-12">
                                <Field
                                    name={"surveySelectQuestion"}
                                    fullWidth={true}
                                    disabled={!p.surveys.hasReceivedData}
                                    component={SelectField}
                                    floatingLabelText={"Survey Select Question"}>
                                        { p.items.hasReceivedData ? surveySelectQuestionOptions : <MenuItem key={0} value={"default"} primaryText={"Loading Survey Select Question Options"} /> }
                                </Field>
                            </div>

                        )

                        :

                        (

                            <div style={{display: (p.lang ? 'block' : 'none')}} className="col-sm-12">
                                <Field
                                    name={"survey"}
                                    fullWidth={true}
                                    disabled={!p.surveys.hasReceivedData}
                                    component={SelectField}
                                    floatingLabelText={"Survey for " + LABEL_MAP[p.type]}>
                                        { p.surveys.hasReceivedData ? _.map(p.surveys.data, function (survey, surveyId) { 
                                                return survey.type && survey.type == "intake" ? <MenuItem key={surveyId} value={surveyId} primaryText={survey.name} /> : null;
                                            }) : <MenuItem key={0} value={"default"} primaryText={"Loading Intake Surveys"} /> }
                                </Field>
                            </div>

                        )
                    }
                    <div className="col-sm-12">
                        <RaisedButton
                            primary
                            disabled={this.props.pristine}
                            label="Save new survey variation"
                            type="submit" />
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
AddAltSurvey = reduxForm({
  form: 'addAltSurvey', // a unique name for this form
  validate
})(AddAltSurvey);

const selector = formValueSelector('addAltSurvey')
AddAltSurvey = connect(
  state => {
    // can select values individually
    const type = selector(state, 'type')
    const lang = selector(state, 'lang')
    return {
      type,
      lang,
      items: state.coachingItems,
      surveys: state.coachingSurveys,
    }
  }
)(AddAltSurvey)

export default AddAltSurvey;