import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import JSONTree from 'react-json-tree';
import IconButton from 'material-ui/IconButton';
import DeleteForever from 'material-ui/svg-icons/action/delete-forever';
import Paper from 'material-ui/Paper';
import {blue500, red500, greenA200} from 'material-ui/styles/colors';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#000000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

class Experiment extends Component {

    render() {
        const p = this.props;

        return (
          <Paper style={{ margin: "30px", padding: "25px"}}>
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: '-37px', right: '-10px', width: '48px', height: '48px'}}>
                    <IconButton
                        iconStyle={{width: 48, height: 48, color: '#999'}}
                        style={{}}
                        tooltip="Delete this experiment"
                        tooltipPosition="top-right"
                        onClick={this.props.deleteExperiment}
                    >
                        <DeleteForever />
                    </IconButton>
                </div>
                <JSONTree 
                    data={p.test}
                    theme={theme}
                    invertTheme={true}
                    hideRoot={true}/>
            </div>
          </Paper>     
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        // flags: appState.featureFlags
    }
}

const mapDispatchToProps = function(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Experiment);

