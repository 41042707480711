import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import store from '../../store';


class UserInfo extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    componentWillMount() {
        store.dispatch( actions.fetchUserData(this.props.uid) );
    }

    render () {
        const p = this.props;
        const uid = p.uid;

        const user = p.users && p.users.data && p.users.data[uid];

        if (!user) {
            return null;
        }

        return (
            <span className="userinfo" style={{ fontSize: "88%", fontWeight: "lighter"}}>
                    {"   " + user.fullname + "   " + user.email}
            </span>
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        users : appState.users
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);