import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { C } from '../constants';

const filesRef = C.APP.database().ref('files');
const storage = firebase.storage().ref();

export const startListeningToFiles = function () {
    return function (dispatch, getState) {
        filesRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_FILES_DATA, data: snap.val()});
        });
    }
}


export const deleteFile = function (fileId, file) {
    return function (dispatch, getState) {
        let fileRef = storage.child(file.ref);
        fileRef.delete().then(() => {
            filesRef.child(fileId).set(null).then(() => {
                prodConsole.log('file deleted');
            })
        }).catch(error => {
            prodConsole.log('error occured deleting file');
        })
    }
}

export const uploadFile = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.file.values;

        const file = formValues.file[0];

        dispatch({type: C.AWAIT_NEW_FILE_RESPONSE});

        const fileStorageRef = storage.child('helpFiles/' + file.name);
        console.log("fileStorageRef: ", fileStorageRef);

        var uploadTask = fileStorageRef.put(file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', (snapshot) => {

          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            dispatch({type: C.SET_FILE_UPLOAD_PROGRESS, progress, totalBytes: snapshot.totalBytes, bytesTransferred: snapshot.bytesTransferred})
            }, (error) => {
                // Handle unsuccessful uploads
                dispatch({type: C.RECEIVE_FILE_UPLOAD_ERROR, error: "An error occurred in the upload process.", 
                    transferred: snapshot.bytesTransferred, total: snapshot.totalBytes});
            }, () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                console.log(uploadTask.snapshot);
                uploadTask.snapshot.ref.getDownloadURL().then(dlUrl => {
                    console.log("||||||||||||||| file uploaded successfully |||||||||||||||");
                    console.log(dlUrl);

                    filesRef.push({name: formValues.name, description: formValues.description, ref: 'helpFiles/' + file.name}).then(() => {
                        dispatch({type: C.RECEIVE_NEW_FILE_RESPONSE});
                        console.log("finished uploading file");
                    }).catch((error) => {
                        dispatch({type: C.RECEIVE_FILE_UPLOAD_ERROR, error: "An error occurred saving file."});
                        console.log("error")
                    })
                });
                
        });

    }
}