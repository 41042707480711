import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit, reset } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import Paper from 'material-ui/Paper';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ImageEdit from 'material-ui/svg-icons/image/edit';
import FileCloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import FieldFileInput from './FieldFileInput';

const LogoField = (props) => {
    const { imgSrc = "", disabled, onPreviewChange, previewSrc } = props;
    
    const body = (
        <div>
            <img alt="logo"
                src={imgSrc}
                style={{ height: "115px", width: "auto" }}/>

            <Field
                name="logoFile"
                onPreviewChange={onPreviewChange}
                previewSrc={previewSrc}
                component={FieldFileInput}
                label="Choose a Logo"
                disabled={disabled}
                accept="image/*" />
        </div>
    );

    return makeFieldPanel("logo", body);
};

const PreambleField = (props) => {
    const { disabled } = props;
    const name = "preamble";

    const body = (
        <Field 
            name={name}
            disabled={disabled}
            component={TextField}
            fullWidth={true}
            multiLine={true}
            rows={10}
            hintText='This is the feedback report "preamble" (i.e. Thank you for generously volunteering your time and...) *You can use Markdown'
            floatingLabelText={name} />
    );

    return makeFieldPanel(name, body);
};

const ActionButtons = (props) => {
    const { onCancelClick, onPrimaryClick, showEditMode } = props;

    const primaryButton = (
        <FloatingActionButton onClick={onPrimaryClick}>
            {showEditMode ? <FileCloudUpload/> : <ImageEdit/>}
        </FloatingActionButton>
    );

    const cancelButton = showEditMode ? (
        <FloatingActionButton onClick={onCancelClick} mini={true}>
            <NavigationClose/>
        </FloatingActionButton>
    ) : null;

    return (
        <div className="feedbackActionButtonsContainer">
            {cancelButton}
            {primaryButton}
        </div>
    );
}

const makeFieldPanel = (label, body) => {
    return (
        <div className="panel" style={{ marginBottom: "40px" }}>
            <div className="panel-body">
                <label className="panel-title" htmlFor={label}>{label}</label>

                <div className="dash-section-body">
                    {body}
                </div>
            </div>
        </div>
    );
};

class FeedbackForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { 
            disabledForm: true,
            logoFile: null
        };
        
        this.handleEditToggle = this.handleEditToggle.bind(this);
        this.handleLogoPreview = this.handleLogoPreview.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }
    
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
    }

    handleEditToggle() {
        if (this.state.disabledForm) {
            this.setState({ disabledForm: false });
        } else {
            this.setState({ 
                disabledForm: true,
                logoFile: null
            });
            this.props.onSubmitForm();
        }
    }

    handleReset() {
        this.setState({ 
            disabledForm: true,
            logoFile: null
        });
        this.props.onResetForm();
    }

    handleLogoPreview(file) {
        this.setState({ logoFile: file });
    }

    render() {
        const { config, className, handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <Paper className="spacy-paper">
                <form
                    className={className}
                    onSubmit={handleSubmit}>

                    <LogoField
                        imgSrc={config ? config.logo : ""}
                        onPreviewChange={this.handleLogoPreview}
                        previewSrc={this.state.logoFile}
                        disabled={this.state.disabledForm}/>
                    <PreambleField
                        disabled={this.state.disabledForm}/>
                    <ActionButtons 
                        onPrimaryClick={this.handleEditToggle} 
                        onCancelClick={this.handleReset} 
                        showEditMode={!this.state.disabledForm}/>

                </form>
            </Paper>
        );
    }
}

// Decorate the form component
FeedbackForm = reduxForm({
  form: 'feedback', // a unique name for this form
  enableReinitialize: true
})(FeedbackForm);

const mapStateToProps = (appState) => {
    return { 
        initialValues: Object.assign({}, {
            logoFile: null
        }, appState.feedback.content )
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmitForm: () => { 
            dispatch(submit("feedback"));
        },
        onResetForm: () => {
            dispatch(reset("feedback"));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);