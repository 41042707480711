import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  values: {},
  errors: []
};

export default (state = initialState, action) => {
  switch (action.type) {
      case C.FORM_UPDATE_VALUE:
        return Object.assign({}, state, {
          values: Object.assign({}, state.values, {
            [action.name]: action.value
          })
        });

      case C.FORM_RESET:
        return initialState;

      case C.DISPLAY_ERROR:
        return Object.assign({}, state, {
            errors: state.errors.concat(action.error)
        })

      case C.DISMISS_ERROR:
        return Object.assign({}, state, {
            errors: state.errors.filter((val ,index) => {
                return index !== action.num;
            })
        })

      case C.DISMISS_ALL_ERRORS:
        return Object.assign({}, state, {
            errors: []
        })


      default:
        return state;
  }
}