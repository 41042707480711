import React, { Component } from 'react';
import Dimensions from 'react-dimensions';
// import { Legend, PieChart } from 'react-easy-chart';
//import actions from '../../actions'

class CollisionCountPieChart extends Component {

    constructor() {
        super();
        this.state = {
            showToolTip: false,
            top: 0,
            left: 0,
            value: 0, 
            key: ""
        }
    } 

    mouseOverHandler(d, e) {
        console.log(d, e);
        this.setState({
            showToolTip: true,
            top: e.layerY - 30,
            left: e.layerX + 30,
            value: d.value,
            key: d.data.key});
    }

    mouseMoveHandler(e) {
        if (this.state.showToolTip) {
            this.setState({top: e.layerY - 30, left: e.layerX + 30});
        }
    }

    mouseOutHandler() {
        this.setState({showToolTip: false});
    }



  render() {

    return  <div>
                <div className="chart-title">Collision Counts</div>
                <div style={{ textAlign: "center", position: 'relative'}}>
                    <div style={{
                        position: "absolute",
                        top: this.state.top + "px",
                        left: this.state.left + "px",
                        display: this.state.showToolTip ? "block" : "none",
                        backgroundColor: "#FFF",
                        border: "2px solid #000",
                        padding: "5px",
                        borderRadius: "10px"
                    }}>
                        { this.state.value + " customer" + (this.state.value == 1 ? "" : "s") + " had " + this.state.key + " collision" + (this.state.key == "1" ? "": "s") }
                    </div>
                    {
                    // <PieChart
                    //     mouseOverHandler={this.mouseOverHandler.bind(this)}
                    //     mouseOutHandler={this.mouseOutHandler.bind(this)}
                    //     mouseMoveHandler={this.mouseMoveHandler.bind(this)}
                    //     size={300}
                    //     data={this.props.data} />
                    }
                </div>
                <div style={{ textAlign: "center"}}>
                    {
                    // <Legend
                    //     horizontal
                    //     data={this.props.data}
                    //     dataId='key' />
                    }
                </div>
            </div>
  }
}


export default Dimensions()(CollisionCountPieChart)
