import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import OrgDrawer from './components/orgdrawer';

const startListening = hlp.createOneShot(function () {
    //TODO -- I think Firebase handles this
    // but we should make sure this only happens once
    store.dispatch( actions.startListeningToUsers() );
    store.dispatch( actions.startListeningToGroups() );
    store.dispatch( actions.startListeningToOrgs() );
});

class ManageOrg extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const oid = this.props.params.oid;
        const loaded = p.orgs.hasReceivedOrgData;

        return (
                <div>
                    <div className="panel">
                        <div className="panel-body">
                        	{ loaded ?  <OrgDrawer oid={oid}/> : <h3>Nothing here. Move along.</h3>}
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        users: appState.users,
        orgs: appState.orgs,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {

    }
}

ManageOrg.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageOrg);