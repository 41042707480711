import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedData: false,
  // init: false,
  submittingnew: false,
  data: {},
  columns: {
      customerId: true,
      startDate: true,
      location: true,
      department: true,
      completeDuration: true,
      completed: true,
      riskMessage: true
  },
  headerNames: {},
  columnGroups: {},
  colSizes: {},
  start: null,
  end: null,
  orgs: [],
  org: '',
  aid: "",
  scoringMethod: '',
  dimensionName: '',
  dimensionValue: '',
  customerIds: [],
  customColumns: [],
  showAllOrgs: false,
  limit: 0,
  error: "",
  showExcluded: false
  // init: {},
  // states: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_DASH_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedData: true,
          submittingnew: false,
          error: ""
        });

    case C.RECEIVE_DASH_ERROR:
        return Object.assign({}, state, {
          error: action.error,
          hasReceivedData: true,
          submittingnew: false
        });

    case C.SET_COLUMNS:
        return Object.assign({}, state, {
          columns: action.columns
        });

    case C.SET_HEADER_NAMES:
        return Object.assign({}, state, {
          headerNames: action.headerNames,
          columnGroups: action.columnGroups
        });

    case C.SET_COL_SIZES:
        return Object.assign({}, state, {
          colSizes: action.colSizes
        });

    case C.SET_TIMESPAN:
        return Object.assign({}, state, {
          start: action.start,
          end: action.end
        });

    case C.SET_ORGS: 
        return Object.assign({}, state, {
          orgs: action.orgs
        });

    case C.AWAIT_NEW_DASH_DATA:
        return Object.assign({},state,{
            submittingnew: true,
            // init: true
        });

    case C.CLEAR_WAIT:
      return Object.assign({},state,{
            submittingnew: false
        });

    case C.SET_QRY_AID:
        return Object.assign({}, state, {
          aid: action.aid,
          dimensionName: '',
          dimensionValue: ''
        });

    case C.SET_QRY_SCORING_METHOD:
        return Object.assign({}, state, {
          scoringMethod: action.method
        });

    case C.SET_QRY_LIMIT:
        return Object.assign({}, state, {
          limit: action.limit
        });

    case C.SET_QRY_DIMENSIONS:
        return Object.assign({}, state, {
          dimensionName: action.dimensionName,
          dimensionValue: action.dimensionValue,
          aid: ''
        });

    case C.SET_CUSTOMER_IDS:
        return Object.assign({}, state, {
          customerIds: action.ids
        });

    case C.SET_QRY_CUSTOM_COLS:
        return Object.assign({}, state, {
          customColumns: action.addCols
        });

    case C.SET_QRY_SHOW_ALL_ORGS:
        return Object.assign({}, state, {
          showAllOrgs: action.showAllOrgs
        });

    case C.SET_DASH_ORG:
        console.log('setting dash org', action.org);
        return Object.assign({}, state, {
          org: action.org
        });

    case C.SET_SHOW_EXCLUDED:
        return Object.assign({}, state, {
          showExcluded: true
        });

    case C.SET_HIDE_EXCLUDED:
        return Object.assign({}, state, {
          showExcluded: false
        });

      default:
        return state;
  }
}