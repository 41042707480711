import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  simulating: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.START_SIMULATING:
        return Object.assign({}, state, {
          simulating: true
        });

    case C.END_SIMULATING:
        return Object.assign({},state,{
            simulating: false
        });

      default:
        return state;
  }
}