import { C } from '../constants';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
const functions = firebase.functions();

const orgsRef = C.APP.database().ref('organizations');
const feedbackRef = C.APP.database().ref('feedback');
const storageRef = firebase.storage().ref();
const aRef = C.APP.database().ref('assessments');

export const startListeningToFeedbackContent = (oid) => {
    return (dispatch, getState) => {
        orgsRef.child(oid).on('value', (snap) => {
            const dispatchFeedback = (content) => dispatch({ type: C.RECEIVE_FEEDBACK_CONTENT, content });

            const org = snap.val();

            if (org.config && org.config.feedback) {
                dispatchFeedback(org.config.feedback);
            } else {
                feedbackRef.child('default').on('value', (snap) => {
                    const defaultContent = snap.val();

                    if (defaultContent) {
                        dispatchFeedback(defaultContent);
                    } else {
                        console.error("No default feedback content is available to display.");
                    }
                });
            }
        });
    }
}

export const loadPDF = function (aid, version2 = false) {

    return function (dispatch, getState) {
        console.log("LOAD PDF FOR " + aid);
        dispatch({type: C.AWAIT_PDF_URL, aid});
        aRef.child(aid + '/feedbackData').once('value').then(snap => {
            let fd = snap.val();
            if (!fd) {
                dispatch({type: C.SET_PDF_NOT_READY, aid});
            } else {
                let pdfFile = storageRef.child('pdfFeedback/' + aid + '.pdf');
                pdfFile.getDownloadURL().then((url) => {
                    //url exists
                    dispatch({type: C.SET_PDF_DOWNLOAD_URL, aid, url});
                }).catch((error) => {
                    switch (error.code) {
                        case 'storage/object-not-found':
                            dispatch({type: C.PDF_GENERATING, aid});
                            let generateAndStorePdf = functions.httpsCallable('generateAndStorePdf');
                            generateAndStorePdf({aid, version2}).then((result) => {
                                prodConsole.log("Success - generated and saved PDF for " + aid, result);
                                pdfFile = storageRef.child('pdfFeedback/' + aid + '.pdf');
                                pdfFile.getDownloadURL().then(url => {
                                    //url exists
                                    dispatch({type: C.SET_PDF_DOWNLOAD_URL, aid, url});
                                }).catch(error => {
                                    prodConsole.log("Generated PDF but still can't get dlUrl for " + aid, error);
                                    dispatch({type: C.PDF_GIVE_UP, aid});
                                })
                                
                            }).catch((err) => {
                                prodConsole.log("Error generating and saving PDF for " + aid, err);
                                dispatch({type: C.PDF_GIVE_UP, aid});
                            });
                            break;
                        default: 
                            prodConsole.log("Error getting download URL for " + aid, err);
                            dispatch({type: C.PDF_GIVE_UP, aid});
                      }
                })
            }
        })
                
    }
}


export const updateFeedbackContent = (orgId, values) => {
    const { preamble, logoFile } = values;

    return (dispatch, getState) => {
        const storageRef = firebase.storage().ref(`/feedback/${orgId}/`);
        const dbConfigRef = C.APP.database().ref(`/organizations/${orgId}/config/feedback`);

        const uploadPreamble = (text) => dbConfigRef.child('/preamble').set(text);
        const uploadLogo = (file) => {
            if (!file) return Promise.resolve();
            
            return storageRef.child('/logos').put(file)
                .then((storageSnap) => storageSnap.ref.getDownloadURL())
                .then((url) => {
                    return dbConfigRef.child('/logo').set(url);
                });
        };

        return Promise.all([
            uploadLogo(logoFile),
            uploadPreamble(preamble)
        ]).catch((err) => {
            console.error("updateFeedbackContent failed: ", err);
        });
    }
}

export const logApology = (aid, msg) => {
    C.APP.database().ref('assessments/' + aid + '/apologyRendered').set(msg);
}