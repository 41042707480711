
import _ from 'lodash';

import Paper from 'material-ui/Paper';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import store from '../../../store';
import ModuleJsonForm from './ModuleJsonForm';
import { Link } from 'react-router';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import IconButton from 'material-ui/IconButton';


const style = {
  margin: 20,
  position: "relative"
};

const errorStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative",
    color: "red",
    fontWeight: "bold"
}

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

/*
    allScenarios: {},
    allScenariosLoading: false,
    jsonUploading: false,
    jsonErrors: []

    */

class ModuleCreator extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToModules() );
    }

    render () {
        const p = this.props;


        const bForm = (<ModuleJsonForm  onSubmit={(function (e) {e.preventDefault(); this.props.uploadModule(); }).bind(this) } />);
        const wait = <div>Validating and Uploading...</div>

        const add = (
                    <Paper
                        style={style}
                        zDepth={1}
                        key={999}
                    >

                        <div style={innerStyle} >{ this.props.cpt.moduleUploading ? wait : bForm}</div>
                    </Paper>
                     
        )

        
        const displayErrors = function () {
            let errors = [];
            let idx = 0;
            p.cpt.moduleJsonErrors.forEach(er => {
                errors.push(<div key={idx}>{er}</div>)
                idx++;
            })
            if (errors.length) {
                return <Paper className="asset-upload-error" style={style} zDepth={1}>
                    <div style={errorStyle} >{errors}</div>
                </Paper>
            }
            return null;  

        }




        return (
            <div>
                { displayErrors() }
                { add }
                
                    {
                        <div>
                            {_.map(this.props.cpt.allModules, (mod, key) => {
                                return  <Paper key={key} style={{margin: 20, padding: 20}}>
                                    <div className='row' key={key}>
                                    
                                        <div style={{paddingBottom:5}} className='col-sm-3'>title: {mod.title}</div>
                                        <div style={{paddingBottom:5}} className='col-sm-2'>scenarios: {mod.scenarios.length}</div>
                                        <div style={{paddingBottom:5}} className='col-sm-2'>
                                            <Link to={ "/cpt/" + key }>
                                                view
                                            </Link>
                                        </div>
                                        <div className='col-sm-2'>{key}</div>
                                        <div className='col-sm-2'>
                                            <IconButton
                                                tooltip="Delete this module"
                                                onClick={(function (e) { 
                                                        let userConfirms = window.confirm("Are you sure you want to delete Module: " + mod.title);
                                                        if (userConfirms) { p.deleteModule(key); }
                                                    }).bind(this)}>
                                                    <DeleteIcon />
                                                
                                            </IconButton> 
                                            

                                        </div>
                                    </div> </Paper>
                            }).reverse()}
                        </div>
                    }
                
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        cpt: appState.cpt,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadModule: function () { dispatch( actions.uploadModule() )},
        deleteModule: function (key) { dispatch( actions.deleteModule(key) )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ModuleCreator);
