import { C } from '../../constants';

const initialState = {
    empty : true
}

export default (state = initialState, action) => {
    let entry = {};
    switch (action.type) {
        case C.RECEIVE_INTAKE_RESULT_DATA:
            entry[action.key] = entry[action.key] || {};
            entry[action.key].loading = false;
            entry[action.key].data = action.data;
            return Object.assign({}, state, entry);

        case C.AWAIT_NEW_INTAKE_RESULT_DATA:
            entry[action.key] = {};
            entry[action.key].loading = true;
            entry.empty = false;
            return Object.assign({}, state, entry);

        case C.RECEIVE_ALL_INTAKE_DATA:
            return Object.assign({}, state, {allIntakeData : action.data || {}});

        case C.RECEIVE_ALL_OPEN_INTAKE_DATA:
            return Object.assign({}, state, {openIntakeData : action.data || {}});

        default:
            return state;
  }
}