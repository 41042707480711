import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {C} from '../constants';
import actions from '../actions';
import store from '../store';
import Dialog from 'material-ui/Dialog';
import DashGrid from './components/dashGrid';
import DashWrapper from './components/dashWrapper';
import SetQueryTimespanForm from './components/setquerytimespanform';
import queryString from 'query-string';


class DefaultDash extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            timespanDialogOpen : false
        }
    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    componentWillMount () {
        // let init = this.props.dash.init;
        this.props.setTitle('Intelligence Dashboard');
        store.dispatch( actions.startListeningToOrgs() );
        const qs = queryString.parse(this.props.location.search);
        if (qs.showExcludedIds) {
            store.dispatch({ type: C.SET_SHOW_EXCLUDED});
        }
        if (qs.hideExcludedIds) {
            store.dispatch({ type: C.SET_HIDE_EXCLUDED});
        }
    }


    handleTimespanDialogOpen() {
        this.setState({timespanDialogOpen : true})
    }

    handleTimespanDialogClose() {
        this.setState({timespanDialogOpen : false})
    }

    handleCellClick(cellData) {

        let thisAid = cellData.data.id;
        let thisIndId = cellData.data.customerId;
        let thisInProgress = cellData.data.hasOwnProperty('inProgress') ?  cellData.data.inProgress : 'NO';

        console.log(cellData);
        console.log(thisInProgress);
        // if (['location', 'department'].includes(cellData.colDef.field)) {
        //     // this won't work. . . need a better way
        //     if (cellData.value) {
        //         let dimension = cellData.colDef.field == 'department' ? 'departmentId' : cellData.colDef.field;
        //         let value = cellData.colDef.field == 'department' ? cellData.data.departmentId : cellData.value;
        //         this.props.router.push('/dash/a/' + dimension + '/' + value);
        //         return;
        //     }
            
        // }
        if ((this.props.auth.featureFlags && this.props.auth.featureFlags.LIMIT_RESULTS) || thisInProgress == 'YES') {
            //nada
        } else {
            if (thisAid && thisIndId) {
                if (this.props.auth.featureFlags && this.props.auth.featureFlags.EMAIL_REPORT_V3) {
                    this.props.router.push('/download/pdf/' + thisAid);
                } else {
                    this.props.router.push('/feedback/report/' + thisAid);
                }
                
            }
        }

        
    }

    handleOrgChange(event) {
        console.log(event.target.value);
        this.props.changeDashOrg(event.target.value);
    }

    handleClearTimespan(event) {
        this.props.clearTimespan();
    }

    render () {
        
        const p = this.props;
        //for all views (although, probably don't need orgs for all views)
        
        const feats = this.props.auth.featureFlags;
        const rtd = feats && feats.REAL_TIME_DASHBOARD;
        let isLoading = (p.dash.submittingnew || !p.dash.hasReceivedData );
        if (rtd) {
            isLoading = isLoading && p.orgs.hasReceivedOrgData
        }
        let content = <div></div>

        // TODO process columndefs to make data fields sortable correctly
        // return assessment ID somehow -- even if not in column def

        let orgSelect = null;
        // console.log('%c Oh my heavens! ', 'background: #222; color: #bada55');
        // console.log("LOOK AT ME", rtd, p.auth.isParent, p.auth.childOrgs);
        if (p.orgs.hasReceivedOrgData && rtd && p.auth.isParent) {
            // console.log('%c creating drop down ', 'background: #222; color: #bada55', p.orgs.data, p.auth.oid);
            orgSelect =     <select onChange={this.handleOrgChange.bind(this)} key="orgslct" value={p.dash.org || p.auth.oid} id="orgs">
                                <option value="ALL">View All</option>
                                <option value={p.auth.oid}>{p.orgs.data[p.auth.oid].name}</option>
                                { _.map(p.auth.childOrgs, (tru, oid) => {
                                    return <option key={oid} value={oid}>{p.orgs.data[oid].name}</option>
                                }) }
                                
                            </select>
        }

        const dashHeaderItems = [
            orgSelect,
            <button key="uno" onClick={this.handleTimespanDialogOpen.bind(this)}>
                Set Timespan
            </button>,
            <button disabled={(!p.dash.start) && (!p.dash.end)} key="dos" onClick={this.handleClearTimespan.bind(this)}>Clear Timespan</button>,
            <Dialog
                title="Set Query Timespan"
                key="tres"
                modal={false}
                open={this.state.timespanDialogOpen}
                onRequestClose={this.handleTimespanDialogClose.bind(this)}>
                    <SetQueryTimespanForm
                        dash={p.dash}
                        onSubmit={(function (e) {e.preventDefault(); p.setQueryTimespan(); this.handleTimespanDialogClose(); }).bind(this) } />
             </Dialog>
        ]

        if (p.dash.hasReceivedData) {
            content = (
                <DashGrid
                    headerItems={dashHeaderItems}
                    loading={isLoading}
                    onCellClicked = {(function(cellData) {this.handleCellClick(cellData)}).bind(this)} />
            );
            
        }
        

        return (

            <DashWrapper {...this.props}>
                <div className='dash-box'>
                    {isLoading ? <div className="dash-curtain"></div> : null}
                    <div>
                        { isLoading ? (
                               <div className="loading">Loading&#8230;</div>
                            ) : null }
                        {content}
                    </div>
                </div>
            </DashWrapper>
                
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        dash: appState.dash,
        auth: appState.auth,
        orgs: appState.orgs,
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setQueryTimespan : function () { dispatch( actions.setQueryTimespan() ) },
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) },
        changeDashOrg : function (oid) { dispatch( actions.changeDashOrg(oid) ) },
        clearTimespan : function () { dispatch( actions.clearTimespan() ) }
    }
}

const RoutableDash = withRouter(DefaultDash);


export default connect(mapStateToProps, mapDispatchToProps)(RoutableDash);
