import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedData: false,
  submittingnew: false,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_INTAKE_SURVEY_OPTIONS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedData: true
        });

    case C.AWAIT_NEW_INTAKE_SURVEY_OPTION_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_INTAKE_SURVEY_OPTION_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        });

      default:
        return state;
  }
}