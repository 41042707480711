import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import moment from 'moment';
import { withRouter } from 'react-router';

import Warning from 'material-ui/svg-icons/alert/warning';


import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import RequiredVideoBox from "./components/requiredvideoboxV2";

import ReadyAssessLogo from '../assets/images/Ready-Assess-Logo.png';
import DxdLogo from '../assets/images/DiagnosticDrivingLogo.png';
import Title from '../assets/images/CoverPage-Graphic.png';
import BellCurve from '../assets/images/bellCurveMask.png';
import Arrow from '../assets/images/curveArrow.png';
import ErrCrashN from '../assets/images/errors/crash_n.png';
import ErrCrashP from '../assets/images/errors/crash_p.png';
import ErrRedN from '../assets/images/errors/redlight_n.png';
import ErrRedP from '../assets/images/errors/redlight_p.png';
import ErrStopN from '../assets/images/errors/stop_n.png';
import ErrStopP from '../assets/images/errors/stop_p.png';
import ErrLostN from '../assets/images/errors/lost_n.png';
import ErrLostP from '../assets/images/errors/lost_p.png';

import KeepReading from '../assets/images/keepReading.png';


import AggressiveSpeeding from '../assets/images/AggressiveSpeeding.png';
import DrivingOffCourse from '../assets/images/DrivingOffCourse.png';
import DrivingStraightTooFast from '../assets/images/DrivingStraightTooFast.png';
import DrivingThroughRedlight from '../assets/images/DrivingThroughRedlight.png';
import DrivingThruStopSign from '../assets/images/DrivingThruStopSign.png';
import DrivingTooSlow from '../assets/images/DrivingTooSlow.png';
import ExcessiveLaneDeviation from '../assets/images/ExcessiveLaneDeviation.png';
import ExcessiveWeavingLane from '../assets/images/ExcessiveWeavingLane.png';
import FollowingVehicles from '../assets/images/FollowingVehicles.png';
import HardBraking from '../assets/images/HardBraking.png';
import JerkyVehicleControl from '../assets/images/JerkyVehicleControl.png';
import ManagingIntersections from '../assets/images/ManagingIntersections.png';
import NegotiatingCurves from '../assets/images/NegotiatingCurves.png';
import NotFollowingInstructions from '../assets/images/NotFollowingInstructions.png';
import NotPullingOverAmbulance from '../assets/images/NotPullingOverAmbulance.png';
import NotStoppingForBus from '../assets/images/NotStoppingForBus.png';
import NotUsingTurnSignals from '../assets/images/NotUsingTurnSignals.png';
import NotYielding from '../assets/images/NotYielding.png';
import NotYieldingPedestrians from '../assets/images/NotYieldingPedestrians.png';
import OtherAreasofDriving from '../assets/images/OtherAreasofDriving.png';
import OverAdjustingSteering from '../assets/images/OverAdjustingSteering.png';
import OvertakingVehicle from '../assets/images/OvertakingVehicle.png';
import ScanForHazards from '../assets/images/ScanForHazards.png';
import SchoolZoneSpeedLimit from '../assets/images/SchoolZoneSpeedLimit.png';
import SpeedingConstructionZone from '../assets/images/SpeedingConstructionZone.png';
import UnsafeFollowing from '../assets/images/UnsafeFollowing.png';
import CrashBang from '../assets/images/1f4a5.png';
import Worried from '../assets/images/1f6a9.png';

import NJMLogo from '../assets/images/CHoP/NJM-Insurance-Group_RGB.png';
import CHoPLogo from '../assets/images/CHoP/CHOP_HORIZ_RGB.png';


import Apology from './apology';

import {red500, greenA200} from 'material-ui/styles/colors';
import _ from 'lodash';
import wrapper from './wrapper';

// const ConditionalWrapper = ({ condition, wrapper, children }) => 
//   condition ? wrapper(children) : children;

class ConditionalWrapper extends Component {
    render () {
        const p = this.props;
        if (p.condition) {
            // console.log("CONDITION IS TRUE IN CONDITIONAL WRAPPER")
            let output = p.wrapper(p.children);
            // console.log("DID WE GET HERE THEN?");
            
            return  output;
        } else {
            if (p.otherwise) {
                return p.otherwise(p.children);
            }
            return  p.children
        }
    }
}

class WarningBadge extends Component {
    render () {
        if (this.props.show) {
            return <div className="badge_box"><div className="badge"><Warning style={{width: 36, height: 36}} color="#B02C19" className="danger-icon" /></div></div>
        }
        return null;
    }
}

const TITLE_PAGE_LOGOS = {
    '-MWNAja4yGMW2rkzBXuW' : CHoPLogo,
    '-MUyDFYzNdrR2iUw5Auz': CHoPLogo,
    '-Mvu8VYmhIlLdXyT9_fs': CHoPLogo,
    '-LRmsgEgBT3xKmdWFuwq': CHoPLogo,
    '-LeYGPgCRXXPlNy7hjTr': CHoPLogo
}

const PAGE_3_LOGOS = {
    '-MWNAja4yGMW2rkzBXuW' : NJMLogo,
    '-MUyDFYzNdrR2iUw5Auz': NJMLogo,
    '-Mvu8VYmhIlLdXyT9_fs' : NJMLogo,
    '-LRmsgEgBT3xKmdWFuwq': NJMLogo,
    '-LeYGPgCRXXPlNy7hjTr': NJMLogo
}

const NJM_COPY = "This personalized feedback report was made possible by a generous grant from NJM Insurance Group, a leading auto insurer and teen driver safety advocate in the Mid-Atlantic Region.";

const PAGE_3_CUSTOM_COPY = {
    '-MWNAja4yGMW2rkzBXuW' : NJM_COPY,
    '-MUyDFYzNdrR2iUw5Auz': NJM_COPY,
    '-Mvu8VYmhIlLdXyT9_fs': NJM_COPY,
    '-LRmsgEgBT3xKmdWFuwq': NJM_COPY,
    '-LeYGPgCRXXPlNy7hjTr': NJM_COPY
}

const SCENARIOS = [
    {
        id: 'following', 
        desc: 'Following other vehicles (rear-end)', 
        title: 'Following Vehicles', 
        example: 'Rear-end crash',
        suggestions : [
            'Maintaining a safe following distance',
            'Anticipating vehicle in front of you stopping suddenly',
            'Practicing safe speed management'
        ]
    },
    {id: 'curves', desc: 'Negotiating curves (driving off the road)'},
    {id: 'straightfast', desc: 'Driving straight & too fast for conditions (driving off the road)'},
    {id: 'intersections', desc: 'Managing intersections with traffic (turn & timing-related collisions)'},
    {id: 'other', desc: 'Other areas of the drive (beyond the four scenarios)'}
]


function getFirstName(iData) {
    let ir = iData.responses;
    return ir && (ir['first_name>>first_name'] || ir['Name>>first_name'] || ir['first_name_last_name>>first_name'] || ir['firstName_lastName>>first_name']);
}

function getLastName(iData) {
    let ir = iData.responses;
    //    lastName : "getAnswerMulti(intake, ['last_name_lowercase>>last_name_lowercase', 'last_name>>last_name', 'Name>>last_name_initial', 'first_name_last_name>>last_name', 'firstName_lastName>>last_name']) as lastName",
    return ir && (ir['last_name_lowercase>>last_name_lowercase'] || ir['last_name>>last_name'] || ir['Name>>last_name_initial'] || ir['first_name_last_name>>last_name'] || ir['firstName_lastName>>last_name']);
}

const MONTHS = 'January,February,March,April,May,June,July,August,September,October,November,December'.split(',');

function formatStart(st) {
    if (st) {
        let start = new Date(st);
        return MONTHS[start.getMonth()] + ' ' + start.getDate() + ', ' + start.getFullYear(); 
    }
    return '';
}

function getAgeCategoricalString(age) {
    if (age == -1000) {
        return "Under 18";
    }
    if (age == -2000) {
        return "18 or over";
    }
    return "";
}

function isAgeCategorical(age) {
    return age == -1000 || age == -2000;
}

function getAge(aData, iData) {

    // Ohio2020-26>>Ohio2020-26

    let born = iData.responses && iData.responses['Ohio2020-26>>Ohio2020-26'];
    // console.log('getAge', born, aData.start);
    if (born && aData.start) {
        let birthdate = moment(parseInt(born));
        let testDate = moment(aData.start);
        return testDate.diff(birthdate, 'years');
    }

    let ageCat = iData.responses && iData.responses['age_categorical_AGECAT>>age_categorical_AGECAT'];
    if (ageCat) {
        if (ageCat == 'va1') {
            return -1000;
        }
        if (ageCat == 'va2') {
            return -2000;
        }
    }

    return -1;

}

function getStatus(iData) {

    let licenseStatus = iData.responses && iData.responses['license_status>>license_status'];

    if (licenseStatus) {
        return {'_1' : 'Valid License', '_2' : 'Suspended/Revoked License', '_3' : 'Learner Permit', '_4' : 'None'}[licenseStatus];
    } else {

        if (iData.survey) {
            switch(iData.survey) {
                case '-MXcGNqZVAfyT7DIuM2R':
                case '-MuMR-4O4USzdSma6oOc':
                case '-Mo5f4YeUi3NsiCCmaGc':
                    return 'Valid License';

                case '-MXcI4-ls5zC7LMHuWA-':
                case '-MuMY0_7E_WF06tos1uh':
                case '-Mo5gdfLT-ja6OU-fki2':
                    return 'Suspended/Revoked License';

                case '-MXcJQBceriKZfy401yB':
                case '-MuMZAKe0CUUJU0iE1MI':
                case '-Mo5hxWBMgo9VzfQBeHX':
                    return 'Learner Permit';

                case '-MXcJoqVXbLrQB3zNEwX':
                case '-MuM_0GA1ARyR3JRZroI':
                case '-Mo5k2JZzjRq-dVx8eQm':
                    return 'None';

                default:
                    return '';
            }
        }

    }

    
    return ''
}

function getOrgName(aData, orgs) {
    return aData.organization && orgs.data && orgs.data[aData.organization] && orgs.data[aData.organization].name;
}

function getLoc(aData, orgs) {
    let org = getOrgName(aData, orgs);
    return (org ? org : '') + (aData.location ? (org ? ': ' : '') + aData.location : '');
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function collectFeedbackData(aData, iData, orgs) {

    aData.feedbackData.infractions_va0 = aData.feedbackData.infractions_va0 || [];
    aData.feedbackData.infractions_va1 = aData.feedbackData.infractions_va1 || [];
    aData.feedbackData.infractions_va2 = aData.feedbackData.infractions_va2 || [];

    let first = getFirstName(iData);
    let last = getLastName(iData);

    return {
        name : capitalize(first) + (last ? " " + capitalize(last) : ""),
        date: formatStart(aData.start),
        age: getAge(aData, iData),
        status: hlp.determineLicenseStatus(aData, null, '', iData),
        loc: getLoc(aData, orgs),
        results: aData.feedbackData
    }
}

class Infraction extends Component {
    render () {
        return  <div>
                    <div className='infraction-v2-box'>
                        
                        <div className={'common-crash-title-bar cc-ng'}>
                            <span className='cc-title'>{this.props.desc}</span>
                        </div>
                        
                        <div className='infraction-v2-content-box inf-flex'>
                            <div className='infraction-v2-icon-box inf-flex-l'>
                                <div className='invisible-infraction-icon'>
                                    <img className="dtl-img-v3 spact" alt="" src={this.props.icon} />
                                </div>
                                <div className='vertical-center-icon'>
                                    <img className="dtl-img-v3 spact" alt="" src={this.props.icon} />
                                </div>
                            </div>
                            <div className='infraction-v2-content inf-flex-r'>
                                <div className='invisible-infraction-content'>
                                    {
                                        this.props.raw ? (this.props.tip) : <ul>

                                        {_.map(this.props.tip, (val, index) => {
                                            return <li key={"tip_" + index}>{val}</li>
                                        })}

                                    </ul>
                                    }
                                </div>
                                <div className='vertical-center-infraction-statement'>
                                    {
                                        this.props.raw ? (this.props.tip) : <ul>

                                        {_.map(this.props.tip, (val, index) => {
                                            return <li key={"tip_" + index}>{val}</li>
                                        })}

                                    </ul>
                                    }
                                </div>
                            </div>
                            <div className='cc-space'></div>
                        </div>
                    </div>


                    {
                        false ?
                        <div className="row">
                            <div className={'col-sm-12 common-crash-title-bar cc-ng'}>
                                <span className='cc-title'>{this.props.desc}</span>
                            </div>
                            <div className='col-sm-12'>
                                <div className='dtl-tbl-cell thngs sdthngs infraction-row-box'>
                                    <div className='infrabox infraction-row'>
                                        <img className="dtl-img-v2 spact infrpos" alt="" src={this.props.icon} />
                                        <div className='infracntnt'>
                                            {
                                                this.props.raw ? (this.props.tip) : <ul>

                                                {_.map(this.props.tip, (val, index) => {
                                                    return <li key={"tip_" + index}>{val}</li>
                                                })}

                                            </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 cc-space'></div>
                        </div>
                        :
                        null
                    }


                    
                </div>
    }
}

const VIDEO_MAP = {
    rightOfWay : {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=gCla_EVlEDE">Right of Way</a>, id: "gCla_EVlEDE", label: "Right of Way", seconds: 68},
    controlledIntersections : {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=8JWc4agC-7w">Controlled Intersections</a>, id: "8JWc4agC-7w", label: "Controlled Intersections", seconds: 169},
    managingIntersections : {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=dyI33CV_hf4">Managing Intersections</a>, id: "dyI33CV_hf4", label: "Managing Intersections", seconds: 203},
    scanning: {anchor: <a target="_blank" href="https://www.youtube.com/playlist?list=PLamT53otBDn53210FmuY_aG0FgEvYeT2b">All About Scanning, Identifying, and Reacting to Dangerous Situations</a>, id: "xMutekpY0cs", label: "Scanning", title: "All About Scanning, Identifying, and Reacting to Dangerous Situations", seconds: 263},
    spaceCushion1: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=ye-zGn9agys">Space Cushion</a>, id: "ye-zGn9agys", label: "Space Cushion", seconds: 164},
    spaceCushion2: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=T2wOTgz_cJk">Space Cushion on Four Sides</a>, id: "T2wOTgz_cJk", label: "Space Cushion on Four Sides", seconds: 146},
    distanceEstimation: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=DPYoO46GGOk">Distance Estimation</a>, id: "DPYoO46GGOk", label: "Distance Estimation", seconds: 146},
    speedMgmt: {anchor: <a target="_blank" href="https://www.youtube.com/playlist?list=PLamT53otBDn7od1y1MHXLyc3mm2YDvdhS">All About Speed Management</a>, id: "9Z89ZMZw44U", label: "Speed Management", title: "All About Speed Management", seconds: 91},
    crosswalks: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=ldxIRUBxCjk">Intersections with Crosswalks</a>, id: "ldxIRUBxCjk", label: "Crosswalks", title: "Intersections with Crosswalks", seconds: 192},
    laneChanging: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=1j4b55v4KJ8">Lane Changing</a>, id: "1j4b55v4KJ8", label: "Lane Changing", seconds: 151},
    enteringExiting: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=mdstrgH2ETU">Entering and Exiting</a>, id: "mdstrgH2ETU", label: "Entering and Exiting", seconds: 213},
    steeringWheel: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=eAIbwVE9mG0&list=PLamT53otBDn4O56FljV_jF6hl-ADZTF_k&index=3">Adjusting the Steering Wheel</a>, id: "eAIbwVE9mG0", label: "Adjusting the Steering Wheel", seconds: 59},
    turning: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=1zz6ml4YSew">Turning</a>, id: "1zz6ml4YSew", label: "Turning", seconds: 170},
    drivingStraight: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=LqZ6GFpRGbE">Driving Straight</a>, id: "LqZ6GFpRGbE", label: "Driving Straight", seconds: 89},
    mirrors: {anchor: <a target="_blank" href="https://www.youtube.com/watch?v=fz0HBjt7b_o">Adjusting the Mirrors</a>, id: "fz0HBjt7b_o", label: "Adjusting the Mirrors", seconds: 81}
}

const VIDS_PER_INFRCT = {
    stop_sign_infraction :          [
                                        'rightOfWay',
                                        'controlledIntersections',
                                        'managingIntersections'
                                    ],
    red_light_infraction :          [
                                        'rightOfWay',
                                        'controlledIntersections',
                                        'managingIntersections'
                                    ],
    navigation_infraction :         [
                                        'scanning'
                                    ],
    unsafe_follow_infraction :      [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'speedMgmt'
                                    ],
    school_zone_infraction :        [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    crosswalk_infraction_va0 :      [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    crosswalk_infraction_va1 :      [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    crosswalk_infraction_va2 :      [
                                        'crosswalks',
                                        'scanning',
                                        'speedMgmt'
                                    ],
    school_bus_infraction :         [
                                        'scanning'
                                    ],
    ambulance_infraction :          [
                                        'scanning'
                                    ],
    yielding_infraction :           [
                                        'laneChanging',
                                        'enteringExiting',
                                        'speedMgmt'
                                    ],
    construction_infraction :       [
                                        'scanning',
                                        'speedMgmt'
                                    ],
    aggro_speed_infraction_va0 :    [
                                        'speedMgmt'
                                    ],
    aggro_speed_infraction_va1 :    [
                                        'speedMgmt'
                                    ],
    aggro_speed_infraction_va2 :    [
                                        'speedMgmt'
                                    ],
    too_slow_infraction_va0 :       [
                                        'speedMgmt'
                                    ],
    too_slow_infraction_va1 :       [
                                        'speedMgmt'
                                    ],
    too_slow_infraction_va2 :       [
                                        'speedMgmt'
                                    ],
    over_steer_infraction_va0 :     [
                                        'steeringWheel',
                                        'turning',
                                        'drivingStraight'
                                    ],
    over_steer_infraction_va1 :     [
                                        'steeringWheel',
                                        'turning',
                                        'drivingStraight'
                                    ],
    over_steer_infraction_va2 :     [
                                        'steeringWheel',
                                        'turning',
                                        'drivingStraight'
                                    ],
    weaving_infraction_va0 :        [
                                        'drivingStraight',
                                        'laneChanging'
                                    ],
    weaving_infraction_va1 :        [
                                        'drivingStraight',
                                        'laneChanging'
                                    ],
    weaving_infraction_va2 :        [
                                        'drivingStraight',
                                        'laneChanging'
                                    ],
    jerky_infraction_va0 :          [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'drivingStraight'
                                    ],
    jerky_infraction_va1 :          [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'drivingStraight'
                                    ],
    jerky_infraction_va2 :          [
                                        'spaceCushion1',
                                        'spaceCushion2',
                                        'distanceEstimation',
                                        'drivingStraight'
                                    ],
    off_course_infraction :         [
                                        'speedMgmt',
                                        'scanning',
                                        'turning',
                                        'drivingStraight'
                                    ],
    off_lane_center_infraction_va0 :    [
                                            'drivingStraight',
                                            'laneChanging'
                                        ],
    off_lane_center_infraction_va1 :    [
                                            'drivingStraight',
                                            'laneChanging'
                                        ],
    off_lane_center_infraction_va2 :    [
                                            'drivingStraight',
                                            'laneChanging'
                                        ],
    scanning_infraction :           [
                                        'mirrors',
                                        'scanning'
                                    ]
    }

const INFRACTION_MAP = {
    stop_sign_infraction :          <Infraction
                                        icon={DrivingThruStopSign}
                                        desc={"Driving through a stop sign"}
                                        tip={[
                                                "Always come to a full stop at stop signs.",
                                                "Look all ways for vehicles, people walking, or hazards to decide when it is safe to go."
                                            ]}/>,
    red_light_infraction :          <Infraction
                                        icon={DrivingThroughRedlight}
                                        desc={"Driving through a red traffic light"}
                                        tip={[
                                                "Be careful when coming to a traffic light.", 
                                                "Never run a red light.",
                                                "Stop on yellow when it is okay to do so.",
                                                "Brake slowly and stop fully behind the white stop line or crosswalk if there is one."
                                            ]}/>,
    navigation_infraction :         <Infraction
                                        icon={NotFollowingInstructions}
                                        desc={"Not following the driving directions"}
                                        tip={[
                                                "Following turn-by-turn directions is something you often have to do as a driver. If you are missing turns, or often heading the wrong way, take that as a warning to focus on your main job of driving safely."
                                            ]}/>,
    unsafe_follow_infraction :      <Infraction 
                                        icon={UnsafeFollowing}
                                        desc={"Unsafe following distance"}
                                        tip={["Follow the 3-second rule as often as you can. Leaving less space than that between you and another driver puts you at greater risk of crashing."]}/>,
    school_zone_infraction :        <Infraction
                                        icon={SchoolZoneSpeedLimit}
                                        desc={"Going faster than the school-zone speed limit"}
                                        tip={[
                                            <span><b>{"Do not forget "}</b>{"that speed limits drop on roads near schools during certain hours (before and after school)."}</span>, 
                                            "This is for the safety of students walking to and from school.", 
                                            "The lower speed limit most often extends for some distance past the school."]}/>,
    crosswalk_infraction_va0 :      <Infraction
                                        icon={NotYieldingPedestrians}
                                        desc={"Unsafe driving at a crosswalk"}
                                        tip={[
                                                "People walking always have the right-of-way at posted crosswalks.",
                                                "Come to a full stop when there are walkers crossing or about to cross the road."
                                            ]}/>,
    crosswalk_infraction_va1 :      <Infraction
                                        icon={NotYieldingPedestrians}
                                        desc={"Unsafe driving at a crosswalk"}
                                        tip={[
                                                "People walking always have the right-of-way at posted crosswalks.",
                                                "Come to a full stop when there are walkers crossing or about to cross the road."
                                            ]}/>,
    crosswalk_infraction_va2 :      <Infraction
                                        icon={NotYieldingPedestrians}
                                        desc={"Unsafe driving at a crosswalk"}
                                        tip={[
                                                "People walking always have the right-of-way at posted crosswalks.",
                                                "Come to a full stop when there are walkers crossing or about to cross the road."
                                            ]}/>,
    school_bus_infraction :         <Infraction
                                        icon={NotStoppingForBus}
                                        desc={"Not stopping for a school bus"}
                                        tip={[
                                                "Keep a safe distance from school buses at all times.",
                                                <div>
                                                    {"Never pass a school bus when:"}
                                                    <ul>
                                                        <li>{"it is stopped to drop off or pick up students."}</li>
                                                        <li>{"its lights are blinking."}</li>
                                                        <li>{"its stop sign is extended."}</li>
                                                    </ul>
                                                    {"This is true even if you cannot see children near the bus."}
                                                </div>
                                                
                                            ]}/>,
    ambulance_infraction :          <Infraction
                                        icon={NotPullingOverAmbulance}
                                        desc={"Not stopping for an ambulance"}
                                        tip={[
                                                "Always move to the right for ambulances and other emergency vehicles.",
                                                "If you hear or see one coming up toward you, behind you, or on a nearby crossroad -- move to the right side of the road and come to a full stop as quickly as it is safe to do so."
                                            ]}/>,
    yielding_infraction :           <Infraction
                                        icon={NotYielding}
                                        desc={"Not yielding for traffic"}
                                        raw={true}
                                        tip={
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <ul>
                                                        <li>
                                                        {"When entering a main road from a ramp:"}
                                                        <ul>
                                                            <li>{"slow down."}</li>
                                                            <li>{"watch for traffic."}</li>
                                                            <li>{"use your mirrors to decide when it is safe to merge."}</li>
                                                            <li>{"check your blind spot as a final safety check before merging."}</li>
                                                        </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <ul>
                                                        <li>
                                                            {"When entering a road from a stop sign:"}
                                                            <ul>
                                                                <li>{"stop."}</li>
                                                                <li>{"watch for traffic."}</li>
                                                                <li>{"go when it is safe to do so."}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }/>,
    construction_infraction :       <Infraction
                                        icon={SpeedingConstructionZone}
                                        desc={"Speeding in a construction zone"}
                                        tip={[
                                                "Construction zones can be unsafe for you and those working in them. Speed limits are lower in these zones to keep everyone safe.",
                                                "Slow down when you drive through them no matter where they are.",
                                                "Be mindful of special posted speed limits."
                                            ]}/>,
    aggro_speed_infraction_va0 :    <Infraction
                                        icon={AggressiveSpeeding}
                                        desc={"Case of aggressive speeding"}
                                        tip={[
                                                "Speed limits are there to keep you and others safe. That is why they are posted.",
                                                "Scan for speed limit signs and check your speedometer often.",
                                                "Stay at or just below the posted speed."
                                            ]}/>,
    aggro_speed_infraction_va1 :    <Infraction
                                        icon={AggressiveSpeeding}
                                        desc={"Case of aggressive speeding"}
                                        tip={[
                                                "Speed limits are there to keep you and others safe. That is why they are posted.",
                                                "Scan for speed limit signs and check your speedometer often.",
                                                "Stay at or just below the posted speed."
                                            ]}/>,
    aggro_speed_infraction_va2 :    <Infraction
                                        icon={AggressiveSpeeding}
                                        desc={"Case of aggressive speeding"}
                                        tip={[
                                                "Speed limits are there to keep you and others safe. That is why they are posted.",
                                                "Scan for speed limit signs and check your speedometer often.",
                                                "Stay at or just below the posted speed."
                                            ]}/>,
    too_slow_infraction_va0 :       <Infraction
                                        icon={DrivingTooSlow}
                                        desc={"Driving too slowly"}
                                        tip={[
                                                "Going too slow can be just as unsafe as going too fast.",
                                                "Scan for speed limit signs and check your speedometer often.",
                                                "Try and stay at or just below the posted speed.    "                                        
                                            ]}/>,
    too_slow_infraction_va1 :       <Infraction
                                        icon={DrivingTooSlow}
                                        desc={"Driving too slowly"}
                                        tip={[
                                                "Going too slow can be just as unsafe as going too fast.",
                                                "Scan for speed limit signs and check your speedometer often.",
                                                "Try and stay at or just below the posted speed.    "                                        
                                            ]}/>,
    too_slow_infraction_va2 :       <Infraction
                                        icon={DrivingTooSlow}
                                        desc={"Driving too slowly"}
                                        tip={[
                                                "Going too slow can be just as unsafe as going too fast.",
                                                "Scan for speed limit signs and check your speedometer often.",
                                                "Try and stay at or just below the posted speed.    "                                        
                                            ]}/>,
    over_steer_infraction_va0 :     <Infraction
                                        icon={OverAdjustingSteering}
                                        desc={"Over-turning your steering wheel"}
                                        tip={[
                                                "Small changes or corrections to your steering wheel can cause a big change in the direction your car is going.",
                                                "When driving conditions are normal, it is better to make small changes to the direction your car is going."
                                            ]}/>,
    over_steer_infraction_va1 :     <Infraction
                                        icon={OverAdjustingSteering}
                                        desc={"Over-turning your steering wheel"}
                                        tip={[
                                                "Small changes or corrections to your steering wheel can cause a big change in the direction your car is going.",
                                                "When driving conditions are normal, it is better to make small changes to the direction your car is going."
                                            ]}/>,
    over_steer_infraction_va2 :     <Infraction
                                        icon={OverAdjustingSteering}
                                        desc={"Over-turning your steering wheel"}
                                        tip={[
                                                "Small changes or corrections to your steering wheel can cause a big change in the direction your car is going.",
                                                "When driving conditions are normal, it is better to make small changes to the direction your car is going."
                                            ]}/>,
    weaving_infraction_va0 :        <Infraction
                                        icon={ExcessiveWeavingLane}
                                        desc={"Too much weaving in your lane"}
                                        tip={["Keep your vehicle steady in one lane position. In or near the center of the lane is best."]}/>,
    weaving_infraction_va1 :        <Infraction
                                        icon={ExcessiveWeavingLane}
                                        desc={"Too much weaving in your lane"}
                                        tip={["Keep your vehicle steady in one lane position. In or near the center of the lane is best."]}/>,
    weaving_infraction_va2 :        <Infraction
                                        icon={ExcessiveWeavingLane}
                                        desc={"Too much weaving in your lane"}
                                        tip={["Keep your vehicle steady in one lane position. In or near the center of the lane is best."]}/>,
    jerky_infraction_va0 :          <Infraction
                                        icon={JerkyVehicleControl}
                                        desc={"Jerky vehicle control"}
                                        tip={["When it is not an emergency, first slow your vehicle by slowly taking your foot off the accelerator. When braking, begin with soft pressure and slowly increase it as needed."]}/>,
    jerky_infraction_va1 :          <Infraction
                                        icon={JerkyVehicleControl}
                                        desc={"Jerky vehicle control"}
                                        tip={["When it is not an emergency, first slow your vehicle by slowly taking your foot off the accelerator. When braking, begin with soft pressure and slowly increase it as needed."]}/>,
    jerky_infraction_va2 :          <Infraction
                                        icon={JerkyVehicleControl}
                                        desc={"Jerky vehicle control"}
                                        tip={["When it is not an emergency, first slow your vehicle by slowly taking your foot off the accelerator. When braking, begin with soft pressure and slowly increase it as needed."]}/>,
    off_course_infraction :         <Infraction
                                        icon={DrivingOffCourse}
                                        desc={"Driving off the road"}
                                        tip={[
                                            <div>
                                                {"When you drive off the edge of the road it is generally because:"}
                                                <ul>
                                                    <li>{"You are going too fast for road conditions.  Or,"}</li>
                                                    <li>{"You are distracted and not focusing on the road ahead."}</li>
                                                </ul>
                                            </div>,
                                            "Watch your speed and keep your eyes on the road."
                                            
                                        ]}/>,
    off_lane_center_infraction_va0 :    <Infraction
                                            icon={ExcessiveLaneDeviation}
                                            desc={"Often off center of lane"}
                                            tip={["In most driving conditions, it is safest to stay as close to the center of your lane as you can.", " Keeping your eyes looking ahead of your vehicle will help you do this."]}/>,
    off_lane_center_infraction_va1 :    <Infraction
                                            icon={ExcessiveLaneDeviation}
                                            desc={"Often off center of lane"}
                                            tip={["In most driving conditions, it is safest to stay as close to the center of your lane as you can.", " Keeping your eyes looking ahead of your vehicle will help you do this."]}/>,
    off_lane_center_infraction_va2 :    <Infraction
                                            icon={ExcessiveLaneDeviation}
                                            desc={"Often off center of lane"}
                                            tip={["In most driving conditions, it is safest to stay as close to the center of your lane as you can.", " Keeping your eyes looking ahead of your vehicle will help you do this."]}/>,
    scanning_infraction :           <Infraction
                                            icon={ScanForHazards}
                                            desc={"Not scanning for possible or hidden hazards"}
                                            tip={[
                                                    "Always scan around you while driving.",
                                                    "Expect that a hazard may enter your path so you can take steps to avoid a crash."
                                                ]}/>,
}

function hasInfraction(inf, fd) {
    // console.log("hasInfraction", inf, fd);
    if (fd && fd.infractions) {
        for (let i = 0; i < fd.infractions.length; i++) {
            const infraction = fd.infractions[i];
            if (infraction.name == inf) {
                return true;
            }
        }
    }
    
    return false;
}

function getRegion(zScore) {
    // console.log(zScore);
    if (zScore > 2) {
        return 0;
    }
    if (zScore > 1 && zScore <= 2) {
        return 1;
    }
    if (zScore > 0.5 && zScore <= 1) {
        return 2;
    }

    if (zScore > -0.5 && zScore <= 0.5) {
        return 3;
    }

    if (zScore > -1 && zScore <= -0.5) {
        return 4;
    }

    if (zScore > -2 && zScore <= -1) {
        return 5;
    }
    if (zScore <= -2) {
        return 6;
    }
}

const OVRL_RSPNS = [
    "well below average",
    "below average",
    "somewhat below average",
    "around the average",
    "somewhat above average",
    "above average",
    "well above average"
]

const OVRL_TAG = [
    "Very concerned",
    "Concerned",
    "Keep practicing",
    "Keep practicing",
    "Keep practicing",
    "Nice work but keep practicing!",
    "Nice work but keep practicing!"
]

const OVRL_TAG_STMT = [
    "You are likely not ready to drive on your own and should get some more driving practice with a certified driving instructor.",
    "Consider getting some more practice with a certified driving instructor before driving on your own.",
    "There are still many things you can improve to become a safer driver.",
    "There are still many things you can improve to become a safer driver.",
    "There are still many things you can improve to become a safer driver.",
    "There are still many things you can improve to become a safer driver.",
    "There are still many things you can improve to become a safer driver."
]

function getBetterPeople(fd) {
    return Math.round(100 - (fd.results.overallPerformScore * 100));
}

function getArrowXOffset(z, center, distance) {
    if (z < 0) {
        z = Math.max(z, -3.0);
    }

    if (z > 0) {
        z = Math.min(z, 3.0);
    }
    let result = (-(z * distance) + center) - 5; // Total image size is 680, halfway point is 340, pixel distance from 0 - 1 is 96 -- z is offset from center. Subtract 5 half arrow width.
    // console.log("get Arrow offset", result);
    return result;
}

function setupInfractionAndVideoLists(feedbackData) {
    if (feedbackData.age >= 0 || isAgeCategorical(feedbackData.age)) {
        let globalInfractions = [];
        // console.group('GLOBAL INFRACTIONS');
        feedbackData.results.infractions_va0 = feedbackData.results.infractions_va0 || [];
        feedbackData.results.infractions_va1 = feedbackData.results.infractions_va1 || [];
        feedbackData.results.infractions_va2 = feedbackData.results.infractions_va2 || [];
        for (var i = feedbackData.results.infractions_va0.length - 1; i >= 0; i--) {
            let infraction = feedbackData.results.infractions_va0[i];
            // console.log(i, infraction, infraction.name.indexOf('_va0'));
            if (!(infraction.name.indexOf('_va0') >= 0)) {
                globalInfractions.push(infraction);
            }
        };
        // console.groupEnd();
        if (feedbackData.age >= 18 || feedbackData.age == -2000) {
            feedbackData.groupId = 'va2';
            feedbackData.infractions = feedbackData.results.infractions_va2.concat(globalInfractions);
            feedbackData.results.overallPerformScore = feedbackData.results.percentile_va2;
            feedbackData.results.num_infractions = feedbackData.results.num_infractions_va2;
            feedbackData.results.zScore = feedbackData.results.z_score_va2;
        } else {
            feedbackData.groupId = 'va1';
            feedbackData.infractions = feedbackData.results.infractions_va1.concat(globalInfractions);
            feedbackData.results.overallPerformScore = feedbackData.results.percentile_va1;
            feedbackData.results.num_infractions = feedbackData.results.num_infractions_va1;
            feedbackData.results.zScore = feedbackData.results.z_score_va1;
        }
    } else {
        feedbackData.groupId = 'va0';
        feedbackData.infractions = feedbackData.results.infractions_va0;
        feedbackData.results.overallPerformScore = feedbackData.results.percentile_va0;
        feedbackData.results.num_infractions = feedbackData.results.num_infractions_va0;
        feedbackData.results.zScore = feedbackData.results.z_score_va0;
    }

    feedbackData.results.num_infractions = feedbackData.infractions.length;
    

    let videoFilter = {};
    for (var i = 0; i < feedbackData.infractions.length; i++) {
        let infracky = feedbackData.infractions[i];
        if (infracky) {
            let vids = VIDS_PER_INFRCT[infracky.name];
            if (vids) {
                for (var j = 0; j < vids.length; j++) {
                    videoFilter[vids[j]] = true;
                };
            }
            
        }
        
    };

    videoFilter['scanning'] = true;
    videoFilter['speedMgmt'] = true;

    feedbackData.videos = _.map(videoFilter, (val, key) => {
        return VIDEO_MAP[key];
    })

    // console.log("VIDEO DEBUG", videoFilter, feedbackData.videos);

    let infractionPages = [];
    let page = -1;
    for (var i = 0; i < feedbackData.infractions.length; i++) {
        let remainder = i % 6;

        if (!remainder) {
            page++;
        }

        let oddPage = page % 1;
        let oddRow = i % 2;
        let rowClass = "fbr-wht";
        // if (oddPage) {
        //     if (oddRow) {
        //         rowClass = "";
        //     }
        // } else {
        //     if (!oddRow) {
        //         rowClass = "";
        //     }
        // }

        if (!oddRow) {
            rowClass = ""; 
        }
        infractionPages[page] = infractionPages[page] || [];
        let inf = feedbackData.infractions[i];
        infractionPages[page].push(<div key={"infraction-" + i} className={"fbr-wht-" + oddRow}>{INFRACTION_MAP[inf.name]}</div>)
    };
    feedbackData.infractionPages = infractionPages;
}

const COMMON_CRASH_COPY = {
    rear_end_common_crash: {
        short: 'Following other vehicles (rear-end)',
        icon: FollowingVehicles,
        title : 'Following Vehicles',
        example : 'Rear-end crash',
        iconClass : 'flw',
        handledWell : <div><div className='ccp-hdr'>Nice work!</div><ul className='ccp-ul'><li>Always keep a cushion of at least 3 seconds between you and vehicles in front of you.</li></ul></div>,
        crashed : <div><div className='ccp-hdr'>You followed too closely and caused a bad outcome.</div><ul className='ccp-ul'><li>Practice keeping a space cushion of at least 3 seconds between you and vehicles in front of you.</li></ul></div>,
        danger : <div><div className='ccp-hdr'>You did not have a bad outcome. But there were times when following too close put you in danger.</div><ul className='ccp-ul'><li>Practice keeping a space cushion of at least 3 seconds between you and vehicles in front of you.</li></ul></div>,
    },
    curve_common_crash: {
        short: 'Handling Curves (driving off the road)',
        icon: NegotiatingCurves,
        title : 'Handling Curves',
        example : 'Driving off the road',
        iconClass : 'crv',
        handledWell : <div><div className='ccp-hdr'>Good job!</div><ul className='ccp-ul'><li>Keep working on slowing your vehicle over time as you enter a curve.</li><li>Keep your eyes on the road in front of you so you know how much of the curve is left.</li></ul></div>,
        crashed : <div><div className='ccp-hdr'>Curves can be tricky. To keep from going off the road or bad outcomes like that while driving through a curve:</div><ul className='ccp-ul'><li>Practice slowing your vehicle over time as you enter a curve.</li><li>Keep your eyes on the road in front of you so you know how much of the curve is left.</li></ul></div>,
        danger : <div><div className='ccp-hdr'>Curves can be tricky. To keep from going off the road or bad outcomes like that while driving through a curve:</div><ul className='ccp-ul'><li>Practice slowing your vehicle over time as you enter a curve.</li><li>Keep your eyes on the road in front of you so you know how much of the curve is left.</li></ul></div>,
    },
    too_fast_common_crash: {
        short: 'Driving straight & too fast for conditions (driving off the road)',
        icon: DrivingStraightTooFast,
        title : 'Driving Straight and Too Fast',
        example : 'Speeding on straightaways',
        iconClass : 'fast',
        handledWell : <div><div className='ccp-hdr'>You stayed on the road in all the places that were not curves.</div><ul className='ccp-ul'><li>Keep following posted speed limits.</li><li>Always keep your eyes on the road in front of you and scan often.</li></ul></div>,
        crashed : <div><div className='ccp-hdr'>You had a bad outcome in one or more places that were not a curve.</div><ul className='ccp-ul'><li>Practice keeping your eyes on the road in front of you and scanning often.</li><li>Watch your speed. Going too fast can make running off the road more likely.</li></ul></div>,
        danger : <div><div className='ccp-hdr'>You were at risk of having a bad outcome in one or more places that were not a curve.</div><ul className='ccp-ul'><li>Practice keeping your eyes on the road in front of you and scan often.</li><li>Watch your speed because going too fast can make running off the road more likely.</li></ul></div>,
    },
    manage_intersection_common_crash: {
        short: 'Handling intersections with traffic (Turning & timing crashes)',
        icon: ManagingIntersections,
        title : 'Handling Intersections',
        example : 'Turning & timing crashes',
        iconClass : 'int',
        handledWell : <div><div className='ccp-hdr'>Well done! You did not crash or have any other bad outcomes when turning left at intersections.</div><ul className='ccp-ul'><li>Stay aware of traffic lights and stop signs.</li><li>When you turn, be sure to safely check the speed and distance of traffic coming across your path or headed at you.</li></ul></div>,
        crashed : <div><div className='ccp-hdr'>You had a bad outcome at least once when turning left at an intersection.</div><ul className='ccp-ul'><li>Pay attention to traffic lights and stop signs.</li><li>When you turn, be sure to safely check the speed and distance of traffic coming across your path or headed at you.</li></ul></div>,
        danger : <div><div className='ccp-hdr'>You did not have a bad outcome when turning left at an intersection. But some of your actions put you at risk.</div><ul className='ccp-ul'><li>Pay closer attention to traffic lights and stop signs.</li><li>When you turn, be sure to safely check the speed and distance of traffic coming across your path or headed at you.</li></ul></div>,
    },
    other_areas_common_crash: {
        short: 'Other Parts of the Drive (Beyond the four scenarios above)',
        icon: OtherAreasofDriving,
        title : 'Other Parts of the Drive',
        example : 'Beyond the four scenarios above',
        iconClass : 'oth',
        handledWell : <div><div className='ccp-hdr'>Bad outcomes can happen at any time.</div><ul className='ccp-ul'><li>Stay alert to:<ul><li>vehicles stopping quickly in front of you</li><li>people walking, or objects you might need to stop or slow down for</li></ul></li><li>Stick to posted speed limits</li><li>Scan in front of you often</li><li>Keep the right following distances</li></ul></div>,
        crashed : <div><div className='ccp-hdr'>Bad outcomes can happen at any time.</div><ul className='ccp-ul'><li>Stay alert to:<ul><li>vehicles stopping quickly in front of you</li><li>people walking, or objects you might need to stop or slow down for</li></ul></li><li>Stick to posted speed limits</li><li>Scan in front of you often</li><li>Keep the right following distances</li></ul></div>,
        danger : <div><div className='ccp-hdr'>Bad outcomes can happen at any time.</div><ul className='ccp-ul'><li>Stay alert to:<ul><li>vehicles stopping quickly in front of you</li><li>people walking, or objects you might need to stop or slow down for</li></ul></li><li>Stick to posted speed limits</li><li>Scan in front of you often</li><li>Keep the right following distances</li></ul></div>,
    }
}


function createSampleData() {
    return {
        name: 'Mary Jo Driver',
        date: 'December 20, 2020',
        age: 16,
        status: 'Learner permit',
        loc: 'Anytown Driving School, LLC, 1 A St.',
        results: {
            description: "version 25",
            common_crashes: [
                {
                    name: 'rear_end_common_crash',
                    show_handled_well: true,
                    show_in_crash_column:false,
                    show_in_danger_column:false,
                    show_needs_work:false,
                    show_red_exclamation:false,
                },
                {
                    name: 'curve_common_crash',
                    show_handled_well: false,
                    show_in_crash_column:true,
                    show_in_danger_column:true,
                    show_needs_work:true,
                    show_red_exclamation:true,
                },
                {
                    name: 'too_fast_common_crash',
                    show_handled_well: false,
                    show_in_crash_column:false,
                    show_in_danger_column:true,
                    show_needs_work:true,
                    show_red_exclamation:true,
                },
                {
                    name: 'manage_intersection_common_crash',
                    show_handled_well: false,
                    show_in_crash_column:false,
                    show_in_danger_column:true,
                    show_needs_work:true,
                    show_red_exclamation:true,
                },
                {
                    name: 'other_areas_common_crash',
                    show_handled_well: true,
                    show_in_crash_column:false,
                    show_in_danger_column:false,
                    show_needs_work:false,
                    show_red_exclamation:false,
                },
            ],
            infractions_va0: [
                {name: "stop_sign_infraction"},
                {name: "red_light_infraction"},
                {name: "navigation_infraction"},
                {name: "unsafe_follow_infraction"},
                {name: "school_zone_infraction"},
                {name: "crosswalk_infraction_va0"},
                {name: "school_bus_infraction"},
                {name: "ambulance_infraction"},
                {name: "yielding_infraction"},
                {name: "construction_infraction"},
                {name: "off_course_infraction"},
                {name: "off_lane_center_infraction_va0"},
                {name: "scanning_infraction"}
            ],
            infractions_va1: [
                {name: "crosswalk_infraction_va1"},
                {name: "aggro_speed_infraction_va1"},
                {name: "too_slow_infraction_va1"},
                {name: "over_steer_infraction_va1"},
                {name: "weaving_infraction_va1"},
                {name: "jerky_infraction_va1"},
                {name: "off_lane_center_infraction_va1"},
            ],
            infractions_va2: [
                {name: "off_lane_center_infraction_va2"}
            ],
            num_infractions_va0:11,
            num_infractions_va1:11,
            num_infractions_va2:11,
            GLOBAL_num_ped_collisions: 1,
            GLOBAL_num_veh_collisions: 1,
            GLOBAL_num_misc_obj_collisions: 1,
            GLOBAL_num_static_collisions: 1,
            percentile_va0:0.7048261135451468,
            percentile_va1:0.7403462439321946,
            percentile_va2:0.6835196165710286,
            vdt_error_score:7,
            z_score_va0: -0.5383321304604921,
            z_score_va1: -0.6444132231651334,
            z_score_va2: -0.47756370785065677,
        }
    }
}

function renderScorebar(ctx, score, w, h, bw, y) {
    //oom=23&pm=6&lbld=55
    ctx.clearRect(0, 0, w, h);
    ctx.save();

    const lineStartX = (w - bw) / 2;
    const lineY = 50;
    const pm = 6;
    const om = 8;
    const oom = 23;
    const lineEndX = (lineStartX + bw);
    const lineMidX = (lineStartX + (bw / 2));
    const lbld = 55;


    const you = score ? parseInt(score) / 100 : 0;
    // console.log('YOU', you);
    const uX = lineStartX + (you * bw);
    let ulb = 'YOUR SCORE';

    ctx.beginPath();
    ctx.moveTo(lineStartX, lineY);
    ctx.lineTo(lineEndX, lineY);
    ctx.lineWidth = 3;

      // set line color
    ctx.strokeStyle = '#6b2867';
    ctx.stroke();


    ctx.beginPath();
    ctx.arc(lineStartX, lineY, pm, 0, 2 * Math.PI);
    ctx.fillStyle = '#6b2867';
    ctx.fill();
    
    
    ctx.beginPath();
    ctx.arc(lineMidX, lineY, pm, 0, 2 * Math.PI);
    ctx.fillStyle = '#6b2867';
    ctx.fill();

    ctx.beginPath();
    ctx.arc(lineEndX, lineY, pm, 0, 2 * Math.PI);
    ctx.fillStyle = '#6b2867';
    ctx.fill();

    ctx.font = "11px Arial"; 
    ctx.textAlign = "center"; 
    ctx.fillStyle = '#d28c3f';
    ctx.fillText('0%', lineStartX , 20);
    ctx.fillText('50%', lineMidX , 20);
    ctx.fillText('100%', lineEndX , 20);


    if (uX !== null) {
        // console.log("DRAW uX!");
        ctx.beginPath();
        ctx.arc(uX, lineY, om, 0, 2 * Math.PI);
        ctx.fillStyle = '#d28c3f';
        ctx.fill();
        ctx.beginPath();
        ctx.arc(uX, lineY, oom, 0, 2 * Math.PI);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#d28c3f';
        ctx.stroke();
        ctx.font = "15px Arial"; 
        ctx.textAlign = "center"; 
        ctx.fillStyle = '#d28c3f';
        ctx.fillText(ulb, uX , lineY + lbld);
    }

    ctx.restore();
}

class FeedbackReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackData : {},
            isStep: false, 
            activeIndex: 0,
            finished: false,
            totalSteps: 4,
            stepsHaveBeenSet: false,
            completedAllVideos: false
        }
    }

    componentWillMount () {
        const p = this.props;
        if (p.params && p.params.aid || p.aid) {
            store.dispatch( actions.startListeningToAssessment(p.aid || p.params.aid) );
        } else {
            store.dispatch(actions.startListeningToStaticFeedback());
        }
        store.dispatch( actions.startListeningToOrgs() );
        let doStepByStep = p.isStep || p.params && p.params.isStep;
        this.setState({isStep : doStepByStep})
        window.toggleIsStep = (() => {
            this.setState({isStep : !this.state.isStep})
        }).bind(this)
        // store.dispatch( actions.logBrowserInfo('pfr') );
        document.body.classList.toggle('hideSuperBar', true);
    }

    componentWillUnmount () {
        document.body.classList.toggle('hideSuperBar', false);
    }

    handleNext () {
        const {activeIndex} = this.state;
        let allDone = activeIndex >= this.state.totalSteps;
        let p = this.props;
        let aid = p.params && p.params.aid || p.aid;
        store.dispatch( actions.updateFeedbackProgress(aid, this.state.totalSteps, activeIndex) );
        if (allDone) {
            this.setState({finished: allDone});
            // REPORT page completed to assessment
            // REPORT completed to assessment
            //NB: What are we actually doing here?
            const router = this.props.router;
            router.replace('/');
            //alert("You did it!")
        } else {
            // REPORT page completed to assessment
            this.setState({
                activeIndex: activeIndex + 1,
            });
        } 
    };
    
    handlePrev () {
        const {activeIndex} = this.state;
        if (activeIndex > 0) {
            this.setState({activeIndex: activeIndex - 1});
        }
    };
    
    renderStepActions (opt_finished) {
        const {activeIndex, isStep} = this.state;
        if (!isStep) {
            return null;
        }
        console.log("render step actions", activeIndex, this.state.totalSteps);
        return (
            <div className='fbr-step-nav-btn-box'>
                {activeIndex > 0 && (
                    <FlatButton
                        label="Back"
                        disabled={activeIndex === 0}
                        disableTouchRipple={true}
                        disableFocusRipple={true}
                        onClick={this.handlePrev.bind(this)}
                    />
                )}
                <RaisedButton
                    label={activeIndex === this.state.totalSteps ? 'Finish' : 'Next'}
                    disableTouchRipple={true}
                    disableFocusRipple={true}
                    disabled={opt_finished && !opt_finished()}
                    primary={true}
                    onClick={this.handleNext.bind(this)}
                    style={{marginRight: screen.width <= 479 ? 5 : 12}}
                />
                
            </div>
        );
    }

    makeStep (label, stepIndex, content) {
        const {activeIndex} = this.state;
        if (this.state.isStep) {
            console.log("STEP: THIS IS A STEP!", stepIndex, label, this.state);
            return  <div key={"fbr-step-" + stepIndex} className={('fbr-step fbr-step-' + stepIndex + 1) + (activeIndex === stepIndex ? " fbr-active-step" : " fbr-hidden-step")}>
                        {content}
                    </div>
        } else {
            console.log("STEP: THIS IS NOT A STEP!", this.state);
            return  <div key={"fbr-step-" + stepIndex} className={'fbr-page-' + (stepIndex + 1)}>
                        {content}
                     </div>
        }
    }; 

    componentDidUpdate(prevProps, prevState, snapshot) {
        let p = this.props;
        let aid = p.params && p.params.aid || p.aid;
        let nowHave = p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData;
        let hadBefore = prevProps.assessments && prevProps.assessments.data && prevProps.assessments.data[aid] && prevProps.assessments.data[aid].hasReceivedData;
        if (nowHave && !hadBefore) {
            let assessmentData = p.assessments.data[aid].value;
            if (assessmentData && assessmentData.intake) {
                store.dispatch( actions.startListeningToIntakeForm(assessmentData.intake))
            }
        }

        let intakeForms = p.intakeForms;
        let assessmentData = p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData && p.assessments.data[aid].value;
        let intakeId = assessmentData && assessmentData.intake;
        let intakeData = intakeId && intakeForms && intakeForms[intakeId] && intakeForms[intakeId].data;
        console.log('<<WUT>>', intakeForms, assessmentData, intakeId, intakeData);
        let okToSetSteps = false; // !this.state.stepsHaveBeenSet && assessmentData && ((!intakeId) || intakeData)
        // if (!this.state.stepsHaveBeenSet) {
            if (assessmentData) {
                if (!intakeId) {
                    okToSetSteps = true;
                } else {
                    if (intakeData) {
                        okToSetSteps = true;
                    }
                }
            }
        // }

        if (okToSetSteps) {
            console.log("DO WE EVER GET HERE?");
            let feedbackData = {};
            // let assessmentData = {};
            // let intakeData = {};
            let go = false;

            if (!aid) {
                if (!p.staticFeedback.empty) {
                    feedbackData = p.staticFeedback;
                    setupInfractionAndVideoLists(feedbackData);
                    go = true;
                }
                
            } else {
                    
                    if (assessmentData && assessmentData.feedbackData) {


                        feedbackData = collectFeedbackData(assessmentData, intakeData, p.orgs);
                        setupInfractionAndVideoLists(feedbackData);
                        go = true;
                    }

            }

            console.log('component did update', go, feedbackData);
            if (go && feedbackData.infractionPages) {
                let isNJM = 0;
                if (assessmentData && assessmentData.organization) {
                    let page3Logo = PAGE_3_LOGOS[assessmentData.organization]; //PAGE_3_LOGOS['-MWNAja4yGMW2rkzBXuW'];
                    let page3CustomCopy = PAGE_3_CUSTOM_COPY[assessmentData.organization]; // PAGE_3_CUSTOM_COPY['-MWNAja4yGMW2rkzBXuW']; 
                    isNJM = page3Logo && page3CustomCopy ? 1 : 0;
                }
                console.log('** < ( debugging page count COMPONENT DID UPDATE ) > ** ', isNJM, feedbackData.infractionPages.length);
                let allSteps = 6 + isNJM + feedbackData.infractionPages.length;
                if (allSteps != this.state.totalSteps) {
                    this.setState({totalSteps: 6 + isNJM + feedbackData.infractionPages.length, stepsHaveBeenSet: true});
                }
            }
        }

          
        
    }

    render () {

        // return <div style={{backgroundColor: '#FFF', width: "200px", height: "200px"}}>test</div>;
        // total # of pages = 7 + NJM? + infractionPages 
        const p = this.props;
        const aid = this.props.params && this.props.params.aid || p.aid;
        const isStep = p.params && p.params.isStep || p.isStep;
        const isMbl = screen.width <= 479;
        const usiLeft = isMbl ? 'col-xs-6' : 'col-sm-4';
        const usiRight = isMbl ? 'col-xs-6' : 'col-sm-8';

        // console.log("isStep?", isStep);

        let feedbackData = {};
        let assessmentData = {};
        let intakeData = {};

        if (!p.orgs.hasReceivedOrgData) {
            return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
        }

        if (aid && !(p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData)) {
            return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
        }

        if (aid && p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
            if (!p.intakeForms) {
                return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
            }
            if (assessmentData.intake && !(p.intakeForms[assessmentData.intake] && p.intakeForms[assessmentData.intake].data)) {
                return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
            }
        }



        if (!aid) {
            if (p.staticFeedback.empty) {
                return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
            }
            feedbackData = p.staticFeedback;
        } else {
            if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
                assessmentData = p.assessments.data[aid].value;
                if (assessmentData) {
                    if (!assessmentData.feedbackData) {
                        console.log('no feedbackData');
                        return <Apology aid={aid} msg="noFeedbackOnAssessment" />
                    }
                }
                

                if (p.intakeForms && assessmentData && assessmentData.intake && p.intakeForms[assessmentData.intake] && p.intakeForms[assessmentData.intake].data) {
                    
                    intakeData = p.intakeForms[assessmentData.intake].data;
                }

            } else {
                if (p.assessments && p.assessments.data && p.assessments.data[aid] && p.assessments.data[aid].hasReceivedData) {
                    // not loading -- no assessment found
                    // render apology PDF
                    return <Apology aid={aid} msg="noAssessmentFound" />
                }
                return <div style={{textAlign: 'center', color: '#FFF', fontSize: '100px'}}>Loading...</div>
            }


            if (assessmentData) {
                feedbackData = collectFeedbackData(assessmentData, intakeData, p.orgs);
            } else {
                return <Apology aid={aid} msg="noAssessmentFinalCheck" />
            }
            
            
        }

        setupInfractionAndVideoLists(feedbackData);

        let titlePageLogo = TITLE_PAGE_LOGOS[assessmentData.organization]; //TITLE_PAGE_LOGOS['-MWNAja4yGMW2rkzBXuW'];
        let page3Logo = PAGE_3_LOGOS[assessmentData.organization]; //PAGE_3_LOGOS['-MWNAja4yGMW2rkzBXuW'];
        let page3CustomCopy = PAGE_3_CUSTOM_COPY[assessmentData.organization]; // PAGE_3_CUSTOM_COPY['-MWNAja4yGMW2rkzBXuW']; 
        
        // titlePageLogo = TITLE_PAGE_LOGOS['-MWNAja4yGMW2rkzBXuW'];
        // page3Logo = PAGE_3_LOGOS['-MWNAja4yGMW2rkzBXuW'];
        // page3CustomCopy = PAGE_3_CUSTOM_COPY['-MWNAja4yGMW2rkzBXuW']; 

        const isNJM = page3Logo && page3CustomCopy ? 1 : 0;
        // let totalNumberOfPages = 7 + isNJM + feedbackData.infractionPages.length;
        console.log('** < ( debugging page count MAIN RENDER ) > ** ', isNJM, feedbackData.infractionPages.length);
        console.log("OOOOOOOOOOOOOOOOOO", feedbackData);

        return (
            <ConditionalWrapper
                condition={isStep}
                wrapper={children => <div className='fbr-s' >{children}</div>}
                otherwise={children => <div className='fbr'>{children}</div>}
            >
                
                {this.makeStep('Your Ready Assess Feedback Report', 0, <div>
                    { isStep ? <div className="fbr-flush-top fbr-purp display-7">{this.renderStepActions()}</div> : null}
                    <div className="fbr-p1-ra-logo"><img width="460"  alt="" src={ReadyAssessLogo} /></div>
                    <div><img className='personal-feedback-report-title' width="800"  alt="" src={Title} /></div>
                    <div className="fbr-p1-deets display-6">
                        <div className="fbr-prepFor">Prepared for <span className="fbr-p1-name">{feedbackData.name || 'you'}</span></div>
                        <div className="fbr-date">{feedbackData.date}</div>
                    </div>
                    {
                        titlePageLogo ?
                        <div style={{width:"80%", margin: "auto"}}>
                            <div className="row">
                                <div className="col-sm-6"><img className="fbr-dxd-logo" style={{width:"100%"}}  alt="" src={DxdLogo} /></div>
                                <div className="col-sm-6"><img className="fbr-dxd-logo" style={{width:"100%"}}  alt="" src={titlePageLogo} /></div>
                            </div>
                        </div> :
                        <div className="fbr-p1-dxd-logo"><img className="fbr-dxd-logo" width="386"  alt="" src={DxdLogo} /></div>
                    }
                </div>)}
                {this.makeStep("READ THIS FIRST: AN INTRODUCTION", 1, <div>
                    <span className="page-number">1</span>
                    <div className="fbr-flush-top fbr-purp display-7">READ THIS FIRST: AN INTRODUCTION{this.renderStepActions()}</div>
                    <div className="intro-content-v2">
                        <div className="ic-deets">
                            <div><span className="ic-label">Name: </span><span className="lrgr">{feedbackData.name || ''}</span></div>
                            <div><span className="ic-label">Age: </span><span className="lrgr">{feedbackData.age >= 0 ? (feedbackData.age + ' year' + (feedbackData.age == 1 ? '' : 's')) : (getAgeCategoricalString(feedbackData.age))}</span></div>
                            <div><span className="ic-label">Driver status: </span><span className="lrgr">{feedbackData.status}</span></div>
                            <div><span className="ic-label">Date completed: </span><span className="lrgr">{feedbackData.date || ''}</span></div>
                            <div><span className="ic-label">Location taken: </span><span className="lrgr">{feedbackData.loc}</span></div>
                        </div>
                        {
                        
                        false ?
                        
                        (
                            
                            <div className="fbr-cntnt">
                                <div className="fbr-sct-hdr-v2">Welcome to your Safe Driving Skills Report.</div>
                                <div className="fbr-sct-cntnt">
                                    <p>
                                        <b>Please read through these first two pages</b> – they will provide you with important background information on the test you’ve just taken and give you useful context for how to think about your results.
                                    </p>
                                    <p>
                                        <span className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is an educational tool and should not replace the advice of a certified driving instructor. We encourage you to share this report with a driving instructor and/or your guardians. Your results and personalized action plan are detailed in the pages below.
                                    </p>
                                </div>
                                <div className="fbr-sct-hdr-v2">Thank you for caring about being a safer driver.</div>
                                <div className="fbr-sct-cntnt">
                                    <p>
                                        Nearly 1-in-5 new drivers get into a crash soon after obtaining their driver’s license and we don’t want that to happen to you.
                                    </p>
                                    <p>
                                        Developed by scientists from the Children’s Hospital of Philadelphia, <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is a way to measure your driving skills, specifically focusing on the skills you need to avoid crashes. Whether you are preparing to get your license, or already have one, we hope this report provides you with useful information as you continue on your journey of becoming a safer and independent driver.
                                    </p>
                                </div>
                                <div className="fbr-sct-hdr-v2">What Does <i>Ready-Assess</i>&#8482; do?</div>
                                <div className="fbr-sct-cntnt">
                                    <p>
                                        <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> specifically tests your skills at avoiding crashes that are highly common and cause injuries to drivers and passengers.
                                    </p>
                                    <ul>
                                        <li>You may not have realized it, but in the drive you completed we exposed you to common crash scenarios shown to result in serious injuries to drivers like you – scenarios like following other vehicles, handling curves, and managing intersections when there is traffic.</li>
                                        <li>In your feedback you’ll see how you performed compared to over 50,000 other people like you who have completed this exact same test.</li>
                                        <li>You will learn more about where your skills are strong, and where you still have room for improvement.</li>
                                    </ul>
                            
                                </div>
                            
                            </div>
                            
                        )
                        
                        :
                        
                        null
                        
                        
                        }
                        
                        <div className="fbr-cntnt">
                            <div className="fbr-sct-hdr-v2">Welcome to Your Report.</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    Car crashes are a leading cause of death and injury for all ages.  Specifically for teenagers, nearly 1 out of 5 get into a crash soon after they get their driver’s license. Many adults are at risk of crashing too.
                                </p>
                                <p>
                                    We do not want any of this to happen to you.
                                </p>
                            </div>
                            <div className="fbr-sct-hdr-v2">What Does <i>Ready-Assess</i>&#8482; Do?</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    Scientists from the <a href="https://injury.research.chop.edu/" target="_blank">Children’s Hospital of Philadelphia</a> built <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> as a way to measure your skills at avoiding car crashes that are very common.
                                </p>
                                <p>
                                    During the test, you drove through common and dangerous crash situations.  
                                </p>
                                <p>
                                <b>Things like:</b>
                                </p>
                                <ul>
                                    <li>Following other vehicles.</li>
                                    <li>Handling curves.</li>
                                    <li>Dealing with intersections when there is traffic.</li>
                                </ul>
                            
                            </div>
                            <div className="fbr-sct-hdr-v2">How To Use This Feedback Report and Successfully Complete <i>Ready-Assess</i>&#8482;</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    In your feedback you will:
                                </p>
                                <ul>
                                    <li>See how you did, compared to thousands of people like you who have taken the same test.</li>
                                    <li>Learn more about where your skills are strong, and where you still have room to get better.</li>
                                    <li>Get tips on how to be a safer driver.</li>
                                    <li>Get direct access to safe driving videos that are proven to help people improve their driving.</li>
                                </ul>
                            </div>
                            <div className="bold-to-edge-hilite-box" style={{width: (Math.min(screen.width, 844) + 'px')}}>
                                <div className='bold-to-edge-hilite'>Watch all of the videos provided on page {4 + isNJM} of this report to successfully complete <i>Ready-Assess</i>&#8482;.</div>
                            </div>
                        
                        </div>
                        
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>)}
                {this.makeStep("READ THIS FIRST: AN INTRODUCTION", 2, <div>
                    <span className="page-number">2</span>
                    <div className="fbr-flush-top fbr-purp display-7">READ THIS FIRST: AN INTRODUCTION{this.renderStepActions()}</div>
                    <div className="intro-content-v2">
                        <div className="fbr-cntnt">
                            <div className="fbr-sct-hdr-v2">The Test Is Not the Same As Driving a Real Car, and That Is OK.</div>
                            <div className="fbr-sct-cntnt">
                                <p>We know that this “drive” took place on a computer and not in a real car.</p>
                                <p>We know that the steering wheels and pedals were a little more touchy than you are used to.  Because of that, you may have felt as if you had some trouble controlling the car.</p>
                                <p><b>This is OK</b> because <span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is not meant to:</p>
                                <ul>
                                    <li>Test your skills on the basics of how to drive a car.</li>
                                    <li>Copy exactly what it feels like to drive in a real car.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="fbr-cntnt">
                            <div className="fbr-sct-hdr-v2">What We Really Care About:</div>
                            <div className="fbr-sct-cntnt">
                                <ul>
                                    <li>How are your safe driving choices?</li>
                                    <li>Are your driving choices safe, even if you did not crash or have another poor result?</li>
                                    <li>Do you spot hazards that could happen on the road, and change how you are driving in the moment?</li>
                                </ul>
                            </div>
                        </div>
                        <div className="fbr-cntnt">
                            <div className="fbr-sct-hdr-v2">We Compare How You Perform to Other Drivers Who Took the Same Test</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                    You may have felt that <i>Ready-Assess</i>&#8482; was “harder” than you thought it would be. Maybe you thought…
                                </p>
                                <div className="fbr-cntr-p">
                                    <p><i>How is this testing my skills?</i></p>
                                    <p>or</p>
                                    <p><i>Based on this test, I must be an awful driver.</i></p>
                                </div>
                            </div>
                            <div className="hilite">
                                <div className="hilite-box-v2"></div>
                                <p>
                                    If you did, do not worry.  <b>We compare how you did to lots of other drivers who took this same test.</b>  Many of them had the same experiences with this test that you did.
                                </p>
                                <p>
                                    At the end of the day, it is not about “doing better than everyone else.”  It is about knowing that you always have room to get better. Please continue to the next pages to see what we uncovered together about your driving.
                                </p>
                                <p>
                                    <b>And remember – you complete <i>Ready-Assess</i>&#8482; by watching the safe driving videos provided for you on page {4 + isNJM} of this report.</b>
                                </p>
                            </div>
                        </div>
                        <div className="fbr-ftr-blrb"><span  className="ra_inln"><i>Ready-Assess</i>&#8482;</span> is a tool. It should not replace the advice of a driving instructor.  You can choose to share this report with your driving instructor your parents, your supervisor or your doctor.</div>
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>)}
                {
                    isNJM ?
                    this.makeStep("A WORD FROM OUR SPONSOR", 3, <div>
                        <div className="fbr-flush-top fbr-purp display-7">{this.renderStepActions()}</div>
                        <span className="page-number">3</span>
                        <div className='smry-content' style={{paddingTop:(screen.width > 479 ? 420 : 169)}}>
                            <div className="page-3-only-logo"><img className="page-3-only-img"  alt="" src={page3Logo} /></div>
                            <div className="page-3-only-content">{page3CustomCopy}</div>
                        </div>
                    </div>) :
                    null
                }
                {this.makeStep("SUMMARY OF RESULTS", 3 + isNJM, <div>
                    <span className="page-number">{3 + isNJM}</span>
                    <div className="fbr-flush-top fbr-orng display-7">SUMMARY OF RESULTS{this.renderStepActions()}</div>
                    <div className={"smry-content" + (isStep ? " smry-cntnt-step" : "")} style={{paddingTop:20}}>
                        <div className="smry-sct-hdr fift">
                            <b>Your Score:</b>
                        </div>
                        {isStep ? 
                        
                        <div className='step-above-bell'>
                                Your score is <b className='scoreBold'>{OVRL_RSPNS[getRegion(feedbackData.results.zScore)]}</b>. Out of 100 people who took the same test, <b className='scoreBold'>{getBetterPeople(feedbackData)}</b> got a better score than you.
                        </div>

                        : 
                        <div className="smry-sct-cntnt fift">
                            <div className='wideHiLite'></div>
                            <div className='widehilited'>
                                Your score is <b className='scoreBold'>{OVRL_RSPNS[getRegion(feedbackData.results.zScore)]}</b>. Out of 100 people who took the same test, <b className='scoreBold'>{getBetterPeople(feedbackData)}</b> got a better score than you.
                            </div>
                        </div>
                        }
                        
                        <div className="score-chart-box">
                            <div className="score-chart">
                                <img className="bell-curve" width={screen.width > 479 ? "680" : "348"}  alt="" src={BellCurve} />
                                <div className="arrow-box">
                                    <div className="arrow-inner">
                                        <img className="score-arrow" width="10" style={{left: getArrowXOffset(feedbackData.results.zScore, (screen.width > 479 ? 340 : 174), (screen.width > 479 ? 96 : 50))}} alt="" src={Arrow} />
                                    </div>
                                        
                                </div>


                                {
                                    isStep ?
                                    <div className='step-score-chart-caption'>
                                        <div className='step-scc-hdr'>
                                            Remember:  Watch the videos chosen for you, which are on the next page of this report.
                                        </div>
                                        <div className='step-scc-body'>
                                            <div className='row'>
                                                <div className='col-sm-4 score-tag-cell'>
                                                    <div className={'vertical-center-tag score-tag score_' + (getRegion(feedbackData.results.zScore))}>{OVRL_TAG[getRegion(feedbackData.results.zScore)]}</div>
                                                </div>
                                                <div className='col-sm-8 score-statement-cell'>
                                                    <div className='vertical-center-stmt score-statement'>{OVRL_TAG_STMT[getRegion(feedbackData.results.zScore)]}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='score-chart-caption'>
                                        <div className='score-chart-caption-positioned-background'>
                                            <div className='score-chart-top-alert'>Remember:  Watch the videos chosen for you, which are on the next page of this report.</div>
                                        </div>
                                        <div className='score-chart-caption-content'>
                                            <div className='row'>
                                                <div className='col-sm-4 score-tag-cell'>
                                                    <div className={'vertical-center-tag score-tag score_' + (getRegion(feedbackData.results.zScore))}>{OVRL_TAG[getRegion(feedbackData.results.zScore)]}</div>
                                                </div>
                                                <div className='col-sm-8 score-statement-cell'>
                                                    <div className='vertical-center-stmt score-statement'>{OVRL_TAG_STMT[getRegion(feedbackData.results.zScore)]}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                }

                                

                            </div>
                        </div>

                        <div className="picscs uapcs">
                            <div className='unsafe-action-container'>
                                <div className="smry-sct-hdr">
                                    <b>Some Unsafe Actions or Driving Choices Linked to Your Score:</b>
                                </div>
                                <div className="error-list">
                                    <div className="err-bx">
                                        <div className="row">
                                            <div className={'err-cb-bx ' + usiLeft}>
                                                <img className="err-cb" alt=""  src={feedbackData.results.GLOBAL_num_ped_collisions ? ErrCrashP : ErrCrashN} />
                                            </div>
                                            <div className={'err-tag ' + usiRight}><span className='err-shift'>Crashing into a pedestrian</span></div>
                                        </div>
                                    </div>
                                    <div className="err-bx">
                                        <div className="row">
                                            <div className={'err-cb-bx ' + usiLeft}>
                                                <img className="err-cb" alt=""  src={feedbackData.results.GLOBAL_num_veh_collisions ? ErrCrashP : ErrCrashN} />
                                            </div>
                                            <div className={'err-tag ' + usiRight}><span className='err-shift'>Crashing into a vehicle</span></div>
                                        </div>
                                    </div>
                                    <div className="err-bx">
                                        <div className="row">
                                            <div className={'err-cb-bx ' + usiLeft}>
                                                <img className="err-cb" alt=""  src={feedbackData.results.GLOBAL_num_static_collisions || feedbackData.results.GLOBAL_num_misc_obj_collisions ? ErrCrashP : ErrCrashN} />
                                            </div>
                                            <div className={'err-tag ' + usiRight}><span className='err-shift'>Crashing into an object</span></div>
                                        </div>
                                    </div>
                                    <div className="err-bx">
                                        <div className="row">
                                            <div className={'err-cb-bx ' + usiLeft}>
                                                <img className="err-cb" alt=""  src={hasInfraction("red_light_infraction", feedbackData) ? ErrRedP : ErrRedN} />
                                            </div>
                                            <div className={'err-tag ' + usiRight}><span className='err-shift'>Driving through a red light</span></div>
                                        </div>
                                    </div>
                                    <div className="err-bx">
                                        <div className="row">
                                            <div className={'err-cb-bx ' + usiLeft}>
                                                <img className="err-cb" alt=""  src={hasInfraction("stop_sign_infraction", feedbackData) ? ErrStopP : ErrStopN} />
                                            </div>
                                            <div className={'err-tag ' + usiRight}><span className='err-shift'>Driving through a stop sign</span></div>
                                        </div>
                                    </div>
                                    <div className="err-bx">
                                        <div className="row">
                                            <div className={'err-cb-bx ' + usiLeft}>
                                                <img className="err-cb" alt=""  src={hasInfraction("navigation_infraction", feedbackData) ? ErrLostP : ErrLostN} />
                                            </div>
                                            <div className={'err-tag ' + usiRight}><span className='err-shift'>Not following the driving directions</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='turnPageOut'>
                                <img className="turnPage" alt=""  src={KeepReading} />
                            </div>
                        </div>
                        
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>)}
                {this.makeStep("VIDEOS CHOSEN JUST FOR YOU", 4 + isNJM, <div>
                    <span className="page-number">{4 + isNJM}</span>
                    <div className="fbr-flush-top fbr-teal display-7">VIDEOS CHOSEN JUST FOR YOU{this.renderStepActions(() => {return this.state.completedAllVideos;})}</div>
                    

                    <div className="video-page-content">
                        <div className="wshd vid-tt">
                            <div className="cntr">
                                <div className="gca-hdr">Safe Driving Videos To Watch</div>
                            </div>
                        </div>

                        <div className="fbr-cntnt gca-box vid-prs">
                            <div className="spacer"><div className="fbr-sct-hdr-v2">Guidance and Instruction Based on Your <i>Ready-Assess</i>&#8482; Results:</div></div>
                            <div className="fbr-sct-cntnt">
                                <p>The links below will connect you to easy, short, and helpful safe driving videos. <b>We chose these videos for you based on the results of your test.</b> They are proven to help people build upon their driving skills.</p>

                                <p>After you have watched each video, you will have completed <i>Ready-Assess</i>&#8482;.</p>

                                {
                                    (feedbackData.age >= 18 || feedbackData.age == -2000) ?
                                    <p>
                                        Note - the content below was originally created to help parents and their teenage drivers get ready for the road. We understand that you are not a teen. Please know that these links below provide information that is helpful to drivers of ALL ages.
                                    </p> : null
                                }

                                
                            </div>
                            {
                                this.state.isStep ?
                                <RequiredVideoBox aid={aid} assessmentData={assessmentData} videos={feedbackData.videos} onComplete={()=>{
                                    this.setState({completedAllVideos: true});
                                    //NB: report completed all videos
                                }} /> :
                                <div className="video-link-box">
                                    <div className="fbr-sct-orng bigger-vid">Your Assigned Videos</div>
                                    <div className="fbr-sct-cntnt vid-div">
                                        {(function() {


                                            let videoPairs = feedbackData.videos.reduce(function(result, value, index, array) {
                                                if (index % 2 === 0)
                                                    result.push(array.slice(index, index + 2));
                                                return result;
                                            }, []);
                                            let videoRows = [];
                                            for (var i = 0; i < videoPairs.length; i++) {
                                                let pair = videoPairs[i];
                                                let odd = i % 2;
                                                videoRows.push(<div key={"vr-" + i} className={"video-row row" + (odd ? "" : " vrOdd")}>
                                                                        <div className="vid col-sm-6">
                                                                            {pair[0].anchor}
                                                                        </div>
                                                                        { pair[1] ?  <div className="vid col-sm-6">
                                                                                        {pair[1].anchor}
                                                                                    </div> : null }
                                                                        
                                                                    </div>) 
                                            };

                                            return videoRows;
                                        })()}
                                    </div>
                                </div>
                            }
                            

                        </div>

                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>)}
                {this.makeStep("DETAILED RESULTS", 5 + isNJM, <div>
                    <span className="page-number">{5 + isNJM}</span>
                    <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS{this.renderStepActions()}</div>
                    <div className="dtl-table wshd">
                        <div className="cntr">
                            <div className="smry-hdr">How You Did in Common Crash Scenarios</div>
                        </div>
                        <div className="fbr-wht">
                            <div className="row">
                                <div className={isMbl ? "col-xs-5" : "col-sm-4"}>
                                    <div className="dtl-tbl-hdr-v2">Crash Scenario</div>
                                </div>
                                <div className={isMbl ? "col-xs-7" : "col-sm-8"}>
                                    <div className="dtl-tbl-hdr-v2">Things To Think About.</div>
                                </div>
                            </div>
                        </div>

                        {
                            (function () {
                                let rows = [];
                                for (var i = 0; i < feedbackData.results.common_crashes.length; i++) {
                                    let commonCrash = feedbackData.results.common_crashes[i];
                                    let copy = COMMON_CRASH_COPY[commonCrash.name];
                                    rows.push(<div key={commonCrash.name + i} className={i % 2 ? 'fbr-wht' : ''}>

                                                <div className='cocr-v2-box'>
                                                    
                                                    <div className={'common-crash-title-bar ' + (commonCrash.show_handled_well ? 'cc-ok' : 'cc-ng')}>
                                                        <span className='cc-title'>{copy.title}</span><span className='cc-example'>{copy.example}</span>
                                                    </div>
                                                    
                                                    <div className='cocr-v2-content-box inf-flex'>
                                                        <div className='cocr-v2-icon-box cocr-flex-a'>
                                                            <div className='invisible-infraction-icon'>

                                                                <img className="dtl-img-v4" alt="" src={copy.icon} />

                                                            </div>
                                                            <div className='vertical-center-icon'>
                                                                <img className="dtl-img-v4" alt="" src={copy.icon} />
                                                            </div>
                                                        </div>
                                                        <div className='cocr-v2-sum-box cocr-flex-b'>
                                                            <div className='invisible-cocr-sum'>
                                                                {
                                                                    commonCrash.show_in_crash_column ? 
                                                                        <div className={"thngs-hd-v2 outcome"}>
                                                                            <div>
                                                                                { false ? <img className="crash-bang" alt="" src={CrashBang} /> : <Warning style={{width: 36, height: 36}} color="#B02C19" className="danger-icon" />}
                                                                            </div>
                                                                            <div>NEEDS WORK</div>
                                                                        </div> : 
                                                                            (
                                                                                commonCrash.show_in_danger_column ? 
                                                                                <div className={"thngs-hd-v2 outcome"}>
                                                                                    <div>
                                                                                        <Warning style={{width: 36, height: 36}} color="#B02C19" className="danger-icon" />
                                                                                    </div>
                                                                                    <div>NEEDS WORK</div>
                                                                                </div> : 
                                                                                    <div className={"thngs-hd-v2 outcome"}>
                                                                                        HANDLED WELL
                                                                                    </div>
                                                                            )
                                                                }
                                                            </div>
                                                            <div className='vertical-center-cocr-sum'>
                                                                {
                                                                        commonCrash.show_in_crash_column ? 
                                                                            <div className={"thngs-hd-v2 outcome"}>
                                                                                <div>
                                                                                    { false ? <img className="crash-bang" alt="" src={CrashBang} /> : <Warning style={{width: 36, height: 36}} color="#B02C19" className="danger-icon" />}
                                                                                </div>
                                                                                <div>NEEDS WORK</div>
                                                                            </div> : 
                                                                                (
                                                                                    commonCrash.show_in_danger_column ? 
                                                                                    <div className={"thngs-hd-v2 outcome"}>
                                                                                        <div>
                                                                                            <Warning style={{width: 36, height: 36}} color="#B02C19" className="danger-icon" />
                                                                                        </div>
                                                                                        <div>NEEDS WORK</div>
                                                                                    </div> : 
                                                                                        <div className={"thngs-hd-v2 outcome"}>
                                                                                            HANDLED WELL
                                                                                        </div>
                                                                                )
                                                                    }
                                                            </div>
                                                        </div>
                                                        <div className='cocr-v2-content cocr-flex-c'>
                                                            <div className='invisible-cocr-statement'>
                                                                {
                                                                    commonCrash.show_handled_well ?

                                                                    <div className="dtl-tbl-cell thngs">
                                                                        <div  className="thngs-cntnt-v2">
                                                                            {copy.handledWell}
                                                                        </div>
                                                                    </div> :

                                                                    ( commonCrash.show_in_crash_column ? 
                                                                        <div className="dtl-tbl-cell thngs">
                                                                            <div  className="thngs-cntnt-v2">
                                                                                {copy.crashed}
                                                                            </div>
                                                                        </div> :
                                                                        <div className="dtl-tbl-cell thngs">
                                                                            <div  className="thngs-cntnt-v2">
                                                                                {copy.danger}
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                }
                                                            </div>
                                                            <div className='vertical-center-cocr-statement'>
                                                                {
                                                                    commonCrash.show_handled_well ?

                                                                    <div className="dtl-tbl-cell thngs">
                                                                        <div  className="thngs-cntnt-v2">
                                                                            {copy.handledWell}
                                                                        </div>
                                                                    </div> :

                                                                    ( commonCrash.show_in_crash_column ? 
                                                                        <div className="dtl-tbl-cell thngs">
                                                                            <div  className="thngs-cntnt-v2">
                                                                                {copy.crashed}
                                                                            </div>
                                                                        </div> :
                                                                        <div className="dtl-tbl-cell thngs">
                                                                            <div  className="thngs-cntnt-v2">
                                                                                {copy.danger}
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='cc-space'></div>
                                                    </div>
                                                </div>


                                            </div>)
                                };
                                return rows;
                            })()
                        }
                    </div>
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>)}
                {(function () {
                    console.log("***** INFRACTION PAGES", feedbackData.infractionPages);
                    let infractionPages = [];

                    let pageIndex = 7 + isNJM;

                    for (var i = 0; i < feedbackData.infractionPages.length; i++) {
                        let theseInfractions = feedbackData.infractionPages[i];
                        infractionPages.push(
                            this.makeStep("DETAILED RESULTS", (pageIndex - 1), <div>
                                <span className="page-number">{pageIndex - 1}</span>
                                <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS{this.renderStepActions()}</div>
                                <div className="dtl-table wshd">
                                    <div className="cntr">
                                        <div className="infr-hdr">Become a Safer Driver:  Some Areas for Improvement</div>
                                    </div>
                                    <div className="fbr-wht">
                                        <div className="row">
                                            <div className="col-sm-12 centr">
                                                <div className="dtl-tbl-hdr-v2"><div className="dtl-inner-v2 dtl-sdi">Specific Areas to Focus On, and Tips to Improve in the Future</div></div>
                                            </div>
                                        </div>
                                    </div>
                                    {theseInfractions}
                                    
                                </div>
                                <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                            </div>)

                        )
                        pageIndex++;
                    };

                    return infractionPages;

                }).bind(this)()}
                {this.makeStep("HELPFUL RESOURCES", 6 + isNJM + feedbackData.infractionPages.length, <div>
                    <span className="page-number">{((7 + isNJM) + feedbackData.infractionPages.length) - 1}</span>
                    <div className="meta-info"><div className="row"><div className="col-sm-10 aidlbl">{aid}</div><div className="col-sm-2">{feedbackData.groupId}</div></div></div>
                    <div className="fbr-flush-top fbr-teal display-7">DETAILED RESULTS{this.renderStepActions()}</div>


                    <div className="dtl-content">
                        <div className="wshd">
                            <div className="cntr">
                                <div className="gca-hdr">Helpful Resources</div>
                            </div>
                        </div>

                        <div className="fbr-cntnt gca-box">
                            <div className="fbr-sct-hdr-v2">A Practice Plan Based on Years of Crash-Avoidance Research:</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                Certified driving instructors are a key part of learning how to drive safely. You also should practice at home. To do that well, it is helpful to have a plan to follow. This should include time spent driving in varied:
                                </p>
                                <ul>
                                    <li>Routes and roads.</li>
                                    <li>Types of weather.</li>
                                    <li>Road conditions.</li>
                                </ul>
                                <p>If you need help coming up with a plan like this, think about the following as a great place to start:</p>
                                <p><a href="https://teendriversource.research.chop.edu/learning-to-drive/practice-driving-lessons" target="_blank">Practice Driving Lessons</a></p>
                            </div>
                            <div className="fbr-sct-hdr-v2">And Always Remember:  Keep the Focus on Driving!</div>
                            <div className="fbr-sct-cntnt">
                                <p>
                                It is vital for new drivers to stay focused, as driving is a new skill. You should cut down on anything that takes your focus away from the road like:
                                </p>
                                <ul>
                                    <li>Riders in the car with you.</li>
                                    <li>Electronics (cell phones).</li>
                                    <li>Other distractions (loud music).</li>
                                </ul>
                                <p>
                                Here is a helpful video on the topic of distracted driving (Managing Distractions Inside and Outside the Car). And to learn more about safe driving and stopping crashes, see <a href="https://teendriversource.research.chop.edu" target="_blank">TeenDriverSource.org</a>.
                                </p>
                                <p><a href="https://teendriversource.research.chop.edu/teen-crash-risks-prevention/car-accident-prevention" target="_blank">Car Accident Prevention</a></p>
                            </div>
                        </div>


                        <div className="rounded-hilite">
                            
                                
                                <p>A final reminder - don’t forget to watch the specific safe driving videos we chose for you.  They are proven to help people become safer drivers.</p> 
                                <p className='partdeux'>Watch all of the videos provided on page {4 + isNJM} of this report and you will have successfully completed <i>Ready-Assess</i>&#8482;.</p>

                            
                        </div>

                    </div>



                    
                    <img className="bottom-ra-logo" alt="" src={ReadyAssessLogo} />
                </div>)}
                
            </ConditionalWrapper>
        );
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        assessments: appState.assessments,
        intakeForms: appState.intakeForms,
        orgs: appState.orgs,
        staticFeedback: appState.staticFeedback
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        trackNextPage: function (pageNumber) { dispatch( actions.trackNextPage(pageNumber)) },
      }
}

const RoutableFeedbackReport = withRouter(FeedbackReport);
export default connect(mapStateToProps, mapDispatchToProps)(RoutableFeedbackReport);