import { C } from '../../constants';

const initialState = { 
    data: {},
    unlocked: {},
    locked: {},
    unlockedLoaded: false,
    lockedLoaded: false
};

function updateAssessment(state, key, value) {
    let newEntry = {};
    newEntry[key] = value;
    return Object.assign({}, state.data, newEntry);
}


export default (state = initialState, action) => {
    let entry = {};
  switch (action.type) {
    case C.RECEIVE_ASSESSMENT_DATA:
        return Object.assign({}, state, {
            data: updateAssessment(state, action.aid, { hasReceivedData: true, value: action.data})
        });

    case C.AWAIT_ASSESSMENT_DATA:
        return Object.assign({}, state, {
            data: updateAssessment(state, action.aid, { hasReceivedData: false })
        })

    case C.RECEIVE_UNLOCKED_ASSESSMENTS:
        return Object.assign({}, state, {
            unlocked: action.data,
            unlockedLoaded: true
        })

    case C.RECEIVE_LOCKED_ASSESSMENTS:
        return Object.assign({}, state, {
            locked: action.data,
            lockedLoaded: true
        })


      default:
        return state;
  }
}