import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField } from 'redux-form-material-ui';


const validate = function (values) {
    const errors = {};
    if (!values.address) {
        errors.address = "Address is required"
    }
    if (!values.city) {
        errors.city = "City is required"
    } 
    if (!values.zip) {
        errors.city = "Zip code is required"
    } else {
        //validate zip?
    }
    return errors;
}

const getNumericOptions = function () {
    return [
                <MenuItem value={1} key={1} primaryText={"1"} />,
                <MenuItem value={2} key={2} primaryText={"2"} />,
                <MenuItem value={3} key={3} primaryText={"3"} />,
                <MenuItem value={4} key={4} primaryText={"4"} />,
                <MenuItem value={5} key={5} primaryText={"5"} />,
                <MenuItem value={6} key={6} primaryText={"6"} />,
                <MenuItem value={7} key={7} primaryText={"7"} />,
                <MenuItem value={8} key={8} primaryText={"8"} />,
                <MenuItem value={9} key={9} primaryText={"9"} />,
                <MenuItem value={10} key={10} primaryText={"10"} />
            ]
}

const stateMap = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

const getStateOptions = function () {
    return _.map(stateMap, (name, code) => {
        return <MenuItem value={code} key={code} primaryText={name} />
    })
}

class AddLoc extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.props.initialize({district: 1, state: 'OH'});
    }

    render () {

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="row add-loc-form">
                    <div className="col-sm-12">
                        <Field
                            name="district"
                            fullWidth={true}
                            component={SelectField}
                            floatingLabelText="District">
                            {
                                getNumericOptions()
                            }
                        </Field>
                    </div>
                    <div className="col-sm-12">
                        <Field 
                            name="address"
                            component={TextField}
                            fullWidth={true}
                            multiLine={true}
                            hintText={"Address for this location"}
                            floatingLabelText="Address" />
                    </div>
                    <div className="col-sm-4">
                        <Field 
                            name="city"
                            component={TextField}
                            hintText={"City for this location"}
                            floatingLabelText="City" />
                        </div>
                    <div className="col-sm-4">
                        <Field
                            name="state"
                            value="OH"
                            component={SelectField}
                            floatingLabelText="State">
                            {
                                getStateOptions()
                            }
                        </Field>
                    </div>
                    <div className="col-sm-4">
                        <Field 
                            name="zip"
                            component={TextField}
                            hintText={"Zip code for this location"}
                            floatingLabelText="Zip Code" />
                    </div>
                    <div className="col-sm-12">
                        <RaisedButton
                            primary
                            disabled={this.props.pristine}
                            label="Add Location"
                            type="submit" />
                    </div>
                </div>
            </form>
        )
    }
}

// Decorate the form component
AddLoc = reduxForm({
  form: 'addLoc', // a unique name for this form
  validate
})(AddLoc);

export default AddLoc;