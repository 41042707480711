import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import _ from 'lodash';
import { destroy, reset } from 'redux-form';
import { C } from '../constants';


const storageRef = firebase.storage().ref();
const stepsRef = C.DB.ref('coachingsteps');
const surveysRef = C.DB.ref('coachingsurveys');
const responseRef = C.DB.ref('coachingresponses');
const orgsRef = C.DB.ref('organizations');
const usersRef = C.DB.ref('users');
const assignmentsRef = C.APP.database().ref('assignments');
const driversRef = C.APP.database().ref('customers');

const coachingItemRef = C.DB.ref('coachingItems');
const coachingPagesRef = C.DB.ref('coachingPages');
const coachingSurveysRef = C.DB.ref('coachingSurveys');
const intakeSurveyOptionsRef = C.DB.ref('intakeSurveyOptions');
const coachRef = C.DB.ref('coachingResults');
const intakeRef = C.DB.ref('intakeforms');
const openIntakes = C.DB.ref('openIntakes');
const openIntakeIds = C.DB.ref('intakeMap');

const assessmentsRef = C.DB.ref('assessments');

export const submitIntakeSurvey = function (sid, s, router, unity, intakeKeyForOpen, mgrId, driverId) {
    return function (dispatch, getState) {
        submitSurvey(dispatch, getState, sid, s, router, null, null, true, unity, intakeKeyForOpen, mgrId, driverId);
    }
}

export const submitCoachingSurvey = function (sid, s, router, assignmentId, assignment) {
    return function (dispatch, getState) {
        submitSurvey(dispatch, getState, sid, s, router, assignmentId, assignment, false);
    }
}

export const setPrescriptedSurvey = function () {
    return function (dispatch, getState) {
        const formValues = getState().form.surveyPrescription.values;
        console.log("setting surveyId", formValues.surveyId);
        dispatch({ type: C.SET_PRESCRIBED_SURVEY, surveyId: formValues.surveyId});
    }
}

export const chooseSurvey = function () {
    return function (dispatch, getState) {
        const formValues = getState().form.csf.values;
        dispatch({ type: C.SET_CHOSEN_SURVEY, option: formValues.surveyChoice});
    }
}

export const submitPostDriveSurvey = function (sid, s, router, unity, aid) {
    return function (dispatch, getState) {
        console.log("submitting PDS", aid);
        if (aid) {
            dispatch({ type: C.SUBMITTING_COACHING });
            const formValues = getState().form.coaching.values;
            const coachingItems = getState().coachingItems.data;
            let postDriveSurveyResponses = Object.assign({}, formValues);
            let answerArray = [];
            _.map(getState().form.coaching.registeredFields, (data, name) => {
                // We want to know explicitly if a certain field had no data (for non-required fields)
                if (name.split('.').length < 2) {
                    postDriveSurveyResponses[name] = postDriveSurveyResponses[name] || {"noValue": true}
                }
                answerArray.push(postDriveSurveyResponses[name]);
            });
            assessmentsRef.child(aid).update({
                survey: answerArray,
                postDriveSurveyResponses
            }).then(() => {
                if (unity) {
                    if (window.sendPostDriveSuccess) {
                        window.sendPostDriveSuccess("PDS saved to " + aid);
                    }
                    router.replace('/blank');
                } else {
                    console.log("re-routing to main page"); //this is just for testing anyway
                    router.replace('/');
                }
            });
        }
        
    }
}

function submitSurvey (dispatch, getState, sid, s, router, assignmentId, assignment, isIntake, unity, intakeKeyForOpen, mgrId, driverId) {
    dispatch({ type: C.SUBMITTING_COACHING });
    const formValues = getState().form.coaching.values;
    const coachingItems = getState().coachingItems.data;
    const uid = getState().auth.uid;
    const record = isIntake ? (intakeKeyForOpen ? openIntakes.push() : intakeRef.push()) : coachRef.push();
    let surveyResponses = Object.assign({}, formValues);
    _.map(getState().form.coaching.registeredFields, (data, name) => {
        // We want to know explicitly if a certain field had no data (for non-required fields)
        if (name.split('.').length < 2) {
            surveyResponses[name] = surveyResponses[name] || {"noValue": true}
        }
    });

    let wrapper = {
        timestamp: C.TS,
        user: uid,
        survey: sid,
        surveyName: s.name,
        surveyDescription: s.description,
        responses: surveyResponses
    }


    wrapper.driverId = driverId || null;
    wrapper.mgrId = mgrId || null;


    record.set(wrapper).then(() => {
        // This is for adding to the list of assessments for the driver
        const recordUpdate = {}
        recordUpdate[record.key] = C.TS;
        let updateData = {};
        if (isIntake) {
            updateData.lastIntake = record.key;
            updateData.lastIntakeTime = C.TS;
        } else {
            updateData.lastCoachingResponse = record.key;
            updateData.lastCoachingResponseTime = C.TS;
        }
        // when we are "open" we just stop heree
        if (intakeKeyForOpen) {
            if (unity) {
                window.sendIntakeSuccess(record.key);
                router.replace('/blank');
            } else {
                console.log("re-routing to intake success page")
                router.replace('/');
            }
        } else {

            let root = usersRef.child(uid);
            if (driverId) {
                root = driversRef.child(getState().auth.oid + "/" + driverId);
            }

            root.update(updateData).then(() => {
                root.child(isIntake ? "intakeforms" : "coachingResponses").update(recordUpdate).then(() => {
                    dispatch({ type: C.COACHING_SUBMISSION_RECEIVED });
                    if (isIntake) {
                        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%Are we from unity?", unity);
                        if (unity) {
                            window.sendIntakeSuccess(record.key);
                            router.replace('/blank');
                        } else {
                            console.log("re-routing to intake success page")
                            router.replace('/intakesuccess');
                        }
                    } else {
                        
                        assignmentsRef.child(assignmentId).update({completed: C.TS}).then(() => {
                            usersRef.child(uid).update({currentlyAssigned : null}).then(() => {
                                router.replace('/coachingsuccess');
                            });
                        });
                    }
                })
            })
        }
        dispatch(destroy('coaching'));
    });
}

export const startListeningIntakeSurveyOptions = function () {
    return function (dispatch, getState) {
        console.log("START LISTENING TO INTAKE SURVEY OPTIONS")
        intakeSurveyOptionsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_INTAKE_SURVEY_OPTIONS_DATA, data: snap.val()});
        })
    }
}

export const startListeningToCoachingSurveys = function () {
    return function (dispatch, getState) {
        console.log("START LISTENING TO COACHING SURVEYS")
        coachingSurveysRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_COACHING_SURVEYS_DATA, data: snap.val()});
        })
    }
}

export const startListeningToCoachingPages = function () {
    return function (dispatch, getState) {
        console.log("START LISTENING TO COACHING Pages")
        coachingPagesRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_COACHING_PAGES_DATA, data: snap.val()});
        })
    }
}

export const startListeningToCoachingItems = function () {
    return function (dispatch, getState) {
        console.log("START LISTENING TO COACHING ITEMS")
        coachingItemRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_COACHING_ITEMS_DATA, data: snap.val()});
        })
    }
}

export const addCoachingSurvey = function () {
    return function (dispatch, getState) {
        console.log("ADD COACHING SURVEY")
        const formValues = getState().form.coachingsurvey.values;
        const coachingSurvey = coachingSurveysRef.push();
        const newSurvey = {
            name: formValues.name,
            description: formValues.description,
            type: formValues.type,
            numberOfPages: formValues.numberOfPages,
            pages: []
        }

        for (let i of Array(formValues.numberOfPages).keys()) {
            newSurvey.pages.push(formValues["page_" + i]);
            //pages need to knkw if they are being used by any surveys
            let entry = {};
            entry[coachingSurvey.key] = formValues.name;
            coachingPagesRef.child(formValues["page_" + i]).child('usedBy').update(entry);
        }

        dispatch({type: C.AWAIT_NEW_COACHING_SURVEY_RESPONSE});
        coachingSurvey.set(newSurvey).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_SURVEY_RESPONSE});
            dispatch(reset('coachingsurvey'));
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_SURVEY_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching survey!"}) //TODO: better errors
        });

    }
}

export const addCoachingPage = function () {
    return function (dispatch, getState) {
        console.log("ADD COACHING PAGE")
        const formValues = getState().form.coachingpage.values;
        const coachingPage = coachingPagesRef.push();
        const newPage = {
            name: formValues.name,
            description: formValues.description,
            numberOfItems: formValues.numberOfItems,
            items: []
        }

        for (let i of Array(formValues.numberOfItems).keys()) {
            console.log("what is i?", i);
            newPage.items.push({coachingItem: formValues["coaching_item_" + i], layout: formValues["layout_" + i]});
            // coaching items need to know if they are being used
            let entry = {};
            entry[coachingPage.key] = formValues.name;
            coachingItemRef.child(formValues["coaching_item_" + i]).child('usedBy').update(entry);
        }

        dispatch({type: C.AWAIT_NEW_COACHING_PAGE_RESPONSE});
        coachingPage.set(newPage).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_PAGE_RESPONSE});
            dispatch(reset('coachingpage'));
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_PAGE_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching page!"}) //TODO: better errors
        });

    }
}

export const addCoachingItem = function () {
    return function (dispatch, getState) {
        const formValues = getState().form.addQuestion.values;
        const newQuestion = {
            name: formValues.shortname,
            type: formValues.type,
            text: formValues.text,
            required: Boolean(formValues.required)
        }
        if (formValues.type == "multhorz") {
            newQuestion.leftText = formValues.lefttext;
            newQuestion.rightText = formValues.righttext;
        }
        if (["multvert", "multhorz", "allthatapply"].includes(formValues.type)) {
            newQuestion.numberOfChoices = formValues.numberofchoices;
            let choices = [];
            for (let i of Array(formValues.numberofchoices).keys()) {
                let num = i + 1;
                // newQuestion["choicevalue-" + num] = formValues["choicevalue-" + num];
                // newQuestion["choicedisplay-" + num] = formValues["choicedisplay-" + num];
                choices.push({display: formValues["choicedisplay-" + num], value: formValues["choicevalue-" + num]})
            }
            newQuestion.choices = choices;
        }
        if (formValues.type == "allthatapply") {
            newQuestion.includeOther = Boolean(formValues.includeother);
        }
        if (formValues.type == "video") {
            newQuestion.video = formValues.video;
        }
        dispatch({type: C.AWAIT_NEW_COACHING_ITEM_RESPONSE});
        coachingItemRef.push(newQuestion).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_ITEM_RESPONSE});
            dispatch(reset('addQuestion'));
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_ITEM_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching item!"}) //TODO: better errors
        });
    }
}

export const wrapCoachingItem = function (qid, qName) {
    return function (dispatch, getState) {
        const coachingPage = coachingPagesRef.push();
        const newPage = {
            name: qName,
            description: "Wrapper page for " + qName,
            numberOfItems: 1,
            items: [{coachingItem: qid, layout: "full"}]
        }
        coachingPage.set(newPage).then((snap) => {
            let entry = {};
            entry[coachingPage.key] = qName;
            coachingItemRef.child(qid).child('usedBy').update(entry);
        }).catch((error) => {
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to wrap coaching item in page!"}) //TODO: better errors
        });

    }
}

export const deleteCoachingItem = function (qid) {
    return function (dispatch, getState) {
        coachingItemRef.child(qid).set(null);
    }
}

export const deleteCoachingPage = function (cpid, cp) {
    return function (dispatch, getState) {
        // need to iterate through items and remove this page from usedBy
        for (var i = 0; i < cp.items.length; i++) {
            const item = cp.items[i].coachingItem;
            let entry = {};
            entry[cpid] = null;
            coachingItemRef.child(item).child("usedBy").update(entry);
        }
        coachingPagesRef.child(cpid).set(null);
    }
}

export const deleteCoachingSurvey = function (csid, cs) {
    return function (dispatch, getState) {
        // need to iterate through items and remove this page from usedBy
        for (var i = 0; i < cs.pages.length; i++) {
            const page = cs.pages[i]
            let entry = {};
            entry[csid] = null;
            coachingPagesRef.child(page).child("usedBy").update(entry);
        }
        coachingSurveysRef.child(csid).set(null);
    }
}

export const deleteintakeSurveyOption = function (isoId, iso) {
    return function (dispatch, getState) {
        // need to iterate through items and remove this page from usedBy
        // WHAT ABOUT THE ORGS?
        // Make sure that items and surveys know they are used by this intake option?
        // for (var i = 0; i < cs.pages.length; i++) {
        //     const page = cs.pages[i]
        //     let entry = {};
        //     entry[csid] = null;
        //     coachingPagesRef.child(page).child("usedBy").update(entry);
        // }
        // coachingSurveysRef.child(csid).set(null);
        let entry = {};
        entry[isoId] = null;
        if (iso.survey) {
            coachingSurveysRef.child("usedBy").update(entry);
        }
        if (iso.surveySelectQuestion) {
            coachingItemRef.child("usedBy").update(entry);
        }
        intakeSurveyOptionsRef.child(isoId).set(null);
    }
}

export const addIntakeSurveyOption = function () {
    return function (dispatch, getState) {
        console.log("ADD INTAKE SURVEY OPTION")
        const formValues = getState().form.iso.values;
        const intakeSurveyOption = intakeSurveyOptionsRef.push();
        const newOption = {
            name: formValues.name,
            displayText: formValues.optionDisplayText,
            instructionText: formValues.instructionText,
            survey: formValues.survey || null,
            surveySelectQuestion: formValues.surveySelectQuestion || null
        }

        dispatch({type: C.AWAIT_NEW_INTAKE_SURVEY_OPTION_RESPONSE});
        let entry = {};
        entry[intakeSurveyOption.key] = formValues.name;
        let updateUsedBy = [];
        if (formValues.survey) {
            updateUsedBy.push(coachingSurveysRef.child(formValues.survey).child('usedBy').update(entry));
        }
        if (formValues.surveySelectQuestion) {
            updateUsedBy.push(coachingItemRef.child(formValues.surveySelectQuestion).child('usedBy').update(entry));
        }
        intakeSurveyOption.set(newOption).then((snap) => {
            Promise.all(updateUsedBy).then(results => {
                dispatch({type:C.RECEIVE_NEW_INTAKE_SURVEY_OPTION_RESPONSE});
                dispatch(reset('iso'));
            });
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_INTAKE_SURVEY_OPTION_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching page!"}) //TODO: better errors
        });

    }
}


export const startListeningToCoachingSteps = function () {
    return function (dispatch, getState) {
        stepsRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_COACHING_STEP_DATA, data: snap.val()});
        });
    }
}

// export const startListeningToCoachingSurveys = function () {
//     return function (dispatch, getState) {
//         surveysRef.on('value', function (snap) {
//             dispatch({ type: C.RECEIVE_COACHING_SURVEY_DATA, data: snap.val()});
//         });
//     }
// }

export const resetCoachingResponseForm = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.START_COACHING_RESPONSE });
    }
}

export const startListeningToCoachingResponses = function () {
    return function (dispatch, getState) {
        surveysRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_COACHING_RESPONSE_DATA, data: snap.val()});
        });
    }
}

export const addCoachingStep = function () {
    return function (dispatch, getState) {
        const state = getState();
        const formValues = state.form.coachingStepForm.values;
        const first = {"v1": formValues.firstvideo, "q1": formValues.firstquestion};
        const second = {"v2": formValues.secondvideo, "q2": formValues.secondquestion};
        const last = {"last" : formValues.lastquestion};

        dispatch({type: C.AWAIT_NEW_COACHING_STEP_RESPONSE});
        stepsRef.push({0: first, 1: second, 2: last}).then((snap) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_STEP_RESPONSE});
            dispatch(reset('coachingStepForm'));
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_STEP_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching step!"}) //TODO: better errors
        } )
    }
}

// export const addCoachingSurvey = function () {
//     return function (dispatch, getState) {
//         const state = getState();
//         const formValues = state.form.createCoachingSurveyForm.values;
//         let label = formValues.label;
//         let surveySteps = {};
//         // make an object that omits the 'numberofsteps' and 'label' properties
//         let onlySteps = _.omitBy(formValues, 'numberofsteps', 'label');

//         //get the values of the children nodes in each step and push them to surveySteps
//         let i = 0; //counter
//         stepsRef.once("value").then( function(snapshot) {
//             //the promise is fulfilled

//             // iterate through onlySteps 
//             Object.values(onlySteps).forEach( function(step) {
//                 //get the value of this step from firebase
//                 var stepNode = snapshot.val()[step];

//                 // How many parts does this step have? (what's it's size?)
//                 var size = Object.keys(stepNode).length;

//                 // push all parts to the surveySteps object
//                 for (var j=0; j < size; j++) {
//                     surveySteps["step"+i+"_part"+j]=stepNode[j];
//                 }
//                 i++;
//             })

//             // push the surveysSteps object to firebase
//             dispatch({type: C.AWAIT_NEW_COACHING_SURVEY_RESPONSE});
//             surveysRef.push({label, surveySteps}).then(function (snap) {
//                 dispatch({type:C.RECEIVE_NEW_COACHING_SURVEY_RESPONSE});
//                 dispatch(reset('createCoachingSurveyForm'));
//             }).catch(function (error) {
//                 dispatch({type:C.RECEIVE_NEW_COACHING_SURVEY_RESPONSE});
//                 dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching survey!"}) //TODO: better errors
//             })

//         }, function(error) {
//             // promise was not fulfilled
//             console.error(error);
//         })

//     }
// }

export const addCoachingResponse = function () {
    return function (dispatch, getState) {
        const state = getState();
        const uid = getState().auth.uid;
        var responseKey;
        const formValues = state.form.coachingResponseForm.values;
        let coachingResponseData = Object.assign({}, formValues);

        //push the response data to firebase coachingresponses and get the key
        dispatch({type: C.AWAIT_NEW_COACHING_RESPONSE_RESPONSE});
        responseRef.push({coachingResponseData}).then((snap) => {
            responseKey = snap.key;
            dispatch({type:C.RECEIVE_NEW_COACHING_RESPONSE_RESPONSE});
            dispatch(reset('coachingResponseForm'));

            //add the key for lastCoachingResponse to the user
            usersRef.child(uid).child('lastCoachingResponse').set({responseKey});
        }).catch((error) => {
            dispatch({type:C.RECEIVE_NEW_COACHING_RESPONSE_RESPONSE});
            dispatch({type: C.DISPLAY_ERROR, error: "Failed to add coaching step!"}) //TODO: better errors
        } )
    }
}