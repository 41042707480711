import { C } from '../constants';

const contentBaseRef = C.APP.database().ref('content');

export const startListeningToContent = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.AWAIT_NEW_CONTENT_DATA });
        contentBaseRef.on('value', (snap) => {
            dispatch({ type: C.RECEIVE_CONTENT_DATA, data: snap.val()});
        });
    }
}