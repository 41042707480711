import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectField, TextField } from 'redux-form-material-ui';
// import { CSSTransition } from 'react-transition-group';

const validate = values => {
    const errors = {}
    if (!values[ "surveyChoice" ]) {
        errors[ "surveyChoice" ] = 'Required'
    } 

    return errors
}


class ChooseSurveyForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            tick : 0
        }
    }

    componentWillMount () {
        this.timer = setInterval(() => this.setState({
            tick: this.state.tick + 1
        }), 3200);

    }

    componentWillUnmount () {
        clearInterval(this.timer)
    }

    render () {
        
        let instructions = _.map(this.props.org.intakeSurveyOptions, (option, key) => {
                                console.log(option, key, this.props.intakeSurveyOptions)
                                return <span key={key} className="ss-inst">{this.props.intakeSurveyOptions[key].instructionText}</span>
                            })

        return (
            <form onSubmit={this.props.onSubmit}>
                <div className="inTheMiddle">
                    <div className="middledX" style={{backgroundColor : "#FFF"}}>
                            <div style={{padding:30}}>
                                <div style={{margin: "0 auto", width: 750, display: "block", position: "relative", float: "none"}}>

                                    <div className="display-6">
                                        {instructions[this.state.tick % instructions.length]}
                                    </div>
                                    
                                    <div className="ss-slct">
                                        <Field
                                            name="surveyChoice"
                                            fullWidth={true}
                                            component={SelectField}
                                            style={{ verticalAlign: "top"}} >

                                            {_.map(this.props.org.intakeSurveyOptions, (option, key) => {
                                                let optionItem = this.props.intakeSurveyOptions[key];
                                                return <MenuItem value={optionItem} key={key} primaryText={optionItem.displayText} />
                                            })}
                                        </Field>
                                    </div>
                                    <div className="ss-sbmt">
                                        <RaisedButton
                                            primary
                                            disabled={this.props.pristine || !this.props.valid}
                                            label="OK"
                                            type="submit"
                                            fullWidth={true}
                                            style={{ width: "20%", marginTop: "15px" }} />
                                    </div>


                                </div>
                                

                            </div>
                    </div>
                </div>

            </form>
        )
    }
}


// Decorate the form component
ChooseSurveyForm = reduxForm({
  form: 'csf', // a unique name for this form
  validate
})(ChooseSurveyForm);



export default ChooseSurveyForm;