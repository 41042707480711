import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedDocumentData: false,
  uploading: false,
  error: "",
  deleting: {},
  progress: 0,
  totalBytes: 0,
  bytesTransferred: 0,
  data: {}
};

export default (state = initialState, action) => {
    let newDel = {};
    let record = {};
  switch (action.type) {
    case C.RECEIVE_DOCUMENTS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedDocumentData: true
        });

    case C.AWAIT_NEW_DOCUMENT_RESPONSE:
        return Object.assign({},state,{
            uploading: true,
            error: ""
        });

    case C.RECEIVE_NEW_DOCUMENT_RESPONSE:
        return Object.assign({}, state, {
            uploading: false,
            error: "",
            progress: 0
        })

    case C.START_DELETE_DOCUMENT:
        record = {};
        record[action.docId] = true;
        newDel = Object.assign({}, state.deleting, record);
      return Object.assign({}, state, {
            deleting: newDel,
            error: ""
        })

    case C.END_DELETE_DOCUMENT:
        record = {};
        record[action.docId] = false;
        newDel = Object.assign({}, state.deleting, record);
      return Object.assign({}, state, {
            deleting: newDel
        })

    case C.RECEIVE_DOCUMENT_UPLOAD_ERROR:
        return Object.assign({}, state, {
            uploading: false,
            error: action.error,
            progress: 0
        })

    case C.SET_DOCUMENT_UPLOAD_PROGRESS:
        return Object.assign({}, state, {
            progress: action.progress,
            totalBytes: action.totalBytes,
            bytesTransferred: action.bytesTransferred
        })

      default:
        return state;
  }
}