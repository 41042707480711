
import React, { Component } from 'react';
import JSONTree from 'react-json-tree';
import { connect } from 'react-redux';
import actions from '../../actions';

import store from '../../store';
import Editable from './editable';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#000000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

// <em style={{color:"#900"}}>{valueAsString} : {typeof value}</em>

const eTypes = {
    number : 'number',
    string : 'text',
    boolean : 'toggle'
}


class JSONEdit extends Component {
    
    componentWillMount () {
        const p = this.props;
        const path = p.path;
        console.log(path);
        store.dispatch( actions.startListeningToJsonEdit(path) );
    }

    render() {
        const p = this.props;
        const path = p.path;
        const data =  p.util.jsonEditData;

        return (

            <div className='json-edit'>
                <JSONTree 
                    data={data}
                    theme={theme}
                    invertTheme={true}
                    valueRenderer={(valueAsString, value, ...keyPath) => {
                        let editPath = path + '/' + keyPath.reverse().join('/');
                        console.log("JSONEdit what are these things?", valueAsString, value, keyPath);
                        console.log(editPath);
                        return <Editable type={eTypes[typeof value]} value={value} fullPath={editPath} /> ;
                    }}
                    hideRoot={true}/>
            </div>
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        util: appState.util
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JSONEdit);

