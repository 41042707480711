
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import List from 'material-ui/List';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddGroup from './components/addgroup';
import Group from './components/group';

const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToGroups() );
});

class Groups extends Component {

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const rows = _.map(p.groups.data, function (group, gid) {
            const groupState = p.groups.states[gid]; //TODO: use this for editing groups
            return <Group key={gid} group={group} />
        })
        const list = (
            <List className="groupList">
                {p.groups.hasReceivedGroupData ? rows : "Loading Groups..."}
            </List>
        )
        const noGroups = (
            <div>No groups exist</div>
        )
        const add = <AddGroup groups={this.props.groups} onSubmit={this.props.submitNewGroup} />
        const wait = <CircularProgress />

        return (
            <div>
            { rows.length ? list : noGroups }
            { this.props.groups.submittingnew ? wait : add}
                <div>warning: this resets the groups db to default</div>
                <div>
                    <RaisedButton
                    secondary
                    style={{ width: "100%", marginTop: "15px" }}
                    onTouchTap={this.props.initializeGroups}>
                        Initialize Groups
                    </RaisedButton>
                </div>
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        groups: appState.groups
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewGroup: function () { dispatch( actions.submitNewGroup() )},
        initializeGroups: function () {dispatch( actions.initializeGroups())}
    }
}

Groups.contextTypes = {
    router: React.PropTypes.func.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(Groups);
