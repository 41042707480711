import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../../actions';
import PurpleAnonImg from "../../assets/images/anon.png";
import LogoImg from "../../assets/images/new_logo_horz.png";
import OdpsLogo from "../../assets/images/odps/ohiopdsslgo.png";
import * as perms from "../../utils/permission";

const rightStyle = {
    marginRight: "21px"
}

const userIconStyle = {
    width: 40,
    height: 40
}

const MenuLink = (props) => {
    return (
        <li className="nav-item">
            <Link to={props.path} className="nav-link">
                <i className={"icon " + props.iconClass}></i>
                <div className="menu-block-label">{props.label}</div>
            </Link>
        </li>
    )
}
MenuLink.propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired
}

class ManagerNav extends Component {
    render () {
        return (
            <ul className="nav mgmt">
                <MenuLink label="Manage Users" path="/users" iconClass="ion-person"/>
                <MenuLink label="Manage Organizations" path="/organizations" iconClass="ion-person-stalker"/>
                <MenuLink label="Manage Intake" path="/intake/admin" iconClass="ion-clipboard"/>
                <MenuLink label="Coaching Items" path="/coachingitem/admin" iconClass="ion-medkit"/>
                <MenuLink label="Coaching Pages" path="/coachingpage/admin" iconClass="ion-medkit"/>
                <MenuLink label="Coaching Surveys" path="/coachingsurvey/admin" iconClass="ion-medkit"/>
                <MenuLink label="Intake Survey Options" path="/intakesurveyoptions" iconClass="ion-medkit"/>

                <MenuLink label="CPT Scenarios" path="/scenario/upload" iconClass="ion-folder"/>
                <MenuLink label="CPT Modules" path="/module/upload" iconClass="ion-briefcase"/>

                <MenuLink label="Manage Videos" path="/video/admin" iconClass="ion-videocamera"/>
                
                {/* Manager Links */}
                <MenuLink label="Download Launcher" path="/download" iconClass="ion-android-download"/>
                <MenuLink label="Invites" path="/invites" iconClass="ion-ios-personadd"/>
                <MenuLink label="Overview" path="/overview" iconClass="ion-filing"/>
                
                {/* Super User Links */}
                <MenuLink label="Groups" path="/groups" iconClass="ion-ios-people"/>
                <MenuLink label="Utils" path="/utils" iconClass="ion-settings"/>
                <MenuLink label="Coaching Admin" path="/coachingadmin" iconClass="ion-person"/>
                <MenuLink label="Alerts" path="/alerts" iconClass="ion-android-notifications"/>
                <MenuLink label="Feedback" path="/feedback/admin" iconClass="ion-chatbubbles"/>
                <MenuLink label="Builds" path="/builds" iconClass="ion-cube"/>
                <MenuLink label="Locations" path="/locations" iconClass="ion-location"/>
                <MenuLink label="Upload" path="/upload" iconClass="ion-upload"/>
            </ul>
        )
    }
}

class TeamNav extends Component {
    render () {
        return <MenuLink label="My Team" path="/team" iconClass="ion-ios-people"/>
    }
}

class AddDriverNav extends Component {
    render () {
        return <MenuLink label='Add Driver IDs' path='/add/driver' iconClass='ion-person-add' />
    }
}

class FilesNav extends Component {
    render () {
        return <MenuLink label='Help Files' path='/files' iconClass='ion-document-text' />
    }
}

class OwnerOverviewNav extends Component {
    render () {
        return <MenuLink label='Overview' path='/x/overview' iconClass='ion-ios-paper' />
    }
}

// class AddNav extends Component {
//     render () {
//         return <MenuLink label="Add Drivers" path="/managerutils" iconClass="ion-person-add"/>
//     }
// }

class LauncherNav extends Component {
    render () {
        return <MenuLink label="Download" path="/download" iconClass="ion-ios-download"/>
    }
}


/*
This Component renders at the top of all pages for all logged in users
The content of this component will vary for users with different permissions
*/

class Header extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const SecureTeamLink = perms.manager(TeamNav); // they need to invite not add...
        // const SecureAddLink = perms.manager(AddNav); // they need to invite not add...
        const SecureAddLink = perms.manager(AddDriverNav);
        const SecureFilesLink = perms.manager(FilesNav);
        const SecureMgmtNav = perms.spr(ManagerNav);
        const SecureDownload = perms.manager(LauncherNav);
        const SecureOwnerOverviewNav = perms.ownerNonBasic(OwnerOverviewNav);
        const SecureMenuLink = perms.manager(MenuLink);
        const OwnerNonBasicMenuLink = perms.ownerNonBasic(MenuLink);

        let topHeader = (
            <li className="nav-item mainmenu-user-profile" >
                <div className="circle-box">
                    <img width="80" alt="" src={PurpleAnonImg} />
                </div>
                <div className="menu-block-label">
                    <div><b>{ this.props.auth.username }</b></div>
                    <div className="display-6" style={{fontSize: "83%", lineHeight: 0, fontStyle: 'italic'}}>
                        {this.props.auth.orgName}
                    </div>
                </div>
            </li>
        )

        let leftTabs = (
            <ul className="nav">
                <MenuLink label="Home" path="/" iconClass="ion-home"/>

                <li className="nav-item hidden">
                    <a href="#" className="nav-link">
                        <i className="icon ion-clipboard"> </i>
                        <div className="menu-block-label">
                            Assessments
                        </div>
                    </a>
                </li>
                <SecureAddLink />
                <SecureFilesLink />
                {
                    this.props.auth.featureFlags && this.props.auth.featureFlags.LIMIT_RESULTS ? null :
                    <SecureOwnerOverviewNav />
                }
                
                {false ? <SecureTeamLink /> : null}
                <SecureDownload />

                {false ? <MenuLink label="Intake" path={ "/intake"} iconClass="ion-clipboard"/> : null}
                {false ? <MenuLink label="My Drives" path={ "/profile/" + this.props.auth.uid } iconClass="ion-model-s"/> : null }
                {
                    this.props.auth.featureFlags && this.props.auth.featureFlags.LIMIT_RESULTS ? null :
                    <OwnerNonBasicMenuLink label="Webhooks" path={"/api"} iconClass="ion-code-working"/>
                }
                
                {false ? <SecureMenuLink label="Documents" path={"/docs"} iconClass="ion-document" /> : null}
                {false ? <SecureMenuLink label="Payment" path={"/payment"} iconClass="ion-card" /> : null}
                
                <li className="nav-item">
                    <a href="#" onClick={this.props.logout} className="nav-link">
                        <i className="icon ion-log-out"> </i>
                        <div className="menu-block-label">
                            Logout
                        </div>
                    </a>
                </li>
            </ul>
        )

        //change skin if the user is in ODPS organization
        if(__ODPS__) {
            topHeader = (
                <div style={{padding: "10px"}}>
                    <img src={OdpsLogo} style={ {width: "100%"} } />
                </div>
            )

            leftTabs = (
                <ul className="nav">
                    <MenuLink label="Add Customer(s)" path="/odps/addcustomer" iconClass="ion-plus"/>
                </ul>
            );

            leftTabs = null;
        }

        return (
            <div className={"mainmenu-block"} >
                <nav className="menu-block">

                    <ul className="nav">
                        {topHeader}
                    </ul>
                    
                    {leftTabs}

                    <SecureMgmtNav mid={this.props.auth.uid} />
                    
                </nav>

                <div id="copyright">
                    <img src={LogoImg} style={ {width: "100%"} } />
                    <small>&copy; Diagnostic Driving, Inc {new Date().getFullYear()}</small>
                </div>
            </div>
        )
    }
}

Header.contextTypes = {
    router: React.PropTypes.object.isRequired
};

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        logout: function() { dispatch(actions.logoutUser()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

