import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import actions from '../../actions';
import store from '../../store';
import CoachingWizardStep from './CoachingWizardStep';

let requiredFields = [];

const validate = values => {
    const errors = {}
    const keys = Object.keys(values)
    for (var field of requiredFields) {
        if (!keys.includes(field)) {
            errors[field] = "This question is required";
        }
    }

    return errors
}

class CoachingWizard extends Component {

    constructor(props, context) {
        super(props, context);
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.state = {
            page: 0
        }
    }

    nextPage(e) {
        e.preventDefault();
        this.setState({ page: this.state.page + 1 })
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 })
    }

    componentWillMount () {
        store.dispatch( actions.startListeningToDriver( this.props.uid ) );
        store.dispatch( actions.startListeningToOrgs());
        store.dispatch( actions.startListeningToVideos());
        store.dispatch( actions.startListeningToCoachingSurveys());
        store.dispatch( actions.resetCoachingResponseForm() ); // on  mount we should always start fresh
    }

    render () {
        const s = this.state;
        const p = this.props;
        const driver = p.driver[p.uid];
        let org = p.org.data[driver.organization];
        let stepDivs;

        if (!org || org.loading) { 
            return (<CircularProgress />)
        }

        if (p.coaching.done) {
            return <Paper className="spacy-paper">{"That's It! Thanks"}</Paper>
        }
    
        //check if this user's organization has a survey assigned to it
        let surveyID = org.survey ? org.survey : null
        let survey; // to hold the survey data for this user's assigned coaching survey
        let index = 0;
        let display, lastIndex;
        //if this organization has a survey, display its label. otherwise display a message.
        if (p.survey.hasReceivedCoachingSurveyData) {
            survey = p.survey.data[surveyID];

            if (!surveyID) {
                return <Paper className="spacy-paper">{"The organization that this driver belongs to does not have a Coaching Survey. Please contact an administrator."}</Paper>
            }

            display = survey.label;
            lastIndex = Object.keys(survey.surveySteps).length - 1;

            stepDivs = _.map(survey.surveySteps, (value, stepId) => {
                // if (question.required && !(question.type == "instruction" || question.type == "allthatapply")) {
                //         requiredFields.push(question.name);
                // }
            
                const row = <div key={stepId} >
                                    {s.page === index && <CoachingWizardStep
                                                            step={value}
                                                            stepId={stepId}
                                                            index={index}
                                                            validate={validate}
                                                            last={index == lastIndex}
                                                            previousPage={this.previousPage}
                                                            nextPage={this.nextPage}
                                                            onSubmit={(function (e) {e.preventDefault(); this.props.addCoachingResponse(); }).bind(this) }  />}
                                    </div>
                index++;
                return row;
            }, this);
        }

        return (
            <Paper className="spacy-paper">
                    {display}
                    {stepDivs}
            </Paper>
        )
    }
}

// Wrap the component with the coaching response form. The fields are in the child component (coachingwizardstep)
CoachingWizard = reduxForm({
  form: 'coachingResponseForm', // a unique name for this form
  destroyOnUnmount: false,
  validate
})(CoachingWizard);

//to access the state (Redux doesnt want components to change state, so make it a prop)
const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        org: appState.orgs,
        driver: appState.driver,
        video: appState.video,
        survey: appState.survey,
        coaching: appState.coaching
    };
}

const mapDispatchToProps = function (dispatch) {//for dispatching actions
    return {
        setSortState : function (sortBy) {
            dispatch( actions.setSortState(sortBy) )
        },
        addCoachingResponse : function () {
            dispatch( actions.addCoachingResponse() )
        }
    }
}

CoachingWizard.contextTypes = {
    router: React.PropTypes.object.isRequired
};

//this allows injection of state into this component
export default connect(mapStateToProps, mapDispatchToProps)(CoachingWizard); 