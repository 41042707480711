import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import Assessment from './components/assessment';

class ManagerOverview extends Component {
    constructor(props, context) {
        super(props, context);
        
    }

    componentWillMount () {
        // store.dispatch( actions.startListeningToOverview() );
        if (this.props.auth.features.data) {
            const mid = this.props.params.mid;
            store.dispatch( actions.startListeningToManagerOverview(mid) );
        }
    }

    render () {
        const p = this.props;
        const mid = p.params.mid;

        if (!this.props.auth.features.data) {
            return <div className="spacy-paper">Manager overview feature not enabled.</div>;
        }



        if (!p.overview.managers[mid]) {
            return <div className="magic-load">Loading Overview&#8230;</div>
        }

        let aRows = _.map(p.overview.managers[mid], function (aData, aid) {
            return <Assessment data={aData} aid={aid} key={aid} />
        });

        let dash = (
            <div>
                
                { aRows.reverse() }
            </div>
        );

        return (
            <div>
                {dash} 
            </div> 
        )
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        overview: appState.overview
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        
    }
}

ManagerOverview.contextTypes = {
    router: React.PropTypes.object.isRequired
};


export default connect(mapStateToProps, mapDispatchToProps)(ManagerOverview);