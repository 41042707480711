/*
This is our top-level component. Sub-components matching specific routes will be
contained in `this.props.children` and rendered out.
*/
import _ from 'lodash';
import Snackbar from 'material-ui/Snackbar';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { C } from '../constants';
import store from '../store';
import * as perms from "../utils/permission";
import Header from './components/Header';
import InviteLink from './components/invitelink';
import Spinner from './components/spinner';
import ReadyAssessment from './components/readyassessment';
import CPTDash from './components/cptdash';
import RAImg from "../assets/images/Ready-Assess-Logo.png";
import IconButton from 'material-ui/IconButton';
import CloudDownload from 'material-ui/svg-icons/file/cloud-download';
import CloudOff from 'material-ui/svg-icons/file/cloud-off';
import Share from "../assets/images/share.png";
import Dialog from 'material-ui/Dialog';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import ChangingSecurityPreferencesOnMac from '../assets/images/ChangingSecurityPreferencesOnMac.pdf';


/*
Have this wrapper listen for auth -- show a spinner if awaiting auth info
need to know username and role to proceed

*/

const styles = {

  largeIcon: {
    width: "160px",
    height: "160px",
  },
  large: {
    paddingTop: "10px",
    textAlign: "center",
  },
};


class ReadyAssessHome extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            verificationReminderOpen: false,
            open: false
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({
            verificationReminderOpen : !nextProps.auth.emailVerified && !nextProps.auth.resentVerification
        })
    }

    componentWillMount () {
        store.dispatch( actions.listenToLauncher() );
        document.body.style.background = "#FFF";
        const p = this.props;
        console.log("AUTH ON LOAD", p.auth);
        store.dispatch( actions.listenToCustomerAssessments(p.auth.uid, p.auth.oid) );
        // store.dispatch( actions.startListeningToDriver( this.props.uid ) );
    }

    componentDidMount () {
        C.GA.logEvent('ra_view_dash');
    }

    trackViewSec () {
        C.GA.logEvent('view_security_prefs_pdf');
    }

    trackDownloadMac () {
        C.GA.logEvent('download_launcher');
        C.GA.logEvent('download_launcher_mac');
    }

    trackDownloadWin () {
        C.GA.logEvent('download_launcher');
        C.GA.logEvent('download_launcher_win');
    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    handleRequestClose () {

        this.setState({
            verificationReminderOpen: false,
        });
    };

    handleReSendClick () {
        this.props.reSendVerificationEmail();
    }

    handleOpen () {
        this.setState({open: true});
    }

    handleClose () {
        this.setState({open: false});
    }

    handleCopyShare (e) {
        e.preventDefault();
        let shareUrl = this.props.auth && this.props.auth.shareUrl || 'https://dxdriving.com/get/ready/-MuWDnVBzUALokJH88Hi';
        navigator.clipboard.writeText(shareUrl);
        this.handleClose();
        alert("URL for this page copied to the clipboard");
    }

    handleShare (e) {
        C.GA.logEvent('ra_share_dash');
        let shareUrl = this.props.auth && this.props.auth.shareUrl || 'https://dxdriving.com/get/ready/-MuWDnVBzUALokJH88Hi';
        if (navigator.share) {
            navigator.share({
                text: shareUrl,
                url: shareUrl,
                title: "Ready Assess™ Self-guided Virtual Driving Test",
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            console.log("Web Share not supported :(");
            this.handleOpen();     
        }
    }

    render () {
        
        const auth = this.props.auth;
        const isDxdDevWeb = __DXD_DEV_WEB__ || process.env.NODE_ENV === 'development';

        const p = this.props;
        
        lightBaseTheme.palette.primary1Color = "#3B125D";
        lightBaseTheme.palette.accent1Color = "#DE8931";

        const lightMuiTheme = getMuiTheme(lightBaseTheme);

        const plt = window.navigator.platform;
        const canDownload = plt.startsWith("MacIntel") || plt.startsWith("Win");
        const msg = canDownload ? (plt.startsWith("Win") ? "Download for Windows" : "Download for Mac") : "The Virtual Driving Test software is not available for " + plt + ". Please return to this page on a Windows or Mac computer.";
        const path = plt.startsWith("Win") ? this.props.launcher.win.path : this.props.launcher.mac.path;
        const trackDL = plt.startsWith("Win") ? this.trackDownloadWin.bind(this) : this.trackDownloadMac.bind(this)
        let shareUrl = this.props.auth && this.props.auth.shareUrl || 'https://dxdriving.com/get/ready/-MuWDnVBzUALokJH88Hi';

        let noDrivesYet = "Once you've completed your first virtual driving test, a record of it will be listed here.";
        let loadingDrives = "Loading assessment results";
        let assessmentRows = []

        if (p.overview.hasReceivedCustomerAssessmentData) {
            assessmentRows = _.map(p.overview.customerAssessments, (asm, key) => {
                return <ReadyAssessment data={asm} aid={key} key={key} />;
            })
        }


        return (
            <MuiThemeProvider muiTheme={lightMuiTheme}>
                <div className="ra-all">
                    <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css" />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CLexend+Deca:regular%7CLexend+Deca:regular,600" media="all"/>

                    <div className="ra-top">
                        <div className="ra-top-inner">
                            <span className="ra-logo-span"><img className="ra-logo" src={RAImg} alt="Ready Assess Logo"  width="311" /></span>
                            <button onClick={this.handleShare.bind(this)} className="share-button shtop"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                            <a href="#" onClick={this.props.logout} className="nav-link ra-nav-link" title="Logout"><i style={{fontSize: "29px"}} className="icon ion-log-out"></i></a>
                            <div className="clrbth"></div>
                        </div>
                    </div>

                    <div className="ra-section ra-dash-section bg-primary-3">
                        <div className="ra-section-inner row">
                            <div className="col-sm-12 share-hero">
                                <button onClick={this.handleShare.bind(this)} className="share-button shmid"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                            </div>
                            <div className="col-sm-12 clr-lft-pd">
                                <h1 className="ra-dash-header">
                                    Complete a Virtual Driving Test
                                </h1>
                            </div>
                            <div className="ra-chk-cnt col-sm-7 clr-lft-pd">
                                <h5 className="ra-chk-title">
                                    What you need:
                                </h5>
                                <div className="checklist upper-checklist">
                                    <div id="w-node-_41f085fc-dfdc-eac1-ff4d-bbe7f482c9e7-2950a28b" className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            A Windows or Mac PC
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            A decent Internet connection
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            A distraction-free space where you can complete Ready-Assess™ on your own
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            A pair of headphones (for a more immersive experience)
                                        </div>
                                    </div>
                                    <div className="checklist-item">
                                        <div className="check-circle chkchk">
                                            <CheckCircle style={{width:50, height: 50}} color="#ff4242" />
                                        </div>
                                        <div className="checklist-item-text large-text">
                                            A mouse or touchpad (a USB-external mouse works better)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5">

                                {


                                    canDownload ?



                                    (   this.props.launcher.filename ?
                                            <div className="ra-dl-outer">
                                                <div
                                                    className="ra-dl-inner"
                                                    style={styles.large}>
                                                    <a
                                                        onClick = {trackDL}
                                                        href = {this.props.launcher.blobUrl}
                                                        download = {this.props.launcher.filename}
                                                    >
                                                        <IconButton
                                                            disabled={!canDownload || !path}
                                                            iconStyle={styles.largeIcon}>
                                                                {canDownload ? <CloudDownload /> : <CloudOff />}
    
                                                        </IconButton>
                                                        <div style={{textAlign: "center", fontWeight: "bold", position:"relative", "top": -20}}>
                                                            {msg}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            <div className="dxd-loginSpinner" />)

                                    :

                                    <div className="ra-dl-outer">
                                        <div
                                            className="ra-dl-inner"
                                            style={styles.large}>

                                                <IconButton
                                                    disabled={true}
                                                    iconStyle={styles.largeIcon}>
                                                        <CloudOff />

                                                </IconButton>
                                                <div style={{textAlign: "center", fontWeight: "bold", position:"relative", "top": -20}}>
                                                    {msg}
                                                </div>

                                        </div>
                                    </div>

                                }

                            </div>
                            
                        </div>
                    </div>


                    <div className="ra-section ra-dash-section">
                        <div className="ra-section-inner">
                            <h2 className="ra-dash-sub-header">
                                Steps to get started:
                            </h2>
                            <div className="checklist">
                                <div className="checklist-item">
                                    <div className="check-circle bg-primary-1">
                                        <div>1</div>
                                    </div>
                                    <div className="checklist-item-text large-text">
                                        Download and install the Launcher application.
                                    </div>
                                </div>
                                <div className="checklist-item">
                                    <div className="check-circle bg-primary-1">
                                        <div>2</div>
                                    </div>
                                    <div className="checklist-item-text large-text">
                                        <div>Run the application.</div>
                                        <div className="chklist-hlp"><strong>For Mac users</strong>, you will need to <a onClick={this.trackViewSec.bind(this)}  className="ra-home-anchor" href={ChangingSecurityPreferencesOnMac} target="_blank">set your security preferences</a> to allow your computer to run the application</div>
                                    </div>
                                </div>
                                <div className="checklist-item">
                                    <div className="check-circle bg-primary-1">
                                        <div>3</div>
                                    </div>
                                    <div className="checklist-item-text large-text">
                                        <div>Log in and follow the prompts.</div>
                                        <div className="chklist-hlp">In the Sim Configuration settings, select “Windowed” and click “Play!” We recommend maximizing the window.</div>
                                    </div>
                                </div>
                                <div className="checklist-item">
                                    <div className="check-circle bg-primary-1">
                                        <div>4</div>
                                    </div>
                                    <div className="checklist-item-text large-text">
                                        Relax and enjoy the drive
                                    </div>
                                </div>
                                <div className="checklist-item">
                                    <div className="check-circle bg-primary-1">
                                        <div>5</div>
                                    </div>
                                    <div className="checklist-item-text large-text">
                                        {"After you finish, return to this page to access your results."}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="ra-section ra-dash-section bg-primary-3">
                        <div className="ra-section-inner">
                            <h2 className="ra-dash-sub-header">
                                Your results:
                            </h2>
                            {

                                p.overview.hasReceivedCustomerAssessmentData ?

                                    (assessmentRows.length ? assessmentRows.reverse() : noDrivesYet)
                                :

                                loadingDrives

                            }
                        </div>
                    </div>

                    <CPTDash />

                    <div style={{height:"32px"}}></div>
                    <div className="ra-announcement-bar ra-dash-btm-bar">
                        You can email us at any point at <a href="mailto:support@diagnosticdriving.com">support@diagnosticdriving.com</a> if you have any questions or need assistance.
                    </div>

                    <Dialog
                        actions={[<button onClick={this.handleClose.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">Close</span></button>]}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this.handleClose.bind(this)}>

                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + shareUrl}  target="_blank" className="share-link"><i className={"icon share-icon ion-social-facebook"}></i></a>
                        <a href={"http://twitter.com/share?text=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills." + "&url=" + shareUrl + "&hashtags=" + ['safedriving', 'drivesafe']} className="share-link" target="_blank"><i className={"icon share-icon ion-social-twitter"}></i></a>
                        <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareUrl} target="_blank" className="share-link"><i className={"icon share-icon ion-social-linkedin"}></i></a>
                        <a href={"http://pinterest.com/pin/create/button/?url=" + shareUrl + "&media=" + "https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c45fc379b131a4baff925_Ready-Assess-Logo.png" + "&description=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-pinterest"}></i></a>
                        <a href={"https://web.whatsapp.com/send?text=" + shareUrl} className="share-link" target="_blank"><i className={"icon share-icon ion-social-whatsapp"}></i></a>
                        <a href={"https://reddit.com/submit?url=" + shareUrl + "&title=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-reddit"}></i></a>
                        <a onClick={this.handleCopyShare.bind(this)} className="share-link"><i className={"icon share-icon ion-ios-copy"}></i></a>
                        <a href={"mailto:?subject=Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills.&body=Go here to register your free account and get started! site: " + shareUrl } target="_blank" className="share-link"><i className={"icon share-icon ion-ios-email"}></i></a>

                    </Dialog>

                    <Snackbar
                        open={this.state.verificationReminderOpen}
                        message="Your email address is unverified"
                        action="Re-send Verification e-mail"
                        autoHideDuration={300000}
                        onActionTouchTap={this.handleReSendClick.bind(this)}
                        onRequestClose={this.handleRequestClose.bind(this)} />
                </div>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        launcher: appState.launcher,
        overview: appState.overview

    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        logout: function() { dispatch(actions.logoutUser()); },
        reSendVerificationEmail: function() { dispatch( actions.reSendVerificationEmail() ); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadyAssessHome);
