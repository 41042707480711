import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import actions from '../actions';
import CoachingStepForm from './components/coachingstepform';
import CreateCoachingSurveyForm from './components/CreateCoachingSurveyForm';


/* 
   A form for super users to create a CoachingSurvey by adding CoachingSurveySteps.
   The order of questions is determined by CoachingSurveyStep.

    first pass:
    first ask how many steps
    then that many forms will be populated on screen: upload first video, add two questions; upload second video, add one quesiton
    videos will be drop downs
    questions will be text forms (TextInputs?)

    second pass:
   + button for new steps
   be able to drag them for order?

*/

class CoachingAdmin extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillMount() {
        this.props.startListeningToVideos();
        this.props.startListeningToCoachingSteps();
        this.props.startListeningToCoachingSurveys();
    }

    render () {
        if (__ODPS__) {
            functions["Fix Locations (ODPS)"] = this.props.fixOdpsLocations;
        }

        const p = this.props;

        //check that hasReceivedCoachingStepData is true before attempting to render createCoachingSurveyForm component
        return (
            <div>
                {p.step.hasReceivedCoachingStepData ? 
                <CreateCoachingSurveyForm 
                    steps={p.step} 
                    onSubmit={(function (e) {e.preventDefault(); this.props.addCoachingSurvey(); }).bind(this) }/> 
                : "Loading..."}
                
                <CoachingStepForm videos={p.video} onSubmit={(function (e) {e.preventDefault(); this.props.addCoachingStep(); }).bind(this) }/>

            </div>
        )
    }
}

//get the input value from createCoachingSurveyForm -- formValueSelector function is from redux-form
const selector = formValueSelector('createCoachingSurveyForm');

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        video: appState.video,
        step: appState.step,
    };
}

const mapDispatchToProps = function(dispatch) { //***not all of these actions have been implemented***
    return {
        startListeningToVideos: function () {
            dispatch( actions.startListeningToVideos() )
        },
        startListeningToCoachingSteps: function () {
            dispatch( actions.startListeningToCoachingSteps() )
        },
        startListeningToCoachingSurveys: function () {
            dispatch( actions.startListeningToCoachingSurveys() )
        },
        addCoachingStep: function () {dispatch( actions.addCoachingStep() )},
        addCoachingSurvey: function () {dispatch( actions.addCoachingSurvey() )},
        deleteCoachingStep: function (qid) {
            dispatch( actions.deleteCoachingStep( qid ) )
        },
        load: function(qid) { dispatch(actions.dupeCoachingStep(qid)); } 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachingAdmin);