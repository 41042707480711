import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';
import AddScoringOption from './components/addscoringoption';


class ScoringOptions extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {


        store.dispatch( actions.startListeningToScoringOptions() );

    }

    render () {
        const p = this.props;
        let rows = _.map(p.scoringOptions.values, (option, index) => {
                return <div key={index}>{option}</div>
            })
        const list = (
            <div className="scoringOption-list">
                { rows }
            </div>
        )
        const nodata = (
            <div>No scoring options exist</div>
        )
        const add = <AddScoringOption onSubmit={(function (e) {e.preventDefault(); this.props.submitNewScoringOption(); }).bind(this) } />
        const wait = <CircularProgress />
        return (

            <div>
                <div className='display-6 btm-space'>Configure Scoring Options</div>
                { p.scoringOptions.submittingnew ? wait : add}
                { rows.length ? list : nodata }
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        scoringOptions: appState.scoringOptions
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewScoringOption: function () { dispatch( actions.submitNewScoringOption() )}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoringOptions);