import RaisedButton from 'material-ui/RaisedButton';
import React, { Component, PropTypes } from 'react';

const ImagePreview = (props) => {
    const { file } = props;
    const name = file.name;
    const preview = URL.createObjectURL(file);
    const size = file.size;

    return (
        <div style={{ margin: '12px' }}>
            <div className="thumbnailPreview">
                <img src={preview} alt={name}/>
            </div>
            <span style={{ verticalAlign: 'top', margin: '6px' }}>
                {name} ({(size/1024000).toFixed(2)}MB)
            </span>
        </div>
    );
};

ImagePreview.propTypes = {
    file: PropTypes.instanceOf(File)
};

class FieldFileInput extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(evt) {
        const { input: { onChange } } = this.props;
        const file = evt.target.files[0];

        this.props.onPreviewChange(file);
        onChange(file);
    }

    render() {
        const { label, required, accept, name, disabled } = this.props;

        return (
            <div>
                <RaisedButton
                    secondary
                    containerElement="label"
                    label={label}
                    labelPosition="before"
                    disabled={disabled}
                    style={{ margin: "12px" }}>

                        <input
                            type="file"
                            onChange={this.handleChange}
                            accept={accept}
                            id={name}
                            name={name}
                            disabled={disabled}
                            className="formFieldInputButton" />
                </RaisedButton>

                {this.props.previewSrc ? <ImagePreview file={this.props.previewSrc}/> : null}
            </div>
        );
    }
}

export default FieldFileInput;