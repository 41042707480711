import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';



const MAC_FIELD_NAME = 'maclauncherfile';
const WINDOWS_FIELD_NAME = 'windowslauncherfile';

const renderDropzoneInput = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        className="dropzone"
        onDrop={( filesToUpload, e ) => field.input.onChange(filesToUpload)}
      >
        <div style={{ padding: 15 }}>Try dropping launcher file here, or click to select file to upload.</div>
      </Dropzone>
      {field.meta.touched &&
        field.meta.error &&
        <span className="error">{field.meta.error}</span>}
      {files && Array.isArray(files) && (
        <ul>
          { files.map((file, i) => <li key={i}>{file.name}</li>) }
        </ul>
      )}
    </div>
  );
}


class LauncherForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        console.log("Rendering launcher form");
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor={MAC_FIELD_NAME}>Mac Launcher</label>
                        <Field
                            name={MAC_FIELD_NAME}
                            component={renderDropzoneInput} />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor={WINDOWS_FIELD_NAME}>Windows Launcher</label>
                        <Field
                            name={WINDOWS_FIELD_NAME}
                            component={renderDropzoneInput} />
                    </div>
                </div>
                <div>
                    <RaisedButton
                        primary
                        disabled={this.props.pristine}
                        label="Upload Launcher Files"
                        type="submit"
                        style={{ width: "100%", marginTop: "15px" }} />
                </div>
            </form>
        )
    }
}

// Decorate the form component
LauncherForm = reduxForm({
  form: 'launcher'
})(LauncherForm);


export default LauncherForm;