import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  hasReceivedOrgData: false,
  submittingnew: false,
  data: {},
  states: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.RECEIVE_ORGS_DATA:
        return Object.assign({}, state, {
          data: action.data,
          hasReceivedOrgData: true
        });

    case C.AWAIT_NEW_ORG_RESPONSE:
        return Object.assign({},state,{
            submittingnew: true
        });

    case C.RECEIVE_NEW_ORG_RESPONSE:
        return Object.assign({}, state, {
            submittingnew: false
        })

      default:
        return state;
  }
}