import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentForm from './components/paymentform';
import actions from '../actions';
import store from '../store';
import Paper from 'material-ui/Paper';
import RefreshIndicator from 'material-ui/RefreshIndicator';

const style = {
    paper : {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 350,
        padding: 20,
        marginTop: 50,
        position: 'relative'
    },
    refresh: {
        display: 'block',
        position: 'relative',
  },
}



class Pay extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount () {
        store.dispatch( actions.startListeningToOrgs() );
        store.dispatch( actions.startListeningToDriverIds() );
        this.props.setTitle('Payment');
    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    render () {

        const p = this.props;

        if (p.driverIds.empty || p.pay.loading) {
            return (
                <Paper style={style.paper}>
                    <RefreshIndicator
                        size={100}
                        left={103}
                        top={0}
                        loadingColor="#FF9800"
                        status="loading"
                        style={style.refresh} />
                </Paper>
            )
        }

        return (
            <Paper style={style.paper}>
                <PaymentForm
                    orgs={p.orgs}
                    driverIds={p.driverIds}
                    onSubmit={(function (e) {e.preventDefault(); p.startCheckout(); }).bind(this) } />
            </Paper>
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
        orgs: appState.orgs,
        driverIds : appState.driverIds,
        pay : appState.pay
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        startCheckout: function () {
            dispatch( actions.startCheckout() )
        },
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Pay);