import React, { Component } from 'react';
import { connect } from 'react-redux';
import RAImg from "../assets/images/Ready-Assess-Logo.png";
import VENK from "../assets/images/VENK.jpg";
import LUKE from "../assets/images/Luke.jpg";
import FLAURA from "../assets/images/Flaura.jpg";
import { C } from '../constants';
import Share from "../assets/images/share.png";
import LoadingDots from "../assets/images/745.png";
import Dialog from 'material-ui/Dialog';


class ReadyStory extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        }
    }

    trackClickLogin () {
        C.GA.logEvent('ra_click_login_v2');
    }

    trackClickRegister () {
        C.GA.logEvent('ra_click_register_v2');
    }

    handleOpen () {
        this.setState({open: true});
    }


    handleClose () {
        this.setState({open: false});
    }

    componentWillMount () {
        document.body.style.background = "#FFF";
    }

    componentDidMount () {
        C.GA.logEvent('ra_view_story');
    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    handleCopyShare (e) {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href);
        this.handleClose();
        alert("URL for this page copied to the clipboard");
    }

    handleShare (e) {
        let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
        C.GA.logEvent('ra_share_story');
        if (navigator.share) {
            navigator.share({
                text: window.location.href,
                url: window.location.href,
                title: "Ready Assess™ Self-guided Virtual Driving Test",
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            console.log("Web Share not supported :(");
            this.handleOpen();     
        }
    }

    render () {

    let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
    let loggedIn = this.props.auth && this.props.auth.uid;
    let uninitiated = this.props.auth && this.props.auth.currently == C.UNINITIATED;

        return (
            <div className="ra-all">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CLexend+Deca:regular%7CLexend+Deca:regular,600" media="all"/>

                <div className="ra-top">
                    <div className="ra-top-inner row">
                        <div className="col-sm-6 ra-top-left">
                            <a href={"/get/ready/" + inviteId}><span className="ra-logo-span"><img className="ra-logo" src={RAImg} alt="Ready Assess Logo"  width="311" /></span></a>
                        </div>
                        <div className="col-sm-6 ra-top-rt">
                            <button onClick={this.handleShare.bind(this)} className="share-button"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                        </div>
                        
                        
                        <div className="clrbth"></div>
                    </div>
                </div>
                <div className="ra-section ra-dash-section bg-primary-3">
                        <div className="ra-section-inner row">
                            <div className="col-sm-12 share-hero">
                                <button onClick={this.handleShare.bind(this)} className="share-button shmid"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                            </div>
                            <div className="col-sm-12 clr-lft-pd">
                                <h1 className="ra-dash-header">
                                    Our Mission is simple:
                                </h1>
                                <h1 className="ra-dash-header">
                                    Fill the roads with safer drivers
                                </h1>
                            </div>
                            <div className="col-sm-12 clr-lft-pd">
                                <p className="large-text">
                                    Ready-Assess is the creation of Diagnostic Driving -- a Philadelphia-based technology company spun-off from the Children’s Hospital of Philadelphia. &nbsp;The Diagnostic Driving mission is to have <strong>safer drivers on our roadways</strong>. Our team of scientists has more than 30 years of combined experience in road traffic safety research. These skills allowed us to build a simple but powerful tool to help people better understand how to avoid crashes and drive safely.
                                </p>
                            </div>
                            
                        </div>
                    </div>
                <div className="ra-section">
                    <div className="ra-section-inner">
                        <h4 className="medium-heading">Our Team</h4>
                        <div className="large-text text-above-team">
                            <strong>We are a team of innovators for safety: </strong>scientists, engineers and game developers.
                        </div>
                        <div className="w-layout-grid team-grid-thirds">
                            <div data-w-id="568a9f91-da7e-2a99-2212-1bd8a9520cf6" className="team-slide">
                                <img className="team-image" src={VENK} alt="Venk Kandadai, CEO" />
                                <div>
                                    <h5 className="no-bottom-margin h5x">
                                        Venk Kandadai
                                    </h5>
                                    <div className="team-member-social-links">
                                        <a href="https://linkedin.com" target="_blank" className="social-link reduced-margin hover-link w-inline-block">
                                            <img src="https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c43dfa3a45a36dbd7ea50_social-linkedin-red.svg" alt="" className="social-link-image" />
                                            <div>
                                                Linkedin
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div data-w-id="568a9f91-da7e-2a99-2212-1bd8a9520d02" className="team-slide">
                                <img className="team-image" src={LUKE} alt="Luke Walker, CTO" />
                                <div>
                                    <h5 className="no-bottom-margin h5x">
                                        Luke Walker
                                    </h5>
                                    <div className="team-member-social-links">
                                        <a href="https://linkedin.com" target="_blank" className="social-link reduced-margin hover-link w-inline-block">
                                            <img src="https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c43dfa3a45a36dbd7ea50_social-linkedin-red.svg" alt="" className="social-link-image" />
                                            <div>
                                                Linkedin
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div data-w-id="568a9f91-da7e-2a99-2212-1bd8a9520d0e" className="team-slide">
                                <img className="team-image" src={FLAURA} alt="Flaura Winston" />
                                <div>
                                    <h5 className="no-bottom-margin h5x">
                                        Flaura Winston
                                    </h5>
                                    <div className="team-member-social-links">
                                        <a href="https://linkedin.com" target="_blank" className="social-link reduced-margin hover-link w-inline-block">
                                            <img src="https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c43dfa3a45a36dbd7ea50_social-linkedin-red.svg" alt="" className="social-link-image" />
                                            <div>
                                                Linkedin
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{height:"62px"}}></div>
                <div className="ra-announcement-bar">
                    {
                        uninitiated ?

                        <img className="loading-dots" src={LoadingDots} alt="Loading Dots"  />

                        :

                        loggedIn ? 
                        
                        <a href={"/"} className="link-3">Go to Dashboard</a>

                        : 

                        <div>


                            <div  className="ftr-col"><a onClick={this.trackClickLogin.bind(this)} className="ftr-link" href={"/login"}>Login</a></div>
                            <div  className="ftr-col"><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-shrt">Register</a><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-lng">Register for a free account!</a></div>
                            <div  className="ftr-col"><button onClick={this.handleShare.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="18" /></button></div>
                        </div>
                    }
                </div>
                <Dialog
                    actions={[<button onClick={this.handleClose.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">Close</span></button>]}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose.bind(this)}>

                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}  target="_blank" className="share-link"><i className={"icon share-icon ion-social-facebook"}></i></a>
                    <a href={"http://twitter.com/share?text=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills." + "&url=" + window.location.href + "&hashtags=" + ['safedriving', 'drivesafe']} className="share-link" target="_blank"><i className={"icon share-icon ion-social-twitter"}></i></a>
                    <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href} target="_blank" className="share-link"><i className={"icon share-icon ion-social-linkedin"}></i></a>
                    <a href={"http://pinterest.com/pin/create/button/?url=" + window.location.href + "&media=" + "https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c45fc379b131a4baff925_Ready-Assess-Logo.png" + "&description=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-pinterest"}></i></a>
                    <a href={"https://web.whatsapp.com/send?text=" + window.location.href} className="share-link" target="_blank"><i className={"icon share-icon ion-social-whatsapp"}></i></a>
                    <a href={"https://reddit.com/submit?url=" + window.location.href + "&title=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-reddit"}></i></a>
                    <a onClick={this.handleCopyShare.bind(this)} className="share-link"><i className={"icon share-icon ion-ios-copy"}></i></a>
                    <a href={"mailto:?subject=Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills.&body=Go here to register your free account and get started! site: " + window.location.href } target="_blank" className="share-link"><i className={"icon share-icon ion-ios-email"}></i></a>

                </Dialog>
            </div>
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth : appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReadyStory);