
import ChipInput from 'material-ui-chip-input';
import CircularProgress from 'material-ui/CircularProgress';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import * as hlp from '../../utils/helpers';
import SimpleAddDriverIdForm from '../components/simpleadddriverform';
import DriverIdList from '../components/driveridlist';


const re = /\s*,\s*|\s+/;

const digitRe = /^\d+$/;

const driverRe = /^[\da-zA-Z_-]+$/;

const ID_LABEL = __ODPS__ ? "BASS ID" : "driver ID";

const ID_GUIDANCE = __ODPS__ ? "Valid IDs are numeric with a maximum of 9 digits." : "Valid IDs can only contain, letters, numbers, dashes & underscores.";

function isValidBassId(val) {
    if (digitRe.test(val)) {
        let num = parseInt(val);
        return num >= 0 && num < 1000000000;
    }
    return false;
}

function isValidDriverId(val) {
    return driverRe.test(val);
}

const isValidId = __ODPS__ ? isValidBassId : isValidDriverId;

function isProvisional(p) {
    return p.params && p.params.provisional;
}

class AddCustomer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            bassIDs : [],
            errorBassIDs : [],
            simpleForm : (__ODPS__ ? false : true)
        };
    }

    //TODO: clear customer state when component is removed

    componentWillMount () {

    }

    componentWillReceiveProps (nextProps) {

    }

    handleAddChip(chip) {
        let values = chip.split(re);
        values = values.map( (s) => { return s.trim() })
        values = values.filter( (str, idx) => { return str.length } )
        let errorBids = [];
        let newBids = [];
        for (var bid of values) {
            if (!isValidId(bid)) {
                errorBids.push(bid)
            } else {
                newBids.push(bid)
            }
        }
        this.setState({
            bassIDs : [...this.state.bassIDs, ...newBids],
            errorBassIDs : errorBids
        });

    }

    handleDeleteChip(chip) {
        this.setState({
            bassIDs : this.state.bassIDs.filter((str, idx) => {return str !== chip}),
            errorBassIDs : []
        });
    }

    handleFormToggle(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            simpleForm : !this.state.simpleForm
        })
    }

    

    render () {
        const p = this.props;
        let errorMessage = "";
        if (this.state.errorBassIDs.length) {
            errorMessage = this.state.errorBassIDs.join(", ") +
            (this.state.errorBassIDs.length > 1 ? " are not valid " + ID_LABEL + "s" : " is not a valid " + ID_LABEL) +
            ". " + ID_GUIDANCE;
        } 

        let addForm = null;
        let formToggleMsg = "I'd rather enter (or copy/paste) multiple " + ID_LABEL + "s";
        if (!this.state.simpleForm) {
            formToggleMsg = "I'd rather enter one " + ID_LABEL + " at a time."
        }

        if (this.state.simpleForm) {
            addForm = (
                <div>
                    
                    <SimpleAddDriverIdForm onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.addDriverId(isProvisional(p));
                            }} className="simpleAddDriverForm"
                            provisional={isProvisional(p)}
                            idLabel={ID_LABEL} />
                </div>
            )
        } else {
            addForm = (
                <div style={{padding: "44px 11px 0"}}>
                        
                    <h6 className="headerWithTip">{"Add " + (isProvisional(p) ? "provisional " : " ") + ID_LABEL + "s:"}</h6>
                    <small className="protip">
                        {"Enter " + ID_LABEL + "s followed by the return key. " +
                            "You can also paste a list of IDs that is " +
                            "comma and/or whitespace separated."
                        }
                    </small>
                    <div>
                        <ChipInput
                            value={this.state.bassIDs}
                            onRequestAdd={(chip) => this.handleAddChip(chip)}
                            onRequestDelete={(chip) => this.handleDeleteChip(chip)}
                            fullWidth={true} />
                    </div>
                    <div className="errorText">
                        {errorMessage}
                    </div>
                    <div>
                        <RaisedButton
                            onClick={() => {
                                this.props.addOdpsCustomers(this.state.bassIDs, this.props.auth.uid, isProvisional(p));
                                this.setState({bassIDs : []})
                            }} 
                            label="Add" 
                            primary={true} 
                            disabled={!this.state.bassIDs.length}
                            style={{marginRight: 20}}/>
                    </div>

                </div>
            )
        }

        let success = null;
        let provSuccess = null;
        let provFailed = null;
        let failed = null;
        let error = null;

        if (this.props.odps.success.length) {
            let sList = this.props.odps.success;
            let many = sList.length > 1;
            let sMsg = hlp.toListWithAnd(this.props.odps.success) + 
            (many ? " were" : " was") + " added to the system successfully."
            success = (
                <div className="fdbk odpssuccess">
                    { sMsg }
                </div>
            )
        }

        if (this.props.odps.provSuccess.length) {
            let sList = this.props.odps.provSuccess;
            let many = sList.length > 1;
            let sMsg = hlp.toListWithAnd(this.props.odps.provSuccess) + 
            (many ? " were" : " was") + " added to the list of provisional IDs successfully."
            provSuccess = (
                <div className="fdbk odpssuccess">
                    { sMsg }
                </div>
            )
        }

        if (this.props.odps.alreadyExists.length) {
            let list = this.props.odps.alreadyExists;
            let many = list.length > 1;
            let fMsg = hlp.toListWithAnd(list) + (many ? " were" : " was") +
            " not added because" + (many ? " those IDs were" : " that ID was") +
            " already present in the system."
            failed = (
                <div className="fdbk odpsissue">
                    {fMsg}
                </div>
            )
        }

        if (this.props.odps.provAlreadyExists.length) {
            let list = this.props.odps.provAlreadyExists;
            let many = list.length > 1;
            let fMsg = hlp.toListWithAnd(list) + (many ? " were" : " was") +
            " not added to the list of provisional IDs because" + (many ? " those IDs were" : " that ID was") +
            " already present in that list."
            provFailed = (
                <div className="fdbk odpsissue">
                    {fMsg}
                </div>
            )
        }

        if (this.props.odps.error) {
            error = (
                <div className="fdbk odpsissue">
                    { "Error adding " + ID_LABEL + "(s)"
                    }
                </div>
            )
        }

        if (this.props.odps.submittingnew) {
            addForm = <CircularProgress />
        }

        return (

            <div>
                
                <div className="panel">
                    <div className="panel-body">
                        <div>
                            <div style={{textAlign:"right"}} id="page-controls">
                                <a style={{fontStyle: 'italic', fontSize: '85%'}} href="#" onClick={this.handleFormToggle.bind(this)}>
                                    {formToggleMsg}
                                </a>
                            </div>
                        </div>
                        { addForm }
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body">
                        { success }
                        { provSuccess }
                        { failed }
                        { provFailed }
                        { error }
                    </div>
                </div>

                {
                    __ODPS__ ? null :
                    <div className="panel">

                        <DriverIdList />

                    </div>
                }

                
                
            </div> 
                
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        odps: appState.odps
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        addOdpsCustomers: function (listofBids, addedBy, opt_provisional) {
            dispatch( actions.addOdpsCustomers(listofBids, addedBy, opt_provisional) )
        },
        addDriverId: function (isProvisional) {
            dispatch( actions.addDriverId(isProvisional) )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
