import { C } from '../constants';

export const toggleMobileNav = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.TOGGLE_MOBILE_NAV });
        document.body.classList.toggle('minibar', getState().ui.mobileNavToggled);
    }
}

export const hideMobileNav = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.HIDE_MOBILE_NAV });
        document.body.classList.toggle('minibar', getState().ui.mobileNavToggled);
    }
}

export const showMobileNav = function () {
    return function (dispatch, getState) {
        dispatch({ type: C.SHOW_MOBILE_NAV });
        document.body.classList.toggle('minibar', getState().ui.mobileNavToggled);
    }
}

