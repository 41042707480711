import React, { Component } from 'react';
import { connect } from 'react-redux';

import RAImg from "../assets/images/Ready-Assess-Logo.png";
import SCIENCEGLASSES from "../assets/images/science_glasses.jpg";
import { Accordion, AccordionItem } from 'react-light-accordion';

import { C } from '../constants';
import Share from "../assets/images/share.png";
import LoadingDots from "../assets/images/745.png";
import Dialog from 'material-ui/Dialog';


class ReadyScience extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        }
    }

    trackClickLogin () {
        C.GA.logEvent('ra_click_login_v2');
    }

    trackClickRegister () {
        C.GA.logEvent('ra_click_register_v2');
    }

    handleOpen () {
        this.setState({open: true});
    }


    handleClose () {
        this.setState({open: false});
    }

    componentWillMount () {
        document.body.style.background = "#FFF";
    }

    componentDidMount () {
        document.querySelectorAll("button.title")[0].click();
        C.GA.logEvent('ra_view_science');
    }

    componentWillUnmount () {
        document.body.style.background = "#3b125d";
    }

    handleCopyShare (e) {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href);
        this.handleClose();
        alert("URL for this page copied to the clipboard");
    }

    handleShare (e) {
        let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
        C.GA.logEvent('ra_share_science');
        if (navigator.share) {
            navigator.share({
                text: window.location.href,
                url: window.location.href,
                title: "Ready Assess™ Self-guided Virtual Driving Test",
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            console.log("Web Share not supported :(");
            this.handleOpen();     
        }
    }

    render () {

    let inviteId = this.props && this.props.params && this.props.params.inviteId || "";
    let loggedIn = this.props.auth && this.props.auth.uid;
    let uninitiated = this.props.auth && this.props.auth.currently == C.UNINITIATED;

        return (
            <div className="ra-all">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CLexend+Deca:regular%7CLexend+Deca:regular,600" media="all"/>

                <div className="ra-top">
                    <div className="ra-top-inner row">
                        <div className="col-sm-6 ra-top-left">
                            <a href={"/get/ready/" + inviteId}><span className="ra-logo-span"><img className="ra-logo" src={RAImg} alt="Ready Assess Logo"  width="311" /></span></a>
                        </div>
                        <div className="col-sm-6 ra-top-rt">
                            <button onClick={this.handleShare.bind(this)} className="share-button"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                        </div>
                        
                        
                        <div className="clrbth"></div>
                    </div>
                </div>
                <div className="ra-section ra-dash-section bg-primary-3">
                        <div className="ra-section-inner row">
                            <div className="col-sm-12 share-hero">
                                <button onClick={this.handleShare.bind(this)} className="share-button shmid"><span className="share-text">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="36" /></button>
                            </div>
                            <div className="col-sm-12 clr-lft-pd">
                                <div className="w-layout-grid grid-halves">
                                    <div className="subscribe-container">
                                        <h1 className="science-header">
                                            The Science Behind Ready-Assess
                                        </h1>
                                        <p className="large-text">
                                            Ready-Assess is backed by over <strong>two decades of research</strong> and is supported by the Diagnostic Driving team of scientists. We are dedicated to creating innovative tools that increase road safety.<br />
                                        </p>
                                    </div>
                                    <img className="image-2" src={SCIENCEGLASSES} alt="Glasses on a science book" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                <div className="ra-section">
                    <div className="ra-section-inner">

                        <div className="section-title">
                            <div className="title-row">
                                <div className="subscribe-container">
                                    <h4 className="large-heading">
                                        Our Assessment is proudly grounded in and validated by rigorous science.
                                    </h4>
                                    <div className="large-text">
                                        Ready-Assess:
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Accordion atomic={true}>
                            <AccordionItem title={<span className="innerTitle">Is true to life</span>}>
                                <div className="accordion-text-inner">
                                    <div className="accordion-top-text">
                                        Simulating the most common and serious crash scenarios as determined by the National Highway Traffic Safety Administration.
                                    </div>
                                    <ul role="list" className="accordion-list">
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Curry, A.E., Kandadai, V., Sommers, M.S., &amp; Winston, F.K. (2014) Comparison of teen and adult driver crash scenarios in a nationally representative sample of serious crashes. Accident Analysis and Prevention, 72, 302–308. <a href="https://doi.org/10.1016/j.aap.2014.07.016">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Durbin, D.R., Mirmana, J.H., Curry A.E., Wanga, W., Fisher Thiel, M.C., Schultheis, M., &amp; Winston, F.K. (2014). Driving errors of learner teens: Frequency, nature and their association with practice. Accident Analysis and Prevention, 72, 433-439. <a href="https://doi.org/10.1016/j.aap.2014.07.033">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Curry, A.E., Hafetz, J., Kallan, M.J., Winston, F.K., &amp; Durbin, D.R. (2011). Prevalence of teen driver errors leading to serious motor vehicle crashes. Accident Analysis and Prevention, 43(4), 1285-1290. <a href="https://doi.org/10.1016/j.aap.2010.10.019">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            McDonald, C., Tanenbaum, J., Lee, Y.C., Fisher, D., Mayhew, D., &amp; Winston, F. (2012). Using Crash Data to Develop Simulator Scenarios for Assessing Novice Driver Performance. Transportation Research Record: Journal of the Transportation Research Board, 2321, 73-78. <a href="https://doi.org/10.3141/2321-10">Source</a>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">Proactively identifies risky drivers</span>}>
                                <div className="accordion-text-inner">
                                    <div className="accordion-top-text">
                                        With a proven ability to differentiate between skilled and unskilled drivers and between those who are experienced and inexperienced.
                                    </div>
                                    <ul role="list" className="accordion-list">
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Kandadai, V., Loeb, H., Seacrist, T.S., Lee, Y.C., Winston, Z., &amp; Winston, F.K. (2015). Simulated Driving Assessment (SDA) for teen drivers: results from a validation study. Injury Prevention, 21(3), 145-152. <a href="http://dx.doi.org/10.1136/injuryprev-2014-041480">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Kandadai, V., Loeb, H., Seacrist, T., Lee, Y.C., Bonfiglio, D., Fisher, D.L., &amp; Winston, F.K. (2015). Evaluation of a Risk Awareness Perception Training Program on Novice Teen Driver Behavior at Left-Turn Intersections. Transportation Research Record: Journal of the Transportation Research Board, 2516, 15-21. <a href="https://doi.org/10.3141/2516-03">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Loeb, H.S., Kandadai, V., McDonald, C.C., &amp; Winston, F.K. (2015). Emergency Braking in Adults Versus Novice Teen Drivers Response to Simulated Sudden Driving Events. Transportation Research Record: Journal of the Transportation Research Board, 2516, 8-14. <a href="https://doi.org/10.3141/2516-02">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Durbin, D.R., Mirman, J.H., Curry A.E., Wanga, W., Fisher Thiel, M.C., Schultheis, M., &amp; Winston, F.K. (2014). Driving errors of learner teens: Frequency, nature and their association with practice. Accident Analysis and Prevention, 72, 433-439. <a href="https://doi.org/10.1016/j.aap.2014.07.033">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Winston, F., McDonald, C., Kandadai, V., Winston, Z., &amp; Seacrist, T. (2014). Experience and Skill Predict Failure to Brake Errors: Further Validation of the Simulated Driving Assessment. SAE Technical Paper, 2014-01-0445. <a href="https://doi.org/10.4271/2014-01-0445">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Ontañón S., Lee, Y.C., Snodgrass, S., Bonfiglio, D., Winston, F.K., McDonald, C., &amp; Gonzalez, A.J. (2014). Case-Based Prediction of Teen Driver Behavior and Skill. In: Lamontagne L., Plaza E. (eds) Case-Based Reasoning Research and Development. ICCBR 2014. Lecture Notes in Computer Science, 8765. Springer, Cham. <a href="https://doi.org/10.1007/978-3-319-11209-1_27">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Winston, F.K., McDonald, C.C., Kandadai, V., Loeb, H., Tanenbaum, J.B., Seacrist, T., Scarfone, S.R., &amp; Winston, Z. (2014). Headway Time Errors, Safe Driving Skill, and Experience: An Initial Validation of the Simulated Driving Assessment. Presented at the Transportation Research Board 93rd Annual Meeting. Washington DC: Transportation Research Board. Retrieved from, <a href="https://trid.trb.org/view.aspx?id=1289640">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Seacrist, T.S., Lee, Y.C., Loeb, H., Kandadai, V., &amp; Winston, F.K. Headway Time and Crashes Among Novice Teens and Experienced Adult Drivers in a Simulated Lead Truck Braking Scenario. (2013). Proceedings presented at the 7th International Driving Symposium on Human Factors in Driver Assessment, Training and Vehicle Design (439-445). Bolton Landing, New York: University of Iowa.
                                        </li>
                                    </ul>
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">Delivers accurate results</span>}>
                                <div className="accordion-text-inner">
                                    <div className="accordion-top-text">
                                        As corroborated by motor vehicle crash record reviews and ratings conducted by independent driving evaluators certified to administer a state driver’s licensing road test.
                                    </div>
                                    <ul role="list" className="accordion-list">
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Kandadai, V., Loeb, H., Seacrist, T.S., Lee, Y.C., Winston, Z., &amp; Winston, F.K. (2015). Simulated Driving Assessment (SDA) for teen drivers: results from a validation study. Injury Prevention, 21(3), 145-152. <a href="http://dx.doi.org/10.1136/injuryprev-2014-041480">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Kandadai, V., Loeb, H., Seacrist, T., Lee, Y.C., Bonfiglio, D., Fisher, D.L., &amp; Winston, F.K. (2015). Evaluation of a Risk Awareness Perception Training Program on Novice Teen Driver Behavior at Left-Turn Intersections. Transportation Research Record: Journal of the Transportation Research Board, 2516, 15-21. <a href="https://doi.org/10.3141/2516-03">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Curry, A.E., Kandadai, V., Sommers, M.S., &amp; Winston, F.K. (2014) Comparison of teen and adult driver crash scenarios in a nationally representative sample of serious crashes. Accident Analysis and Prevention, 72, 302–308. <a href="https://doi.org/10.1016/j.aap.2014.07.016">Source</a>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<span className="innerTitle">State of the science</span>}>
                                <div className="accordion-text-inner">
                                    <ul role="list" className="accordion-list">
                                        <li className="accordion-list-item">
                                            Kandadai, V., Loeb, H., Jean-Gilles, G., McDonald, C., Winston, A., Seacrist, T., Winston, F. (2015). &nbsp;LiveMetrics: Providing individualized feedback on driving performance. SAE Technical Paper, 2015-01-1390. &nbsp;<a href="https://doi.org/10.4271/2015-01-1390">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            McDonald, C.C., Kandadai, V., Loeb, H., Seacrist, T., Lee, Y.C., Bonfiglio, D., Fisher, D.L., &amp; Winston, F.K. (2015). Evaluation of a Risk Awareness Perception Training Program on Novice Teen Driver Behavior at Left-Turn Intersections. Transportation Research Record: Journal of the Transportation Research Board, 2516, 15-21. <a href="https://doi.org/10.3141/2516-03">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Loeb, H., Seacrist, T., McDonald, C., &amp; Winston, F. (2014). Simulated Driving Assessment: Case Study for the Development of Drivelab, Extendable Matlab™ Toolbox for Data Reduction of Clinical Driving Simulator Data. SAE Technical Paper, 2014-01-0452. <a href="https://doi.org/10.4271/2014-01-0452">Source</a>
                                        </li>
                                        <li className="accordion-list-item">
                                            Romer, D., Lee, Y.C., McDonald, C.C., Winston, F.K. (2014). Adolescence, Attention Allocation, and Driving Safety. Journal of Adolescent Health, 54(5), S6-S15. &nbsp;<a href="http://dx.doi.org/10.1016/j.jadohealth.2013.10.202">Source</a>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>

                <div style={{height:"62px"}}></div>
                <div className="ra-announcement-bar">
                    {
                        uninitiated ?

                        <img className="loading-dots" src={LoadingDots} alt="Loading Dots"  />

                        :

                        loggedIn ? 
                        
                        <a href={"/"} className="link-3">Go to Dashboard</a>

                        : 

                        <div>


                            <div  className="ftr-col"><a onClick={this.trackClickLogin.bind(this)} className="ftr-link" href={"/login"}>Login</a></div>
                            <div  className="ftr-col"><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-shrt">Register</a><a onClick={this.trackClickRegister.bind(this)} href={"/register/" + inviteId} className="ftr-link link-3-lng">Register for a free account!</a></div>
                            <div  className="ftr-col"><button onClick={this.handleShare.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">SHARE</span><img className="share-logo" src={Share} alt="Share this page"  width="18" /></button></div>
                        </div>
                    }
                </div>
                <Dialog
                    actions={[<button onClick={this.handleClose.bind(this)} className="share-button sb-sm"><span className="share-text st-sm">Close</span></button>]}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose.bind(this)}>

                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}  target="_blank" className="share-link"><i className={"icon share-icon ion-social-facebook"}></i></a>
                    <a href={"http://twitter.com/share?text=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills." + "&url=" + window.location.href + "&hashtags=" + ['safedriving', 'drivesafe']} className="share-link" target="_blank"><i className={"icon share-icon ion-social-twitter"}></i></a>
                    <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href} target="_blank" className="share-link"><i className={"icon share-icon ion-social-linkedin"}></i></a>
                    <a href={"http://pinterest.com/pin/create/button/?url=" + window.location.href + "&media=" + "https://uploads-ssl.webflow.com/610c43dea3a45a372cd7ea00/610c45fc379b131a4baff925_Ready-Assess-Logo.png" + "&description=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-pinterest"}></i></a>
                    <a href={"https://web.whatsapp.com/send?text=" + window.location.href} className="share-link" target="_blank"><i className={"icon share-icon ion-social-whatsapp"}></i></a>
                    <a href={"https://reddit.com/submit?url=" + window.location.href + "&title=" + "Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills."} target="_blank" className="share-link"><i className={"icon share-icon ion-social-reddit"}></i></a>
                    <a onClick={this.handleCopyShare.bind(this)} className="share-link"><i className={"icon share-icon ion-ios-copy"}></i></a>
                    <a href={"mailto:?subject=Ready Assess: The simple, powerful way to diagnose and improve your crash-avoidance skills.&body=Go here to register your free account and get started! site: " + window.location.href } target="_blank" className="share-link"><i className={"icon share-icon ion-ios-email"}></i></a>

                </Dialog>
            </div>
        )

        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth : appState.auth
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReadyScience);