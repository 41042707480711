import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import AssignmentForm from './AssignmentForm';
import AssignmentRow from './AssignmentRow';

// Pull down coaching assignments
// IF none -- show assignment form
// If assignment in progress -- show when assigned - time lapsed and buttons to revoke, remind
// If 1 or more completed assignments but none in progress, show assignment form and list of completed coaching assignments
// (when assigned and time lapsed until completion)

let liveAssignments = {};

class CoachingSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPrevious: false
        }
    }

    componentWillMount() {
        this.props.loadOrgs();
        this.props.loadSurveys();
    }

    togglePrevious() {
        this.setState({showPrevious: !this.state.showPrevious});
    }


    render() {
        const p = this.props;

        let content = "Loading...";

        let hasPrevious = false;

        const driver = p.driver[p.driverId];

        if (driver && !driver.loading && p.orgs.hasReceivedOrgData) {
            const org = p.orgs.data[driver.organization];
            console.log("ORGANIZATION", org);

            let assignmentRows = [];
            const aForm = <AssignmentForm key="assignment_form" surveys={org.surveys}
                        onSubmit={(function (e) {e.preventDefault(); this.props.assignCoaching(p.driverId, org.surveys); }).bind(this) } />
            if (!driver.currentlyAssigned) {
                assignmentRows.push(aForm);
            }
            console.log("Why is this blank?", driver);
            if (driver.assignments) {
                hasPrevious = Object.keys(driver.assignments).length > 1 ? true : (driver.currentlyAssigned ? false: true);
                assignmentRows = assignmentRows.concat( _.map(driver.assignments, (value, assignmentId) => {
                    return <AssignmentRow showPrevious={this.state.showPrevious} key={assignmentId} assignmentId={assignmentId} />
                }).reverse() ) // newest on top
            }
            console.log("assignment rows?", assignmentRows);
            content = assignmentRows;

            
        }

        const toggle = hasPrevious ? (

            <div style={{textAlign: "right", fontSize: "75%", fontStyle: "italic", paddingTop: "10px"}}>
                <a style={this.state.showPrevious ? {cursor: "-webkit-zoom-out"} : {cursor: "-webkit-zoom-in"} } onClick={this.togglePrevious.bind(this)}>
                {this.state.showPrevious ? "- hide previous" : "+ show previous"}
                </a>
            </div>

                                       ) : null;
        

        return (

            <div>
                {content}
                {toggle}
                
            </div>
                
        )
    }
}

// UserDrawer.contextTypes = {
//     router: React.PropTypes.func.isRequired
// };

const mapStateToProps = function (appState) {
    return {
        auth : appState.auth,
        orgs : appState.orgs,
        surveys: appState.coachingSurveys,
        driver: appState.driver
    }
}

const mapDispatchToProps = function(dispatch) {
    return {
        assignCoaching: function (driverId, surveys) {
            dispatch( actions.assignCoaching(driverId, surveys) )
        },
        loadOrgs: function () {
            dispatch( actions.startListeningToOrgs() )
        },
        loadSurveys: function () {
            dispatch( actions.startListeningToCoachingSurveys() )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachingSection);

