import { C } from '../../../constants';

const initialState = { //define initial state - an empty form
  submittingnew: false,
  alreadyExists: [],
  provAlreadyExists: [],
  success: [],
  provSuccess: [],
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {

    case C.ADDING_CUSTOMERS:
        return Object.assign({},state,{
            submittingnew: true,
            alreadyExists: [],
            provAlreadyExists: [],
            success: [],
            provSuccess: [],
            error: false
        });

    case C.ADD_BASS_KEY_EXISTS_MSG:
        return Object.assign({}, state, {
            alreadyExists: [...state.alreadyExists, action.bid]
        })

    case C.ADD_PROVISIONAL_BASS_KEY_EXISTS_MSG:
        return Object.assign({}, state, {
            provAlreadyExists: [...state.provAlreadyExists, action.bid]
        })

    case C.ADD_BASS_KEY_SUCCESS:
        return Object.assign({}, state, {
            success: [...state.success, action.bid]
        })

    case C.ADD_PROVISIONAL_BASS_KEY_SUCCESS:
        return Object.assign({}, state, {
            provSuccess: [...state.provSuccess, action.bid]
        })

    case C.ADD_BASS_KEY_ERROR:
        return Object.assign({},state,{
            error: true
        })

    case C.BASS_KEY_RESET:
        return Object.assign({}, state, {
            success: [],
            alreadyExists: [],
            provAlreadyExists: [],
            provSuccess: [],
            error: false
        })

    case C.BASS_KEY_DONE:
        return Object.assign({},state,{
            submittingnew: false
        });

      default:
        return state;
  }
}