import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';
import _ from 'lodash';

const styles = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16,
  },
};

class OrgCpt extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        let moduleToggles = [];
        let orgModules = (p.org.config && p.org.config.cptModules) || {}
        _.map(p.modules, (mod, key) => {
            moduleToggles.push(   
                <Checkbox
                    label={mod.title}
                    key={key}
                    checked={!!orgModules[key]}
                    onCheck={ (function(oid, module, isOn) { 
                                return function() {  p.toggleModule(oid, module, isOn)  }
                            })(p.oid, key, !!orgModules[key])  }
                    style={styles.checkbox} />
            );
        })
        return (

            <div className='org-settings-box'>
                <div className='display-6 btm-space'>Configure CPT Modules</div>
                {moduleToggles}
            </div>
        )
    }
}

export default OrgCpt;