import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scenario from './components/accell/Scenario';
import data from '../data/hazard-anticipation.json';
import store from '../store';
import actions from '../actions';
import { withRouter } from 'react-router';

class CPT extends Component {

    constructor(props, context) {
        console.log('CPT constructor');
        super(props, context);
        this.state = {

        };
    }

    componentWillMount () {
        console.log("§§§§§§§§§ mounting CPT");
        const p = this.props;
        const m = p.params.module;
        store.dispatch( actions.startListeningToModule(m) );
        store.dispatch( actions.startOrContinue(p.auth.uid , m));
        // somehow check here to see if this is a new attempt and save start timestamp 
    }

    // const { router } = this.context
    //     if ((auth.isOwner && auth.scoringMethod) && !auth.fakeDriver) {
    //         router.replace('/dash');
    //     }

    nextScenario () {
        const p = this.props;
        const m = p.params.module;
        const cpt = p.cpt;
        const userCpt = p.auth.cpt || {};
        let currentUserModule = userCpt && userCpt[m] && userCpt[m].inProgress
        let nextIndex = currentUserModule && currentUserModule.index + 1 || 0;
        console.log("NEXT SCENE FUNC", nextIndex, cpt.endIndex, currentUserModule);
        if (nextIndex > cpt.endIndex) {
            p.router.replace('/cptfin/' + m);
            //do whatever we do for end
            // clear progress on user
            // increment number of attempts
            

        } else {
            store.dispatch( actions.setCurrentIndex(nextIndex));
            store.dispatch( actions.setUserProgress(p.auth.uid ,m, nextIndex));
        }
    }

    render () {
        const p = this.props;
        const m = p.params.module;
        const cpt = p.cpt;
        const module = cpt.data;
        let currentIndex = cpt.currentIndex;
        const userCpt = p.auth.cpt || {};
        let currentUserModule = userCpt && userCpt[m] && userCpt[m].inProgress
        console.log(currentUserModule);
        if (currentUserModule) {
            currentIndex = currentUserModule.index;
        }

        if (cpt.loaded) {
            console.log("CPT!!!!", cpt, module, module[currentIndex], currentIndex);
            return (

                <div className='accell-wrapper'>
                    <Scenario scenario={module[currentIndex]} onDone={this.nextScenario.bind(this)} />
                </div> 
                    
            )
        }

        return <div>Loading...</div>

        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth,
        cpt: appState.cpt
    };
}

const mapDispatchToProps = function(dispatch) {
    return {}
}

const RoutableCPT = withRouter(CPT);

export default connect(mapStateToProps, mapDispatchToProps)(RoutableCPT);