import React, { Component } from 'react';
import actions from '../../actions';
import { connect } from 'react-redux';


class Subtitle extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    componentWillMount () {
        let intake = this.props.intake;
        if (intake.responses) {
            if (intake.responses['first_name>>first_name'] || intake.responses['last_name>>last_name']) {
                this.props.setSubtitle((intake.responses['first_name>>first_name'] || '') + ' ' + (intake.responses['last_name>>last_name'] || ''));
            }
        }
    }

    render () {
        
        return <div></div>
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
        setSubtitle : function (st) { dispatch( actions.setSubtitle(st) ) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subtitle);
