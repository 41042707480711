
import _ from 'lodash';

import Paper from 'material-ui/Paper';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import store from '../../../store';
import ScenarioJsonForm from './ScenarioJsonForm';
import { Link } from 'react-router';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import IconButton from 'material-ui/IconButton';


const style = {
  margin: 20,
  position: "relative"
};

const errorStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative",
    color: "red",
    fontWeight: "bold"
}

const innerStyle = {
    padding: 15,
    zIndex: 10,
    position: "relative"
}

/*
    allScenarios: {},
    allScenariosLoading: false,
    jsonUploading: false,
    jsonErrors: []

    */

class ScenarioUploader extends Component {

    componentWillMount () {
        store.dispatch( actions.startListeningToScenarios() );
        store.dispatch( actions.startListeningToModules() );
    }

    render () {
        const p = this.props;
        let modules = {};
        if (p.cpt.allModulesLoaded) {
            _.map(p.cpt.allModules, (module, moduleKey) => {
                for (let i = 0; i < module.scenarios.length; i++) {
                    modules[module.scenarios[i]] = true;
                }
            })
        }

        const bForm = (<ScenarioJsonForm  onSubmit={(function (e) {e.preventDefault(); this.props.uploadScenario(); }).bind(this) } />);
        const wait = <div>Validating and Uploading...</div>

        const add = (
                     <Paper
                        style={style}
                        zDepth={1}
                        key={999}
                    >

                        <div style={innerStyle} >{ this.props.cpt.jsonUploading ? wait : bForm}</div>
                    </Paper>
                     
        )

        
        const displayErrors = function () {
            let errors = [];
            let idx = 0;
            p.cpt.jsonErrors.forEach(er => {
                errors.push(<div key={idx}>{er}</div>)
                idx++;
            })
            if (errors.length) {
                return <Paper className="asset-upload-error" style={style} zDepth={1}>
                    <div style={errorStyle} >{errors}</div>
                </Paper>
            }
            return null;  

        }




        return (
            <div>
                { displayErrors() }
                { add }
                
                    {
                        <div>
                            {_.map(this.props.cpt.allScenarios, (scn, key) => {
                                return  <Paper key={key} style={{margin: 20, padding: 20}}>
                                    <div className='row' key={key}>
                                    
                                        <div style={{paddingBottom:5}} className='col-sm-2'>title: {scn.title}</div>
                                        <div style={{paddingBottom:5}} className='col-sm-2'>pages: {scn.pages.length}</div>
                                        <div style={{paddingBottom:5}} className='col-sm-2'>
                                            <Link to={ "/view/scenario/" + key }>
                                                view
                                            </Link>
                                        </div>
                                        <div style={{paddingBottom:5}} className='col-sm-2'>
                                            <Link to={ "/edit/scenario/" + key }>
                                                edit
                                            </Link>
                                        </div>
                                        <div className='col-sm-2'>{key}</div>
                                        <div className='col-sm-2'>
                                            {
                                                p.cpt.allModulesLoaded && !modules[key] ?
                                                <IconButton
                                                    tooltip="Delete this scenario"
                                                    onClick={(function (e) { p.deleteScenario(key); }).bind(this)}>
                                                        <DeleteIcon />
                                                
                                                </IconButton> 
                                                :
                                                null
                                            }
                                            

                                        </div>
                                    </div> </Paper>
                            }).reverse()}
                        </div>
                    }
                
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        cpt: appState.cpt,
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        uploadScenario: function () { dispatch( actions.uploadScenario() )},
        deleteScenario: function (key) { dispatch( actions.deleteScenario(key) )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioUploader);
