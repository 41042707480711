import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { DatePicker, TimePicker } from 'redux-form-material-ui';

// const validate = values => {
//     const errors = {}
//     const requiredFields = ['startTime', 'startDate', 'endTime', 'endDate']
//     requiredFields.forEach(field => {
//         if (!values[ field ]) {
//             errors[ field ] = 'Required'
//         }
//     })
//     return errors
// }



class DateRangeForm extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        console.log("rendering datetimerange form", p.start, p.end);
        const sbmt = (
            this.props.includeSubmitButton ?
            <div>
                <RaisedButton
                    primary
                    disabled={this.props.pristine}
                    label="Set query timespan"
                    type="submit"
                    style={{ width: "100%", marginTop: "15px" }} />
            </div> :
            null
        )
        return (
            <form
                className={this.props.className}
                onSubmit={this.props.onSubmit}>
                <div className="row">
                    <div className="col-sm-6 start-inputs">
                        <div className="datetimefields">
                            <div>
                                <Field
                                    name="startDate"
                                    component={DatePicker}
                                    format={(value, name) => {
                                        if (p.start) {
                                            return value === '' ? null : p.start
                                        } else {
                                            return null;
                                        }
                                    }}
                                    hintText="Day of start?" />
                            </div>

                        
                            <div>
                                <Field
                                    name="startTime"
                                    component={TimePicker}
                                    format={(value, name) => {
                                        if (p.start) {
                                            return value === '' ? null : p.start
                                        } else {
                                            return null;
                                        }
                                    }}
                                    defaultValue={null} // TimePicker requires an object,
                                    // and redux-form defaults to ''
                                    hintText="Time of start?" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 end-inputs">
                        <div className="datetimefields">
                            <div>
                                <Field
                                    name="endDate"
                                    component={DatePicker}
                                    format={(value, name) => {
                                        if (p.end) {
                                            return value === '' ? null : p.end
                                        } else {
                                            return null;
                                        }
                                    }}
                                    hintText="Day of end?" />
                            </div>
                            <div>
                                <Field
                                    name="endTime"
                                    component={TimePicker}
                                    format={(value, name) => {
                                        if (p.end) {
                                            return value === '' ? null : p.end
                                        } else {
                                            return null;
                                        }
                                    }}
                                    defaultValue={null} // TimePicker requires an object,
                                    // and redux-form defaults to ''
                                    hintText="Time of end?" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>
        )
    }
}

// Decorate the form component
DateRangeForm = reduxForm({
  form: 'datetimerange', // a unique name for this form
  // validate
})(DateRangeForm);


export default DateRangeForm;