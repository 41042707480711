import { C } from '../../constants';

const initialState = { //define initial state - an empty form
  done : false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case C.FINISH_RECONCILIATION:
        return Object.assign({}, state, {
          done : true
        });

    case C.RESET_RECONCILIATION:
        return Object.assign({}, state, {
          done : false
        });

      default:
        return state;
  }
}