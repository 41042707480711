import React, { Component } from 'react';
import { connect } from 'react-redux';
import {C} from '../../constants';
import actions from '../../actions';
import store from '../../store';
import queryString from 'query-string';


class DashWrapper extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    waitForScoringMethod () {
        const router = this.props.router;

        let aid = this.props.params.aid;
        let dn = this.props.params.dimensionName;
        let dv = this.props.params.dimensionValue;
        let free = this.props.params.free;
        console.log('UP FRONT YO', this.props.location.search);
        if (aid || (dn && dv) || free) {
            if (aid) {
                router.replace('/dash/d/' + aid + this.props.location.search);
            }
            if (dn && dv) {
                router.replace('/dash/a/' + dn + '/' + dv + this.props.location.search);
            }
            if (free) {
                router.replace('/dash/f/' + free + this.props.location.search);
            }
        } else {
            router.replace('/dash' + this.props.location.search);
        }

    }

    componentWillMount () {
        if (!this.props.auth.scoringMethod) {
            this.waitForScoringMethod();
        } else {
            let aid = this.props.params.aid;
            let dimensionName = this.props.params.dimensionName;
            let dimensionValue = this.props.params.dimensionValue;
            const qs = queryString.parse(this.props.location.search);
            if (qs.customerIds) {
                let ids = qs.customerIds.split(',');
                store.dispatch({ type: C.SET_CUSTOMER_IDS, ids});
            }
            if (qs.additionalColumns && this.props.auth.gid == 'super') { 
                let addCols = qs.additionalColumns.split(',');
                if (addCols.length) {
                    store.dispatch({ type: C.SET_QRY_CUSTOM_COLS, addCols});
                }
            }
            if (qs.showAllOrgs == 'true' && this.props.auth.gid == 'super') {
                store.dispatch({ type: C.SET_QRY_SHOW_ALL_ORGS, showAllOrgs: true});
            }
            store.dispatch({ type: C.SET_QRY_AID, aid});
            store.dispatch({ type: C.SET_QRY_DIMENSIONS, dimensionName, dimensionValue});
            let qryLimit = qs.limit || 1000;
            store.dispatch( actions.loadInitialDashQuery(aid, dimensionName, dimensionValue, qs.showIds, qryLimit) );
        }
    }



    render () {
        
        return <div> {this.props.children} </div>
        
    }
}

const mapStateToProps = function (appState) {
    return {
        auth: appState.auth
    };
}

const mapDispatchToProps = function (dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashWrapper);
