import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default class Mailer {
    sendNewAssignment(driverUid, assignorUid, assignment) {
        return this.executeCallable('sendAssignmentEmail', { 
            origin: window.location.origin,
            driverUid,
            assignorUid,
            assignment
        }).catch(err => console.log("Error sending assignment: ", err));
    }

    resendAssignment(assignmentUid) {
        return this.executeCallable('sendAssignmentEmail', {
            origin: window.location.origin,
            resend: true,
            assignment: { uid: assignmentUid }
        }).catch(err => console.log("Error re-sending assignment: ", err));
    }

    sendNewUserInvite(invitorUid, orgUid, makeManager, email, payPerAssessment, waiveFee, requireParent, validateParentEmail) {
        return this.executeCallable('sendInviteEmail', {
            origin: window.location.origin,
            invitorUid,
            orgUid,
            makeManager,
            email,
            payPerAssessment,
            waiveFee,
            requireParent,
            validateParentEmail
        }).catch(err => console.log("Error sending user invitation: ", err));
    }

    resendUserInvite(inviteUid) {
        return this.executeCallable('sendInviteEmail', {
            origin: window.location.origin,
            resend: true,
            inviteUid
        }).catch(err => console.log("Error re-sending invitation: ", err));
    }

    executeCallable(name, data) {
        return firebase.functions().httpsCallable(name)(data);
    }
}