import React, { Component } from 'react';
import { Link } from 'react-router';



class InviteLink extends Component {


    render () {

        return (
            <li className="nav-item">
                <Link className="nav-link" to="/invites">
                    <i className={"icon ion-person-add"} style={{marginRight: "6px"}}></i>
                    <span style={{fontWeight: "bolder"}}>Invite</span>
                </Link>
            </li>
        )
        
    }
}


export default InviteLink;