
import CircularProgress from 'material-ui/CircularProgress';

import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import store from '../store';






class Simulate extends Component {

    componentWillMount () {
        this.props.setTitle('Simulate Drive');
    }

    componentWillUnmount () {
        this.props.clearTitle();
    }

    render () {

        const p = this.props;
        console.log("WHERE AM I?", p.simulate);
        return (
            <div>
                {p.simulate.simulating ? <CircularProgress /> : null}
                {
                    !p.simulate.simulating ? 
                    <button disabled={p.simulate.simulating} style={{marginRight: 10}} onClick={(function (e) {e.preventDefault(); this.props.simulateDrive("abacus"); }).bind(this) }>
                        Simulate Drive
                    </button>
                :
                    <span>Simulating...</span>
                }
                
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        simulate: appState.simulate
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        simulateDrive: function (drid) { dispatch( actions.simulateDrive(drid) )},
        setTitle : function (t) { dispatch( actions.setTitle(t) ) },
        clearTitle : function () { dispatch( actions.clearTitle() ) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Simulate);
