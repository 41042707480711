import { C } from '../constants';
import {hlp} from '../utils/helpers';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

const functions = firebase.functions();

const orgsRef = C.APP.database().ref('organizations');

const startPayment = functions.httpsCallable('startPayment');

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(C.STRIPE_PK);
  }
  return stripePromise;
};

export const startCheckout = function (opt_options, opt_isForCredit) {
    return function (dispatch, getState) {
        // get oid and driverID from form
        // handle invalid driver ID
        // call startPayment with this data

        dispatch({ type: C.SET_CHECKOUT_LOADING, loading: true});


        console.log("startCheckout", opt_options, opt_isForCredit);

        let options = {
        }

        if (!opt_isForCredit) {
            const formValues = getState().form.payment.values;
            const oid = getState().auth.oid;
            const driverId = formValues.driverId;
            options = {
                oid, 
                driverId
            }
        } else {
            options.price = opt_options && opt_options.price || getState().auth.price;
            options.productName = opt_options && opt_options.productName || 'Virtual Driving Test';
            options.successPath = opt_options && opt_options.successPath || '';
            options.cancelPath = opt_options && opt_options.cancelPath || '';
            options.metadata = {
                uid: getState().auth.uid, // This is used by webhook to add credit to users account
            }
        }
        
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            options.hostPrefix = "http://localhost" + (location.port ? ':' + location.port : '') + '/';
        }


        startPayment(options).then(res => {
            let stp = getStripe();
            console.log(res);
            stp.then(stripe => {
                stripe.redirectToCheckout({
                    sessionId: res.data.id,
                }).then(() => {

                }).catch(error => {
                    dispatch({ type: C.SET_CHECKOUT_LOADING, loading: false});
                    alert('an error occured: ' + error.message);
                })
            })
            // const stripe = await stripePromise;
            // const result = await stripe.redirectToCheckout({
            //     sessionId: res.id,
            // });

            // if (result.error) {
            //     // If `redirectToCheckout` fails due to a browser or network
            //     // error, display the localized error message to your customer
            //     // using `result.error.message`.
            //     alert('an error occured: ' + result.error.message);
            // }
        }).catch(error => {
            dispatch({ type: C.SET_CHECKOUT_LOADING, loading: false});
            alert('an error occured: ' + error.message);
        })


        
    }
}