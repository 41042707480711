import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';

const styles = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16,
  },
};

class FeatureFlags extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render () {
        const p = this.props;
        let flagToggles = [];
        let orgFlags = (p.org.config && p.org.config.featureFlags) || {}
        for (var i = 0; i < p.flags.length; i++) {
                        flagToggles.push(   
                            <Checkbox
                                label={p.flags[i]}
                                key={p.flags[i]}
                                checked={!!orgFlags[p.flags[i]]}
                                onCheck={ (function(oid, flag, isOn) { 
                                            return function() {  p.toggleFeatureFlag(oid, flag, isOn)  }
                                        })(p.oid, p.flags[i], !!orgFlags[p.flags[i]])  }
                                style={styles.checkbox} />
                        );
                    };
        return (

            <div className='org-settings-box'>
                <div className='display-6 btm-space'>Configure Feature Flags</div>
                {flagToggles}
            </div>
        )
    }
}

export default FeatureFlags;