
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import List from 'material-ui/List';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import actions from '../actions';
import store from '../store';
import * as hlp from '../utils/helpers';
import AddOrg from './components/addorg';
import Org from './components/org';

const startListening = hlp.createOneShot(function () {
    store.dispatch( actions.startListeningToOrgs() );
});

class Orgs extends Component {

    componentWillMount () {
        startListening();
    }

    render () {
        const p = this.props;
        const rows = _.map(p.orgs.data, function (org, oid) {
            const orgState = p.orgs.states[oid]; //TODO: use this for editing groups
            return (
                    <div className="user-list-item" key={oid}>
                        <Link to={"manageorg/" + oid}><Org key={oid} org={org} /></Link>
                    </div>
            )
        })

//from users.jsx:
// const rows = _.map(p.users.data, function (user, uid) {
//     return (
//         <div className="user-list-item" key={uid}>
//             <Link to={ "manageuser/" + uid }><Face /> {user.fullname}</Link>
//         </div>
//     )
// })

        const list = (
            <List className="orgList">
                {p.orgs.hasReceivedOrgData ? rows : "Loading Organizations..."}
            </List>
        )
        const nodata = (
            <div>No organizations exist</div>
        )

        const add = <AddOrg onSubmit={(function (e) {e.preventDefault(); this.props.submitNewOrg(); }).bind(this) } />
        const wait = <CircularProgress />

        return (
            <div>
            { rows.length ? list : nodata }
            { this.props.orgs.submittingnew ? wait : add}
            </div>
        )
    }
}

const mapStateToProps = function (appState) {
    return {
        orgs: appState.orgs
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
        submitNewOrg: function () { dispatch( actions.submitNewOrg() )}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Orgs);
