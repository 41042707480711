import * as altSurveyActions from './altSurveys';
import * as assessmentActions from './assessments';
import * as assettActions from './assets';
import * as assignmentActions from './assignments';
import * as authActions from './auth';
import * as buildActions from './builds';
import * as coachingActions from './coaching';
import * as contentActions from './content';
import * as cptActions from './cpt';
import * as dashActions from './dash';
import * as documentsActions from './documents';
import * as driverActions from './drivers';
import * as drivesActions from './drives';
import * as driverIdsActions from './driverIds';
import * as featureFlagActions from './featureflags';
import * as feedbackActions from './feedback';
import * as filesActions from './files';
import * as formActions from './form';
import * as groupActions from './groups';
import * as intakeActions from './intake';
import * as inviteActions from './invites';
import * as launcherActions from './launcher';
import * as customerActions from './odps/customer';
import * as locActions from './odps/locations';
import * as localeActions from './locales';
import * as orgActions from './orgs';
import * as overviewActions from './overview';
import * as payActions from './pay';
import * as reconcileActions from './reconcile';
import * as rollupActions from './rollup';
import * as simulateActions from './simulate';
import * as uiActions from './ui';
import * as usersActions from './users';
import * as utilActions from './util';
import * as utilsActions from './utils';
import * as videoActions from './video';
import * as webhookActions from './webhooks';

export default Object.assign({}, assettActions, authActions, documentsActions,
    formActions, usersActions, groupActions, orgActions, drivesActions,
    utilActions, utilsActions, driverActions, videoActions, contentActions,
    dashActions, inviteActions, intakeActions, customerActions, buildActions,
    overviewActions, payActions, locActions, localeActions, rollupActions,
    launcherActions, coachingActions, assignmentActions, assessmentActions,
    feedbackActions, reconcileActions, simulateActions, uiActions,
    featureFlagActions, webhookActions, driverIdsActions, filesActions,
    altSurveyActions, cptActions);
