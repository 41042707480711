
import Paper from 'material-ui/Paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';



class CoachingSuccess extends Component {

    componentWillMount () {
        // will need to listen to alerts for this user
    }

    

    render () {


        return (

                <div>
                    <Paper style={{margin: "30px", padding: "25px"}}>
                        
                        {"Thank you for completing this coaching task!"}

                    </Paper>
                </div>

        )
    }
}

const mapStateToProps = function (appState) {
    return {
    };
}

const mapDispatchToProps = function(dispatch) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoachingSuccess);

